import { Card, CardEmptyState, GridCard, GridContainer } from 'components'
import React, { Fragment } from 'react'

import Button from '@material-ui/core/Button'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import getLocalizedValue from 'utils/getLocalizedValue'

const CreateCategory = ({ ecosystem, children }) => (
  <Link to={`/ecosystems/${ecosystem.id}/category/create`}>{children}</Link>
)

const Categories = ({ categories, ecosystem }) => {
  return (
    <Fragment>
      <Card
        title="Categories"
        button={
          <CreateCategory ecosystem={ecosystem}>
            <Button variant="contained" className="fr" color="primary">
              + Create
            </Button>
          </CreateCategory>
        }
      >
        <div data-testid="ecosystem-listings-categories-view">
          {categories.length !== 0 ? (
            <GridContainer>
              {categories.map((category) => (
                <GridCard
                  key={category.id}
                  title={getLocalizedValue(category, 'name', ecosystem.locales)}
                  imageSrc={category.logo ? category.logo.url : null}
                  // subtitle={`${category.listings.length} listing(s)`} // TODO use listingCount
                  link={`categories/${category.id}/overview`}
                  sm={3}
                  xs={12}
                />
              ))}
            </GridContainer>
          ) : (
            <CardEmptyState>
              {`You haven't created any categories yet. `}
              <CreateCategory ecosystem={ecosystem}>+ Create a category.</CreateCategory>
            </CardEmptyState>
          )}
        </div>
      </Card>
    </Fragment>
  )
}

Categories.propTypes = {
  categories: PropTypes.array.isRequired
}

export default Categories
