import React from 'react'
import integrationPlatforms from 'constants/integrationPlatforms'
import BorderIcon from './BorderIcon'

export const Logo = ({ type, ...rest }) => (
  <BorderIcon
    src={integrationPlatforms[type].icon}
    alt={integrationPlatforms[type].label}
    {...rest}
  />
)

export default Logo
