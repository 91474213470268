import React, { Fragment, useState } from 'react'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  dialog: {
    padding: theme.spacing(2, 3)
  }
}))

const ConfirmationDialogNew = ({
  renderOpener,
  title,
  text,
  cancelButtonText,
  confirmButtonText,
  loading,
  loadingText,
  onConfirm
}) => {
  const [open, setOpen] = useState(false)
  const classes = useStyles()

  return (
    <Fragment>
      {renderOpener(setOpen)}
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        fullWidth
        onClick={(e) => e.stopPropagation()}
        classes={{
          paper: classes.dialog
        }}
      >
        <DialogTitle>{title}</DialogTitle>
        {text && (
          <DialogContent>
            <DialogContentText>{text}</DialogContentText>
          </DialogContent>
        )}
        <DialogActions>
          <Button onClick={() => setOpen(false)}>{cancelButtonText}</Button>
          <Button
            color="primary"
            variant="contained"
            onClick={() => onConfirm(() => setOpen(false))}
            disabled={loading}
          >
            {loading ? loadingText : confirmButtonText}
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  )
}

ConfirmationDialogNew.defaultProps = {
  cancelButtonText: 'Cancel',
  confirmButtonText: 'Confirm',
  text: null,
  loadingText: 'Loading..'
}

ConfirmationDialogNew.propTypes = {
  renderOpener: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  loadingText: PropTypes.string,
  cancelButtonText: PropTypes.string,
  confirmButtonText: PropTypes.string,
  onConfirm: PropTypes.func.isRequired
}

export default ConfirmationDialogNew
