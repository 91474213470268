import PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import NetworkContent from './Content'

function joinServicesWithListings(services, listings) {
  return services.map((service) => {
    const listing = listings?.find((l) => l.slug === service.id)

    return Object.assign(
      {
        imported: !!listing,
        listingId: listing ? listing.id : ''
      },
      service
    )
  })
}

function Network(props) {
  const {
    handleServiceClick,
    hasMore,
    listings,
    loading,
    loadNextPage,
    searching,
    services: _services,
    total
  } = props

  const services = useMemo(
    () => joinServicesWithListings(_services, listings),
    [_services, listings]
  )

  return (
    <NetworkContent
      handleServiceClick={handleServiceClick}
      hasMore={hasMore}
      loading={loading}
      searching={searching}
      loadNextPage={loadNextPage}
      services={services}
      total={total}
    />
  )
}

Network.propTypes = {
  handleServiceClick: PropTypes.func.isRequired,
  hasMore: PropTypes.bool.isRequired,
  listings: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  loadNextPage: PropTypes.func.isRequired,
  searching: PropTypes.bool.isRequired,
  services: PropTypes.array,
  total: PropTypes.number.isRequired
}

export default Network
