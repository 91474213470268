import React from 'react'
import { Mutation } from 'react-apollo'
import { importServiceMutation, deleteListingMutation } from 'api/queries'
import store from 'store'
import { adopt } from 'react-adopt'

function deleteListing({ accountId, ecosystemId, render }) {
  return (
    <Mutation
      mutation={deleteListingMutation}
      update={(cache, { data: { deleteListing } }) => {
        store.listings.remove(cache, { accountId, ecosystemId }, { id: deleteListing.id })
      }}
    >
      {(mutation, result) => render({ mutation, result })}
    </Mutation>
  )
}

function importService({ accountId, ecosystemId, render }) {
  return (
    <Mutation
      mutation={importServiceMutation}
      update={(cache, { data: { importService: service } }) => {
        store.services.import(cache, { accountId, ecosystemId }, { service })
      }}
    >
      {(mutation, result) => render({ mutation, result })}
    </Mutation>
  )
}

const NetworkContainerWithoutServices = adopt({
  deleteListing,
  importService
})

export default NetworkContainerWithoutServices
