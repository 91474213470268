import React from 'react'
import classNames from 'classnames'
import { lighten } from 'polished'
import styled from 'react-emotion'
import { useIntercom } from 'react-use-intercom'

const PlanContainer = styled('div')`
  border: 1px solid var(--color-lighter);
  border-radius: 8px;
  text-align: center;
  padding: 0;
  width: 220px;

  :hover {
    box-shadow: 0 2px 35px 0 rgba(32, 74, 145, 0.2);
  }

  :not(.highlighted) {
    margin-top: 36px;
  }

  h4 {
    color: var(--color-text-darkest);
    margin: 5px;
  }

  p {
    font-size: 14px;
    margin: 0;
    color: var(--color-text-light);
  }

  .price {
    display: inline-block;
    font-size: 32px;
    font-weight: 700;
    color: var(--color-text-darkest);
  }

  ul {
    margin: 0;
    padding: 0;
  }

  ul li {
    position: relative;
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 15px;
    color: var(--color-text-darkest);
    list-style: none;
  }

  ul li.highlight {
    color: var(--color-text-darkest);
  }

  .button {
    margin-top: 20px;
    cursor: pointer;
  }

  p.notice {
    font-size: 12px;
    margin: 10px 0;
  }

  .top {
    border-bottom: 1px solid var(--color-lightest);
    padding: 24px 0px;
  }

  .highlighted {
    margin-top: 0px;
    border: none;

    .price {
      font-size: 40px;
      color: var(--color-secondary);
    }

    button {
      margin-top: 13px;
    }

    h4 {
      color: var(--color-secondary);
    }
  }

  .features-title {
    margin-top: 20px;
  }

  .prev-plan {
    color: var(--color-secondary);
  }

  span.most-popular {
    margin-top: 0;
    background-color: var(--color-secondary);
    color: #fff;
    width: auto;
    display: block;
    border-radius: 5px 5px 0 0;
    padding: 6px;
  }

  .enterprise {
    background-color: #210d7c;

    .price,
    ul li {
      color: #fff;
    }

    p,
    h4 {
      color: #dbd3ff;
    }
  }

  .-dimmed {
    color: ${lighten(0.25, '#414386')};
  }
`

const Plan = ({ plan, prevPlanName, currency = 'USD', cycle = 'MONTHLY', highlighted = false }) => {
  const { showNewMessages } = useIntercom()
  let currencySign = '$'
  if (currency === 'EUR') {
    currencySign = '€'
  } else if (currency === 'GBP') {
    currencySign = '£'
  }

  const handleClick = () => {
    const message = `I'm ready to upgrade to the ${
      plan.name
    } plan (${cycle.toLowerCase()}) and would like to know the next steps`

    showNewMessages(message)
  }

  return (
    <PlanContainer className={classNames({ highlighted })}>
      <div className={classNames({ highlighted })}>
        {highlighted && <span className="most-popular">Most popular</span>}
        <div className="top">
          <h4>{plan.name}</h4>
          <span className="price">
            {plan.name !== 'Enterprise' ? (
              `${currencySign}${
                cycle === 'MONTHLY' ? plan.pricePerMonth[currency] : plan.pricePerYear[currency]
              }`
            ) : (
              <span style={{ fontSize: '28px' }}>Custom</span>
            )}
          </span>
          <p>Billed {plan.fixedCycle || cycle.toLowerCase()}</p>
        </div>
        <button
          className={`button -small ${!highlighted && ' -ghost'}`}
          style={{ whiteSpace: 'nowrap' }}
          onClick={() => handleClick()}
        >
          Choose {plan.name}
        </button>
        <ul>
          {prevPlanName ? (
            <li className="features-title">
              <strong>
                Includes <span className="prev-plan">{prevPlanName}</span>, plus:
              </strong>
            </li>
          ) : (
            <li className="features-title">
              <strong>Includes:</strong>
            </li>
          )}
          {Object.keys(plan.features).map((feature, index) => {
            const included = plan.features[feature]

            return (
              <li key={index}>
                <div className={`feature ${!included && ' -dimmed'}`}>
                  {!included ? '-' : feature}
                </div>
              </li>
            )
          })}
        </ul>
      </div>
    </PlanContainer>
  )
}

export default Plan
