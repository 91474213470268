export const ACTION_TYPES = {
  LOAD_ACTIVE_ACCOUNT: 'account/LOAD_ACTIVE_ACCOUNT'
}
export const loadActiveAccount = (payload) => ({
  type: ACTION_TYPES.LOAD_ACTIVE_ACCOUNT,
  payload
})

const initialState = {
  errors: null,
  activeAccount: null
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.LOAD_ACTIVE_ACCOUNT:
      return {
        ...state,
        activeAccount: action.payload
      }
    default:
      return state
  }
}

export default reducer
