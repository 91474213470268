import React from 'react'
import styled from 'react-emotion'
import { LoadingPlaceholder } from 'components'

const CardWrapper = styled('div')`
  box-shadow: 0 1px 3px 0 rgba(184, 184, 184, 0.5);
  border-radius: 5px;
  background: #fff;
  margin-bottom: 24px;
`

const Left = styled('div')`
  margin: 10px 0 5px;
`

const Top = styled('div')`
  border-bottom: 1px solid #e9e9e9;
  background: #fdfdfd;
  padding: 4px 24px;
  border-radius: 5px 5px 0 0;

  h3 {
    font-family: Roboto, sans-serif;
    font-size: 16px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #001b2f;
  }
`

const Main = styled('div')`
  font-size: 14px;
  padding: 24px;

  p,
  ul li {
    font-size: 1em;
  }

  p:first-child {
    margin-top: 0;
  }
`

const Card = ({ title, button, left, style, children, id, height = '100px', loading }) => (
  <CardWrapper style={{ ...style }} id={id}>
    {(title || button || left) && (
      <Top>
        <div style={{ float: 'right', marginTop: '15px' }}>{button}</div>
        {title && <h3>{title}</h3>}
        {left && <Left>{left}</Left>}
      </Top>
    )}
    <Main style={{ minHeight: height }}>{loading ? <LoadingPlaceholder /> : children}</Main>
  </CardWrapper>
)

export default Card
