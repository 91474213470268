import React from 'react'
import useToggle from '@rooks/use-toggle'
import Switch from '@material-ui/core/Switch'
import PanelInputWithInfo from '../PanelInputWithInfo'

const PanelToggle = ({ children, label, show, formikProps, field }) => {
  const [check, toggle] = useToggle(show, (v) => {
    if (!v) {
      formikProps.setFieldValue(field, '')
    }

    return !v
  })

  return (
    <PanelInputWithInfo
      rightBloc={<label style={{ marginBottom: 0 }}>{label}</label>}
      rightBlocAddon={<Switch id="check" name="check" checked={check} onChange={toggle} />}
    >
      {check ? children : null}
    </PanelInputWithInfo>
  )
}

export default PanelToggle
