import { ErrorFallback, ListingCreate, injectLocalizedValues, prepareVariables } from 'components'
import { compose, withHandlers } from 'recompose'

import LoadingPlaceholder from 'components/placeholder/Loading'
import React from 'react'
import { createListingMutation } from 'api/queries'
import { ecosystemLocales } from 'utils/locales'
import { graphql } from 'react-apollo'
import { toastr } from 'react-redux-toastr'
import transformError from 'utils/transformError'
import uploadFile from 'utils/fileUpload'
import { useMaxListingsContext } from 'context/useMaxListingsContext'
import { useHistory } from 'react-router-dom'
import { useEcosystemContext } from 'context'

export const Container = ({ loading, error, marketplace, handleSubmit }) => {
  const { ecosystem } = useEcosystemContext()
  const history = useHistory()
  const { maxListingsReached } = useMaxListingsContext()

  if (maxListingsReached) {
    history.replace(`/ecosystems/${ecosystem.id}/listings/overview`)
  }

  if (loading) {
    return <LoadingPlaceholder />
  } else if (error) {
    return <ErrorFallback error={error} />
  } else {
    let initialValues = {
      name: '',
      slug: '',
      tagLine: '',
      logo: [],
      description: '',
      features: '',
      pricing: '',
      metaTagKeywords: '',
      metaTagTitle: '',
      metaTagDescription: '',
      unifyConnectorId: '',
      nativeIntegration: false,
      thirdPartyIntegration: false,
      nativeIntegrationLink: marketplace.listingNativeIntegrationLink,
      thirdPartyIntegrationLink: '',
      zapierId: '',
      piesyncId: '',
      blendr: '',
      sticky: false,
      upcoming: false,
      websiteLink: '',
      termsLink: '',
      privacyLink: '',
      supportLink: '',
      published: true,
      locales: ecosystemLocales(marketplace)
    }

    initialValues = injectLocalizedValues('listing', initialValues)

    return (
      <ListingCreate
        initialValues={initialValues}
        handleSubmit={handleSubmit}
        categories={marketplace.categories}
        ecosystem={marketplace}
      />
    )
  }
}

const handleSubmit = withHandlers({
  handleSubmit:
    ({
      createListing,
      activeAccount,
      history,
      marketplace,
      match: {
        params: { ecosystemId }
      }
    }) =>
    async (props, { setErrors, setSubmitting }) => {
      setSubmitting(true)
      const {
        externalId,
        name,
        description,
        features,
        pricing,
        slug,
        tagLine,
        category,
        metaTagTitle,
        metaTagDescription,
        metaTagKeywords,
        logo,
        unifyConnectorId,
        nativeIntegration,
        thirdPartyIntegration,
        nativeIntegrationLink,
        thirdPartyIntegrationLink,
        zapierId,
        piesyncId,
        blendrId,
        automateId,
        integromatId,
        sticky,
        upcoming,
        websiteLink,
        termsLink,
        privacyLink,
        supportLink,
        published
      } = props

      let variables = {
        accountId: activeAccount.id,
        ecosystemId,
        externalId,
        name,
        slug,
        features,
        pricing,
        description,
        tagLine,
        metaTagTitle,
        metaTagDescription,
        metaTagKeywords,
        unifyConnectorId,
        nativeIntegration,
        thirdPartyIntegration,
        nativeIntegrationLink,
        thirdPartyIntegrationLink,
        zapierId,
        piesyncId,
        blendrId,
        automateId,
        integromatId,
        sticky,
        upcoming,
        websiteLink,
        termsLink,
        privacyLink,
        supportLink,
        published,
        translations: prepareVariables(props, marketplace, marketplace.translations)
      }

      try {
        if (logo.length !== 0) {
          let result = await uploadFile(logo[0], {
            params: {
              folder: `marketplaces/${ecosystemId}/listings`
            }
          })

          variables = Object.assign(
            {
              logo: {
                url: result.url,
                contentType: `${result.resourceType}/${result.format}`
              }
            },
            variables
          )
        }

        if (category) {
          variables.categoryId = category
        }

        const createdListing = await createListing({
          variables,
          refetchQueries: 'listing'
        })

        toastr.success('Listing created')
        history.push(
          `/ecosystems/${ecosystemId}/listings/${createdListing.data.createListing.id}/overview`
        )
      } catch (error) {
        toastr.error('Could not create listing')
        if (error.graphQLErrors) {
          setErrors(transformError(error.graphQLErrors))
        }

        setSubmitting(false)
      }
    }
})

const createListing = graphql(createListingMutation, {
  name: 'createListing'
})

const CreateListingContainer = compose(createListing, handleSubmit)(Container)

export default CreateListingContainer
