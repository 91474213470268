import { makeStyles } from '@material-ui/core'

export const useCardStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4)
  },
  row: {
    padding: theme.spacing(1.5, 2.5),
    borderBottom: '1px solid #F3F4F6',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    '&:hover': {
      background: theme.palette.ui[50]
    }
  },
  avatar: {
    width: theme.spacing(5),
    height: theme.spacing(5),
    marginRight: 15
  },
  flexContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  header: {
    display: 'block',
    fontWeight: 500
  },
  userName: {
    display: 'block',
    color: '#6B7280',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '100%'
  },
  connection: {
    border: '2px solid white',
    background: 'white',
    width: theme.spacing(4.5),
    height: theme.spacing(4.5)
  },
  connectionCount: {
    fontSize: '12px',
    border: '2px solid white',
    width: theme.spacing(4.5),
    height: theme.spacing(4.5)
  },
  grid: {
    display: 'flex',
    alignItems: 'center',
    paddingRight: 5,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
}))
