import {
  CustomDomainSettingsContainer,
  GeneralSettingsContainer,
  ListingsSettingsContainer,
  PartnerSettingsContainer,
  SeoSettingsContainer,
  TemplateSettingsContainer
} from 'containers'
import { IntegrationsSettingsContainer, LeadsSettingsContainer } from 'pages'
import React, { useState } from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'

import { ContentContainer } from 'components'
import Header from 'components/ecosystem/settings/Header'
import PrivateRoute from 'components/PrivateRoute'

const SettingsRoutes = ({ marketplace, activeAccount }) => {
  const [activePath, setActivePath] = useState('')
  const match = useRouteMatch()
  return (
    <Switch>
      <Route path={`${match.path}`}>
        <ContentContainer header={<Header activePath={activePath} activeAccount={activeAccount} />}>
          <Switch>
            <PrivateRoute
              exact
              path={`${match.path}`}
              component={GeneralSettingsContainer}
              marketplace={marketplace}
              activeAccount={activeAccount}
            />
            <PrivateRoute
              exact
              path={`${match.path}/integrations`}
              component={IntegrationsSettingsContainer}
              ecosystem={marketplace}
              activeAccount={activeAccount}
              setActivePath={setActivePath}
            />
            <PrivateRoute
              exact
              path={`${match.path}/listings`}
              component={ListingsSettingsContainer}
              ecosystem={marketplace}
              activeAccount={activeAccount}
            />
            <PrivateRoute
              exact
              path={`${match.path}/leads`}
              component={LeadsSettingsContainer}
              marketplace={marketplace}
              activeAccount={activeAccount}
            />
            <PrivateRoute
              exact
              path={`${match.path}/template`}
              component={TemplateSettingsContainer}
              ecosystem={marketplace}
              activeAccount={activeAccount}
            />
            <PrivateRoute
              exact
              path={`${match.path}/seo`}
              component={SeoSettingsContainer}
              ecosystem={marketplace}
              activeAccount={activeAccount}
            />
            <PrivateRoute
              exact
              path={`${match.path}/custom-domain`}
              component={CustomDomainSettingsContainer}
              marketplace={marketplace}
              activeAccount={activeAccount}
            />
            <PrivateRoute
              exact
              path={`${match.path}/partners`}
              component={PartnerSettingsContainer}
              marketplace={marketplace}
              activeAccount={activeAccount}
            />
          </Switch>
        </ContentContainer>
      </Route>
    </Switch>
  )
}

export default SettingsRoutes
