import { createContext, useContext } from 'react'

const EcosystemContext = createContext({ ecosystem: null })

const useEcosystemContext = () => {
  const context = useContext(EcosystemContext)

  if (!context) {
    throw new Error('useEcosystem must be used within a EcosystemProvider')
  }

  const { ecosystem } = context

  return {
    ecosystem
  }
}

export { EcosystemContext, useEcosystemContext }
