import React, { Component } from 'react'
import PropTypes from 'prop-types'
import * as yup from 'yup'
import { FormDialog, TextInput, FileUploadInput, injectLocalizedValues } from 'components'

const schema = yup.object().shape({
  image: yup.mixed().required()
})

class ScreenshotDialog extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    handleDelete: PropTypes.func.isRequired
  }

  static defaultProps = {
    screenshot: null
  }

  handleOpen = (openWithState) => () => {
    const { screenshot, locales } = this.props
    const title = screenshot ? 'Edit Screenshot' : 'New Screenshot'
    const contentText = screenshot
      ? 'Add a screenshot with a descriptive caption'
      : 'Update screenshot with a descriptive caption'

    let initialValues = {
      id: screenshot ? screenshot.id : null,
      caption: screenshot ? screenshot.caption : '',
      image: screenshot ? [screenshot.file] : [],
      locales
    }

    if (screenshot) {
      initialValues = injectLocalizedValues('listingScreenshots', initialValues, screenshot)
    }

    openWithState({
      title,
      contentText,
      initialValues,
      validationSchema: schema,
      render: this.renderFields,
      onSubmit: this.props.handleSubmit,
      onDelete: screenshot ? this.props.handleDelete : null
    })
  }

  renderFields = (formikProps) => (
    <fieldset>
      <TextInput formikProps={formikProps} label="Caption" field="caption" localized required />
      <FileUploadInput
        formikProps={formikProps}
        label="Image"
        field="image"
        description="Ideal dimensions: 1440x826px"
        multiple={false}
        required
      />
    </fieldset>
  )

  render() {
    return (
      <FormDialog
        opener={(openWithState) => (
          <div onClick={this.handleOpen(openWithState)}>{this.props.opener}</div>
        )}
      />
    )
  }
}

export default ScreenshotDialog
