import React from 'react'
import PropTypes from 'prop-types'
import PrivateRoute from 'components/PrivateRoute'
import { Redirect, Switch, useRouteMatch } from 'react-router-dom'
import { ContentContainer } from 'components'
import PartnerHeader from './Header'
import PartnerOverviewPage from './overview/Page'
import PartnerSettingsPage from './settings/Page'

function PartnerLayout({ activeAccount, ecosystem, partner }) {
  const match = useRouteMatch()

  return (
    <ContentContainer header={<PartnerHeader partner={partner} />}>
      <Switch>
        <Redirect exact from={`${match.path}/`} to={`${match.path}/overview`} />
        <PrivateRoute
          path={`${match.path}/overview`}
          basePath={match.url}
          component={PartnerOverviewPage}
          activeAccount={activeAccount}
          ecosystem={ecosystem}
          partner={partner}
        />
        <PrivateRoute
          path={`${match.path}/settings`}
          basePath={match.url}
          component={PartnerSettingsPage}
          activeAccount={activeAccount}
          ecosystem={ecosystem}
          partner={partner}
        />
      </Switch>
    </ContentContainer>
  )
}

PartnerLayout.propTypes = {
  activeAccount: PropTypes.object.isRequired,
  ecosystem: PropTypes.object.isRequired,
  partner: PropTypes.object.isRequired
}

export default PartnerLayout
