import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { withStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { Card, CardEmptyState, DeleteDialog } from 'components'
import locale from 'locale-codes'
import React from 'react'
import ItemDialog from './Dialog'

class Links extends React.Component {
  state = {
    anchorEl: null
  }

  handleClick = (event, item) => {
    this.setState({
      anchorEl: event.currentTarget,
      item
    })
  }

  handleClose = () => {
    this.setState({ anchorEl: null })
  }

  render() {
    const { anchorEl } = this.state
    const { classes } = this.props
    const items = this.props.items ?? []

    return (
      <Card
        title="Locales"
        button={
          <ItemDialog
            handleSubmit={this.props.handleCreate}
            opener={
              <Button variant="contained" className="fr" color="primary">
                + Add
              </Button>
            }
          />
        }
        style={{ marginTop: '24px' }}
      >
        {items.length > 0 ? (
          <Table className={classes.table} dense="true">
            <TableHead>
              <TableRow>
                <TableCell width={100}>Label</TableCell>
                <TableCell>Language</TableCell>
                <TableCell style={{ textAlign: 'right' }}>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.props.items.map((item) => {
                return (
                  <TableRow key={item.id}>
                    <TableCell>{item.code}</TableCell>
                    <TableCell>{item.code && locale.getByTag(item.code).name}</TableCell>
                    <TableCell>
                      {this.props.items.length > 1 && (
                        <React.Fragment>
                          <Button
                            className={`${item.code}-locale-action fr`}
                            aria-owns={anchorEl ? 'simple-menu' : null}
                            aria-haspopup="true"
                            onClick={(event) => {
                              this.handleClick(event, item)
                            }}
                          >
                            ...
                          </Button>
                          <Menu
                            id="simple-menu"
                            data-testid={`${item.code}-locale-delete`}
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={this.handleClose}
                          >
                            <DeleteDialog
                              handleDelete={this.props.handleDelete}
                              id={this.state.item && this.state.item.id}
                              label={'locale'}
                              opener={<MenuItem>Delete</MenuItem>}
                            />
                          </Menu>
                        </React.Fragment>
                      )}
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        ) : (
          <ItemDialog
            handleSubmit={this.props.handleCreate}
            opener={<CardEmptyState>Add Locale</CardEmptyState>}
          />
        )}
      </Card>
    )
  }
}

export default withStyles({})(Links)
