import Grid from '@material-ui/core/Grid'
import { Card, CardEmptyState, GridCard } from 'components'
import { listingLogo } from 'components/Helpers'
import { differenceWith } from 'lodash'
import truncate from 'lodash/truncate'
import PropTypes from 'prop-types'
import React from 'react'
import getLocalizedValue from 'utils/getLocalizedValue'

class Overview extends React.Component {
  render() {
    const { product, listings, handleListingClick, ecosystem } = this.props
    return (
      <React.Fragment>
        <Card title="Listings in product">
          {product.listings.length > 0 ? (
            <ListingList
              handleClick={handleListingClick}
              listings={product.listings}
              ecosystem={ecosystem}
              active
            />
          ) : (
            <CardEmptyState>Get started by adding listings below to this product.</CardEmptyState>
          )}
        </Card>
        <Card title="Available listings">
          {getAvailableListings(product.listings, listings).length >= 0 ? (
            <ListingList
              handleClick={handleListingClick}
              listings={getAvailableListings(product.listings, listings)}
              ecosystem={ecosystem}
            />
          ) : (
            <CardEmptyState>No available listings.</CardEmptyState>
          )}
        </Card>
      </React.Fragment>
    )
  }
}

const ListingList = ({ listings, handleClick, active, ecosystem }) => (
  <Grid container>
    {listings.map((listing) => (
      <Listing
        listing={listing}
        key={listing.id}
        handleClick={handleClick}
        action={active ? 'Remove' : 'Add'}
        ecosystem={ecosystem}
      />
    ))}
  </Grid>
)

const Listing = ({ listing, handleClick, action, ecosystem }) => (
  <GridCard
    title={getLocalizedValue(listing, 'name', ecosystem.locales)}
    subtitle={
      listing?.category
        ? truncate(getLocalizedValue(listing?.category, 'name', ecosystem.locales), { length: 20 })
        : ''
    }
    imageSrc={listingLogo(listing)}
    handleClick={handleClick}
    id={listing.id}
    action={action}
    small={true}
    style={{ borderRadius: '0' }}
    sm={2}
    xs={12}
  />
)

const getAvailableListings = (listings, allListings) => {
  return differenceWith(allListings, listings, (l1, l2) => l1.id === l2.id).filter(
    ({ state }) => state === 'APPROVED'
  )
}

Overview.propTypes = {
  product: PropTypes.object.isRequired,
  listings: PropTypes.array.isRequired,
  ecosystem: PropTypes.object.isRequired,
  handleListingClick: PropTypes.func.isRequired
}

export default Overview
