import {
  ContentContainer,
  ErrorFallback,
  GridContainer,
  IntegrationTypesCard,
  LayoutHeader,
  SearchesCard
} from 'components'
import React from 'react'

import PagesCard from 'components/ecosystem/dashboard/PagesCard'
import styled from 'react-emotion'

import {
  keenAnalyticsQuery,
  noSearchResultsDashboardQuery,
  searchesDashboardQuery
} from 'api/queries'
import ClicksPerDayCard from 'components/ecosystem/dashboard/ClicksPerDayCard'
import VisitsCard from 'components/ecosystem/dashboard/VisitsCard'
import { useQuery } from 'react-apollo'
import ecosystemUrl from 'utils/ecosystemUrl'

const FilterDiv = styled('div')`
  border-radius: 4px;
  position: relative;

  :before {
    content: '';
    position: absolute;
    pointer-events: none;
    top: 50%;
    margin-top: -0.09em;
    right: 10px;
    width: 0;
    height: 0;
    border: 0.3em solid transparent;
    border-top-color: #464d69;
  }

  select {
    padding: 0.4rem 2rem 0.4rem 1rem;
    box-shadow: 0 1px 15px 1px rgba(69, 65, 78, 0.08);
    background-color: #fff;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    text-transform: capitalize;
  }
`

const FilterDateButton = ({ changePeriod }) => (
  <FilterDiv>
    <select
      name="select"
      id="exampleSelect"
      type="select"
      onChange={(e) => changePeriod(e.target.value)}
      defaultValue={'90'}
    >
      <option value="30">Last 30 days</option>
      <option value="14">Last 14 days</option>
      <option value="7">Last 7 days</option>
    </select>
  </FilterDiv>
)

const Header = ({ changePeriod }) => (
  <LayoutHeader title="Dashboard" right={<FilterDateButton changePeriod={changePeriod} />} />
)

const DashboardPage = ({ ecosystem, activeAccount }) => {
  const [period, setPeriod] = React.useState('30')

  const { loading, error, data } = useQuery(keenAnalyticsQuery, {
    variables: {
      accountId: activeAccount.id,
      ecosystemId: ecosystem.id,
      period
    }
  })

  if (loading) {
    return (
      <ContentContainer>
        <GridContainer>
          <React.Fragment>
            <VisitsCard title="Visits per day" loading />
            <ClicksPerDayCard title="Clicks per day" loading />
            <PagesCard title="Most popular listings" loading />
            <PagesCard title="Most popular categories" loading />
            <PagesCard title="Most popular shadow listings" loading />
          </React.Fragment>
        </GridContainer>
      </ContentContainer>
    )
  }

  if (error) {
    return <ErrorFallback error={error} />
  }

  const {
    keenAnalytics: { pageViewsByDate, pageViewsByPage, clicksByDay }
  } = data

  const popularListings = pageViewsByPage
    .filter((page) => page.pageType === 'LISTING' && page.name)
    .splice(0, 10)

  const popularCategories = pageViewsByPage
    .filter((page) => page.pageType === 'CATEGORY' && page.name)
    .splice(0, 10)

  const shadowListings = pageViewsByPage.filter((page) => page.pageType === 'SHADOW').splice(0, 10)

  return (
    <ContentContainer header={<Header changePeriod={setPeriod} />}>
      <GridContainer>
        <React.Fragment>
          <VisitsCard title="Visits per day" data={pageViewsByDate} />
          <ClicksPerDayCard title="Clicks per day" data={clicksByDay} />
          <PagesCard
            title="Most popular listings"
            data={popularListings}
            ecosystemUrl={ecosystemUrl(ecosystem)}
          />
          <PagesCard
            title="Most popular categories"
            data={popularCategories}
            ecosystemUrl={ecosystemUrl(ecosystem)}
          />
          <PagesCard
            title="Most popular shadow listings"
            data={shadowListings}
            ecosystemUrl={ecosystemUrl(ecosystem)}
          />
        </React.Fragment>

        <SearchesCard
          query={searchesDashboardQuery}
          title="Most popular searches"
          accountId={activeAccount.id}
          ecosystemId={ecosystem.id}
        />

        <SearchesCard
          query={noSearchResultsDashboardQuery}
          title="No result searches"
          accountId={activeAccount.id}
          ecosystemId={ecosystem.id}
        />
        <IntegrationTypesCard accountId={activeAccount.id} ecosystem={ecosystem} />
      </GridContainer>
    </ContentContainer>
  )
}

export default DashboardPage
