import { getListingName, injectTags } from 'components/Helpers'

import Grid from '@material-ui/core/Grid'
import { GridContainer } from 'components'
import React from 'react'
import ecosystemUrl from 'utils/ecosystemUrl'
import styled from 'react-emotion'
import { transparentize } from 'polished'

const EcosystemPreview = ({
  ecosystem,
  primaryColor,
  bodyColor,
  bodyLinkColor,
  bodyBackgroundColor,
  cardColumns,
  cardStyle,
  cardShadowEnabled,
  cardShowCategory,
  cardShowDescription,
  cardDescriptionLines,
  cardBorderRadius,
  website,
  logo,
  favicon,
  mastheadTitle,
  mastheadDescription,
  mastheadColor,
  mastheadBackgroundColor,
  mastheadBackgroundImage,
  mastheadImage,
  mastheadColumns = 1,
  ctaTitle,
  ctaDescription,
  ctaColor,
  ctaBackgroundColor,
  ctaButtonLabel,
  ctaButtonBackgroundColor,
  ctaButtonColor,
  ctaEnabled,
  footerColor,
  footerBackgroundColor,
  menuPosition,
  menuStyle
}) => (
  <Browser>
    <div className="bar">
      <div className="dots" />
      <div className="address">
        {(favicon || website) && (
          <Favicon src={favicon || (website && `//logo.clearbit.com/${website}`)} />
        )}
        <a
          className="url"
          target="_blank"
          id="site-url"
          rel="noopener noreferrer"
          href={ecosystemUrl(ecosystem)}
        >
          {ecosystemUrl(ecosystem)}
        </a>
      </div>
    </div>
    <div className="body">
      <Masthead
        title={injectTags(mastheadTitle, ecosystem)}
        description={injectTags(mastheadDescription, ecosystem)}
        color={mastheadColor}
        backgroundColor={mastheadBackgroundColor}
        backgroundImage={mastheadBackgroundImage}
        image={mastheadImage}
        columns={mastheadColumns}
        logo={logo}
      />
      <Content
        primaryColor={primaryColor}
        bodyColor={bodyColor}
        bodyLinkColor={bodyLinkColor}
        bodyBackgroundColor={bodyBackgroundColor}
        cardColumns={cardColumns}
        cardStyle={cardStyle}
        cardBorderRadius={cardBorderRadius}
        cardShowCategory={cardShowCategory}
        cardShowDescription={cardShowDescription}
        cardDescriptionLines={cardDescriptionLines}
        cardShadowEnabled={cardShadowEnabled}
        menuPosition={menuPosition || 'LEFT'}
        menuStyle={menuStyle || 'LIST'}
        listingName={getListingName(ecosystem)}
      />
      {ctaEnabled && (
        <Cta
          title={ctaTitle}
          description={ctaDescription}
          color={ctaColor}
          backgroundColor={ctaBackgroundColor}
          buttonLabel={ctaButtonLabel}
          buttonBackgroundColor={ctaButtonBackgroundColor}
          buttonColor={ctaButtonColor}
        />
      )}
      <Footer color={footerColor} backgroundColor={footerBackgroundColor} />
    </div>
  </Browser>
)

const Browser = styled('div')`
  margin: 0 auto;
  box-shadow: 0px 10px 40px -10px rgba(24, 65, 107, 0.35);

  > {
    border: 1px solid #fff;
  }

  .bar {
    height: 35px;
    background: #e7edf3;
    border-radius: 4px 4px 0 0;
    display: -webkit-flex;
    display: flex;
    justify-content: flex-end;
    padding: 7px 0 8px;
    position: relative;
    align-items: center;
  }

  .dots {
    position: relative;
    width: 8px;
    height: 8px;
    background: #c7d1da;
    border-radius: 10px;
    margin-right: 5%;
  }

  .dots::before {
    left: 13px;
  }

  .dots::after {
    right: 13px;
  }

  .dots::after,
  .dots::before {
    width: 8px;
    height: 8px;
    background: #c7d1da;
    border-radius: 10px;
    position: absolute;
    content: '';
  }

  .address {
    width: 86%;
    margin-right: 2%;
    height: 100%;
    background: rgba(0, 0, 0, 0.03);
    border-radius: 3px;
    white-space: nowrap;
    font-size: 9px;
    line-height: 20px;
    padding-left: 10px;
    color: #96abb7;
    text-overflow: ellipsis;
    overflow: hidden;
    padding-right: 5px;
  }

  .body {
    border-radius: 0 0 4px 4px;
    overflow: auto;
    padding: 0;
    min-height: 257px;
    font-size: 0.7rem;

    p {
      margin: 0;
      line-height: 1rem;
    }

    h1 {
      font-size: 13px;
      margin-bottom: 0;
    }

    h2 {
      font-size: 12px;
      margin-bottom: 0;
    }

    .searchBox {
      p {
        line-height: 1rem;
        font-size: 0.6rem;
        color: #999;
        text-align: left;
      }
    }

    .sidebar h3 {
      font-size: 0.7rem;
      margin-top: 0;
    }

    .sidebar ul {
      list-style: none;
      margin-left: 0px;
      padding-left: 0;

      li {
        line-height: 17px;
      }
    }

    ul.pills {
      list-style: none;
      margin-left: 0px;
      padding-left: 0;
      display: flex;
      justify-content: center;

      li {
        border-radius: 15px;
        border: 1px solid #ededed;
        line-height: 17px;
        padding: 3px 6px;
        margin-right: 5px;
        font-size: 0.5rem;
      }
    }

    .cards {
      .title {
        color: #444;
      }

      .description,
      .category {
        color: rgba(0, 56, 108, 0.5);
      }

      .description {
        line-height: 12px;
        height: 12px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }

      .description.multi1,
      .description.multi2,
      .description.multi3 {
        text-overflow: initial;
        white-space: normal;
      }

      .description.multi1 {
        height: 12px;
      }

      .description.multi2 {
        height: 24px;
      }

      .description.multi3 {
        height: 36px;
      }

      .action {
        font-size: 0.6rem;
        color: #3199fa;
      }
    }
  }
`
const borderRadius = '2px'

const Button = ({
  backgroundColor = '#FFF',
  color = '#000',
  children,
  textAlign = 'center',
  width = 'auto',
  fontSize = '0.7rem'
}) => (
  <div
    style={{
      padding: '6px 10px',
      display: 'block',
      backgroundColor,
      color,
      borderRadius,
      width,
      textAlign,
      fontSize
    }}
  >
    {children}
  </div>
)

const Search = () => (
  <div
    className="searchBox flex flex-column w-100 justify-center items-center"
    style={{
      width: '200px',
      height: '30px',
      backgroundColor: '#FFF',
      borderRadius,
      margin: '0 auto',
      color: '#000',
      marginTop: '10px'
    }}
  >
    <p>Search service, category, ...</p>
  </div>
)

const MaxWidthContainer = ({ children }) => (
  <div style={{ width: '90%', margin: '0 auto' }}>{children}</div>
)

const Masthead = ({
  backgroundColor,
  backgroundImage,
  color,
  description,
  columns = 1,
  logo,
  title
}) => (
  <Section
    color={color}
    backgroundColor={backgroundColor}
    backgroundImage={backgroundImage}
    label="Masthead"
  >
    <div style={{ height: '150px' }}>
      <Navigation logo={logo} />
      <GridContainer>
        <Grid item sm={12 / columns}>
          <div className="flex flex-column items-center self-middle" style={{ marginTop: '5px' }}>
            <h1>{title}</h1>
            <p>{description}</p>
            <Search />
          </div>
        </Grid>
        {/* {columns === 2 && <Grid item sm={6}>
          <img src={image} />
        </Grid>} */}
      </GridContainer>
    </div>
  </Section>
)

const Navigation = ({ className, logo }) => (
  <div style={{ fontSize: bodyFontSize, width: '100%' }} className={className}>
    <GridContainer>
      <Grid item sm={6}>
        <div className="fl">
          {logo ? (
            <img src={logo} alt="logo" style={{ height: '16px', marginTop: '4px' }} />
          ) : (
            'Logo'
          )}
        </div>
      </Grid>
      <Grid item sm={6}>
        <div className="fr">Navigation items</div>
      </Grid>
    </GridContainer>
  </div>
)

const bodyFontSize = '10px'

const SectionLabel = ({ label, color = '#FFF' }) => (
  <div
    style={{
      position: 'absolute',
      bottom: 0,
      left: 0,
      backgroundColor: `${transparentize(0.8, color)}`,
      borderRadius: '0 3px 0 0',
      padding: '2px 12px',
      fontSize: '8px',
      lineHeight: '12px',
      textTransform: 'uppercase'
    }}
  >
    {label}
  </div>
)

const Section = ({
  backgroundColor,
  backgroundImage,
  children,
  color = '#FFF',
  fontSize = '0.7rem',
  height = '150px',
  label,
  labelColor,
  style
}) => {
  if (backgroundImage) {
    return (
      <div
        style={{
          minHeight: height,
          backgroundColor,
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: 'cover',
          color,
          fontSize,
          ...style
        }}
      >
        <SectionLabel label={label} color={labelColor} />
        <MaxWidthContainer>{children}</MaxWidthContainer>
      </div>
    )
  }

  return (
    <div
      style={{
        minHeight: height,
        position: 'relative',
        backgroundColor,
        textAlign: 'center',
        color,
        fontSize,
        ...style
      }}
    >
      <SectionLabel label={label} color={labelColor} />
      <MaxWidthContainer>{children}</MaxWidthContainer>
    </div>
  )
}

const categoryMenuItems = [
  'CRM',
  'Email marketing',
  'Customer support',
  'Marketing automation',
  'Websites'
]

const collectionMenuItems = ['New', 'Featured']

const Sidebar = ({ primaryColor, menuPosition, listingName }) => {
  const sidebar = <VerticalSidebar primaryColor={primaryColor} listingName={listingName} />

  switch (menuPosition) {
    case 'LEFT':
      return sidebar
    case 'RIGHT':
      return sidebar
    case 'TOP':
      return <Pills items={[...collectionMenuItems, ...categoryMenuItems]} />
    case 'HIDDEN':
      return <div />
    default:
      return <div>Not supported</div>
  }
}

const VerticalSidebar = ({ primaryColor, listingName }) => (
  <div className="sidebar">
    <SidebarBlock title="Collections" items={collectionMenuItems} />
    <SidebarBlock title="Categories" items={categoryMenuItems} />
    <Button backgroundColor={primaryColor} color="#FFF" width="96px" fontSize="0.45rem">
      Request {listingName.singular}
    </Button>
  </div>
)

const SidebarBlock = ({ title, items }) => (
  <div>
    <h3>{title}</h3>
    <ul>
      {items.map((i) => (
        <li key={i}>{i}</li>
      ))}
    </ul>
  </div>
)

const Pills = ({ items }) => (
  <div>
    <ul className="pills">
      {items.map((i) => (
        <li key={i}>{i}</li>
      ))}
    </ul>
  </div>
)

const Content = ({
  primaryColor,
  bodyBackgroundColor = '#FFF',
  bodyColor = '#444',
  bodyLinkColor = 'blue',
  cardColumns,
  cardStyle,
  cardShadowEnabled = false,
  cardBorderRadius = '5px',
  cardShowCategory,
  cardShowDescription,
  cardDescriptionLines,
  menuPosition,
  listingName
}) => (
  <Section
    height="300px"
    color={bodyColor}
    linkColor={bodyLinkColor}
    backgroundColor={bodyBackgroundColor}
    label="Content"
    labelColor="#000"
    style={{ textAlign: 'left', padding: '20px 0' }}
    fontSize="0.6rem"
  >
    <GridContainer>
      {(menuPosition === 'LEFT' || menuPosition === 'TOP') && (
        <Grid item sm={menuPosition !== 'TOP' ? 3 : 12}>
          <Sidebar
            primaryColor={primaryColor}
            menuPosition={menuPosition}
            listingName={listingName}
          />
        </Grid>
      )}
      <Grid item sm={menuPosition !== 'TOP' && menuPosition !== 'HIDDEN' ? 9 : 12}>
        <Cards
          columns={cardColumns}
          style={cardStyle}
          primaryColor={primaryColor}
          borderRadius={cardBorderRadius}
          shadowEnabled={cardShadowEnabled}
          showCategory={cardShowCategory}
          showDescription={cardShowDescription}
          descriptionLines={cardDescriptionLines}
        />
      </Grid>
      {menuPosition === 'RIGHT' && (
        <Grid item sm={3}>
          <Sidebar
            primaryColor={primaryColor}
            menuPosition={menuPosition}
            listingName={listingName}
          />
        </Grid>
      )}
    </GridContainer>
  </Section>
)

const Cta = ({
  title,
  description,
  color,
  backgroundColor,
  buttonColor,
  buttonBackgroundColor,
  buttonLabel
}) => (
  <Section backgroundColor={backgroundColor} color={color} label="CTA">
    <div className="flex flex-column w-100 justify-center items-center" style={{ height: '150px' }}>
      <h2>{title}</h2>
      <p style={{ marginBottom: '5px' }}>{description}</p>
      <Button width="100px" color={buttonColor} backgroundColor={buttonBackgroundColor}>
        {buttonLabel}
      </Button>
    </div>
  </Section>
)

const Footer = ({ backgroundColor, color }) => (
  <Section backgroundColor={backgroundColor} color={color} height="40px" label="Footer">
    Footer
  </Section>
)

const Favicon = ({ src }) => (
  <img src={src} style={{ width: '12px', margin: '4px 5px 0 0' }} className="fl" alt="favicon" />
)

const Cards = ({
  columns = 4,
  style,
  primaryColor,
  borderRadius,
  shadowEnabled,
  showCategory,
  showDescription,
  descriptionLines
}) => (
  <div className="cards">
    <GridContainer>
      {Array(7)
        .fill(1)
        .map((el, i) => (
          <Grid item key={i} sm={columns}>
            <CardWrapper
              borderRadius={borderRadius}
              shadowEnabled={shadowEnabled}
              style={style === 'STYLE11' && { borderRadius: '50px', width: '56px' }}
            >
              {card(style, primaryColor, showCategory, showDescription, descriptionLines)}
            </CardWrapper>
          </Grid>
        ))}
    </GridContainer>
  </div>
)

const CardWrapper = ({ borderRadius, shadowEnabled, style, children }) => (
  <div
    className="card w-100"
    style={{
      border: shadowEnabled ? 'none' : 'thin solid #e9ebed',
      boxShadow: shadowEnabled && '0 4px 8px 0 rgba(0,0,0,0.05)',
      backgroundColor: '#FFF',
      borderRadius: borderRadius,
      textAlign: 'center',
      overflow: 'hidden',
      ...style
    }}
  >
    {children}
  </div>
)

const DESCRIPTION = 'The listing tag line goes here. More content will be shown.'

const card = (type, primaryColor, showCategory, showDescription, descriptionLines) => {
  switch (type) {
    case 'STYLE1':
      return (
        <CardStyle1
          title="Title"
          description={DESCRIPTION}
          showCategory={showCategory}
          showDescription={showDescription}
          descriptionLines={descriptionLines}
        />
      )
    case 'STYLE2':
      return (
        <CardStyle2
          title="Title"
          description={DESCRIPTION}
          showCategory={showCategory}
          showDescription={showDescription}
          descriptionLines={descriptionLines}
        />
      )
    case 'STYLE3':
      return <CardStyle1 title="Title" />
    case 'STYLE4':
      return <CardStyle1 />
    case 'STYLE5':
      return <CardStyle1 title="Title" />
    case 'STYLE6':
      return (
        <CardStyle3
          title="Title"
          description={DESCRIPTION}
          showCategory={showCategory}
          showDescription={showDescription}
          descriptionLines={descriptionLines}
        />
      )
    case 'STYLE7':
      return (
        <CardStyle4
          title="Title"
          description={DESCRIPTION}
          showCategory={showCategory}
          showDescription={showDescription}
          descriptionLines={descriptionLines}
          primaryColor={primaryColor}
        />
      )
    case 'STYLE8':
      return (
        <CardStyle5
          title="Title"
          description={DESCRIPTION}
          showCategory={showCategory}
          showDescription={showDescription}
          descriptionLines={descriptionLines}
          primaryColor={primaryColor}
        />
      )
    case 'STYLE10':
      return <CardStyle7 />
    case 'STYLE11':
      return <CardStyle6 />
    default:
      return <CardStyle1 title="Title" description="Description" />
  }
}

const TextBlock = ({
  title,
  showCategory,
  showDescription,
  descriptionLines,
  description,
  style
}) => (
  <div className="text" style={style}>
    {showCategory && <p className="category">Category</p>}
    {title && <p className="title">{title}</p>}
    {showDescription && <p className={`description multi${descriptionLines}`}>{description}</p>}
    {/* <p className='action'>View details</p> */}
  </div>
)

const CardStyle1 = ({ title, description, showCategory, showDescription, descriptionLines }) => (
  <div style={{ padding: '15px' }}>
    <BlockIcon />
    <TextBlock
      title={title}
      description={description}
      showCategory={showCategory}
      showDescription={showDescription}
      descriptionLines={descriptionLines}
    />
  </div>
)

const CardStyle2 = ({ title, description, showCategory, showDescription, descriptionLines }) => (
  <div className="card w-100 flex" style={{ padding: '10px 15px' }}>
    <BlockIconLeft />
    <TextBlock
      title={title}
      description={description}
      showCategory={showCategory}
      showDescription={showDescription}
      descriptionLines={descriptionLines}
      style={{ textAlign: 'left', marginLeft: '10px' }}
    />
  </div>
)

const CardStyle3 = ({ title, description, showCategory, showDescription, descriptionLines }) => (
  <div style={{ padding: '15px', textAlign: 'left' }}>
    <CircleIcon />
    <TextBlock
      title={title}
      description={description}
      showCategory={showCategory}
      showDescription={showDescription}
      descriptionLines={descriptionLines}
    />
  </div>
)

const CardStyle4 = ({
  title,
  description,
  primaryColor,
  showCategory,
  showDescription,
  descriptionLines
}) => (
  <React.Fragment>
    <div
      className="w-100"
      style={{
        backgroundColor: primaryColor,
        padding: '15px',
        textAlign: 'center',
        color: '#FFF'
      }}
    >
      <p>Icon</p>
    </div>
    <div
      className="w-100"
      style={{
        padding: '15px'
      }}
    >
      <TextBlock
        title={title}
        description={description}
        showCategory={showCategory}
        showDescription={showDescription}
        descriptionLines={descriptionLines}
      />
    </div>
  </React.Fragment>
)

const CardStyle5 = ({
  title,
  description,
  primaryColor,
  showCategory,
  showDescription,
  descriptionLines
}) => (
  <React.Fragment>
    <div
      className="w-100"
      style={{
        backgroundColor: primaryColor,
        padding: '15px',
        height: '56px'
      }}
    >
      <BlockIcon style={{ marginTop: '30px' }} />
    </div>
    <div
      className="w-100"
      style={{
        padding: '15px'
      }}
    >
      <TextBlock
        title={title}
        description={description}
        showCategory={showCategory}
        showDescription={showDescription}
        descriptionLines={descriptionLines}
      />
    </div>
  </React.Fragment>
)

const CardStyle6 = () => (
  <div style={{ padding: '15px' }}>
    <CircleIcon style={{ margin: '0 auto' }} />
  </div>
)

const CardStyle7 = ({ title, description, showCategory, showDescription, descriptionLines }) => (
  <div style={{ padding: '15px' }}>
    <BlockIcon style={{ width: '80%' }} />
    <TextBlock
      title={title}
      description={description}
      showCategory={showCategory}
      showDescription={showDescription}
      descriptionLines={descriptionLines}
    />
  </div>
)

const CircleIcon = ({ style }) => (
  <div
    className="blockIcon"
    style={{
      width: '24px',
      height: '24px',
      backgroundColor: '#EEE',
      borderRadius: '24px',
      marginBottom: '5px',
      ...style
    }}
  />
)

const BlockIcon = ({ style }) => (
  <div
    className="blockIcon"
    style={{
      width: '24px',
      height: '24px',
      minWidth: '24px',
      backgroundColor: '#EEE',
      borderRadius: '2px',
      margin: '0 auto',
      ...style
    }}
  />
)

const BlockIconLeft = () => (
  <div
    className="blockIcon self-start"
    style={{
      width: '24px',
      height: '24px',
      minWidth: '24px',
      backgroundColor: '#EEE',
      borderRadius: '2px'
    }}
  />
)

export default EcosystemPreview
