import { SelectIntegrationInput } from 'components'
import { getListingName } from 'components/Helpers'
import React, { useState } from 'react'
import slugify from 'slugify'

const RequestsFilter = ({ services = [], service, handleChange, ecosystem }) => {
  const [value, setValue] = useState(service)

  const options = services?.map((name) => {
    return {
      id: name,
      label: name,
      value: name,
      icon: `https://res.cloudinary.com/apideck/icons/${slugify(name).toLowerCase()}`
    }
  })

  return (
    <SelectIntegrationInput
      placeholder={`Filter by ${getListingName(ecosystem).label}`}
      field="service_filter"
      value={value}
      height={40}
      isClearable={true}
      options={options}
      handleChange={(name) => {
        setValue(name)
        handleChange(name)
      }}
    />
  )
}

export default RequestsFilter
