import { AllListingsPage, ListingsList } from 'features/ecosystem/listings/all'
import { AutomatedContainer, RequestsContainer } from 'pages'
import {
  MarketplaceCategoriesContainer as CategoriesContainer,
  CategoryContainer,
  CollectionContainer,
  MarketplaceCollectionsContainer as CollectionsContainer,
  ListingAddContainer,
  ListingContainer,
  ListingCreateContainer,
  ProductContainer,
  EcosystemProductsContainer as ProductsContainer
} from 'containers'
import { ContentContainer, NotFound } from 'components'
import React, { useState } from 'react'
import { Redirect, Route, Switch, withRouter } from 'react-router-dom'

import Header from 'components/ecosystem/listings/Header'
import { NetworkPage } from 'features/network'
import PrivateRoute from 'components/PrivateRoute'
import PropTypes from 'prop-types'
import { setItem } from 'utils/localstorage'
import { MaxListingsProvider } from 'context/useMaxListingsContext'

const ListingsRoutes = ({ activeAccount, ecosystem, user, match }) => {
  setItem('lastProductUsed', 'ecosystem')

  return (
    <MaxListingsProvider ecosystem={ecosystem} activeAccount={activeAccount}>
      <Switch>
        <Redirect exact from={`${match.path}/`} to={`${match.path}/overview`} />
        <PrivateRoute
          exact
          ecosystem={ecosystem}
          path={`${match.path}/add`}
          component={NetworkPage}
          activeAccount={activeAccount}
        />
        <PrivateRoute
          path={`${match.path}/add/:service`}
          component={ListingAddContainer}
          ecosystem={ecosystem}
          activeAccount={activeAccount}
        />
        <PrivateRoute
          path={`${match.path}/create`}
          activeAccount={activeAccount}
          marketplace={ecosystem}
          component={ListingCreateContainer}
        />
        <PrivateRoute
          path={`${match.path}/collections/:collectionId`}
          component={CollectionContainer}
          activeAccount={activeAccount}
          user={user}
          ecosystem={ecosystem}
        />
        <PrivateRoute
          path={`${match.path}/categories/:categoryId`}
          component={CategoryContainer}
          activeAccount={activeAccount}
          user={user}
          ecosystem={ecosystem}
        />
        <PrivateRoute
          path={`${match.path}/products/:productId`}
          component={ProductContainer}
          activeAccount={activeAccount}
          user={user}
          ecosystem={ecosystem}
        />
        <Route
          path={[
            `${match.path}/overview`,
            `${match.path}/categories`,
            `${match.path}/collections`,
            `${match.path}/products`,
            `${match.path}/automated`
          ]}
        >
          <WithHeader ecosystem={ecosystem} activeAccount={activeAccount} />
        </Route>
        <PrivateRoute
          path={`${match.path}/:listingId`}
          component={ListingContainer}
          activeAccount={activeAccount}
          ecosystem={ecosystem}
          user={user}
          key={`${Math.random()}`}
        />
      </Switch>
    </MaxListingsProvider>
  )
}

const WithHeader = ({ activeAccount, ecosystem }) => {
  const [activePath, setActivePath] = useState('')

  return (
    <ContentContainer header={<Header activePath={activePath} ecosystem={ecosystem} />}>
      <Switch>
        <PrivateRoute
          path="/ecosystems/:ecosystemId/listings/overview/list"
          component={ListingsList}
          activeAccount={activeAccount}
          ecosystem={ecosystem}
        />
        <PrivateRoute
          path="/ecosystems/:ecosystemId/listings/overview"
          component={AllListingsPage}
          activeAccount={activeAccount}
          ecosystem={ecosystem}
        />
        <Route path="/ecosystems/:ecosystemId/listings/categories">
          <CategoriesContainer activeAccount={activeAccount} ecosystem={ecosystem} />
        </Route>
        <Route path="/ecosystems/:ecosystemId/listings/collections">
          <CollectionsContainer activeAccount={activeAccount} ecosystem={ecosystem} />
        </Route>
        <Route path="/ecosystems/:ecosystemId/listings/products">
          <ProductsContainer activeAccount={activeAccount} ecosystem={ecosystem} />
        </Route>
        <PrivateRoute
          path="/ecosystems/:ecosystemId/listings/automated"
          component={AutomatedContainer}
          activeAccount={activeAccount}
          ecosystem={ecosystem}
          setActivePath={setActivePath}
        />
        <PrivateRoute
          path="/ecosystems/:ecosystemId/listings/requests"
          component={RequestsContainer}
          setActivePath={setActivePath}
          activeAccount={activeAccount}
        />
        {/*
          fallback route
          TODO: redirect to dedicated 404 page (to be created)
        */}
        <Route component={NotFound} />
      </Switch>
    </ContentContainer>
  )
}

ListingsRoutes.propTypes = {
  activeAccount: PropTypes.object.isRequired,
  ecosystem: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired
}

export default withRouter(ListingsRoutes)
