import React from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import TemplateOverview from './Overview'
import TemplateContainer from './Container'
import uploadFile from 'utils/fileUpload'
import transformError from 'utils/transformError'
import { toastr } from 'react-redux-toastr'
import { darken } from 'polished'
import parseColor from 'parse-color'
import { CardAlert } from 'components'

async function buildVariables({
  activeAccount,
  cardColumns,
  cardStyle,
  ecosystem,
  menuPosition,
  menuStyle,
  logo,
  primaryColor
}) {
  const parsedColor = parseColor(primaryColor)
  const primary = parsedColor.hex && parsedColor.hex.length === 7 ? parsedColor.hex : '#000000'

  let variables = {
    accountId: activeAccount.id,
    id: ecosystem.id,
    name: ecosystem.name,
    slug: ecosystem.slug,
    primaryColor: primary,
    mastheadColor: '#FFFFFF',
    mastheadBackgroundColor: primary,
    ctaColor: '#FFFFFF',
    ctaBackgroundColor: primary,
    ctaButtonColor: primary,
    ctaButtonBackgroundColor: '#FFFFFF',
    alternativesColor: '#444',
    alternativesBackgroundColor: '#F5F8FA',
    footerColor: '#FFFFFF',
    footerBackgroundColor: darken(0.8, primary),
    cardStyle: cardStyle,
    cardColumns: cardColumns,
    menuPosition: menuPosition,
    menuStyle: menuStyle
  }

  if (logo.length !== 0 && logo[0].preview) {
    let result = await uploadFile(logo[0], {
      params: {
        folder: `ecosystems`
      }
    })

    variables = Object.assign(
      {
        logo: {
          url: result.url,
          contentType: `${result.resourceType}/${result.format}`
        }
      },
      variables
    )
  }

  return variables
}

function TemplatePage(props) {
  const { ecosystem, activeAccount, history } = props

  let logo = []

  if (ecosystem.logo) {
    logo = [ecosystem.logo]
  } else if (ecosystem.icon) {
    logo = [ecosystem.icon]
  }

  let favicon = []

  if (ecosystem && ecosystem.favicon) {
    favicon = [ecosystem.favicon]
  } else if (ecosystem.icon) {
    favicon = [ecosystem.icon]
  }

  return (
    <TemplateContainer accountId={activeAccount.id}>
      {({ updateTemplateSettings }) => {
        const handleSubmit = async (input, { setSubmitting, setErrors }) => {
          setSubmitting(true)

          try {
            const variables = await buildVariables({
              activeAccount,
              ecosystem,
              ...input
            })

            const data = await updateTemplateSettings.mutation({ variables })

            toastr.success(`${data.data.updateMarketplace.name} has been set up`)
            history.push(`/ecosystems/${data.data.updateMarketplace.id}/listings/overview`)
          } catch (error) {
            if (error.graphQLErrors) {
              setErrors(transformError(error.graphQLErrors))
            }

            setSubmitting(false)
          }
        }

        return (
          <div>
            <CardAlert.Default>
              <p>
                Style your ecosystem, so it fits your current marketing website. Extended template
                options will be available after the setup.
              </p>
            </CardAlert.Default>
            <TemplateOverview
              ecosystem={ecosystem}
              handleSubmit={handleSubmit}
              initialValues={{
                cardColumns: ecosystem.cardColumns || '',
                cardStyle: ecosystem.cardStyle || '',
                menuPosition: ecosystem.menuPosition || '',
                favicon,
                logo,
                primaryColor: ecosystem.primaryColor || '#6f56fc'
              }}
            />
          </div>
        )
      }}
    </TemplateContainer>
  )
}

TemplatePage.propTypes = {
  activeAccount: PropTypes.object.isRequired,
  ecosystem: PropTypes.object.isRequired
}

export default withRouter(TemplatePage)
