import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import {
  CollectionNavigation,
  HeaderWithTabs,
  HeaderTitle,
  PreviewButton,
  SecondaryButton
} from 'components'
import ecosystemUrl from 'utils/ecosystemUrl'
import getLocalizedValue from 'utils/getLocalizedValue'

const CollectionHeaderWrapper = ({ collection, match, history, ecosystem }) => (
  <HeaderWithTabs tabs={<CollectionNavigation match={match} />}>
    <HeaderTitle
      title={getLocalizedValue(collection, 'name', ecosystem.locales)}
      imageSrc={collection.logo ? collection.logo.url : null}
      subtitle={getLocalizedValue(collection, 'description', ecosystem.locales) || 'Collection'}
    />
    <SecondaryButton className="fr" onClick={history.goBack}>
      Go Back
    </SecondaryButton>
    <PreviewButton href={`${ecosystemUrl(ecosystem)}/collection/${collection.slug}`} />
  </HeaderWithTabs>
)

CollectionHeaderWrapper.propTypes = {
  collection: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired
}

const CollectionHeader = withRouter(CollectionHeaderWrapper)

export default CollectionHeader
