import Grid from '@material-ui/core/Grid'
import React from 'react'

const GridContainer = ({ children }) => (
  <Grid container spacing={2}>
    {children}
  </Grid>
)

export default GridContainer
