import React, { Fragment } from 'react'
import styled from 'react-emotion'
import { withRouter } from 'react-router-dom'

const Container = styled('div')`
  color: var(--color-white);
  line-height: 1.6;

  div {
    font-size: 24px;
  }

  h3 {
    font-weight: 400;
    font-size: 20px;
    margin: 0;
  }

  div.go-back-link {
    cursor: pointer;
    display: inline-block;
    margin-top: 40px;
    color: var(--color-primary-lighter);
    font-size: 16px;
    font-weight: 500;
  }
`

const CreateSidebarContent = ({ hasEcosystem, history }) => {
  const handleClick = () => {
    history.goBack()
  }

  return (
    <Container>
      {hasEcosystem ? (
        <Fragment>
          <h3>{`Let's create another ecosystem!`}</h3>
          <div className="go-back-link" onClick={() => handleClick()}>
            &laquo; Go back
          </div>
        </Fragment>
      ) : (
        <Fragment>
          <div>
            <span role="img" aria-label="OK">
              👌
            </span>
          </div>
          <h3>{`Let's set up your account.`}</h3>
        </Fragment>
      )}
    </Container>
  )
}

export default withRouter(CreateSidebarContent)
