import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { useEcosystemContext } from 'context'
import PropTypes from 'prop-types'
import React, { Fragment } from 'react'
import getRedirectUri from 'utils/redirectUri'

const useStyles = makeStyles((theme) => ({
  card: {
    marginTop: theme.spacing(2),
    backgroundColor: theme.palette.ui[100],
    border: 'none',
    padding: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  connected: {
    color: theme.palette.primary.main,
    textTransform: 'uppercase',
    fontSize: '13px',
    fontWeight: 500
  },
  notConnected: {
    color: theme.palette.ui[400],
    textTransform: 'uppercase',
    fontSize: '13px',
    fontWeight: 500
  }
}))

const OAuthCard = ({ connection }) => {
  const classes = useStyles()
  const { ecosystem } = useEcosystemContext()
  const { id, authorizeUrl, revokeUrl } = connection
  const isConnected = !!revokeUrl
  const queryString = `modal=${id}`
  const authorizeUrlWithRedirect = getRedirectUri(authorizeUrl, ecosystem, queryString)
  let revokeUrlWithRedirect
  if (isConnected) {
    revokeUrlWithRedirect = getRedirectUri(revokeUrl, ecosystem, queryString)
  }

  return (
    <Card className={classes.card} variant="outlined">
      <div>
        {isConnected ? (
          <Typography className={classes.connected}>Connected</Typography>
        ) : (
          <Typography className={classes.notConnected}>Not connected</Typography>
        )}
      </div>
      <div className={classes.buttonsContainer}>
        {isConnected ? (
          <Fragment>
            <Button
              variant="contained"
              color="primary"
              href={authorizeUrlWithRedirect}
              style={{ marginRight: '10px' }}
            >
              Re-Authorize
            </Button>
            <Button variant="outlined" href={revokeUrlWithRedirect}>
              Disconnect
            </Button>
          </Fragment>
        ) : (
          <Button variant="contained" color="primary" href={authorizeUrlWithRedirect}>
            Authorize
          </Button>
        )}
      </div>
    </Card>
  )
}

OAuthCard.propTypes = {
  connection: PropTypes.shape({
    authorizeUrl: PropTypes.string,
    revokeUrl: PropTypes.string
  }).isRequired
}

export default OAuthCard
