import { Global, css } from '@emotion/core'
import React, { Fragment, useState } from 'react'

import PropTypes from 'prop-types'
import SignupForm from './form/Form'
import SocialButtons from '../common/buttons/SocialNew'
import { media } from 'styles/styles'
import styled from 'react-emotion'

const copyVariations = (product) => {
  if (product === 'ecosystem') {
    return {
      h1: 'Showcase integrations, apps, and partners.',
      h2: 'Join the 400+ companies who have chosen to scale their ecosystem strategy with Apideck.',
      features: <EcosystemFeatures />,
      connectors: 'Integrated with the tools you already use',
      planInfo: (
        <div className="tagline" style={{ marginTop: '50px' }}>
          Start your <span>free 14 days</span> trial.
        </div>
      )
    }
  } else {
    return {
      h1: 'Integrate and monitor APIs 💯 times faster',
      h2: 'Join the 2500+ developers who have chosen to build with Apideck.',
      connectors: 'Out-of-the-box support for the APIs you need',
      planInfo: (
        <div className="tagline" style={{ marginTop: '50px' }}>
          No credit card needed. Includes <span>5,000 free API calls</span> on the developer plan.
        </div>
      )
    }
  }
}

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;

  h1 {
    margin-top: 150px;
  }

  h2 {
    margin-bottom: 0px;
    font-weight: 400;
    font-size: 20px;
  }

  h1,
  h2 {
    text-align: center;
  }

  ul.features {
    list-style: none;
    margin: auto;
    margin-top: 40px;
    padding-left: 120px;
    color: var(--text-color);
    font-size: 15px;

    li {
      margin-bottom: 8px;
    }
  }

  .tagline {
    margin-bottom: 20px;
    color: var(--color-text-lighter);
    font-size: 18px;

    span {
      color: var(--color-primary);
      font-weight: 500;
    }
  }

  .form-container {
    text-align: center;
  }

  .integrations {
    width: 100%;
  }

  .integrations p {
    text-align: center;
  }

  .connector-images {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    margin: 10px 20px;
  }

  input {
    background-color: #fff;
  }

  .connector-images img {
    max-height: 60px;
    padding: 12px;
  }

  .continue-with-email-container {
    font-size: 14px;
    color: #444587;

    a {
      color: #444587;
      text-decoration: underline;

      &:hover {
        color: var(--color-primary);
      }
    }
  }

  footer {
    max-width: 530px;
    margin: 0 auto;
    color: var(--color-text-lighter);
    font-size: 12px;
    line-height: 1.4;
    text-align: center;

    a {
      color: var(--color-text-lighter);
      text-decoration: underline;
    }
  }

  @media screen and (max-width: ${media.medium}) {
    ul.features {
      display: none;
    }

    .connector-images {
      display: block;
      align-items: center;
    }

    h2 {
      margin-bottom: 24px;
    }
  }

  @media screen and (max-height: 767px) {
    ul.features {
      display: none;
    }
  }
`

const SignupContent = ({
  handleUsernamePasswordSubmit,
  handleGoogleSubmit,
  handleGithubSubmit,
  initialValues,
  product
}) => {
  const [signupWithSocial, setSignupWithSocial] = useState(true)
  const handleSwitch = (e) => {
    e.preventDefault()
    setSignupWithSocial(!signupWithSocial)
  }

  const copy = copyVariations(product)

  return (
    <Container>
      <Global
        styles={css`
          body {
            background-image: url('./images/dot-grid.webp');
          }
        `}
      />
      <div>
        <h1>{copy.h1}</h1>
        <h2>{copy.h2}</h2>
        {copy.features}
      </div>
      {copy.planInfo}
      <div className="form-container">
        {signupWithSocial && (
          <Fragment>
            <SocialButtons
              handleGoogleSubmit={handleGoogleSubmit}
              handleGithubSubmit={handleGithubSubmit}
              action="Sign up"
            />
          </Fragment>
        )}
        {!signupWithSocial && (
          <SignupForm onSubmit={handleUsernamePasswordSubmit} initialValues={initialValues} />
        )}
        <div className="continue-with-email-container">
          You can also{' '}
          <a href="#continue" onClick={(e) => handleSwitch(e)} data-testid={'signup-with-email'}>
            {signupWithSocial ? 'continue with email' : 'continue with Google or GitHub'}
          </a>{' '}
          or <a href="/login">Login</a>
        </div>
      </div>
      <div className="integrations">
        <p>{copy.connectors}</p>
        <div className="connector-images">
          <img src="/images/logos/googledrive.png" alt="google drive connector" />
          <img src="/images/logos/hubspot.png" alt="hubspot connector" />
          <img src="/images/logos/quickbooks.png" alt="quickbooks connector" />
          <img
            src="/images/logos/bamboohr.png"
            alt="bamboohr connector"
            style={{ maxHeight: 55 }}
          />
          <img src="/images/logos/xero.png" alt="xero connector" />
        </div>
      </div>
      <footer>
        By clicking “Continue with Google/GitHub/email” above, you acknowledge that you have read
        and understood, and agree to our{' '}
        <a
          href="https://compliance.apideck.com/privacy-policy"
          target="_blank"
          rel="noopener noreferrer"
        >
          Privacy Policy
        </a>{' '}
        and{' '}
        <a
          href="https://termsfeed.com/terms-conditions/957c85c1b089ae9e3219c83eff65377e"
          target="_blank"
          rel="noopener noreferrer"
        >
          Terms and Conditions
        </a>
        .
      </footer>
    </Container>
  )
}

const EcosystemFeatures = () => (
  <ul className="features">
    <li>
      <span role="img" aria-label="nail polish">
        💅
      </span>
      &nbsp;&nbsp; Showcase your integrations on a fully customizable ecosystem website
    </li>
    <li>
      <span role="img" aria-label="target">
        🎯
      </span>
      &nbsp;&nbsp; Boost the ROI and SEO of your integrations by the autogenerated landing pages
    </li>
    <li>
      <span role="img" aria-label="brain">
        🧠
      </span>
      &nbsp;&nbsp; Import +15.000 SaaS listings data as integrations from our Apideck Network
    </li>
    <li>
      <span role="img" aria-label="heart">
        💖
      </span>
      &nbsp;&nbsp; Collect leads and intent data through the request integrations form
    </li>
  </ul>
)

SignupContent.propTypes = {
  handleUsernamePasswordSubmit: PropTypes.func.isRequired,
  handleGoogleSubmit: PropTypes.func.isRequired
}

export default SignupContent
