import { deleteListingMutation, importServiceMutation } from 'api/queries'
import { ErrorFallback, LoadingPlaceholder } from 'components'
import { useMaxListingsContext } from 'context/useMaxListingsContext'
import gql from 'graphql-tag'
import React from 'react'
import { useMutation, useQuery } from 'react-apollo'
import { toastr } from 'react-redux-toastr'
import Network from './Network'

const listingsQuery = gql`
  query marketplace($id: ID!, $accountId: ID!) {
    marketplace(id: $id, accountId: $accountId) {
      id
      listings {
        id
        slug
      }
      categories {
        id
        slug
        name
        translations {
          id
          key
          value
          locale
        }
      }
    }
  }
`

const NetworkWithHandlers = ({
  ecosystem,
  hasMore,
  loading,
  loadNextPage,
  searching,
  services,
  onIntegrationFilterChange,
  onTextFilterChange,
  total,
  activeAccount
}) => {
  const { recalculateMaxListings } = useMaxListingsContext()
  const {
    loading: listingsQueryLoading,
    error,
    data
  } = useQuery(listingsQuery, {
    variables: {
      id: ecosystem.id,
      accountId: activeAccount.id
    }
  })
  const [importService] = useMutation(importServiceMutation, {
    refetchQueries: ['marketplace']
  })
  const [deleteListing] = useMutation(deleteListingMutation, {
    refetchQueries: ['marketplace']
  })

  if (listingsQueryLoading) {
    return <LoadingPlaceholder />
  }

  if (error) {
    return <ErrorFallback error={error} />
  }

  const { marketplace } = data

  const handleDelete = async (service) => {
    await deleteListing({
      variables: {
        accountId: activeAccount.id,
        id: service.listingId
      }
    })
    // check if max limit is not reached
    await recalculateMaxListings()

    // Inform user
    toastr.success(`${service.name} has been removed`)
  }

  return (
    <Network
      handleIntegrationFilterChange={onIntegrationFilterChange}
      handleTextFilterChange={onTextFilterChange}
      hasMore={hasMore}
      loading={loading}
      loadNextPage={loadNextPage}
      searching={searching}
      services={services}
      ecosystem={ecosystem}
      listings={marketplace.listings}
      categories={marketplace.categories}
      total={total}
      handleDelete={handleDelete}
      importService={importService}
      activeAccount={activeAccount}
    />
  )
}

export default NetworkWithHandlers
