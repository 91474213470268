import React from 'react'
import { SketchPicker } from 'react-color'
import styled from 'react-emotion'
import { Small } from 'components'

class ColorPicker extends React.Component {
  state = {
    value:
      this.props.formikProps.values[this.props.field] !== null
        ? this.props.formikProps.values[this.props.field]
        : '',
    displayColoPicker: false
  }

  handleColorChange = ({ hex }) => {
    this.props.formikProps.setFieldValue(this.props.field, hex)

    this.setState({
      value: hex
    })
  }

  handleClick = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker })
  }

  handleClose = () => {
    this.setState({ displayColorPicker: false })
  }

  render() {
    const { field, formikProps, label, type, description, ...rest } = this.props
    const { handleChange, handleBlur, values, touched, errors } = formikProps

    return (
      <div className="form-element">
        <label htmlFor={field}>{label}</label>
        <InputGroup>
          <input
            id={field}
            name={field}
            value={values[field]}
            onChange={handleChange}
            onBlur={handleBlur}
            type={type || 'text'}
            pattern="^#(([0-9a-fA-F]{2}){3}|([0-9a-fA-F]){3})$"
            title="Enter a valid HEX color code. e.g. #16263F"
            {...rest}
          />
          <span className="input-group-addon">
            <i
              onClick={this.handleClick}
              style={{
                backgroundColor: this.props.formikProps.values[this.props.field]
              }}
            />
            {this.state.displayColorPicker && (
              <div className="popover">
                <div className="cover" onClick={this.handleClose} />
                <SketchPicker color={this.state.value} onChangeComplete={this.handleColorChange} />
              </div>
            )}
          </span>
        </InputGroup>
        <Small>{description}</Small>
        {touched[field] && errors[field] && <span className="errors">{errors[field]}</span>}
      </div>
    )
  }
}

const InputGroup = styled('div')`
  position: relative;
  display: table;
  border-collapse: separate;

  .input-group-addon i {
    display: inline-block;
    cursor: pointer;
    height: 16px;
    vertical-align: text-top;
    width: 16px;
  }

  .input-group-addon {
    padding: 6px 12px;
    font-size: 14px;
    font-weight: 400;
    line-height: 1;
    color: #555;
    text-align: center;
    background-color: #eee;
    border: 1px solid #ccc;
    border-radius: 4px;
  }

  input,
  .input-group-addon,
  .input-group-btn {
    display: table-cell;
  }

  input {
    margin-bottom: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: 1px;
    width: 250px;
  }

  .input-group-addon {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .popover {
    position: absolute;
    right: -64px;
    top: 48px;
    z-index: 2;
  }

  .cover {
    position: fixed;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
  }
`

export default ColorPicker
