import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import Avatar from '@material-ui/core/Avatar'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace'
import { makeStyles } from '@material-ui/core/styles'
import { IconLabelButton, IconButton } from 'components'

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    padding: theme.spacing(4, 6),
    borderBottom: `1px solid ${theme.palette.ui[200]}`,
    '& > div': {
      flex: 1
    }
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  title: {
    fontSize: '20px',
    fontWeight: 500,
    color: theme.palette.ui[800]
  },
  subheader: {
    color: theme.palette.ui[300],
    fontSize: '14px'
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  avatar: {
    marginRight: theme.spacing(2)
  }
}))

const SettingsModalSubView = ({
  icon,
  name,
  title,
  backAction,
  closeAction,
  subheader,
  children
}) => {
  const classes = useStyles()

  return (
    <Fragment>
      <div className={classes.header}>
        <div>
          <IconLabelButton onClick={() => backAction()} startIcon={<KeyboardBackspaceIcon />}>
            Back
          </IconLabelButton>
        </div>
        <div className={classes.titleContainer}>
          <Avatar className={classes.avatar} src={icon} aria-label={name} variant="rounded">
            {name.substring(0, 1)}
          </Avatar>
          <div>
            <Typography className={classes.title}>{title}</Typography>
            {subheader && <Typography className={classes.subheader}>{subheader}</Typography>}
          </div>
        </div>
        <div className={classes.actions}>
          <Tooltip title="Close" placement="top" arrow>
            <div>
              <IconButton onClick={() => closeAction()}>
                <CloseIcon style={{ fontSize: 24 }} />
              </IconButton>
            </div>
          </Tooltip>
        </div>
      </div>
      {children}
    </Fragment>
  )
}

SettingsModalSubView.defaultProps = {
  icon: null,
  name: null,
  subheader: null
}

SettingsModalSubView.propTypes = {
  icon: PropTypes.string,
  name: PropTypes.string,
  title: PropTypes.string.isRequired,
  backAction: PropTypes.func.isRequired,
  closeAction: PropTypes.func.isRequired,
  subheader: PropTypes.string
}

export default SettingsModalSubView
