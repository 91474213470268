import React from 'react'
import PropTypes from 'prop-types'
import PrivateRoute from 'components/PrivateRoute'
import { Switch, withRouter } from 'react-router-dom'
import { CollectionHeader, ContentContainer } from 'components'
import {
  CollectionOverviewContainer as OverviewContainer,
  CollectionSettingsContainer as SettingsContainer
} from 'containers'

const CollectionLayout = ({ match, collection, activeAccount, listings, ecosystem }) => (
  <ContentContainer header={<CollectionHeader collection={collection} ecosystem={ecosystem} />}>
    <Switch>
      <PrivateRoute
        path={`${match.path}/overview`}
        component={OverviewContainer}
        collection={collection}
        activeAccount={activeAccount}
        ecosystem={ecosystem}
        listings={listings}
      />
      <PrivateRoute
        path={`${match.path}/settings`}
        component={SettingsContainer}
        collection={collection}
        activeAccount={activeAccount}
        ecosystem={ecosystem}
      />
    </Switch>
  </ContentContainer>
)

CollectionLayout.propTypes = {
  collection: PropTypes.object.isRequired,
  activeAccount: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  listings: PropTypes.array.isRequired
}

const CollectionCollectionLayout = withRouter(CollectionLayout)

export default CollectionCollectionLayout
