import { addListingToCategoryMutation, removeListingFromCategoryMutation } from 'api/queries'
import { CategoryOverview as Overview } from 'components'
import some from 'lodash/some'
import { categoryOverviewQuery } from 'pages/private/ecosystem/listings/categories/graphql'
import React from 'react'
import { graphql } from 'react-apollo'
import { toastr } from 'react-redux-toastr'
import { withRouter } from 'react-router-dom'
import { compose, withHandlers } from 'recompose'

// TODO move to pages folder

class Container extends React.Component {
  render() {
    return (
      <Overview
        ecosystem={this.props.ecosystem}
        category={this.props.category}
        handleListingClick={this.props.handleListingClick}
        listings={this.props.listings}
      />
    )
  }
}

const handleListingClick = withHandlers({
  handleListingClick:
    ({ addListingToCategory, removeListingFromCategory, activeAccount, category, match }) =>
    async (id) => {
      try {
        const variables = {
          accountId: activeAccount.id,
          id: category.id,
          listingId: id
        }

        const refetchQueries = [
          {
            query: categoryOverviewQuery,
            variables: {
              accountId: activeAccount.id,
              ecosystemId: match.params.ecosystemId,
              categoryId: category.id
            }
          }
        ]

        if (some(category.listings, ['id', id])) {
          await removeListingFromCategory({ variables, refetchQueries })
        } else {
          await addListingToCategory({ variables, refetchQueries })
        }
      } catch (error) {
        toastr.error('could not add/remove listing to/from category')
        throw new Error(error.message)
      }
    }
})

const OverviewContainer = compose(
  withRouter,
  graphql(addListingToCategoryMutation, {
    name: 'addListingToCategory'
  }),
  graphql(removeListingFromCategoryMutation, {
    name: 'removeListingFromCategory'
  }),
  handleListingClick
)(Container)

export default OverviewContainer
