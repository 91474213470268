import Chip from '@material-ui/core/Chip'
import { Table } from 'components'
import PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import { useHistory } from 'react-router-dom'

function normalizePartnerData(ecosystemId, partners) {
  const contacts = []
  partners.map((p) => {
    return (
      p.contacts &&
      p.contacts.map(
        (c) =>
          c.invitation &&
          contacts.push({
            id: c.id,
            partner: p.company,
            name: `${c.firstName} ${c.lastName}`,
            email: c.email,
            role: c.role,
            icon: p.icon && p.icon.url,
            invitationStatus: c.invitation && c.invitation.status,
            link: `/ecosystems/${ecosystemId}/partners/${p.id}`
          })
      )
    )
  })

  return contacts
}

function PartnerInvitationsTable({ data, ecosystemId }) {
  const history = useHistory()
  const normalizedData = useMemo(() => normalizePartnerData(ecosystemId, data), [data, ecosystemId])

  const columns = [
    {
      Header: '',
      accessor: 'icon',
      Cell: ({ cell, row }) => {
        return (
          <a
            onClick={() => {
              history.push(row.original.link)
            }}
            href="#"
          >
            <img src={cell.value} alt="icon" />
          </a>
        )
      },
      cursor: 'pointer',
      width: 10,
      padding: 0
    },
    {
      id: 'partner',
      accessor: 'partner',
      Header: 'Partner'
    },
    {
      Header: 'Name',
      accessor: 'name',
      Cell: ({ cell, row }) => {
        return (
          <div
            onClick={() => {
              history.push(row.original.link)
            }}
          >
            {cell.value}
          </div>
        )
      },
      cursor: 'pointer'
    },
    {
      id: 'role',
      accessor: 'role',
      Header: 'Role'
    },
    {
      id: 'email',
      accessor: 'email',
      Cell: ({ cell: { value: email } }) => <a href={`mailto:${email}`}>{email}</a>,
      Header: 'Email'
    },
    {
      Header: 'Invitation status',
      accessor: 'invitationStatus',
      Cell: ({ cell: { value: invitationstatus } }) => {
        return <Chip variant="outlined" color="primary" size="small" label={invitationstatus} />
      },
      cursor: 'pointer'
    }
  ]

  return <Table columns={columns} data={normalizedData} hoverOnRows />
}

PartnerInvitationsTable.propTypes = {
  data: PropTypes.array.isRequired,
  ecosystemId: PropTypes.string.isRequired
}

export default PartnerInvitationsTable
