import React from 'react'
import { UserSettingsForm, Card } from 'components'

const Settings = ({ handleSubmit, initialValues }) => {
  return (
    <Card>
      <h1>User Settings</h1>
      <UserSettingsForm onSubmit={handleSubmit} initialValues={initialValues} enableReinitialize />
    </Card>
  )
}

export default Settings
