import React, { Fragment } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import { useFormikContext } from 'formik'

const useStyles = makeStyles((theme) => ({
  error: {
    textAlign: 'left',
    color: theme.palette.error.main,
    margin: theme.spacing(1)
  }
}))

export const AnyErrorsMessage = () => {
  const formikContext = useFormikContext()
  const errors = formikContext.errors
  const classes = useStyles()

  if (errors && errors.length === 0) {
    return <Fragment />
  }

  // if formik.errors has any keys, there was an error
  return Object.values(errors)?.map((error) => {
    if (typeof error === 'string') {
      return error?.split(/\n/).map((line) => (
        <div key={line} className={classes.error}>
          {line}
        </div>
      ))
    } else {
      return <div className={classes.error}>{error}</div>
    }
  })
}
