import React, { useState, useEffect, useRef } from 'react'
import { useQuery } from 'react-apollo'
import { useDebounce } from 'use-debounce'
import { catalogServicesQuery } from 'api/queries'
import { automatedQuery } from './graphql'
import LoadingPlaceholder from 'components/placeholder/Loading'
import AutomatedPage from './AutomatedPage'

const PER_PAGE = 25

const AutomatedContainer = ({ activeAccount, ecosystem, setActivePath, match }) => {
  const didMountRef = useRef(false)
  const urlParams = new URLSearchParams(window.location.search)
  const searchValueInUrl = urlParams.get('search')
  const [searchText, setSearchText] = useState(searchValueInUrl ? searchValueInUrl : '')
  const [debouncedSearchText] = useDebounce(searchText, 500)
  const handleSearchText = (value) => {
    setSearchText(value)
  }

  useEffect(() => {
    setActivePath(match.path)

    return () => {
      setActivePath('')
    }
  })

  useEffect(() => {
    if (didMountRef.current) {
      window.history.replaceState(
        null,
        null,
        `${window.location.pathname}?search=${debouncedSearchText}`
      )
    } else {
      didMountRef.current = true
    }
  }, [debouncedSearchText])

  const { loading, error, data } = useQuery(automatedQuery, {
    variables: {
      accountId: activeAccount.id,
      ecosystemId: ecosystem.id
    }
  })
  const {
    loading: catalogServicesLoading,
    error: catalogServicesError,
    data: catalogServicesData,
    fetchMore
  } = useQuery(catalogServicesQuery, {
    variables: {
      page: 1,
      filterText: debouncedSearchText,
      perPage: PER_PAGE
    },
    notifyOnNetworkStatusChange: true
  })

  if (loading || (catalogServicesLoading && !catalogServicesData)) {
    return <LoadingPlaceholder />
  }

  if (catalogServicesError || error) {
    return `Error!: ${error}`
  }

  const { marketplace } = data
  const { listings } = marketplace
  const currentPage = catalogServicesData.services.page
  const loadNextPage = async () => {
    try {
      await fetchMore({
        variables: {
          page: currentPage + 1,
          perPage: PER_PAGE
        },
        updateQuery: (previousResult, { fetchMoreResult }) => ({
          services: {
            hasMore: fetchMoreResult.services.hasMore,
            page: fetchMoreResult.services.page,
            perPage: fetchMoreResult.services.perPage,
            services: [...previousResult.services.services, ...fetchMoreResult.services.services],
            total: fetchMoreResult.services.total,
            totalPages: fetchMoreResult.services.totalPages,
            __typename: 'ServicePage'
          }
        })
      })
    } catch (error) {
      console.log(error)
    }
  }

  const listingSlugs = listings.map((listing) => listing.slug)
  let existingListingsInAutomated = 0
  const automatedListings = catalogServicesData.services.services.filter((automatedListing) => {
    if (listingSlugs.includes(automatedListing.id)) {
      existingListingsInAutomated++
      return false
    }
    return true
  })

  return (
    <AutomatedPage
      data={automatedListings}
      total={catalogServicesData.services.total - existingListingsInAutomated}
      hasMore={catalogServicesData.services.hasMore}
      ecosystem={ecosystem}
      loadNextPage={loadNextPage}
      setSearchText={handleSearchText}
      searchText={searchText}
      nextPageIsLoading={catalogServicesData && catalogServicesLoading}
    />
  )
}

export default AutomatedContainer
