import React, { Fragment } from 'react'

import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import WarningIcon from '@material-ui/icons/Warning'
import classNames from 'classnames'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    textTransform: 'uppercase',
    padding: theme.spacing(0.5, 1.5),
    borderRadius: '30px',
    backgroundColor: theme.palette.ui[100]
  },
  containerEnabled: {
    backgroundColor: '#ebf7ef' // TODO use theme
  },
  containerBeta: {
    backgroundColor: '#EBF5F8' // TODO use theme
  },
  containerIncomplete: {
    backgroundColor: '#fef5e5' // TODO use theme
  },
  containerSmall: {
    padding: theme.spacing(0.25, 0.75)
  },
  indicator: {
    marginRight: theme.spacing(1),
    width: '8px',
    height: '8px',
    borderRadius: '4px',
    backgroundColor: theme.palette.ui[300]
  },
  indicatorEnabled: {
    backgroundColor: theme.palette.success.main
  },
  warningIconContainer: {
    marginRight: theme.spacing(1),
    color: theme.palette.warning.main,
    lineHeight: 1
  },
  text: {
    fontSize: '13px',
    fontWeight: 500,
    color: theme.palette.ui[300]
  },
  textEnabled: {
    color: theme.palette.success.main
  },
  textBeta: {
    color: '#4C8CAF'
  },
  textIncomplete: {
    color: theme.palette.warning.main
  },
  textSmall: {
    fontSize: '11px',
    lineHeight: 1,
    padding: '2px 0'
  }
}))

const Status = ({ type, isSmall, hideIndicator }) => {
  const classes = useStyles()
  let label
  if (type === 'disabled') {
    label = 'Off'
  } else if (type === 'beta') {
    label = 'Live'
  } else if (type === 'incomplete') {
    label = 'Incomplete configuration'
  } else if (type === 'development') {
    label = 'In development'
  } else {
    label = type
  }

  return (
    <div
      className={classNames(classes.container, {
        // [classes.containerEnabled]: type === 'enabled' || type === 'live',
        [classes.containerBeta]: type === 'beta',
        [classes.containerIncomplete]: type === 'incomplete',
        [classes.containerSmall]: isSmall
      })}
    >
      {!hideIndicator && (
        <Fragment>
          {type === 'incomplete' ? (
            <span className={classes.warningIconContainer}>
              <WarningIcon style={{ fontSize: 16 }} />
            </span>
          ) : (
            <span
              className={classNames(classes.indicator, {
                [classes.indicatorEnabled]: type === 'enabled'
              })}
            />
          )}
        </Fragment>
      )}
      <Typography
        className={classNames(classes.text, {
          [classes.textEnabled]:
            type === 'enabled' || type === 'beta' || type === 'live' || type === 'auth-only',
          [classes.textBeta]: type === 'beta',
          [classes.textIncomplete]: type === 'incomplete',
          [classes.textSmall]: isSmall
        })}
      >
        {label}
      </Typography>
    </div>
  )
}

Status.defaultProps = {
  isSmall: false,
  hideIndicator: false
}

Status.propTypes = {
  type: PropTypes.oneOf([
    'live',
    'enabled',
    'disabled',
    'incomplete',
    'requested',
    'available',
    'development',
    'beta',
    'considering',
    'upcoming',
    'auth-only'
  ]).isRequired,
  isSmall: PropTypes.bool,
  hideIndicator: PropTypes.bool
}

export default Status
