import React from 'react'
import { ContentContainer, LayoutHeader } from 'components'

const ContentWrapper = ({ title, description, btnName, btnTargetBlank, url, children }) => (
  <ContentContainer
    header={
      <LayoutHeader
        title={title}
        description={description}
        btnName={btnName}
        btnTargetBlank={btnTargetBlank}
        url={url}
      />
    }
  >
    {children}
  </ContentContainer>
)

export default ContentWrapper
