import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { ConfirmationDialog } from 'components'
import DeleteIcon from '@material-ui/icons/Delete'
import IconButton from '@material-ui/core/IconButton'

class DeleteDialog extends Component {
  static propTypes = {
    handleDelete: PropTypes.func.isRequired
  }

  static defaultProps = {
    id: null,
    label: null,
    opener: null
  }

  handleOpen = (openWithState) => (e) => {
    e.preventDefault()
    e.stopPropagation()
    openWithState({
      title: `Delete ${this.props.label}`,
      contentText: this.props.contentText,
      id: this.props.id,
      onDelete: this.props.handleDelete
    })
  }

  render() {
    return (
      <ConfirmationDialog
        opener={(openWithState) => {
          if (this.props.opener !== null) {
            return <span onClick={this.handleOpen(openWithState)}>{this.props.opener}</span>
          }

          return (
            <IconButton size="medium" aria-label="delete" onClick={this.handleOpen(openWithState)}>
              <DeleteIcon style={{ fontSize: '20px' }} />
            </IconButton>
          )
        }}
      />
    )
  }
}

export default DeleteDialog
