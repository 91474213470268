import { Card, DeleteDialog, LinkDialog, SecondaryButton } from 'components'
import {
  DndContext,
  DragOverlay,
  KeyboardSensor,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors
} from '@dnd-kit/core'
import React, { useState, useRef, useEffect, useCallback } from 'react'
import {
  SortableContext,
  arrayMove,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy
} from '@dnd-kit/sortable'

import Button from '@material-ui/core/Button'
import { CSS } from '@dnd-kit/utilities'
import DragIndicatorIcon from '@material-ui/icons/DragIndicator'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import classNames from 'classnames'
import getLocalizedValue from 'utils/getLocalizedValue'
import { sortBySequence } from 'utils/sortBySequence'
import styled from 'react-emotion'
import { useDragStyles } from 'components/ecosystem/settings/navigation-items'

const LinkList = styled('ul')`
  padding-inline-start: 0;
  margin-top: 0;
`

const DragIconContainer = styled('span')`
  margin-right: 0.5em;
  color: rgba(0, 0, 0, 0.87);
  cursor: move;
`

const LinkItem = styled('li')`
  display: flex;
  align-items: center;
  margin-bottom: 0em;
  white-space: no-wrap;
  border-radius: 5px;

  a {
    font-size: 14px;
  }
`

const LinkName = styled('div')`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: bolder;
  margin-right: 1em;
  width: 300px;
`

const getLinkById = (id, links) => links.find((link) => link.id === id)

const Links = (props) => {
  const { locales, handleLinkCreate, handleLinkUpdate, handleLinkDelete } = props
  const [links, setLinks] = useState(props.links.slice().sort(sortBySequence))
  const [loading, setLoading] = useState(false)
  const [draggedLinkId, setDraggedLinkId] = useState(null)
  const classes = useDragStyles()
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  )

  const draggedLink = getLinkById(draggedLinkId, links)

  // Update links with a sequence if more than one link has a sequance of 0
  useEffect(() => {
    if (links.filter((l) => l.sequence === 0).length > 1) {
      saveSequenceOfLinks(links)
    }
  }, [links, saveSequenceOfLinks])

  const saveSequenceOfLinks = useCallback(
    async (items) => {
      const updatedItems = await Promise.all(
        items.map((item, i) => {
          item.sequence = i
          return handleLinkUpdate(item)
        })
      )
      setLinks(updatedItems)
    },
    [handleLinkUpdate, setLinks]
  )

  const handleCreate = async (link, _, onClose) => {
    const createdLink = await handleLinkCreate(link, _, onClose)
    setLinks([...links, createdLink].slice().sort(sortBySequence))
    return createdLink
  }

  const handleUpdate = async (link, _, onClose) => {
    const updatedLink = await handleLinkUpdate(link, _, onClose)
    const updatedLinks = links.map((l) => (l.id === updatedLink.id ? updatedLink : l))
    setLinks(updatedLinks)
    return updatedLink
  }

  const handleDelete = async (deletedLinkId) => {
    await handleLinkDelete(deletedLinkId)
    const updatedLinks = links.filter((l) => l.id !== deletedLinkId)
    setLinks(updatedLinks)
    return deletedLinkId
  }

  const handleDragEnd = async (event, currentLinks) => {
    const { active, over } = event

    if (active.id !== over.id) {
      const oldIndex = currentLinks.map((item) => item.id).indexOf(active.id)
      const newIndex = currentLinks.map((item) => item.id).indexOf(over.id)
      const newLinks = arrayMove(currentLinks, oldIndex, newIndex)
      setLoading(true)
      await saveSequenceOfLinks(newLinks)
      setLoading(false)
      setDraggedLinkId(null)
    }
  }

  return (
    <Card
      title="External links"
      button={
        <LinkDialog
          handleSubmit={handleCreate}
          opener={<SecondaryButton className="fr">+ Add</SecondaryButton>}
          locales={locales}
          sequence={Math.max(...links.map((item) => item.sequence)) + 1}
        />
      }
    >
      <LinkList style={{ opacity: loading ? 0.5 : 1 }}>
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={(event) => handleDragEnd(event, links)}
          onDragCancel={() => setDraggedLinkId(null)}
          onDragStart={({ active }) => {
            setDraggedLinkId(active.id)
          }}
        >
          <SortableContext items={links} strategy={verticalListSortingStrategy}>
            {links?.map((link) => (
              <SortableLink
                key={link.id}
                id={link.id}
                link={link}
                locales={locales}
                handleLinkUpdate={handleUpdate}
                handleLinkDelete={handleDelete}
              />
            ))}
          </SortableContext>
          <DragOverlay>
            {draggedLink ? (
              <Link
                link={draggedLink}
                locales={locales}
                className={classNames(classes.dragging, classes.overlay)}
              />
            ) : null}
          </DragOverlay>
        </DndContext>
      </LinkList>
    </Card>
  )
}

const Link = (props) => {
  const { link, locales, dragListeners, isCurrentLink, handleLinkUpdate, handleLinkDelete } = props
  const buttonRef = useRef()
  const [isLinkMenuVisible, setIsLinkMenuVisible] = useState(false)
  const hasMenuHandlers = handleLinkUpdate || handleLinkDelete

  return (
    <LinkItem>
      <DragIconContainer {...dragListeners}>
        <DragIndicatorIcon fontSize="small" style={{ verticalAlign: 'bottom' }} />
      </DragIconContainer>
      <LinkName>
        <a href={link.url} target="_blank" rel="noopener noreferrer">
          {getLocalizedValue(link, 'name', locales)}
        </a>
      </LinkName>
      <Button
        ref={buttonRef}
        aria-owns={isCurrentLink ? 'simple-menu' : null}
        aria-haspopup="true"
        onClick={() => setIsLinkMenuVisible(true)}
      >
        ...
      </Button>
      {hasMenuHandlers && (
        <Menu
          id="simple-menu"
          anchorEl={buttonRef?.current}
          open={isLinkMenuVisible}
          onClose={() => setIsLinkMenuVisible(false)}
        >
          <LinkDialog
            handleSubmit={async (link) => {
              await handleLinkUpdate(link)
              setIsLinkMenuVisible(false)
            }}
            link={link}
            opener={<MenuItem>Edit</MenuItem>}
            onClose={() => setIsLinkMenuVisible(false)}
            locales={locales}
          />
          <DeleteDialog
            handleDelete={async (linkId) => {
              setIsLinkMenuVisible(false)
              await handleLinkDelete(linkId)
            }}
            handleClose={() => setIsLinkMenuVisible(false)}
            id={link?.id}
            label="link"
            opener={<MenuItem>Delete</MenuItem>}
          />
        </Menu>
      )}
    </LinkItem>
  )
}

const SortableLink = (props) => {
  const { link } = props
  const classes = useDragStyles()
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: link.id,
    transition: {
      duration: 150,
      easing: 'cubic-bezier(0.25, 1, 0.5, 1)'
    }
  })

  return (
    <div
      ref={setNodeRef}
      className={classNames(classes.draggable, isDragging && classes.drop)}
      style={{
        transform: CSS.Transform.toString(transform),
        transition
      }}
      {...attributes}
    >
      <Link dragListeners={listeners} {...props} />
    </div>
  )
}

export default Links
