import Button from '@material-ui/core/Button'
import React from 'react'

const Primary = ({ children, ...rest }) => (
  <Button variant="contained" color="primary" {...rest}>
    {children}
  </Button>
)

export default Primary
