import gql from 'graphql-tag'

export const updateNotificationPreferencesMutation = gql`
  mutation updateNotificationPreferences(
    $accountId: ID!
    $accountUserId: ID!
    $notificationPreferences: NotificationPreferencesInput!
  ) {
    updateNotificationPreferences(
      input: {
        accountId: $accountId
        accountUserId: $accountUserId
        notificationPreferences: $notificationPreferences
      }
    ) {
      notificationPreferences {
        weeklyDigest
        listingChanges
      }
    }
  }
`
