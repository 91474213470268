import gql from 'graphql-tag'

export const invitationFields = gql`
  fragment invitationFields on Invitation {
    id
    challenge
    createdAt
    updatedAt
    status
    accountUser {
      id
      role
      inactive
      account {
        id
        name
      }
      user {
        id
        email
      }
    }
  }
`

export const translationFields = gql`
  fragment translationFields on Translation {
    id
    key
    value
    locale
  }
`

export const leadFields = gql`
  fragment leadFields on Lead {
    id
    name
    firstName
    lastName
    email
    role
    telephone
    companyName
    website
    isCustomer
    installationRequest
    requestedServices
    createdAt
    isRead
    notes
    referer
    srcUrl
  }
`

export const fileFields = gql`
  fragment fileFields on File {
    id
    url
    contentType
  }
`

export const contactFields = gql`
  fragment contactFields on Contact {
    id
    firstName
    lastName
    email
    role
    linkedIn
    twitter
    icon {
      ...fileFields
    }
  }
  ${fileFields}
`
export const partnerFragment = gql`
  fragment partner on Partner {
    company
    contacts {
      ...contactFields
    }
    icon {
      ...fileFields
    }
    id
    listed
    listings {
      id
      name
      logo {
        ...fileFields
      }
    }
    website
    twitter
  }
  ${contactFields}
  ${fileFields}
`

export const accountFields = gql`
  fragment accountFields on Account {
    id
    name
    imageUrl
    website
    plan
    subscriptionStatus
    endOfTrial
    trialExtensions
    createdAt
    marketplaces {
      id
      unifyApplicationId
      name
      website
      slug
      customDomain
      icon {
        id
        url
      }
      favicon {
        id
        url
      }
      listings {
        id
      }
    }
  }
`
export const userQuery = gql`
  query user {
    user {
      id
      name
      email
      imageUrl
      emailSubscription
      accounts {
        id
        account {
          ...accountFields
        }
      }
    }
  }
  ${accountFields}
`
export const userFields = gql`
  fragment userFields on User {
    id
    name
    email
    emailSubscription
    imageUrl
    referer
    accounts {
      id
      role
      inactive
      invitation {
        id
        challenge
        createdAt
        updatedAt
        status
      }
      account {
        ...accountFields
        users {
          id
        }
      }
    }
  }
  ${accountFields}
`
export const userWithoutInvitationFields = gql`
  fragment userWithoutInvitationFields on User {
    id
    name
    email
    emailSubscription
    imageUrl
    referer
    accounts {
      id
      role
      inactive
      account {
        ...accountFields
        users {
          id
        }
      }
    }
  }
  ${accountFields}
`

export const screenshotFields = gql`
  fragment screenshotFields on Screenshot {
    id
    caption
    sequence
    file {
      ...fileFields
    }
    translations {
      ...translationFields
    }
  }
  ${fileFields}
  ${translationFields}
`

export const mediaFields = gql`
  fragment mediaFields on Media {
    id
    caption
    url
    sequence
    translations {
      ...translationFields
    }
  }
  ${translationFields}
`

export const linkFields = gql`
  fragment linkFields on Link {
    id
    name
    url
    type
    sequence
    translations {
      ...translationFields
    }
  }
  ${translationFields}
`

export const localeFields = gql`
  fragment localeFields on MarketplaceLocale {
    id
    code
    default
  }
`

export const navigationItemFields = gql`
  fragment navigationItemFields on NavigationItem {
    id
    label
    url
    type
    sequence
    translations {
      ...translationFields
    }
  }
  ${translationFields}
`

export const footerItemFields = gql`
  fragment footerItemFields on FooterItem {
    id
    label
    url
    type
    alignment
    sequence
    translations {
      ...translationFields
    }
  }
  ${translationFields}
`

export const categoryFields = gql`
  fragment categoryFields on Category {
    id
    name
    slug
    description
    logo {
      ...fileFields
    }
    translations {
      ...translationFields
    }
    listingDescriptionTextTemplate
    listingPricingTextTemplate
    listingFeaturesTextTemplate
    listings {
      id
      name
      links {
        id
        url
        type
        sequence
      }
      logo {
        ...fileFields
      }
      translations {
        ...translationFields
      }
    }
  }
  ${fileFields}
  ${translationFields}
`

export const multiCategoryFields = gql`
  fragment multiCategoryFields on MultiCategory {
    id
    name
    slug
    description
    logo {
      ...fileFields
    }
    translations {
      ...translationFields
    }
    listingDescriptionTextTemplate
    listingPricingTextTemplate
    listingFeaturesTextTemplate
    listings {
      id
      name
      links {
        id
        url
        type
        sequence
      }
      logo {
        ...fileFields
      }
      translations {
        ...translationFields
      }
    }
  }
  ${fileFields}
  ${translationFields}
`

export const listingFields = gql`
  fragment listingFields on Listing {
    id
    externalId
    name
    slug
    description
    features
    pricing
    tagLine
    metaTagTitle
    metaTagDescription
    metaTagKeywords
    unifyConnectorId
    nativeIntegration
    thirdPartyIntegration
    nativeIntegrationLink
    thirdPartyIntegrationLink
    cardBackgroundColor
    cardBackgroundImage {
      ...fileFields
    }
    sticky
    upcoming
    zapierId
    piesyncId
    blendrId
    integromatId
    segmentId
    microsoftFlowId
    combideskId
    trayIoId
    automateId
    cloudServiceId
    detailPageDisabled
    published
    createdAt
    updatedAt
    translations {
      ...translationFields
    }
    category {
      ...categoryFields
    }
    categories {
      ...multiCategoryFields
    }
    logo {
      ...fileFields
    }
    screenshots {
      ...screenshotFields
    }
    media {
      ...mediaFields
    }
    links {
      ...linkFields
    }
    partner {
      ...partner
    }
    state
    comments {
      id
      content
      author {
        id
        email
      }
      createdAt
      updatedAt
    }
  }
  ${categoryFields}
  ${multiCategoryFields}
  ${translationFields}
  ${fileFields}
  ${screenshotFields}
  ${mediaFields}
  ${linkFields}
  ${partnerFragment}
`

export const collectionFields = gql`
  fragment collectionFields on Collection {
    id
    name
    slug
    description
    visible
    cardStyle
    cardColumns
    hiddenFromHomepage
    showMaxItemsHomepage
    listings {
      ...listingFields
    }
    logo {
      ...fileFields
    }
    cardBackgroundImage {
      ...fileFields
    }
    translations {
      ...translationFields
    }
    cardBackgroundColor
    sequence
  }
  ${translationFields}
  ${listingFields}
  ${fileFields}
`

export const productFields = gql`
  fragment productFields on Product {
    id
    name
    slug
    description
    visible
    sequence
    listings {
      ...listingFields
    }
    logo {
      ...fileFields
    }
    translations {
      ...translationFields
    }
  }
  ${translationFields}
  ${listingFields}
  ${fileFields}
`

export const marketplaceFieldsForSettings = gql`
  fragment marketplaceFieldsForSettings on Marketplace {
    id
    unifyApplicationId
    alternativesBackgroundColor
    alternativesColor
    attribution
    blendrEnabled
    automateEnabled
    cardBorderColor
    cardBorderSize
    cardBackgroundColor
    cardColor
    cardColumns
    cardShowAction
    cardShowCategory
    cardShowDescription
    cardDescriptionLines
    cardStyle
    cardBorderRadius
    cardShadowEnabled
    categoriesCountBadge
    categoriesShowMaxItems
    collectionsCountBadge
    collectionsTitle
    privacyLink
    termsLink
    customCssLink
    customCss
    customJavaScriptLink
    customHtmlNav
    customHtmlFooter
    showRequestedListings
    zapsPageEnabled
    zapsMenuTitle
    combideskEnabled
    trayIoEnabled
    createLink
    ctaBackgroundColor
    ctaButtonBackgroundColor
    ctaButtonColor
    ctaButtonLabel
    ctaButtonLink
    ctaColor
    ctaDescription
    ctaTitle
    ctaEnabled
    customDomain
    icon {
      ...fileFields
    }
    favicon {
      ...fileFields
    }
    footerBackgroundColor
    footerColor
    footerItems {
      ...footerItemFields
    }
    googleSiteVerificationId
    integrationAlbacrossId
    integrationDriftId
    integrationGoogleAnalyticsId
    integrationGoogleTagManagerId
    integrationHeapId
    integrationHubspotPortalId
    integrationIntercomAppId
    integrationCrispId
    integrationIubendaCookiePolicyId
    integrationIubendaSiteId
    integrationJournyIoDomain
    integrationJournyIoId
    integrationMetomicId
    integrationOnetrustId
    integrationLivechatId
    integrationSegmentId
    integrationZapierId
    integrationZapierBetaLink
    leadCaptureFormEnabled
    listingApprovalEnabled
    listingNaming
    listingDescriptionTitle
    listingDescriptionTextTemplate
    listingFeaturesTitle
    listingFeaturesTextTemplate
    listingNativeIntegrationLink
    listingInstallButtonLabel
    listingNamePostfix
    listingPricingTitle
    listingPricingTextTemplate
    listingSidebarPosition
    menuPosition
    menuStyle
    logo {
      ...fileFields
    }
    translations {
      ...translationFields
    }
    mastheadBackground
    mastheadBackgroundColor
    mastheadBackgroundImage {
      ...fileFields
    }
    mastheadColumns
    mastheadImage {
      ...fileFields
    }
    locales {
      ...localeFields
    }
    mastheadColor
    mastheadDescription
    mastheadTitle
    homePageShowAllListings
    homePageCollectionCategoryCards
    bodyBackgroundColor
    bodyColor
    bodyButtonBackgroundColor
    bodyButtonColor
    bodyLinkColor
    metaTagDescription
    metaTagDescriptionCategoryPage
    metaTagDescriptionCollectionPage
    metaTagDescriptionListingPage
    metaTagKeywords
    metaTagTitle
    metaTagTitlePostfix
    microsoftFlowEnabled
    name
    navigationItems {
      ...navigationItemFields
    }
    navigationLogoPostFix
    navigationMobileMenuType
    navigationBackgroundColor
    navigationColor
    navigationSticky
    piesyncEnabled
    primaryColor
    requestLink
    segmentEnabled
    slug
    socialImage {
      ...fileFields
    }
    utmCampaign
    website
    integromatEnabled
    zapierEnabled
    zapsPageEnabled
    zapsMenuTitle
    privacyLink
    termsLink
    customCssLink
    customCss
    customJavaScriptLink
    customHtmlNav
    customHtmlFooter
    showRequestedListings
    about
    shadowPagesEnabled
    shadowPageDescription
    leadTelephoneFieldEnabled
    leadTelephoneFieldRequired
    leadFirstNameFieldEnabled
    leadFirstNameFieldRequired
    leadLastNameFieldEnabled
    leadLastNameFieldRequired
    leadWorkEmailValidation
    leadIntegrationEnabled
    installationRequestFlowEnabled
    maxListings
    listingApprovalEnabled
  }
  ${translationFields}
  ${fileFields}
  ${navigationItemFields}
  ${footerItemFields}
  ${localeFields}
`

export const marketplaceFields = gql`
  fragment marketplaceFields on Marketplace {
    ...marketplaceFieldsForSettings
    categories {
      ...categoryFields
    }
    collections {
      ...collectionFields
    }
    listings {
      ...listingFields
    }
    products {
      ...productFields
    }
  }
  ${marketplaceFieldsForSettings}
  ${listingFields}
  ${collectionFields}
  ${categoryFields}
  ${productFields}
`

export const catalogServiceFields = gql`
  fragment catalogServiceFields on Service {
    id
    name
    icon {
      url
    }
    description
    pricing
    features
    screenshots {
      url
    }
    partnersUrl
    developerPortalUrl
    privacyUrl
    termsUrl
    websiteUrl
    statusUrl
    pricingUrl
    apiReferenceUrl
    supportUrl
    brandAssetsUrl
    integrations {
      id
      name
      icon {
        __typename
        url
      }
      integrationPlatforms {
        type
        externalId
      }
      categories {
        name
      }
    }
    integrationsUrl
    integrationPlatforms {
      type
      externalId
    }
    products {
      id
      name
      icon {
        __typename
        url
      }
      integrationPlatforms {
        type
        externalId
      }
      categories {
        name
      }
    }
    zapierStatus
    primaryColor
    categories {
      name
      id
    }
    contacts {
      firstName
      lastName
      image
      role
    }
    funds {
      name
      size
      apply
      url
      amount
      currency
    }
    apis {
      id
      name
      documentationUrl
      referenceUrl
    }
    company {
      icon {
        url
      }
      id
      logo {
        url
      }
      name
      websiteUrl
      termsUrl
      privacyUrl
      developerPortalUrl
      partnersUrl
      integrationsUrl
      contacts {
        firstName
        lastName
        image
        role
      }
    }
  }
`

export const partialServiceFields = gql`
  fragment partialServiceFields on PartialService {
    id
    name
    icon {
      __typename
      url
    }
    integrationPlatforms {
      type
      externalId
    }
    categories {
      name
      id
    }
  }
`

const connectionFields = gql`
  fragment connectionFields on Connection {
    id
    serviceId
    tagLine
    name
    logo
    icon
    authorizeUrl
    revokeUrl
    authType
    unifiedApi
    added
    enabled
    state
    createdAt
    updatedAt
    formFields {
      formId
      label
      value
      placeholder
      mask
      type
      required
      description
      options {
        value
        label
      }
    }
  }
`

const unifiedApiStatFields = gql`
  fragment unifiedApiStatFields on UnifiedApiStats {
    totalConnectors
    totalConsumers
  }
`

const connectorFields = gql`
  fragment connectorFields on Connector {
    id
    name
    description
    icon
    authType
    hasSandboxCredentials
    oauthCredentialsSource
    oauthGrantType
    status
    serviceId
    webhookExecuteUrl
    authOnly
  }
`

const integrationFields = gql`
  fragment integrationFields on Integration {
    id
    enabled
    clientId
    clientSecret
    useSandboxCredentials
    hasSandboxCredentials
    oauthCredentialsSource
    settings {
      serverUrl
    }
    servers {
      url
      label
    }
    scopes {
      id
      label
    }
  }
`

const unifiedApiFields = gql`
  fragment unifiedApiFields on UnifiedApi {
    id
    name
    status
    configured
    enabled
    authOnly
    stats {
      ...unifiedApiStatFields
    }
    apiReferenceUrl
    postmanCollectionUrl
    connectors {
      ...connectorFields
    }
    integrations {
      ...integrationFields
    }
    eventTypes
  }
  ${connectorFields}
  ${integrationFields}
  ${unifiedApiStatFields}
`

export const unifiedApisQuery = gql`
  query unifiedApisQuery($ecosystemId: ID!, $accountId: ID!) {
    unifiedApis(ecosystemId: $ecosystemId, accountId: $accountId) {
      ...unifiedApiFields
    }
  }
  ${unifiedApiFields}
`

export const unifiedApiQuery = gql`
  query unifiedApiQuery($ecosystemId: ID!, $accountId: ID!, $unifiedApiId: ID!) {
    unifiedApi(ecosystemId: $ecosystemId, accountId: $accountId, unifiedApiId: $unifiedApiId) {
      ...unifiedApiFields
    }
  }
  ${unifiedApiFields}
`

export const integrationsQuery = gql`
  query integrationsQuery($ecosystemId: ID!, $accountId: ID!) {
    integrations(ecosystemId: $ecosystemId, accountId: $accountId) {
      ...integrationFields
    }
  }
  ${integrationFields}
`

export const catalogServicePage = gql`
  fragment catalogServicePage on ServicePage {
    hasMore
    page
    perPage
    services {
      ...partialServiceFields
    }
    total
    totalPages
  }
  ${partialServiceFields}
`

export const catalogServiceIntegrationsPage = gql`
  fragment catalogServiceIntegrationsPage on ServiceIntegrationsPage {
    hasMore
    page
    perPage
    integrations {
      id
      name
      icon {
        __typename
        url
      }
      integrationPlatforms {
        type
        id
      }
      categories {
        name
      }
    }
    total
    totalPages
  }
`

export const marketplaceQuery = gql`
  query marketplaceQuery($id: ID!, $accountId: ID!) {
    marketplace(id: $id, accountId: $accountId) {
      ...marketplaceFields
    }
  }
  ${marketplaceFields}
`

export const marketplaceIntegrationSettingsQuery = gql`
  query marketplaceQuery($id: ID!, $accountId: ID!) {
    marketplace(id: $id, accountId: $accountId) {
      blendrEnabled
      zapierEnabled
      piesyncEnabled
      segmentEnabled
      automateEnabled
      microsoftFlowEnabled
      combideskEnabled
      trayIoEnabled
      integromatEnabled
      integrationZapierId
      integrationZapierBetaLink
      zapsPageEnabled
    }
  }
`

export const marketplacesQuery = gql`
  query marketplacesQuery($accountId: ID!) {
    marketplaces(accountId: $accountId) {
      id
      name
      icon {
        id
        url
      }
      favicon {
        id
        url
      }
      listings {
        id
      }
    }
  }
`

export const createMarketplaceMutation = gql`
  mutation createMarketplace(
    $accountId: ID!
    $name: String!
    $slug: String!
    $website: String
    $primaryColor: String!
    $listingNaming: LISTING_NAMING
  ) {
    createMarketplace(
      input: {
        accountId: $accountId
        marketplace: {
          name: $name
          slug: $slug
          website: $website
          primaryColor: $primaryColor
          listingNaming: $listingNaming
        }
      }
    ) {
      ...marketplaceFieldsForSettings
    }
  }
  ${marketplaceFieldsForSettings}
`

export const deleteEcosystemMutation = gql`
  mutation deleteEcosystem($accountId: ID!, $ecosystemId: ID!, $challenge: String!) {
    deleteEcosystem(
      input: { accountId: $accountId, ecosystemId: $ecosystemId, challenge: $challenge }
    ) {
      id
      success
    }
  }
`

export const deleteEcosystemMastheadBackgroundImageMutation = gql`
  mutation deleteEcosystemMastheadBackgroundImage($ecosystemId: ID!, $accountId: ID!) {
    deleteEcosystemMastheadBackgroundImage(
      input: { ecosystemId: $ecosystemId, accountId: $accountId }
    ) {
      ...marketplaceFields
    }
  }
  ${marketplaceFields}
`
export const deleteEcosystemMastheadImageMutation = gql`
  mutation deleteEcosystemMastheadImage($ecosystemId: ID!, $accountId: ID!) {
    deleteEcosystemMastheadImage(input: { ecosystemId: $ecosystemId, accountId: $accountId }) {
      ...marketplaceFields
    }
  }
  ${marketplaceFields}
`

export const deleteEcosystemSocialImageMutation = gql`
  mutation deleteEcosystemSocialImage($ecosystemId: ID!, $accountId: ID!) {
    deleteEcosystemSocialImage(input: { ecosystemId: $ecosystemId, accountId: $accountId }) {
      ...marketplaceFields
    }
  }
  ${marketplaceFields}
`

export const deleteEcosystemFaviconMutation = gql`
  mutation deleteEcosystemFavicon($ecosystemId: ID!, $accountId: ID!) {
    deleteEcosystemFavicon(input: { ecosystemId: $ecosystemId, accountId: $accountId }) {
      ...marketplaceFields
    }
  }
  ${marketplaceFields}
`

export const deleteEcosystemIconMutation = gql`
  mutation deleteEcosystemIcon($ecosystemId: ID!, $accountId: ID!) {
    deleteEcosystemIcon(input: { ecosystemId: $ecosystemId, accountId: $accountId }) {
      ...marketplaceFields
    }
  }
  ${marketplaceFields}
`

export const deleteEcosystemLogoMutation = gql`
  mutation deleteEcosystemLogo($ecosystemId: ID!, $accountId: ID!) {
    deleteEcosystemLogo(input: { ecosystemId: $ecosystemId, accountId: $accountId }) {
      ...marketplaceFields
    }
  }
  ${marketplaceFields}
`

export const deleteCategoryLogoMutation = gql`
  mutation deleteCategoryLogo($id: ID!, $accountId: ID!) {
    deleteCategoryLogo(input: { id: $id, accountId: $accountId }) {
      id
    }
  }
`

export const deleteCollectionLogoMutation = gql`
  mutation deleteCollectionLogo($id: ID!, $accountId: ID!) {
    deleteCollectionLogo(input: { id: $id, accountId: $accountId }) {
      ...collectionFields
    }
  }
  ${collectionFields}
`

export const updateMarketplaceGeneralSettingsMutation = gql`
  mutation updateMarketplace(
    $id: ID!
    $name: String!
    $slug: String!
    $website: String
    $accountId: ID!
    $icon: ImageInput
    $favicon: ImageInput
    $socialImage: ImageInput
  ) {
    updateMarketplace(
      input: {
        accountId: $accountId
        id: $id
        marketplace: {
          name: $name
          slug: $slug
          icon: $icon
          favicon: $favicon
          website: $website
          socialImage: $socialImage
        }
      }
    ) {
      ...marketplaceFieldsForSettings
    }
  }
  ${marketplaceFieldsForSettings}
`

export const updateMarketplaceCustomDomainSettingsMutation = gql`
  mutation updateMarketplace(
    $id: ID!
    $name: String!
    $slug: String!
    $website: String
    $accountId: ID!
    $customDomain: String!
  ) {
    updateCustomDomain(
      input: {
        accountId: $accountId
        id: $id
        marketplace: { customDomain: $customDomain, name: $name, slug: $slug, website: $website }
      }
    ) {
      ...marketplaceFieldsForSettings
    }
  }
  ${marketplaceFieldsForSettings}
`

export const updatePartnerSettingsMutation = gql`
  mutation updateMarketplace(
    $accountId: ID!
    $id: ID!
    $name: String!
    $slug: String!
    $listingApprovalEnabled: Boolean!
  ) {
    updateMarketplace(
      input: {
        accountId: $accountId
        id: $id
        marketplace: { name: $name, slug: $slug, listingApprovalEnabled: $listingApprovalEnabled }
      }
    ) {
      ...marketplaceFieldsForSettings
    }
  }
  ${marketplaceFieldsForSettings}
`

export const updateListingsSettingsMutation = gql`
  mutation updateMarketplace(
    $id: ID!
    $name: String!
    $slug: String!
    $accountId: ID!
    $listingDescriptionTextTemplate: String
    $listingPricingTextTemplate: String
    $listingFeaturesTextTemplate: String
    $listingDescriptionTitle: String
    $listingPricingTitle: String
    $listingFeaturesTitle: String
    $listingNativeIntegrationLink: String
    $listingInstallButtonLabel: String
    $shadowPagesEnabled: Boolean
    $shadowPageDescription: String
    $installationRequestFlowEnabled: Boolean
    $about: String
    $translations: [TranslationInput]
  ) {
    updateMarketplace(
      input: {
        accountId: $accountId
        id: $id
        marketplace: {
          name: $name
          slug: $slug
          listingDescriptionTextTemplate: $listingDescriptionTextTemplate
          listingPricingTextTemplate: $listingPricingTextTemplate
          listingFeaturesTextTemplate: $listingFeaturesTextTemplate
          listingNativeIntegrationLink: $listingNativeIntegrationLink
          listingInstallButtonLabel: $listingInstallButtonLabel
          listingDescriptionTitle: $listingDescriptionTitle
          listingPricingTitle: $listingPricingTitle
          listingFeaturesTitle: $listingFeaturesTitle
          shadowPagesEnabled: $shadowPagesEnabled
          shadowPageDescription: $shadowPageDescription
          installationRequestFlowEnabled: $installationRequestFlowEnabled
          about: $about
          translations: $translations
        }
      }
    ) {
      ...marketplaceFieldsForSettings
    }
  }
  ${marketplaceFieldsForSettings}
`

export const updateTemplateSettingsMutation = gql`
  mutation updateMarketplace(
    $accountId: ID!
    $name: String!
    $alternativesBackgroundColor: String
    $alternativesColor: String
    $cardBorderColor: String
    $cardBorderSize: String
    $cardBackgroundColor: String
    $cardColor: String
    $cardBorderRadius: String
    $cardColumns: Int
    $cardShadowEnabled: Boolean
    $cardShowAction: Boolean
    $cardShowCategory: Boolean
    $cardShowDescription: Boolean
    $cardDescriptionLines: Int
    $cardStyle: String
    $createLink: String
    $ctaBackgroundColor: String
    $ctaButtonBackgroundColor: String
    $ctaButtonColor: String
    $ctaButtonLabel: String
    $ctaButtonLink: String
    $ctaColor: String
    $ctaDescription: String
    $ctaTitle: String
    $ctaEnabled: Boolean
    $categoriesCountBadge: Boolean
    $categoriesShowMaxItems: Int
    $collectionsTitle: String
    $collectionsCountBadge: Boolean
    $privacyLink: String
    $termsLink: String
    $customCssLink: String
    $customCss: String
    $customJavaScriptLink: String
    $customHtmlNav: String
    $customHtmlFooter: String
    $showRequestedListings: Boolean
    $footerBackgroundColor: String
    $footerColor: String
    $hideInstallButtons: Boolean
    $id: ID!
    $logo: ImageInput
    $mastheadBackgroundColor: String
    $mastheadBackgroundImage: ImageInput
    $mastheadColumns: Int
    $mastheadColor: String
    $mastheadDescription: String
    $mastheadImage: ImageInput
    $mastheadTitle: String
    $homePageShowAllListings: Boolean
    $homePageCollectionCategoryCards: Boolean
    $listingSidebarPosition: String
    $menuPosition: String
    $menuStyle: String
    $navigationBackgroundColor: String
    $navigationColor: String
    $navigationSticky: Boolean
    $navigationLogoPostFix: String
    $navigationMobileMenuType: String
    $bodyColor: String
    $bodyLinkColor: String
    $bodyBackgroundColor: String
    $primaryColor: String
    $requestLink: String
    $slug: String!
    $zapsMenuTitle: String
    $translations: [TranslationInput]
  ) {
    updateMarketplace(
      input: {
        accountId: $accountId
        id: $id
        marketplace: {
          name: $name
          alternativesBackgroundColor: $alternativesBackgroundColor
          alternativesColor: $alternativesColor
          cardBorderRadius: $cardBorderRadius
          cardBorderColor: $cardBorderColor
          cardBorderSize: $cardBorderSize
          cardBackgroundColor: $cardBackgroundColor
          cardColor: $cardColor
          cardColumns: $cardColumns
          cardShadowEnabled: $cardShadowEnabled
          cardShowAction: $cardShowAction
          cardShowCategory: $cardShowCategory
          cardShowDescription: $cardShowDescription
          cardDescriptionLines: $cardDescriptionLines
          cardStyle: $cardStyle
          createLink: $createLink
          ctaBackgroundColor: $ctaBackgroundColor
          ctaButtonBackgroundColor: $ctaButtonBackgroundColor
          ctaButtonColor: $ctaButtonColor
          ctaButtonLabel: $ctaButtonLabel
          ctaButtonLink: $ctaButtonLink
          ctaColor: $ctaColor
          ctaDescription: $ctaDescription
          ctaTitle: $ctaTitle
          ctaEnabled: $ctaEnabled
          categoriesCountBadge: $categoriesCountBadge
          categoriesShowMaxItems: $categoriesShowMaxItems
          collectionsTitle: $collectionsTitle
          collectionsCountBadge: $collectionsCountBadge
          privacyLink: $privacyLink
          termsLink: $termsLink
          customCssLink: $customCssLink
          customJavaScriptLink: $customJavaScriptLink
          customCss: $customCss
          customHtmlNav: $customHtmlNav
          customHtmlFooter: $customHtmlFooter
          showRequestedListings: $showRequestedListings
          footerBackgroundColor: $footerBackgroundColor
          footerColor: $footerColor
          hideInstallButtons: $hideInstallButtons
          logo: $logo
          mastheadBackgroundImage: $mastheadBackgroundImage
          mastheadBackgroundColor: $mastheadBackgroundColor
          mastheadColor: $mastheadColor
          mastheadColumns: $mastheadColumns
          mastheadDescription: $mastheadDescription
          mastheadImage: $mastheadImage
          mastheadTitle: $mastheadTitle
          homePageShowAllListings: $homePageShowAllListings
          homePageCollectionCategoryCards: $homePageCollectionCategoryCards
          listingSidebarPosition: $listingSidebarPosition
          menuPosition: $menuPosition
          menuStyle: $menuStyle
          navigationBackgroundColor: $navigationBackgroundColor
          navigationColor: $navigationColor
          navigationSticky: $navigationSticky
          navigationLogoPostFix: $navigationLogoPostFix
          navigationMobileMenuType: $navigationMobileMenuType
          primaryColor: $primaryColor
          bodyColor: $bodyColor
          bodyLinkColor: $bodyLinkColor
          bodyBackgroundColor: $bodyBackgroundColor
          requestLink: $requestLink
          slug: $slug
          zapsMenuTitle: $zapsMenuTitle
          translations: $translations
        }
      }
    ) {
      ...marketplaceFieldsForSettings
    }
  }
  ${marketplaceFieldsForSettings}
`

export const updateSeoSettingsMutation = gql`
  mutation updateMarketplace(
    $id: ID!
    $name: String!
    $slug: String!
    $accountId: ID!
    $metaTagTitlePostfix: String
    $metaTagTitle: String
    $metaTagKeywords: String
    $metaTagDescription: String
    $metaTagDescriptionCategoryPage: String
    $metaTagDescriptionCollectionPage: String
    $metaTagDescriptionListingPage: String
    $utmCampaign: String
    $listingNamePostfix: String
    $translations: [TranslationInput]
  ) {
    updateMarketplace(
      input: {
        accountId: $accountId
        id: $id
        marketplace: {
          name: $name
          slug: $slug
          metaTagTitlePostfix: $metaTagTitlePostfix
          metaTagTitle: $metaTagTitle
          metaTagKeywords: $metaTagKeywords
          metaTagDescription: $metaTagDescription
          metaTagDescriptionCategoryPage: $metaTagDescriptionCategoryPage
          metaTagDescriptionCollectionPage: $metaTagDescriptionCollectionPage
          metaTagDescriptionListingPage: $metaTagDescriptionListingPage
          utmCampaign: $utmCampaign
          listingNamePostfix: $listingNamePostfix
          translations: $translations
        }
      }
    ) {
      ...marketplaceFieldsForSettings
    }
  }
  ${marketplaceFieldsForSettings}
`

export const updateIntegrationsSettingsMutation = gql`
  mutation updateIntegrationSettings(
    $id: ID!
    $name: String!
    $slug: String!
    $accountId: ID!
    $integrationZapierId: String
    $integrationZapierBetaLink: String
    $zapsMenuTitle: String
    $zapsPageEnabled: Boolean
    $integrationGoogleTagManagerId: String
    $integrationMetomicId: String
    $integrationOnetrustId: String
    $integrationAlbacrossId: String
    $integrationSegmentId: String
    $integrationIubendaSiteId: String
    $integrationIubendaCookiePolicyId: String
    $integrationJournyIoId: String
    $integrationJournyIoDomain: String
    $integrationGoogleAnalyticsId: String
    $integrationHeapId: String
    $integrationHubspotPortalId: String
    $integrationCrispId: String
    $integrationIntercomAppId: String
    $integrationDriftId: String
    $integrationLivechatId: String
    $leadIntegrationEnabled: Boolean
  ) {
    updateIntegrationSettings(
      input: {
        accountId: $accountId
        id: $id
        marketplace: {
          name: $name
          slug: $slug
          integrationZapierId: $integrationZapierId
          integrationZapierBetaLink: $integrationZapierBetaLink
          zapsMenuTitle: $zapsMenuTitle
          zapsPageEnabled: $zapsPageEnabled
          integrationGoogleTagManagerId: $integrationGoogleTagManagerId
          integrationMetomicId: $integrationMetomicId
          integrationOnetrustId: $integrationOnetrustId
          integrationAlbacrossId: $integrationAlbacrossId
          integrationSegmentId: $integrationSegmentId
          integrationIubendaSiteId: $integrationIubendaSiteId
          integrationIubendaCookiePolicyId: $integrationIubendaCookiePolicyId
          integrationJournyIoId: $integrationJournyIoId
          integrationJournyIoDomain: $integrationJournyIoDomain
          integrationGoogleAnalyticsId: $integrationGoogleAnalyticsId
          integrationHeapId: $integrationHeapId
          integrationHubspotPortalId: $integrationHubspotPortalId
          integrationCrispId: $integrationCrispId
          integrationIntercomAppId: $integrationIntercomAppId
          integrationDriftId: $integrationDriftId
          integrationLivechatId: $integrationLivechatId
          leadIntegrationEnabled: $leadIntegrationEnabled
        }
      }
    ) {
      ...marketplaceFieldsForSettings
    }
  }
  ${marketplaceFieldsForSettings}
`

export const updateIntegrationPlatformsSettingsMutation = gql`
  mutation updateMarketplace(
    $id: ID!
    $name: String!
    $slug: String!
    $accountId: ID!
    $zapierEnabled: Boolean
    $segmentEnabled: Boolean
    $blendrEnabled: Boolean
    $automateEnabled: Boolean
    $piesyncEnabled: Boolean
    $microsoftFlowEnabled: Boolean
    $combideskEnabled: Boolean
    $trayIoEnabled: Boolean
    $integromatEnabled: Boolean
  ) {
    updateMarketplace(
      input: {
        accountId: $accountId
        id: $id
        marketplace: {
          name: $name
          slug: $slug
          zapierEnabled: $zapierEnabled
          blendrEnabled: $blendrEnabled
          automateEnabled: $automateEnabled
          piesyncEnabled: $piesyncEnabled
          segmentEnabled: $segmentEnabled
          microsoftFlowEnabled: $microsoftFlowEnabled
          combideskEnabled: $combideskEnabled
          trayIoEnabled: $trayIoEnabled
          integromatEnabled: $integromatEnabled
        }
      }
    ) {
      ...marketplaceFieldsForSettings
    }
  }
  ${marketplaceFieldsForSettings}
`

export const updateLeadsSettingsMutation = gql`
  mutation updateMarketplace(
    $id: ID!
    $accountId: ID!
    $name: String!
    $slug: String!
    $leadTelephoneFieldEnabled: Boolean
    $leadTelephoneFieldRequired: Boolean
    $leadFirstNameFieldEnabled: Boolean
    $leadFirstNameFieldRequired: Boolean
    $leadLastNameFieldEnabled: Boolean
    $leadLastNameFieldRequired: Boolean
    $leadWorkEmailValidation: Boolean
    $leadCaptureFormEnabled: Boolean
  ) {
    updateMarketplace(
      input: {
        accountId: $accountId
        id: $id
        marketplace: {
          name: $name
          slug: $slug
          leadTelephoneFieldEnabled: $leadTelephoneFieldEnabled
          leadTelephoneFieldRequired: $leadTelephoneFieldRequired
          leadFirstNameFieldEnabled: $leadFirstNameFieldEnabled
          leadFirstNameFieldRequired: $leadFirstNameFieldRequired
          leadLastNameFieldEnabled: $leadLastNameFieldEnabled
          leadLastNameFieldRequired: $leadLastNameFieldRequired
          leadWorkEmailValidation: $leadWorkEmailValidation
          leadCaptureFormEnabled: $leadCaptureFormEnabled
        }
      }
    ) {
      ...marketplaceFieldsForSettings
    }
  }
  ${marketplaceFieldsForSettings}
`

export const createNavigationItemMutation = gql`
  mutation createNavigationItem($accountId: ID!, $ecosystemId: ID!, $item: NavigationItemInput!) {
    createNavigationItem(input: { accountId: $accountId, ecosystemId: $ecosystemId, item: $item }) {
      ...navigationItemFields
    }
  }
  ${navigationItemFields}
`

export const updateNavigationItemMutation = gql`
  mutation updateNavigationItem($accountId: ID!, $id: ID!, $item: NavigationItemInput!) {
    updateNavigationItem(input: { accountId: $accountId, id: $id, item: $item }) {
      ...navigationItemFields
    }
  }
  ${navigationItemFields}
`

export const deleteNavigationItemMutation = gql`
  mutation deleteNavigationItem($accountId: ID!, $id: ID!) {
    deleteNavigationItem(input: { accountId: $accountId, id: $id }) {
      id
    }
  }
`

export const createFooterItemMutation = gql`
  mutation createFooterItem($accountId: ID!, $ecosystemId: ID!, $item: FooterItemInput!) {
    createFooterItem(input: { accountId: $accountId, ecosystemId: $ecosystemId, item: $item }) {
      ...footerItemFields
    }
  }
  ${footerItemFields}
`

export const updateFooterItemMutation = gql`
  mutation updateFooterItem($accountId: ID!, $id: ID!, $item: FooterItemInput!) {
    updateFooterItem(input: { accountId: $accountId, id: $id, item: $item }) {
      ...footerItemFields
    }
  }
  ${footerItemFields}
`
export const deleteFooterItemMutation = gql`
  mutation deleteFooterItem($accountId: ID!, $id: ID!) {
    deleteFooterItem(input: { accountId: $accountId, id: $id }) {
      ...footerItemFields
    }
  }
  ${footerItemFields}
`

export const createLocaleMutation = gql`
  mutation createLocale($accountId: ID!, $ecosystemId: ID!, $item: LocaleInput!) {
    createLocale(input: { accountId: $accountId, ecosystemId: $ecosystemId, item: $item }) {
      ...localeFields
    }
  }
  ${localeFields}
`

export const deleteLocaleMutation = gql`
  mutation deleteLocale($accountId: ID!, $id: ID!) {
    deleteLocale(input: { accountId: $accountId, id: $id }) {
      id
      code
    }
  }
`

export const accountUsersQuery = gql`
  query accountUsersQuery($accountId: ID!) {
    accountUsers(accountId: $accountId) {
      id
      role
      inactive
      notificationPreferences {
        weeklyDigest
        listingChanges
      }
      user {
        id
        name
        email
        imageUrl
      }
      invitation {
        id
        createdAt
        updatedAt
        status
      }
    }
  }
`

export const updateUserMutation = gql`
  mutation updateUser($name: String, $email: String, $emailSubscription: Boolean) {
    updateUser(input: { name: $name, email: $email, emailSubscription: $emailSubscription }) {
      ...userFields
    }
  }
  ${userFields}
`

export const invitationsQuery = gql`
  query invitationsQuery($accountId: ID!) {
    invitations(accountId: $accountId) {
      ...invitationFields
    }
  }
  ${invitationFields}
`

export const createInvitationMutation = gql`
  mutation createInvitation($accountId: ID!, $email: String!, $role: USER_ROLE!) {
    createInvitation(input: { accountId: $accountId, invitation: { email: $email, role: $role } }) {
      ...invitationFields
    }
  }
  ${invitationFields}
`

export const deleteInvitationMutation = gql`
  mutation deleteInvitationMutation($id: ID!, $accountId: ID!) {
    deleteInvitation(input: { id: $id, accountId: $accountId }) {
      id
    }
  }
`

export const createAccountAndAddUserAsOwnerMutation = gql`
  mutation (
    $name: String!
    $website: String!
    $plan: String
    $billingCycle: String
    $currency: String
    $referer: String
  ) {
    createAccountAndAddUserAsOwner(
      input: {
        name: $name
        website: $website
        plan: $plan
        billingCycle: $billingCycle
        currency: $currency
        referer: $referer
      }
    ) {
      ...userFields
    }
  }
  ${userFields}
`

export const updateAccountMutation = gql`
  mutation updateAccount($accountId: ID!, $name: String!, $imageUrl: String, $website: String) {
    updateAccount(
      input: { accountId: $accountId, name: $name, imageUrl: $imageUrl, website: $website }
    ) {
      ...accountFields
    }
  }
  ${accountFields}
`

// Listings
export const listingsQuery = gql`
  query listingsQuery($id: ID!, $accountId: ID!) {
    marketplace(id: $id, accountId: $accountId) {
      id
      listings {
        id
        name
        slug
        description
        zapierId
        piesyncId
        segmentId
        blendrId
        automateId
        microsoftFlowId
        combideskId
        trayIoId
        integromatId
        published
        createdAt
        updatedAt
        publishedAt
        translations {
          ...translationFields
        }
        logo {
          ...fileFields
        }
        links {
          ...linkFields
        }
        nativeIntegration
        thirdPartyIntegration
      }
    }
  }
  ${translationFields}
  ${fileFields}
  ${linkFields}
`

export const createListingMutation = gql`
  mutation createListing(
    $accountId: ID!
    $ecosystemId: ID!
    $externalId: String
    $name: String!
    $slug: String!
    $tagLine: String
    $logo: ImageInput
    $sticky: Boolean
    $unifyConnectorId: String
    $nativeIntegration: Boolean
    $thirdPartyIntegration: Boolean
    $nativeIntegrationLink: String
    $thirdPartyIntegrationLink: String
    $cloudServiceId: String
    $detailPageDisabled: Boolean
    $websiteLink: String
    $termsLink: String
    $privacyLink: String
    $supportLink: String
    $upcoming: Boolean
    $published: Boolean
    $translations: [TranslationInput]
    $zapierId: String
    $piesyncId: String
    $blendrId: String
    $segmentId: String
    $combideskId: String
    $integromatId: String
    $automateId: String
    $trayIoId: String
    $microsoftFlowId: String
  ) {
    createListing(
      input: {
        accountId: $accountId
        ecosystemId: $ecosystemId
        listing: {
          externalId: $externalId
          name: $name
          slug: $slug
          tagLine: $tagLine
          logo: $logo
          sticky: $sticky
          unifyConnectorId: $unifyConnectorId
          nativeIntegrationLink: $nativeIntegrationLink
          thirdPartyIntegrationLink: $thirdPartyIntegrationLink
          nativeIntegration: $nativeIntegration
          thirdPartyIntegration: $thirdPartyIntegration
          cloudServiceId: $cloudServiceId
          detailPageDisabled: $detailPageDisabled
          websiteLink: $websiteLink
          termsLink: $termsLink
          privacyLink: $privacyLink
          supportLink: $supportLink
          upcoming: $upcoming
          published: $published
          translations: $translations
          zapierId: $zapierId
          piesyncId: $piesyncId
          blendrId: $blendrId
          segmentId: $segmentId
          combideskId: $combideskId
          integromatId: $integromatId
          automateId: $automateId
          trayIoId: $trayIoId
          microsoftFlowId: $microsoftFlowId
        }
      }
    ) {
      ...listingFields
    }
  }
  ${listingFields}
`

export const updateListingMutation = gql`
  mutation updateListing(
    $accountId: ID!
    $id: ID!
    $externalId: String
    $name: String
    $slug: String
    $description: String
    $features: String
    $pricing: String
    $tagLine: String
    $logo: ImageInput
    $categoryId: ID
    $sticky: Boolean
    $metaTagTitle: String
    $metaTagDescription: String
    $metaTagKeywords: String
    $nativeIntegration: Boolean
    $thirdPartyIntegration: Boolean
    $unifyConnectorId: String
    $nativeIntegrationLink: String
    $thirdPartyIntegrationLink: String
    $cardBackgroundColor: String
    $cardBackgroundImage: ImageInput
    $detailPageDisabled: Boolean
    $upcoming: Boolean
    $translations: [TranslationInput]
    $zapierId: String
    $piesyncId: String
    $blendrId: String
    $segmentId: String
    $combideskId: String
    $integromatId: String
    $automateId: String
    $trayIoId: String
    $microsoftFlowId: String
  ) {
    updateListing(
      input: {
        accountId: $accountId
        id: $id
        listing: {
          externalId: $externalId
          name: $name
          slug: $slug
          description: $description
          features: $features
          pricing: $pricing
          tagLine: $tagLine
          categoryId: $categoryId
          logo: $logo
          sticky: $sticky
          metaTagTitle: $metaTagTitle
          metaTagDescription: $metaTagDescription
          metaTagKeywords: $metaTagKeywords
          unifyConnectorId: $unifyConnectorId
          nativeIntegrationLink: $nativeIntegrationLink
          thirdPartyIntegrationLink: $thirdPartyIntegrationLink
          nativeIntegration: $nativeIntegration
          thirdPartyIntegration: $thirdPartyIntegration
          cardBackgroundColor: $cardBackgroundColor
          cardBackgroundImage: $cardBackgroundImage
          detailPageDisabled: $detailPageDisabled
          upcoming: $upcoming
          translations: $translations
          zapierId: $zapierId
          piesyncId: $piesyncId
          blendrId: $blendrId
          segmentId: $segmentId
          combideskId: $combideskId
          integromatId: $integromatId
          automateId: $automateId
          trayIoId: $trayIoId
          microsoftFlowId: $microsoftFlowId
        }
      }
    ) {
      ...listingFields
    }
  }
  ${listingFields}
`

export const deleteListingMutation = gql`
  mutation deleteListing($accountId: ID!, $id: ID!) {
    deleteListing(input: { accountId: $accountId, id: $id }) {
      ...listingFields
    }
  }
  ${listingFields}
`

export const categoriesQuery = gql`
  query categories($id: ID!, $accountId: ID!) {
    marketplace(id: $id, accountId: $accountId) {
      id
      categories {
        id
        name
        description
        slug
        listingDescriptionTextTemplate
        listingPricingTextTemplate
        listingFeaturesTextTemplate
        logo {
          ...fileFields
        }
        translations {
          ...translationFields
        }
      }
    }
  }

  ${fileFields}
  ${translationFields}
`

export const categoryQuery = gql`
  query categoryQuery($accountId: ID!, $id: ID!) {
    category(id: $id, accountId: $accountId) {
      id
      name
      slug
      description
      logo {
        ...fileFields
      }
      translations {
        ...translationFields
      }
      listingDescriptionTextTemplate
      listingPricingTextTemplate
      listingFeaturesTextTemplate
      listings {
        id
        name
        links {
          id
          url
          type
        }
        logo {
          ...fileFields
        }
        translations {
          ...translationFields
        }
      }
    }
  }
  ${fileFields}
  ${translationFields}
`

export const createCategoryMutation = gql`
  mutation createCategory(
    $accountId: ID!
    $ecosystemId: ID!
    $name: String
    $slug: String!
    $description: String
    $logo: ImageInput
    $translations: [TranslationInput]
  ) {
    createCategory(
      input: {
        accountId: $accountId
        ecosystemId: $ecosystemId
        category: {
          name: $name
          slug: $slug
          description: $description
          logo: $logo
          translations: $translations
        }
      }
    ) {
      id
      name
      slug
      description
      logo {
        ...fileFields
      }
      translations {
        ...translationFields
      }
      listingDescriptionTextTemplate
      listingPricingTextTemplate
      listingFeaturesTextTemplate
    }
  }
  ${fileFields}
  ${translationFields}
`

export const updateCategoryMutation = gql`
  mutation updateCategory(
    $accountId: ID!
    $id: ID!
    $name: String
    $slug: String!
    $description: String
    $listingDescriptionTextTemplate: String
    $listingPricingTextTemplate: String
    $listingFeaturesTextTemplate: String
    $logo: ImageInput
    $translations: [TranslationInput]
  ) {
    updateCategory(
      input: {
        id: $id
        accountId: $accountId
        category: {
          name: $name
          slug: $slug
          description: $description
          listingDescriptionTextTemplate: $listingDescriptionTextTemplate
          listingPricingTextTemplate: $listingPricingTextTemplate
          listingFeaturesTextTemplate: $listingFeaturesTextTemplate
          logo: $logo
          translations: $translations
        }
      }
    ) {
      ...multiCategoryFields
    }
  }
  ${multiCategoryFields}
`

export const deleteCategoryMutation = gql`
  mutation deleteCategory($accountId: ID!, $id: ID!) {
    deleteCategory(input: { accountId: $accountId, id: $id }) {
      id
    }
  }
`

export const addListingToCategoryMutation = gql`
  mutation addListingToCategory($accountId: ID!, $id: ID!, $listingId: ID!) {
    addListingToCategory(input: { accountId: $accountId, id: $id, listingId: $listingId }) {
      ...multiCategoryFields
    }
  }
  ${multiCategoryFields}
`

export const removeListingFromCategoryMutation = gql`
  mutation removeListingFromCategory($accountId: ID!, $id: ID!, $listingId: ID!) {
    removeListingFromCategory(input: { accountId: $accountId, id: $id, listingId: $listingId }) {
      ...multiCategoryFields
    }
  }
  ${multiCategoryFields}
`

export const authenticateMutation = gql`
  mutation authenticateMutation($idToken: String!) {
    authenticate(idToken: $idToken) {
      ...userFields
    }
  }
  ${userFields}
`

export const createUserMutation = gql`
  mutation createUser(
    $auth0UserId: String!
    $email: String!
    $emailSubscription: Boolean!
    $name: String
    $referer: String
  ) {
    createUser(
      input: {
        auth0UserId: $auth0UserId
        email: $email
        emailSubscription: $emailSubscription
        name: $name
        referer: $referer
      }
    ) {
      id
      email
    }
  }
`

export const createScreenshotMutation = gql`
  mutation createScreenshot($accountId: ID!, $listingId: ID!, $screenshot: ScreenshotCreateInput!) {
    createScreenshot(
      input: { accountId: $accountId, listingId: $listingId, screenshot: $screenshot }
    ) {
      ...screenshotFields
    }
  }
  ${screenshotFields}
`

export const updateScreenshotMutation = gql`
  mutation updateScreenshot($accountId: ID!, $id: ID!, $screenshot: ScreenshotUpdateInput!) {
    updateScreenshot(input: { accountId: $accountId, id: $id, screenshot: $screenshot }) {
      ...screenshotFields
    }
  }
  ${screenshotFields}
`

export const deleteScreenshotMutation = gql`
  mutation deleteScreenshot($accountId: ID!, $id: ID!) {
    deleteScreenshot(input: { accountId: $accountId, id: $id }) {
      id
    }
  }
`

export const createMediaMutation = gql`
  mutation createMedia($accountId: ID!, $listingId: ID!, $media: MediaCreateInput!) {
    createMedia(input: { accountId: $accountId, listingId: $listingId, media: $media }) {
      ...mediaFields
    }
  }
  ${mediaFields}
`

export const updateMediaMutation = gql`
  mutation updateMedia($accountId: ID!, $id: ID!, $media: MediaUpdateInput!) {
    updateMedia(input: { accountId: $accountId, id: $id, media: $media }) {
      ...mediaFields
    }
  }
  ${mediaFields}
`

export const deleteMediaMutation = gql`
  mutation deleteMedia($accountId: ID!, $id: ID!) {
    deleteMedia(input: { accountId: $accountId, id: $id }) {
      id
    }
  }
`

export const connectionsQuery = gql`
  query connections($ecosystemId: ID!, $accountId: ID!) {
    connections(ecosystemId: $ecosystemId, accountId: $accountId) {
      ...connectionFields
    }
  }
  ${connectionFields}
`

export const getConnectionQuery = gql`
  query connection($ecosystemId: ID!, $accountId: ID!, $unifiedApiId: ID!, $serviceId: String!) {
    connection(
      ecosystemId: $ecosystemId
      accountId: $accountId
      unifiedApiId: $unifiedApiId
      serviceId: $serviceId
    ) {
      id
      serviceId
      tagLine
      name
      logo
      icon
      authorizeUrl
      revokeUrl
      authType
      unifiedApi
      added
      enabled
      state
      settingsRequiredForAuthorization
      configurableResources
      resourceSchemaSupport
      resourceSettingsSupport
      createdAt
      updatedAt
      formFields {
        formId
        label
        value
        placeholder
        mask
        type
        required
        description
        options {
          value
          label
        }
      }
    }
  }
`

export const createLinkMutation = gql`
  mutation createLink($accountId: ID!, $listingId: ID!, $link: LinkCreateInput!) {
    createLink(input: { accountId: $accountId, listingId: $listingId, link: $link }) {
      ...linkFields
    }
  }
  ${linkFields}
`

export const updateLinkMutation = gql`
  mutation updateLink($accountId: ID!, $id: ID!, $link: LinkUpdateInput!) {
    updateLink(input: { accountId: $accountId, id: $id, link: $link }) {
      ...linkFields
    }
  }
  ${linkFields}
`

export const deleteLinkMutation = gql`
  mutation deleteLink($accountId: ID!, $id: ID!) {
    deleteLink(input: { accountId: $accountId, id: $id }) {
      id
    }
  }
`

export const collectionsQuery = gql`
  query collections($id: ID!, $accountId: ID!) {
    marketplace(id: $id, accountId: $accountId) {
      id
      collections {
        ...collectionFields
      }
    }
  }
  ${collectionFields}
`

export const collectionQuery = gql`
  query collection($id: ID!, $accountId: ID!) {
    collection(id: $id, accountId: $accountId) {
      ...collectionFields
    }
  }
  ${collectionFields}
`

export const createCollectionMutation = gql`
  mutation createCollection(
    $accountId: ID!
    $ecosystemId: ID!
    $name: String
    $slug: String!
    $description: String
    $sequence: Int
    $cardStyle: String
    $visible: Boolean!
    $hiddenFromHomepage: Boolean
    $showMaxItemsHomepage: Int
    $logo: ImageInput
    $translations: [TranslationInput]
  ) {
    createCollection(
      input: {
        accountId: $accountId
        ecosystemId: $ecosystemId
        collection: {
          name: $name
          slug: $slug
          description: $description
          cardStyle: $cardStyle
          visible: $visible
          hiddenFromHomepage: $hiddenFromHomepage
          showMaxItemsHomepage: $showMaxItemsHomepage
          logo: $logo
          sequence: $sequence
          translations: $translations
        }
      }
    ) {
      id
      name
      slug
      description
      visible
      cardStyle
      cardColumns
      hiddenFromHomepage
      showMaxItemsHomepage
      listings {
        id
        name
        slug
        description
        logo {
          ...fileFields
        }
      }
      logo {
        ...fileFields
      }
      cardBackgroundImage {
        ...fileFields
      }
      translations {
        ...translationFields
      }
      cardBackgroundColor
      sequence
    }
  }
  ${translationFields}
  ${fileFields}
`

export const updateCollectionMutation = gql`
  mutation updateCollection(
    $accountId: ID!
    $id: ID!
    $name: String
    $slug: String!
    $description: String
    $cardColumns: Int
    $cardStyle: String
    $visible: Boolean!
    $hiddenFromHomepage: Boolean
    $showMaxItemsHomepage: Int
    $logo: ImageInput
    $sequence: Int
    $cardBackgroundColor: String
    $cardBackgroundImage: ImageInput
    $translations: [TranslationInput]
  ) {
    updateCollection(
      input: {
        accountId: $accountId
        id: $id
        collection: {
          name: $name
          slug: $slug
          description: $description
          cardStyle: $cardStyle
          cardColumns: $cardColumns
          visible: $visible
          hiddenFromHomepage: $hiddenFromHomepage
          showMaxItemsHomepage: $showMaxItemsHomepage
          logo: $logo
          sequence: $sequence
          cardBackgroundColor: $cardBackgroundColor
          cardBackgroundImage: $cardBackgroundImage
          translations: $translations
        }
      }
    ) {
      id
      name
      slug
      description
      visible
      cardStyle
      cardColumns
      hiddenFromHomepage
      showMaxItemsHomepage
      listings {
        id
        name
        slug
        description
        logo {
          ...fileFields
        }
      }
      logo {
        ...fileFields
      }
      cardBackgroundImage {
        ...fileFields
      }
      translations {
        ...translationFields
      }
      cardBackgroundColor
      sequence
    }
  }
  ${translationFields}
  ${fileFields}
`

export const deleteCollectionMutation = gql`
  mutation deleteCollection($accountId: ID!, $id: ID!) {
    deleteCollection(input: { accountId: $accountId, id: $id }) {
      id
    }
  }
`

export const addListingToCollectionMutation = gql`
  mutation addListingToCollection($accountId: ID!, $id: ID!, $listingId: ID!) {
    addListingToCollection(input: { accountId: $accountId, id: $id, listingId: $listingId }) {
      ...collectionFields
    }
  }
  ${collectionFields}
`

export const removeListingFromCollectionMutation = gql`
  mutation removeListingFromCollection($accountId: ID!, $id: ID!, $listingId: ID!) {
    removeListingFromCollection(input: { accountId: $accountId, id: $id, listingId: $listingId }) {
      ...collectionFields
    }
  }
  ${collectionFields}
`

export const productsQuery = gql`
  query productsQuery($ecosystemId: ID!, $accountId: ID!) {
    products(ecosystemId: $ecosystemId, accountId: $accountId) {
      id
      name
      slug
      visible
      sequence
      logo {
        ...fileFields
      }
      translations {
        ...translationFields
      }
    }
  }
  ${fileFields}
  ${translationFields}
`

export const createProductMutation = gql`
  mutation createProduct(
    $accountId: ID!
    $ecosystemId: ID!
    $name: String!
    $sequence: Int
    $slug: String!
    $description: String
    $visible: Boolean!
    $logo: ImageInput
    $translations: [TranslationInput]
  ) {
    createProduct(
      input: {
        accountId: $accountId
        ecosystemId: $ecosystemId
        product: {
          name: $name
          slug: $slug
          description: $description
          visible: $visible
          logo: $logo
          sequence: $sequence
          translations: $translations
        }
      }
    ) {
      id
      name
      slug
      description
      visible
      sequence
      listings {
        id
        name
        slug
        description
        logo {
          ...fileFields
        }
      }
      logo {
        ...fileFields
      }
      translations {
        ...translationFields
      }
    }
  }
  ${fileFields}
  ${translationFields}
`

export const updateProductMutation = gql`
  mutation updateProduct(
    $accountId: ID!
    $id: ID!
    $name: String!
    $slug: String!
    $sequence: Int
    $description: String
    $visible: Boolean!
    $logo: ImageInput
    $translations: [TranslationInput]
  ) {
    updateProduct(
      input: {
        accountId: $accountId
        id: $id
        product: {
          name: $name
          slug: $slug
          description: $description
          visible: $visible
          logo: $logo
          sequence: $sequence
          translations: $translations
        }
      }
    ) {
      id
      name
      slug
      description
      visible
      sequence
      listings {
        id
        name
        slug
        description
        logo {
          ...fileFields
        }
      }
      logo {
        ...fileFields
      }
      translations {
        ...translationFields
      }
    }
  }
  ${fileFields}
  ${translationFields}
`

export const deleteProductMutation = gql`
  mutation deleteProduct($accountId: ID!, $id: ID!) {
    deleteProduct(input: { accountId: $accountId, id: $id }) {
      id
    }
  }
`

export const addListingToProductMutation = gql`
  mutation addListingToProduct($accountId: ID!, $id: ID!, $listingId: ID!) {
    addListingToProduct(input: { accountId: $accountId, id: $id, listingId: $listingId }) {
      ...productFields
    }
  }
  ${productFields}
`

export const removeListingFromProductMutation = gql`
  mutation removeListingFromProduct($accountId: ID!, $id: ID!, $listingId: ID!) {
    removeListingFromProduct(input: { accountId: $accountId, id: $id, listingId: $listingId }) {
      ...productFields
    }
  }
  ${productFields}
`

export const catalogServicesQuery = gql`
  query catalogServicesQuery(
    $page: Int
    $perPage: Int
    $filterText: String
    $filterIntegrationPlatform: String
  ) {
    services(
      input: {
        page: $page
        perPage: $perPage
        filters: { text: $filterText, integrationPlatform: $filterIntegrationPlatform }
      }
    ) {
      ...catalogServicePage
    }
  }
  ${catalogServicePage}
`

export const catalogServiceQuery = gql`
  query catalogServiceQuery($id: String!) {
    service(input: { id: $id }) {
      ...catalogServiceFields
    }
  }
  ${catalogServiceFields}
`

export const importServiceMutation = gql`
  mutation importServiceMutation(
    $accountId: ID!
    $id: String!
    $ecosystemId: ID!
    $category: ImportServiceCategoryInput
    $upcoming: Boolean
    $published: Boolean
  ) {
    importService(
      input: {
        accountId: $accountId
        category: $category
        upcoming: $upcoming
        published: $published
        ecosystemId: $ecosystemId
        id: $id
      }
    ) {
      ...listingFields
    }
  }
  ${listingFields}
`

export const searchResultFields = gql`
  fragment searchResultFields on SearchResult {
    query
    count
  }
`

export const searchesDashboardQuery = gql`
  query searches($accountId: ID!, $ecosystemId: ID!) {
    searches(input: { accountId: $accountId, ecosystemId: $ecosystemId, type: TOP }) {
      ...searchResultFields
    }
  }
  ${searchResultFields}
`

export const noSearchResultsDashboardQuery = gql`
  query searches($accountId: ID!, $ecosystemId: ID!) {
    searches(input: { accountId: $accountId, ecosystemId: $ecosystemId, type: NO_RESULTS }) {
      ...searchResultFields
    }
  }
  ${searchResultFields}
`

const keenAnalyticsResultFields = gql`
  fragment keenAnalyticsResultFields on AnalyticsResult {
    pageViewsByDate {
      date
      count
    }
    pageViewsByPage {
      id
      name
      slug
      icon
      pageType
      count
      path
    }
    clicksByDay {
      date
      clicks {
        result
        href
      }
    }
    clicks {
      result
      href
    }
  }
`

export const keenAnalyticsQuery = gql`
  query keenAnalytics($accountId: ID!, $ecosystemId: ID!, $period: String!) {
    keenAnalytics(input: { accountId: $accountId, ecosystemId: $ecosystemId, period: $period }) {
      ...keenAnalyticsResultFields
    }
  }
  ${keenAnalyticsResultFields}
`

export const checkInvitationQuery = gql`
  query checkInvitation($challenge: String!) {
    invitation: checkInvitation(input: { challenge: $challenge }) {
      id
      createdAt
      updatedAt
    }
  }
`

export const activateInvitationMutation = gql`
  mutation activateInvitation($challenge: String!, $auth0UserId: String!) {
    activateInvitation(input: { challenge: $challenge, auth0UserId: $auth0UserId }) {
      success
    }
  }
`

export const publishListingMutation = gql`
  mutation publishListing($accountId: String!, $id: String!) {
    publishListing(input: { accountId: $accountId, id: $id }) {
      ...listingFields
    }
  }
  ${listingFields}
`

export const unpublishListingMutation = gql`
  mutation unpublishListing($accountId: String!, $id: String!) {
    unpublishListing(input: { accountId: $accountId, id: $id }) {
      ...listingFields
    }
  }
  ${listingFields}
`

export const leadsQuery = gql`
  query leadsQuery($accountId: ID!, $ecosystemId: ID!, $pageSize: Int) {
    leads(input: { accountId: $accountId, ecosystemId: $ecosystemId, pageSize: $pageSize }) {
      ...leadFields
    }
  }
  ${leadFields}
`

export const deleteLeadMutation = gql`
  mutation deleteLead($accountId: ID!, $id: ID!) {
    deleteLead(input: { accountId: $accountId, id: $id }) {
      id
    }
  }
`

export const importIntegrationsByService = gql`
  mutation importIntegrationsByService($accountId: ID!, $ecosystemId: ID!, $serviceId: String!) {
    importIntegrationsByService(
      input: { accountId: $accountId, ecosystemId: $ecosystemId, serviceId: $serviceId }
    ) {
      ...listingFields
    }
  }
  ${listingFields}
`

export const updateConnectionMutation = gql`
  mutation updateConnection(
    $accountId: ID!
    $ecosystemId: ID!
    $unifiedApi: String!
    $serviceId: String!
    $enabled: Boolean
    $settings: [ConnectionSettingsInput]
    $metadata: ConnectionMetadataInput
  ) {
    updateConnection(
      input: {
        accountId: $accountId
        ecosystemId: $ecosystemId
        unifiedApi: $unifiedApi
        serviceId: $serviceId
        enabled: $enabled
        settings: $settings
        metadata: $metadata
      }
    ) {
      ...connectionFields
    }
  }
  ${connectionFields}
`

export const deleteConnectionMutation = gql`
  mutation deleteConnection(
    $accountId: ID!
    $ecosystemId: ID!
    $unifiedApi: String!
    $serviceId: String!
  ) {
    deleteConnection(
      input: {
        accountId: $accountId
        ecosystemId: $ecosystemId
        unifiedApi: $unifiedApi
        serviceId: $serviceId
      }
    ) {
      success
    }
  }
`

export const createIntegration = gql`
  mutation createIntegration($input: CreateIntegrationInput!) {
    createIntegration(input: $input) {
      ...integrationFields
    }
  }
  ${integrationFields}
`

export const updateIntegration = gql`
  mutation updateIntegration($input: UpdateIntegrationInput!) {
    updateIntegration(input: $input) {
      ...integrationFields
    }
  }
  ${integrationFields}
`

export const deleteIntegration = gql`
  mutation deleteIntegration($input: DeleteIntegrationInput!) {
    deleteIntegration(input: $input) {
      id
      success
    }
  }
`

export const allocationFields = gql`
  fragment allocationFields on Allocation {
    unify
    vault
    proxy
  }
`

export const consumerMetadataFields = gql`
  fragment consumerMetadataFields on ConsumerMetadata {
    accountName
    userName
    image
  }
`

export const getApplication = gql`
  query getApplication($accountId: ID!, $ecosystemId: ID!) {
    application(accountId: $accountId, ecosystemId: $ecosystemId) {
      id
      apiKey
      requestCountAllocation {
        ...allocationFields
      }
    }
  }
  ${allocationFields}
`

export const getConsumers = gql`
  query getConsumers($accountId: ID!, $ecosystemId: ID!) {
    consumers(accountId: $accountId, ecosystemId: $ecosystemId) {
      id
      totalRequests
      services
      requestCounts {
        ...allocationFields
      }
      metadata {
        ...consumerMetadataFields
      }
    }
  }
  ${allocationFields}
  ${consumerMetadataFields}
`

export const getConsumer = gql`
  query getConsumer($accountId: ID!, $ecosystemId: ID!, $consumerId: String!) {
    consumer(accountId: $accountId, ecosystemId: $ecosystemId, consumerId: $consumerId) {
      id
      totalRequests
      requestCounts {
        ...allocationFields
      }
      connections {
        ...connectionFields
      }
      metadata {
        ...consumerMetadataFields
      }
    }
  }
  ${allocationFields}
  ${connectionFields}
  ${consumerMetadataFields}
`

export const createApplication = gql`
  mutation createApplication($accountId: ID!, $ecosystemId: ID!) {
    createApplication(accountId: $accountId, ecosystemId: $ecosystemId) {
      ...marketplaceFields
    }
  }
  ${marketplaceFields}
`

export const updateApplication = gql`
  mutation updateApplication($accountId: ID!, $ecosystemId: ID!) {
    updateApplication(accountId: $accountId, ecosystemId: $ecosystemId) {
      id
      apiKey
    }
  }
`

export const checkListingForPending = gql`
  query checkForPending($accountId: ID!, $id: ID!) {
    checkForPending(accountId: $accountId, id: $id) {
      id
    }
  }
`
