import { LetterAvatar, Table } from 'components'
import React, { Fragment, useMemo } from 'react'

import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'

function normalizePartnerData(ecosystemId, partners) {
  return partners.map((p) => ({
    id: p.id,
    name: p.company,
    icon: p.icon && p.icon.url,
    link: `/ecosystems/${ecosystemId}/partners/${p.id}`,
    contacts: p.contacts,
    listings: p.listings.map((l) => ({
      id: l.id,
      logo: l.logo ? l.logo.url : '',
      name: l.name
    }))
  }))
}

function AllPartnersTable({ data, ecosystemId }) {
  const history = useHistory()
  const normalizedData = useMemo(() => normalizePartnerData(ecosystemId, data), [data, ecosystemId])

  const columns = [
    {
      Header: '',
      accessor: 'icon',
      Cell: ({ cell, row }) => {
        return (
          <div
            onClick={() => {
              history.push(row.original.link)
            }}
          >
            <img src={cell.value} alt="icon" />
          </div>
        )
      },
      cursor: 'pointer',
      width: 10,
      padding: 0
    },
    {
      Header: 'Name',
      accessor: 'name',
      Cell: ({ cell, row }) => {
        return (
          <div
            onClick={() => {
              history.push(row.original.link)
            }}
          >
            {cell.value}
          </div>
        )
      },
      cursor: 'pointer'
    },
    {
      Header: 'Listings',
      accessor: 'listings',
      Cell: ({ cell }) => {
        const listings = cell.value

        if (listings && listings.length > 0) {
          return (
            <Fragment>
              {listings.map((listing) => (
                <img
                  alt={listing.name}
                  src={listing.logo}
                  key={listing.id}
                  style={{
                    width: '24px',
                    height: '24px',
                    marginLeft: '5px',
                    marginRight: '5px'
                  }}
                />
              ))}
            </Fragment>
          )
        }

        return <Fragment />
      }
    },
    {
      Header: 'Contacts',
      accessor: 'contacts',
      Cell: ({ cell }) => {
        const contacts = cell.value

        if (contacts && contacts.length > 0) {
          return (
            <div style={{ display: 'flex' }}>
              {contacts.map((contact, i) => (
                <LetterAvatar
                  key={i}
                  title={contact.firstName + contact.lastName}
                  size={24}
                  style={{ marginLeft: 4, height: '24px', width: '24px' }}
                />
              ))}
            </div>
          )
        }

        return <Fragment />
      }
    },
    {
      Header: 'Actions',
      id: 'actions',
      Cell: ({ row }) => {
        return (
          <span
            onClick={() => {
              history.push(`${row.original.link}/settings`)
            }}
          >
            Edit
          </span>
        )
      },
      cursor: 'pointer',
      numeric: true,
      width: 15
    }
  ]

  return <Table columns={columns} data={normalizedData} hoverOnRows />
}

AllPartnersTable.propTypes = {
  data: PropTypes.array.isRequired,
  ecosystemId: PropTypes.string.isRequired
}

export default AllPartnersTable
