const transformError = (graphQLErrors) => {
  return graphQLErrors.reduce((acc, graphQLError) => {
    if (graphQLError?.extensions?.exception?.invalidArgs) {
      acc = {
        ...acc,
        ...graphQLError.extensions.exception.invalidArgs
      }
    } else if (graphQLError.error) {
      acc = {
        ...acc,
        ...graphQLError.error
      }
    }
    return acc
  }, {})
}

export default transformError
