import { default as Button } from '@material-ui/core/Button'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import { Card, Fieldset, MarketplaceLocales, Submit } from 'components'
import { Formik } from 'formik'
import PropTypes from 'prop-types'
import React, { Fragment, useState } from 'react'
import * as yup from 'yup'
import DeleteEcosystemModal from '../../../modals/DeleteEcosystemModal'
import {
  baseValidationSchema,
  IconFieldset,
  NameField,
  SlugField,
  SocialImageField,
  WebsiteField
} from '../../forms/Base'

const schema = yup.object().shape({
  ...baseValidationSchema
})

export const GeneralForm = ({
  initialValues,
  handleSubmit,
  ecosystem,
  handleLocaleCreate,
  handleLocaleUpdate,
  handleLocaleDelete,
  handleEcosystemSocialImageDelete,
  handleEcosystemIconDelete,
  handleEcosystemFaviconDelete,
  handleEcosystemDelete,
  activeAccount
}) => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const i18nAllowedPlans = ['SCALE', 'ENTERPRISE']

  return (
    <Formik validationSchema={schema} initialValues={initialValues} onSubmit={handleSubmit}>
      {(formikProps) => {
        const { handleSubmit, isSubmitting, errors } = formikProps
        return (
          <Fragment>
            <form onSubmit={handleSubmit} data-testid="ecosystem-settings-general-form">
              <Fieldset>
                {errors.general && <span className="errors">{errors.general}</span>}
                <NameField formikProps={formikProps} />
                <SlugField formikProps={formikProps} />
                <WebsiteField formikProps={formikProps} />
              </Fieldset>
              <IconFieldset
                formikProps={formikProps}
                ecosystem={ecosystem}
                handleIconDelete={() => handleEcosystemIconDelete(ecosystem.id)}
                handleFaviconDelete={() => handleEcosystemFaviconDelete(ecosystem.id)}
              />
              {i18nAllowedPlans.includes(activeAccount?.plan) && (
                <MarketplaceLocales
                  items={ecosystem.locales}
                  handleCreate={handleLocaleCreate}
                  handleUpdate={handleLocaleUpdate}
                  handleDelete={handleLocaleDelete}
                />
              )}
              <Fieldset title="Social">
                <SocialImageField
                  formikProps={formikProps}
                  handleSocialImageDelete={() => handleEcosystemSocialImageDelete(ecosystem.id)}
                />
              </Fieldset>
              {activeAccount?.marketplaces?.length > 1 && (
                <Card title="Danger zone" style={{ marginTop: '24px' }}>
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    type="button"
                    className="delete"
                    startIcon={<DeleteForeverIcon />}
                    onClick={() => setIsDeleteModalOpen(true)}
                  >
                    Delete ecosystem
                  </Button>

                  <DeleteEcosystemModal
                    isOpen={isDeleteModalOpen}
                    ecosystem={ecosystem}
                    closeModal={() => setIsDeleteModalOpen(false)}
                    handleEcosystemDelete={handleEcosystemDelete}
                  />
                </Card>
              )}
              <div className="sticky-submit-container">
                <Submit isSubmitting={isSubmitting} text="Save settings" />
              </div>
            </form>
          </Fragment>
        )
      }}
    </Formik>
  )
}

GeneralForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object
}

export default GeneralForm
