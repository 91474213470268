import { ErrorFallback, ListToggle, LoadingPlaceholder } from 'components'
import { Link, withRouter } from 'react-router-dom'

import { CardIcon } from 'components'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import PropTypes from 'prop-types'
import { default as React } from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import { compose } from 'recompose'
import getLocalizedValue from 'utils/getLocalizedValue'
import { listingLogo } from 'components/Helpers'
import { listingsQuery } from 'api/queries'
import timeAgo from 'time-ago'
import { useQuery } from 'react-apollo'
import { withStyles } from '@material-ui/core/styles'

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy)
}

const EntranceTable = ({ ecosystem, activeAccount, classes, history }) => {
  const { loading, error, data } = useQuery(listingsQuery, {
    variables: {
      id: ecosystem.id,
      accountId: activeAccount.id
    },
    notifyOnNetworkStatusChange: true
  })

  if (loading && !data) {
    return <LoadingPlaceholder />
  }

  if (error) {
    return <ErrorFallback error={error} />
  }

  if (data) {
    return (
      <EnhancedTable
        data={data.marketplace.listings}
        ecosystem={ecosystem}
        classes={classes}
        history={history}
      />
    )
  }
}

class EnhancedTableHead extends React.Component {
  createSortHandler = (property) => (event) => {
    this.props.onRequestSort(event, property)
  }

  render() {
    const { order, orderBy } = this.props
    const rows = []
    rows.push(
      {
        id: 'icon',
        numeric: false,
        label: '',
        sort: false
      },
      {
        id: 'name',
        numeric: false,
        label: 'Name',
        sort: true
      },
      {
        id: 'publishedAt',
        numeric: true,
        label: 'Published',
        sort: true,
        width: '180px'
      },
      {
        id: 'createdAt',
        numeric: true,
        label: 'Created',
        sort: true,
        width: '180px'
      }
    )

    return (
      <TableHead>
        <TableRow>
          {rows.map((row) => {
            const sortLabel = (
              <TableSortLabel
                active={orderBy === row.id}
                direction={order}
                onClick={this.createSortHandler(row.id)}
              >
                {row.label}
              </TableSortLabel>
            )

            return (
              <TableCell
                key={row.id}
                sortDirection={orderBy === row.id ? order : false}
                style={{
                  textAlign: row.numeric ? 'right' : 'left',
                  width: row.width ? row.width : 'auto'
                }}
              >
                {row.sort ? sortLabel : row.label}
              </TableCell>
            )
          }, this)}
        </TableRow>
      </TableHead>
    )
  }
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired
}

const styles = (theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3)
  },
  gridItemsWrap: {
    display: 'flex',
    alignItems: 'center'
  },
  title: {
    fontSize: '16px',
    fontWeight: 500,
    color: '#001b2f'
  },
  table: {
    minWidth: '1020px'
  },
  tableWrapper: {
    overflowX: 'auto'
  },
  tableCell: {
    fontSize: '13px'
  },
  chip: {
    marginRight: '10px',
    backgroundColor: 'transparent',
    border: `1px solid ${theme.palette.ui[200]}`,
    fontSize: '12px'
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start'
  },
  avatar: {
    width: '16px',
    height: '16px',
    marginRight: theme.spacing(1)
  }
})

class EnhancedTable extends React.Component {
  state = {
    order: 'desc',
    orderBy: 'createdAt',
    data: [],
    filteredData: [],
    page: 0,
    rowsPerPage: 50
  }

  handleRequestSort = (event, property) => {
    const orderBy = property
    let order = 'desc'

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc'
    }

    this.setState({ order, orderBy })
  }

  handleChangePage = (event, page) => {
    this.setState({ page })
  }

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value })
  }

  render() {
    const { classes, data, ecosystem } = this.props
    const { order, orderBy, rowsPerPage, page, filteredData } = this.state

    return (
      <div>
        <div style={{ marginBottom: '12px' }}>
          <ListToggle view="list" ecosystemId={ecosystem.id} />
        </div>
        <Paper style={{ position: 'relative' }}>
          <Toolbar>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item>
                <Typography className={classes.title}>Listings</Typography>
              </Grid>
            </Grid>
          </Toolbar>
          <Divider />
          <TableContainer>
            <Table className={classes.table} aria-label="Listings table" size="medium" stickyHeader>
              {data.length === 0 && <caption>No records available.</caption>}
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={this.handleRequestSort}
                rowCount={filteredData.length}
              />
              <TableBody>
                {stableSort(data, getSorting(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((listing) => {
                    return (
                      <TableRow
                        hover
                        tabIndex={-1}
                        key={listing.id}
                        onClick={() => {
                          this.props.history.push(
                            `/ecosystems/${ecosystem.id}/listings/${listing.id}/overview`
                          )
                        }}
                      >
                        <TableCell padding="checkbox">
                          <div style={{ marginLeft: '16px' }}>
                            <CardIcon
                              imageSrc={listingLogo(listing)}
                              title={listing.name}
                              small={true}
                            />
                          </div>
                        </TableCell>
                        <TableCell>
                          <Link to={`/ecosystems/${ecosystem.id}/listings/${listing.id}/overview`}>
                            {getLocalizedValue(listing, 'name', ecosystem.locales)}
                          </Link>
                        </TableCell>
                        <TableCell align="right">
                          <span title={listing.publishedAt}>
                            {listing.published &&
                              listing.publishedAt &&
                              timeAgo.ago(listing.publishedAt)}
                          </span>
                        </TableCell>
                        <TableCell align="right">
                          <span title={listing.publishedAt}>{timeAgo.ago(listing.createdAt)}</span>
                        </TableCell>
                      </TableRow>
                    )
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              'aria-label': 'Previous Page'
            }}
            nextIconButtonProps={{
              'aria-label': 'Next Page'
            }}
            onPageChange={this.handleChangePage}
            onRowsPerPageChange={this.handleChangeRowsPerPage}
          />
        </Paper>
      </div>
    )
  }
}

EnhancedTable.propTypes = {
  classes: PropTypes.object.isRequired
}

export default compose(withRouter, withStyles(styles))(EntranceTable)
