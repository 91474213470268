import React from 'react'
import PropTypes from 'prop-types'
import Service from '../Service'
import Grid from '@material-ui/core/Grid'
import { AutoSizer, List, WindowScroller } from 'react-virtualized'

const NUM_ITEMS_PER_ROW = 6

function MatchedServicesGrid({ handleServiceClick, services }) {
  const rowCount = Math.round(services.length / 6)
  const rowRenderer = ({ index, key, style }) => {
    const fromIndex = index * NUM_ITEMS_PER_ROW
    const toIndex = Math.min(fromIndex + NUM_ITEMS_PER_ROW, services.length)

    return (
      <Grid container key={key} style={style} className="servicesRow" spacing={2}>
        {services.slice(fromIndex, toIndex).map((service) => (
          <Service
            action={service.imported ? 'Remove' : 'Import'}
            handleClick={handleServiceClick}
            key={service.id}
            imported={service.imported}
            service={service}
          />
        ))}
      </Grid>
    )
  }

  return (
    <WindowScroller>
      {({ height, isScrolling, scrollTop, onChildScroll }) => (
        <AutoSizer disableHeight>
          {({ width }) => (
            <List
              autoHeight
              height={height}
              rowHeight={200}
              rowCount={rowCount}
              isScrolling={isScrolling}
              scrollTop={scrollTop}
              onScroll={onChildScroll}
              rowRenderer={rowRenderer}
              width={width}
            />
          )}
        </AutoSizer>
      )}
    </WindowScroller>
  )
}

MatchedServicesGrid.propTypes = {
  handleServiceClick: PropTypes.func.isRequired,
  // List of services
  services: PropTypes.array.isRequired
}

export default MatchedServicesGrid
