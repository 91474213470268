import React from 'react'
import PropTypes from 'prop-types'

const ExternalA = ({ href, children, ...rest }) => (
  <a target="_blank" rel="noopener noreferrer" href={href} {...rest}>
    {children}
  </a>
)

ExternalA.propTypes = {
  href: PropTypes.string.isRequired
}

export default ExternalA
