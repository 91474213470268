const fields = {
  listing: ['name', 'tagLine', 'features', 'pricing', 'description'],
  listingLinks: ['name'],
  listingScreenshots: ['caption'],
  listingMedia: ['caption'],
  category: ['name', 'description'],
  collection: ['name', 'description'],
  product: ['name', 'description'],
  navigationItem: ['label'],
  footerItem: ['label'],
  ecosystemTemplateSettings: [
    'mastheadTitle',
    'mastheadDescription',
    'collectionsTitle',
    'zapsMenuTitle',
    'ctaTitle',
    'ctaDescription',
    'ctaButtonLabel'
  ],
  ecosystemListingSettings: ['shadowPageDescription', 'about'],
  ecosystemSeoSettings: [
    'metaTagTitlePostfix',
    'metaTagTitle',
    'metaTagKeywords',
    'metaTagDescription',
    'metaTagDescriptionCategoryPage',
    'metaTagDescriptionCollectionPage',
    'metaTagDescriptionCollectionPage',
    'metaTagDescriptionListingPage',
    'metaTagDescriptionListingPage',
    'listingNamePostfix'
  ]
}

export default fields
