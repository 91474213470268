import * as yup from 'yup'

import { FormDialog, SelectInput, TextInput, injectLocalizedValues } from 'components'
import React, { Component } from 'react'

import PropTypes from 'prop-types'

// const localizedBaseValidation = () =>

const schema = yup.object().shape({
  url: yup.string().required('URL is a required field'),
  type: yup.string().required('Type is a required field')
})

class LinkDialog extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired
  }

  static defaultProps = {
    link: null
  }

  handleOpen = (openWithState) => () => {
    const { link, locales } = this.props
    const title = link ? 'Edit Link' : 'New Link'

    let initialValues = {
      id: link ? link.id : null,
      name: link ? link.name : '',
      url: link ? link.url : '',
      type: link ? link.type : '',
      sequence: link ? link.sequence : this.props.sequence ?? 0,
      locales
    }

    if (link) {
      initialValues = injectLocalizedValues('listingLinks', initialValues, link)
    }

    openWithState({
      title,
      initialValues: initialValues,
      validationSchema: schema,
      render: this.renderFields,
      onSubmit: this.props.handleSubmit
    })
  }

  renderFields = (formikProps) => (
    <fieldset>
      <SelectInput
        formikProps={formikProps}
        label="Type*"
        field="type"
        placeholder="Select type"
        required
        options={[
          {
            label: 'Website',
            value: 'WEBSITE'
          },
          {
            label: 'Privacy policy',
            value: 'PRIVACY'
          },
          {
            label: 'Terms',
            value: 'TERMS'
          },
          {
            label: 'Support',
            value: 'SUPPORT'
          },
          {
            label: 'Other',
            value: 'OTHER'
          }
        ]}
      />
      <TextInput formikProps={formikProps} label="Label" field="name" localized="true" required />
      <TextInput formikProps={formikProps} label="Url or email (mailto:..)" field="url" required />
      <TextInput
        formikProps={formikProps}
        field="sequence"
        type="hidden"
        style={{ display: 'none' }}
      />
    </fieldset>
  )

  render() {
    return (
      <FormDialog
        opener={(openWithState) => (
          <div onClick={this.handleOpen(openWithState)}>{this.props.opener}</div>
        )}
      />
    )
  }
}

export default LinkDialog
