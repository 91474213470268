import PropTypes from 'prop-types'
import React from 'react'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  page: {
    padding: theme.spacing(4)
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '40px',
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(8),
    paddingBottom: theme.spacing(1),
    borderBottom: `1px solid ${theme.palette.ui[200]}`,
    '&:first-of-type': {
      marginTop: 0
    }
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    fontSize: '18px',
    color: theme.palette.ui[700],
    marginRight: theme.spacing(1)
  },
  chipContainer: {
    lineHeight: 1,
    marginLeft: theme.spacing(1)
  }
}))

const ContentTitle = ({ label, chipComponent, actionComponent = '' }) => {
  const classes = useStyles()

  return (
    <div className={classes.titleContainer}>
      <Typography className={classes.title} component="div">
        {label}
        {chipComponent && <div className={classes.chipContainer}>{chipComponent}</div>}
      </Typography>
      {actionComponent}
    </div>
  )
}

ContentTitle.defaultProps = {
  chipComponent: null,
  actionComponent: null
}

ContentTitle.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  chipComponent: PropTypes.element,
  actionComponent: PropTypes.element
}

export default ContentTitle
