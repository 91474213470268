import React from 'react'
import styled from 'react-emotion'
import { PrivacyLink, TermsLink } from 'components'

const P = styled('p')`
  font-size: 12px;
`

const LegalNotice = () => (
  <P>
    By clicking this button, you agree to the <TermsLink /> and <PrivacyLink />.
  </P>
)

export default LegalNotice
