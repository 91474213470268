import React from 'react'
import PropTypes from 'prop-types'
import ServicesFromNetwork from './ServicesFromNetwork'
import ServicesFromMatchedService from './ServicesFromMatchedService'

function ServicesPage(props) {
  const hasIntegrations = props.service && props.service.integrations.length > 0

  if (hasIntegrations) {
    return (
      <ServicesFromMatchedService
        activeAccount={props.activeAccount}
        ecosystem={props.ecosystem}
        handleComplete={props.handleComplete}
        service={props.service}
      />
    )
  }

  return (
    <ServicesFromNetwork
      cloudService={props.cloudService}
      activeAccount={props.activeAccount}
      ecosystem={props.ecosystem}
      handleComplete={props.handleComplete}
    />
  )
}

ServicesPage.propTypes = {
  activeAccount: PropTypes.object.isRequired,
  service: PropTypes.object,
  ecosystem: PropTypes.object.isRequired,
  handleComplete: PropTypes.func.isRequired
}

export default ServicesPage
