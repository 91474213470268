import * as yup from 'yup'

import { FormDialog, TextInput, injectLocalizedValues } from 'components'
import React, { Component } from 'react'

import PropTypes from 'prop-types'

const schema = yup.object().shape({
  url: yup.string().url().required('URL is a required field')
})

class VideoDialog extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    handleDelete: PropTypes.func.isRequired
  }

  static defaultProps = {
    media: null
  }

  handleOpen = (openWithState) => () => {
    const { media, locales } = this.props
    const title = media ? 'Edit Video' : 'New Video'
    const contentText = media
      ? 'Add a video with a descriptive caption'
      : 'Update video with a descriptive caption'

    let initialValues = {
      id: media ? media.id : null,
      caption: media ? media.caption : '',
      url: media ? media.url : '',
      locales
    }

    if (media) {
      initialValues = injectLocalizedValues('listingMedia', initialValues, media)
    }

    openWithState({
      title,
      contentText,
      initialValues,
      validationSchema: schema,
      render: this.renderFields,
      onSubmit: this.props.handleSubmit,
      onDelete: media ? this.props.handleDelete : null
    })
  }

  renderFields = (formikProps) => (
    <fieldset>
      <TextInput
        formikProps={formikProps}
        label="Url"
        field="url"
        placeholder="Add a Wistia, Youtube, Facebook, or Vimeo link."
        required
      />
      <TextInput formikProps={formikProps} label="Caption" field="caption" localized="true" />
    </fieldset>
  )

  render() {
    return (
      <FormDialog
        opener={(openWithState) => (
          <div onClick={this.handleOpen(openWithState)}>{this.props.opener}</div>
        )}
      />
    )
  }
}

export default VideoDialog
