import Button from '@material-ui/core/Button'
import { HeaderWithTabs } from 'components'
import Navigation from './Navigation'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import PropTypes from 'prop-types'
import React from 'react'
import { withRouter } from 'react-router-dom'

const REQUEST_APIDECK_INTEGRATION_LINK = 'https://integrations.apideck.com/request'

const SettingsHeader = ({ match, activePath, activeAccount }) => {
  const isPathIntegrations = activePath.split('/').pop() === 'integrations'

  return (
    <HeaderWithTabs tabs={<Navigation match={match} activeAccount={activeAccount} />}>
      <h1>Settings</h1>
      {isPathIntegrations && (
        <Button
          className="fr"
          target="_blank"
          href={REQUEST_APIDECK_INTEGRATION_LINK}
          endIcon={
            <OpenInNewIcon
              style={{
                color: 'currentColor',
                fontSize: 16
              }}
            />
          }
        >
          Request Integration
        </Button>
      )}
    </HeaderWithTabs>
  )
}

SettingsHeader.propTypes = {
  activePath: PropTypes.string.isRequired
}

export default withRouter(SettingsHeader)
