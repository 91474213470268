import { CartesianGrid, Line, LineChart, Tooltip, XAxis, YAxis } from 'recharts'

import { CardNoData } from 'components'
import DashboardCard from './Card'
import React from 'react'

function hashCode(str) {
  var hash = 0
  for (var i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash)
  }
  return hash
}

function intToRGB(i) {
  var c = (i & 0x00ffffff).toString(16).toUpperCase()

  return '00000'.substring(0, 6 - c.length) + c
}

const keys = (data) => {
  let uniqueKeys = [new Set()]
  data.forEach((a) => {
    Object.entries(a).forEach(([key]) => {
      if (key !== 'date') {
        uniqueKeys = [...new Set(uniqueKeys).add(stripUtmFromUrl(key))]
      }
    })
  })

  return uniqueKeys
}

const stripUtmFromUrl = (url) => url.split('?')[0]

const normalizeData = (data) => {
  const normalized = data.map((d) => {
    const r = {}
    r.date = d.date

    d.clicks.length > 0 &&
      d.clicks.forEach((c) => {
        r[stripUtmFromUrl(c.href)] = c.result
      })

    return r
  })

  return normalized
}

class ClicksCard extends React.Component {
  render() {
    let { title, data, loading } = this.props

    if (!loading) {
      data = normalizeData(data)
    }

    return (
      <DashboardCard title={title} size={12} loading={loading}>
        {data && data.length > 0 ? (
          <LineChart
            width={1120}
            height={230}
            data={data}
            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
            barCategoryGap={8}
          >
            <XAxis dataKey="date" />
            <YAxis />
            <CartesianGrid strokeDasharray="3 3" />
            <Tooltip />
            {keys(data).map((d, i) => {
              return (
                <Line
                  type="monotone"
                  key={i}
                  dataKey={d}
                  stroke={`#${intToRGB(hashCode(d))}`}
                  activeDot={{ r: 8 }}
                />
              )
            })}
          </LineChart>
        ) : (
          <CardNoData />
        )}
      </DashboardCard>
    )
  }
}

export default ClicksCard
