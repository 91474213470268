import { ContentContainer, ErrorFallback, LoadingPlaceholder } from 'components'

import { translationFieldsFragment } from 'api/fragments'
import gql from 'graphql-tag'
import PropTypes from 'prop-types'
import React from 'react'
import { useQuery } from 'react-apollo'
import getLocalizedValue from 'utils/getLocalizedValue'
import PartnerForm from '../Form'
import Header from './Header'
import { useCreatePartnerAction } from './useCreatePartnerAction'

const listingsQuery = gql`
  query marketplace($id: ID!, $accountId: ID!) {
    marketplace(id: $id, accountId: $accountId) {
      id
      listings {
        id
        slug
        name
        state
        translations {
          ...translationFields
        }
      }
    }
  }

  ${translationFieldsFragment}
`

// TODO moves to pages folder

const CreatePartnerPage = ({ activeAccount, ecosystem }) => {
  const [createPartnerAction] = useCreatePartnerAction({
    accountId: activeAccount.id,
    ecosystemId: ecosystem.id
  })
  const { loading, error, data } = useQuery(listingsQuery, {
    variables: {
      id: ecosystem.id,
      accountId: activeAccount.id
    }
  })

  if (loading) return <LoadingPlaceholder />
  if (error) return <ErrorFallback error={error} />

  const { marketplace } = data
  const { listings } = marketplace

  return (
    <ContentContainer header={<Header />}>
      <PartnerForm
        action={'create'}
        initialValues={{
          icon: [],
          name: '',
          listed: '',
          listings: [],
          website: '',
          twitter: ''
        }}
        handleSubmit={createPartnerAction}
        listings={listings
          .filter((listing) => listing.state === 'APPROVED')
          .map((listing) => {
            return {
              value: listing.id,
              label: getLocalizedValue(listing, 'name', ecosystem.locales)
            }
          })}
      />
    </ContentContainer>
  )
}

CreatePartnerPage.propTypes = {
  activeAccount: PropTypes.object.isRequired,
  ecosystem: PropTypes.object.isRequired
}

export default CreatePartnerPage
