import { makeStyles, useTheme } from '@material-ui/core/styles'

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import React from 'react'
import Select from 'react-select'
import { Small } from 'components'
import Typography from '@material-ui/core/Typography'
import { find } from 'lodash'

const useStyles = makeStyles((theme) => ({
  label: {
    fontWeight: 500,
    fontSize: '14px',
    marginBottom: theme.spacing(1),
    color: '#5a5760'
  },
  dropdownIndicator: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1),
    color: '#5a5760'
  }
}))

const DropdownIndicator = () => {
  const classes = useStyles()

  return (
    <div className={classes.dropdownIndicator}>
      <KeyboardArrowDownIcon />
    </div>
  )
}

const SelectInput = ({
  field,
  options,
  formikProps,
  label,
  description,
  required,
  placeholder,
  isMulti,
  defaultValue,
  isDisabled = false,
  ...rest
}) => {
  const classes = useStyles()
  const theme = useTheme()
  const { values, errors } = formikProps
  const getOptionFromValue = (value) => {
    if (value === '' && !!defaultValue) {
      return find(options, { value: defaultValue })
    }

    return value && find(options, { value })
  }
  const customStyles = {
    menu: (provided) => ({
      ...provided,
      zIndex: 9999
    }),
    control: (provided, state) => ({
      ...provided,
      height: '38px',
      borderColor: state.isFocused ? theme.palette.primary.main : ' #d4d4d4',
      borderRadius: '4px',
      fontSize: '16px',
      fontFamily: theme.typography.fontFamily,
      boxShadow: 'none',
      '&:hover': {
        borderColor: state.isFocused ? theme.palette.primary.main : ' #d4d4d4'
      }
    }),
    placeholder: (provided) => ({
      ...provided,
      color: 'inherit',
      opacity: '0.54'
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? theme.palette.primary.main
        : state.isFocused
        ? theme.palette.ui[200]
        : 'transparent',
      '&:hover': {
        backgroundColor: state.isSelected ? theme.palette.primary.main : theme.palette.ui[100]
      }
    })
  }

  return (
    <div className="form-element">
      <Typography className={classes.label} component="label" htmlFor={field}>
        {label}
      </Typography>
      <Select
        id={field}
        name={field}
        value={values && getOptionFromValue(values[field])}
        defaultValue={defaultValue}
        options={options}
        onChange={({ value }) => formikProps.setFieldValue(field, value)}
        onBlur={field.onBlur}
        required={required}
        placeholder={placeholder !== '' ? placeholder : 'Select..'}
        isMulti={isMulti}
        isDisabled={isDisabled}
        onKeyDown={(e) => {
          if (e.keyCode === 27) {
            e.stopPropagation()
          }
        }}
        components={{
          DropdownIndicator,
          IndicatorSeparator: null
        }}
        {...rest}
        styles={customStyles}
      />
      <Small>{description}</Small>
      {!!errors[field] && <div style={{ color: 'red', marginTop: '.5rem' }}>{errors[field]}</div>}
    </div>
  )
}

export default SelectInput
