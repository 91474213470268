import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import SettingsIcon from '@material-ui/icons/Settings'
import { HeaderWithTabs, LayoutHeader } from 'components'
import Navigation from './LeadsNavigation'

const LeadsHeader = ({ leadCaptureFormEnabled, activePath, ecosystem, match }) => {
  const isPathRequestform = activePath.split('/').pop() === 'request-form'
  const RequestFormButton = () => (
    <Button
      className="fr"
      href={`/ecosystems/${ecosystem.id}/settings/leads`}
      endIcon={
        <SettingsIcon
          style={{
            color: 'currentColor',
            fontSize: 16
          }}
        />
      }
    >
      Edit settings
    </Button>
  )

  if (leadCaptureFormEnabled) {
    return (
      <HeaderWithTabs tabs={<Navigation match={match} />}>
        <h1>Leads</h1>
        {isPathRequestform && <RequestFormButton />}
      </HeaderWithTabs>
    )
  }
  return (
    <LayoutHeader
      title="Leads"
      description="Leads are collected through the integration request form on your ecosystem."
      right={isPathRequestform && <RequestFormButton />}
    />
  )
}

LeadsHeader.propTypes = {
  activePath: PropTypes.string.isRequired
}

export default LeadsHeader
