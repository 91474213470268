import { partnersQuery } from 'api'
import { union } from 'ramda'

function add(cache, { accountId, ecosystemId }, { partners }) {
  const { partners: currentPartners } = cache.readQuery({
    query: partnersQuery,
    variables: {
      accountId,
      ecosystemId
    }
  })

  cache.writeQuery({
    data: {
      partners: union(currentPartners, partners)
    },
    query: partnersQuery,
    variables: {
      accountId,
      ecosystemId,
      limit: 500
    }
  })
}

export { add }
