import { Card, CardEmptyState, GridCard, GridContainer } from 'components'
import React, { Fragment } from 'react'

import Button from '@material-ui/core/Button'
import { Link } from 'react-router-dom'
import getLocalizedValue from 'utils/getLocalizedValue'

const CreateCollection = ({ ecosystem, children }) => (
  <Link to={`/ecosystems/${ecosystem.id}/collection/create`}>{children}</Link>
)

const Collections = ({ collections, ecosystem }) => {
  return (
    <Fragment>
      <Card
        title="Collections"
        button={
          <CreateCollection ecosystem={ecosystem}>
            <Button variant="contained" className="fr" color="primary">
              + Create
            </Button>
          </CreateCollection>
        }
      >
        <div data-testid="ecosystem-listings-collections-view">
          {collections.length !== 0 ? (
            <GridContainer>
              {collections.map((collection) => (
                <GridCard
                  id={collection.id}
                  key={collection.id}
                  title={
                    collection?.name || getLocalizedValue(collection, 'name', ecosystem.locales[0])
                  }
                  imageSrc={collection.logo ? collection.logo.url : null}
                  // subtitle={`${collection.listings.length} listing(s)`} // TODO use listingCount
                  link={`collections/${collection.id}/overview`}
                  sm={4}
                  xs={12}
                />
              ))}
            </GridContainer>
          ) : (
            <CardEmptyState>
              {`You've created no collections yet. `}
              <CreateCollection ecosystem={ecosystem}>+ Create a collection.</CreateCollection>
            </CardEmptyState>
          )}
        </div>
      </Card>
    </Fragment>
  )
}

export default Collections
