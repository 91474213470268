import {
  Table as MaUTable,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow
} from '@material-ui/core'
import React, { Fragment } from 'react'
import { usePagination, useTable } from 'react-table'

import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'

function Table({ columns, data, clickableRow, hoverOnRows, actions }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    gotoPage,
    setPageSize,
    state: { pageIndex, pageSize }
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 25 },
      ...actions
    },
    usePagination
  )

  const renderTableHead = (headerGroup) => {
    return (
      <TableRow {...headerGroup.getHeaderGroupProps()}>
        {headerGroup.headers.map((column, i) => (
          <TableCell
            {...column.getHeaderProps({
              style: {
                width: `${column.width}px`,
                textAlign: column.align
              }
            })}
            padding={column.padding || 'normal'}
            numeric={column.numeric?.toString()}
            key={`column-${i}`}
          >
            {column.render('Header')}
          </TableCell>
        ))}
      </TableRow>
    )
  }

  const renderRow = (row) => {
    prepareRow(row)
    const { key } = row.getRowProps()

    return <Row key={key} row={row} hoverOnRows={hoverOnRows} clickableRow={clickableRow} />
  }

  return (
    <Fragment>
      <MaUTable size={'small'} {...getTableProps()}>
        <TableHead>{headerGroups.map(renderTableHead)}</TableHead>
        <TableBody {...getTableBodyProps()}>
          {page.map((row) => {
            return renderRow(row)
          })}
        </TableBody>
      </MaUTable>
      <TablePagination
        component="div"
        rowsPerPage={pageSize}
        count={data.length}
        page={pageIndex}
        onPageChange={(_, p) => gotoPage(p)}
        onRowsPerPageChange={(e) => {
          setPageSize(e.target.value)
        }}
      />
    </Fragment>
  )
}

function Row({ row, hoverOnRows, clickableRow }) {
  const history = useHistory()

  return (
    <TableRow
      {...row.getRowProps()}
      hover={hoverOnRows}
      onClick={() => {
        clickableRow && history.push(row.original.link)
      }}
      style={clickableRow && { cursor: 'pointer' }}
    >
      {row.cells.map((cell, i) => (
        <TableCell
          key={`cell-${i}`}
          padding={cell.column.padding || 'normal'}
          {...cell.getCellProps({
            style: {
              width: `${cell.column.width}px`,
              cursor: cell.column.cursor,
              textAlign: cell.column.align
            }
          })}
          numeric={cell.column.numeric?.toString()}
        >
          {cell.render('Cell')}
        </TableCell>
      ))}
    </TableRow>
  )
}

Table.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired
}

Table.defaultProps = {
  hoverOnRows: false
}

export default Table
