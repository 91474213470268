import { useQuery } from '@apollo/react-hooks'
import { leadsQuery } from 'api/queries'
import { Loader } from 'components'
import { useEcosystemContext } from 'context'
import React from 'react'
import RequestsPage from './RequestsPage'

const RequestsContainer = ({ activeAccount }) => {
  const { ecosystem } = useEcosystemContext()
  const { id: ecosystemId } = ecosystem

  const { loading, data } = useQuery(leadsQuery, {
    variables: {
      accountId: activeAccount.id,
      ecosystemId: ecosystemId,
      pageSize: 2000 // we need all the leads
    }
  })

  if (loading) {
    return <Loader verticalCentered />
  }

  const leads = data.leads
  let requestedServices = []
  for (let lead of leads) {
    if (lead.requestedServices) {
      requestedServices = [...requestedServices, lead.requestedServices]
    }
  }

  const votesObj = requestedServices.reduce((acc, cur) => {
    return {
      ...acc,
      [cur]: (acc[cur] | 0) + 1
    }
  }, {})
  const votes = Object.keys(votesObj).map((voteObj) => {
    return {
      name: voteObj,
      amount: votesObj[voteObj]
    }
  })
  const sortedVotes = votes.sort((a, b) => b.amount - a.amount)
  return <RequestsPage ecosystem={ecosystem} votes={sortedVotes} />
}

export default RequestsContainer
