import React from 'react'
import styled from 'react-emotion'

const Section = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  height: 100%;
`

const PanelInput = styled('div')`
  border-radius: 0px;
`

const PanelInputWithInfo = ({ children, leftBloc, rightBloc, rightBlocAddon }) => (
  <PanelInput>
    <Section>
      {leftBloc && <div style={{ flexBasis: '25%' }}>{leftBloc}</div>}
      <div style={{ flexBasis: '75%' }}>
        <div>{rightBloc}</div>
        {rightBlocAddon && <div>{rightBlocAddon}</div>}
      </div>
    </Section>
    <div>{children}</div>
  </PanelInput>
)

export default PanelInputWithInfo
