import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
import { reducer as toastrReducer } from 'react-redux-toastr'
import account from './modules/account'

export default combineReducers({
  account,
  form: formReducer,
  toastr: toastrReducer
})
