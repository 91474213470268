import '../../styles/toaster.css'

import React from 'react'
import ReduxToastr from 'react-redux-toastr'

const Toastr = () => (
  <ReduxToastr
    timeOut={4000}
    icon={<div />}
    showCloseButton={false}
    closeOnToastrClick
    newestOnTop={false}
    preventDuplicates
    position="top-right"
    transitionIn="fadeIn"
    transitionOut="fadeOut"
  />
)

export default Toastr
