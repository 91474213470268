import { FileUploadInput, TextInput, generateSlugValue } from 'components'

import React from 'react'

const NameField = ({ formikProps }) => (
  <TextInput formikProps={formikProps} label="Name" field="name" required localized="true" />
)

const ConnectedSlugField = ({ formikProps, prefix }) => (
  <TextInput
    formikProps={formikProps}
    label="Slug (autogenerated)"
    field="slug"
    value={(formikProps.values['slug'] = generateSlugValue(formikProps, 'name'))}
    prefix={prefix}
  />
)

const SlugField = ({ formikProps, prefix }) => (
  <TextInput formikProps={formikProps} label="Slug" field="slug" prefix={prefix} />
)

const LogoField = ({ formikProps }) => (
  <FileUploadInput
    formikProps={formikProps}
    label="Logo"
    field="logo"
    description="Square icon. Ideal dimensions 400x400px."
    multiple={false}
  />
)

const DescriptionField = ({ formikProps }) => (
  <TextInput formikProps={formikProps} label="Description" field="description" localized="true" />
)

const SequenceField = ({ formikProps }) => (
  <TextInput
    formikProps={formikProps}
    label="Sequence"
    field="sequence"
    type="number"
    description="Defines the sequence of the products on the homepage. (1 === first)"
  />
)

export { ConnectedSlugField, SlugField, NameField, DescriptionField, SequenceField, LogoField }
