import {
  HeaderTitle,
  HeaderWithTabs,
  ListingNavigation,
  PreviewButton,
  SecondaryButton
} from 'components'
import { NavLink, withRouter } from 'react-router-dom'
import { getListingName, listingLogo } from 'components/Helpers'

import Button from '@material-ui/core/Button'
import PropTypes from 'prop-types'
import React from 'react'
import { checkListingForPending } from 'api/queries'
import ecosystemUrl from 'utils/ecosystemUrl'
import getLocalizedValue from 'utils/getLocalizedValue'
import { useQuery } from 'react-apollo'

const ListingHeaderWrapper = ({
  listing,
  marketplace,
  match,
  history,
  handleTogglePublishListing,
  activeAccount
}) => {
  const { data } = useQuery(checkListingForPending, {
    variables: {
      accountId: activeAccount.id,
      id: listing.id
    },
    fetchPolicy: 'network-only'
  })

  const draftId = data?.checkForPending?.id

  return (
    <HeaderWithTabs tabs={<ListingNavigation match={match} />}>
      <HeaderTitle
        imageTitle={getLocalizedValue(listing, 'name', marketplace.locales)}
        imageSrc={listingLogo(listing)}
        title={getLocalizedValue(listing, 'name', marketplace.locales)}
        subtitle={getLocalizedValue(listing, 'tagLine', marketplace.locales) || 'Listing'}
      />
      <SecondaryButton
        className="fr"
        onClick={() => history.push(`/ecosystems/${marketplace.id}/listings/overview`)}
      >
        Go Back
      </SecondaryButton>
      <PreviewButton
        href={`${ecosystemUrl(marketplace)}/${getListingName(marketplace).label}/${listing.slug}`}
      />
      {listing.state !== 'PENDING_REVIEW' && (
        <Button
          onClick={handleTogglePublishListing}
          variant="contained"
          className="fr"
          color="primary"
          style={{ marginRight: '15px' }}
        >
          {listing.published ? 'Unpublish' : 'Publish'}
        </Button>
      )}
      {(listing.pendingReviewListing || listing.state === 'PENDING_REVIEW') && (
        <NavLink
          to={`/ecosystems/${marketplace.id}/listings/${
            listing.parentId || listing?.pendingReviewListing?.id
          }/overview`}
        >
          <Button
            variant="contained"
            className="fr"
            color="primary"
            style={{ marginRight: '15px' }}
          >
            Show original
          </Button>
        </NavLink>
      )}
      {draftId && marketplace?.listingApprovalEnabled && (
        <NavLink to={`/ecosystems/${marketplace.id}/listings/${draftId}/overview`}>
          <Button
            variant="contained"
            className="fr"
            color="primary"
            style={{ marginRight: '15px' }}
          >
            Show draft
          </Button>
        </NavLink>
      )}
    </HeaderWithTabs>
  )
}

ListingHeaderWrapper.propTypes = {
  listing: PropTypes.object.isRequired,
  handleTogglePublishListing: PropTypes.func.isRequired
}

const ListingHeader = withRouter(ListingHeaderWrapper)

export default ListingHeader
