import { importIntegrationsByService, marketplaceQuery } from 'api/queries'
import { CardAlert, ExternalA, LoaderWithText } from 'components'
import PropTypes from 'prop-types'
import * as R from 'ramda'
import React, { useEffect } from 'react'
import { useMutation } from 'react-apollo'
import MatchedServices from './matched-services/MatchedServices'
import Services from './Services'

function addListingsToMarketplace(marketplace, listings) {
  // add listing to marketplace
  marketplace.listings = R.concat(marketplace.listings, listings)

  // add categories to marketplace if they don't exist
  listings.forEach((listing) => {
    if (listing?.category) {
      const exists = R.any((c) => c.slug === listing?.category?.slug, marketplace.categories)

      if (!exists) {
        marketplace.categories.push(listing?.category)
      }
    }
  })

  return marketplace
}

function ServicesFromMatchedService({ activeAccount, ecosystem, handleComplete, service }) {
  const [importServices, { loading }] = useMutation(importIntegrationsByService, {
    update: (cache, { data: { importIntegrationsByService: listings } }) => {
      // add listing
      const { marketplace } = cache.readQuery({
        query: marketplaceQuery,
        variables: {
          accountId: activeAccount.id,
          id: ecosystem.id
        }
      })
      const updatedMarketplace = addListingsToMarketplace(marketplace, listings)

      cache.writeQuery({
        data: { marketplace: updatedMarketplace },
        query: marketplaceQuery,
        variables: {
          accountId: activeAccount.id,
          id: ecosystem.id
        }
      })
    }
  })

  useEffect(() => {
    async function _importServices() {
      await importServices({
        variables: {
          accountId: activeAccount.id,
          ecosystemId: ecosystem.id,
          serviceId: service.id
        }
      })
    }

    _importServices()
    return () => {}
  }, [activeAccount.id, ecosystem.id, service.id, importServices])

  let Content = (
    <LoaderWithText>
      Importing detected integrations for{' '}
      <ExternalA href={ecosystem.website}>{ecosystem.website}</ExternalA> ...
    </LoaderWithText>
  )

  if (!loading) {
    Content = (
      <div>
        <ImportAlert ecosystem={ecosystem} service={service} />
        <MatchedServices
          activeAccount={activeAccount}
          ecosystem={ecosystem}
          services={service.integrations}
        />
      </div>
    )
  }

  return (
    <Services
      Content={Content}
      ecosystem={ecosystem}
      handleComplete={handleComplete}
      service={service}
    />
  )
}

const ImportAlert = ({ service, ecosystem }) => (
  <CardAlert.Default>
    <p>Listings are dedicated integration or partner pages.</p>
    {service && service.integrations.length > 0 ? (
      <p>
        The Apideck Network detected the following listings by scanning{' '}
        <ExternalA href={ecosystem.website}>{ecosystem.website}</ExternalA>. Please remove the
        integrations that do not belong.
      </p>
    ) : (
      <p>Please add/remove your integrations below.</p>
    )}
  </CardAlert.Default>
)

ServicesFromMatchedService.propTypes = {
  activeAccount: PropTypes.object.isRequired,
  service: PropTypes.object,
  ecosystem: PropTypes.object.isRequired,
  handleComplete: PropTypes.func.isRequired
}

export default ServicesFromMatchedService
