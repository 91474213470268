import React from 'react'
import styled from 'react-emotion'
import classNames from 'classnames'
import PropTypes from 'prop-types'

const Button = styled('button')`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 140px;
  height: 45px;
  border: 1px solid var(--color-lighter);
  border-radius: 4px;
  font-size: 15px;
  font-weight: 500;
  color: #8687af;
  background-color: transparent;
  line-height: 1;
  padding: 0 5px;

  &:focus {
    border: 1px solid var(--color-primary);
  }

  &.active {
    color: var(--color-primary);
    border: none;
    background-image: var(--gradient);
    padding: 0;

    div {
      background-color: #ededf6;
    }
  }

  div {
    position: absolute;
    top: 3px;
    right: 3px;
    bottom: 3px;
    left: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  /* From children */
  .icon {
    margin-right: 5px;
    line-height: 0;

    svg {
      font-size: 14px;
    }
  }
`

const TerminologyButton = ({ isActive, handleClick, children }) => (
  <Button type="button" className={classNames({ active: isActive })} onClick={handleClick}>
    <div>{children}</div>
  </Button>
)

TerminologyButton.propTypes = {
  isActive: PropTypes.bool,
  handleClick: PropTypes.func.isRequired
}

export default TerminologyButton
