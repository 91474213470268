import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { CardNew as Card, IntegrationCard } from 'components'
import React from 'react'

const THIRD_PARTY_SNIPPETS_TOOLTIP_CONTENT = `Embed a chat widget, set up a cookie consent, analytics tracker, ..`

export const ThirdPartySnippets = ({
  classes,
  ecosystem,
  connectionsQueryLoading,
  connectionsQueryError,
  addedScriptConnections,
  handleUpdateConnectionFormSubmit,
  updateConnectionMutationLoading,
  handleDeleteConnection,
  whichSettingsModalIsOpen,
  setSettingsModalOpen,
  setAddModalOpen,
  ErrorMessage
}) => {
  const thirdPartyScriptPlaceholderMessage =
    'Add a third-party snippet by clicking on the add button in the right upper corner.'

  return (
    <Card
      title="Third-party snippets"
      tooltipContent={THIRD_PARTY_SNIPPETS_TOOLTIP_CONTENT}
      action={
        !connectionsQueryLoading && (
          <Button
            variant="contained"
            color="primary"
            onClick={() => setAddModalOpen('third-party-snippets')}
          >
            + Add
          </Button>
        )
      }
      loading={connectionsQueryLoading}
      ErrorMessage={connectionsQueryError && ErrorMessage}
      PlaceholderMessage={addedScriptConnections.length === 0 && thirdPartyScriptPlaceholderMessage}
    >
      <Typography className={classes.title} component="h4">
        Providers
      </Typography>
      <div className={classes.cardsContainer}>
        {addedScriptConnections.map((addedScriptConnection) => {
          return (
            <IntegrationCard
              key={addedScriptConnection.id}
              ecosystemId={ecosystem.id}
              connection={addedScriptConnection}
              handleUpdateConnectionFormSubmit={handleUpdateConnectionFormSubmit}
              updateConnectionMutationLoading={updateConnectionMutationLoading}
              handleDeleteConnection={handleDeleteConnection}
              whichSettingsModalIsOpen={whichSettingsModalIsOpen}
              setSettingsModalOpen={setSettingsModalOpen}
            />
          )
        })}
      </div>
    </Card>
  )
}
