import PropTypes from 'prop-types'
import React from 'react'
import styled from 'react-emotion'
import { media } from 'styles/styles'

const Container = styled('div')`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  font-family: var(--font-family-basier);

  /* Some global styles used on pages which use the SignupSetup layout are defined here */

  h1 {
    font-size: 42px;
    color: var(--title-text-color);
    font-weight: 500;
    line-height: 1.15;
  }

  h2 {
    font-size: 24px;
    color: var(--title-text-color);
    font-weight: 500;
    line-height: 1.15;
  }

  p {
    color: var(--text-color);
  }

  @media screen and (max-width: ${media.xLarge}) {
    h1 {
      font-size: 36px;
    }
  }
`

const Aside = styled('aside')`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--sidebar-background-color);
  min-width: 400px;
  width: 400px;
  padding: 80px 60px 50px;
  color: var(--color-white);
  overflow: auto;

  .logo {
    padding-bottom: 30px;
    border-bottom: 1px solid var(--sidebar-divider-color);
    margin-bottom: 50px;

    img {
      width: 120px;
    }
  }

  @media screen and (max-width: ${media.medium}) {
    padding: 80px 30px 60px;
    min-width: 0;
    width: auto;
  }

  @media screen and (max-width: ${media.small}) {
    display: none;
  }
`

const SignupSetupLayout = ({ SidebarContent, SidebarFooter, children }) => (
  <Container>
    <Aside>
      <div>
        <div className="logo">
          <a href="https://apideck.com/">
            <img src="/images/logo-white.svg" alt="Apideck" />
          </a>
        </div>
        {SidebarContent}
      </div>
      <div>{SidebarFooter}</div>
    </Aside>
    {children}
  </Container>
)

SignupSetupLayout.propTypes = {
  SidebarContent: PropTypes.element.isRequired,
  SidebarFooter: PropTypes.element.isRequired,
  hasBackgroundImage: PropTypes.bool
}

export default SignupSetupLayout
