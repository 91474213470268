import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import { HeaderWithTabs, SecondaryButton, PrimaryButton } from 'components'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import PartnersNavigation from './Navigation'
import ecosystemUrl from 'utils/ecosystemUrl'

function Header({ history, match, goBack, ecosystem }) {
  return (
    <HeaderWithTabs tabs={<PartnersNavigation match={match} />}>
      <h1>Partners</h1>

      <Button
        className="fr"
        style={{ marginLeft: 10 }}
        target="_blank"
        href={`${ecosystemUrl(ecosystem)}/partner/login`}
        endIcon={
          <OpenInNewIcon
            style={{
              color: 'currentColor',
              fontSize: 16
            }}
          />
        }
      >
        Partner login
      </Button>

      <PrimaryButton className="fr" href={`${ecosystemUrl(ecosystem)}/partner/request`}>
        Partner request form
      </PrimaryButton>

      {goBack && (
        <SecondaryButton className="fr" onClick={history.goBack}>
          Go Back
        </SecondaryButton>
      )}
    </HeaderWithTabs>
  )
}

Header.propTypes = {
  match: PropTypes.object.isRequired
}

const PartnersHeader = withRouter(Header)

export default PartnersHeader
