import React from 'react'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'
import PrivateRoute from 'components/PrivateRoute'
import { DashboardContainer } from 'pages'

const DashboardRoutes = ({ activeAccount, match, marketplace }) => {
  const { subscriptionStatus, plan } = activeAccount
  let dashboardAccess = true
  if (subscriptionStatus === 'TRAIL' || plan === 'FREE' || !plan) {
    dashboardAccess = false
  }

  if (dashboardAccess) {
    return (
      <PrivateRoute
        path={`${match.path}/dashboard`}
        component={DashboardContainer}
        marketplace={marketplace}
        activeAccount={activeAccount}
      />
    )
  }

  return <Redirect to="/" />
}

DashboardRoutes.defaultProps = {
  plan: null
}

DashboardRoutes.propTypes = {
  activeAccount: PropTypes.shape({
    subscriptionStatus: PropTypes.string.isRequired,
    plan: PropTypes.string
  })
}

export default DashboardRoutes
