export default class Storage {
  get(key) {
    return localStorage.getItem(key)
  }

  set(key, value) {
    localStorage.setItem(key, value)
  }

  clear() {
    localStorage.clear()
  }

  remove(key) {
    return localStorage.removeItem(key)
  }
}
