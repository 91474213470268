import { useMutation, useQuery } from '@apollo/react-hooks'
import {
  connectionsQuery as CONNECTIONS,
  deleteConnectionMutation as DELETE_CONNECTION,
  marketplaceIntegrationSettingsQuery as MARKETPLACE,
  updateConnectionMutation as UPDATE_CONNECTION,
  updateIntegrationPlatformsSettingsMutation as UPDATE_INTEGRATION_PLATFORM_SETTINGS,
  updateIntegrationsSettingsMutation as UPDATE_INTEGRATIONS_SETTINGS
} from 'api/queries'
import { Loader } from 'components'
import { NOT_UPDATED, UPDATED } from 'constants/settings'
import PropTypes from 'prop-types'
import React, { Fragment, useEffect } from 'react'
import { toastr } from 'react-redux-toastr'
import IntegrationsSettingsPage from './integrations/Page'

const IntegrationsSettingsContainer = ({
  ecosystem,
  setActivePath,
  match,
  activeAccount // from Redux
}) => {
  const accountId = activeAccount.id
  const accountName = activeAccount.name
  const { id: ecosystemId, name: ecosystemName, slug } = ecosystem
  const { loading, data } = useQuery(MARKETPLACE, {
    variables: {
      id: ecosystemId,
      accountId
    }
  })
  const {
    loading: connectionsQueryLoading,
    data: connectionsQueryData,
    error: connectionsQueryError,
    refetch: refetchConnections
  } = useQuery(CONNECTIONS, {
    variables: {
      ecosystemId,
      accountId
    }
  })

  const [updateMarketplace, { loading: updateIntegrationPlatformSettingsMutationLoading }] =
    useMutation(UPDATE_INTEGRATION_PLATFORM_SETTINGS)
  const [updateIntegrationSettings, { loading: updateIntegrationSettingsMutationLoading }] =
    useMutation(UPDATE_INTEGRATIONS_SETTINGS)
  const [updateConnection, { loading: updateConnectionMutationLoading }] =
    useMutation(UPDATE_CONNECTION)
  const [deleteConnection] = useMutation(DELETE_CONNECTION)

  useEffect(() => {
    setActivePath(match.path)

    return () => {
      setActivePath('')
    }
  }, [match.path, setActivePath])

  if (loading || !data) {
    return <Loader verticalCentered />
  }

  const connections =
    connectionsQueryLoading || connectionsQueryError ? [] : connectionsQueryData.connections

  const handleUpdateConnectionFormSubmit = async (values, callback) => {
    const { enabled, serviceId, unifiedApi, ...rest } = values
    const metadata = {
      account: {
        id: accountId,
        name: accountName
      },
      ecosystem: {
        id: ecosystemId,
        name: ecosystemName
      }
    }
    const variables = {
      accountId,
      ecosystemId,
      unifiedApi,
      serviceId,
      metadata
    }

    if (Object.keys(rest).length !== 0) {
      variables.settings = []
      Object.keys(rest).forEach((setting) => {
        variables.settings.push({
          id: setting,
          value: rest[setting]
        })
      })
    }
    if (typeof enabled !== 'undefined') {
      variables.enabled = enabled
    }

    try {
      const { data } = await updateConnection({ variables })

      if (data) {
        toastr.success(UPDATED)
        if (callback) {
          callback()
        }
      }
    } catch (e) {
      toastr.error(NOT_UPDATED)
    }

    // Temporary save settings in Ecosystem db
    // TODO handle solely with Unify
    const computedValues = {}
    if (serviceId === 'google-tag-manager') {
      computedValues.integrationGoogleTagManagerId = values.id
    } else if (serviceId === 'metomic-io') {
      computedValues.integrationMetomicId = values.project_id
    } else if (serviceId === 'onetrust') {
      computedValues.integrationOnetrustId = values.id
    } else if (serviceId === 'albacross') {
      computedValues.integrationAlbacrossId = values.id
    } else if (serviceId === 'segment') {
      computedValues.integrationSegmentId = values.id
    } else if (serviceId === 'iubenda') {
      if ('site_id' in values) {
        computedValues.integrationIubendaSiteId = values.site_id
      } else if ('cookie_policy_id' in values) {
        computedValues.integrationIubendaCookiePolicyId = values.cookie_policy_id
      }
    } else if (serviceId === 'journy-io') {
      if ('id' in values) {
        computedValues.integrationJournyIoId = values.id
      } else if ('tracking_url' in values) {
        computedValues.integrationJournyIoDomain = values.tracking_url
      }
    } else if (serviceId === 'google-analytics') {
      computedValues.integrationGoogleAnalyticsId = values.id
    } else if (serviceId === 'heap') {
      computedValues.integrationHeapId = values.id
    } else if (serviceId === 'hubspot') {
      computedValues.integrationHubspotPortalId = values.portal_id
    } else if (serviceId === 'crisp') {
      computedValues.integrationCrispId = values.id
    } else if (serviceId === 'intercom') {
      computedValues.integrationIntercomAppId = values.app_id
    } else if (serviceId === 'drift') {
      computedValues.integrationDriftId = values.id
    } else if (serviceId === 'livechat') {
      computedValues.integrationLivechatId = values.id
    }

    const integrationSettingsVariables = {
      accountId,
      id: ecosystemId,
      name: ecosystemName,
      slug,
      ...computedValues
    }

    await updateIntegrationSettings({ variables: integrationSettingsVariables })
  }

  const handleDeleteConnection = async (serviceId, unifiedApi, callback) => {
    const variables = {
      unifiedApi,
      accountId,
      ecosystemId,
      serviceId
    }
    const updateEcosystemIntegrationSettings = async (clearLeadIntegrationEnabled) => {
      // Temporary clear settings in Ecosystem db
      // TODO handle solely with Unify
      const values = {}
      if (clearLeadIntegrationEnabled) {
        values.leadIntegrationEnabled = false
      }
      if (serviceId === 'google-tag-manager') {
        values.integrationGoogleTagManagerId = null
      } else if (serviceId === 'metomic-io') {
        values.integrationMetomicId = null
      } else if (serviceId === 'onetrust') {
        values.integrationOnetrustId = null
      } else if (serviceId === 'albacross') {
        values.integrationAlbacrossId = null
      } else if (serviceId === 'segment') {
        values.integrationSegmentId = null
      } else if (serviceId === 'iubenda') {
        values.integrationIubendaSiteId = null
        values.integrationIubendaCookiePolicyId = null
      } else if (serviceId === 'journy-io') {
        values.integrationJournyIoId = null
        values.integrationJournyIoDomain = null
      } else if (serviceId === 'google-analytics') {
        values.integrationGoogleAnalyticsId = null
      } else if (serviceId === 'heap') {
        values.integrationHeapId = null
      } else if (serviceId === 'hubspot') {
        values.integrationHubspotPortalId = null
      } else if (serviceId === 'crisp') {
        values.integrationCrispId = null
      } else if (serviceId === 'intercom') {
        values.integrationIntercomAppId = null
      } else if (serviceId === 'drift') {
        values.integrationDriftId = null
      } else if (serviceId === 'livechat') {
        values.integrationLivechatId = null
      }

      const integrationSettingsVariables = {
        accountId,
        id: ecosystemId,
        name: ecosystemName,
        slug,
        ...values
      }

      await updateIntegrationSettings({
        variables: integrationSettingsVariables
      })
    }

    try {
      const { data } = await deleteConnection({ variables })
      const { success } = data.deleteConnection

      if (success) {
        if (callback) {
          callback()
        }

        // check if there still are lead integrations, if not disable leadIntegrationEnabled
        const { data } = await refetchConnections()
        const { connections } = data
        const leadConnections = connections.filter((connection) => connection.unifiedApi === 'crm')
        let hasActiveLeadConnection = false
        leadConnections.forEach((leadConnection) => {
          if (leadConnection.added) {
            hasActiveLeadConnection = true
          }
        })
        const clearLeadIntegrationEnabled = hasActiveLeadConnection ? false : true
        await updateEcosystemIntegrationSettings(clearLeadIntegrationEnabled)

        toastr.success('Integration deleted')
      }
    } catch (e) {
      console.log(e)
      toastr.error('Something went wrong while deleting the integration')
    }
  }

  return (
    <Fragment>
      <IntegrationsSettingsPage
        accountId={accountId}
        ecosystem={ecosystem}
        connections={connections}
        updateMarketplace={updateMarketplace}
        updateIntegrationSettings={updateIntegrationSettings}
        connectionsQueryLoading={connectionsQueryLoading}
        connectionsQueryError={!!connectionsQueryError}
        updateIntegrationPlatformSettingsMutationLoading={
          updateIntegrationPlatformSettingsMutationLoading
        }
        updateIntegrationSettingsMutationLoading={updateIntegrationSettingsMutationLoading}
        handleUpdateConnectionFormSubmit={handleUpdateConnectionFormSubmit}
        updateConnectionMutationLoading={updateConnectionMutationLoading}
        handleDeleteConnection={handleDeleteConnection}
      />
    </Fragment>
  )
}

IntegrationsSettingsContainer.propTypes = {
  setActivePath: PropTypes.func.isRequired
}

export default IntegrationsSettingsContainer
