import DialogTitle from '@material-ui/core/DialogTitle'
import React from 'react'
import { withStyles } from '@material-ui/core/styles'

const styles = () => ({
  root: {
    backgroundColor: '#fdfdfd',
    fontSize: '18px',
    borderBottom: '1px solid #e9e9e9'
  }
})

const Title = ({ children, classes }) => <DialogTitle classes={classes}>{children}</DialogTitle>

export default withStyles(styles)(Title)
