import LazyLoad from 'react-lazyload'
import { LetterAvatar } from 'components'
import React from 'react'

const Icon = ({ imageSrc, title, small = true }) => (
  <div className="icon">
    {imageSrc ? (
      <LazyLoad height={small ? 32 : 64} offset={2000}>
        <img
          src={imageSrc}
          alt={title}
          className="image"
          style={{ height: small ? '32px' : '64px' }}
        />
      </LazyLoad>
    ) : (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <LetterAvatar title={title} size={small ? 32 : 64} />
      </div>
    )}
  </div>
)

export default Icon
