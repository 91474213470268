import PropTypes from 'prop-types'
import React from 'react'
import IntegrationPlatformsOverview from './components/Overview'
import IntegrationPlatformsContainer from './Container'

function getEnabledIntegrationPlatforms(ecosystem) {
  let integrationPlatforms = {
    automateEnabled: ecosystem?.automateEnabled ?? false,
    blendrEnabled: ecosystem?.blendrEnabled ?? false,
    combideskEnabled: ecosystem?.combideskEnabled ?? false,
    trayIoEnabled: ecosystem?.trayIoEnabled ?? false,
    microsoftFlowEnabled: ecosystem?.microsoftFlowEnabled ?? false,
    piesyncEnabled: ecosystem?.piesyncEnabled ?? false,
    segmentEnabled: ecosystem?.segmentEnabled ?? false,
    zapierEnabled: ecosystem?.zapierEnabled ?? false,
    integromatEnabled: ecosystem?.integromatEnabled ?? false
  }

  return integrationPlatforms
}

function IntegrationPlatformsPage(props) {
  const { activeAccount, service, ecosystem, handleComplete } = props

  return (
    <IntegrationPlatformsContainer accountId={activeAccount.id}>
      {({ updateIntegrationPlatformsSettings }) => {
        const enabledIntegrationPlatforms = getEnabledIntegrationPlatforms(ecosystem)
        const handleSubmit = () => {}
        const handleToggle = async (field) => {
          await updateIntegrationPlatformsSettings.mutation({
            variables: {
              accountId: activeAccount.id,
              id: ecosystem.id,
              name: ecosystem.name,
              slug: ecosystem.slug,
              [field]: !ecosystem[field]
            }
          })
        }

        return (
          <IntegrationPlatformsOverview
            service={service}
            ecosystem={ecosystem}
            initialValues={{ ...enabledIntegrationPlatforms }}
            handleComplete={handleComplete}
            handleSubmit={handleSubmit}
            handleToggle={handleToggle}
          />
        )
      }}
    </IntegrationPlatformsContainer>
  )
}

IntegrationPlatformsPage.propTypes = {
  activeAccount: PropTypes.object.isRequired,
  service: PropTypes.object,
  ecosystem: PropTypes.object.isRequired,
  handleComplete: PropTypes.func.isRequired
}

export default IntegrationPlatformsPage
