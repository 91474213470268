import PropTypes from 'prop-types'
import React from 'react'
import Dashboard from './DashboardPage'

class DashboardContainer extends React.Component {
  render() {
    const { marketplace, match, activeAccount } = this.props

    return <Dashboard ecosystem={marketplace} activeAccount={activeAccount} match={match} />
  }
}

DashboardContainer.propTypes = {
  activeAccount: PropTypes.object.isRequired,
  marketplace: PropTypes.object.isRequired
}

export default DashboardContainer
