import gql from 'graphql-tag'

export const productsPreviewQuery = gql`
  query productsPreviewQuery($ecosystemId: ID!, $offset: Int = 0, $limit: Int = 20) {
    productsPreview(ecosystemId: $ecosystemId, offset: $offset, limit: $limit) {
      id
      name
      slug
      visible
      sequence
      logo {
        id
        url
      }
      translations {
        locale
        key
        value
      }
    }
  }
`
