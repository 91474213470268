import { makeStyles } from '@material-ui/core/styles'
import React from 'react'

const useStyles = makeStyles((theme) => ({
  header: {
    margin: '0 auto',
    width: '100%',
    padding: '0 var(--main-padding-horizontal)',
    minHeight: '145px',
    paddingTop: '24px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#FFF',
    borderBottom: `1px solid ${theme.palette.ui[200]}`,
    fontFamily: 'var(--font-family-basier)', // TODO add to theme
    '& h1': {
      fontWeight: 500
    }
  }
}))

const Header = ({ children }) => {
  const classes = useStyles()

  return <div className={classes.header}>{children}</div>
}

export default Header
