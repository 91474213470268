import styled from 'react-emotion'

export default styled('span')`
  background-color: #eee;
  border-radius: 10px;
  color: #5a5760;
  font-size: 12px;
  letter-spacing: 0.5px;
  margin-left: 2px;
  padding: 2px 8px;
  text-transform: uppercase;
`
