import React from 'react'
import { ListingEditForm } from 'components'
import PropTypes from 'prop-types'

const Settings = (props) => <ListingEditForm {...props} />

Settings.propTypes = {
  categories: PropTypes.array.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired
}

export default Settings
