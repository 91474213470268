import Grid from '@material-ui/core/Grid'
import { EcosystemBaseForm, EcosystemPreview, Fieldset, GridContainer, Submit } from 'components'
import { imageUrlFormField } from 'components/Helpers'
import { Formik } from 'formik'
import PropTypes from 'prop-types'
import React from 'react'

export const TemplateSettingsForm = ({ initialValues, handleSubmit, ecosystem }) => (
  <Formik initialValues={initialValues} onSubmit={handleSubmit}>
    {(formikProps) => {
      const { handleSubmit, isSubmitting, values } = formikProps

      return (
        <GridContainer>
          <Grid item sm={6}>
            <EcosystemPreview
              ecosystem={ecosystem}
              primaryColor={values.primaryColor}
              cardColumns={12 / values.cardColumns}
              cardStyle={values.cardStyle}
              website={ecosystem.website}
              logo={imageUrlFormField(values.logo)}
              favicon={
                (ecosystem.favicon && ecosystem.favicon.url) ||
                (ecosystem.icon && ecosystem.icon.url)
              }
              mastheadTitle={ecosystem.mastheadTitle}
              mastheadDescription={ecosystem.mastheadDescription}
              mastheadColor="#FFF"
              mastheadBackgroundColor={values.primaryColor}
              homePageShowAllListings={ecosystem.homePageShowAllListings}
              homePageCollectionCategoryCards={ecosystem.homePageCollectionCategoryCards}
              ctaTitle={ecosystem.ctaTitle}
              ctaDescription={ecosystem.ctaDescription}
              ctaColor="#FFF"
              ctaBackgroundColor={values.primaryColor}
              ctaButtonLabel={ecosystem.ctaButtonLabel}
              ctaButtonBackgroundColor="#FFF"
              ctaButtonColor="#111"
              ctaEnabled={ecosystem.ctaEnabled}
              footerColor="#FFF"
              footerBackgroundColor="#111"
              menuPosition={values.menuPosition}
              menuStyle={ecosystem.menuStyle}
            />
          </Grid>
          <Grid item sm={6}>
            <form onSubmit={handleSubmit}>
              <Fieldset
                title="Style your ecosystem"
                button={
                  <Submit
                    isSubmitting={isSubmitting}
                    text="Complete setup"
                    style={{ padding: '5px 10px' }}
                  />
                }
              >
                <EcosystemBaseForm.PrimaryColorField formikProps={formikProps} />
                <EcosystemBaseForm.LogoField formikProps={formikProps} />
                <EcosystemBaseForm.MenuPositionField formikProps={formikProps} />
                <EcosystemBaseForm.CardStyleField formikProps={formikProps} />
                <EcosystemBaseForm.CardColumnsField formikProps={formikProps} />
              </Fieldset>
            </form>
          </Grid>
        </GridContainer>
      )
    }}
  </Formik>
)

TemplateSettingsForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object
}

export default TemplateSettingsForm
