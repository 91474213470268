import { FormDialog } from 'components'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import * as yup from 'yup'
import { Email, FirstName, LastName, Role } from './FormFields'

const schema = yup.object().shape({
  firstName: yup.string().required('First name is required'),
  lastName: yup.string(),
  email: yup.string().trim().email('Email should be valid email address')
})

class UpdateContactDialog extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired
  }

  static defaultProps = {
    contact: null,
    title: 'Edit Contact'
  }

  handleOpen = (openWithState) => () => {
    const { contact, title } = this.props

    openWithState({
      title,
      initialValues: {
        id: contact.id,
        firstName: contact.firstName || '',
        lastName: contact.lastName || '',
        email: contact.email || '',
        role: contact.role || '',
        invite: true
      },
      validationSchema: schema,
      render: this.renderFields,
      onSubmit: this.props.handleSubmit
    })
  }

  renderFields = (formikProps) => (
    <fieldset>
      <FirstName formikProps={formikProps} />
      <LastName formikProps={formikProps} />
      <Email formikProps={formikProps} />
      <Role formikProps={formikProps} />
    </fieldset>
  )

  render() {
    return (
      <FormDialog
        opener={(openWithState) => (
          <div onClick={this.handleOpen(openWithState)}>{this.props.opener}</div>
        )}
      />
    )
  }
}

export default UpdateContactDialog
