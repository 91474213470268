import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import { PrimaryButtonNew as PrimaryButton, TextInputNew as TextInput } from 'components'
import TerminologyButton from 'components/ecosystem/create/TerminologyButton'
import LISTING_NAMING from 'constants/listingNaming'
import { Formik } from 'formik'
import React from 'react'
import styled from 'react-emotion'
import { ScaleLoader } from 'react-spinners'
import slugify from 'slugify'
import { media } from 'styles/styles'
import * as yup from 'yup'

const FormContainer = styled('form')`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  overflow: auto;
`

const Content = styled('div')`
  width: 100%;
  max-width: 860px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  padding: 20px 40px;

  h2 {
    margin-top: 60px;
  }

  ul.terminology-buttons-container {
    margin-top: 45px;
    margin-bottom: -20px;
    margin-right: -20px;
    list-style: none;
    padding: 0;

    li {
      display: inline-block;
      margin-right: 20px;
      margin-bottom: 20px;
      line-height: 1;
    }
  }

  small {
    display: inline-block;
    padding-top: 10px;
    font-style: italic;
    color: #676889;
    line-height: 1;
  }
`

const Columns = styled('div')`
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 30px;

  & > * {
    width: 47%;
  }

  .name-container {
    display: flex;
    align-items: flex-end;

    & > div {
      margin-bottom: 25px;
      width: 100%;
    }

    .form-element {
      flex: 1;
    }

    @media screen and (max-height: 850px) {
      display: block;

      & > * {
        width: 100%;
      }

      .form-element {
        display: block;
      }
    }
  }

  .icon {
    width: 45px;
    max-width: 45px;
    height: 45px;
    margin-left: 10px;
    background-size: contain;
    border-radius: 4px;
    margin-bottom: 15px;
  }
`

const Footer = styled('footer')`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--body-background-color);
  padding: 20px 130px;

  @media screen and (max-width: ${media.xLarge}) {
    padding: 20px 40px;
  }

  .previous-container {
    a {
      text-transform: uppercase;
      font-size: 13px;
      letter-spacing: 1px;
      color: #9698ae;
      font-weight: 500;
      transition: 150ms;

      &:hover {
        color: #696b84;
      }
    }
  }

  button {
    width: 160px;
    margin-bottom: 0;
    font-size: 16px;
    height: 48px;
    padding-left: 15px;
    padding-right: 15px;
  }
`

const validationSchema = yup.object().shape({
  name: yup.string().required('Name is a required field'),
  slug: yup
    .string()
    .matches(
      /^[a-z0-9]+(?:-[a-z0-9]+)*$/,
      'Must be alphanumeric (A-Z, 0-9) with dashes between words.'
    )
    .required('Slug is a required field'),
  website: yup.string().required('Website is a required field'),
  listingNaming: yup.string().required('')
})
const websiteValidationSchema = yup.string().url()

const EcosystemCreateForm = ({ handleSubmit, initialValues, iconUrl, activeAccount, product }) => (
  <Formik
    validationSchema={validationSchema}
    initialValues={initialValues}
    onSubmit={handleSubmit}
    validateOnBlur={false}
    validateOnChange={false}
  >
    {(formikProps) => {
      const { isSubmitting, handleSubmit, setFieldValue, values, setFieldError } = formikProps
      const handleChangeNameField = (value) => {
        setFieldValue('name', value)
        setFieldValue('slug', slugify(value).toLowerCase())
      }

      return (
        <FormContainer
          onSubmit={(e) => {
            e.preventDefault()
            const formattedWebsiteValue = `https://${values.website}`
            websiteValidationSchema.isValid(formattedWebsiteValue).then((valid) => {
              if (valid) {
                handleSubmit(e)
              } else {
                setFieldError('website', 'Website is not a valid url')
              }
            })
          }}
        >
          <Content>
            <div>
              <h1>About your {product === 'ecosystem' ? 'ecosystem' : 'application'}</h1>
              <p>
                {activeAccount
                  ? 'Help us set up your new ecosystem by filling out the form below.'
                  : 'We prefilled the fields below with help from your email address. Please check if this information is correct.'}
              </p>
              <Columns>
                <div className="name-container">
                  <TextInput
                    formikProps={formikProps}
                    label="Application Name"
                    field="name"
                    required
                    onChange={(e) => handleChangeNameField(e.target.value)}
                    testid="application-name-input"
                  />
                  {iconUrl && (
                    <div className="icon" style={{ backgroundImage: `url(${iconUrl})` }} />
                  )}
                </div>
                <div className="website-container">
                  <TextInput
                    formikProps={formikProps}
                    label="Website"
                    field="website"
                    required
                    prefix="https://"
                    testid="application-website-input"
                  />
                </div>
              </Columns>
              <div className="subdomain-container">
                <TextInput
                  formikProps={formikProps}
                  label="Subdomain"
                  field="slug"
                  required
                  prefix="https://"
                  suffix=".apideck.io"
                  testid="application-subdomain-input"
                />
              </div>
              <small>
                Must be alphanumeric (A-Z, 0-9) with dashes between words. You will be able to
                configure a custom domain after this setup.
              </small>
              {product === 'ecosystem' && (
                <div>
                  <h2>Specify what you want to showcase</h2>
                  <p>
                    Choose one of the following options that best describes the use case of your
                    ecosystem. This will be used as terminology throughout your ecosystem. *
                  </p>
                  <ul className="terminology-buttons-container">
                    {LISTING_NAMING.map((term) => {
                      const isActive = term.type === formikProps.values.listingNaming

                      return (
                        <li key={term.type}>
                          <TerminologyButton
                            isActive={isActive}
                            handleClick={() =>
                              formikProps.setFieldValue('listingNaming', term.type)
                            }
                          >
                            {isActive && (
                              <span className="icon">
                                <CheckCircleIcon />
                              </span>
                            )}
                            {term.label}
                          </TerminologyButton>
                        </li>
                      )
                    })}
                  </ul>
                  <small>
                    {`* We'll also use it to define the url structure of your ecosystem, f.i.
                    https://company-name.apideck.com/integrations/stripe.`}
                    <br /> <br />
                    {`No worries, this can be changed after the setup.`}
                  </small>
                </div>
              )}
            </div>
          </Content>
          <Footer>
            <div className="previous-container">
              {/* {footerPreviousStepAction && (
                <a href='#' onClick={() => footerPreviousStepAction()}>
                  ← Previous step
                </a>
              )} */}
            </div>
            <PrimaryButton
              type="submit"
              disabled={isSubmitting}
              data-testid="application-create-submit"
            >
              {isSubmitting ? (
                <ScaleLoader height={12} width={3} color="var(--color-white)" />
              ) : product === 'ecosystem' ? (
                'Next »'
              ) : (
                'Get Started »'
              )}
            </PrimaryButton>
          </Footer>
        </FormContainer>
      )
    }}
  </Formik>
)

export default EcosystemCreateForm
