import * as yup from 'yup'

import {
  Fieldset,
  FileUploadInput,
  MultiSelectInput,
  PanelToggle,
  Submit,
  TextInput
} from 'components'

import { Formik } from 'formik'
import PropTypes from 'prop-types'
import React from 'react'

const schema = yup.object().shape({
  company: yup.string().required('Name is a required field'),
  icon: yup.mixed(),
  listed: yup.string().url('Link must be a valid URL'),
  listings: yup.array(),
  website: yup.string().url('Website must be a valid URL'),
  twitter: yup.string().url('Must be a valid URL')
})

export const PartnerForm = ({ action, handleSubmit, initialValues, listings }) => (
  <Formik validationSchema={schema} initialValues={initialValues} onSubmit={handleSubmit}>
    {(formikProps) => {
      const { handleSubmit, isSubmitting } = formikProps

      return (
        <form onSubmit={handleSubmit}>
          <Fieldset>
            <MultiSelectInput
              field="listings"
              formikProps={formikProps}
              label="Select listings"
              options={listings}
              placeholder={'Select the listings that are associated with this partner'}
              placeholderNoPossibleOptions={
                'They are no possible listings that can be associated with this partner'
              }
            />
          </Fieldset>
          <Fieldset>
            <TextInput
              formikProps={formikProps}
              label="Name"
              field="company"
              placeholder="ACME"
              required
            />
            <FileUploadInput
              formikProps={formikProps}
              label="Icon"
              field="icon"
              description="Square icon. Ideal dimensions 400x400px."
              multiple={false}
            />
            <TextInput
              formikProps={formikProps}
              label="Website"
              field="website"
              placeholder="https://acme.org"
            />
            <TextInput
              formikProps={formikProps}
              label="Twitter URL"
              field="twitter"
              placeholder="https://twitter.com/stripe"
            />
            <PanelToggle
              field="listed"
              formikProps={formikProps}
              label={"Are you listed on this partner's ecosystem?"}
              show={formikProps.values.listed !== ''}
            >
              <TextInput
                formikProps={formikProps}
                field="listed"
                description={"Link of your product on the partners' ecosystem"}
                placeholder={`https://ecosystem.acme.org/l/example`}
              />
            </PanelToggle>
          </Fieldset>
          <div className="sticky-submit-container">
            <Submit isSubmitting={isSubmitting} text={action} />
          </div>
        </form>
      )
    }}
  </Formik>
)

PartnerForm.propTypes = {
  action: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  listings: PropTypes.array.isRequired
}

export default PartnerForm
