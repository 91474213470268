import { Tab, Tabs } from 'components'
import React from 'react'
import { Link } from 'react-router-dom'

const LeadsNavigation = ({ match }) => {
  const { ecosystemId } = match.params

  return (
    <Tabs
      selectors={[
        {
          tab: 0,
          path: `${match.url}/request-form`,
          exact: true
        },
        {
          tab: 1,
          path: `${match.url}/cta-form`,
          exact: true
        }
      ]}
    >
      <Tab
        label="Request Form"
        component={Link}
        to={`/ecosystems/${ecosystemId}/leads/request-form`}
        data-testid={`ecosystem-leads-tab-request-form`}
      />
      <Tab
        label="CTA Form"
        component={Link}
        to={`/ecosystems/${ecosystemId}/leads/cta-form`}
        data-testid={`ecosystem-leads-tab-cta-form`}
      />
    </Tabs>
  )
}

export default LeadsNavigation
