import { useMutation } from 'react-apollo'
import { toastr } from 'react-redux-toastr'
import uploadFile from 'utils/fileUpload'
import transformError from 'utils/transformError'
import { UPDATE_PARTNER } from '../../graphql'

function useUpdatePartnerAction({ accountId, ecosystemId, partnerId }) {
  const [updatePartnerMutation] = useMutation(UPDATE_PARTNER)

  async function handleUpdatePartner(
    { company, icon, listed, listings, website, twitter },
    { setErrors, setSubmitting }
  ) {
    try {
      setSubmitting(true)

      let variables = {
        accountId,
        company,
        ecosystemId,
        id: partnerId,
        listed,
        listings,
        website,
        twitter
      }

      if (icon.length !== 0 && icon[0].preview) {
        let result = await uploadFile(icon[0], {
          params: {
            folder: `marketplaces/${ecosystemId}/partners`
          }
        })

        variables = {
          ...variables,
          icon: {
            url: result.url,
            contentType: `${result.resourceType}/${result.format}`
          }
        }
      }

      await updatePartnerMutation({ variables })
      toastr.success('Partner Updated')
    } catch (error) {
      toastr.error('could not update partner')

      if (error.graphQLErrors) {
        setErrors(transformError(error.graphQLErrors))
      }
    }
  }

  return [handleUpdatePartner]
}

export { useUpdatePartnerAction }
