import * as yup from 'yup'

import { CardAlert, Fieldset, Submit } from 'components'

import { Formik } from 'formik'
import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'
import { CustomDomainField } from '../../forms/Base'

const schema = yup.object().shape({
  customDomain: yup.string().url()
})

const Guide = () => (
  <React.Fragment>
    <CardAlert.Default>
      <p>
        Need help? <Link to="/account/invitations/create">Invite your CTO</Link> to set everything
        up.
      </p>
    </CardAlert.Default>
    <h2>Add a custom domain name for your ecosystem</h2>
    <p>
      If you are using our apideck ecosystem product you probably want to customize the URL it is
      served behind. <br />
      Changing your URL can help brand awareness, as ecosystem visitors will see a URL in their
      browser they know and trust. <br />
      We provide step-by-step instructions for you to configure your own custom URL. <br />
    </p>
    <h3>1. Create a CNAME record</h3>
    <p>
      Sign in to your DNS name server host and navigate to your DNS settings. <br />
      Add a CNAME record with value <code>cname.vercel-dns.com.</code>
      <br />
    </p>
    <pre>
      <code>[REPLACE_WITH_DESIRED_SUBDOMAIN] CNAME cname.vercel-dns.com.</code>
    </pre>
    <h3>2. Wait for the changes to propagate</h3>
    <p>
      Congratulations! You have now pointed your custom domain to apideck! When DNS changes are
      made, you can expect a propagation time up to 24 hours (but mostly much less then that). This
      is because it takes time for the DNS to take effect across the internet. The actual time of
      propagation may vary in some locations based on your network setup.
    </p>
    <h3>3. Save your custom domain in apideck</h3>
    <p>
      Once your domain successfully points to apideck you can save your custom domain in the form.
    </p>
  </React.Fragment>
)

export const CustomDomainSettings = ({ initialValues, handleSubmit, disabled }) => (
  <Formik validationSchema={schema} initialValues={initialValues} onSubmit={handleSubmit}>
    {(formikProps) => {
      const { handleSubmit, isSubmitting, errors } = formikProps
      return (
        <form onSubmit={handleSubmit} data-testid="ecosystem-settings-custom-domain-form">
          <Fieldset>
            <Guide />
            {errors.general && <span className="errors">{errors.general}</span>}
            <CustomDomainField
              formikProps={formikProps}
              disabled={disabled}
              description={
                disabled &&
                'Custom domains are only available on paid plans. Please contact support to activate this feature.'
              }
            />
          </Fieldset>

          {!disabled && (
            <div className="sticky-submit-container">
              <Submit isSubmitting={isSubmitting} text="Save settings" />
            </div>
          )}
        </form>
      )
    }}
  </Formik>
)

CustomDomainSettings.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object
}

export default CustomDomainSettings
