import React from 'react'
import { Route } from 'react-router-dom'
// import { auth } from 'routes/App'

const renderMergedProps = (WrappedComponent, ...rest) => {
  const finalProps = Object.assign({}, ...rest)
  return <WrappedComponent {...finalProps} />
}

const PrivateRoute = ({ component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(routeProps) => {
        // if (!auth.isAuthenticated()) {
        //   auth.logout('Token expired')
        //   return null
        // }

        return renderMergedProps(component, routeProps, rest)
      }}
    />
  )
}

export default PrivateRoute
