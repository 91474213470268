import { loader } from 'graphql.macro'
import store from 'store'
import { toastr } from 'react-redux-toastr'
import transformError from 'utils/transformError'
import { useMutation } from 'react-apollo'

const CREATE_CONTACT = loader('../contacts/create.graphql')
const INVITE_CONTACTS = loader('../contacts/invite.graphql')

function useCreateContactAction({ accountId, ecosystemId, partnerId }) {
  const [createContactMutation] = useMutation(CREATE_CONTACT, {
    update: (cache, { data: { createContact: contact } }) => {
      store.contacts.add(
        cache,
        {
          accountId,
          ecosystemId,
          partnerId
        },
        {
          contact
        }
      )
    }
  })
  const [inviteContactsMutation] = useMutation(INVITE_CONTACTS)

  async function handleCreateContact(
    { firstName, lastName, email, invite, role },
    { setSubmitting, setErrors },
    handleClose
  ) {
    try {
      setSubmitting(true)

      let variables = {
        accountId,
        email,
        firstName: firstName,
        lastName: lastName,
        role,
        partnerId
      }

      const {
        data: { createContact: contact }
      } = await createContactMutation({ variables })

      if (invite) {
        await inviteContactsMutation({
          variables: {
            accountId,
            ecosystemId,
            ids: [contact.id]
          }
        })
      }

      toastr.success('Contact added and invited')
      handleClose()
    } catch (error) {
      if (error.graphQLErrors) {
        setErrors(transformError(error.graphQLErrors))
      }
      toastr.error('Contact could not be added')
    } finally {
      setSubmitting(false)
    }
  }

  return [handleCreateContact]
}

export { useCreateContactAction }
