import { Tab, Tabs } from 'components'

import { withStyles } from '@material-ui/core/styles'
import React from 'react'
import { Link } from 'react-router-dom'

const styles = () => ({
  sizeSmall: {
    fontSize: '11px',
    height: '16px',
    marginLeft: '3px',
    pointerEvents: 'none'
  }
})

const ListingsNavigation = ({ match }) => {
  const { ecosystemId } = match.params

  return (
    <Tabs
      selectors={[
        {
          tab: 0,
          path: '/ecosystems/:ecosystemId/listings/overview'
        },
        {
          tab: 1,
          path: '/ecosystems/:ecosystemId/listings/categories',
          exact: true
        },
        {
          tab: 2,
          path: '/ecosystems/:ecosystemId/listings/collections',
          exact: true
        },
        {
          tab: 3,
          path: '/ecosystems/:ecosystemId/listings/products',
          exact: true
        },
        {
          tab: 4,
          path: '/ecosystems/:ecosystemId/listings/automated',
          exact: true
        }
      ]}
    >
      <Tab
        label="Overview"
        component={Link}
        to={`/ecosystems/${ecosystemId}/listings/overview`}
        data-testid={`ecosystem-listings-tab-overview`}
      />
      <Tab
        label="Categories"
        component={Link}
        to={`/ecosystems/${ecosystemId}/listings/categories`}
        data-testid={`ecosystem-listings-tab-categories`}
      />
      <Tab
        label="Collections"
        component={Link}
        to={`/ecosystems/${ecosystemId}/listings/collections`}
        data-testid={`ecosystem-listings-tab-collections`}
      />
      <Tab
        label="Products"
        component={Link}
        to={`/ecosystems/${ecosystemId}/listings/products`}
        data-testid={`ecosystem-listings-tab-products`}
      />
      <Tab
        label="Automated"
        component={Link}
        to={`/ecosystems/${ecosystemId}/listings/automated`}
        data-testid={`ecosystem-listings-tab-automated`}
      />
    </Tabs>
  )
}

export default withStyles(styles)(ListingsNavigation)
