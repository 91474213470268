export default (user, userAccounts = []) => {
  const companies = userAccounts.map((userAccount) => {
    return {
      companyId: userAccount.account.id,
      name: userAccount.account.name,
      website: userAccount.account.website,
      plan:
        userAccount.account.plan &&
        userAccount.account.subscriptionStatus === 'ACTIVE' &&
        userAccount.account.plan,
      createdAt: userAccount.account.createdAt
    }
  })

  const intercomData = {
    userId: user.id,
    email: user.email,
    name: user.name,
    companies
  }

  return intercomData
}
