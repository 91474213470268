import { CardNoData } from 'components'
import DashboardCard from './Card'
import React from 'react'
import { useQuery } from 'react-apollo'

const SearchesCard = ({ query, title, accountId, ecosystemId }) => {
  const { loading, error, data } = useQuery(query, {
    variables: {
      ecosystemId,
      accountId
    }
  })

  if (error) {
    return (
      <DashboardCard title={title} size={4} height="280px">
        Unable to fetch search analytics
      </DashboardCard>
    )
  }
  if (loading) {
    return <DashboardCard title={title} size={4} height="280px" loading />
  }

  return (
    <DashboardCard title={title} size={4} height="280px">
      {data.searches && data.searches.length > 0 ? (
        <div className="flex flex-column justify-between" style={{ height: '232px' }}>
          <ul>
            {data.searches.map((s, i) => (
              <li key={i}>
                {s.query} {s.count}
              </li>
            ))}
          </ul>
          <p>Attention: this data gets reset weekly.</p>
        </div>
      ) : (
        <CardNoData />
      )}
    </DashboardCard>
  )
}

export default SearchesCard
