import React from 'react'
import styled from 'react-emotion'
import classNames from 'classnames'
import PropTypes from 'prop-types'

const Button = styled('button')`
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: var(--gradient);
  height: 56px;
  border-radius: 4px;
  line-height: 1;
  font-size: 18px;
  font-weight: 500;
  background-size: 200% auto;
  transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
  width: 100%;
  margin-bottom: 20px;
  border: none;

  &:hover {
    background-position: 100% 0;
  }

  &.disabled {
    cursor: default;

    &:hover {
      background-position: unset;
    }
  }
`

const PrimaryNew = ({ children, handleClick, disabled, ...rest }) => (
  <Button
    className={classNames({ disabled: disabled })}
    onClick={handleClick}
    disabled={disabled}
    {...rest}
  >
    {children}
  </Button>
)

PrimaryNew.propTypes = {
  handleClick: PropTypes.func
}

export default PrimaryNew
