import React from 'react'
import styled from 'react-emotion'

const DefaultDiv = styled('div')`
  background-color: #f4f5f6;
  padding: 15px 0 2px;
  border-radius: 5px;
  margin-bottom: 15px;
  text-align: center;

  a:hover {
    text-decoration: underline;
  }
`

const Default = ({ children }) => <DefaultDiv>{children}</DefaultDiv>

export default {
  Default
}
