import { updateMarketplaceCustomDomainSettingsMutation } from 'api/queries'
import { CustomDomainSettings } from 'components'
import LoadingPlaceholder from 'components/placeholder/Loading'
import { NOT_UPDATED, UPDATED } from 'constants/settings'
import PropTypes from 'prop-types'
import React from 'react'
import { graphql } from 'react-apollo'
import { toastr } from 'react-redux-toastr'
import { compose, withHandlers } from 'recompose'
import transformError from 'utils/transformError'

class Container extends React.Component {
  render() {
    const { handleSubmit, marketplace, activeAccount } = this.props

    if (!marketplace) {
      return <LoadingPlaceholder />
    } else {
      return (
        <CustomDomainSettings
          handleSubmit={handleSubmit}
          disabled={activeAccount.plan === 'FREE'}
          initialValues={{
            customDomain: marketplace.customDomain || ''
          }}
        />
      )
    }
  }
}

Container.propTypes = {
  activeAccount: PropTypes.object.isRequired,
  marketplace: PropTypes.object.isRequired
}

const handlers = withHandlers({
  handleSubmit:
    ({ updateMarketplace, activeAccount, marketplace }) =>
    async ({ customDomain }, { setSubmitting, setErrors }) => {
      setSubmitting(true)

      let variables = {
        accountId: activeAccount.id,
        id: marketplace.id,
        name: marketplace.name,
        slug: marketplace.slug,
        customDomain
      }

      try {
        await updateMarketplace({
          variables
        })

        toastr.success(UPDATED)
        setSubmitting(false)
      } catch (error) {
        toastr.error(NOT_UPDATED)
        if (error.graphQLErrors) {
          setErrors(transformError(error.graphQLErrors))
        }

        setSubmitting(false)
      }
    }
})

const updateMarketplace = graphql(updateMarketplaceCustomDomainSettingsMutation, {
  name: 'updateMarketplace'
})

const SettingsContainer = compose(updateMarketplace, handlers)(Container)

export default SettingsContainer
