import { Divider, SocialButtons } from 'components'

import { Link } from 'react-router-dom'
import LoginForm from './Form'
import PropTypes from 'prop-types'
import React from 'react'

const Login = ({
  handleUsernamePasswordSubmit,
  handleGoogleSubmit,
  handleGithubSubmit,
  initialValues
}) => (
  <React.Fragment>
    <h2>Log in to Apideck</h2>
    <p style={{ fontSize: 15, marginTop: 0 }}>
      {`Don't have an account yet? `}
      <Link
        to="signup"
        style={{ fontWeight: 'semibold', textDecoration: 'underline', color: 'white' }}
      >
        Sign up
      </Link>
    </p>
    <SocialButtons
      handleGoogleSubmit={handleGoogleSubmit}
      handleGithubSubmit={handleGithubSubmit}
      action="Sign in"
    />
    <Divider>or</Divider>

    <LoginForm onSubmit={handleUsernamePasswordSubmit} initialValues={initialValues} />
  </React.Fragment>
)

Login.propTypes = {
  handleUsernamePasswordSubmit: PropTypes.func.isRequired,
  handleGoogleSubmit: PropTypes.func.isRequired
}

export default Login
