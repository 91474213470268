import { CardNew as Card, Submit, SwitchInput } from 'components'
import { capitalize, getListingName } from 'components/Helpers'

import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormGroup from '@material-ui/core/FormGroup'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import Alert from '@material-ui/lab/Alert'
import { Formik } from 'formik'
import PropTypes from 'prop-types'
import React from 'react'
import ecosystemUrl from 'utils/ecosystemUrl'

const useStyles = makeStyles((theme) => ({
  formGroup: {
    marginTop: theme.spacing(4),
    paddingBottom: theme.spacing(1),
    borderBottom: `1px solid ${theme.palette.ui[200]}`,
    '&:first-of-type': {
      marginTop: theme.spacing(1)
    },
    '&:last-of-type': {
      borderBottom: 'none'
    }
  },
  title: {
    fontSize: '15px',
    fontWeight: 500,
    color: theme.palette.ui[800]
  },
  description: {
    maxWidth: '520px',
    fontSize: '14px',
    color: '#686b89', // TODO add to theme
    marginBottom: theme.spacing(1)
  },
  checkbox: {
    marginBottom: 0
  },
  indentedCheckbox: {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(-1)
  },
  label: {
    fontSize: '15px',
    color: theme.palette.ui[800]
  },
  button: {
    marginTop: theme.spacing(2)
  }
}))

const LeadsSettingsPage = ({ ecosystem, initialValues, handleSubmit, loading }) => {
  const classes = useStyles()
  const { leadIntegrationEnabled } = ecosystem

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      {(formikProps) => {
        const { handleSubmit, values, handleChange } = formikProps

        return (
          <form
            onSubmit={handleSubmit}
            position="relative"
            data-testid="ecosystem-settings-leads-form"
          >
            <Card
              title={`${capitalize(getListingName(ecosystem).singular)} request form settings`}
              action={
                <Button
                  className="fr"
                  target="_blank"
                  href={`${ecosystemUrl(ecosystem)}/request`}
                  endIcon={
                    <OpenInNewIcon
                      style={{
                        color: 'currentColor',
                        fontSize: 16
                      }}
                    />
                  }
                >
                  View request form
                </Button>
              }
            >
              <FormGroup className={classes.formGroup}>
                <Typography className={classes.title}>Extra fields</Typography>
                <Typography className={classes.description}>
                  Extend the request form with additional fields
                </Typography>
                {leadIntegrationEnabled && (
                  <Alert severity="warning" style={{ margin: '16px 0' }}>
                    Because a leads integration is enabled for this ecosystem, the first name and
                    last name fields are shown and required by default
                  </Alert>
                )}
                <FormControlLabel
                  classes={{
                    root: classes.checkbox,
                    label: classes.label
                  }}
                  control={
                    <Checkbox
                      name="leadFirstNameFieldEnabled"
                      checked={values.leadFirstNameFieldEnabled}
                      onChange={handleChange}
                      size="small"
                      disabled={leadIntegrationEnabled}
                    />
                  }
                  label="First name"
                />
                {values.leadFirstNameFieldEnabled && (
                  <FormControlLabel
                    classes={{
                      root: classes.indentedCheckbox,
                      label: classes.label
                    }}
                    control={
                      <Checkbox
                        name="leadFirstNameFieldRequired"
                        checked={values.leadFirstNameFieldRequired}
                        onChange={handleChange}
                        size="small"
                        disabled={leadIntegrationEnabled}
                      />
                    }
                    label="Make first name field required"
                  />
                )}
                <FormControlLabel
                  classes={{
                    root: classes.checkbox,
                    label: classes.label
                  }}
                  control={
                    <Checkbox
                      name="leadLastNameFieldEnabled"
                      checked={values.leadLastNameFieldEnabled}
                      onChange={handleChange}
                      size="small"
                      disabled={leadIntegrationEnabled}
                    />
                  }
                  label="Last name"
                />
                {values.leadLastNameFieldEnabled && (
                  <FormControlLabel
                    classes={{
                      root: classes.indentedCheckbox,
                      label: classes.label
                    }}
                    control={
                      <Checkbox
                        name="leadLastNameFieldRequired"
                        checked={values.leadLastNameFieldRequired}
                        onChange={handleChange}
                        size="small"
                        disabled={leadIntegrationEnabled}
                      />
                    }
                    label="Make last name field required"
                  />
                )}
                <FormControlLabel
                  classes={{
                    root: classes.checkbox,
                    label: classes.label
                  }}
                  control={
                    <Checkbox
                      name="leadTelephoneFieldEnabled"
                      checked={values.leadTelephoneFieldEnabled}
                      onChange={handleChange}
                      size="small"
                    />
                  }
                  label="Telephone"
                />
                {values.leadTelephoneFieldEnabled && (
                  <FormControlLabel
                    classes={{
                      root: classes.indentedCheckbox,
                      label: classes.label
                    }}
                    control={
                      <Checkbox
                        name="leadTelephoneFieldRequired"
                        checked={values.leadTelephoneFieldRequired}
                        onChange={handleChange}
                        size="small"
                      />
                    }
                    label="Make telephone field required"
                  />
                )}
              </FormGroup>
              <FormGroup className={classes.formGroup}>
                <Typography className={classes.title}>Business email validation</Typography>
                <Typography className={classes.description}>
                  Only allow business email addresses to be filled in, meaning all personal email
                  addresses (Gmail/Outlook/Hotmail/..) will not be accepted
                </Typography>
                <SwitchInput field="leadWorkEmailValidation" formikProps={formikProps} />
              </FormGroup>
            </Card>
            <Card
              title="CTA form settings"
              action={
                values.leadCaptureFormEnabled && (
                  <Button
                    className="fr"
                    target="_blank"
                    href={`${ecosystemUrl(ecosystem)}#cta`}
                    endIcon={
                      <OpenInNewIcon
                        style={{
                          color: 'currentColor',
                          fontSize: 16
                        }}
                      />
                    }
                  >
                    View CTA Form
                  </Button>
                )
              }
            >
              <FormGroup className={classes.formGroup}>
                <Typography className={classes.title}>Enable CTA form</Typography>
                <Typography className={classes.description}>
                  The CTA form will be added to the CTA section on your ecosystem
                </Typography>
                <SwitchInput field="leadCaptureFormEnabled" formikProps={formikProps} />
              </FormGroup>
            </Card>
            <div className="sticky-submit-container">
              <Submit isSubmitting={loading} text="Save settings" />
            </div>
          </form>
        )
      }}
    </Formik>
  )
}

LeadsSettingsPage.propTypes = {
  initialValues: PropTypes.shape({
    leadTelephoneFieldEnabled: PropTypes.bool.isRequired,
    leadTelephoneFieldRequired: PropTypes.bool.isRequired,
    leadFirstNameFieldEnabled: PropTypes.bool.isRequired,
    leadFirstNameFieldRequired: PropTypes.bool.isRequired,
    leadLastNameFieldEnabled: PropTypes.bool.isRequired,
    leadWorkEmailValidation: PropTypes.bool.isRequired,
    leadCaptureFormEnabled: PropTypes.bool.isRequired
  }).isRequired,
  loading: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired
}

export default LeadsSettingsPage
