import { HeaderWithTabs, AccountNavigation as Navigation } from 'components'

import React from 'react'
import { withRouter } from 'react-router-dom'

const Header = ({ match }) => (
  <HeaderWithTabs tabs={<Navigation match={match} />}>
    <h1>Account</h1>
  </HeaderWithTabs>
)

const AccountHeader = withRouter(Header)

export default AccountHeader
