import { makeStyles } from '@material-ui/core/styles'
import {
  AnyErrorsMessage,
  LegalNotice,
  PrimaryButtonNew as PrimaryButton,
  TextInput
} from 'components'
import { Formik } from 'formik'
import PropTypes from 'prop-types'
import React from 'react'
import { ScaleLoader } from 'react-spinners'

const useStyles = makeStyles((theme) => ({
  inputWrapper: {
    marginBottom: theme.spacing(2)
  }
}))

export const Form = ({ initialValues, onSubmit }) => {
  const classes = useStyles()

  return (
    <Formik onSubmit={onSubmit} initialValues={initialValues}>
      {(formikProps) => {
        const { handleSubmit, isSubmitting } = formikProps

        return (
          <form onSubmit={handleSubmit}>
            <div className={classes.inputWrapper}>
              <TextInput
                formikProps={formikProps}
                label="Email"
                field="email"
                type="email"
                autofill="username"
                data-testid="login-email-input"
              />
            </div>

            <div className={classes.inputWrapper}>
              <TextInput
                formikProps={formikProps}
                label="Password"
                field="password"
                type="password"
                autofill="current-password"
                data-testid="login-password-input"
              />
            </div>

            <PrimaryButton type="submit" disabled={isSubmitting} data-testid="login-submit">
              {isSubmitting ? (
                <ScaleLoader height={12} width={3} color="var(--color-white)" />
              ) : (
                'Login'
              )}
            </PrimaryButton>

            <AnyErrorsMessage />

            <LegalNotice />
          </form>
        )
      }}
    </Formik>
  )
}

Form.propTypes = {
  onSubmit: PropTypes.func.isRequired
}

export default Form
