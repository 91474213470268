import * as yup from 'yup'

import {
  ColorPicker,
  FeaturePill,
  Fieldset,
  FileUploadInput,
  GridContainer,
  HtmlTextInput,
  Logo,
  MarkdownTextInput,
  PanelInputWithInfo,
  SelectInput,
  SwitchInput,
  TextInput
} from 'components'
import React, { Fragment } from 'react'

import { Grid } from '@material-ui/core'
import ecosystemUrl from 'utils/ecosystemUrl'
import { getListingName } from 'components/Helpers'
import integrationPlatforms from 'constants/integrationPlatforms'
import localeCodes from 'locale-codes'
import styled from 'react-emotion'

const baseValidationSchema = {
  name: yup.string().required('Name is a required field'),
  slug: yup
    .string()
    .matches(
      /^[a-z0-9]+(?:-[a-z0-9]+)*$/,
      'Must be alphanumeric (A-Z, 0-9) with dashes between words.'
    )
    .required('Slug is a required field'),
  website: yup.string().required('Website is a required field')
}

const templateValidationSchema = {
  cardStyle: yup.mixed().required(),
  logo: yup.mixed(),
  mastheadBackgroundImage: yup.mixed().required()
}

const seoValidationSchema = {
  socialImage: yup.mixed()
}

const privacyAndTermsLinkValidation = {
  privacyUrl: yup.string().url(),
  termsUrl: yup.string().url()
}

const NameField = ({ formikProps }) => (
  <TextInput formikProps={formikProps} label="Name" field="name" required />
)

const SlugField = ({ formikProps }) => (
  <TextInput
    formikProps={formikProps}
    label="Subdomain"
    field="slug"
    required
    prefix="https://"
    suffix=".apideck.io"
    description="Must be alphanumeric (A-Z, 0-9) with dashes between words. You will be able to configure a custom domain after the setup if required."
  />
)

const WebsiteField = ({ formikProps }) => (
  <TextInput
    formikProps={formikProps}
    label="Website"
    field="website"
    description="We need this to detect integrations on your current website accurately."
    required
  />
)

const CustomDomainField = ({ formikProps, ...rest }) => (
  <TextInput formikProps={formikProps} label="Custom domain" field="customDomain" {...rest} />
)

const LogoField = ({ formikProps, handleLogoDelete }) => (
  <FileUploadInput
    formikProps={formikProps}
    label="Logo"
    field="logo"
    description="Ideal dimensions 600x100px."
    multiple={false}
    handleDelete={handleLogoDelete}
  />
)

const IconField = ({ formikProps, ecosystem, handleIconDelete }) => (
  <FileUploadInput
    formikProps={formikProps}
    label="Icon"
    field="icon"
    description={`Ideal dimensions 400x400px. This icon is being used on the automated listing pages (e.g., ${ecosystemUrl(
      ecosystem
    )}/s/google-pay).`}
    multiple={false}
    handleDelete={handleIconDelete}
  />
)

const FaviconField = ({ formikProps, handleFaviconDelete }) => (
  <FileUploadInput
    formikProps={formikProps}
    label="Favicon"
    field="favicon"
    description=" Ideal dimensions 400x400px."
    multiple={false}
    handleDelete={handleFaviconDelete}
  />
)

const MastheadBackgroundImage = ({ formikProps, handleBackgroundImageDelete }) => (
  <Fragment>
    <FileUploadInput
      formikProps={formikProps}
      label="Masthead Background Image"
      field="mastheadBackgroundImage"
      description="Recommended minimum width: 2000px. Max size is 10MB."
      multiple={false}
      maxSize={10485760}
      handleDelete={handleBackgroundImageDelete}
    />
  </Fragment>
)

const MastheadImage = ({ formikProps, handleImageDelete }) => (
  <Fragment>
    <FileUploadInput
      formikProps={formikProps}
      label="Masthead Image"
      field="mastheadImage"
      description="Max size is 10MB."
      multiple={false}
      maxSize={10485760}
      handleDelete={handleImageDelete}
    />
  </Fragment>
)

const MastheadColumnsField = ({ formikProps }) => (
  <SelectInput
    formikProps={formikProps}
    label="# Columns"
    field="mastheadColumns"
    description="Set the amount of columns (defaults to full-width)"
    options={[
      {
        label: 'Full-width',
        value: 1
      },
      {
        label: '2 columns',
        value: 2
      }
    ]}
  />
)

const SocialImageField = ({ formikProps, handleSocialImageDelete }) => (
  <FileUploadInput
    formikProps={formikProps}
    label="Social image"
    field="socialImage"
    description="Image that will be used on social platforms. Ideal dimensions 1024x512px."
    multiple={false}
    handleDelete={handleSocialImageDelete}
  />
)

const SeoFieldset = ({ formikProps }) => (
  <Fragment>
    <Fieldset title="Meta tags">
      <AvailableTags />
      <TextInput formikProps={formikProps} label="Title" field="metaTagTitle" localized="true" />
      <TextInput
        formikProps={formikProps}
        label="Postfix Title"
        field="metaTagTitlePostfix"
        localized="true"
      />
      <TextInput
        formikProps={formikProps}
        label="Description"
        field="metaTagDescription"
        localized="true"
      />
      <TextInput
        formikProps={formikProps}
        label="Keywords"
        field="metaTagKeywords"
        localized="true"
      />
      <TextInput
        formikProps={formikProps}
        label="Listing page description"
        field="metaTagDescriptionListingPage"
        localized="true"
      />
      <TextInput
        formikProps={formikProps}
        label="Category page description"
        field="metaTagDescriptionCategoryPage"
        localized="true"
      />
      <TextInput
        formikProps={formikProps}
        label="Collection page description"
        field="metaTagDescriptionCollectionPage"
        localized="true"
      />
    </Fieldset>
    <Fieldset title="SEO">
      <TextInput
        formikProps={formikProps}
        label="Listing name postfix"
        field="listingNamePostfix"
        description="Append a custom string to each listing name on detail pages to optimize SEO. E.g. integration, app, ..."
        localized="true"
      />
      <TextInput
        formikProps={formikProps}
        label="UTM campaign"
        field="utmCampaign"
        description="Replace the custom campaign property on links"
      />
    </Fieldset>
  </Fragment>
)

const PrimaryColorField = ({ formikProps }) => (
  <ColorPicker formikProps={formikProps} label="Primary color" field="primaryColor" />
)

const localesSelectList = () => {
  const locales = []

  for (const locale of localeCodes.all) {
    !locale.location &&
      locales.push({
        label: locale.name,
        value: locale.tag
      })
  }

  return locales
}

const LanguageField = ({ formikProps }) => (
  <SelectInput
    formikProps={formikProps}
    label="Language"
    field="locale"
    defaultValue="en"
    options={localesSelectList()}
  />
)

const MenuPositionField = ({ formikProps }) => (
  <SelectInput
    formikProps={formikProps}
    label="Sidebar position"
    field="menuPosition"
    defaultValue="LEFT"
    options={[
      {
        label: 'Left',
        value: 'LEFT'
      },
      {
        label: 'Right',
        value: 'RIGHT'
      },
      {
        label: 'Top',
        value: 'TOP'
      },
      {
        label: 'Hidden',
        value: 'HIDDEN'
      }
    ]}
  />
)

const MenuStyleField = ({ formikProps }) => (
  <SelectInput
    formikProps={formikProps}
    label="Menu style"
    field="menuStyle"
    defaultValue="LIST"
    options={[
      {
        label: 'Lists',
        value: 'LIST'
      },
      {
        label: 'Pills',
        value: 'PILL'
      },
      {
        label: 'Filters',
        value: 'FILTER'
      }
    ]}
  />
)

const IconFieldset = ({ formikProps, ecosystem, handleIconDelete, handleFaviconDelete }) => (
  <Fieldset title="Icons">
    <GridContainer>
      <Grid item lg={6}>
        <IconField
          formikProps={formikProps}
          ecosystem={ecosystem}
          handleIconDelete={handleIconDelete}
        />
      </Grid>
      <Grid item lg={6}>
        <FaviconField formikProps={formikProps} handleFaviconDelete={handleFaviconDelete} />
      </Grid>
    </GridContainer>
  </Fieldset>
)

const MastheadFieldset = ({ formikProps, handleBackgroundImageDelete, handleImageDelete }) => (
  <Fieldset title="Masthead">
    <MastheadBackgroundImage
      formikProps={formikProps}
      handleBackgroundImageDelete={handleBackgroundImageDelete}
    />
    <GridContainer>
      <Grid item lg={6}>
        <ColorPicker
          formikProps={formikProps}
          label="Masthead background color"
          field="mastheadBackgroundColor"
        />
      </Grid>
      <Grid item lg={6}>
        <ColorPicker formikProps={formikProps} label="Masthead color" field="mastheadColor" />
      </Grid>
    </GridContainer>
    <GridContainer>
      <Grid item lg={6}>
        <MastheadColumnsField formikProps={formikProps} />
        {formikProps.values.mastheadColumns === 2 && (
          <MastheadImage formikProps={formikProps} handleImageDelete={handleImageDelete} />
        )}
      </Grid>
    </GridContainer>
  </Fieldset>
)

const HomePageFieldset = ({ formikProps }) => (
  <Fieldset title="Homepage">
    <TextInput
      formikProps={formikProps}
      label="Masthead title"
      field="mastheadTitle"
      localized="true"
    />
    <TextInput
      formikProps={formikProps}
      label="Masthead description"
      field="mastheadDescription"
      localized="true"
    />
    <PanelInputWithInfo
      rightBloc={<p>Show all listings on homepage</p>}
      rightBlocAddon={<SwitchInput formikProps={formikProps} field="homePageShowAllListings" />}
    />
    <PanelInputWithInfo
      rightBloc={<p>Enable collection and category cards (grouped listings)</p>}
      rightBlocAddon={
        <SwitchInput formikProps={formikProps} field="homePageCollectionCategoryCards" />
      }
    />
  </Fieldset>
)

const TemplateColorsFieldset = ({ formikProps }) => (
  <Fieldset title="Colors">
    <GridContainer>
      <Grid item lg={6}>
        <ColorPicker formikProps={formikProps} label="Primary color" field="primaryColor" />
        <ColorPicker formikProps={formikProps} label="Body color" field="bodyColor" />
      </Grid>
      <Grid item lg={6}>
        <ColorPicker
          formikProps={formikProps}
          label="Body background color"
          field="bodyBackgroundColor"
        />
        <ColorPicker formikProps={formikProps} label="Body link color" field="bodyLinkColor" />
      </Grid>
    </GridContainer>
  </Fieldset>
)

const TemplateCtaFieldset = ({ formikProps }) => (
  <Fieldset title="CTA">
    <TextInput formikProps={formikProps} label="CTA title" field="ctaTitle" localized="true" />
    <TextInput
      formikProps={formikProps}
      label="CTA description"
      field="ctaDescription"
      localized="true"
    />
    <TextInput formikProps={formikProps} label="CTA button link" field="ctaButtonLink" />
    <TextInput
      formikProps={formikProps}
      label="CTA button label"
      field="ctaButtonLabel"
      localized="true"
    />
    <GridContainer>
      <Grid item lg={6}>
        <ColorPicker
          formikProps={formikProps}
          label="CTA background color"
          field="ctaBackgroundColor"
        />
        <ColorPicker
          formikProps={formikProps}
          label="CTA button background color"
          field="ctaButtonBackgroundColor"
        />
      </Grid>
      <Grid item lg={6}>
        <ColorPicker formikProps={formikProps} label="CTA color" field="ctaColor" />
        <ColorPicker formikProps={formikProps} label="CTA button color" field="ctaButtonColor" />
      </Grid>
    </GridContainer>
    <PanelInputWithInfo
      rightBloc={<p>Enable CTA section</p>}
      rightBlocAddon={<SwitchInput formikProps={formikProps} field="ctaEnabled" />}
    />
  </Fieldset>
)

const DetailPageFieldset = ({ formikProps }) => (
  <Fieldset title="Similar listings section (only visibile on listing pages)">
    <GridContainer>
      <Grid item lg={6}>
        <ColorPicker
          formikProps={formikProps}
          label="Background color"
          field="alternativesBackgroundColor"
        />
      </Grid>
      <Grid item lg={6}>
        <ColorPicker formikProps={formikProps} label="Color" field="alternativesColor" />
      </Grid>
    </GridContainer>
  </Fieldset>
)

const TemplateNavigationFieldset = ({ formikProps, children, handleEcosystemLogoDelete }) => (
  <Fieldset title="Navigation">
    <LogoField formikProps={formikProps} handleLogoDelete={handleEcosystemLogoDelete} />
    <GridContainer>
      <Grid item lg={6}>
        <TextInput
          formikProps={formikProps}
          label="Navigation logo text"
          description="This text will be appended to your logo"
          placeholder="e.g. Integrations"
          field="navigationLogoPostFix"
        />
      </Grid>
      <Grid item lg={6}>
        <SelectInput
          formikProps={formikProps}
          label="Navigation mobile menu type"
          field="navigationMobileMenuType"
          description="Adjust mobile menu to icon or text"
          options={[
            {
              label: 'Icon',
              value: 'ICON'
            },
            {
              label: 'Text',
              value: 'TEXT'
            }
          ]}
        />
      </Grid>
    </GridContainer>
    <PanelInputWithInfo
      rightBloc={<p>Make navigation bar sticky </p>}
      rightBlocAddon={<SwitchInput formikProps={formikProps} field="navigationSticky" />}
    />
    {formikProps.values.navigationSticky && (
      <GridContainer>
        <Grid item lg={6}>
          <ColorPicker
            formikProps={formikProps}
            label="Background color"
            field="navigationBackgroundColor"
          />
        </Grid>
        <Grid item lg={6}>
          <ColorPicker formikProps={formikProps} label="Text color" field="navigationColor" />
        </Grid>
      </GridContainer>
    )}
    {children}
  </Fieldset>
)

const TemplateOverviewSidebarFieldset = ({ formikProps, ecosystem }) => (
  <Fieldset title="Sidebar">
    <MenuPositionField formikProps={formikProps} />
    <MenuStyleField formikProps={formikProps} />
    <TextInput
      formikProps={formikProps}
      label="Limit the amount of categories shown in the sidebar"
      field="categoriesShowMaxItems"
      type="number"
      min="1"
      max="100"
      placeholder="20"
    />
    <PanelInputWithInfo
      rightBloc={<p>Show category totals badge</p>}
      rightBlocAddon={<SwitchInput formikProps={formikProps} field="categoriesCountBadge" />}
    />
    <TextInput
      formikProps={formikProps}
      label="Collections title"
      description="Overwrite the collections label on your ecosystem template"
      field="collectionsTitle"
      localized="true"
    />
    <PanelInputWithInfo
      rightBloc={<p>Show collection totals badge</p>}
      rightBlocAddon={<SwitchInput formikProps={formikProps} field="collectionsCountBadge" />}
    />
    <TextInput
      formikProps={formikProps}
      label="Zaps menu title"
      description="overwrite the default zaps menu title"
      field="zapsMenuTitle"
      style={{ marginTop: '40px' }}
      localized="true"
    />
    <TextInput
      formikProps={formikProps}
      label={`Custom create ${getListingName(ecosystem).singular} link`}
      placeholder={`${ecosystemUrl(ecosystem)}/partner/request`}
      description="Overwrite the link to the default partner request form"
      field="createLink"
    />
  </Fieldset>
)

const VotingPortalFieldsetTitle = () => (
  <Fragment>
    Voting Portal <FeaturePill href="https://www.apideck.com/voting-portal" label="BETA" />
  </Fragment>
)

const VotingPortalFieldset = ({ formikProps }) => (
  <Fieldset title={<VotingPortalFieldsetTitle />} id="voting-portal">
    <PanelInputWithInfo
      rightBloc={<p>Show requested listings and allow visitors to upvote them</p>}
      rightBlocAddon={<SwitchInput formikProps={formikProps} field="showRequestedListings" />}
    />
  </Fieldset>
)

const TemplateFooterFieldset = ({ formikProps, children }) => (
  <Fieldset title="Footer">
    <GridContainer>
      <Grid item lg={6}>
        <ColorPicker
          formikProps={formikProps}
          label="Footer background color"
          field="footerBackgroundColor"
        />
      </Grid>
      <Grid item lg={6}>
        <ColorPicker formikProps={formikProps} label="Footer color" field="footerColor" />
      </Grid>
    </GridContainer>
    {children}
  </Fieldset>
)

const CardStyleField = ({ formikProps }) => (
  <SelectInput
    formikProps={formikProps}
    label="Card style"
    field="cardStyle"
    // description='Pick a style that fits your ecosystem.'
    options={[
      {
        label: 'Basic',
        value: 'STYLE1'
      },
      {
        label: 'Wide',
        value: 'STYLE2'
      },
      {
        label: 'Wide + icon background',
        value: 'STYLE12'
      },
      {
        label: 'Compact',
        value: 'STYLE3'
      },
      {
        label: 'Icon',
        value: 'STYLE4'
      },
      {
        label: 'Icon + title',
        value: 'STYLE5'
      },
      {
        label: 'Big icon',
        value: 'STYLE10'
      },
      {
        label: 'Circle',
        value: 'STYLE11'
      },
      {
        label: 'Top circle icon',
        value: 'STYLE6'
      },
      {
        label: 'Cover + icon',
        value: 'STYLE7'
      },
      {
        label: 'Cover + overlay icon',
        value: 'STYLE8'
      }
    ]}
  />
)

const CardColumnsField = ({ formikProps }) => (
  <SelectInput
    formikProps={formikProps}
    label="# Card columns (max 6)"
    field="cardColumns"
    description="Set the amount of columns"
    options={[
      {
        label: '2 columns',
        value: 2
      },
      {
        label: '3 columns',
        value: 3
      },
      {
        label: '4 columns',
        value: 4
      },
      {
        label: '6 columns',
        value: 6
      }
    ]}
  />
)

const CardFieldset = ({ formikProps }) => (
  <Fieldset title="Cards">
    <GridContainer>
      <Grid item lg={6}>
        <CardColumnsField formikProps={formikProps} />
        <CardStyleField formikProps={formikProps} />
        {formikProps.values.cardShadowEnabled && (
          <ColorPicker
            formikProps={formikProps}
            label="Card border color"
            field="cardBorderColor"
            description="Overwrite the default border color"
          />
        )}
        <ColorPicker
          formikProps={formikProps}
          label="Card background color"
          field="cardBackgroundColor"
        />
        <PanelInputWithInfo
          rightBloc={<p>Show action in cards (only on hover)</p>}
          rightBlocAddon={<SwitchInput formikProps={formikProps} field="cardShowAction" />}
        />
        <PanelInputWithInfo
          rightBloc={<p>Show category in cards</p>}
          rightBlocAddon={<SwitchInput formikProps={formikProps} field="cardShowCategory" />}
        />
      </Grid>
      <Grid item lg={6}>
        <TextInput
          formikProps={formikProps}
          label="Border radius"
          field="cardBorderRadius"
          placeholder="5px"
          description="Overwrite the default border radius"
        />
        <TextInput
          formikProps={formikProps}
          label="Split tagline across multiple lines"
          field="cardDescriptionLines"
          type="number"
          min="1"
          max="3"
          placeholder="1"
        />
        {formikProps.values.cardShadowEnabled && (
          <TextInput
            formikProps={formikProps}
            label="Card border size"
            field="cardBorderSize"
            placeholder="1px"
            description="Overwrite the default border size of 1px"
          />
        )}
        {/* <ColorPicker
          formikProps={formikProps}
          label='Card color'
          field='cardColor'
        /> */}
        <PanelInputWithInfo
          rightBloc={<p>Show tag line in cards</p>}
          rightBlocAddon={<SwitchInput formikProps={formikProps} field="cardShowDescription" />}
        />
        <PanelInputWithInfo
          rightBloc={<p>Enable card shadow</p>}
          rightBlocAddon={<SwitchInput formikProps={formikProps} field="cardShadowEnabled" />}
        />
      </Grid>
    </GridContainer>
    {/* <TextInput
      formikProps={formikProps}
      label='Create link'
      field='createLink'
      description=''
    /> */}
  </Fieldset>
)

const IntegrationsFieldset = ({ formikProps }) => (
  <Fieldset title="Integrations">
    <PanelInputWithInfo
      leftBloc={<Logo type="zapier" />}
      rightBloc={
        <TextInput formikProps={formikProps} label="Zapier id" field="integrationZapierId" />
      }
    />
    <PanelInputWithInfo
      leftBloc={<div />}
      rightBloc={`Activate dedicated Zaps page. A menu link will be automatically added to the categories menu on your ecosystem.`}
      rightBlocAddon={
        <SwitchInput
          formikProps={formikProps}
          label="Zaps and Zapier templates page"
          field="zapsPageEnabled"
        />
      }
    />
    {formikProps.values.zapsPageEnabled && (
      <PanelInputWithInfo
        leftBloc={<div />}
        rightBloc={
          <TextInput
            formikProps={formikProps}
            label="Zaps menu link label"
            field="zapsMenuTitle"
            placeholder="Zaps"
          />
        }
      />
    )}
    <PanelInputWithInfo
      leftBloc={<div />}
      rightBloc={
        <TextInput
          formikProps={formikProps}
          label="Zapier beta link"
          field="integrationZapierBetaLink"
          description="This is the Zapier link to invite people to join your Zapier beta."
        />
      }
    />
    <PanelInputWithInfo
      leftBloc={<Logo type="segment" />}
      rightBloc={
        <TextInput formikProps={formikProps} label="Segment id" field="integrationSegmentId" />
      }
    />
    <PanelInputWithInfo
      leftBloc={<Logo type="googleAnalytics" />}
      rightBloc={
        <TextInput
          formikProps={formikProps}
          label="Google Analytics id"
          field="integrationGoogleAnalyticsId"
        />
      }
    />
    <PanelInputWithInfo
      leftBloc={<Logo type="googleTagManager" />}
      rightBloc={
        <TextInput
          formikProps={formikProps}
          label="Google Tag Manager id"
          field="integrationGoogleTagManagerId"
        />
      }
    />
    <PanelInputWithInfo
      leftBloc={<Logo type="heap" />}
      rightBloc={
        <TextInput formikProps={formikProps} label="Heap app id" field="integrationHeapId" />
      }
    />
    <PanelInputWithInfo
      leftBloc={<Logo type="hubspot" />}
      rightBloc={
        <TextInput
          formikProps={formikProps}
          label="HubSpot portal id"
          field="integrationHubspotPortalId"
        />
      }
    />
    <PanelInputWithInfo
      leftBloc={<Logo type="intercom" />}
      rightBloc={
        <TextInput
          formikProps={formikProps}
          label="Intercom app id"
          field="integrationIntercomAppId"
        />
      }
    />
    <PanelInputWithInfo
      leftBloc={<Logo type="drift" />}
      rightBloc={
        <TextInput formikProps={formikProps} label="Drift app id" field="integrationDriftId" />
      }
    />
    <PanelInputWithInfo
      leftBloc={<Logo type="livechat" />}
      rightBloc={
        <TextInput formikProps={formikProps} label="Livechat id" field="integrationLivechatId" />
      }
    />
    <PanelInputWithInfo
      leftBloc={<Logo type="crisp" />}
      rightBloc={
        <TextInput formikProps={formikProps} label="Crisp website id" field="integrationCrispId" />
      }
    />
    <PanelInputWithInfo
      leftBloc={<Logo type="iubenda" />}
      rightBloc={
        <div>
          <TextInput
            formikProps={formikProps}
            label="Iubenda site id"
            field="integrationIubendaSiteId"
          />
          <TextInput
            formikProps={formikProps}
            label="Iubenda cookie policy id"
            field="integrationIubendaCookiePolicyId"
          />
        </div>
      }
    />
    <PanelInputWithInfo
      leftBloc={<Logo type="metomic" />}
      rightBloc={
        <TextInput
          formikProps={formikProps}
          label="Metomic project id"
          field="integrationMetomicId"
        />
      }
    />
    <PanelInputWithInfo
      leftBloc={<Logo type="onetrust" />}
      rightBloc={
        <TextInput formikProps={formikProps} label="OneTrust id" field="integrationOnetrustId" />
      }
    />
    <PanelInputWithInfo
      leftBloc={<Logo type="journyIo" />}
      rightBloc={
        <div>
          <TextInput
            formikProps={formikProps}
            label="journy.io tracker id"
            field="integrationJournyIoId"
          />
          <TextInput
            formikProps={formikProps}
            label="journy.io advanced tracking URL (optional)"
            field="integrationJournyIoDomain"
            placeholder="https://tracking.journy.io"
          />
        </div>
      }
    />
    <PanelInputWithInfo
      leftBloc={<Logo type="albacross" />}
      rightBloc={
        <TextInput formikProps={formikProps} label="Albacross id" field="integrationAlbacrossId" />
      }
    />
  </Fieldset>
)

const Tag = styled('span')`
  background: #8b63f7;
  color: white;
  padding: 5px;
  border-radius: 2px;
`

const FieldsetNote = ({ children }) => (
  <p
    style={{
      background: '#f6f8fc',
      borderRadius: '5px',
      padding: '10px',
      lineHeight: '23px',
      fontSize: '13px',
      fontWeight: '500'
    }}
  >
    {children}
  </p>
)

const TemplatePrivacyTermsLinks = ({ formikProps }) => (
  <Fieldset title="Privacy policy and terms links">
    <FieldsetNote>
      The terms and privacy policy links will replace the default Apideck terms and privacy policy
      on the listing request, partner request, and partner login form. <br />
      <br />
      <b>Attention:</b> you will still need to add separate terms and privacy policy footer items
      for compliance purposes.
    </FieldsetNote>
    <TextInput formikProps={formikProps} label="Privacy policy link" field="privacyLink" />
    <TextInput formikProps={formikProps} label="Terms link" field="termsLink" />
  </Fieldset>
)

const TemplateCustomCssFieldset = ({ formikProps, disabled }) => (
  <Fieldset title="CSS">
    {!disabled && (
      <HtmlTextInput
        label="Custom CSS"
        field="customCss"
        description={
          disabled &&
          'Custom CSS is only available on a paid plan. Please contact support to activate this feature.'
        }
        disabled={disabled}
        formikProps={formikProps}
        mode="htmlmixed"
      />
    )}
    <p>Alternatively you can link an external CSS link. This will be loaded last.</p>
    <TextInput
      formikProps={formikProps}
      label="Link to custom CSS link"
      field="customCssLink"
      description={
        disabled &&
        'Custom CSS is only available on a paid plan. Please contact support to activate this feature.'
      }
      disabled={disabled}
    />
  </Fieldset>
)

const TemplateCustomJavaScriptFieldset = ({ formikProps, disabled }) => (
  <Fieldset title="Custom JavaScript">
    <TextInput
      formikProps={formikProps}
      label="Link to custom JavaScript file"
      field="customJavaScriptLink"
      description={
        disabled &&
        'Custom JS is only available on a paid plan. Please contact support to activate this feature.'
      }
      disabled={disabled}
    />
  </Fieldset>
)

const TemplateCustomHtmlFieldsetTitle = () => (
  <React.Fragment>
    Custom HTML <FeaturePill label="ENTERPRISE" href="https://www.apideck.com/pricing/ecosystem" />
  </React.Fragment>
)

const TemplateCustomHtmlFieldset = ({ formikProps, disabled }) => (
  <Fieldset title={<TemplateCustomHtmlFieldsetTitle />}>
    <HtmlTextInput
      label="Navigation"
      field="customHtmlNav"
      description={
        disabled &&
        'Custom HTML is only available on a paid plan. Please contact support to activate this feature.'
      }
      disabled={disabled}
      formikProps={formikProps}
      mode="htmlmixed"
    />
    <HtmlTextInput
      label="Footer"
      field="customHtmlFooter"
      description={
        disabled &&
        'Custom HTML is only available on a paid plan. Please contact support to activate this feature.'
      }
      disabled={disabled}
      formikProps={formikProps}
      mode="htmlmixed"
    />
  </Fieldset>
)

const DefaultIntegrationLinksFieldset = ({ formikProps }) => (
  <Fieldset title="Integration links">
    <TextInput
      formikProps={formikProps}
      label="Native Integration Link"
      field="listingNativeIntegrationLink"
      description="Default native integrations link. This will be copied to the Native Integration Link field when creating a new listing. for the 'Install' button on the listing page."
    />
    <TextInput
      formikProps={formikProps}
      label="Installation button label"
      field="listingInstallButtonLabel"
      description="Defaults to install. Override for the Install button label on the listing page."
      localized="true"
    />
  </Fieldset>
)

const CategoryPageContentTemplateFieldset = ({ formikProps, ecosystem }) => (
  <Fieldset title="Listing content template">
    <AvailableTags />
    <MarkdownTextInput
      formikProps={formikProps}
      label="Description"
      field="listingDescriptionTextTemplate"
      tagline="description"
      description="A detailed description about the integration or app."
      localized="true"
      ecosystem={ecosystem}
    />
    <MarkdownTextInput
      formikProps={formikProps}
      label="Features (H2)"
      field="listingFeaturesTextTemplate"
      description="A list of features from the integration or app."
      localized="true"
      ecosystem={ecosystem}
    />
    <MarkdownTextInput
      formikProps={formikProps}
      label="Pricing (will be hidden if left empty)"
      field="listingPricingTextTemplate"
      description="The pricing of the integration or app."
      localized="true"
      ecosystem={ecosystem}
    />
  </Fieldset>
)

const PageContentTemplateFieldset = ({ formikProps, ecosystem }) => (
  <Fieldset title="Listing content template">
    <AvailableTags />
    <TextInput
      formikProps={formikProps}
      label="Custom description title (H2)"
      field="listingDescriptionTitle"
      description="Overwrite the default description title."
      placeholder="About %listing%"
      localized="true"
      ecosystem={ecosystem}
    />
    <MarkdownTextInput
      formikProps={formikProps}
      label="Description"
      field="listingDescriptionTextTemplate"
      tagline="description"
      description="A detailed description about the integration or app."
      localized="true"
      ecosystem={ecosystem}
    />
    <TextInput
      formikProps={formikProps}
      label="Custom features title (H2)"
      field="listingFeaturesTitle"
      description="Overwrite the default features title."
      placeholder="%listing% features"
      localized="true"
      ecosystem={ecosystem}
    />
    <MarkdownTextInput
      formikProps={formikProps}
      label="Features (H2)"
      field="listingFeaturesTextTemplate"
      description="A list of features from the integration or app."
      localized="true"
    />
    <TextInput
      formikProps={formikProps}
      label="Custom pricing title (H2)"
      field="listingPricingTitle"
      description="Overwrite the default pricing title."
      placeholder="%listing% pricing"
      localized="true"
    />
    <MarkdownTextInput
      formikProps={formikProps}
      label="Pricing (will be hidden if left empty)"
      field="listingPricingTextTemplate"
      description="The pricing of the integration or app."
      localized="true"
    />
  </Fieldset>
)

const ShadowContentTemplateFieldset = ({ formikProps }) => (
  <Fieldset title="Shadow listing content">
    <p>This text will be used as a the default content for the shadow detail listings.</p>
    <PanelInputWithInfo
      leftBloc="Enable Shadow listings"
      rightBloc="Shadow listings are autogenerated listings for unsupported integrations."
      rightBlocAddon={<SwitchInput formikProps={formikProps} field="shadowPagesEnabled" />}
    />
    <AvailableTags />
    <TextInput
      formikProps={formikProps}
      label="Description"
      field="shadowPageDescription"
      tagline="description"
      description="The default description that will be added to each shadow listing."
      localized="true"
    />
    <TextInput
      formikProps={formikProps}
      label="About"
      field="about"
      description="An about description describing your solution(s)."
      localized="true"
    />
  </Fieldset>
)

const InstallationRequestFlowFieldset = ({ formikProps }) => (
  <Fieldset title="Installation request flow">
    <p>
      Configure the installation request flow below. Requests will become available in the leads
      overview.
    </p>
    <PanelInputWithInfo
      leftBloc="Enable installation request flow"
      rightBloc=""
      rightBlocAddon={
        <SwitchInput formikProps={formikProps} field="installationRequestFlowEnabled" />
      }
    />
  </Fieldset>
)

const AvailableTags = () => (
  <p>
    Available tags: <Tag>%listing%</Tag> <Tag>%ecosystem%</Tag> <Tag>%category%</Tag>{' '}
    <Tag>%listing_naming%</Tag>
  </p>
)

const IntegrationPlatformsFieldset = ({ formikProps, children, handleToggle, button }) => (
  <Fieldset title="Integration platforms" button={button}>
    {children}
    {Object.keys(integrationPlatforms).map(
      (partner, index) =>
        integrationPlatforms[partner].formId && (
          <PanelInputWithInfo
            key={index}
            leftBloc={<Logo type={integrationPlatforms[partner].id} />}
            rightBloc={
              integrationPlatforms[partner].formId ? (
                <p>{integrationPlatforms[partner].description}</p>
              ) : (
                'Coming soon'
              )
            }
            rightBlocAddon={
              integrationPlatforms[partner].formId && (
                <SwitchInput
                  formikProps={formikProps}
                  onClick={() => handleToggle(integrationPlatforms[partner].formId)}
                  field={integrationPlatforms[partner].formId}
                />
              )
            }
          />
        )
    )}
  </Fieldset>
)

export {
  baseValidationSchema,
  templateValidationSchema,
  seoValidationSchema,
  privacyAndTermsLinkValidation,
  NameField,
  SlugField,
  WebsiteField,
  CustomDomainField,
  LogoField,
  FaviconField,
  IconField,
  PrimaryColorField,
  CardColumnsField,
  CardStyleField,
  TemplateCtaFieldset,
  DetailPageFieldset,
  SocialImageField,
  CardFieldset,
  IntegrationsFieldset,
  IntegrationPlatformsFieldset,
  SeoFieldset,
  PageContentTemplateFieldset,
  CategoryPageContentTemplateFieldset,
  InstallationRequestFlowFieldset,
  IconFieldset,
  TemplateNavigationFieldset,
  TemplateColorsFieldset,
  ShadowContentTemplateFieldset,
  MenuPositionField,
  TemplateOverviewSidebarFieldset,
  TemplateFooterFieldset,
  TemplatePrivacyTermsLinks,
  TemplateCustomCssFieldset,
  TemplateCustomHtmlFieldset,
  TemplateCustomJavaScriptFieldset,
  MastheadFieldset,
  HomePageFieldset,
  VotingPortalFieldset,
  LanguageField,
  DefaultIntegrationLinksFieldset
}

export default {
  baseValidationSchema,
  templateValidationSchema,
  seoValidationSchema,
  NameField,
  SlugField,
  WebsiteField,
  CustomDomainField,
  LogoField,
  FaviconField,
  IconField,
  PrimaryColorField,
  CardColumnsField,
  CardStyleField,
  DetailPageFieldset,
  SocialImageField,
  CardFieldset,
  IntegrationsFieldset,
  IntegrationPlatformsFieldset,
  SeoFieldset,
  TemplateColorsFieldset,
  ShadowContentTemplateFieldset,
  MenuPositionField
}
