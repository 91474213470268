import React from 'react'
import PropTypes from 'prop-types'
import { ContentContainer } from 'components'
import PrivateRoute from 'components/PrivateRoute'
import Header from './Header'
import { AllPartnersPage } from './all'
import { RequestedPartnersOverviewPage } from './requested'
import { PartnerInvitationsPage } from './invitations'
import { Redirect, Switch, withRouter } from 'react-router-dom'

function PartnersPage({ activeAccount, ecosystem, match }) {
  return (
    <ContentContainer header={<Header ecosystem={ecosystem} />}>
      <Switch>
        <Redirect exact from={`${match.path}/`} to={`${match.path}/all`} />
        <PrivateRoute
          exact
          path={`${match.path}/all`}
          component={AllPartnersPage}
          activeAccount={activeAccount}
          ecosystem={ecosystem}
        />
        <PrivateRoute
          exact
          path={`${match.path}/requested`}
          component={RequestedPartnersOverviewPage}
          activeAccount={activeAccount}
          ecosystem={ecosystem}
        />
        <PrivateRoute
          exact
          path={`${match.path}/invitations`}
          component={PartnerInvitationsPage}
          activeAccount={activeAccount}
          ecosystem={ecosystem}
        />
      </Switch>
    </ContentContainer>
  )
}

PartnersPage.propTypes = {
  activeAccount: PropTypes.object.isRequired,
  ecosystem: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired
}

export default withRouter(PartnersPage)
