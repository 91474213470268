import { EmptyState, Loader, PrimaryButton } from 'components'

import Box from '@material-ui/core/Box'
import NetworkGrid from './Grid'
import PropTypes from 'prop-types'
import React from 'react'
import { useHistory } from 'react-router-dom'

const NetworkContent = ({
  handleServiceClick,
  hasMore,
  loading,
  loadNextPage,
  searching,
  services,
  ecosystem,
  total
}) => {
  const history = useHistory()

  if (searching || (services.length === 0 && loading)) {
    return <Loader />
  }

  if (services.length === 0 && !loading && !searching) {
    return (
      <EmptyState>
        <Box marginY={4}>
          <div style={{ marginBottom: '40px' }}>
            {`We couldn't find any listings to import through the Apideck Network.`}
          </div>
          <PrimaryButton
            onClick={() => history.push(`/ecosystems/${ecosystem.id}/listings/create`)}
          >
            Create a custom listing
          </PrimaryButton>
        </Box>
      </EmptyState>
    )
  }

  return (
    <NetworkGrid
      isNextPageLoading={loading}
      handleServiceClick={handleServiceClick}
      hasNextPage={hasMore}
      loadNextPage={loadNextPage}
      services={services}
      ecosystem={ecosystem}
      total={total}
    />
  )
}

NetworkContent.propTypes = {
  handleServiceClick: PropTypes.func.isRequired,
  hasMore: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  loadNextPage: PropTypes.func.isRequired,
  searching: PropTypes.bool.isRequired,
  services: PropTypes.array,
  ecosystem: PropTypes.object,
  total: PropTypes.number.isRequired
}

export default NetworkContent
