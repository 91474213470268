import React from 'react'
import { graphql } from 'react-apollo'
import { compose, withHandlers } from 'recompose'
import { updateUserMutation } from 'api/queries'
import { UserSettings } from 'components'
import LoadingPlaceholder from 'components/placeholder/Loading'
import { toastr } from 'react-redux-toastr'

class SettingsContainer extends React.Component {
  render() {
    if (this.props.user) {
      return (
        <UserSettings
          handleSubmit={this.props.handleSubmit}
          initialValues={{
            name: this.props.user.name,
            email: this.props.user.email,
            emailSubscription: this.props.user.emailSubscription
          }}
        />
      )
    } else {
      return <LoadingPlaceholder />
    }
  }
}

export default compose(
  graphql(updateUserMutation, { name: 'updateUser' }),
  withHandlers({
    handleSubmit:
      ({ updateUser }) =>
      async ({ name, email, emailSubscription }) => {
        const variables = {
          name,
          email,
          emailSubscription
        }

        try {
          await updateUser({ variables })
          toastr.success('User updated')
        } catch (error) {
          console.error(error)
          toastr.error("Couldn't update user")
        }
      }
  })
)(SettingsContainer)
