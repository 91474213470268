import React, { Fragment } from 'react'

import Box from '@material-ui/core/Box'
import CloseIcon from '@material-ui/icons/Close'
import Divider from '@material-ui/core/Divider'
import Drawer from '@material-ui/core/Drawer'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import { compose } from 'recompose'
import timeAgo from 'time-ago'
import { withStyles } from '@material-ui/core/styles'

const styles = (theme) => ({
  list: {
    width: '360px'
  },
  title: {
    fontSize: '18px',
    fontWeight: '500'
  },
  subtitle: {
    fontSize: '12px',
    opacity: 0.6
  },
  label: {
    fontWeight: '500',
    padding: '3px 0',
    fontSize: '12px',
    opacity: 0.8
  },
  value: {
    fontSize: '14px'
  },
  avatar: {
    width: theme.spacing(4),
    height: theme.spacing(4)
  }
})

const LeadDrawer = ({ drawerOpen, toggleDrawer, classes, activeLead }) => (
  <Drawer
    anchor="right"
    open={drawerOpen}
    onClose={() => toggleDrawer(activeLead)}
    BackdropProps={{ invisible: true }}
  >
    {activeLead && (
      <Fragment>
        <Box m={2}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography className={classes.title}>Lead</Typography>
              <Typography className={classes.subtitle}>CTA Form</Typography>
            </Grid>
            <Grid item>
              <Tooltip title="Or press esc">
                <IconButton
                  size="medium"
                  aria-label="close"
                  onClick={() => toggleDrawer(activeLead)}
                >
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Box>
        <Divider />
        <List className={classes.list}>
          <ListItem>
            <ListItemText
              primary={<Typography className={classes.label}>Name</Typography>}
              secondary={<Typography className={classes.value}>{activeLead.name}</Typography>}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary={<Typography className={classes.label}>Email</Typography>}
              secondary={
                <Typography className={classes.value}>
                  <a href={`mailto:${activeLead.email}`}>{activeLead.email}</a>
                </Typography>
              }
            />
          </ListItem>
          {activeLead.role && (
            <ListItem>
              <ListItemText
                primary={<Typography className={classes.label}>Role</Typography>}
                secondary={<Typography className={classes.value}>{activeLead.role}</Typography>}
              />
            </ListItem>
          )}
          <ListItem>
            <ListItemText
              primary={<Typography className={classes.label}>Company</Typography>}
              secondary={
                <Typography className={classes.value}>{activeLead.companyName}</Typography>
              }
            />
          </ListItem>
          {activeLead.telephone && (
            <ListItem>
              <ListItemText
                primary={<Typography className={classes.label}>Telephone</Typography>}
                secondary={
                  <Typography className={classes.value}>{activeLead.telephone}</Typography>
                }
              />
            </ListItem>
          )}
          <ListItem>
            <ListItemText
              primary={<Typography className={classes.label}>Created</Typography>}
              secondary={
                <Typography className={classes.value}>
                  {timeAgo.ago(activeLead.createdAt)}
                </Typography>
              }
            />
          </ListItem>
        </List>
      </Fragment>
    )}
  </Drawer>
)

export default compose(withStyles(styles))(LeadDrawer)
