import React from 'react'
import { BounceLoader } from 'react-spinners'
import Button from '@material-ui/core/Button'

const Submit = ({ isSubmitting, text = 'Save', style, ...rest }) => (
  <Button
    variant="contained"
    color="primary"
    type="submit"
    size="large"
    disabled={isSubmitting}
    style={{ ...style }}
    {...rest}
  >
    {isSubmitting ? <BounceLoader sizeUnit={'px'} size={24} color={'#FFF'} /> : text}
  </Button>
)

export default Submit
