import Chip from '@material-ui/core/Chip'
import Grid from '@material-ui/core/Grid'
import {
  Card,
  ContentContainer,
  ExternalA,
  GridContainer,
  HeaderTitle,
  ErrorFallback
} from 'components'
import gql from 'graphql-tag'
import PropTypes from 'prop-types'
import React from 'react'
import { useQuery } from 'react-apollo'
import styled from 'react-emotion'
import { withRouter } from 'react-router-dom'

const StyledSection = styled('section')`
  marginbottom: 30px;

  a {
    color: #7f8fa4;
  }
  a:hover {
    text-decoration: underline;
  }
`

const ServiceHeader = styled('div')`
  border-bottom: 1px solid #f1f1f1;
  padding-bottom: 16px;
`

const Pill = styled('span')`
  background: #edf3f9;
  color: #5e7994;
  font-size: 12px;
  padding: 4px 6px;
  border-radius: 2px;
  margin-right: 4px;
`

const Section = ({ title, children }) => (
  <StyledSection>
    <h4>{title}</h4>
    {children}
  </StyledSection>
)

const UlLinks = styled('ul')`
  padding-left: 0;
  list-style-type: none;
  margin-left: 0;

  li {
    padding: 3px 0;
    color: #ccc;
  }

  li a {
    padding: 10px 0;
    font-weight: bold;
    color: #7f8fa4;
  }

  li a:hover {
    text-decoration: underline;
  }
`

const UlLink = ({ href, label }) => (
  <li>{href ? <ExternalA href={href}>{label}</ExternalA> : label}</li>
)

const GET_REQUESTED_PARTNER = gql`
  query partnerRequest($accountId: ID!, $ecosystemId: ID!, $id: ID!) {
    partnerRequest(accountId: $accountId, ecosystemId: $ecosystemId, id: $id) {
      id
      listingName
      listingWebsite
      listingDescription
      companyName
      companyWebsite
      contactEmail
      contactFirstName
      contactLastName
      listingMultiCategory {
        name
      }
      listingIcon {
        url
      }
      listingPrivacyUrl
      listingTermsUrl
      status
    }
  }
`

function RequestedPartnersDetailPage({ activeAccount, ecosystem, match }) {
  const { loading, error, data } = useQuery(GET_REQUESTED_PARTNER, {
    variables: {
      accountId: activeAccount.id,
      ecosystemId: ecosystem.id,
      id: match.params.partnerId
    }
  })

  if (loading) return <GridContainer />
  if (error) return <ErrorFallback error={error} />

  const { partnerRequest } = data

  return (
    <ContentContainer>
      <Card style={{ minHeight: '500px', padding: '0 30px 20px' }}>
        <ServiceHeader>
          <HeaderTitle
            imageTitle={partnerRequest.listingName}
            imageSrc={partnerRequest.listingIcon ? partnerRequest.listingIcon.url : ''}
            title={partnerRequest.listingName}
            subtitle={
              <Pill>
                {partnerRequest.listingMultiCategory
                  ? partnerRequest.listingMultiCategory.name
                  : 'Other'}
              </Pill>
            }
          />
        </ServiceHeader>
        <Grid container spacing={2}>
          <Grid item xs={9}>
            <Section title="Description">
              <p>{partnerRequest.listingDescription}</p>
            </Section>
            <Section title="Company">
              <p>{partnerRequest.companyName}</p>
            </Section>
            <Section title="Contact">
              <p>{partnerRequest.contactEmail}</p>
              <p>
                {partnerRequest.contactLastName} {partnerRequest.contactFirstName}
              </p>
            </Section>
          </Grid>
          <Grid item xs={3}>
            <Section title="Links">
              <UlLinks>
                <UlLink href={partnerRequest.companyWebsite} label="Company" />
                <UlLink href={partnerRequest.listingTermsUrl} label="Terms" />
                <UlLink href={partnerRequest.listingPrivacyUrl} label="Privacy policy" />
              </UlLinks>
            </Section>
            <Section title="Status">
              <Chip label={partnerRequest.status.toLowerCase()} />
            </Section>
          </Grid>
        </Grid>
      </Card>
    </ContentContainer>
  )
}

RequestedPartnersDetailPage.propTypes = {
  activeAccount: PropTypes.object.isRequired,
  ecosystem: PropTypes.object.isRequired
}

export default withRouter(RequestedPartnersDetailPage)
