import { collectionsPreviewQuery } from 'api'
import {
  ErrorFallback,
  LoadingPlaceholder,
  MarketplaceCollections as Collections
} from 'components'
import React from 'react'
import { useQuery } from 'react-apollo'

// TODO move to pages folder

const CollectionsContainer = ({ ecosystem }) => {
  const { loading, error, data } = useQuery(collectionsPreviewQuery, {
    variables: {
      ecosystemId: ecosystem.id,
      listingLimit: 3,
      limit: 1000 // Use absurd limit until pagination added
    }
  })

  if (loading) {
    return <LoadingPlaceholder />
  }

  if (error) {
    return <ErrorFallback error={error} />
  }

  const collections = data?.collectionsPreview

  return <Collections collections={collections} ecosystem={ecosystem} />
}

export default CollectionsContainer
