import { Controlled as CodeMirror } from 'react-codemirror2'
import React from 'react'

const Editor = ({ value = '', mode, onBeforeChange }) => (
  <React.Fragment>
    <CodeMirror
      value={value}
      options={{ mode, lineWrapping: true }}
      onBeforeChange={onBeforeChange}
    />
  </React.Fragment>
)

export default Editor
