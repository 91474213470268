import gql from 'graphql-tag'

export const currentUserQuery = gql`
  query currentUserQuery {
    currentUser {
      id
      auth0UserId
      imageUrl
      name
      email
      emailSubscription
      accounts {
        id
        role
        notificationPreferences {
          weeklyDigest
          listingChanges
        }
        account {
          id
          name
          website
          plan
          subscriptionStatus
          endOfTrial
          trialExtensions
          marketplaces {
            id
            name
            slug
            customDomain
            unifyApplicationId
            icon {
              id
              url
            }
            favicon {
              id
              url
            }
            categories {
              id
            }
            collections {
              id
            }
          }
        }
      }
    }
  }
`
