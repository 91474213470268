import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import React from 'react'
import { Link } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: theme.spacing(0.25, 0.4),
    fontWeight: 500
  },
  article: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    boxSizing: 'border-box'
  },
  aside: {
    flex: '0 0 75vw',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '2em',
    boxSizing: 'border-box'
  },
  huge: {
    marginTop: '-2em',
    fontSize: '10em',
    letterSpacing: '-0.05em',
    lineHeight: '0.25em'
  },
  error: {
    padding: theme.spacing(1, 2),
    backgroundColor: '#FAEBEB',
    color: '#E58181',
    borderRadius: '4px'
  },
  title: {
    textTransform: 'uppercase',
    letterSpacing: '-0.05em',
    lineHeight: '0.5em'
  },
  pre: {
    padding: '0',
    height: '600px',
    overflow: 'scroll'
  },
  login: {
    marginTop: '1em',
    textAlign: 'center'
  }
}))

const ErrorFallback = ({ error }) => {
  const classes = useStyles()
  return (
    <article role="alert" className={classNames(classes.article)}>
      <aside className={classes.aside}>
        <h1 className={classes.huge}>Oops!</h1>
        <h2 className={classes.title}>Looks like something went wrong.</h2>
        <p>We have been notified and are looking into it.</p>
        {process.env.REACT_APP_ENV !== 'production' && (
          <div>
            <code>{error?.message}</code>
            <pre className={classes.pre}>
              {JSON.stringify(error, null, 2).replace(/\\n/g, '\n')}
            </pre>
          </div>
        )}

        <p className={classes.login}>
          <Link to="/login">Please login and try again.</Link>
        </p>
      </aside>
    </article>
  )
}

export default ErrorFallback
