import gql from 'graphql-tag'

const translationFieldsFragment = gql`
  fragment translationFields on Translation {
    id
    key
    value
    locale
  }
`

export default translationFieldsFragment
