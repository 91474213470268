import { Login, PanelLayout } from 'components'
import React, { useCallback, useEffect, useState } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { getItem, setItem } from 'utils/localstorage'

import { authenticateMutation } from 'api/queries'
import { useMutation } from 'react-apollo'
import { Helmet } from 'react-helmet'
import { useIntercom } from 'react-use-intercom'
import { auth } from 'routes/App'
import { handleUserFormError } from 'utils/ErrorHandling'
import { getEcosystemUrl, getUnifyUrl } from 'utils/getUnifyUrl'

const Page = ({ history }) => {
  const { shutdown: shutdownIntercom } = useIntercom()
  const [authenticate] = useMutation(authenticateMutation)
  const { USERNAME_PASSWORD, GOOGLE, GITHUB } = auth.connections()

  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [authenticatedUser, setAuthenticatedUser] = useState(null)
  const [error, setError] = useState(null)

  const authenticateToken = useCallback(
    async (authResult) => {
      const loginFailed = (authError) => {
        auth.clearSession()
        setError(authError)
      }

      try {
        const {
          error,
          data: { authenticate: user }
        } = await authenticate({
          variables: {
            idToken: authResult?.idToken
          }
        })

        if (error) {
          loginFailed(error)
        }

        if (user) {
          setAuthenticatedUser(user)
        }

        if (!error && !user) {
          loginFailed({
            message: 'User not found'
          })
        }
      } catch (err) {
        loginFailed(err)
      }
    },
    [authenticate]
  )

  useEffect(() => {
    const hasRedirectToken = window.location.hash.includes('id_token')

    const handleAuth0 = async (hash) => {
      // parsing hash verifies token is valid
      const authResult = await auth.parseHash(hash)
      auth.setSession(authResult)

      await authenticateToken(authResult)
    }

    if (hasRedirectToken) {
      shutdownIntercom()
      setIsAuthenticated(true)
      const hash = window.location.hash.slice(1)
      handleAuth0(hash)
    }
  }, [authenticateToken, shutdownIntercom])

  useEffect(() => {
    if (authenticatedUser) {
      if (getItem('lastProductUsed') === 'unify') {
        window.location.replace(getUnifyUrl())
        return
      }
      if (getItem('lastProductUsed') === 'ecosystem') {
        window.location.replace(getEcosystemUrl())
        return
      }

      if (authenticatedUser?.accounts?.length > 1) {
        // user has multiple accounts, redirect to account switcher
        history.push('/accounts/portal')
      } else {
        // user hopefully has 1 account, set that account as active
        // redirect to listings overview
        const activeAccount = authenticatedUser?.accounts?.[0]?.account
        activeAccount?.id && setItem('activeAccountId', activeAccount.id)

        history.push('/')
      }
    }
  }, [history, authenticatedUser])

  const onUsernamePasswordSubmit = async ({ email, password }, { setSubmitting, setErrors }) => {
    if (!email || !password) {
      handleUserFormError(setErrors, 'Please enter a valid email and password')
      return
    }

    setSubmitting(true)
    try {
      await auth.login(USERNAME_PASSWORD, {
        email,
        password
      })
    } catch (err) {
      handleUserFormError(setErrors, err)
      setSubmitting(false)
    }
  }

  // Start Google OAuth flow
  const onGoogleSubmit = async () => {
    await auth.login(GOOGLE)
  }

  // Start GitHub OAuth flow
  const onGithubSubmit = async () => {
    await auth.login(GITHUB)
  }

  if (error) {
    return (
      <PanelLayout>
        <h3>{`Can't find any user with the provided info.`}</h3>
        <p>
          {`Don't have an account yet? `}
          <Link to="signup">Sign up</Link>
        </p>
      </PanelLayout>
    )
  }

  if (isAuthenticated) {
    return (
      <PanelLayout>
        <h3>Logging in...</h3>
      </PanelLayout>
    )
  }

  return (
    <PanelLayout>
      <Helmet>
        <title>Login - Apideck</title>
        <link rel="canonical" href="https://app.apideck.com/login" />
      </Helmet>

      <Login
        handleUsernamePasswordSubmit={onUsernamePasswordSubmit}
        handleGoogleSubmit={onGoogleSubmit}
        handleGithubSubmit={onGithubSubmit}
        initialValues={{
          email: '',
          password: ''
        }}
      />

      <Link to="/forgot-password">{`Don't remember your password?`}</Link>
    </PanelLayout>
  )
}

export default withRouter(Page)
