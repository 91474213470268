import React from 'react'
import PropTypes from 'prop-types'
import CustomDomain from './forms/CustomDomain'

const Overview = ({ handleSubmit, initialValues, disabled }) => (
  <CustomDomain handleSubmit={handleSubmit} initialValues={initialValues} disabled={disabled} />
)

Overview.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired
}

export default Overview
