import { Tab, TabChip, Tabs } from 'components'

import { Link } from 'react-router-dom'
import React from 'react'

const items = [
  {
    label: 'General',
    path: ''
  },
  {
    label: 'Template',
    path: '/template'
  },
  {
    label: 'Listings',
    path: '/listings'
  },
  {
    label: 'Leads',
    path: '/leads'
  },
  {
    label: 'SEO & Meta Tags',
    path: '/seo'
  },
  {
    label: 'Integrations',
    path: '/integrations'
  },
  {
    label: 'Custom Domain',
    path: '/custom-domain'
  }
]

const Navigation = ({ match, activeAccount }) => {
  let navigationItems = items

  const flowIsEnabled = false // TODO: Enable when ready

  if (activeAccount?.plan === 'ENTERPRISE' && flowIsEnabled) {
    navigationItems = [...items, { label: 'Partners', path: '/partners' }]
  }

  return (
    <Tabs
      selectors={navigationItems.map((n, index) => ({
        tab: index,
        path: `${match.url}${n.path}`,
        exact: true
      }))}
    >
      {navigationItems.map((item, index) => {
        const label =
          item.label === 'Leads' ? (
            <span>
              {item.label + ' '}
              <TabChip label="new" />
            </span>
          ) : (
            item.label
          )

        return (
          <Tab
            data-testid={`ecosystem-settings-tab-${item.path.replace('/', '')}`}
            label={label}
            component={Link}
            key={index}
            to={`${match.url}${item.path}`}
          />
        )
      })}
    </Tabs>
  )
}

export default Navigation
