import { Submit } from 'components'
import { Formik } from 'formik'
import PropTypes from 'prop-types'
import React from 'react'
import { SeoFieldset } from '../../forms/Base'

export const SeoForm = ({ initialValues, handleSubmit }) => (
  <Formik initialValues={initialValues} onSubmit={handleSubmit}>
    {(formikProps) => {
      const { handleSubmit, isSubmitting, errors } = formikProps
      return (
        <form onSubmit={handleSubmit} data-testid="ecosystem-settings-seo-form">
          {errors.general && <span className="errors">{errors.general}</span>}
          <SeoFieldset formikProps={formikProps} />
          <div className="sticky-submit-container">
            <Submit isSubmitting={isSubmitting} text="Save settings" />
          </div>
        </form>
      )
    }}
  </Formik>
)

SeoForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object
}

export default SeoForm
