import Footer from 'components/layout/Footer'
import Sidebar from 'components/layout/Sidebar'
import Toastr from 'components/layout/Toastr'
import React, { Fragment, useEffect, useState } from 'react'
import styled from 'react-emotion'
import { Route } from 'react-router-dom'
import { getDaysLeft } from 'utils/trial'
import EndOfTrialModal from './modals/EndOfTrialModal'

const Wrapper = styled('div')`
  display: flex;
  height: 100%;

  main {
    width: 100%;
    overflow: auto;
  }

  .container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    min-width: 744px; // 1024px (table landscape = minimum supported) - 280px (sidebar width)
  }

  .content {
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
  }
`

const renderMergedProps = (WrappedComponent, user, activeAccount, ...rest) => {
  const restProps = Object.assign({}, ...rest)
  const finalProps = {
    user,
    activeAccount,
    ...restProps
  }
  return <WrappedComponent {...finalProps} />
}

const PrivateRouteWithSidebarAndFooter = ({
  component,
  user,
  activeAccount,
  location,
  path,
  ...rest
}) => {
  const [trialModalIsOpen, setTrialModalOpen] = useState(false)
  const { endOfTrial, trialExtensions, subscriptionStatus } = activeAccount

  // check if trial is expired if so, show end-of-trial modal
  useEffect(() => {
    if (subscriptionStatus === 'TRIAL' && path.startsWith('/ecosystems')) {
      const daysLeft = getDaysLeft(endOfTrial, trialExtensions)
      if (daysLeft === 0) {
        setTimeout(() => {
          setTrialModalOpen(true)
        }, 1000)
      }
    }
  }, [endOfTrial, trialExtensions, subscriptionStatus, location])

  return (
    <Route
      {...rest}
      render={(routeProps) => {
        // if (!auth.isAuthenticated()) {
        //   auth.logout('Token expired')

        //   return null
        // }

        return (
          <Fragment>
            <EndOfTrialModal
              isOpen={trialModalIsOpen}
              trialExtensions={trialExtensions}
              closeModal={() => setTrialModalOpen(false)}
            />
            <Wrapper>
              <Sidebar user={user} activeAccount={activeAccount} {...routeProps} />
              <main id="scroll-element">
                <div className="container">
                  <div className="content">
                    <Toastr />
                    {renderMergedProps(component, user, activeAccount, routeProps, rest)}
                  </div>
                  <Footer />
                </div>
              </main>
            </Wrapper>
          </Fragment>
        )
      }}
    />
  )
}

export default PrivateRouteWithSidebarAndFooter
