import { ContentWrapper } from 'components'
import PropTypes from 'prop-types'
import React from 'react'
import { ProductCreateForm } from './forms/Create'

const Create = ({ handleSubmit, initialValues, ecosystem }) => (
  <ContentWrapper title="Create product">
    <ProductCreateForm
      initialValues={initialValues}
      handleSubmit={handleSubmit}
      ecosystem={ecosystem}
    />
  </ContentWrapper>
)

Create.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired
}

export default Create
