import { PrivacyLink, TermsLink } from 'components'

import React from 'react'
import hubspot from 'assets/hubspot.svg.png'
import pipedrive from 'assets/pipedrive.svg'
import styled from 'react-emotion'

const Panel = ({ children, showFooter = false }) => (
  <div>
    <FlexWrapper>
      <div className="formContainer">
        <div style={{ width: '800px', maxWidth: '800px', margin: 'auto' }}>
          <div className="logo">
            <a href="https://apideck.com/">
              <img src="/images/logo-white.svg" alt="Apideck" />
            </a>
          </div>
          <div style={{ marginBottom: '50px' }}>{children}</div>
          {showFooter && (
            <Footer>
              <p>
                <TermsLink /> | &nbsp; <PrivacyLink />
              </p>
            </Footer>
          )}
        </div>
      </div>
      <div className="infoContainer">
        <div className="content">
          <h1>
            Launch integrations{' '}
            <span role="img" aria-label="100">
              💯{' '}
            </span>
            times faster
          </h1>
          <h3>
            Easy to set up. Launch new integrations in less than 5 minutes{' '}
            <a href="https://apideck.com/products/unify" target="_blank" rel="noreferrer">
              with Unify
            </a>
            .
          </h3>
          <img src="/images/get-started.png" alt="" className="preview" width="700px" />
          <div className="integrations">
            <p>Supporting the integrations your customers need</p>
            <div className="connector-images">
              <img src="https://www.apideck.com/logos/intercom.svg" alt="Intercom connector" />
              <img width={100} src={hubspot} alt="Hubspot connector" />
              <img width={100} src={pipedrive} alt="Pipedrive connector" />
              <img src="https://www.apideck.com/logos/drift.svg" alt="Drift connector" />
              <img src="https://www.apideck.com/logos/stripe.svg" alt="Stripe connector" />
            </div>
          </div>
        </div>
      </div>
    </FlexWrapper>
  </div>
)

const Footer = styled('div')`
  height: 30px;
`

const FlexWrapper = styled('div')`
  display: flex;
  height: 100vh;
  margin-bottom: -80px;
  background-color: #FFF;

  @media(max-width: 768px) {
    display: block;
  }

  button {
    display: block;
    width: 100%;
  }

  h1 {
    color: #2a2f73;
    font-size: 38px;
  }

  h3 {
    font-weight: normal;
    color: #2a2f73;
    font-size: 16px;
    margin-bottom: 50px;
  }

  p, h2, label {
    color: #FFF;
  }

  input {
    height: 50px;
  }

  .logo {
    padding-bottom: 30px;
    border-bottom: 1px solid var(--sidebar-divider-color);
    margin-bottom: 50px;

    img {
      width: 120px;
    }
  }

  img.preview {
    border-radius: 8px;
    box-shadow: rgba(63, 49, 178, 0.1) 0px 2px 4px,
    rgba(63, 49, 178, 0.03) 0px 4px 14px;
  }

  .formContainer {
    width: 40%;
    padding: 25px 100px;
    color: #FFF;
    overflow: auto;
    background: var(--sidebar-background-color);
    box-shadow: rgba(63, 49, 178, 0.1) 0px 2px 4px,
    rgba(63, 49, 178, 0.03) 0px 4px 14px;
    display: flex;
    align-items: center;

    .logo {
      width: 140px;
    }

    a {
      color: var(--color-lighter);
    }

    h2 {
      font-size: 18px;
    }

    h3 {
      color: #FFF;
    }

    @media(max-width: 768px) {
      width: 100%;
      padding: 40px 20px;
    }
  }

  .infoContainer {
    width: 60%;
    background: linear-gradient(309deg, #cddbf0 0%, #f9fdff 100%);
    background-color: black;
    color: #FFF;
    text-align: center;
    padding: 60px 40px;
    display: flex;
    align-items: center;

    .content {
      max-width: 840px;
      margin: 0 auto;
    }

    @media(max-width: 768px) {
      display: none;
    }

    .btn {
      border: 1px solid #828282;
      border-radius: 3px;
      padding: 15px 20px;
      color: #828282;
      text-decoration: none;
    }

    .btn:hover {
      border-color: #FFF;
      color: #FFF;
      transition: 0.5s;
    }
  }

  .integrations {
    margin-top: 80px;

    p {
      color:#7E8695;text-align:center;font-size:13px;text-transform:uppercase;font-weight:500
      display: block;
    }

    div {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      margin: 0 auto;
      align-items: center;

      @media (max-width: 768px)  {
        min-width: auto;
        img {
          display: block;
          margin: 0 auto;
          padding: 24px;
        }
      }
      img {
        opacity: 0.4;
        filter: grayscale(100%);
        margin-bottom: 30px;
      }
    }
  }
`

export default Panel
