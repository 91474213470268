import { useMutation } from '@apollo/react-hooks'
import { updateLeadsSettingsMutation as UPDATE_LEADS_SETTINGS } from 'api/queries'
import { NOT_UPDATED, UPDATED } from 'constants/settings'
import { useEcosystemContext } from 'context'
import React from 'react'
import { toastr } from 'react-redux-toastr'
import LeadsSettingsPage from './LeadsSettingsPage'

const LeadsSettingsContainer = ({ activeAccount }) => {
  const { ecosystem } = useEcosystemContext()
  const [updateMarketplace, { loading }] = useMutation(UPDATE_LEADS_SETTINGS)
  const {
    id,
    name,
    slug,
    leadCaptureFormEnabled,
    leadTelephoneFieldEnabled,
    leadTelephoneFieldRequired,
    leadFirstNameFieldEnabled,
    leadFirstNameFieldRequired,
    leadLastNameFieldEnabled,
    leadLastNameFieldRequired,
    leadWorkEmailValidation
  } = ecosystem
  const initialValues = {
    leadCaptureFormEnabled,
    leadTelephoneFieldEnabled,
    leadTelephoneFieldRequired,
    leadFirstNameFieldEnabled,
    leadFirstNameFieldRequired,
    leadLastNameFieldEnabled,
    leadLastNameFieldRequired,
    leadWorkEmailValidation
  }
  const handleSubmit = async (values) => {
    const variables = {
      id,
      name,
      slug,
      accountId: activeAccount.id,
      ...values
    }
    try {
      const { data } = await updateMarketplace({ variables })

      if (data) {
        toastr.success(UPDATED)
      }
    } catch (e) {
      toastr.error(NOT_UPDATED)
    }
  }

  return (
    <LeadsSettingsPage
      ecosystem={ecosystem}
      initialValues={initialValues}
      handleSubmit={handleSubmit}
      loading={loading}
    />
  )
}

export default LeadsSettingsContainer
