import { AutoSizer, InfiniteLoader, List, WindowScroller } from 'react-virtualized'

import Grid from '@material-ui/core/Grid'
import { Loader } from 'components'
import PropTypes from 'prop-types'
import React from 'react'
import { Service } from './service'

const NUM_ITEMS_PER_ROW = 6

function NetworkGrid({
  handleServiceClick,
  hasNextPage,
  isNextPageLoading,
  loadNextPage,
  services,
  total
}) {
  let rowCount = Math.ceil(services.length / NUM_ITEMS_PER_ROW)

  // If there are more items to be loaded then
  // add an extra row to hold a loading indicator
  if (hasNextPage) {
    rowCount += 1
  }

  // Only load 1 page of items at a time.
  // Pass an empty callback to InfiniteLoader in case
  // it asks us to load more than once.
  const loadMoreRows = isNextPageLoading ? () => {} : loadNextPage

  // Every row is loaded except for our loading indicator row.
  const isRowLoaded = ({ index }) =>
    !hasNextPage || index < Math.ceil(services.length / NUM_ITEMS_PER_ROW)

  const rowRenderer = ({ index, key, style }) => {
    const fromIndex = index * NUM_ITEMS_PER_ROW
    const toIndex = Math.min(fromIndex + NUM_ITEMS_PER_ROW, services.length)

    if (fromIndex > services.length) {
      return (
        <div
          key={index}
          style={{
            display: 'flex',
            justifyContent: 'center',
            ...style
          }}
        >
          <Loader />
        </div>
      )
    }

    return (
      <Grid container key={key} style={style} className="servicesRow" spacing={2}>
        {services.slice(fromIndex, toIndex).map((service) => (
          <Service onClick={handleServiceClick} key={service.id} service={service} />
        ))}
      </Grid>
    )
  }

  return (
    <InfiniteLoader
      isRowLoaded={isRowLoaded}
      loadMoreRows={loadMoreRows}
      rowCount={Math.round(total / NUM_ITEMS_PER_ROW)}
    >
      {({ onRowsRendered, registerChild }) => (
        <WindowScroller scrollElement={document.querySelector('#scroll-element')}>
          {({ height, isScrolling, scrollTop, onChildScroll }) => (
            <AutoSizer disableHeight>
              {({ width }) => (
                <List
                  autoHeight
                  height={height}
                  isScrolling={isScrolling}
                  scrollTop={scrollTop}
                  onScroll={onChildScroll}
                  onRowsRendered={onRowsRendered}
                  rowRenderer={rowRenderer}
                  rowCount={rowCount}
                  rowHeight={180}
                  width={width}
                  ref={registerChild}
                  style={{ outline: 'none' }}
                />
              )}
            </AutoSizer>
          )}
        </WindowScroller>
      )}
    </InfiniteLoader>
  )
}

NetworkGrid.propTypes = {
  handleServiceClick: PropTypes.func.isRequired,
  // Are there more items to load?
  hasNextPage: PropTypes.bool.isRequired,
  // Are we currently loading a page of items?
  isNextPageLoading: PropTypes.bool.isRequired,
  // Callback function responsible for loading the next page of items
  loadNextPage: PropTypes.func.isRequired,
  // List of services loaded so far
  services: PropTypes.array.isRequired,
  // Count of total services
  total: PropTypes.number.isRequired
}

export default NetworkGrid
