import React from 'react'
import ListCard from './ListCard'

class PagesCard extends React.Component {
  render() {
    let { title, loading, data, ecosystemUrl } = this.props

    if (data) {
      data = data.map((p, i) => ({
        label: p.name,
        icon: p.icon,
        href: ecosystemUrl + p.path,
        count: p.count,
        key: i
      }))
    }

    return <ListCard title={title} size={6} height="380px" loading={loading} data={data} />
  }
}

export default PagesCard
