import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'

const IconButton = withStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    backgroundColor: theme.palette.ui[100],
    minWidth: 0,
    '&:hover': {
      backgroundColor: theme.palette.ui[200]
    }
  },
  label: {
    padding: 0,
    color: theme.palette.ui[300]
  }
}))(Button)

const IconButtonWrapper = ({ onClick, children }) => {
  return <IconButton onClick={onClick}>{children}</IconButton>
}

IconButtonWrapper.propTypes = {
  onClick: PropTypes.func.isRequired
}

export default IconButtonWrapper
