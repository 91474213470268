import React from 'react'
import { ContentWrapper } from 'components'
import { CollectionCreateForm } from './forms/Create'
import PropTypes from 'prop-types'

const Create = ({ handleSubmit, initialValues, ecosystem }) => (
  <ContentWrapper title="Create collection">
    <CollectionCreateForm
      initialValues={initialValues}
      handleSubmit={handleSubmit}
      ecosystem={ecosystem}
    />
  </ContentWrapper>
)

Create.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired
}

export default Create
