import Button from '@material-ui/core/Button'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { Card } from 'components'
import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'
import { tableClassName } from 'styles/styles'

export const InvitationRow = ({ invitation, handleDelete }) => (
  <TableRow data-testid="invitation-item">
    <TableCell data-testid="invitation-item-email">{invitation.accountUser.user.email}</TableCell>
    <TableCell data-testid="invitation-item-role">{invitation.accountUser.role}</TableCell>
    <TableCell>
      <span onClick={() => handleDelete({ id: invitation.id })}>Delete</span>
    </TableCell>
  </TableRow>
)

export const InvitationsTable = ({ invitations, handleDelete }) => (
  <Table className={tableClassName} cellSpacing="0">
    <TableHead>
      <TableRow>
        <TableCell>Email</TableCell>
        <TableCell>Role</TableCell>
        <TableCell>Actions</TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {invitations.map((invitation) => (
        <InvitationRow invitation={invitation} key={invitation.id} handleDelete={handleDelete} />
      ))}
    </TableBody>
  </Table>
)

export const Invitations = ({ invitations, handleDelete }) => (
  <div data-testid="account-invitations-view">
    <InvitationCreateButton />
    <Card>
      <InvitationsTable invitations={invitations} handleDelete={handleDelete} />
    </Card>
  </div>
)

export const InvitationCreateButton = () => (
  <Link to={`/account/invitations/create`} style={{ textDecoration: 'none' }}>
    <Button variant="contained" color="primary" style={{ marginBottom: '16px' }}>
      + Create Invitation
    </Button>
  </Link>
)

Invitations.propTypes = {
  invitations: PropTypes.array.isRequired,
  handleDelete: PropTypes.func.isRequired
}

export default Invitations
