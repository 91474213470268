import { Card, Loader } from 'components'
import {
  accountUsersQuery,
  removeUserFromAccountMutation,
  updateNotificationPreferencesMutation
} from './graphql'
import { compose, withHandlers } from 'recompose'

import Button from '@material-ui/core/Button'
import ErrorPlaceholder from 'components/placeholder/Error'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import React from 'react'
import { Users } from './Users'
import displayForError from 'components/common/HOC/Error'
import displayWhileLoading from 'components/common/HOC/Loading'
import { graphql } from 'react-apollo'
import { toastr } from 'react-redux-toastr'

const UsersContainer = ({ data, handleUserDelete, handleUserUpdate, user, activeAccount }) => {
  return (
    <Card
      title="Users"
      button={
        <Link to={`/account/invitations/create`}>
          <Button variant="contained" className="fr" color="primary">
            + Create Invitation
          </Button>
        </Link>
      }
    >
      <Users
        user={user}
        accountUsers={data.accountUsers}
        onDelete={handleUserDelete}
        onUpdate={handleUserUpdate}
        activeAccount={activeAccount}
      />
    </Card>
  )
}

const LoadingPlaceholder = () => (
  <div
    className="flex w-100 h-100 items-center justify-center"
    style={{ height: '200px', textAlign: 'center' }}
  >
    <Loader />
  </div>
)

const handlers = withHandlers({
  handleUserDelete:
    ({ deleteUser, activeAccount }) =>
    async ({ accountUserId }) => {
      try {
        const variables = {
          accountId: activeAccount.id,
          accountUserId
        }

        await deleteUser({
          variables,
          update: (store) => {
            const updateVariables = {
              accountId: activeAccount.id
            }
            const { accountUsers } = store.readQuery({
              query: accountUsersQuery,
              variables: updateVariables
            })
            store.writeQuery({
              query: accountUsersQuery,
              data: {
                accountUsers: accountUsers.filter((accountUser) => accountUser.id !== accountUserId)
              },
              variables: updateVariables
            })
          }
        })
        toastr.success('User successfully deleted')
      } catch (error) {
        console.log('Error: ' + error)
        toastr.error('Could not delete User')
      }
    },
  handleUserUpdate:
    ({ updateUser, activeAccount }) =>
    async ({ accountUserId, notificationPreferences }) => {
      try {
        const variables = {
          accountId: activeAccount.id,
          accountUserId,
          notificationPreferences
        }

        await updateUser({
          variables,
          update: (store) => {
            const updateVariables = {
              accountId: activeAccount.id
            }

            const { accountUsers } = store.readQuery({
              query: accountUsersQuery,
              variables: updateVariables
            })

            const updatedUsers = accountUsers.map((accountUser) => {
              const user = { ...accountUser }
              if (user.id === accountUserId) {
                user.notificationPreferences = notificationPreferences
              }
              user.notificationPreferences = {
                ...user.notificationPreferences,
                __typename: 'NotificationPreference'
              }
              return user
            })

            store.writeQuery({
              query: accountUsersQuery,
              data: { accountUsers: updatedUsers },
              variables: updateVariables
            })
          }
        })
        toastr.success('User successfully updated')
      } catch (error) {
        console.log('Error: ' + error)
        toastr.error('Could not update User')
      }
    }
})

UsersContainer.propTypes = {
  activeAccount: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired
}

const data = graphql(accountUsersQuery, {
  options: ({ activeAccount }) => ({
    variables: {
      accountId: activeAccount.id
    }
  })
})

const UsersContainerWithData = compose(
  data,
  displayWhileLoading(LoadingPlaceholder),
  displayForError(ErrorPlaceholder),
  graphql(removeUserFromAccountMutation, { name: 'deleteUser' }),
  graphql(updateNotificationPreferencesMutation, { name: 'updateUser' }),
  handlers
)(UsersContainer)

export default UsersContainerWithData
