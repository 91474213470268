import React from 'react'
import PropTypes from 'prop-types'
import { compose, setPropTypes } from 'recompose'
import { withStyles } from '@material-ui/core/styles'
import PrivateRoute from '../PrivateRoute'
import { Switch, withRouter } from 'react-router-dom'
import { AccountHeader as Header, ContentContainer } from 'components'
import { UsersContainer } from 'pages/private/account/users'
import {
  AccountOverviewContainer as OverviewContainer,
  UserSettingsContainer,
  InvitationsContainer,
  InvitationCreateContainer
} from 'containers'

const styles = (theme) => ({
  content: {
    padding: theme.spacing(3)
  }
})

const Layout = ({ match, activeAccount, user }) => (
  <ContentContainer header={<Header account={activeAccount} goBack />}>
    <Switch>
      <PrivateRoute
        exact
        path={`${match.path}/settings`}
        component={OverviewContainer}
        activeAccount={activeAccount}
      />
      <PrivateRoute
        exact
        path={`${match.path}/users`}
        component={UsersContainer}
        activeAccount={activeAccount}
        user={user}
      />
      <PrivateRoute
        exact
        path={`${match.path}/profile/user`}
        component={UserSettingsContainer}
        activeAccount={activeAccount}
        user={user}
      />
      <PrivateRoute
        exact
        path={`${match.path}/invitations/create`}
        activeAccount={activeAccount}
        component={InvitationCreateContainer}
      />
      <PrivateRoute
        exact
        path={`${match.path}/invitations`}
        component={InvitationsContainer}
        activeAccount={activeAccount}
      />
    </Switch>
  </ContentContainer>
)

const MarketplaceLayout = compose(
  setPropTypes({
    activeAccount: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired
  }),
  withRouter,
  withStyles(styles)
)(Layout)

export default MarketplaceLayout
