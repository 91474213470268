import gql from 'graphql-tag'

const fileFieldsFragment = gql`
  fragment fileFields on File {
    id
    url
    contentType
  }
`

export default fileFieldsFragment
