import Apiant from 'assets/apiant-icon.png'
import ApiantLogo from 'assets/apiant-logo.png'
import Automate from 'assets/automate-io-icon.png'
import AutomateLogo from 'assets/automate-io-logo.png'
import BlendrLogo from 'assets/blendr-logo.png'
import Blendr from 'assets/blendr.png'
import IFTTT from 'assets/ifttt-icon.png'
import IftttLogo from 'assets/ifttt-logo.png'
import Integromat from 'assets/integromat-icon.png'
import IntegromatLogo from 'assets/integromat-logo.png'
import MicrosoftFlow from 'assets/microsoft-flow-icon.png'
import MicrosoftFlowLogo from 'assets/microsoft-flow-logo.png'
import PiesyncLogo from 'assets/piesync-logo.png'
import Piesync from 'assets/piesync.png'
import Segment from 'assets/segment.png'
import SegmentLogo from 'assets/segmentio.png'
import Workato from 'assets/workato-icon.png'
import WorkatoLogo from 'assets/workato-logo.svg'
import ZapierLogo from 'assets/zapier-logo.svg'
import Zapier from 'assets/zapier.png'

const integrationPlatforms = {
  zapier: {
    id: 'zapier',
    label: 'Zapier',
    formId: 'zapierEnabled',
    description: 'Integrates with 4.000+ integrations',
    icon: Zapier,
    logo: ZapierLogo,
    primaryColor: '#ff4a00',
    docUrl: 'https://integrations.apideck.com/listing/zapier'
  },
  piesync: {
    id: 'piesync',
    label: 'Piesync',
    formId: 'piesyncEnabled',
    description: 'IIntegrates with 200+ integrations',
    icon: Piesync,
    logo: PiesyncLogo,
    primaryColor: '#000',
    docUrl: 'https://integrations.apideck.com/listing/piesync'
  },
  segment: {
    id: 'segment',
    label: 'Segment',
    formId: 'segmentEnabled',
    description: 'Integrates with 200+ integrations',
    icon: Segment,
    logo: SegmentLogo,
    primaryColor: '#00BC7D',
    docUrl: 'https://integrations.apideck.com/listing/segment'
  },
  'microsoft-flow': {
    id: 'microsoft-flow',
    label: 'Microsoft Flow',
    formId: 'microsoftFlowEnabled',
    description: 'Integrates with 250+ integrations',
    icon: MicrosoftFlow,
    logo: MicrosoftFlowLogo,
    primaryColor: '#0073FF',
    docUrl: 'https://integrations.apideck.com/listing/microsoft-flow'
  },
  combidesk: {
    id: 'combidesk',
    label: 'Combidesk',
    formId: 'combideskEnabled',
    description: 'Integrates with 80+ integrations',
    icon: 'https://res.cloudinary.com/apideck/image/upload/v1555031711/integration-platforms/combidesk/icon.png',
    logo: 'https://res.cloudinary.com/apideck/image/upload/v1557862721/integration-platforms/combidesk/logo.png',
    primaryColor: '#1784C4',
    docUrl: 'https://integrations.apideck.com/listing/combidesk'
  },
  apiant: {
    id: 'apiant',
    label: 'Apiant',
    formId: null,
    description: '',
    icon: Apiant,
    logo: ApiantLogo
  },
  blendr: {
    id: 'blendr',
    label: 'Blendr.io',
    formId: 'blendrEnabled',
    description: 'Integrates with 150+ integrations',
    icon: Blendr,
    logo: BlendrLogo,
    docUrl: 'https://integrations.apideck.com/listing/blendr-io'
  },
  automate: {
    id: 'automate',
    label: 'Automate.io',
    formId: 'automateEnabled',
    description: 'Integrates with 120 integrations',
    icon: Automate,
    logo: AutomateLogo,
    docUrl: 'https://integrations.apideck.com/listing/automate-io'
  },
  integromat: {
    id: 'integromat',
    label: 'Integromat',
    formId: 'integromatEnabled',
    description: 'Integrates with 230+ integrations',
    icon: Integromat,
    logo: IntegromatLogo,
    docUrl: 'https://integrations.apideck.com/listing/integromat'
  },
  workato: {
    id: 'workato',
    label: 'Workato',
    formId: null,
    description: '',
    icon: Workato,
    logo: WorkatoLogo
  },
  ifttt: {
    id: 'ifttt',
    label: 'IFTTT',
    formId: null,
    description: '',
    icon: IFTTT,
    logo: IftttLogo
  },
  'tray-io': {
    id: 'tray-io',
    label: 'Tray.io',
    formId: 'trayIoEnabled',
    description: 'Integrates with 600+ integrations',
    icon: 'https://res.cloudinary.com/apideck/image/upload/v1554218343/integration-platforms/tray-io/icon.png',
    docUrl: 'https://integrations.apideck.com/listing/tray-io'
  },
  azuqua: {
    id: 'azuqua',
    label: 'Azuqua',
    formId: null,
    description: '',
    icon: 'https://res.cloudinary.com/apideck/image/upload/v1546975745/integration-platforms/azuqua/icon.png'
  }
}

export const integrationPlatformsArray = [
  integrationPlatforms.zapier,
  integrationPlatforms.piesync,
  integrationPlatforms.segment,
  integrationPlatforms['microsoft-flow'],
  integrationPlatforms['tray-io'],
  integrationPlatforms.combidesk,
  integrationPlatforms.apiant,
  integrationPlatforms.blendr,
  integrationPlatforms.automate,
  integrationPlatforms.integromat,
  integrationPlatforms.workato,
  integrationPlatforms.ifttt,
  integrationPlatforms.azuqua
]

export default integrationPlatforms
