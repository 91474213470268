import {
  HeaderTitle,
  HeaderWithTabs,
  PreviewButton,
  ProductNavigation,
  SecondaryButton
} from 'components'
import PropTypes from 'prop-types'
import React from 'react'
import { withRouter } from 'react-router-dom'
import ecosystemUrl from 'utils/ecosystemUrl'
import getLocalizedValue from 'utils/getLocalizedValue'

const ProductHeaderWrapper = ({ product, match, history, ecosystem }) => (
  <HeaderWithTabs tabs={<ProductNavigation match={match} />}>
    <HeaderTitle
      title={getLocalizedValue(product, 'name', ecosystem.locales)}
      imageSrc={product.logo ? product.logo.url : null}
      subtitle={getLocalizedValue(product, 'description', ecosystem.locales) || 'Product'}
    />
    <SecondaryButton className="fr" onClick={history.goBack}>
      Go Back
    </SecondaryButton>
    <PreviewButton href={`${ecosystemUrl(ecosystem)}/product/${product.slug}`} />
  </HeaderWithTabs>
)

ProductHeaderWrapper.propTypes = {
  product: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired
}

const ProductHeader = withRouter(ProductHeaderWrapper)

export default ProductHeader
