import { Card, Loader } from 'components'

import Grid from '@material-ui/core/Grid'
import React from 'react'

const DashboardCard = ({ size = 6, title, children, height = '200px', loading }) => (
  <Grid item xs={size}>
    <Card title={title} height={height} style={{ marginBottom: 0 }}>
      {loading ? <Loader /> : children}
    </Card>
  </Grid>
)

export default DashboardCard
