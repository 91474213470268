import slugify from 'slugify'

const Slug = (formikProps, key) => {
  let value = ''

  if (formikProps.values['locales'] && formikProps.values['locales'].length > 1) {
    value = formikProps.values[key + '_' + formikProps.values['locales'][0]]
  } else {
    value = formikProps.values[key]
  }

  return slugify(
    value
      .toLowerCase()
      .replace('.', '')
      .replace(',', '')
      .replace("'", '')
      .replace('/', '')
      .replace('%20', '-')
      .replace('(', '')
      .replace(')', '')
      .replace('+', '')
      .replace('#', '')
      .replace('®', '')
      .replace('_', '')
      .replace('`', '')
      .replace('"', '')
      .replace('™', ''),
    {
      replacement: '-',
      remove: /[.]/g,
      lower: true
    }
  )
}

export default Slug
