import { makeStyles } from '@material-ui/core/styles'
import {
  AnyErrorsMessage,
  PrimaryButtonNew as PrimaryButton,
  TextInputNew as TextInput
} from 'components'
import { Formik } from 'formik'
import PropTypes from 'prop-types'
import React from 'react'
import { ScaleLoader } from 'react-spinners'

const useStyles = makeStyles((theme) => ({
  inputWrapper: {
    marginBottom: theme.spacing(2)
  }
}))

const Form = ({ initialValues, onSubmit, product }) => {
  const classes = useStyles()

  return (
    <Formik onSubmit={onSubmit} initialValues={initialValues}>
      {(formikProps) => {
        const { handleSubmit, isSubmitting } = formikProps

        return (
          <form onSubmit={handleSubmit}>
            <div className={classes.inputWrapper}>
              <TextInput
                formikProps={formikProps}
                label="Your email"
                field="email"
                testid="signup-email-input"
              />
            </div>

            <div className={classes.inputWrapper}>
              <TextInput
                formikProps={formikProps}
                label="Password"
                field="password"
                type="password"
                testid="signup-password-input"
              />
            </div>

            <PrimaryButton type="submit" disabled={isSubmitting} data-testid="signup-submit">
              {isSubmitting ? (
                <ScaleLoader height={12} width={3} color="var(--color-white)" />
              ) : product === 'ecosystem' ? (
                'Start your free 14 days trial'
              ) : (
                'Get started'
              )}
            </PrimaryButton>

            <AnyErrorsMessage />
          </form>
        )
      }}
    </Formik>
  )
}

Form.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired
}

export default Form
