import { catalogServicesQuery } from 'api/queries'
import { hasPath, path } from 'ramda'
import React, { useState } from 'react'
import { useQuery } from 'react-apollo'
import { useDebounce } from 'use-debounce'
import NetworkContainer from './NetworkContainer'

const PER_PAGE = 200

function NetworkPage({ ecosystem, activeAccount }) {
  const [searchText, setSearchText] = useState()
  const [integrationPlatform, setIntegrationPlatform] = useState()
  const [debouncedSearchText] = useDebounce(searchText, 1000)

  const { data, loading, fetchMore } = useQuery(catalogServicesQuery, {
    variables: {
      filterIntegrationPlatform: integrationPlatform,
      filterText: debouncedSearchText,
      perPage: PER_PAGE,
      page: 1
    },
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true
  })

  const loadNextPage = async () => {
    try {
      fetchMore({
        variables: {
          filterIntegrationPlatform: integrationPlatform,
          filterText: searchText,
          page: data.services.page + 1,
          perPage: PER_PAGE
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev
          let services = []

          // check if we have fetched more results
          const hasNewServices =
            fetchMoreResult && hasPath(['services', 'services'], fetchMoreResult)

          if (hasNewServices) {
            services = path(['services', 'services'], fetchMoreResult)
          }

          return Object.assign({}, prev, {
            services: {
              hasMore: fetchMoreResult.services.hasMore,
              page: fetchMoreResult.services.page,
              perPage: fetchMoreResult.services.perPage,
              services: [...prev.services.services, ...services],
              total: fetchMoreResult.services.total,
              totalPages: fetchMoreResult.services.totalPages,
              __typename: 'ServicePage'
            }
          })
        }
      })
    } catch (error) {
      throw new Error(error.message)
    }
  }

  // by default return 0 services
  let services = []

  // check if we have already have services and use them
  const hasServices = data && hasPath(['services', 'services'], data)
  if (hasServices) {
    services = path(['services', 'services'], data)
  }

  const total = loading ? 50000 : data.services.total
  let hasMore = true
  if (data && data.services) {
    hasMore = data.services.hasMore
  }

  return (
    <NetworkContainer
      ecosystem={ecosystem}
      activeAccount={activeAccount}
      hasMore={hasMore}
      loading={loading}
      loadNextPage={loadNextPage}
      onIntegrationFilterChange={setIntegrationPlatform}
      onTextFilterChange={setSearchText}
      searching={loading && !!debouncedSearchText}
      services={services}
      total={total}
    />
  )
}

export default NetworkPage
