import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import PropTypes from 'prop-types'
import React from 'react'
import classNames from 'classnames'

class FormDialog extends React.Component {
  static propTypes = {
    opener: PropTypes.func.isRequired
  }

  state = {
    open: false
  }

  handleOpen = (newState) => {
    this.setState(() => ({
      ...newState,
      open: true
    }))
  }

  handleClose = () => {
    this.setState({
      open: false
    })
  }

  handleDelete = () => {
    this.handleClose()
    this.state.onDelete(this.state.id)
  }

  render() {
    const { open, title, contentText } = this.state

    return (
      <React.Fragment>
        {this.props.opener(this.handleOpen)}
        <Dialog
          open={open}
          onClose={this.handleClose}
          fullWidth={true}
          onClick={(e) => e.stopPropagation()}
        >
          <DialogTitle>{title}</DialogTitle>
          <DialogContent>
            <DialogContentText>{contentText}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose}>No, cancel</Button>
            <Button onClick={this.handleDelete} className={classNames('delete')}>
              Yes, delete
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    )
  }
}

export default FormDialog
