import { catalogServiceQuery } from 'api/queries'

const domainsToAvoid = [
  'gmail.com',
  'hotmail.com',
  'outlook.com',
  'yahoo.com',
  'icloud.com',
  'msn.com'
]

export const performNetworkLookup = async (domain, client) => {
  if (domainsToAvoid.includes(domain)) return null

  try {
    const { error, data: service } = await client.query({
      query: catalogServiceQuery,
      errorPolicy: 'ignore',
      variables: {
        id: domain
      }
    })

    let serviceData = null

    if (error) {
      return serviceData
    }

    if (service) {
      const { name, icon, primaryColor } = service
      serviceData = {
        name,
        websiteUrl: domain,
        iconUrl: icon?.url,
        primaryColor
      }
    }
    return serviceData
  } catch {
    return null
  }
}
