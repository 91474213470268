import classNames from 'classnames'
import React, { useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import styled from 'react-emotion'
import { Manager, Popper, Reference } from 'react-popper'

const DropdownContainer = styled('div')`
  background-color: var(--color-white);
  min-width: 220px;
  border-radius: 4px;
  padding: 5px;
  box-shadow: 0px 4px 4px rgba(79, 83, 108, 0.1);

  &.top-end {
    margin-right: -15px;
    margin-bottom: 10px;
  }

  &.bottom-end {
    margin-right: -25px;
    margin-top: 5px;
  }
`

const Dropdown = ({ renderContent, placement, children, testid }) => {
  const [isOpen, setIsOpen] = useState(false)
  const node = useRef()
  const handleClick = (e) => {
    if (
      document.querySelector('#dropdowns').contains(e.target) ||
      e.target.closest('.dropdown-trigger.active')
    ) {
      return false
    }
    setIsOpen(false)
  }
  const handleClose = () => {
    setIsOpen(false)
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClick)

    return () => {
      document.removeEventListener('mousedown', handleClick)
    }
  }, [])

  return (
    <div ref={node}>
      <Manager>
        <Reference>
          {({ ref }) => (
            <div
              ref={ref}
              data-testid={testid}
              onClick={(e) => {
                e.stopPropagation()
                setIsOpen(!isOpen)
              }}
              className={classNames('dropdown-trigger', { active: isOpen })}
            >
              {children}
            </div>
          )}
        </Reference>
        {isOpen &&
          createPortal(
            <Popper placement={placement}>
              {({ ref, style, placement }) => (
                <div
                  ref={ref}
                  style={style}
                  data-placement={placement}
                  onClick={(e) => e.stopPropagation()}
                >
                  <DropdownContainer className={classNames({ [placement]: placement })}>
                    {renderContent({ handleClose })}
                  </DropdownContainer>
                </div>
              )}
            </Popper>,
            document.querySelector('#dropdowns')
          )}
      </Manager>
    </div>
  )
}

Dropdown.defaultProps = {
  placement: 'bottom-end',
  testid: null
}

export default Dropdown
