import fileFieldsFragment from './fileFields'
import gql from 'graphql-tag'
import translationFieldsFragment from './translationFields'

const screenshotFieldsFragment = gql`
  fragment screenshotFields on Screenshot {
    id
    caption
    sequence
    file {
      ...fileFields
    }
    translations {
      ...translationFields
    }
  }
  ${fileFieldsFragment}
  ${translationFieldsFragment}
`

export default screenshotFieldsFragment
