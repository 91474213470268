import { bugsnagClient } from '../index'

export const registerUserWithBugsnag = (user, account) => {
  bugsnagClient.user = {
    id: user.id,
    name: user.name,
    email: user.email,
    account: account.name
  }
}
