import * as yup from 'yup'

import { CheckboxInput, Fieldset, Submit, TextInput } from 'components'
import { ConnectedSlugField, DescriptionField, LogoField, NameField, SequenceField } from './Base'

import { Formik } from 'formik'
import PropTypes from 'prop-types'
import React from 'react'
import ecosystemUrl from 'utils/ecosystemUrl'

const schema = yup.object().shape({
  slug: yup
    .string()
    .matches(
      /^[a-z0-9]+(?:-[a-z0-9]+)*$/,
      'Must be alphanumeric (A-Z, 0-9) with dashes between words.'
    )
    .required('Slug is a required field'),
  description: yup.string(),
  visible: yup.string().required('Visible is a required field'),
  logo: yup.mixed()
})

export const CollectionCreateForm = ({ initialValues, handleSubmit, ecosystem }) => (
  <Formik validationSchema={schema} initialValues={initialValues} onSubmit={handleSubmit}>
    {(formikProps) => {
      const { handleSubmit, isSubmitting } = formikProps
      return (
        <form onSubmit={handleSubmit}>
          <Fieldset>
            <NameField formikProps={formikProps} />
            <ConnectedSlugField
              formikProps={formikProps}
              prefix={`${ecosystemUrl(ecosystem)}/collection/`}
            />
            <LogoField formikProps={formikProps} />
            <DescriptionField formikProps={formikProps} />
            <SequenceField formikProps={formikProps} />
            <CheckboxInput formikProps={formikProps} label="Published" field="visible" />
            <CheckboxInput
              formikProps={formikProps}
              label="Hide from homepage (still available in sidebar)"
              field="hiddenFromHomepage"
            />
            {!formikProps.values.hiddenFromHomepage && (
              <TextInput
                formikProps={formikProps}
                label="Max listings on homepage"
                field="showMaxItemsHomepage"
                type="number"
                description="Max listings to show on homepage for this collection"
              />
            )}
          </Fieldset>
          <div className="sticky-submit-container">
            <Submit isSubmitting={isSubmitting} text="Create Collection" />
          </div>
        </form>
      )
    }}
  </Formik>
)

CollectionCreateForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  collection: PropTypes.object,
  initialValues: PropTypes.object
}

export default CollectionCreateForm
