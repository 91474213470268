import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Tooltip from '@material-ui/core/Tooltip'
import CloseIcon from '@material-ui/icons/Close'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import { IconButton, SelectIntegrationInput } from 'components'

const useStyles = makeStyles((theme) => ({
  card: {
    width: '700px'
  },
  title: {
    fontSize: '20px',
    fontWeight: 500,
    color: theme.palette.ui[800]
  },
  action: {
    // alignSelf: 'center',
    margin: 0
  },
  buttonClose: {
    marginLeft: theme.spacing(2)
  },
  cardHeader: {
    padding: theme.spacing(4, 6),
    borderBottom: `1px solid ${theme.palette.ui[200]}`
  },
  content: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(2, 6),
    color: theme.palette.ui[600],
    fontSize: '15px'
  },
  formFooter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    backgroundColor: theme.palette.ui[100],
    padding: theme.spacing(2, 6)
  }
}))

const IntegrationsSettingsAddModal = ({
  unifiedApi,
  title,
  setModalOpen,
  connections,
  handleFormSubmit,
  formSubmitLoading,
  successCallback
}) => {
  const [value, setValue] = useState('')
  const [id, setId] = useState(null)
  const classes = useStyles()
  const connectionsOptions = connections.map((connection) => {
    const { id, name, serviceId, icon } = connection
    return {
      id,
      label: name,
      value: serviceId,
      icon
    }
  })

  return (
    <Card className={classes.card}>
      <CardHeader
        className={classes.cardHeader}
        classes={{
          action: classes.action
        }}
        action={
          <Tooltip title="Or press esc" placement="top" arrow>
            <div className={classes.buttonClose}>
              <IconButton onClick={() => setModalOpen(null)}>
                <CloseIcon style={{ fontSize: 24 }} />
              </IconButton>
            </div>
          </Tooltip>
        }
        title={title}
        titleTypographyProps={{
          className: classes.title
        }}
      />
      <form
        onSubmit={(e) => {
          e.preventDefault()
          handleFormSubmit(
            {
              unifiedApi,
              serviceId: value,
              enabled: true
            },
            successCallback(id)
          )
        }}
      >
        <CardContent className={classes.content}>
          <SelectIntegrationInput
            label="Select provider"
            field="integration"
            value={value}
            options={connectionsOptions}
            handleChange={(value, id) => {
              setId(id)
              setValue(value)
            }}
          />
        </CardContent>
        <div className={classes.formFooter}>
          <Button variant="contained" color="primary" type="submit" disabled={formSubmitLoading}>
            {formSubmitLoading ? 'Loading..' : 'Add'}
          </Button>
        </div>
      </form>
    </Card>
  )
}

IntegrationsSettingsAddModal.propTypes = {
  unifiedApi: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  setModalOpen: PropTypes.func.isRequired,
  connections: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      icon: PropTypes.string
    })
  ).isRequired,
  handleFormSubmit: PropTypes.func.isRequired,
  formSubmitLoading: PropTypes.bool.isRequired,
  successCallback: PropTypes.func
}

export default IntegrationsSettingsAddModal
