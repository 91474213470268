import {
  ConnectedSlugField,
  ExternalLinksFieldset,
  IntegrationLinksFieldset,
  LogoField,
  NameField,
  PublishedField,
  StickyField,
  TagLineField,
  UpcomingField,
  WebsiteField,
  baseValidationSchema
} from './Base'
import { Fieldset, Submit } from 'components'

import { Formik } from 'formik'
import PropTypes from 'prop-types'
import React from 'react'
import ecosystemUrl from 'utils/ecosystemUrl'

export const ListingForm = ({ initialValues, handleSubmit, ecosystem }) => {
  const name = ecosystem.name
  const url = `${ecosystemUrl(ecosystem)}/listing/`

  return (
    <Formik
      validationSchema={baseValidationSchema(initialValues.locales)}
      initialValues={initialValues}
      onSubmit={handleSubmit}
    >
      {(formikProps) => {
        const { handleSubmit, isSubmitting } = formikProps
        return (
          <form onSubmit={handleSubmit}>
            <Fieldset title="Basic info">
              <NameField formikProps={formikProps} />
              <ConnectedSlugField formikProps={formikProps} ecosystemUrl={url} />
              <TagLineField formikProps={formikProps} ecosystemName={name} />
              <WebsiteField formikProps={formikProps} />
              <LogoField formikProps={formikProps} />
              <StickyField formikProps={formikProps} />
              <PublishedField formikProps={formikProps} />
              <UpcomingField disabled={!formikProps.values.published} formikProps={formikProps} />
            </Fieldset>
            <ExternalLinksFieldset formikProps={formikProps} />
            <IntegrationLinksFieldset formikProps={formikProps} ecosystem={ecosystem} />
            <div className="sticky-submit-container">
              <Submit isSubmitting={isSubmitting} text="Create" />
            </div>
          </form>
        )
      }}
    </Formik>
  )
}

ListingForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  categories: PropTypes.array,
  listing: PropTypes.object,
  initialValues: PropTypes.object
}

export default ListingForm
