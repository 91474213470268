import 'react-virtualized/styles.css'
import 'styles/index.css'
import 'styles/theme.css'
import 'tachyons'

import { MuiThemeProvider, createTheme } from '@material-ui/core/styles'
import {
  InvitationActivateCallbackPage,
  InvitationActivatePage,
  PartnerRequestConfirmationPage
} from 'pages'
import React, { Fragment } from 'react'
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'

import { NotFound } from 'components'
import PrivateRoute from 'components/PrivateRoute'
import ScrollToTop from 'components/layout/ScrollToTop'
import { MarketplaceCreateContainer } from 'containers'
import apolloClient from 'lib/apolloClient'
import { initStore } from 'lib/reduxStore'
import { ApolloProvider } from 'react-apollo'
import { Provider } from 'react-redux'
import { IntercomProvider } from 'react-use-intercom'
import { PrivateRoutesContainer } from 'routes'
import Auth from 'utils/auth'

const client = new apolloClient()
export const store = initStore(client)
export const auth = new Auth({ client })

const globalTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1580
    }
  },
  palette: {
    primary: {
      main: '#5D50CE'
    },
    secondary: {
      // main: '#A037EC'
      main: '#5D50CE'
    },
    error: {
      main: '#f44336',
      light: '#ffc4c3',
      lighter: '#ffd7d6',
      lightest: '#feedec',
      dark: '#d32f2f',
      contrastText: '#fff'
    },
    ui: {
      50: '#F9FAFD',
      100: '#F6F8FC',
      200: '#E7EDF7',
      250: '#CCD6E6',
      300: '#7478AA',
      400: '#6566A8',
      500: '#40418C',
      600: '#2A2F73',
      700: '#191D50',
      800: '#0A0E42',
      900: '#06082B'
    }
  }
})

const theme = createTheme(
  {
    overrides: {
      MuiTypography: {
        caption: {
          display: 'block',
          padding: '16px',
          fontSize: '14px',
          color: 'rgba(0, 0, 0, 0.54)',
          letterSpacing: 0
        }
      },
      MuiBackdrop: {
        root: {
          backgroundColor: 'rgba(2, 4, 31, 0.85)'
        }
      },
      MuiCardActionArea: {
        focusHighlight: {
          backgroundColor: globalTheme.palette.ui[600]
        }
      },
      MuiTouchRipple: {
        child: {
          backgroundColor: globalTheme.palette.ui[300]
        }
      },
      MuiTooltip: {
        tooltip: {
          backgroundColor: globalTheme.palette.ui[800],
          color: 'white',
          fontSize: '13px'
        },
        arrow: {
          color: globalTheme.palette.ui[800]
        }
      },
      MuiChip: {
        labelSmall: {
          padding: '2px 8px',
          fontWeight: 'bold'
        }
      },
      MuiTableRow: {
        hover: {
          cursor: 'pointer',
          '&$hover:hover': {
            backgroundColor: globalTheme.palette.ui[100]
          }
        }
      },
      MuiTableCell: {
        head: {
          fontSize: '12px',
          color: globalTheme.palette.ui[400],
          textTransform: 'uppercase'
        },
        body: {
          fontSize: '14px',
          color: globalTheme.palette.ui[700]
        }
      },
      MuiPaper: {
        elevation1: {
          boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.06), 0 1px 3px 0 rgba(4, 7, 45, 0.1)'
        }
      },
      MuiDrawer: {
        paper: {
          boxShadow: '0 2px 12px 0 rgba(4,7,45,0.12)'
        }
      }
    }
  },
  globalTheme
)

const intercomAppId = process.env.REACT_APP_INTERCOM_APP_ID ?? 'uf8tu2tz'

const App = () => (
  <Fragment>
    <IntercomProvider appId={intercomAppId} autoBoot={false}>
      <ApolloProvider client={client}>
        <Provider store={store}>
          <MuiThemeProvider theme={theme}>
            <Router>
              <ScrollToTop>
                <Switch>
                  <Route
                    exact
                    path="/login"
                    component={() => {
                      window.location.replace(`${process.env.REACT_APP_V2_URL}/api/auth/login`)
                      return null
                    }}
                  />
                  <Route
                    exact
                    path="/signup"
                    component={() => {
                      window.location.replace(
                        `${process.env.REACT_APP_V2_URL}/api/auth/login?screenHint=signup`
                      )
                      return null
                    }}
                  />
                  <Route
                    exact
                    path="/forgot-password"
                    component={() => {
                      window.location.replace(`${process.env.REACT_APP_V2_URL}/api/auth/login`)
                      return null
                    }}
                  />
                  <Route exact path="/activate/:challenge" component={InvitationActivatePage} />
                  <Route
                    exact
                    path="/activation-callback"
                    component={InvitationActivateCallbackPage}
                  />
                  <Route
                    exact
                    path="/partner-request/confirm/:challenge"
                    component={PartnerRequestConfirmationPage}
                  />
                  <PrivateRoute
                    exact
                    path="/ecosystem/create"
                    component={MarketplaceCreateContainer}
                  />
                  <Route path="/" render={() => <PrivateRoutesContainer />} />
                  <Route component={NotFound} />
                </Switch>
              </ScrollToTop>
            </Router>
          </MuiThemeProvider>
        </Provider>
      </ApolloProvider>
    </IntercomProvider>
    <div id="dropdowns" />
  </Fragment>
)

export default App
