import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import { Fieldset, DeleteDialog } from 'components'

const Delete = ({ handleCollectionDelete }) => (
  <Fieldset title="Danger Zone" style={{ marginTop: 30 }}>
    <p>Deleting your collection is a permanent change that can not be undone.</p>
    <DeleteDialog
      handleDelete={handleCollectionDelete}
      label="collection"
      opener={
        <Button className="delete" variant="contained" startIcon={<DeleteForeverIcon />}>
          Delete
        </Button>
      }
    />
  </Fieldset>
)

Delete.propTypes = {
  handleCollectionDelete: PropTypes.func.isRequired
}

export default Delete
