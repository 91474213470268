import React from 'react'
import classNames from 'classnames'
import integrationsPlatforms from 'constants/integrationPlatforms'

const iconSize = '18px'

const badgeStyle = {
  borderRadius: '2px',
  width: iconSize,
  height: iconSize,
  marginLeft: '6px'
}

const Badge = ({ src, dimmed, title }) => (
  <img src={src} alt={title} style={{ ...badgeStyle }} className={classNames({ dimmed: dimmed })} />
)

const Badges = ({
  piesyncEnabled,
  zapierEnabled,
  blendrEnabled,
  automateEnabled,
  segmentEnabled,
  microsoftFlowEnabled,
  combideskEnabled,
  integromatEnabled,
  trayIoEnabled,
  piesyncId,
  zapierId,
  blendrId,
  automateId,
  segmentId,
  microsoftFlowId,
  combideskId,
  trayIoId,
  integromatId,
  nativeIcon,
  nativeIntegration,
  thirdPartyIntegration,
  imageSrc
}) => (
  <div className="badges" style={{ position: 'relative' }}>
    <div style={{ position: 'absolute', top: '-30px', right: '-15px' }}>
      {thirdPartyIntegration && <Badge src={imageSrc} dimmed={!thirdPartyIntegration} />}
      {<Badge src={nativeIcon} dimmed={!nativeIntegration} />}
      {piesyncEnabled && (
        <Badge src={integrationsPlatforms['piesync'].icon} dimmed={!piesyncId} title="Piesync" />
      )}
      {zapierEnabled && (
        <Badge src={integrationsPlatforms['zapier'].icon} dimmed={!zapierId} title="Zapier" />
      )}
      {segmentEnabled && (
        <Badge src={integrationsPlatforms['segment'].icon} dimmed={!segmentId} title="Segment" />
      )}
      {microsoftFlowEnabled && (
        <Badge
          src={integrationsPlatforms['microsoft-flow'].icon}
          dimmed={!microsoftFlowId}
          title="Microsoft Flow"
        />
      )}
      {combideskEnabled && (
        <Badge
          src={integrationsPlatforms['combidesk'].icon}
          dimmed={!combideskId}
          title="Combidesk"
        />
      )}
      {trayIoEnabled && (
        <Badge src={integrationsPlatforms['tray-io'].icon} dimmed={!trayIoId} title="Tray.io" />
      )}
      {blendrEnabled && (
        <Badge src={integrationsPlatforms['blendr'].icon} dimmed={!blendrId} title="Blendr.io" />
      )}
      {automateEnabled && (
        <Badge
          src={integrationsPlatforms['automate'].icon}
          dimmed={!automateId}
          title="Automate.io"
        />
      )}
      {integromatEnabled && (
        <Badge
          src={integrationsPlatforms['integromat'].icon}
          dimmed={!integromatId}
          title="integromat"
        />
      )}
    </div>
  </div>
)

export default Badges
