import React from 'react'
import MUTab from '@material-ui/core/Tab'
import { withStyles } from '@material-ui/core/styles'

const styles = {
  root: {
    minWidth: 130
  }
}

const Tab = (props) => <MUTab {...props} classes={props.classes} />

export default withStyles(styles)(Tab)
