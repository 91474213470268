import {
  categoriesQuery,
  categoryQuery,
  deleteCategoryLogoMutation,
  deleteCategoryMutation,
  updateCategoryMutation
} from 'api/queries'
import {
  CategoryDelete as Delete,
  CategorySettings as Settings,
  injectLocalizedValues,
  prepareVariables
} from 'components'
import PropTypes from 'prop-types'
import React from 'react'
import { graphql } from 'react-apollo'
import { toastr } from 'react-redux-toastr'
import { compose, withHandlers } from 'recompose'
import uploadFile from 'utils/fileUpload'
import { ecosystemLocales, setRootValueFromTranslation } from 'utils/locales'
import transformError from 'utils/transformError'

export class Container extends React.Component {
  render() {
    const { category, handleSubmit, handleDelete, ecosystem, handleLogoDelete } = this.props

    let initialValues = {
      description: category.description || '',
      listingDescriptionTextTemplate: category.listingDescriptionTextTemplate || '',
      listingPricingTextTemplate: category.listingPricingTextTemplate || '',
      listingFeaturesTextTemplate: category.listingFeaturesTextTemplate || '',
      logo: category.logo ? [category.logo] : [],
      name: category.name || '',
      slug: category.slug || '',
      locales: ecosystemLocales(ecosystem)
    }

    initialValues = injectLocalizedValues('category', initialValues, category)

    return (
      <React.Fragment>
        <Settings
          handleSubmit={handleSubmit}
          initialValues={initialValues}
          ecosystem={ecosystem}
          handleLogoDelete={() => handleLogoDelete(category.id)}
        />
        <Delete handleCategoryDelete={handleDelete} />
      </React.Fragment>
    )
  }
}

const removeCategoryFromStore = (store, { ecosystemId, accountId }, category) => {
  const data = store.readQuery({
    query: categoriesQuery,
    variables: {
      id: ecosystemId,
      accountId
    }
  })

  data.marketplace.categories = data.marketplace.categories.filter((c) => c.id !== category.id)

  store.writeQuery({
    query: categoriesQuery,
    data,
    variables: {
      id: ecosystemId,
      accountId
    }
  })
}

const removeCategoryLogoFromStore = (store, { id, accountId }) => {
  const data = store.readQuery({
    query: categoryQuery,
    variables: {
      id,
      accountId
    }
  })

  data.category.logo = null

  store.writeQuery({
    query: categoryQuery,
    data,
    variables: {
      id,
      accountId
    }
  })
}

const categoryHandlers = withHandlers({
  handleSubmit:
    ({
      category,
      updateCategory,
      activeAccount,
      ecosystem,
      match: {
        params: { ecosystemId }
      }
    }) =>
    async (props, { setErrors, setSubmitting }) => {
      setSubmitting(true)

      const {
        description,
        listingDescriptionTextTemplate,
        listingPricingTextTemplate,
        listingFeaturesTextTemplate,
        logo,
        name,
        slug
      } = props

      try {
        const locales = ecosystemLocales(ecosystem)
        let variables = {
          id: category.id,
          accountId: activeAccount.id,
          description: setRootValueFromTranslation(locales, category, 'description', description),
          listingDescriptionTextTemplate,
          listingPricingTextTemplate,
          listingFeaturesTextTemplate,
          name: setRootValueFromTranslation(locales, category, 'name', name),
          slug,
          translations: prepareVariables(props, ecosystem, category.translations)
        }

        if (logo.length !== 0 && logo[0].preview) {
          let result = await uploadFile(logo[0], {
            params: {
              folder: `marketplaces/${ecosystemId}/categories`
            }
          })

          variables = {
            ...variables,
            logo: {
              url: result.url,
              contentType: `${result.resourceType}/${result.format}`
            }
          }
        }

        await updateCategory({
          variables
        })
        toastr.success('Category updated')
        setSubmitting(false)
      } catch (error) {
        toastr.error('Could not update category')
        if (error.graphQLErrors) {
          setErrors(transformError(error.graphQLErrors))
        }

        setSubmitting(false)
      }
    },
  handleDelete:
    ({
      deleteCategory,
      activeAccount,
      history,
      category,
      match: {
        params: { ecosystemId }
      }
    }) =>
    async () => {
      const variables = {
        id: category.id,
        accountId: activeAccount.id
      }

      try {
        await deleteCategory({
          variables,
          update: (store, { data: { deleteCategory } }) => {
            removeCategoryFromStore(
              store,
              {
                ecosystemId,
                accountId: activeAccount.id
              },
              deleteCategory
            )
          }
        })
        toastr.success('Category deleted')
        history.push(`/ecosystems/${ecosystemId}/listings/categories`)
      } catch (error) {
        toastr.error('Could not delete category')
      }
    },
  handleLogoDelete:
    ({ deleteCategoryLogo, activeAccount }) =>
    async (id) => {
      try {
        const variables = {
          accountId: activeAccount.id,
          id
        }

        await deleteCategoryLogo({
          variables,
          update: (store) =>
            removeCategoryLogoFromStore(store, {
              id,
              accountId: activeAccount.id
            })
        })

        toastr.success('Category logo deleted')
      } catch (error) {
        toastr.error('Could not delete category logo')
        throw new Error(error && error.message)
      }
    }
})

Container.propTypes = {
  activeAccount: PropTypes.object.isRequired
}

const OverviewContainer = compose(
  graphql(updateCategoryMutation, { name: 'updateCategory' }),
  graphql(deleteCategoryMutation, { name: 'deleteCategory' }),
  graphql(deleteCategoryLogoMutation, { name: 'deleteCategoryLogo' }),
  categoryHandlers
)(Container)

export default OverviewContainer
