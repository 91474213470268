import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom'
import { getItem, setItem } from 'utils/localstorage'

const IndexContainer = ({ activeAccount, user }) => {
  const [loading, setLoading] = useState(true)
  const [activeEcosystemId, setActiveEcosystemId] = useState(null)

  useEffect(() => {
    const getActiveEcosystemId = async () => {
      const storedActiveEcosystemId = getItem('activeEcosystemId')
      if (storedActiveEcosystemId !== null) {
        setActiveEcosystemId(storedActiveEcosystemId)
      } else {
        const account = user.accounts.find((account) => account.account.id === activeAccount.id)
        const ecosystems = account.account.marketplaces
        if (ecosystems.length !== 0) {
          setActiveEcosystemId(ecosystems[0].id)
          // set in localStorage
          setItem('activeEcosystemId', ecosystems[0].id)
        }
      }
      setLoading(false)
    }
    getActiveEcosystemId()
  }, [activeAccount, user.accounts])

  if (loading) {
    return false
  }
  if (activeEcosystemId === null) {
    return <Redirect to="/account/users" />
  }
  return <Redirect to={`/ecosystems/${activeEcosystemId}/`} />
}

IndexContainer.propTypes = {
  activeAccount: PropTypes.object.isRequired
}

export default IndexContainer
