import find from 'lodash/find'
import { ecosystemLocales } from 'utils/locales'

const prepareVariables = (variables, ecosystem, existingTranslations) => {
  if (existingTranslations) {
    const translations = []
    const locales = ecosystemLocales(ecosystem)

    for (const variable in variables) {
      for (const locale of locales) {
        if (variable.includes(`_${locale}`)) {
          const key = variable.split(`_${locale}`)[0]

          const translation = {
            key: key,
            value: variables[variable],
            locale: locale
          }

          const match = find(existingTranslations, { key, locale: locale })

          if (match) {
            translation.id = match.id
          }

          translations.push(translation)
        }
      }
    }

    return translations
  } else {
    throw new Error('Missing parameters')
  }
}

export default prepareVariables
