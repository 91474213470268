import Typography from '@material-ui/core/Typography'
import { CardNew as Card, IntegrationPlatformCard } from 'components'
import { integrationPlatformsArray } from 'constants/integrationPlatforms'
import { NOT_UPDATED, UPDATED } from 'constants/settings'
import React from 'react'
import { toastr } from 'react-redux-toastr'

export const Platforms = ({
  classes,
  accountId,
  ecosystem,
  updateIntegrationPlatformSettingsMutationLoading,
  updateIntegrationSettingsMutationLoading,
  updateIntegrationSettings,
  updateMarketplace
}) => {
  const INTEGRATION_PLATFORMS_TOOLTIP_CONTENT = `By activating an integration platform below the install links will automatically be shown on the supported listings for that specific integration platform. Listings available through integration platforms will also include an extra icon in the listing cards.`

  const {
    blendrEnabled,
    zapierEnabled,
    piesyncEnabled,
    segmentEnabled,
    automateEnabled,
    microsoftFlowEnabled,
    combideskEnabled,
    trayIoEnabled,
    integromatEnabled,
    integrationZapierId,
    integrationZapierBetaLink,
    zapsPageEnabled
  } = ecosystem

  const integrationPlatformValues = {
    blendrEnabled,
    zapierEnabled,
    piesyncEnabled,
    segmentEnabled,
    automateEnabled,
    microsoftFlowEnabled,
    combideskEnabled,
    trayIoEnabled,
    integromatEnabled
  }

  const integrationPlatformZapierFormFields = [
    {
      formId: 'integrationZapierId',
      label: 'Zapier id',
      value: integrationZapierId,
      type: 'text',
      required: false,
      description: null
    },
    {
      formId: 'integrationZapierBetaLink',
      label: 'Zapier beta link',
      value: integrationZapierBetaLink,
      type: 'text',
      required: false,
      description: 'This is the Zapier link to invite people to join your Zapier beta'
    },
    {
      formId: 'zapsPageEnabled',
      label: 'Enable dedicated zaps page',
      value: zapsPageEnabled,
      type: 'checkbox',
      required: false,
      description:
        'When enabled, a menu link will automatically be added to the categories menu on your ecosystem.'
    }
  ]

  const handleToggleActivateIntegrationPlatform = async (formId) => {
    if (!updateIntegrationPlatformSettingsMutationLoading) {
      const variables = {
        accountId,
        id: ecosystem.id,
        name: ecosystem.name,
        slug: ecosystem.slug,
        ...integrationPlatformValues,
        [formId]: !integrationPlatformValues[formId]
      }

      try {
        const { data: integrationPlatformData } = await updateMarketplace({
          variables
        })

        if (integrationPlatformData) {
          toastr.success(UPDATED)
        }
      } catch (error) {
        toastr.error(NOT_UPDATED)
      }
    }
  }

  const handleIntegrationPlatformZapierFormSubmit = async (values) => {
    const variables = {
      accountId,
      id: ecosystem.id,
      name: ecosystem.name,
      slug: ecosystem.slug,
      ...values
    }
    try {
      const { data: integrationPlatformData } = await updateIntegrationSettings({ variables })

      if (integrationPlatformData) {
        toastr.success(UPDATED)
      }
    } catch (error) {
      toastr.error(NOT_UPDATED)
    }
  }

  return (
    <Card title="Integration platforms" tooltipContent={INTEGRATION_PLATFORMS_TOOLTIP_CONTENT}>
      <Typography className={classes.title} component="h4">
        Platforms
      </Typography>
      <div className={classes.cardsContainer}>
        {integrationPlatformsArray.map((platform) => {
          const { id, formId, label, icon, description, docUrl } = platform

          if (formId) {
            return (
              <IntegrationPlatformCard
                key={id}
                formId={formId}
                label={label}
                icon={icon}
                description={description}
                docUrl={docUrl}
                isActive={integrationPlatformValues[formId] === true}
                handleToggleActivateIntegrationPlatform={handleToggleActivateIntegrationPlatform}
                updateIntegrationPlatformSettingsMutationLoading={
                  updateIntegrationPlatformSettingsMutationLoading
                }
                integrationPlatformZapierFormFields={integrationPlatformZapierFormFields}
                handleIntegrationPlatformZapierFormSubmit={
                  handleIntegrationPlatformZapierFormSubmit
                }
                updateIntegrationSettingsMutationLoading={updateIntegrationSettingsMutationLoading}
              />
            )
          }
          return false
        })}
      </div>
    </Card>
  )
}
