import { FormDialog } from 'components'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import * as yup from 'yup'
import { LanguageField } from '../../forms/Base'

const schema = yup.object().shape({
  locale: yup.string().required('Language is a required field')
})

class ItemDialog extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired
  }

  static defaultProps = {
    item: null
  }

  handleOpen = (openWithState) => () => {
    openWithState({
      title: 'Add Locale',
      validationSchema: schema,
      initialValues: { language: '' },
      render: this.renderFields,
      onSubmit: this.props.handleSubmit
    })
  }

  renderFields = (formikProps) => (
    <fieldset>
      <LanguageField formikProps={formikProps} />
    </fieldset>
  )

  render() {
    return (
      <FormDialog
        opener={(openWithState) => (
          <div className="ecosystem-add-locale" onClick={this.handleOpen(openWithState)}>
            {this.props.opener}
          </div>
        )}
      />
    )
  }
}

export default ItemDialog
