import { ExternalA, TabChip } from 'components'
import React from 'react'

const FeaturePill = ({
  href = 'https://roadmap.apideck.com/collection/experimental',
  label = 'EXPERIMENTAL'
}) => (
  <ExternalA href={href}>
    <TabChip label={label} />
  </ExternalA>
)

export default FeaturePill
