import React from 'react'
import styled from 'react-emotion'
import { Link } from 'react-router-dom'
import { Global, css } from '@emotion/core'

const Container = styled('div')`
  color: var(--color-text);

  h1 {
    font-weight: 500;
  }

  a {
    font-weight: 500;
  }
`

const SignupUserExists = ({ resetState }) => {
  const handleClick = (e) => {
    e.preventDefault()
    resetState()
  }

  return (
    <Container>
      <Global
        styles={css`
          body {
            background-color: white;
          }
        `}
      />
      <h1>Oops..</h1>
      <p>
        A user with this email address already exists. If this is your email, please{' '}
        <Link to="/login">log in</Link> or{' '}
        <a href="#" onClick={(e) => handleClick(e)}>
          try again
        </a>{' '}
        with a different email address.
      </p>
    </Container>
  )
}

export default SignupUserExists
