import Fade from '@material-ui/core/Fade'
import Modal from '@material-ui/core/Modal'
import { makeStyles } from '@material-ui/core/styles'
import { BackdropWithPoweredByUnify } from 'components'
import PropTypes from 'prop-types'
import React from 'react'
import IntegrationsSettingsAddModal from './IntegrationsSettingsAddModal'

const useStyles = makeStyles(() => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& span': {
      outline: 0
    }
  }
}))

const IntegrationsSettingsAddModalContainer = ({ isOpen, ...restProps }) => {
  const classes = useStyles()
  const { setModalOpen } = restProps

  return (
    <Modal
      className={classes.modal}
      open={isOpen}
      BackdropComponent={BackdropWithPoweredByUnify}
      BackdropProps={{
        timeout: 500
      }}
      closeAfterTransition
      onClose={() => setModalOpen(false)}
    >
      <Fade in={isOpen}>
        <span>
          <IntegrationsSettingsAddModal {...restProps} />
        </span>
      </Fade>
    </Modal>
  )
}

IntegrationsSettingsAddModalContainer.propTypes = {
  isOpen: PropTypes.bool.isRequired
}

export default IntegrationsSettingsAddModalContainer
