import { Card, CardEmptyState, GridContainer, ScreenshotDialog, SecondaryButton } from 'components'
import {
  DndContext,
  DragOverlay,
  KeyboardSensor,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors
} from '@dnd-kit/core'
import React, { useCallback, useState } from 'react'
import {
  SortableContext,
  arrayMove,
  horizontalListSortingStrategy,
  sortableKeyboardCoordinates,
  useSortable
} from '@dnd-kit/sortable'
import styled, { css } from 'react-emotion'

import Button from '@material-ui/core/Button'
import { CSS } from '@dnd-kit/utilities'
import Grid from '@material-ui/core/Grid'
import classNames from 'classnames'
import { sortBySequence } from 'utils/sortBySequence'
import { useDragStyles } from 'components/ecosystem/settings/navigation-items'

const ScreenshotImage = styled('img')`
  margin-right: 10px;
  margin-bottom: 10px;
  border-radius: 3px;
`

const ScreenshotWrapper = css`
  div {
    cursor: pointer;
    font-size: 12px;
    text-transform: uppercase;
  }
`

const Screenshot = ({ children, screenshotId, ...rest }) => {
  const classes = useDragStyles()
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: screenshotId,
    transition: {
      duration: 150,
      easing: 'cubic-bezier(0.25, 1, 0.5, 1)'
    }
  })

  return (
    <Grid item lg={4} className={ScreenshotWrapper}>
      <div
        ref={setNodeRef}
        className={classNames(classes.draggable, isDragging && classes.drop)}
        style={{
          transform: CSS.Transform.toString(transform),
          transition,
          cursor: 'move'
        }}
        {...attributes}
        {...listeners}
      >
        <ScreenshotImage {...rest} />
      </div>
      {children}
    </Grid>
  )
}

const Screenshots = ({
  screenshots: screenshotProps,
  locales,
  handleScreenshotCreate,
  handleScreenshotUpdate,
  handleScreenshotDelete
}) => {
  const [screenshots, setScreenshots] = useState(screenshotProps.slice().sort(sortBySequence))
  const [draggedScreenshotId, setDraggedScreenshotId] = useState(null)
  const [loading, setLoading] = useState(false)
  const classes = useDragStyles()
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  )

  const draggedScreenshot = screenshots.find((screenshot) => screenshot.id === draggedScreenshotId)

  const saveOrderOfScreenshots = useCallback(
    async (items) => {
      const updatedItems = await Promise.all(
        items.map((item, i) => {
          item.sequence = i

          return handleScreenshotUpdate(item)
        })
      )
      if (updatedItems?.filter(Boolean)?.length) {
        setScreenshots(updatedItems)
      }
    },
    [handleScreenshotUpdate]
  )

  const handleDragEnd = async (event, currentScreenshots) => {
    const { active, over } = event

    if (active.id !== over.id) {
      const oldIndex = currentScreenshots.map((item) => item.id).indexOf(active.id)
      const newIndex = currentScreenshots.map((item) => item.id).indexOf(over.id)
      const newScreenshots = arrayMove(currentScreenshots, oldIndex, newIndex)
      setLoading(true)
      await saveOrderOfScreenshots(newScreenshots)
      setLoading(false)
      setDraggedScreenshotId(null)
    }
  }

  return (
    <Card
      title="Screenshots"
      button={
        <ScreenshotDialog
          handleSubmit={handleScreenshotCreate}
          handleDelete={handleScreenshotDelete}
          locales={locales}
          opener={<SecondaryButton className="fr">+ Add</SecondaryButton>}
        />
      }
    >
      {screenshots.length > 0 ? (
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={(event) => handleDragEnd(event, screenshots)}
          onDragCancel={() => setDraggedScreenshotId(null)}
          onDragStart={({ active }) => {
            setDraggedScreenshotId(active.id)
          }}
        >
          <GridContainer style={{ opacity: loading ? 0.2 : 1 }}>
            <SortableContext items={screenshots} strategy={horizontalListSortingStrategy}>
              {screenshots.map((screenshot) => {
                if (screenshot.file && screenshot.id) {
                  return (
                    <Screenshot
                      src={screenshot.file?.url}
                      alt={screenshot.caption}
                      screenshotId={screenshot.id}
                      key={screenshot.id}
                    >
                      <ScreenshotDialog
                        handleSubmit={handleScreenshotUpdate}
                        handleDelete={handleScreenshotDelete}
                        screenshot={screenshot}
                        key={screenshot.id}
                        locales={locales}
                        opener={
                          <Button type="text" variant="outlined" color="primary">
                            Edit
                          </Button>
                        }
                      />
                    </Screenshot>
                  )
                }
              })}
            </SortableContext>
          </GridContainer>
          <DragOverlay>
            {draggedScreenshot ? (
              <Screenshot
                src={draggedScreenshot?.file?.url}
                alt={draggedScreenshot.caption}
                className={classNames(classes.dragging, classes.overlay)}
              />
            ) : null}
          </DragOverlay>
        </DndContext>
      ) : (
        <ScreenshotDialog
          handleSubmit={handleScreenshotCreate}
          handleDelete={handleScreenshotDelete}
          locales={locales}
          opener={<CardEmptyState>Get started by adding some screenshots.</CardEmptyState>}
        />
      )}
    </Card>
  )
}

export default Screenshots
