import {
  BorderIcon,
  Card,
  CardEmptyState,
  CatalogHeader,
  ContentContainer,
  ExternalA,
  GridContainer,
  HeaderTitle,
  Icon,
  IntegrationPlatformIcon,
  LetterAvatar,
  Link,
  LoadingPlaceholder,
  PrimaryButton,
  ErrorFallback
} from 'components'

import { BounceLoader } from 'react-spinners'
import Grid from '@material-ui/core/Grid'
import React, { useState } from 'react'
import _ from 'lodash'
import classNames from 'classnames'
import integrationPlatforms from 'constants/integrationPlatforms'
import styled from 'react-emotion'
import { withRouter } from 'react-router-dom'
import { useMaxListingsContext } from 'context/useMaxListingsContext'
import Tooltip from '@material-ui/core/Tooltip'

const H4 = styled('h4')`
  margin-bottom: 5px;
  font-size: 14px;
  margin: 0 0 5px 0;
`

const H5 = styled('h5')`
  color: #999;
  margin-top: 0px;
  font-weight: normal;
  font-size: 15px;

  a {
    text-decoration: none;
    color: #999;
  }
`

const UlLinks = styled('ul')`
  padding-left: 0;
  list-style-type: none;
  margin-left: 0;

  li {
    padding: 3px 0;
    color: #ccc;
  }

  li a {
    padding: 10px 0;
    font-weight: bold;
    color: #7f8fa4;
  }

  li a:hover {
    text-decoration: underline;
  }
`

const UlLink = ({ href, label }) => (
  <li>{href ? <ExternalA href={href}>{label}</ExternalA> : label}</li>
)

const IconWrapper = styled('div')`
  margin: 0 20px 0 0;
`

const Pill = styled('span')`
  background: #edf3f9;
  color: #5e7994;
  font-size: 12px;
  padding: 4px 6px;
  border-radius: 2px;
  margin-right: 4px;
`

const DisabledButton = styled('div')`
  padding: 6px 16px;
  font-size: 0.875rem;
  min-width: 64px;
  box-sizing: border-box;
  font-weight: 500;
  line-height: 1.75;
  border-radius: 4px;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.26);
  background: rgba(0, 0, 0, 0.12);
`

const IconDescription = ({ image, title, subtitle, fullIcon, to = '' }) => (
  <div style={{ display: 'flex' }}>
    <IconWrapper>
      {image && <Icon src={image} alt={title} size={56} padding={fullIcon && 0} />}
    </IconWrapper>

    <div>
      <Link to={to}>
        <H4>{title}</H4>
      </Link>
      <H5>{subtitle}</H5>
    </div>
  </div>
)

const Service = (props) => {
  const { handleServiceClick, error, loading, service, listing, ecosystem } = props
  const [disabled, setDisabled] = useState(false)
  const { maxListingsReached, infoMessage, recalculateMaxListings } = useMaxListingsContext()

  const handleClick = async () => {
    setDisabled(true)
    await handleServiceClick(service.id)
    await recalculateMaxListings()
    setDisabled(false)
  }
  const btnCopy = listing ? 'Remove' : 'Import'

  if (loading || !service) return <LoadingPlaceholder />
  if (error) return <ErrorFallback error={error} />

  const showLoadingBtn = disabled
  const showRemoveBtn = !disabled && listing
  const showDisabledImportBtn = !disabled && !listing && maxListingsReached
  const showEnabledImportBtn = !disabled && !listing && !maxListingsReached

  return (
    <ContentContainer header={<CatalogHeader />}>
      <Card style={{ minHeight: '500px', padding: '0 30px 20px' }}>
        <ServiceHeader>
          {listing && (
            <Link to={`/ecosystems/${ecosystem.id}/listings/${listing.id}/overview`}>
              <PrimaryButton className="fr" style={{ marginLeft: 10 }}>
                View listing
              </PrimaryButton>
            </Link>
          )}
          {showRemoveBtn && (
            <PrimaryButton className="fr" onClick={handleClick} style={{ width: 100 }}>
              Remove
            </PrimaryButton>
          )}
          {showLoadingBtn && (
            <PrimaryButton className="fr" onClick={handleClick} style={{ width: 100 }}>
              <BounceLoader sizeUnit={'px'} size={20} color={'#FFF'} />
            </PrimaryButton>
          )}
          {showDisabledImportBtn && (
            <Tooltip title={infoMessage} interactive placement="left" arrow>
              <DisabledButton className="fr" style={{ width: 100, textAlign: 'center' }}>
                {btnCopy}
              </DisabledButton>
            </Tooltip>
          )}
          {showEnabledImportBtn && (
            <PrimaryButton className="fr" onClick={handleClick} style={{ width: 100 }}>
              {btnCopy}
            </PrimaryButton>
          )}
          <HeaderTitle
            imageTitle={service.name}
            imageSrc={service.icon.url}
            title={service.name}
            subtitle={service.categories.map((c, i) => (
              <Pill key={i}>{c.name}</Pill>
            ))}
          />
        </ServiceHeader>
        <Grid container spacing={2}>
          <Grid item xs={9}>
            <Section title="Description">
              {service.description ? (
                <p>{service.description}</p>
              ) : (
                <CardEmptyState>No description available.</CardEmptyState>
              )}
            </Section>

            <Section title="Supported integration platforms">
              <Grid container spacing={2}>
                {Object.keys(integrationPlatforms).map((platform, index) => (
                  <GridBlock
                    key={index}
                    label={integrationPlatforms[platform].label}
                    to={`/ecosystems/${ecosystem.id}/listings/add/${integrationPlatforms[platform].id}`}
                    columns={4}
                    icon={
                      <IntegrationPlatformIcon
                        type={integrationPlatforms[platform].id}
                        className={classNames(
                          !_.find(service.integrationPlatforms, {
                            type: platform
                          }) && 'dimmed'
                        )}
                      />
                    }
                  />
                ))}
              </Grid>
            </Section>

            {service.apis && service.apis.length > 0 && (
              <ApiSection title="APIs" ecosystemId={ecosystem.id} apis={service.apis} />
            )}

            {service.products && service.products.length > 0 && (
              <GridIconSection
                title="Products"
                ecosystemId={ecosystem.id}
                services={service.products}
              />
            )}

            <GridIconSection
              title="Integrations"
              ecosystemId={ecosystem.id}
              services={service.integrations}
            />

            <Section title="Screenshots & videos">
              {service.screenshots.length > 0 ? (
                <GridContainer>
                  {service.screenshots.map((s, index) => (
                    <Grid item lg={6} key={index}>
                      <img src={s.url} alt={s.caption} />
                      {s.caption}
                    </Grid>
                  ))}
                </GridContainer>
              ) : (
                <CardEmptyState>No screenshots or videos available.</CardEmptyState>
              )}
            </Section>
          </Grid>
          <Grid item xs={3}>
            <Section title="Links">
              <UlLinks>
                <UlLink href={service.websiteUrl} label="Website" />
                <UlLink href={service.termsUrl} label="Terms" />
                <UlLink href={service.privacyUrl} label="Privacy policy" />
                <UlLink href={service.developerPortalUrl} label="Developer portal" />
                <UlLink href={service.apiReferenceUrl} label="API reference" />
                <UlLink href={service.partnersUrl} label="Partners" />
                <UlLink href={service.integrationsUrl} label="Integrations" />
                <UlLink href={service.statusUrl} label="Status" />
                <UlLink href={service.supportUrl} label="Support" />
                <UlLink href={service.brandAssetsUrl} label="Brand assets" />
                <UlLink href={service.pricingUrl} label="Pricing" />
              </UlLinks>
            </Section>
            {service.company && (
              <Section title="Company">
                <IconDescription
                  image={service.company.icon && service.company.icon.url}
                  to={serviceUrl(ecosystem.id, service.company.id)}
                  title={service.company.name}
                  fullIcon
                />
              </Section>
            )}
            {service.funds &&
              service.funds.length > 0 &&
              service.funds.map((f, i) => (
                <Section title="Funds" key={i}>
                  <IconDescription image={service.icon.url} title={f.name} subtitle={f.size} />
                </Section>
              ))}
            {service.contacts && service.contacts.length > 0 && (
              <Section title="Contacts">
                {service.contacts.map((contact, i) => (
                  <IconDescription
                    key={i}
                    fullIcon
                    image={contact.image}
                    title={`${contact.firstName} ${contact.lastName}`}
                    subtitle={contact.role}
                  />
                ))}
              </Section>
            )}
          </Grid>
        </Grid>
      </Card>
    </ContentContainer>
  )
}

const ServiceHeader = styled('div')`
  border-bottom: 1px solid #f1f1f1;
  padding-bottom: 16px;
`

const ApiSection = ({ apis, title }) => (
  <Section title={title}>
    {apis && apis.length > 0 ? (
      <Grid container spacing={2}>
        {apis.map(
          (api, index) =>
            api && (
              <GridBlock
                key={index}
                label={api.name}
                columns={4}
                to={api.documentationUrl || api.apiReferenceUrl}
                isExternal
                icon={
                  api.icon ? (
                    <BorderIcon src={api.icon && api.icon.url} label={api.name} />
                  ) : (
                    <LetterAvatar size={24} title={api.name} style={{ marginRight: '8px' }} />
                  )
                }
              />
            )
        )}
      </Grid>
    ) : (
      'Not available'
    )}
  </Section>
)

const GridIconSection = ({ ecosystemId, services, title }) => (
  <Section title={title}>
    {services && services.length > 0 ? (
      <Grid container spacing={2}>
        {services.map(
          (integration, index) =>
            integration && (
              <GridBlock
                key={index}
                label={integration.name}
                columns={4}
                to={serviceUrl(ecosystemId, integration.id)}
                icon={<BorderIcon src={integration.icon.url} label={integration.name} />}
              />
            )
        )}
      </Grid>
    ) : (
      'Not available'
    )}
  </Section>
)

const GridBlock = ({ label, icon, columns, to, isExternal }) => (
  <Grid item lg={12 / columns}>
    <Link to={to} isExternal={isExternal}>
      <div style={{ display: 'flex' }}>
        {icon}
        <p style={{ marginTop: '3px', marginBottom: 0 }} className="truncate">
          {label}
        </p>
      </div>
    </Link>
  </Grid>
)

const StyledSection = styled('section')`
  margin-bottom: 30px;

  a {
    color: #7f8fa4;
  }
  a:hover {
    text-decoration: underline;
  }
`

const Section = ({ title, children }) => (
  <StyledSection>
    <h4>{title}</h4>
    {children}
  </StyledSection>
)

const serviceUrl = (ecosystemId, serviceId) =>
  `/ecosystems/${ecosystemId}/listings/add/${serviceId}`

export default withRouter(Service)
