import { fileFieldsFragment, translationFieldsFragment } from 'api/fragments'
import gql from 'graphql-tag'

const collectionOverviewQuery = gql`
  query collectionOverview($accountId: ID!, $ecosystemId: ID!, $collectionId: ID!) {
    collection(id: $collectionId, accountId: $accountId) {
      id
      name
      slug
      description
      visible
      cardStyle
      cardColumns
      sequence
      hiddenFromHomepage
      showMaxItemsHomepage
      listings {
        id
        name
        links {
          id
          url
          type
        }
        logo {
          ...fileFields
        }
        translations {
          ...translationFields
        }
        state
      }
      logo {
        ...fileFields
      }
      cardBackgroundImage {
        ...fileFields
      }
      translations {
        ...translationFields
      }
      cardBackgroundColor
    }
    marketplace(id: $ecosystemId, accountId: $accountId) {
      id
      listings {
        id
        name
        slug
        logo {
          ...fileFields
        }
        collections {
          id
        }
        translations {
          ...translationFields
        }
        state
      }
    }
  }

  ${fileFieldsFragment}
  ${translationFieldsFragment}
`

export default collectionOverviewQuery
