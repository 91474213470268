import * as yup from 'yup'

import { FormDialog, SelectInput, TextInput, injectLocalizedValues } from 'components'
import React, { Component } from 'react'

import PropTypes from 'prop-types'

class ItemDialog extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired
  }

  static defaultProps = {
    item: null
  }

  handleOpen = (openWithState) => () => {
    const { item, locales, itemType = '' } = this.props
    const isFooterItem = itemType?.toLowerCase() === 'footer'
    const title = item
      ? `Edit ${itemType.toLowerCase()} item`
      : `New ${itemType.toLowerCase()} item`

    let initialValues = {
      id: item ? item.id : null,
      label: item ? item.label : '',
      url: item ? item.url : '',
      type: item ? item.type : '',
      locales
    }

    if (isFooterItem) {
      initialValues.alignment = item?.alignment || ''
    }

    if (item) {
      initialValues = injectLocalizedValues(
        isFooterItem ? 'footerItem' : 'navigationItem',
        initialValues,
        item
      )
    }

    const schema = yup.object().shape({
      // label: yup.string().required('Label is a required field'),
      url: yup.string().url(),
      type: yup.string().required(),
      alignment: isFooterItem && yup.string().required()
    })

    openWithState({
      title,
      initialValues,
      validationSchema: schema,
      render: this.renderFields,
      onSubmit: this.props.handleSubmit
    })
  }

  renderFields = (formikProps) => {
    const isFooterItem = this.props.itemType?.toLowerCase() === 'footer'
    return (
      <fieldset style={{ overflow: 'visible' }}>
        <TextInput formikProps={formikProps} label="Label" field="label" localized="true" />
        <SelectInput
          formikProps={formikProps}
          label="Type*"
          field="type"
          placeholder="Select type"
          required
          options={[
            isFooterItem
              ? {
                  label: 'Text',
                  value: 'TEXT'
                }
              : {
                  label: 'Button',
                  value: 'BUTTON'
                },
            {
              label: 'Link',
              value: 'LINK'
            }
          ]}
        />
        {(formikProps.values?.type === 'LINK' || !isFooterItem) && (
          <TextInput formikProps={formikProps} label="Url" field="url" required />
        )}
        {isFooterItem ? (
          <SelectInput
            formikProps={formikProps}
            label="Alignment*"
            field="alignment"
            required={isFooterItem}
            options={[
              {
                label: 'Left',
                value: 'LEFT'
              },
              {
                label: 'Right',
                value: 'RIGHT'
              }
            ]}
          />
        ) : null}
      </fieldset>
    )
  }

  render() {
    return (
      <FormDialog
        opener={(openWithState) => (
          <div onClick={this.handleOpen(openWithState)}>{this.props.opener}</div>
        )}
      />
    )
  }
}

export default ItemDialog
