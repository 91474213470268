import { Pill, Small } from 'components'
import React from 'react'
import { css, cx } from 'react-emotion'
import isFieldLocalized from 'utils/isFieldLocalized'

const S = {
  error: css`
    border: 1px solid #ff5630;
    border-radius: 3px;
  `,
  prefix: css`
    width: 50%;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  `,
  suffix: css`
    width: 50%;
    padding-right: 100px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  `
}

const stringifyNull = (value) => (value === null ? '' : value)

const TextField = (props) => {
  return isFieldLocalized(props) ? <LocalizedTextField {...props} /> : <Field {...props} />
}

const LocalizedTextField = (props) => (
  <React.Fragment>
    {props.formikProps.initialValues.locales.map((locale, i) => (
      <Field
        {...props} // always put this first - needed for connected values like slug
        key={`${props.field}_${locale}_${i}`}
        field={`${props.field}_${locale}`}
        label={`${props.label}`}
        pill={locale}
        required={props.required && i === 0} // only require the first localized field
      />
    ))}
  </React.Fragment>
)

const Field = (props) => {
  const {
    field,
    formikProps,
    label,
    type,
    required,
    description,
    prefix,
    suffix,
    pill,
    style,
    inputStyle,
    ...rest
  } = props
  const { handleChange, handleBlur, values, touched, errors } = formikProps

  const value = stringifyNull(values[field])
  return (
    <div className="form-element" style={style}>
      <div>
        <label htmlFor={field} className={cx({ required })}>
          {label} {pill && <Pill>{pill}</Pill>}
        </label>
      </div>
      <div>
        <div className="flex">
          {prefix && <div className="inputPrefix">{prefix}</div>}
          {type === 'textarea' ? (
            <textarea
              id={field}
              name={field}
              value={value}
              onChange={handleChange}
              onBlur={handleBlur}
              className={cx(
                { [S.prefix]: prefix },
                { [S.suffix]: suffix },
                { [S.error]: errors[field] }
              )}
              required={required}
              data-enable-grammarly
              style={inputStyle}
              {...rest}
            />
          ) : (
            <input
              id={field}
              name={field}
              value={value}
              onChange={handleChange}
              onBlur={handleBlur}
              className={cx(
                { [S.prefix]: prefix },
                { [S.suffix]: suffix },
                { [S.error]: errors[field] }
              )}
              type={type || 'text'}
              required={required}
              data-enable-grammarly
              {...rest}
            />
          )}

          {suffix && <div className="inputSuffix">{suffix}</div>}
        </div>
        <Small>{description}</Small>
        {errors[field] && touched[field] && errors[field] && (
          <span className="errors">{errors[field]}</span>
        )}
      </div>
    </div>
  )
}

export default TextField
