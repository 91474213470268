import {
  HeaderTitle,
  HeaderWithTabs,
  CategoryNavigation as Navigation,
  PreviewButton,
  SecondaryButton
} from 'components'

import PropTypes from 'prop-types'
import React from 'react'
import ecosystemUrl from 'utils/ecosystemUrl'
import getLocalizedValue from 'utils/getLocalizedValue'
import { withRouter } from 'react-router-dom'

const Header = ({ category, match, history, ecosystem }) => (
  <HeaderWithTabs tabs={<Navigation match={match} />}>
    <HeaderTitle
      title={getLocalizedValue(category, 'name', ecosystem.locales)}
      imageSrc={category.logo ? category.logo.url : null}
      subtitle={getLocalizedValue(category, 'description', ecosystem.locales) || 'Category'}
    />
    <SecondaryButton className="fr" onClick={history.goBack}>
      Go Back
    </SecondaryButton>
    <PreviewButton href={`${ecosystemUrl(ecosystem)}/category/${category.slug}`} />
  </HeaderWithTabs>
)

Header.propTypes = {
  category: PropTypes.object.isRequired
}

const CategoryHeader = withRouter(Header)

export default CategoryHeader
