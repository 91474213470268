import { ErrorFallback, MarketplaceListings as Listings, LoadingPlaceholder } from 'components'
import React, { memo, useState } from 'react'
import { useLocation, useRouteMatch } from 'react-router-dom'

import { listingsOverviewQuery } from 'pages/private/ecosystem/listings/overview/graphql'
import { unique } from 'utils/unique'
import { useDebounce } from 'use-debounce'
import { useQuery } from 'react-apollo'

// TODO move to pages folder

const PER_PAGE = 400

const ListingsPage = ({ ecosystem, activeAccount }) => {
  const [searchText, setSearchText] = useState('')
  const [integrationPlatform, setIntegrationPlatform] = useState('')
  const [listingStates, setListingStates] = useState(['PENDING_REVIEW', 'APPROVED'])

  const [debouncedSearchText] = useDebounce(searchText, 1000)
  const match = useRouteMatch()
  const { search } = useLocation()

  const ecosystemId = match.params.ecosystemId ?? ecosystem.id
  const params = new URLSearchParams(search)
  const accountId = params.get('account_id') ?? activeAccount.id

  const { loading, error, data, fetchMore } = useQuery(listingsOverviewQuery, {
    variables: {
      input: {
        ecosystemId,
        accountId,
        page: 0,
        perPage: PER_PAGE,
        filters: {
          integrationPlatform,
          searchText: debouncedSearchText,
          state: listingStates
        }
      }
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network'
  })

  if (loading && !data) {
    return <LoadingPlaceholder />
  }

  if (error) {
    return <ErrorFallback error={error} />
  }

  const { listings } = data
  const { data: listingsData, hasMore, page, total } = listings
  const loadNextPage = async () => {
    try {
      await fetchMore({
        variables: {
          input: {
            ecosystemId: ecosystem.id,
            accountId: activeAccount.id,
            page: page + 1,
            perPage: PER_PAGE,
            filters: {
              integrationPlatform,
              searchText: debouncedSearchText
            }
          }
        },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          const { listings } = fetchMoreResult

          return {
            listings: {
              hasMore: listings.hasMore,
              page: listings.page,
              perPage: listings.perPage,
              total: listings.total,
              totalPages: listings.totalPages,
              data: [...previousResult.listings.data, ...fetchMoreResult.listings.data],
              __typename: 'PaginatedListing'
            }
          }
        }
      })
    } catch (error) {
      console.log(error)
    }
  }

  let pendingReviewListings = listingsData?.filter((l) => l.state === 'PENDING_REVIEW')
  let approvedListings = listingsData?.filter((l) => l.state === 'APPROVED')

  const normalizedListings = listingsData
    .reduce((acc, l) => {
      l.pendingReviewListing = pendingReviewListings?.find((prl) => prl.parentId === l.id)
      l.parentListing =
        approvedListings?.find((pl) => pl.id === l.parentId) ||
        pendingReviewListings?.find((prl) => prl.id === l.parentId)

      if (l.state === 'APPROVED' && l.pendingReviewListing) {
        l.shouldRender = l.pendingReviewListing
      } else {
        l.shouldRender = l
      }
      return [...acc, l]
    }, [])
    .map((l) => l.shouldRender)
    .filter(unique())

  const listingsToRender = ecosystem.listingApprovalEnabled ? normalizedListings : approvedListings

  return (
    <Listings
      ecosystem={ecosystem}
      listings={listingsToRender}
      setSearchText={setSearchText}
      setIntegrationPlatform={setIntegrationPlatform}
      setListingStates={setListingStates}
      hasMore={hasMore}
      page={page}
      total={total}
      loadNextPage={loadNextPage}
      nextPageIsLoading={listingsData && loading}
    />
  )
}

export default memo(ListingsPage)
