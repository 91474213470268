import { Card, CardEmptyState, DeleteDialog } from 'components'
import {
  DndContext,
  DragOverlay,
  KeyboardSensor,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors
} from '@dnd-kit/core'
import React, { forwardRef, useState } from 'react'
import {
  SortableContext,
  arrayMove,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy
} from '@dnd-kit/sortable'
import { makeStyles, withStyles } from '@material-ui/core/styles'

import Button from '@material-ui/core/Button'
import { CSS } from '@dnd-kit/utilities'
import { Capitalize } from 'components/Helpers'
import DragIndicatorIcon from '@material-ui/icons/DragIndicator'
import ItemDialog from './Dialog'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import classNames from 'classnames'
import getLocalizedValue from 'utils/getLocalizedValue'
import { sortBySequence } from 'utils/sortBySequence'

export const useDragStyles = makeStyles((theme) => ({
  draggable: {
    position: 'relative',
    transition: 'transform 250ms ease'
  },
  dragging: {
    zIndex: 1,
    transition: 'none',
    cursor: 'grabbing',
    background: 'white',
    boxShadow: '-1px 0 15px 0 rgba(34, 33, 81, 0.01), 0px 15px 15px 0 rgba(34, 33, 81, 0.25)'
  },
  drop: {
    opacity: 0.4,
    background: `${theme.palette.ui[100]}`
  },
  overlay: {
    display: 'table',
    width: '100%',
    borderRadius: '5px',
    padding: '0px 4px'
  },
  handle: { verticalAlign: 'text-bottom', cursor: 'move' }
}))

const NavigationItems = (props) => {
  const dragClasses = useDragStyles()
  const { classes, locales, handleCreate, handleUpdate, handleDelete, items, itemType } = props
  const [anchorEl, setAnchorEl] = useState(null)
  const [currentItem, setCurrentItem] = useState()
  const [activeId, setActiveId] = useState(null)
  const [loadingItems, setLoadingItems] = useState()
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  )

  const handleClick = (event, item) => {
    setAnchorEl(event.currentTarget)
    setCurrentItem(item)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const deleteLink = async (item) => {
    handleDelete(item).then(() => {
      handleClose()
    })
  }

  const updateAll = async (currentItems) => {
    return Promise.all(
      currentItems.map((item, i) => {
        item.sequence = i
        return handleUpdate(item)
      })
    )
  }

  const handleDragEnd = async (event, currentItems) => {
    const { active, over } = event

    if (active.id !== over.id) {
      const oldIndex = currentItems.map((item) => item.id).indexOf(active.id)
      const newIndex = currentItems.map((item) => item.id).indexOf(over.id)
      const newItems = arrayMove(currentItems, oldIndex, newIndex)
      setLoadingItems(newItems)
      await updateAll(newItems)
      setLoadingItems(null)
      setActiveId(null)
    }
  }

  const sortedItems = loadingItems?.length
    ? loadingItems
    : items?.slice().sort(sortBySequence) ?? []
  const activeItem = items.find((item) => item.id === activeId)

  return (
    <Card
      title={`${itemType} items`}
      button={
        <ItemDialog
          handleSubmit={handleCreate}
          locales={locales}
          itemType={itemType}
          opener={
            <Button variant="contained" className="fr" color="primary">
              + Add
            </Button>
          }
        />
      }
      style={{ marginTop: '24px' }}
    >
      {sortedItems.length > 0 ? (
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell width={10} />
              <TableCell width={100}>Label</TableCell>
              <TableCell>Type</TableCell>
              {itemType === 'Footer' ? <TableCell>Alignment</TableCell> : null}
              <TableCell style={{ textAlign: 'right' }}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody style={{ opacity: loadingItems?.length ? 0.5 : 1 }}>
            <DndContext
              sensors={sensors}
              collisionDetection={closestCenter}
              onDragEnd={(event) => handleDragEnd(event, sortedItems)}
              onDragCancel={() => setActiveId(null)}
              onDragStart={({ active }) => {
                setActiveId(active.id)
              }}
            >
              <SortableContext
                items={sortedItems}
                strategy={verticalListSortingStrategy}
                useDragOverlay={true}
              >
                {sortedItems.map((item) => {
                  return (
                    <SortableTableRow
                      key={item.id}
                      item={item}
                      itemType={itemType}
                      handleClick={handleClick}
                      handleClose={handleClose}
                      anchorEl={anchorEl}
                      currentItem={currentItem}
                      locales={locales}
                      handleCreate={handleCreate}
                      handleUpdate={handleUpdate}
                      handleDelete={deleteLink}
                    />
                  )
                })}
              </SortableContext>
              <DragOverlay>
                {activeItem ? (
                  <Item
                    item={activeItem}
                    itemType={itemType}
                    handleClick={handleClick}
                    handleClose={handleClose}
                    anchorEl={anchorEl}
                    currentItem={currentItem}
                    locales={locales}
                    handleCreate={handleCreate}
                    handleUpdate={handleUpdate}
                    handleDelete={deleteLink}
                    className={classNames(dragClasses.dragging, dragClasses.overlay)}
                  />
                ) : null}
              </DragOverlay>
            </DndContext>
          </TableBody>
        </Table>
      ) : (
        <ItemDialog
          handleSubmit={handleCreate}
          itemType={itemType}
          locales={locales}
          opener={<CardEmptyState>{`Add ${itemType?.toLowerCase()} item`}</CardEmptyState>}
        />
      )}
    </Card>
  )
}

const SortableTableRow = ({
  locales,
  handleCreate,
  handleUpdate,
  handleDelete,
  handleClick,
  handleClose,
  item,
  itemType,
  anchorEl,
  currentItem
}) => {
  const classes = useDragStyles()
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: item.id,
    transition: {
      duration: 150,
      easing: 'cubic-bezier(0.25, 1, 0.5, 1)'
    }
  })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  }

  return (
    <Item
      key={item.id}
      item={item}
      itemType={itemType}
      handleClick={handleClick}
      handleClose={handleClose}
      anchorEl={anchorEl}
      currentItem={currentItem}
      locales={locales}
      handleCreate={handleCreate}
      handleUpdate={handleUpdate}
      handleDelete={handleDelete}
      className={classNames(classes.draggable, isDragging && classes.drop)}
      ref={setNodeRef}
      style={style}
      listeners={listeners}
      attributes={attributes}
    />
  )
}

const Item = forwardRef(function Item(props, ref) {
  const classes = useDragStyles()
  const {
    item,
    locales,
    anchorEl,
    handleClick,
    handleClose,
    handleUpdate,
    handleDelete,
    currentItem,
    itemType,
    listeners,
    className,
    attributes
  } = props

  return (
    <TableRow listeners={listeners} className={className} {...attributes} ref={ref}>
      <TableCell width={10}>
        <DragIndicatorIcon fontSize="small" className={classes.handle} {...listeners} />
      </TableCell>
      <TableCell width={100} className="truncate">
        {getLocalizedValue(item, 'label', locales)}
      </TableCell>
      <TableCell>
        <Capitalize>{item.type.toLowerCase()}</Capitalize>
      </TableCell>
      {item.alignment ? (
        <TableCell>
          <Capitalize>{item.alignment}</Capitalize>
        </TableCell>
      ) : null}
      <TableCell>
        <Button
          className="fr"
          aria-owns={anchorEl ? 'simple-menu' : null}
          aria-haspopup="true"
          onClick={(event) => {
            handleClick(event, item)
          }}
        >
          ...
        </Button>
        <Menu id="simple-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
          <ItemDialog
            handleSubmit={handleUpdate}
            item={currentItem}
            locales={locales}
            opener={<MenuItem>Edit</MenuItem>}
            itemType={itemType.toLowerCase()}
          />
          <DeleteDialog
            handleDelete={handleDelete}
            id={currentItem?.id}
            label={`${itemType.toLowerCase()} item`}
            opener={<MenuItem>Delete</MenuItem>}
            itemType={itemType.toLowerCase()}
          />
        </Menu>
      </TableCell>
    </TableRow>
  )
})

export default withStyles({})(NavigationItems)
