import { Card, CardEmptyState, GridCard, GridContainer } from 'components'
import React, { Fragment } from 'react'

import Button from '@material-ui/core/Button'
import { Link } from 'react-router-dom'
import getLocalizedValue from 'utils/getLocalizedValue'

const CreateProduct = ({ ecosystem, children }) => (
  <Link to={`/ecosystems/${ecosystem.id}/product/create`}>{children}</Link>
)

const Products = ({ products, ecosystem }) => (
  <Fragment>
    <Card
      title="Products"
      button={
        <CreateProduct ecosystem={ecosystem}>
          <Button variant="contained" className="fr" color="primary">
            + Create
          </Button>
        </CreateProduct>
      }
    >
      <div data-testid="ecosystem-listings-products-view">
        {products.length !== 0 ? (
          <GridContainer>
            {products.map((product) => (
              <GridCard
                id={product.id}
                key={product.id}
                title={getLocalizedValue(product, 'name', ecosystem.locales)}
                imageSrc={product.logo ? product.logo.url : null}
                // subtitle={`${product.listings.length} listing(s)`} // TODO use listingCount
                link={`products/${product.id}/overview`}
                sm={4}
                xs={12}
              />
            ))}
          </GridContainer>
        ) : (
          <CardEmptyState>
            {`You've created no products yet. `}
            <CreateProduct ecosystem={ecosystem}>+ Create a product.</CreateProduct>
          </CardEmptyState>
        )}
      </div>
    </Card>
  </Fragment>
)

export default Products
