import * as yup from 'yup'

import {
  CheckboxInput,
  ColorPicker,
  Fieldset,
  FileUploadInput,
  MarkdownTextInput,
  PanelInputWithInfo,
  Small,
  SwitchInput,
  TextInput,
  generateSlugValue
} from 'components'

import React from 'react'

const baseValidationSchema = () => {
  return yup.object().shape({
    slug: yup
      .string()
      .matches(
        /^[a-z0-9]+(?:-[a-z0-9]+)*$/,
        'Must be alphanumeric (A-Z, 0-9) with dashes between words.'
      )
      .required('Slug is a required field'),
    logo: yup.mixed(),
    nativeIntegrationLink: yup.string().url('Native integration must be an URL').nullable(),
    thirdPartyIntegrationLink: yup.string().url('Third party integration must be an URL'),
    zapierId: yup.string(),
    piesyncId: yup.string(),
    blendrId: yup.string(),
    automateId: yup.string(),
    integromatId: yup.string(),
    microsoftFlowId: yup.string(),
    combideskId: yup.string(),
    trayIoId: yup.string()
  })
}

const DESCRIPTIONS = {
  NATIVE_INTEGRATION_LINK: "This will be used for the 'Install' button on the listing page.",
  THIRD_PARTY_INTEGRATION_LINK: "This will be used for the 'Install' button on the listing page."
}

const NameField = ({ formikProps }) => (
  <TextInput
    formikProps={formikProps}
    label="Name"
    field="name"
    placeholder="Dropbox"
    required
    localized="true"
  />
)

const ConnectedSlugField = ({ formikProps, ecosystemUrl }) => (
  <TextInput
    formikProps={formikProps}
    label="Slug (autogenerated)"
    field="slug"
    prefix={ecosystemUrl}
    placeholder="dropbox"
    description="The slug will be used to compose the url."
    value={(formikProps.values['slug'] = generateSlugValue(formikProps, 'name'))}
    readOnly
  />
)

const SlugField = ({ formikProps, ecosystemUrl }) => (
  <TextInput
    formikProps={formikProps}
    label="Slug"
    field="slug"
    prefix={ecosystemUrl}
    placeholder="dropbox"
    description="The slug will be used to compose the url."
  />
)

const WebsiteField = ({ formikProps }) => (
  <TextInput
    formikProps={formikProps}
    label="Website"
    field="websiteLink"
    placeholder="https://www.dropbox.com"
  />
)

const TagLineField = ({ formikProps }) => (
  <TextInput
    formikProps={formikProps}
    label="Tag Line"
    field="tagLine"
    description="Describe the primary use case of the integration."
    id="tagline"
    localized="true"
  />
)

const LogoField = ({ formikProps }) => (
  <FileUploadInput
    formikProps={formikProps}
    label="Logo"
    field="logo"
    description="Square icon. Ideal dimensions 400x400px."
    multiple={false}
  />
)

const StickyField = ({ formikProps }) => (
  <PanelInputWithInfo
    leftBloc={<CheckboxInput formikProps={formikProps} label="Prioritize" field="sticky" />}
    rightBloc={<Small>Prioritize this listing before other listings on your ecosystem</Small>}
  />
)

const DetailPageDisabled = ({ formikProps }) => (
  <PanelInputWithInfo
    leftBloc={
      <CheckboxInput
        formikProps={formikProps}
        label="Disable detail page"
        field="detailPageDisabled"
      />
    }
    rightBloc={<Small>Link directly to the first defined link</Small>}
  />
)

const UpcomingField = ({ formikProps, disabled }) => (
  <PanelInputWithInfo
    leftBloc={
      <CheckboxInput
        formikProps={formikProps}
        label="Upcoming"
        field="upcoming"
        disabled={disabled}
      />
    }
    rightBloc={
      <Small>
        Mark listing as coming soon. The detail page will not be shown when this is checked.
      </Small>
    }
  />
)

const PublishedField = ({ formikProps }) => (
  <PanelInputWithInfo
    leftBloc={<CheckboxInput formikProps={formikProps} label="Publish" field="published" />}
    rightBloc={<Small>Publish the listing to your ecosystem.</Small>}
  />
)

const BasicFieldset = ({ formikProps, ecosystemName, ecosystemUrl }) => (
  <Fieldset title="Basic info">
    <NameField formikProps={formikProps} />
    <ConnectedSlugField formikProps={formikProps} ecosystemUrl={ecosystemUrl} />
    <TagLineField formikProps={formikProps} ecosystemName={ecosystemName} />
    <LogoField formikProps={formikProps} />
    <StickyField formikProps={formikProps} />
    <UpcomingField formikProps={formikProps} />
  </Fieldset>
)

const ExternalLinksFieldset = ({ formikProps }) => (
  <Fieldset title="External links">
    <TextInput formikProps={formikProps} label="Terms" field="termsLink" />
    <TextInput formikProps={formikProps} label="Privacy policy" field="privacyLink" />
    <TextInput formikProps={formikProps} label="Support" field="supportLink" />
  </Fieldset>
)

const StyleFieldset = ({ formikProps }) => (
  <Fieldset title="Style listing card">
    <ColorPicker
      formikProps={formikProps}
      label="Card background color"
      field="cardBackgroundColor"
    />
    <FileUploadInput
      formikProps={formikProps}
      label="Card background image"
      field="cardBackgroundImage"
      description="Square icon. Ideal dimensions 400x400px."
      multiple={false}
    />
  </Fieldset>
)

const IntegrationLinksFieldset = ({ formikProps, ecosystem }) => {
  return (
    <Fieldset title="What kind of listing is this?">
      <PanelInputWithInfo
        leftBloc={'Native integration'}
        rightBloc=""
        rightBlocAddon={<SwitchInput formikProps={formikProps} field="nativeIntegration" />}
      />
      <TextInput
        description={DESCRIPTIONS.NATIVE_INTEGRATION_LINK}
        field="nativeIntegrationLink"
        formikProps={formikProps}
        placeholder={'URL that should be used for the native integration install button'}
      />
      <TextInput
        description="External ID used for syncing listings with an external system."
        field="externalId"
        formikProps={formikProps}
        placeholder={'ID used for syncing listing to an external system'}
      />
      <TextInput
        description="ID of the Unify connector linked to this listing."
        field="unifyConnectorId"
        formikProps={formikProps}
        placeholder="crm+salesforce"
      />
      <PanelInputWithInfo
        leftBloc={'Third-party integration'}
        rightBloc=""
        rightBlocAddon={<SwitchInput formikProps={formikProps} field="thirdPartyIntegration" />}
      />
      <TextInput
        description={DESCRIPTIONS.THIRD_PARTY_INTEGRATION_LINK}
        field="thirdPartyIntegrationLink"
        formikProps={formikProps}
        placeholder={'URL that should be used for the third party integration install button'}
      />
      {ecosystem.zapierEnabled && (
        <TextInput
          formikProps={formikProps}
          label="Zapier Identifier"
          field="zapierId"
          disabled={ecosystem && !ecosystem.zapierEnabled}
        />
      )}
      {ecosystem.piesyncEnabled && (
        <TextInput
          formikProps={formikProps}
          label="PieSync Identifier"
          field="piesyncId"
          disabled={ecosystem && !ecosystem.piesyncEnabled}
        />
      )}
      {ecosystem.blendrEnabled && (
        <TextInput
          formikProps={formikProps}
          label="Blendr.io Identifier"
          field="blendrId"
          disabled={ecosystem && !ecosystem.blendrEnabled}
        />
      )}
      {ecosystem.automateEnabled && (
        <TextInput
          formikProps={formikProps}
          label="automate.io Identifier"
          field="automateId"
          disabled={ecosystem && !ecosystem.automateEnabled}
        />
      )}
      {ecosystem.segmentEnabled && (
        <TextInput
          formikProps={formikProps}
          label="Segment Identifier"
          field="segmentId"
          disabled={ecosystem && !ecosystem.segmentEnabled}
        />
      )}
      {ecosystem.microsoftFlowEnabled && (
        <TextInput
          formikProps={formikProps}
          label="Microsoft Flow Identifier"
          field="microsoftFlowId"
          disabled={ecosystem && !ecosystem.microsoftFlowEnabled}
        />
      )}
      {ecosystem.combideskEnabled && (
        <TextInput
          formikProps={formikProps}
          label="Combidesk Identifier"
          field="combideskId"
          disabled={ecosystem && !ecosystem.combideskEnabled}
        />
      )}
      {ecosystem.trayIoEnabled && (
        <TextInput
          formikProps={formikProps}
          label="Tray.io Identifier"
          field="trayIoId"
          disabled={ecosystem && !ecosystem.trayIoEnabled}
        />
      )}
      {ecosystem.integromatEnabled && (
        <TextInput
          formikProps={formikProps}
          label="Integromat Identifier"
          field="integromatId"
          disabled={ecosystem && !ecosystem.integromatEnabled}
        />
      )}
    </Fieldset>
  )
}

const PageContentFieldset = ({ formikProps, ecosystem }) => (
  <Fieldset title="Page content">
    <MarkdownTextInput
      formikProps={formikProps}
      label="Description"
      field="description"
      tagline="description"
      localized="true"
      ecosystem={ecosystem}
    />
    <MarkdownTextInput
      formikProps={formikProps}
      label="Features"
      field="features"
      id="features"
      localized="true"
      ecosystem={ecosystem}
    />
    <MarkdownTextInput
      formikProps={formikProps}
      label="Pricing"
      field="pricing"
      id="pricing"
      localized="true"
      ecosystem={ecosystem}
    />
  </Fieldset>
)

const SeoFieldset = ({ formikProps }) => (
  <Fieldset title="SEO & meta tags">
    <TextInput formikProps={formikProps} label="Title" field="metaTagTitle" localized="true" />
    <TextInput
      formikProps={formikProps}
      label="Description"
      field="metaTagDescription"
      localized="true"
    />
    <TextInput
      formikProps={formikProps}
      label="Keywords"
      field="metaTagKeywords"
      localized="true"
    />
  </Fieldset>
)

export {
  BasicFieldset,
  ExternalLinksFieldset,
  IntegrationLinksFieldset,
  PageContentFieldset,
  SeoFieldset,
  baseValidationSchema,
  NameField,
  SlugField,
  ConnectedSlugField,
  LogoField,
  TagLineField,
  WebsiteField,
  StickyField,
  UpcomingField,
  PublishedField,
  DetailPageDisabled,
  StyleFieldset
}
