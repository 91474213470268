import { Legend, Pie, PieChart, Tooltip } from 'recharts'

import { CardNoData } from 'components'
import DashboardCard from './Card'
import React from 'react'
import { useQuery } from 'react-apollo'
import { colors } from 'styles/styles'
import { listingsQuery } from 'api/queries'

const aggregateListings = (ecosystem, listings) => {
  const integrationTypes = []

  let nativeIntegrations = 0
  let thirdPartyIntegrations = 0
  let zapierIntegrations = 0
  let piesyncIntegrations = 0
  let microsoftFlowIntegrations = 0
  let segmentIntegrations = 0
  let combideskIntegrations = 0
  let automateIntegrations = 0
  let trayIoIntegrations = 0

  listings.forEach((listing) => {
    listing.nativeIntegration && nativeIntegrations++
    listing.thirdPartyIntegration && thirdPartyIntegrations++
    listing.zapierId && zapierIntegrations++
    listing.piesyncId && piesyncIntegrations++
    listing.microsoftFlowId && microsoftFlowIntegrations++
    listing.segmentId && segmentIntegrations++
    listing.combideskId && combideskIntegrations++
    listing.automateId && automateIntegrations++
    listing.trayIoId && trayIoIntegrations++
  })

  integrationTypes.push({
    name: 'Native',
    value: nativeIntegrations,
    fill: ecosystem.primaryColor
  })
  integrationTypes.push({
    name: 'Third-party',
    value: thirdPartyIntegrations
  })

  ecosystem.automateEnabled &&
    integrationTypes.push({
      name: 'Automate',
      value: automateIntegrations,
      fill: colors.automate
    })
  ecosystem.zapierEnabled &&
    integrationTypes.push({
      name: 'Zapier',
      value: zapierIntegrations,
      fill: colors.zapier
    })
  ecosystem.piesyncEnabled &&
    integrationTypes.push({
      name: 'Piesync',
      value: piesyncIntegrations,
      fill: colors.piesync
    })
  ecosystem.microsoftFlowEnabled &&
    integrationTypes.push({
      name: 'Microsoft Flow',
      value: microsoftFlowIntegrations,
      fill: colors.microsoftFlow
    })
  ecosystem.segmentEnabled &&
    integrationTypes.push({
      name: 'Segment',
      value: segmentIntegrations,
      fill: colors.segment
    })
  ecosystem.combideskEnabled &&
    integrationTypes.push({
      name: 'Combidesk',
      value: combideskIntegrations,
      fill: colors.combidesk
    })
  ecosystem.trayIoEnabled &&
    integrationTypes.push({
      name: 'Tray.io',
      value: trayIoIntegrations,
      fill: colors.trayIo
    })

  return integrationTypes
}

const IntegrationsTypesCard = ({ accountId, ecosystem }) => {
  const { loading, error, data } = useQuery(listingsQuery, {
    variables: {
      id: ecosystem.id,
      accountId
    }
  })

  if (error) {
    return `Error retrieving integrations ${error.message}`
  }

  if (loading) {
    return <CardNoData />
  }
  const {
    marketplace: { listings }
  } = data
  const integrationTypes = aggregateListings(ecosystem, listings)
  const barColor = '#8884d8'

  return (
    <DashboardCard title="Integration types" size={4} height="280px">
      {integrationTypes.length > 0 ? (
        <PieChart width={320} height={230}>
          <Pie
            data={integrationTypes}
            dataKey="value"
            nameKey="name"
            cx="50%"
            cy="50%"
            innerRadius={50}
            outerRadius={80}
            fill={barColor}
          />
          <Tooltip />
          <Legend />
        </PieChart>
      ) : (
        <CardNoData />
      )}
    </DashboardCard>
  )
}

export default IntegrationsTypesCard
