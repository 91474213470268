import gql from 'graphql-tag'
import { marketplaceFieldsForSettings } from './queries'

export const ecosystemSettingsQuery = gql`
  query marketplaceQuery($id: ID!, $accountId: ID!) {
    marketplace(id: $id, accountId: $accountId) {
      ...marketplaceFieldsForSettings
    }
  }
  ${marketplaceFieldsForSettings}
`
