import { LoadingPlaceholder, ProductLayout, ErrorFallback } from 'components'
import { productOverviewQuery } from 'pages/private/ecosystem/listings/products/graphql'
import PropTypes from 'prop-types'
import React from 'react'
import { Query } from 'react-apollo'

// TODO move to pages folder

const ProductContainer = ({ activeAccount, match, ecosystem }) => (
  <Query
    query={productOverviewQuery}
    variables={{
      accountId: activeAccount.id,
      ecosystemId: ecosystem.id,
      productId: match.params.productId
    }}
  >
    {({ loading, error, data }) => {
      if (loading) return <LoadingPlaceholder />
      if (error) return <ErrorFallback error={error} />

      const { marketplace, product } = data
      const { listings } = marketplace

      return (
        <ProductLayout
          activeAccount={activeAccount}
          product={product}
          listings={listings}
          ecosystem={ecosystem}
        />
      )
    }}
  </Query>
)

ProductContainer.propTypes = {
  activeAccount: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired
}

export default ProductContainer
