import { catalogServiceQuery, deleteListingMutation, importServiceMutation } from 'api/queries'
import { CatalogService, ErrorFallback, LoadingPlaceholder } from 'components'
import gql from 'graphql-tag'
import find from 'lodash/find'
import React from 'react'
import { graphql, useQuery } from 'react-apollo'
import { toastr } from 'react-redux-toastr'
import { compose } from 'recompose'

const listingsQuery = gql`
  query marketplace($id: ID!, $accountId: ID!) {
    marketplace(id: $id, accountId: $accountId) {
      id
      listings {
        id
        slug
      }
    }
  }
`

// TODO move to pages folder

const Container = ({ data, ecosystem, activeAccount, deleteListing, importService }) => {
  const { service } = data
  const {
    loading,
    error,
    data: marketplaceData
  } = useQuery(listingsQuery, {
    variables: {
      id: ecosystem.id,
      accountId: activeAccount.id
    }
  })

  if (loading || !service) return <LoadingPlaceholder />
  if (error) return <ErrorFallback error={error} />

  const { marketplace } = marketplaceData
  const { listings } = marketplace
  const listing = find(listings, (listing) => listing.slug === service.id)
  const handleServiceClick = async (id) => {
    try {
      const existingListing = listings.find((l) => l.slug === id)

      if (existingListing) {
        await deleteListing({
          variables: {
            id: existingListing.id,
            accountId: activeAccount.id
          },
          refetchQueries: ['marketplace']
        })

        toastr.success(`${existingListing.slug} has been removed`)
      } else {
        if (!service) {
          toastr.success(`${service.name} could not be found`)
        }

        await importService({
          variables: {
            accountId: activeAccount.id,
            ecosystemId: marketplace.id,
            id: id
          },
          refetchQueries: ['marketplace']
        })

        toastr.success(`${service.name} has been imported`)
      }
    } catch (error) {
      toastr.error('Could not add/remove service')
      throw new Error(error.message)
    }
  }

  return (
    <CatalogService
      loading={loading}
      error={error}
      service={service}
      listing={listing}
      ecosystem={ecosystem}
      handleServiceClick={handleServiceClick}
    />
  )
}

const CloudCatalogContainer = compose(
  graphql(catalogServiceQuery, {
    options: ({ match }) => ({
      fetchPolicy: 'network-only',
      variables: { id: match.params.service }
    })
  }),
  graphql(importServiceMutation, {
    name: 'importService'
  }),
  graphql(deleteListingMutation, {
    name: 'deleteListing'
  })
)(Container)

export default CloudCatalogContainer
