import React from 'react'
import PropTypes from 'prop-types'
import TemplateForm from './Form'

function Overview(props) {
  return <TemplateForm {...props} />
}

Overview.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
  ecosystem: PropTypes.object.isRequired
}

export default Overview
