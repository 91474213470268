import React from 'react'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import { IntegrationPlatformsFieldset } from 'components/ecosystem/forms/Base'
import { CardAlert, ExternalA, PrimaryButton } from 'components'

export const IntegrationPlatformsForm = ({
  initialValues,
  handleToggle,
  handleSubmit,
  ecosystem,
  service,
  handleComplete
}) => {
  const foundIntegrationPlatforms =
    initialValues.zapierEnabled ||
    initialValues.piesyncEnabled ||
    initialValues.segmentEnabled ||
    initialValues.blendrEnabled ||
    initialValues.automateEnabled ||
    initialValues.integromatEnabled ||
    initialValues.microsoftFlowEnabled

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit} handleToggle={handleToggle}>
      {(formikProps) => {
        const { errors } = formikProps

        return (
          <form>
            {errors.general && <span className="errors">{errors.general}</span>}
            <IntegrationPlatformsFieldset
              formikProps={formikProps}
              handleToggle={handleToggle}
              button={<PrimaryButton onClick={handleComplete}>Next</PrimaryButton>}
            >
              <CardAlert.Default>
                <p>
                  An integration platform is software which integrates different applications and
                  services. Integration platforms like Automate.io or Zapier can serve as a proxy to
                  seed your ecosystem.
                </p>
              </CardAlert.Default>
              <CardAlert.Default>
                {service && foundIntegrationPlatforms ? (
                  <p>
                    The Apideck Network detected the following integration platforms by scanning{' '}
                    <ExternalA href={ecosystem.website}>{ecosystem.website}</ExternalA>.
                  </p>
                ) : (
                  <p>Are you integrated with any of the integration platforms below?</p>
                )}
              </CardAlert.Default>
            </IntegrationPlatformsFieldset>
          </form>
        )
      }}
    </Formik>
  )
}

IntegrationPlatformsForm.propTypes = {
  handleToggle: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  service: PropTypes.object
}

export default IntegrationPlatformsForm
