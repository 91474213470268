import Tooltip from '@material-ui/core/Tooltip'
import { Header, PrimaryButton, SecondaryButton } from 'components'
import React from 'react'
import { withRouter } from 'react-router-dom'

const LayoutHeader = ({
  title,
  description,
  btnName,
  btnTargetBlank,
  btnDisabled,
  btnTooltip,
  url,
  history,
  goBack,
  right
}) => {
  return (
    <Header>
      <div className="fl">
        <h1>{title}</h1>
        <p>{description}</p>
      </div>

      {goBack && (
        <SecondaryButton className="fr" onClick={history.goBack}>
          Go Back
        </SecondaryButton>
      )}

      {btnDisabled && btnTooltip && (
        <Tooltip title={btnTooltip} interactive placement="left" arrow>
          <div>
            <PrimaryButton
              className="fr"
              disabled={btnDisabled}
              href={url}
              target={btnTargetBlank ? '_blank' : '_self'}
            >
              {btnName}
            </PrimaryButton>
          </div>
        </Tooltip>
      )}

      {btnDisabled && !btnTooltip && (
        <PrimaryButton
          className="fr"
          disabled={btnDisabled}
          href={url}
          target={btnTargetBlank ? '_blank' : '_self'}
        >
          {btnName}
        </PrimaryButton>
      )}

      {!btnDisabled && btnName && url && (
        <PrimaryButton className="fr" href={url} target={btnTargetBlank ? '_blank' : '_self'}>
          {btnName}
        </PrimaryButton>
      )}

      {right && <div className="fr">{right}</div>}
    </Header>
  )
}

export default withRouter(LayoutHeader)
