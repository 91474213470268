import Avatar from '@material-ui/core/Avatar'
import Box from '@material-ui/core/Box'
import Divider from '@material-ui/core/Divider'
import Drawer from '@material-ui/core/Drawer'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemText from '@material-ui/core/ListItemText'
import { withStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import HelpIcon from '@material-ui/icons/Help'
import PhoneInTalkIcon from '@material-ui/icons/PhoneInTalk'
import { ExternalA } from 'components'
import truncate from 'lodash/truncate'
import React, { Fragment } from 'react'
import { compose } from 'recompose'
import slugify from 'slugify'
import timeAgo from 'time-ago'

const styles = (theme) => ({
  list: {
    width: '360px'
  },
  title: {
    fontSize: '18px',
    fontWeight: '500'
  },
  subtitle: {
    fontSize: '12px',
    opacity: 0.6
  },
  label: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontWeight: '500',
    padding: '3px 0',
    fontSize: '12px',
    opacity: 0.8
  },
  value: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    fontSize: '14px'
  },
  avatar: {
    width: theme.spacing(4),
    height: theme.spacing(4)
  },
  phoneIcon: {
    fontSize: '18px',
    marginRight: theme.spacing(0.5),
    color: theme.palette.ui[300]
  }
})

const LeadDrawer = ({ drawerOpen, toggleDrawer, classes, activeLead, ecosystem }) => {
  const listingNaming = (ecosystem?.listingNaming ?? 'LISTINGS').toLowerCase()
  const {
    leadTelephoneFieldEnabled,
    leadFirstNameFieldEnabled,
    leadLastNameFieldEnabled,
    installationRequestFlowEnabled
  } = ecosystem

  return (
    <Drawer
      anchor="right"
      open={drawerOpen}
      onClose={() => toggleDrawer(activeLead)}
      BackdropProps={{ invisible: true }}
    >
      {activeLead && (
        <Fragment>
          <Box m={2}>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item>
                <Typography className={classes.title}>Lead</Typography>
                <Typography className={classes.subtitle}>Listing Request</Typography>
              </Grid>
              <Grid item>
                <Tooltip title="Or press esc">
                  <IconButton
                    size="medium"
                    aria-label="close"
                    onClick={() => toggleDrawer(activeLead)}
                  >
                    <CloseIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </Box>
          <Divider />
          <List className={classes.list}>
            {(leadFirstNameFieldEnabled || leadLastNameFieldEnabled) && (
              <ListItem>
                <ListItemText
                  primary={<Typography className={classes.label}>Name</Typography>}
                  secondary={
                    <Typography className={classes.value}>
                      {activeLead.firstName} {activeLead.lastName}
                    </Typography>
                  }
                />
              </ListItem>
            )}
            <ListItem>
              <ListItemText
                primary={<Typography className={classes.label}>Email</Typography>}
                secondary={<Typography className={classes.value}>{activeLead.email}</Typography>}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={<Typography className={classes.label}>ID</Typography>}
                secondary={<Typography className={classes.value}>{activeLead.id}</Typography>}
              />
            </ListItem>
            {leadTelephoneFieldEnabled && activeLead.telephone && (
              <ListItem>
                <ListItemText
                  primary={<Typography className={classes.label}>Telephone</Typography>}
                  secondary={
                    <Typography className={classes.value}>
                      <PhoneInTalkIcon className={classes.phoneIcon} />
                      <a href={`tel:${activeLead.telephone}`}>{activeLead.telephone}</a>
                    </Typography>
                  }
                />
              </ListItem>
            )}
            {installationRequestFlowEnabled && (
              <ListItem>
                <ListItemText
                  primary={<Typography className={classes.label}>Installation request?</Typography>}
                  secondary={
                    <Typography className={classes.value}>
                      {activeLead.installationRequest ? 'Yes' : 'No'}
                    </Typography>
                  }
                />
              </ListItem>
            )}
            <ListItem>
              <ListItemText
                primary={<Typography className={classes.label}>Is customer?</Typography>}
                secondary={
                  <Typography className={classes.value}>
                    {activeLead.isCustomer ? 'Yes' : 'No'}
                  </Typography>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={<Typography className={classes.label}>Created</Typography>}
                secondary={
                  <Typography className={classes.value}>
                    {timeAgo.ago(activeLead.createdAt)}
                  </Typography>
                }
              />
            </ListItem>
            {activeLead.referer && (
              <ListItem>
                <ListItemText
                  primary={
                    <Typography className={classes.label}>
                      <span>Referrer</span>
                      <Tooltip title="The last web address accessed by a browser prior to loading the form, used to identify sources of traffic.">
                        <IconButton aria-label="help" edge="end" size="small">
                          <HelpIcon style={{ fontSize: '20px' }} />
                        </IconButton>
                      </Tooltip>
                    </Typography>
                  }
                  secondary={
                    <Typography className={classes.value}>
                      <ExternalA href={activeLead.referer}>
                        {truncate(activeLead.referer, { length: 45 })}
                      </ExternalA>
                    </Typography>
                  }
                />
              </ListItem>
            )}
            {activeLead.srcUrl && (
              <ListItem>
                <ListItemText
                  primary={
                    <Typography className={classes.label}>
                      <span>Source URL</span>
                      <Tooltip title="URL from where the request form was completed">
                        <IconButton aria-label="help" edge="end" size="small">
                          <HelpIcon style={{ fontSize: '20px' }} />
                        </IconButton>
                      </Tooltip>
                    </Typography>
                  }
                  secondary={
                    <Typography className={classes.value}>
                      <a href={activeLead.srcUrl} target="_blank" rel="noopener noreferrer">
                        {truncate(activeLead.srcUrl, { length: 45 })}
                      </a>
                    </Typography>
                  }
                />
              </ListItem>
            )}
            {activeLead.notes && (
              <ListItem>
                <ListItemText
                  primary={<Typography className={classes.label}>Notes</Typography>}
                  secondary={<Typography className={classes.value}>{activeLead.notes}</Typography>}
                />
              </ListItem>
            )}
            <ListItem>
              <ListItemText
                primary={
                  <Typography className={classes.label}>Requested {listingNaming}</Typography>
                }
              />
            </ListItem>
            {activeLead.requestedServices.map((service) => (
              <ListItem key={service}>
                <ListItemAvatar>
                  <Avatar
                    variant="rounded"
                    src={`https://res.cloudinary.com/apideck/icons/${slugify(
                      service
                    ).toLowerCase()}`}
                    className={classes.avatar}
                  >
                    {service.substring(0, 1)}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={<Typography className={classes.value}>{service}</Typography>}
                />
              </ListItem>
            ))}
          </List>
        </Fragment>
      )}
    </Drawer>
  )
}

export default compose(withStyles(styles))(LeadDrawer)
