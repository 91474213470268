import React from 'react'
import PropTypes from 'prop-types'
import { Mutation } from 'react-apollo'
import { updateIntegrationPlatformsSettingsMutation, marketplaceQuery } from 'api/queries'
import { adopt } from 'react-adopt'

const updateIntegrationPlatformsSettingsInStore = (store, { ecosystem, accountId }) => {
  const data = store.readQuery({
    query: marketplaceQuery,
    variables: {
      id: ecosystem.id,
      accountId
    }
  })

  data.ecosystem = ecosystem

  store.writeQuery({
    query: marketplaceQuery,
    data,
    variables: {
      id: ecosystem.id,
      accountId
    }
  })
}

function updateIntegrationPlatformsSettings({ accountId, render }) {
  return (
    <Mutation
      mutation={updateIntegrationPlatformsSettingsMutation}
      update={(cache, { data: { updateMarketplace: ecosystem } }) => {
        updateIntegrationPlatformsSettingsInStore(cache, {
          accountId,
          ecosystem
        })
      }}
    >
      {(mutation, result) => render({ mutation, result })}
    </Mutation>
  )
}

const TemplateContainer = adopt({
  updateIntegrationPlatformsSettings
})

TemplateContainer.propTypes = {
  accountId: PropTypes.string.isRequired
}

export default TemplateContainer
