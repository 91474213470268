import { Bar, BarChart, CartesianGrid, Tooltip, XAxis, YAxis } from 'recharts'

import { CardNoData } from 'components'
import DashboardCard from './Card'
import React from 'react'

const barColor = '#8884d8'

const VisitCard = ({ title, data, loading }) => (
  <DashboardCard title={title} size={12} loading={loading}>
    {data && data.length > 0 ? (
      <BarChart
        width={1120}
        height={230}
        data={data}
        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
        barCategoryGap={8}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="date" />
        <YAxis />
        <Tooltip />
        <Bar dataKey="count" name="Visits" fill={barColor} />
      </BarChart>
    ) : (
      <CardNoData />
    )}
  </DashboardCard>
)

export default VisitCard
