import React from 'react'
import PropTypes from 'prop-types'
import { PartnerForm } from '../Form'

function PartnerSettings({ handleUpdatePartner, ...rest }) {
  return <PartnerForm action="save" handleSubmit={handleUpdatePartner} {...rest} />
}

PartnerSettings.propTypes = {
  initialValues: PropTypes.object.isRequired,
  handleUpdatePartner: PropTypes.func.isRequired,
  listings: PropTypes.array.isRequired
}

export default PartnerSettings
