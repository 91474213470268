import { HeaderTitle, HeaderWithTabs, SecondaryButton } from 'components'

import PartnerNavigation from './Navigation'
import PropTypes from 'prop-types'
import React from 'react'
import { useHistory } from 'react-router-dom'

function PartnerHeader({ partner }) {
  const history = useHistory()
  const imageUrl = partner.icon ? partner.icon.url : null

  return (
    <HeaderWithTabs tabs={<PartnerNavigation />}>
      <HeaderTitle
        title={partner.company}
        subtitle="Partner"
        imageTitle={partner.company}
        imageSrc={imageUrl}
      />
      <SecondaryButton className="fr" onClick={history.goBack}>
        Go Back
      </SecondaryButton>
    </HeaderWithTabs>
  )
}

PartnerHeader.propTypes = {
  name: PropTypes.string
}

export default PartnerHeader
