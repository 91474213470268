import React, { Fragment } from 'react'

import GithubIcon from 'assets/github-white.svg'
import GoogleIcon from 'assets/google-white.svg'
import styled from 'react-emotion'

const GithubBtn = styled('a')`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  font-weight: 400;
  background-color: #333;
  color: var(--color-white);
  min-width: 320px;
  height: 56px;
  border-radius: 4px;
  line-height: 1;
  font-size: 18px;
  font-weight: 500;
  background-size: 200% auto;
  transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);

  &:hover {
    background-position: 100% 0;
  }
`

const GoogleBtn = styled('a')`
  background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  min-width: 320px;
  height: 56px;
  border-radius: 4px;
  line-height: 1;
  font-size: 18px;
  font-weight: 500;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

  .label {
    font-family: 'Roboto';
    color: rgba(0, 0, 0, 0.54);
  }
`

const Icon = styled('div')`
  padding-right: 18px;

  img {
    width: 24px;
    height: 24px;
  }
`

const GithubButton = ({ label, img, onClick }) => {
  const handleClick = (e) => {
    e.preventDefault()
    onClick()
  }

  return (
    <GithubBtn href="#" onClick={(e) => handleClick(e)}>
      <Icon>
        <img alt={label} src={img} />
      </Icon>
      <div className="label">{label}</div>
    </GithubBtn>
  )
}

const GoogleButton = ({ label, onClick }) => {
  const handleClick = (e) => {
    e.preventDefault()
    onClick()
  }

  return (
    <GoogleBtn href="#" onClick={(e) => handleClick(e)}>
      <Icon>
        <img alt={label} src="/images/logos/google@2x.png" />
      </Icon>
      <div className="label">{label}</div>
    </GoogleBtn>
  )
}

const SocialButtons = ({ handleGoogleSubmit, handleGithubSubmit, action }) => (
  <Fragment>
    <GoogleButton onClick={handleGoogleSubmit} img={GoogleIcon} label={`${action} with Google`} />
    <GithubButton onClick={handleGithubSubmit} label={`${action} with Github`} img={GithubIcon} />
  </Fragment>
)

export default SocialButtons
