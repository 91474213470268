import React from 'react'
import styled from 'react-emotion'

const Container = styled('div')`
  font-size: 16px;
  font-weight: 500;

  ul {
    list-style: none;
    padding: 0;
    margin-top: 30px;
    font-size: 15px;
    font-weight: 400;
    color: var(--sidebar-text-color);

    li {
      margin-bottom: 3px;
    }
  }
`

const SignupSidebarContent = ({ product }) => (
  <Container>
    {product === 'ecosystem' && 'Free 14 days trial'}
    <ul>
      <li>+ No credit card required.</li>
      <li>+ Unlimited access to all features.</li>
      <li>+ Full access to our support team.</li>
    </ul>
  </Container>
)

export default SignupSidebarContent
