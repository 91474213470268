import Grid from '@material-ui/core/Grid'
import { CardIcon } from 'components'
import React from 'react'
import styled from 'react-emotion'
import { Link } from 'react-router-dom'

const ContentWrapper = styled('div')`
  width: 100%;
  display: block;
  padding: 40px 25px 0 25px;
  text-decoration: none;
  text-align: center;
  position: relative;
  background: #fff;
  box-sizing: border-box;
  transform: translateZ(0);
  box-shadow: 0px 1.5px 3px 0 rgba(225, 227, 229, 0.6);
  border-radius: 8px;
  border: thin solid #e9ebed;
  transition: all 0.2s ease-in-out;

  :hover {
    border-color: #087cff;
  }

  .badges {
    visibility: hidden;
  }

  :hover .badges {
    visibility: visible;
  }

  .title {
    color: #666;
    font-size: 15px;
    font-weight: 400;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .subtitle {
    font-size: 14px;
    color: rgba(0, 56, 108, 0.5);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin-bottom: 10px;
  }

  .action {
    opacity: 0;
    transition: all 0.2s ease-in-out;
    color: #087cff;
  }

  :hover .action {
    opacity: 1;
  }

  .icon {
    margin-bottom: 10px;
    line-height: 1.15em;
  }

  .icon img {
    max-width: 120px;
    border-radius: 5px;
  }

  .dimmed {
    filter: grayscale(100%);
    opacity: 0.2;
  }

  p {
    line-height: 1.15em;
  }
`

const Upcoming = styled('div')`
  transform: rotate(37deg);
  font-size: 12px;
  font-weight: 700;
  line-height: normal;
  text-align: center;
  color: #ffffff;
  text-transform: uppercase;
  padding: 5px 100px;
  position: absolute;
  right: -90px;
  top: 20px;
  z-index: 2;
  background-color: var(--color-secondary);
`
const State = styled('div')`
  font-size: 12px;
  font-weight: 700;
  text-align: center;
  color: #854d0e;
  padding: 3px 0px;
  border-radius: 20px;
  background-color: #fef9c3;
  margin: -6px 8px 12px;
`

const GridCard = ({ link, handleClick, id, sm, xs, ...rest }) => {
  if (link) {
    return (
      <Grid item className="grid-card" sm={sm} xs={xs}>
        <div style={{ overflow: 'hidden', position: 'relative' }}>
          <Link to={link} className="link">
            <Content {...rest} />
          </Link>
        </div>
      </Grid>
    )
  }

  if (handleClick) {
    return (
      <Grid item className="grid-card" sm={sm} xs={xs}>
        <div onClick={() => handleClick(id)} style={{ cursor: 'pointer' }}>
          <Content {...rest} />
        </div>
      </Grid>
    )
  }

  return (
    <Grid item className="grid-card" sm={sm} xs={xs}>
      <Content {...rest} />
    </Grid>
  )
}

const Content = ({
  title,
  subtitle,
  imageSrc,
  action = 'View Details',
  small = false,
  integration,
  style,
  hideAction,
  upcoming,
  state,
  children
}) => (
  <ContentWrapper style={style}>
    {integration && children}
    {upcoming && <Upcoming>Upcoming</Upcoming>}
    <CardIcon imageSrc={imageSrc} title={title} small={small} />
    <div className="title">{title}</div>
    <div className="subtitle">{subtitle}</div>
    {state === 'PENDING_REVIEW' && (
      <State>
        <div className="state_pending">{'Pending Review'}</div>
      </State>
    )}

    {state === 'DRAFT' && (
      <State>
        <div className="state_draft">{'DRAFT'}</div>
      </State>
    )}

    {!hideAction && !['PENDING_REVIEW', 'DRAFT'].includes(state) && (
      <div className="action">
        <p>{action}</p>
      </div>
    )}
  </ContentWrapper>
)

export default GridCard
