import React from 'react'
import { Link } from 'react-router-dom'
import { Tabs, Tab } from 'components'

const Navigation = ({ match }) => (
  <Tabs
    selectors={[
      {
        tab: 0,
        path: `${match.url}/overview`,
        exact: true
      },
      {
        tab: 1,
        path: `${match.url}/settings`,
        exact: true
      }
    ]}
  >
    <Tab label="Overview" component={Link} to={`${match.url}/overview`} />
    <Tab label="Settings" component={Link} to={`${match.url}/settings`} />
  </Tabs>
)

export default Navigation
