import Avatar from '@material-ui/core/Avatar'
import deepPurple from '@material-ui/core/colors/deepPurple'
import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import { compose } from 'recompose'

const styles = {
  purpleAvatar: {
    color: '#fff',
    backgroundColor: deepPurple[500],
    borderRadius: 5
  }
}

const sentenceToAcronym = (sentence) => {
  if (sentence) {
    const matched = sentence.match(/\b(\w)/g)

    if (matched) {
      return matched.join('')
    }
  }
}

const LetterAvatar = ({ classes, title, size = 48, ...rest }) => (
  <Avatar
    className={classNames(classes.purpleAvatar)}
    style={{ width: `${size}px`, height: `${size}px` }}
    {...rest}
  >
    {sentenceToAcronym(title)}
  </Avatar>
)

LetterAvatar.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired
}

export default compose(withStyles(styles))(LetterAvatar)
