import React from 'react'
import { Loader } from 'components'

const LoadingPlaceholder = () => (
  <div
    className="flex w-100 h-100 items-center justify-center"
    style={{ height: '200px', textAlign: 'center' }}
  >
    <Loader />
  </div>
)

export default LoadingPlaceholder
