import { useMutation } from 'react-apollo'
import { toastr } from 'react-redux-toastr'
import { loader } from 'graphql.macro'
import store from 'store'

const DELETE_CONTACT = loader('../contacts/delete.graphql')

function useDeleteContactAction({ accountId, ecosystemId, partnerId }) {
  const [deleteContactMutation] = useMutation(DELETE_CONTACT, {
    update: (cache, { data: { deleteContact: contact } }) => {
      store.contacts.remove(
        cache,
        {
          accountId,
          ecosystemId,
          partnerId
        },
        { id: contact.id }
      )
    }
  })

  async function handleDeleteContact(id) {
    try {
      const variables = {
        accountId,
        id
      }

      await deleteContactMutation({ variables })

      toastr.success('Contact deleted')
    } catch (error) {
      toastr.error('Could not delete contact')
    }
  }

  return [handleDeleteContact]
}

export { useDeleteContactAction }
