import React from 'react'
import { TextInput } from 'components'
export const FirstName = ({ formikProps }) => (
  <TextInput
    formikProps={formikProps}
    label="First name"
    field="firstName"
    placeholder=""
    required
  />
)

export const LastName = ({ formikProps }) => (
  <TextInput formikProps={formikProps} label="Last Name" field="lastName" placeholder="" />
)

export const Email = ({ formikProps }) => (
  <TextInput formikProps={formikProps} label="Email" field="email" placeholder="elon@spacex.com" />
)

export const Role = ({ formikProps }) => (
  <TextInput formikProps={formikProps} label="Role" field="role" placeholder="Partner manager" />
)
