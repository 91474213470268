import React from 'react'
import Switch from '@material-ui/core/Switch'

const SwitchInput = (props) => {
  const { field, formikProps, ...rest } = props
  const { handleChange, handleBlur, values } = formikProps

  return (
    <Switch
      id={field}
      name={field}
      checked={values[field]}
      onChange={handleChange}
      onBlur={handleBlur}
      {...rest}
    />
  )
}

export default SwitchInput
