import { activateInvitationMutation, authenticateMutation } from 'api/queries'
import React, { useEffect, useState } from 'react'
import { useLocation, withRouter } from 'react-router-dom'
import { getItem, setItem } from 'utils/localstorage'

import { PanelLayout } from 'components'
import SignupLoading from 'components/signup/SignupLoading'
import { useMutation } from 'react-apollo'
import { auth } from 'routes/App'

const ActivateCallback = ({ history }) => {
  const [activateInvitation] = useMutation(activateInvitationMutation)
  const [authenticate] = useMutation(authenticateMutation)

  const [errors, setErrors] = useState(null)
  const [idToken] = useState(null)
  const challenge = getItem('challenge')
  const location = useLocation()

  useEffect(() => {
    const handleAuth0 = async (tokenHash) => {
      // parsing hash verifies token is valid
      const authResult = await auth.parseHash(tokenHash)
      // auth.setSession(authResult)
      const userProfile = await auth.getProfile(authResult.accessToken)

      if (userProfile) {
        const { error } = await activateInvitation({
          variables: {
            challenge,
            auth0UserId: userProfile.sub
          }
        })

        if (error) {
          setErrors(error)
        } else {
          window.location.href = `${process.env.REACT_APP_V2_URL}/api/auth/login`
          // setIdToken(authResult.idToken)
          return
        }
      }
    }

    if (location.hash.includes('id_token')) {
      handleAuth0(location.hash.slice(1))
    }
  }, [challenge, activateInvitation, location.hash])

  useEffect(() => {
    if (idToken) {
      const authenticateUser = async () => {
        const {
          error,
          data: { authenticate: authenticatedUser }
        } = await authenticate({
          variables: {
            idToken
          }
        })

        if (error) {
          setErrors(error)
        } else {
          if (authenticatedUser.accounts.length > 1) {
            // user has multiple accounts, redirect to account switcher
            history.push('/accounts/portal')
          } else {
            // user has 1 account, set that account as active
            // redirect to listings overview
            const activeAccount = authenticatedUser.accounts?.[0]?.account
            activeAccount?.id && setItem('activeAccountId', activeAccount.id)

            history.push('/')
          }
        }
      }

      authenticateUser()
    }
  }, [idToken, authenticate, history])

  if (!errors) {
    return (
      <PanelLayout>
        <SignupLoading />
      </PanelLayout>
    )
  }

  return <PanelLayout>{errors ? <div>{errors}</div> : ''}</PanelLayout>
}

export default withRouter(ActivateCallback)
