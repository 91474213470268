import React, { createContext, useContext } from 'react'
import { useQuery } from 'react-apollo'
import { listingsOverviewQuery } from 'pages/private/ecosystem/listings/overview/graphql'
import styled from 'react-emotion'
import { getDaysLeft } from 'utils/trial'

const Link = styled('a')`
  color: white;
  text-decoration: underline;
`

const MaxListingsContext = createContext()

export const useMaxListingsContext = () => {
  const context = useContext(MaxListingsContext)

  if (!context) {
    throw new Error('useMaxListings must be used within a MaxListingsProvider')
  }

  return context
}

export const MaxListingsProvider = ({ ecosystem, activeAccount, children }) => {
  // Get total listings
  const { data, refetch } = useQuery(listingsOverviewQuery, {
    variables: {
      input: {
        ecosystemId: ecosystem.id,
        accountId: activeAccount.id,
        page: 0,
        perPage: 1
      }
    },
    notifyOnNetworkStatusChange: true
  })

  const total = data?.listings?.total || 0

  // Restrict import/creation of listings if trial is ended.
  const { endOfTrial, trialExtensions, subscriptionStatus } = activeAccount
  const daysLeft = getDaysLeft(endOfTrial, trialExtensions)
  const isExpired = subscriptionStatus === 'TRIAL' && daysLeft <= 0

  // Create context
  const context = {
    maxListings: ecosystem.maxListings,
    maxListingsReached: total >= ecosystem.maxListings || isExpired,
    currentListingsTotal: total,
    recalculateMaxListings: refetch,
    infoMessage: (
      <>
        {'You have reached the maximum amount of listings for this ecosystem, please reach out to '}
        <Link href="mailto:sales@apideck.com" target="_blank" rel="noreferrer">
          sales
        </Link>{' '}
        {' to upgrade your plan.'}
      </>
    )
  }

  return <MaxListingsContext.Provider value={context}>{children}</MaxListingsContext.Provider>
}
