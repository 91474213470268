import DialogTitle from '@material-ui/core/DialogActions'
import React from 'react'
import { withStyles } from '@material-ui/core/styles'

const styles = () => ({
  root: {
    backgroundColor: '#fdfdfd',
    borderTop: '1px solid #e9e9e9',
    paddingTop: '10px'
  }
})

const Actions = ({ children, classes }) => <DialogTitle classes={classes}>{children}</DialogTitle>

export default withStyles(styles)(Actions)
