import GridCard from './GridCard'
import React from 'react'
import { withRouter } from 'react-router-dom'

class CloudService extends React.Component {
  state = { disabled: false }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.action !== nextProps.action) {
      this.setState({
        disabled: false
      })
    }
  }

  _handleClick = () => {
    this.setState({
      disabled: true
    })

    this.props.handleClick(this.props.service.id)
  }

  render() {
    const { service, imported, match, action } = this.props

    return (
      <GridCard
        title={service.name}
        imageSrc={service.icon.url || null}
        id={service.id}
        link={`${match.url}/${service.id}`}
        handleClick={this._handleClick}
        action={action}
        small
        integration
        disabled={this.state.disabled}
        style={{ border: imported ? '1px #12bf00 solid' : '' }}
        sm={2}
        xs={12}
      />
    )
  }
}

export default withRouter(CloudService)
