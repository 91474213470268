import { activateInvitationMutation, checkInvitationQuery } from 'api/queries'
import { Divider, InvitationActivateForm, PanelLayout, SocialButtons } from 'components'
import { useMutation, useQuery } from 'react-apollo'

import LoadingPlaceholder from 'components/placeholder/Loading'
import React from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { auth } from 'routes/App'
import { handleUserFormError } from 'utils/ErrorHandling'
import { setItem } from 'utils/localstorage'

const Activate = ({ match }) => {
  const [activateInvitation] = useMutation(activateInvitationMutation)
  const { USERNAME_PASSWORD, GOOGLE, GITHUB } = auth.connections()
  const { challenge } = match.params

  setItem('challenge', challenge)

  const { loading, error } = useQuery(checkInvitationQuery, {
    variables: {
      challenge
    }
  })

  if (loading) {
    return <LoadingPlaceholder />
  }

  if (error) {
    return (
      <PanelLayout>
        <h3>
          {error.message
            ? `
            Can not find invitation.
            Invitation does not exist or is expired.
          `
            : 'Error has occurred'}
        </h3>
      </PanelLayout>
    )
  }

  const onGoogleSubmit = async () => {
    await auth.login(GOOGLE, {}, `activation-callback`)
  }

  const onGithubSubmit = async () => {
    await auth.login(GITHUB, {}, `activation-callback`)
  }

  const onUsernamePasswordSubmit = async ({ email, password }, { setSubmitting, setErrors }) => {
    try {
      setSubmitting(true)
      const authResult = await auth.signup(USERNAME_PASSWORD, email, password, {})

      const userProfile = await auth.getProfile(authResult.accessToken)

      const {
        data: {
          activateInvitation: { success }
        }
      } = await activateInvitation({
        variables: {
          challenge,
          auth0UserId: userProfile.sub
        }
      })

      if (success) {
        window.location.href = `${process.env.REACT_APP_V2_URL}/api/auth/login`
        return
      }
    } catch (err) {
      setSubmitting(false)
      // handle user form fails
      handleUserFormError(setErrors, err)
    }
  }

  return (
    <PanelLayout>
      <SocialButtons
        handleGoogleSubmit={onGoogleSubmit}
        handleGithubSubmit={onGithubSubmit}
        action="Sign up"
      />
      <Divider>OR</Divider>
      <InvitationActivateForm
        onSubmit={onUsernamePasswordSubmit}
        initialValues={{
          email: '',
          password: ''
        }}
      />
    </PanelLayout>
  )
}

export default compose(withRouter)(Activate)
