export const addButtonClassName = 'ba dib ph4 pv3 mb4 br3 bg-blue white'
export const tableClassName = 'f6 w-100 mw12 center'

export const media = {
  small: '700px',
  medium: '1024px',
  large: '1200px',
  xLarge: '1400px'
}

const spacingFactor = 8

export const spacing = {
  space0: `${spacingFactor / 2}px`, // 4
  space1: `${spacingFactor}px`, // 8
  space2: `${spacingFactor * 2}px`, // 16
  space3: `${spacingFactor * 3}px`, // 24
  space4: `${spacingFactor * 4}px`, // 32
  space5: `${spacingFactor * 5}px`, // 40
  space6: `${spacingFactor * 6}px`, // 48
  space8: `${spacingFactor * 8}px`, // 64
  space9: `${spacingFactor * 9}px`, // 72
  space13: `${spacingFactor * 13}px` // 104
}

export const colors = {
  blueBackground: 'linear-gradient(to right, rgb(26, 39, 67), rgb(28, 42, 70))',
  button: '#6F56FC',
  automate: 'rgb(90, 134,	229)',
  zapier: 'rgb(253, 76, 30)',
  piesync: 'rgb(27, 17, 8)',
  microsoftFlow: 'rgb(20, 119, 251)',
  segment: 'rgb(78, 183, 132)',
  combidesk: 'rgb(34,	133, 194)',
  trayIo: 'rgb(11, 15, 52)'
}

export const form = {
  inputText: {
    padding: '15px',
    border: '1px solid #D4D4D4',
    borderRadius: '3px',
    width: '100%'
  },
  button: {
    backgroundColor: colors.button,
    border: `1px solid ${colors.button}`
  }
}

export const borderColor = '#D4D4D4'

export const bigAvatar = {
  width: '80px',
  height: '80px',
  marginRight: '20px'
}

export const card = {
  boxShadow: '0 1px 3px 0 rgba(184,184,184,0.50)',
  borderRadius: '5px',
  background: '#FFF',
  padding: '50px'
}

export const cardSpacing = 24
