import { CardIcon } from 'components'
import Grid from '@material-ui/core/Grid'
import { Link } from 'react-router-dom'
import React from 'react'
import { ScaleLoader } from 'react-spinners'
import { colors } from 'styles/styles'
import styled from 'react-emotion'
import truncate from 'lodash/truncate'
import Tooltip from '@material-ui/core/Tooltip'

const ContentWrapper = styled('div')`
  height: 100%;
  width: 100%;
  display: block;
  padding: 25px 0 0 0;
  text-decoration: none;
  text-align: center;
  position: relative;
  background: #fff;
  box-sizing: border-box;
  transform: translateZ(0);
  box-shadow: 0px 1.5px 3px 0 rgba(225, 227, 229, 0.6);
  border-radius: 8px;
  border: thin solid #e9ebed;
  transition: all 0.2s ease-in-out;
  overflow: hidden;

  :hover {
    border-color: #087cff;
  }

  .title {
    color: #666;
    font-size: 14px;
    font-weight: 400;
    height: 24px;
  }

  .subtitle {
    font-size: 14px;
    line-height: 16px;
    color: rgba(0, 56, 108, 0.5);
  }

  .icon {
    margin-bottom: 10px;
  }

  .image {
    max-width: 120px;
  }

  .dimmed {
    filter: grayscale(100%);
    opacity: 0.2;
  }

  p {
    line-height: 1.15em;
  }

  .buttons {
    border-top: 1px solid #ddd;
    height: 45px;
    width: 100%;
    margin-top: 15px;
    display: flex;
    justify-content: center;
  }

  .buttons span,
  .buttons a {
    color: #666;
    font-weight: normal;
    cursor: pointer;
  }

  .buttons span:hover {
    text-decoration: underline;
  }

  .buttons .left,
  .buttons .right {
    flex-grow: 1;
    background: 0 0;
    color: inherit;
    display: inline-block;
    height: 44px;
    line-height: 44px;
    position: relative;
    padding: 0;
    top: 0;
    text-align: center;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  .buttons .left {
    border-right: 1px solid #ddd;
  }

  .buttons .right.disabled {
    background: #eee;
  }
`

const GridCard = ({ sm, xs, ...rest }) => (
  <Grid item className="grid-card" sm={sm} xs={xs}>
    <Content {...rest} />
  </Grid>
)

class Content extends React.Component {
  render() {
    const {
      title,
      imageSrc,
      action = 'View Details',
      link,
      id,
      small,
      style,
      disabled,
      loading,
      handleClick,
      tooltip
    } = this.props

    return (
      <ContentWrapper style={style}>
        <Link to={link}>
          <CardIcon imageSrc={imageSrc} title={title} small={small} />
          <div className="title">{small ? truncate(title, { length: 14 }) : title}</div>
        </Link>
        <div className="buttons">
          <div className="left">
            <Link to={link}>Details</Link>
          </div>
          <div className={`right ${disabled && 'disabled'}`}>
            {disabled && tooltip && (
              <Tooltip title={tooltip} interactive placement="top" arrow>
                <div className="disabled">
                  <span>{action}</span>
                </div>
              </Tooltip>
            )}
            {((disabled && !tooltip) || loading) && (
              <ScaleLoader height={12} width={3} color={colors.primary} margin="2px" />
            )}
            {!loading && !disabled && (
              <div onClick={() => handleClick(id)}>
                <span>{action}</span>
              </div>
            )}
          </div>
        </div>
      </ContentWrapper>
    )
  }
}

export default GridCard
