import React, { useEffect } from 'react'

import { ListingComment } from './ListingComment'
import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(() => ({
  commentWrapper: {
    maxHeight: '300px',
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column'
  }
}))

export const ListingComments = ({ comments, user, handleDeleteComment, commentBoxRef }) => {
  const classes = useStyles()

  const handleDelete = (commentId) => {
    handleDeleteComment({ id: commentId })
  }

  useEffect(() => {
    commentBoxRef.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' })
  }, [comments, commentBoxRef])

  return (
    <div className={classes.commentWrapper}>
      {comments.map((comment) => (
        <ListingComment
          key={comment.id}
          handleDelete={handleDelete}
          user={user}
          comment={comment}
        />
      ))}
      <div ref={commentBoxRef} />
    </div>
  )
}
