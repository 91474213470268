import LoadingPlaceholder from 'components/placeholder/Loading'
import React from 'react'
import SetupArea from './Area'
import SetupContainer from './Container'

function EcosystemSetupPage({ activeAccount }) {
  const steps = [
    { label: 'Integration platforms', path: 'integration-platforms' },
    { label: 'Listings', path: 'listings' },
    { label: 'Styling', path: 'template' }
  ]

  return (
    <SetupContainer>
      {({ service: { loading, data, error } }) => {
        if (loading) return <LoadingPlaceholder />
        if (error) return <SetupArea steps={steps} activeAccount={activeAccount} />

        return (
          <SetupArea catalogService={data.service} steps={steps} activeAccount={activeAccount} />
        )
      }}
    </SetupContainer>
  )
}

export default EcosystemSetupPage
