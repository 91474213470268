import fields from './fields'

const injectValues = (form, initialValues, entity) => {
  const locales = initialValues.locales
  const localizedFields = fields[form]

  if (locales && locales.length > 1) {
    for (const field of localizedFields) {
      initialValues[`${field}_${locales[0]}`] = ''

      if (entity) {
        initialValues[`${field}_${locales[0]}`] = entity[field]
      }
    }

    if (entity && entity.translations) {
      for (const translation of entity.translations) {
        initialValues[`${translation.key}_${translation.locale}`] = translation.value
      }
    }
  }
  return initialValues
}

export default injectValues
