import { Card, CardEmptyState, ErrorFallback, Loader } from 'components'

import PropTypes from 'prop-types'
import React from 'react'
import Table from './Table'
import { partnersQuery } from 'api'
import { useQuery } from 'react-apollo'

function AllPartnersPage({ activeAccount, ecosystem }) {
  const { loading, error, data } = useQuery(partnersQuery, {
    variables: {
      accountId: activeAccount.id,
      ecosystemId: ecosystem.id,
      limit: 500
    }
  })

  if (error) return <ErrorFallback error={error} />

  return (
    <Card title="Invitations">
      <div data-testid="ecosystem-partner-invitations-view">
        {loading ? (
          <Loader />
        ) : data.partners && data.partners.length === 0 ? (
          <CardEmptyState>None found. </CardEmptyState>
        ) : (
          <Table data={data.partners} ecosystemId={ecosystem.id} />
        )}
      </div>
    </Card>
  )
}

AllPartnersPage.propTypes = {
  activeAccount: PropTypes.object.isRequired,
  ecosystem: PropTypes.object.isRequired
}

export default AllPartnersPage
