import React from 'react'
import { ListingCreateForm, ContentWrapper } from 'components'
import PropTypes from 'prop-types'

const Create = (props) => (
  <ContentWrapper title="Create custom listing">
    <ListingCreateForm {...props} />
  </ContentWrapper>
)

Create.propTypes = {
  categories: PropTypes.array,
  handleSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired
}

export default Create
