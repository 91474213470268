import React from 'react'
import styled from 'react-emotion'

const DividerWrapper = styled('div')`
  display: inline-block;
  margin: 20px auto 0;
  width: 100%;
  color: #fff;
  overflow: hidden;
  text-align: center;
`

const Line = styled('div')`
  vertical-align: top;
  border-bottom: 1px solid #1c1e54;
  display: inline-block;
  height: 0.8em;
  width: 50%;
`

const Divider = ({ children }) => (
  <DividerWrapper>
    <Line style={{ margin: '0px 4% 0px -55%' }} />
    <div
      style={{
        display: 'inline-block',
        fontSize: '14px',
        fontWeight: '500',
        textTransform: 'uppercase',
        letterSpacing: '0.05em'
      }}
    >
      {children}
    </div>
    <Line style={{ margin: '0px -55% 0px 4%' }} />
  </DividerWrapper>
)

export default Divider
