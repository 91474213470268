import { useMutation } from '@apollo/react-hooks'
import { PanelLayout } from 'components'
import gql from 'graphql-tag'
import React, { useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'

const CONFIRM_PARTNER_REQUEST = gql`
  mutation ConfirmPartnerRequest($challenge: String!) {
    confirmPartnerRequest(input: { challenge: $challenge }) {
      success
    }
  }
`

function PartnerRequestConfirmationPage({ match }) {
  const [confirmPartnerRequest, { called, loading, error }] = useMutation(CONFIRM_PARTNER_REQUEST)

  useEffect(() => {
    if (!called) {
      confirmPartnerRequest({
        variables: { challenge: match.params.challenge }
      })
    }
  })

  if (loading) {
    return (
      <PanelLayout>
        <h3>Loading...</h3>
      </PanelLayout>
    )
  }

  if (error) {
    return (
      <PanelLayout>
        <h3>
          {error.message ? (
            <p>
              Can not find a matching partner request. If this an error, contact us through{' '}
              <a href="mailto:info@apideck.com">mail</a>.
            </p>
          ) : (
            'Error has occurred'
          )}
        </h3>
      </PanelLayout>
    )
  }

  return (
    <PanelLayout>
      <h3>Your partner request has been confirmed.</h3>
    </PanelLayout>
  )
}

export default compose(withRouter)(PartnerRequestConfirmationPage)
