import { marketplaceQuery } from 'api/queries'
import { any, concat } from 'ramda'

function add(cache, { accountId, ecosystemId }, { listing }) {
  // add listing
  const { marketplace } = cache.readQuery({
    query: marketplaceQuery,
    variables: {
      id: ecosystemId,
      accountId
    }
  })

  marketplace.listings = concat(marketplace.listings, [listing])

  // add category to marketplace if it doesn't exist
  if (listing?.category) {
    const exists = any((c) => c.slug === listing?.category?.slug, marketplace.categories)

    if (!exists) {
      marketplace.categories = marketplace.categories.concat([listing?.category])
    }
  }

  cache.writeQuery({
    data: { marketplace },
    query: marketplaceQuery,
    variables: {
      id: ecosystemId,
      accountId
    }
  })
}

export { add }
