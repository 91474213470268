import { Box, Grid } from '@material-ui/core'
import { useHistory, useLocation } from 'react-router-dom'

import { Badge } from 'components'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import React from 'react'
import { Skeleton } from '@material-ui/lab'
import pluralize from 'pluralize'
import { useCardStyles } from 'components/cards/styles'

const WebhookCard = ({ webhook, isLoading }) => {
  const classes = useCardStyles()
  const location = useLocation()
  const history = useHistory()

  const handleRowClick = () => {
    history.push(`${location.pathname}/${webhook.id}`)
  }

  if (isLoading) return <LoadingCard />

  return (
    <div className={classes.row} onClick={handleRowClick}>
      <Grid container>
        <Grid item sm={5} lg={6}>
          <div className={classes.flexContainer}>
            <div style={{ maxWidth: '100%' }}>
              <span className={classes.header}>{webhook.url}</span>
              <span className={classes.userName}>{webhook.description || 'No description'}</span>
            </div>
          </div>
        </Grid>
        <Grid item sm={2} className={classes.grid}>
          {!webhook.events.includes('*') && (
            <span style={{ marginRight: 3, fontWeight: 600 }}>{webhook.events.length}</span>
          )}
          {webhook.events.includes('*')
            ? 'All event types'
            : pluralize('event type', webhook.events.length)}
        </Grid>
        <Grid item sm={2} lg={2} className={classes.grid}>
          Created on{' '}
          <span style={{ marginLeft: 3, fontWeight: 600 }}>
            {new Date(webhook.createdAt).toLocaleDateString()}
          </span>
        </Grid>
        <Grid item sm={2} lg={1} className={classes.grid}>
          <Box display="flex" justifyContent="flex-end" width="100%">
            <Badge
              label={webhook.status}
              type={webhook.status === 'enabled' ? 'success' : 'error'}
              uppercase={true}
            />
          </Box>
        </Grid>
        <Grid item sm={1} className={classes.grid}>
          <Box display="flex" justifyContent="flex-end" width="100%">
            <ChevronRightIcon fontSize="small" />
          </Box>
        </Grid>
      </Grid>
    </div>
  )
}

const LoadingCard = () => {
  const classes = useCardStyles()

  return (
    <div className={classes.row}>
      <Grid container>
        <Grid item sm={7}>
          <div className={classes.flexContainer}>
            <div>
              <span className={classes.header}>
                <Skeleton display="block" width={90} />
              </span>
              <span className={classes.userName}>
                <Skeleton display="block" width={150} />
              </span>
            </div>
          </div>
        </Grid>
        <Grid item sm={2} lg={2} className={classes.grid}>
          <Skeleton variant="text" width={100} />
        </Grid>
        <Grid item sm={2} className={classes.grid}>
          <Skeleton variant="text" width={120} />
        </Grid>
        <Grid item sm={1} className={classes.grid}>
          <Box display="flex" justifyContent="flex-end" width="100%">
            <Skeleton display="block" width={20} />
          </Box>
        </Grid>
      </Grid>
    </div>
  )
}

WebhookCard.defaultProps = {
  consumer: null
}

export default WebhookCard
