import { ListingSettings as Settings, injectLocalizedValues, prepareVariables } from 'components'
import { compose, withHandlers } from 'recompose'
import { deleteListingMutation, marketplaceQuery, updateListingMutation } from 'api/queries'
import { ecosystemLocales, setRootValueFromTranslation } from 'utils/locales'

import LoadingPlaceholder from 'components/placeholder/Loading'
import PropTypes from 'prop-types'
import React from 'react'
import getLocalizedValue from 'utils/getLocalizedValue'
import { graphql } from 'react-apollo'
import { toastr } from 'react-redux-toastr'
import transformError from 'utils/transformError'
import uploadFile from 'utils/fileUpload'

class Container extends React.Component {
  render() {
    const { handleSubmit, handleDelete, listing, categories, ecosystem } = this.props

    if (!listing) {
      return <LoadingPlaceholder />
    } else {
      let initialValues = {
        name: listing.name || '',
        slug: listing.slug || '',
        externalId: listing.externalId,
        description: getLocalizedValue(listing, 'description', ecosystem.locales) || '',
        features: getLocalizedValue(listing, 'features', ecosystem.locales) || '',
        pricing: getLocalizedValue(listing, 'pricing', ecosystem.locales) || '',
        logo: listing.logo ? [listing.logo] : [],
        tagLine: getLocalizedValue(listing, 'tagLine', ecosystem.locales) || '',
        category: listing?.category ? listing.category.id : null,
        metaTagTitle: listing.metaTagTitle || '',
        metaTagDescription: listing.metaTagDescription || '',
        metaTagKeywords: listing.metaTagKeywords || '',
        unifyConnectorId: listing.unifyConnectorId || '',
        nativeIntegration: listing.nativeIntegration ?? false,
        thirdPartyIntegration: listing?.thirdPartyIntegration ?? false,
        nativeIntegrationLink: listing.nativeIntegrationLink || '',
        thirdPartyIntegrationLink: listing.thirdPartyIntegrationLink || '',
        cardBackgroundColor: listing.cardBackgroundColor || ecosystem.primaryColor,
        cardBackgroundImage: listing.cardBackgroundImage ? [listing.cardBackgroundImage] : [],
        zapierId: listing.zapierId || '',
        piesyncId: listing.piesyncId || '',
        blendrId: listing.blendrId || '',
        automateId: listing.automateId || '',
        segmentId: listing.segmentId || '',
        microsoftFlowId: listing.microsoftFlowId || '',
        combideskId: listing.combideskId || '',
        trayIoId: listing.trayIoId || '',
        integromatId: listing.integromatId || '',
        sticky: listing?.sticky ?? false,
        upcoming: listing?.upcoming ?? false,
        detailPageDisabled: listing?.detailPageDisabled ?? false,
        locales: ecosystemLocales(ecosystem)
      }

      initialValues = injectLocalizedValues('listing', initialValues, listing)

      return (
        <React.Fragment>
          <Settings
            initialValues={initialValues}
            categories={categories}
            handleSubmit={handleSubmit}
            ecosystem={ecosystem}
            handleDelete={handleDelete}
          />
        </React.Fragment>
      )
    }
  }
}

Container.propTypes = {
  activeAccount: PropTypes.object.isRequired,
  listing: PropTypes.object.isRequired,
  categories: PropTypes.array.isRequired
}

const removeListingFromStore = (store, { ecosystemId, accountId }, listing) => {
  const data = store.readQuery({
    query: marketplaceQuery,
    variables: {
      id: ecosystemId,
      accountId
    }
  })

  data.marketplace.listings = data.marketplace.listings.filter((l) => l.id !== listing.id)

  store.writeQuery({
    query: marketplaceQuery,
    data,
    variables: {
      id: ecosystemId,
      accountId
    }
  })
}

const listingHandlers = withHandlers({
  handleSubmit:
    ({
      updateListing,
      activeAccount,
      history,
      listing,
      ecosystem,
      match: {
        params: { ecosystemId }
      }
    }) =>
    async (props, { setErrors, setSubmitting }) => {
      try {
        const {
          name,
          slug,
          description,
          features,
          pricing,
          tagLine,
          category,
          metaTagTitle,
          metaTagDescription,
          metaTagKeywords,
          unifyConnectorId,
          nativeIntegration,
          thirdPartyIntegration,
          nativeIntegrationLink,
          externalId,
          thirdPartyIntegrationLink,
          cardBackgroundColor,
          cardBackgroundImage,
          detailPageDisabled,
          zapierId,
          piesyncId,
          blendrId,
          automateId,
          integromatId,
          microsoftFlowId,
          combideskId,
          trayIoId,
          logo,
          sticky,
          upcoming
        } = props

        const locales = ecosystemLocales(ecosystem)

        let variables = {
          accountId: activeAccount.id,
          id: listing.id,
          name: setRootValueFromTranslation(locales, listing, 'name', name),
          slug,
          description: setRootValueFromTranslation(locales, listing, 'description', description),
          features: setRootValueFromTranslation(locales, listing, 'features', features),
          pricing: setRootValueFromTranslation(locales, listing, 'pricing', pricing),
          tagLine: setRootValueFromTranslation(locales, listing, 'tagLine', tagLine),
          metaTagTitle: setRootValueFromTranslation(locales, listing, 'metaTagTitle', metaTagTitle),
          metaTagDescription: setRootValueFromTranslation(
            locales,
            listing,
            'metaTagDescription',
            metaTagDescription
          ),
          metaTagKeywords: setRootValueFromTranslation(
            locales,
            listing,
            'metaTagKeywords',
            metaTagKeywords
          ),
          unifyConnectorId,
          nativeIntegration,
          thirdPartyIntegration,
          nativeIntegrationLink,
          externalId,
          thirdPartyIntegrationLink,
          cardBackgroundColor,
          detailPageDisabled,
          zapierId,
          piesyncId,
          blendrId,
          automateId,
          integromatId,
          microsoftFlowId,
          combideskId,
          trayIoId,
          sticky,
          upcoming,
          translations: prepareVariables(props, ecosystem, listing.translations)
        }

        if (logo.length !== 0 && logo[0].preview) {
          let result = await uploadFile(logo[0], {
            params: {
              folder: `marketplaces/${ecosystemId}/listings`
            }
          })

          variables = Object.assign(
            {
              logo: {
                url: result.url,
                contentType: `${result.resourceType}/${result.format}`
              }
            },
            variables
          )
        }

        if (cardBackgroundImage.length !== 0 && cardBackgroundImage[0].preview) {
          let result = await uploadFile(cardBackgroundImage[0], {
            params: {
              folder: `marketplaces/${ecosystemId}/listings/${listing.id}`
            }
          })

          variables = Object.assign(
            {
              cardBackgroundImage: {
                url: result.url,
                contentType: `${result.resourceType}/${result.format}`
              }
            },
            variables
          )
        }

        if (category) {
          variables.categoryId = category
        }

        await updateListing({ variables })
        toastr.success('Listing updated!')
        history.push(`/ecosystems/${ecosystemId}/listings/${listing.id}/overview`)
      } catch (error) {
        console.log(error)
        toastr.error('could not update listing')

        if (error.graphQLErrors) {
          setErrors(transformError(error.graphQLErrors))
        }

        setSubmitting(false)
      }
    },
  handleDelete:
    ({
      deleteListing,
      activeAccount,
      history,
      listing,
      match: {
        params: { ecosystemId }
      }
    }) =>
    async () => {
      try {
        let variables = {
          accountId: activeAccount.id,
          id: listing.id
        }

        await deleteListing({
          variables,
          update: (store) =>
            removeListingFromStore(
              store,
              {
                ecosystemId: ecosystemId,
                accountId: activeAccount.id
              },
              listing
            )
        })
        toastr.success('Listing deleted')
        history.push(`/ecosystems/${ecosystemId}/listings/overview`)
      } catch (error) {
        toastr.error('could not delete listing')
      }
    }
})

const SettingsContainer = compose(
  graphql(updateListingMutation, { name: 'updateListing' }),
  graphql(deleteListingMutation, { name: 'deleteListing' }),
  listingHandlers
)(Container)

export default SettingsContainer
