import { CategoryEditForm as Form } from 'components'
import PropTypes from 'prop-types'
import React from 'react'

const Settings = ({ handleSubmit, initialValues, ecosystem, handleLogoDelete }) => (
  <Form
    initialValues={initialValues}
    handleSubmit={handleSubmit}
    ecosystem={ecosystem}
    handleLogoDelete={handleLogoDelete}
  />
)

Settings.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired
}

export default Settings
