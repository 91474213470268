import CircularProgress from '@material-ui/core/CircularProgress'
import PropTypes from 'prop-types'
import React from 'react'
import purple from '@material-ui/core/colors/purple'

const Loader = ({ verticalCentered, size, color = purple[500] }) => {
  const LoaderIcon = <CircularProgress style={{ color }} size={size} thickness={5} />

  if (verticalCentered) {
    return (
      <div className="flex w-100 items-center justify-center" style={{ height: '50vh' }}>
        {LoaderIcon}
      </div>
    )
  } else {
    return <div style={{ textAlign: 'center', display: 'flex' }}>{LoaderIcon}</div>
  }
}

Loader.defaultProps = {
  verticalCentered: false,
  size: 40
}

Loader.propTypes = {
  verticalCentered: PropTypes.bool,
  size: PropTypes.number
}

export default Loader
