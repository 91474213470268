import { useMaxListingsContext } from 'context/useMaxListingsContext'
import PropTypes from 'prop-types'
import React, { useMemo, useState } from 'react'
import { useModal } from 'react-modal-hook'
import { toastr } from 'react-redux-toastr'
import getLocalizedValue from 'utils/getLocalizedValue'
import NetworkContent from './Content'
import NetworkFilters from './Filters'
import ImportForm from './ImportForm'
import NetworkOverview from './Overview'

const parseCategoryToSelectValues = (category, locales) => {
  return {
    label: getLocalizedValue(category, 'name', locales),
    value: category.id
  }
}

const joinServicesWithListings = (services, listings) => {
  return services.map((s) => {
    const listing = getMatchingListingOfService(s, listings)

    if (!listing) {
      return s
    }

    return Object.assign(
      {
        imported: !!listing,
        listingId: listing ? listing.id : ''
      },
      s
    )
  })
}

const getMatchingListingOfService = (service, listings) => {
  return listings?.find((l) => l.slug === service.id)
}

const Network = ({
  handleIntegrationFilterChange,
  handleTextFilterChange,
  hasMore,
  loading,
  loadNextPage,
  searching,
  services: _services,
  ecosystem,
  listings,
  categories,
  total,
  handleDelete,
  importService,
  activeAccount
}) => {
  const [serviceModal, setServiceModal] = useState({})
  const { recalculateMaxListings } = useMaxListingsContext()
  const [showModal, hideModal] = useModal(() => {
    const _getDefaultCategory = (ecosystemCategories, serviceCategories) => {
      let matchedCategory

      for (let s in serviceCategories) {
        matchedCategory = ecosystemCategories.find((c) => c.slug === serviceCategories[s].id)

        if (matchedCategory) {
          return matchedCategory.id
        }
      }
    }

    const _extractOptionsFromCategories = (ecosystemCategories, serviceCategories) => {
      // filter all categories that have to be parsed
      let categoriesFiltered = categories

      if (serviceCategories) {
        categoriesFiltered = categories.concat(
          serviceCategories.filter((serviceCategory) =>
            categories.every((ecosystemCategory) => serviceCategory.id !== ecosystemCategory.slug)
          )
        )
      }

      // parse categories
      const options = categoriesFiltered.map((c) =>
        parseCategoryToSelectValues(c, ecosystem.locales)
      )

      return options
    }

    return (
      <ImportForm
        open={true}
        service={serviceModal}
        initialValues={{
          category: _getDefaultCategory(categories, serviceModal.categories),
          upcoming: false,
          published: true
        }}
        categories={_extractOptionsFromCategories(categories, serviceModal.categories)}
        onClose={hideModal}
        onSubmit={handleImport}
      />
    )
  }, [serviceModal])
  const openModal = (data) => {
    setServiceModal(data)
    showModal()
  }
  const onClick = (service) => {
    if (!service.listingId) {
      openModal(service)
    } else {
      handleDelete(service)
    }
  }
  const services = useMemo(() => {
    if (_services.length === 0) {
      return []
    }

    return joinServicesWithListings(_services, listings)
  }, [_services, listings])
  const handleImport = async (result) => {
    const category = {}

    const categoryExists = categories.some(({ id }) => id === result.category)

    if (categoryExists) {
      category.id = result.category
    } else {
      category.slug = result.category
    }

    await importService({
      variables: {
        accountId: activeAccount.id,
        category,
        upcoming: result.upcoming,
        published: result.published,
        ecosystemId: ecosystem.id,
        id: serviceModal.id
      },
      refetchQueries: ['marketplace']
    })
    // check if max limit is not reached
    await recalculateMaxListings()

    // inform user
    toastr.success(`${serviceModal.name} has been imported`)

    // close modal
    hideModal()
  }

  return (
    <NetworkOverview
      Filter={
        <NetworkFilters
          handleIntegrationFilterChange={handleIntegrationFilterChange}
          handleTextFilterChange={handleTextFilterChange}
        />
      }
      Grid={
        <NetworkContent
          handleServiceClick={onClick}
          hasMore={hasMore}
          loading={loading}
          searching={searching}
          loadNextPage={loadNextPage}
          services={services}
          total={total}
          ecosystem={ecosystem}
        />
      }
      ecosystem={ecosystem}
    />
  )
}

Network.propTypes = {
  handleIntegrationFilterChange: PropTypes.func.isRequired,
  handleTextFilterChange: PropTypes.func.isRequired,
  hasMore: PropTypes.bool.isRequired,
  listings: PropTypes.array.isRequired,
  categories: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  loadNextPage: PropTypes.func.isRequired,
  searching: PropTypes.bool.isRequired,
  services: PropTypes.array,
  total: PropTypes.number.isRequired,
  handleDelete: PropTypes.func.isRequired,
  importService: PropTypes.func.isRequired,
  activeAccount: PropTypes.object.isRequired
}

export default Network
