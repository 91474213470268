import { partnerQuery } from 'api'

function add(cache, { accountId, ecosystemId, partnerId }, { contact }) {
  const variables = {
    accountId,
    ecosystemId,
    id: partnerId
  }
  const query = partnerQuery
  const data = cache.readQuery({ query, variables })

  data.partner.contacts = data.partner.contacts.concat([contact])

  cache.writeQuery({ query, data, variables })
}

export { add }
