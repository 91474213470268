import React from 'react'
import Step from '@material-ui/core/Step'
import StepButton from '@material-ui/core/StepButton'
import StepLabel from '@material-ui/core/StepLabel'
import Stepper from '@material-ui/core/Stepper'
import steps from 'constants/ecosystemSetupSteps'
import { withRouter } from 'react-router-dom'

const StepperNonLinear = ({ activeStep, ecosystem, history }) => (
  <Stepper nonLinear activeStep={activeStep}>
    <Step key={steps[0]}>
      <StepLabel completed>Create ecosystem</StepLabel>
    </Step>
    {steps.map(({ label, path }, index) => {
      return (
        <Step key={label}>
          <StepButton
            onClick={() => history.push(`/ecosystems/${ecosystem.id}/setup/${path}`)}
            completed={index + 1 < activeStep}
          >
            <StepLabel>{label}</StepLabel>
          </StepButton>
        </Step>
      )
    })}
  </Stepper>
)

export default withRouter(StepperNonLinear)
