import { createCollectionMutation, collectionsQuery } from 'api/queries'
import { CollectionCreate, injectLocalizedValues, prepareVariables } from 'components'
import PropTypes from 'prop-types'
import React from 'react'
import { graphql } from 'react-apollo'
import { toastr } from 'react-redux-toastr'
import { compose, withHandlers } from 'recompose'
import uploadFile from 'utils/fileUpload'
import { ecosystemLocales } from 'utils/locales'
import transformError from 'utils/transformError'

class Container extends React.Component {
  render() {
    const { handleSubmit, ecosystem } = this.props
    let initialValues = {
      name: '',
      slug: '',
      description: '',
      cardStyle: '',
      visible: true,
      hiddenFromHomepage: false,
      showMaxItemsHomepage: '',
      logo: [],
      sequence: 1,
      locales: ecosystemLocales(ecosystem)
    }

    initialValues = injectLocalizedValues('collection', initialValues)

    return (
      <CollectionCreate
        initialValues={initialValues}
        handleSubmit={handleSubmit}
        ecosystem={ecosystem}
      />
    )
  }
}

Container.propTypes = {
  activeAccount: PropTypes.object.isRequired
}

const addCollectionToStore = (store, { ecosystemId, accountId }, collection) => {
  const data = store.readQuery({
    query: collectionsQuery,
    variables: {
      id: ecosystemId,
      accountId
    }
  })

  data.marketplace.collections.push(collection)

  store.writeQuery({
    query: collectionsQuery,
    data,
    variables: {
      id: ecosystemId,
      accountId
    }
  })
}

const handleSubmit = withHandlers({
  handleSubmit:
    ({
      createCollection,
      activeAccount,
      ecosystem,
      history,
      match: {
        params: { ecosystemId }
      }
    }) =>
    async (props, { setSubmitting, setErrors }) => {
      setSubmitting(true)
      try {
        const {
          name,
          slug,
          description,
          cardStyle,
          visible,
          hiddenFromHomepage,
          showMaxItemsHomepage,
          logo
        } = props

        let variables = {
          accountId: activeAccount.id,
          ecosystemId,
          name,
          slug,
          description,
          cardStyle,
          visible,
          hiddenFromHomepage,
          showMaxItemsHomepage: showMaxItemsHomepage || null,
          translations: prepareVariables(props, ecosystem, [])
        }

        if (logo.length !== 0) {
          let result = await uploadFile(logo[0], {
            params: {
              folder: `marketplaces/${ecosystemId}/collections`
            }
          })

          variables = {
            ...variables,
            logo: {
              url: result.url,
              contentType: `${result.resourceType}/${result.format}`
            }
          }
        }

        // create collection
        await createCollection({
          variables,
          update: (store, { data: { createCollection } }) =>
            addCollectionToStore(
              store,
              {
                ecosystemId,
                accountId: activeAccount.id
              },
              createCollection
            )
        })

        toastr.success('Collection created')
        history.push(`/ecosystems/${ecosystemId}/listings/collections`)
      } catch (error) {
        toastr.error('Could not create collection')
        if (error.graphQLErrors) {
          setErrors(transformError(error.graphQLErrors))
        }

        setSubmitting(false)
      }
    }
})
const createCollection = graphql(createCollectionMutation, {
  name: 'createCollection'
})

const CreateCollectionContainer = compose(createCollection, handleSubmit)(Container)

export default CreateCollectionContainer
