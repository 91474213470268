import ChevronLeft from '@material-ui/icons/ChevronLeft'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import React from 'react'
import { withStyles } from '@material-ui/core/styles'

const styles = (theme) => ({
  headerWrapper: {
    backgroundColor: '#FFF'
  },
  header: {
    margin: '0 auto',
    padding: '0 var(--main-padding-horizontal)',
    minHeight: '145px',
    paddingTop: '24px',
    borderBottom: `1px solid ${theme.palette.ui[200]}`,
    '& h1': {
      fontWeight: 500,
      fontFamily: 'var(--font-family-basier)' // TODO add to theme
    }
  },
  row: {
    justifyContent: 'space-between'
  },
  backLink: {
    display: 'flex',
    color: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.primary.dark
    }
  }
})

const HeaderWithTabs = ({ children, classes, tabs, backLink }) => {
  return (
    <div className={classes.headerWrapper}>
      <div className={classes.header}>
        {backLink && (
          <Link to={backLink}>
            <div className={classes.backLink}>
              <ChevronLeft fontSize="small" />
              Back
            </div>
          </Link>
        )}
        <div className={classes.row}>{children}</div>
        {tabs}
      </div>
    </div>
  )
}

HeaderWithTabs.propTypes = {
  tabs: PropTypes.object.isRequired
}

export default withStyles(styles)(HeaderWithTabs)
