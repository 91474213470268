import { loader } from 'graphql.macro'
import { toastr } from 'react-redux-toastr'
import { useMutation } from 'react-apollo'

const INVITE_CONTACTS = loader('../contacts/invite.graphql')

function useInviteContactsAction({ accountId, ecosystemId }) {
  const [inviteContactsMutation] = useMutation(INVITE_CONTACTS)

  async function handleInviteContacts(contacts) {
    try {
      await inviteContactsMutation({
        variables: {
          accountId,
          ecosystemId,
          ids: contacts
        }
      })

      toastr.success('Contact invited')
    } catch (error) {
      toastr.error('Contact could not be invited')
    }
  }

  return [handleInviteContacts]
}

export { useInviteContactsAction }
