import { ImgPreview } from 'components/Helpers'
import PropTypes from 'prop-types'
import React from 'react'
import { Field, reduxForm } from 'redux-form'

const SettingsForm = ({ handleSubmit, initialValues }) => {
  return (
    <form onSubmit={handleSubmit} data-testid="account-profile-form">
      <ImgPreview image={initialValues.imageUrl} />
      <label htmlFor="name">Name</label>
      <Field name="name" component="input" type="text" />
      <br />
      <label htmlFor="email">Email</label>
      <Field name="email" component="input" placeholder="Email" type="text" required />
      <br />
      <label htmlFor="emailSubscription">Stay up to date about product updates and news?</label>
      <Field
        style={{ display: 'block', width: 'auto' }}
        name="emailSubscription"
        id="emailSubscription"
        component="input"
        type="checkbox"
      />
      <br />
      <button type="submit">Save</button>
    </form>
  )
}

SettingsForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired
}

export default reduxForm({ form: 'userSettingsForm' })(SettingsForm)
