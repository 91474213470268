import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import { DeleteDialog } from 'components'
import UpdateContactDialog from './UpdateDialog'

function ContactActionsMenu({
  handleContactDelete,
  handleContactUpdate,
  handleContactsInvite,
  contact
}) {
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const UpdateMenuItemContent = (
    <UpdateContactDialog
      handleSubmit={handleContactUpdate}
      contact={contact}
      opener={<p>Edit</p>}
    />
  )

  const DeleteMenuText = `
    ${contact.lastName} ${contact.firstName} can no longer access your partner portal and will be deleted.
  `

  const DeleteMenuItemContent = (
    <DeleteDialog
      label={'Contact'}
      contentText={DeleteMenuText}
      id={contact.id}
      handleDelete={handleContactDelete}
      opener={<p>Delete</p>}
    />
  )

  const InviteMenuItemContent = (
    <p
      onClick={() => {
        handleContactsInvite([contact.id])
      }}
    >
      Invite
    </p>
  )

  return (
    <Fragment>
      <MoreHorizIcon aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick} />
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {contact.status !== 'Activated' ? <MenuItem>{InviteMenuItemContent}</MenuItem> : ''}
        <MenuItem>{UpdateMenuItemContent}</MenuItem>
        <MenuItem>{DeleteMenuItemContent}</MenuItem>
      </Menu>
    </Fragment>
  )
}

ContactActionsMenu.propTypes = {
  contact: PropTypes.object.isRequired,
  handleContactUpdate: PropTypes.func.isRequired,
  handleContactDelete: PropTypes.func.isRequired,
  handleContactsInvite: PropTypes.func.isRequired
}

export default ContactActionsMenu
