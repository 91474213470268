import { LayoutHeader as Header } from 'components'
import { useMaxListingsContext } from 'context/useMaxListingsContext'
import React from 'react'

const NetworkHeader = ({ ecosystemId }) => {
  const { maxListingsReached, infoMessage } = useMaxListingsContext()

  return (
    <Header
      title="Add a listing"
      url={`/ecosystems/${ecosystemId}/listings/create`}
      btnName="Create a custom listing"
      btnDisabled={maxListingsReached}
      btnTooltip={infoMessage}
    />
  )
}

export default NetworkHeader
