import React from 'react'
import PropTypes from 'prop-types'
import Form from './Form'

const Overview = ({
  handleToggle,
  handleSubmit,
  handleComplete,
  initialValues,
  ecosystem,
  service
}) => (
  <Form
    handleSubmit={handleSubmit}
    handleToggle={handleToggle}
    handleComplete={handleComplete}
    initialValues={initialValues}
    ecosystem={ecosystem}
    service={service}
  />
)

Overview.propTypes = {
  handleToggle: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
  service: PropTypes.object
}

export default Overview
