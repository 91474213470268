import { AccountPortalContainer, IndexContainer } from 'pages'
import { Redirect, Route, Switch } from 'react-router-dom'

import { AccountContainer } from 'containers'
import { EcosystemRoutesContainer } from 'routes'
import { NotFound } from 'components'
import PrivateRoute from 'components/PrivateRoute'
import PrivateRouteWithSidebarAndFooter from 'components/PrivateRouteWithSidebarAndFooter'
import React from 'react'

const PrivateRoutes = ({ activeAccount, user }) => {
  return (
    <Switch>
      <PrivateRoute
        exact
        path="/"
        activeAccount={activeAccount}
        user={user}
        component={IndexContainer}
      />
      <PrivateRoute
        path="/accounts/portal"
        component={AccountPortalContainer}
        activeAccount={activeAccount}
        user={user}
      />

      {/* DEEPLINKS */}
      <Redirect
        path="/dashboard"
        to={{
          pathname: `/ecosystems/${getFirstMarketplaceIdForActiveAccount(activeAccount)}/dashboard`
        }}
      />
      <Redirect
        path="/listings/overview"
        to={{
          pathname: `/ecosystems/${getFirstMarketplaceIdForActiveAccount(
            activeAccount
          )}/listings/overview`
        }}
      />
      <Redirect
        path="/listings/add"
        to={{
          pathname: `/ecosystems/${getFirstMarketplaceIdForActiveAccount(
            activeAccount
          )}/listings/add`
        }}
      />
      <Redirect
        path="/listings/categories"
        to={{
          pathname: `/ecosystems/${getFirstMarketplaceIdForActiveAccount(
            activeAccount
          )}/listings/categories`
        }}
      />
      <Redirect
        path="/listings/collections"
        to={{
          pathname: `/ecosystems/${getFirstMarketplaceIdForActiveAccount(
            activeAccount
          )}/listings/collections`
        }}
      />
      <Redirect
        path="/listings/automated"
        to={{
          pathname: `/ecosystems/${getFirstMarketplaceIdForActiveAccount(
            activeAccount
          )}/listings/automated`
        }}
      />
      <Redirect
        path="/voting-portal/requests"
        to={{
          pathname: `/ecosystems/${getFirstMarketplaceIdForActiveAccount(
            activeAccount
          )}/voting-portal/requests`
        }}
      />
      <Redirect
        path="/partners"
        to={{
          pathname: `/ecosystems/${getFirstMarketplaceIdForActiveAccount(
            activeAccount
          )}/partners/overview`
        }}
      />
      <Redirect
        path="/partners/overview"
        to={{
          pathname: `/ecosystems/${getFirstMarketplaceIdForActiveAccount(
            activeAccount
          )}/partners/overview`
        }}
      />
      <Redirect
        path="/settings/integrations"
        to={{
          pathname: `/ecosystems/${getFirstMarketplaceIdForActiveAccount(
            activeAccount
          )}/settings/integrations`
        }}
      />
      <Redirect
        path="/settings/leads"
        to={{
          pathname: `/ecosystems/${getFirstMarketplaceIdForActiveAccount(
            activeAccount
          )}/settings/leads`
        }}
      />
      <Redirect
        path="/settings/template"
        to={{
          pathname: `/ecosystems/${getFirstMarketplaceIdForActiveAccount(
            activeAccount
          )}/settings/template`
        }}
      />
      <Redirect
        path="/settings/custom-domain"
        to={{
          pathname: `/ecosystems/${getFirstMarketplaceIdForActiveAccount(
            activeAccount
          )}/settings/custom-domain`
        }}
      />
      <Route path="/ecosystems/:ecosystemId">
        <EcosystemRoutesContainer activeAccount={activeAccount} user={user} />
      </Route>
      <PrivateRouteWithSidebarAndFooter
        path="/account"
        activeAccount={activeAccount}
        user={user}
        component={AccountContainer}
      />

      {/* fallback route */}
      <Route component={NotFound} />
    </Switch>
  )
}

const getFirstMarketplaceIdForActiveAccount = (activeAccount) => {
  if (activeAccount && activeAccount.marketplaces && activeAccount.marketplaces[0]) {
    return activeAccount.marketplaces[0].id
  }
}

export default PrivateRoutes
