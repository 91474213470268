import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import Fade from '@material-ui/core/Fade'
import Modal from '@material-ui/core/Modal'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import { Formik } from 'formik'

import { IconButton, ModalParentView, ModalView, TextInputNew as TextInput } from 'components'
import React, { Fragment, useState } from 'react'
import { useStyles } from './integrations-settings/IntegrationsSettingsModal'
import { useStyles as useModalStyles } from './integrations-settings/IntegrationsSettingsModalContainer'

const DeleteEcosystemModal = ({ isOpen, closeModal, ecosystem, handleEcosystemDelete }) => {
  const classes = useStyles()
  const modalClasses = useModalStyles()
  const [isLoading, setIsLoading] = useState(false)

  const handleSubmit = async ({ challenge }) => {
    setIsLoading(true)
    try {
      await handleEcosystemDelete(ecosystem.id, challenge)
    } catch (error) {
      console.log('error', error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Modal className={modalClasses.modal} open={isOpen} closeAfterTransition onClose={closeModal}>
      <Fade in={isOpen}>
        <Card className={classes.card}>
          <ModalParentView activeModalViewId={'default'}>
            <ModalView id="default">
              <Fragment>
                <CardHeader
                  className={classes.cardHeader}
                  classes={{
                    action: classes.action
                  }}
                  avatar={
                    ecosystem.icon?.url || ecosystem.favicon?.url ? (
                      <Avatar
                        className={classes.avatar}
                        src={ecosystem.icon?.url || ecosystem.favicon?.url}
                        variant="rounded"
                      />
                    ) : null
                  }
                  action={
                    <div className={classes.cardHeaderActions}>
                      <Tooltip title="Close" placement="top" arrow>
                        <div className={classes.buttonClose}>
                          <IconButton onClick={closeModal}>
                            <CloseIcon style={{ fontSize: 24 }} />
                          </IconButton>
                        </div>
                      </Tooltip>
                    </div>
                  }
                  title={`Are you sure you would like to delete "${ecosystem.name}"?`}
                  titleTypographyProps={{
                    className: classes.title
                  }}
                  subheader="Deleting this ecosystem can only be done by the owner. This action can not be undone."
                  subheaderTypographyProps={{
                    className: classes.subheader
                  }}
                />

                <Formik
                  onSubmit={(values) => handleSubmit(values)}
                  initialValues={{ challenge: '' }}
                  validate={(values) => {
                    const errors = {}
                    if (values?.challenge === '') {
                      errors.challenge = 'This field is required'
                    }
                    if (
                      values?.challenge?.toLocaleLowerCase() !== ecosystem.name?.toLocaleLowerCase()
                    ) {
                      errors.challenge = 'The input does not match the ecosystem name'
                    }
                    return errors
                  }}
                  validateOnChange={false}
                >
                  {(formikProps) => {
                    const { handleSubmit } = formikProps

                    return (
                      <form className={classes.form} onSubmit={handleSubmit}>
                        <div className={classes.formContent}>
                          <TextInput
                            formikProps={formikProps}
                            label="Ecosystem name"
                            field="challenge"
                            required
                            placeholder={ecosystem.name}
                          />

                          <Typography className={classes.fieldDescription}>
                            Enter the ecosystem name to confirm
                          </Typography>
                        </div>
                        <div className={classes.formFooter}>
                          <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            className="delete"
                            disabled={isLoading}
                          >
                            {isLoading ? 'Deleting..' : 'Delete'}
                          </Button>
                        </div>
                      </form>
                    )
                  }}
                </Formik>
              </Fragment>
            </ModalView>
          </ModalParentView>
        </Card>
      </Fade>
    </Modal>
  )
}

export default DeleteEcosystemModal
