import { Global, css } from '@emotion/core'
import { createAccountAndAddUserAsOwnerMutation, createMarketplaceMutation } from 'api/queries'
import React, { useEffect, useState } from 'react'
import { graphql, withApollo } from 'react-apollo'
import { useLocation, withRouter } from 'react-router-dom'
import { getItem, setItem } from 'utils/localstorage'

import { currentUserQuery } from 'api'
import { EcosystemCreate as CreateForm } from 'components'
import { toastr } from 'react-redux-toastr'
import { useIntercom } from 'react-use-intercom'
import { compose } from 'recompose'
import slugify from 'slugify'
import { getEcosystemUrl, getUnifyUrl } from 'utils/getUnifyUrl'
import transformError from 'utils/transformError'
import ChatSidebarFooter from '../../components/ecosystem/create/sidebar/ChatSidebarFooter'
import CreateSidebarContent from '../../components/ecosystem/create/sidebar/CreateSidebarContent'
import SignupSetupLayout from '../../components/layout/SignupSetup'

const isNonEmptyArray = (data) => Array.isArray(data) && data.length > 0

const getInitialValues = (serviceData, activeAccount) => {
  const c = 'abcdefghijklmnopqrstuvwxyz0123456789'
  const randomizedString = [...Array(10)].map(() => c[~~(Math.random() * c.length)]).join('')

  if (activeAccount) {
    return {
      name: activeAccount?.name,
      website: activeAccount?.website.replace('https://', ''),
      slug: activeAccount?.name
        ? slugify(activeAccount?.name).toLowerCase() + '-' + randomizedString
        : randomizedString,
      listingNaming: 'INTEGRATIONS'
    }
  }

  return {
    name: serviceData?.name ?? '',
    website: serviceData?.websiteUrl ?? '',
    slug: serviceData?.name
      ? slugify(serviceData.name).toLowerCase() + '-' + randomizedString
      : randomizedString,
    listingNaming: 'INTEGRATIONS'
  }
}

const Container = ({ history, data, createAccountMutation, createMarketplaceMutation }) => {
  const [currentAccount, setCurrentAccount] = useState(null)
  const [ecosystemId, setEcosystemId] = useState(null)
  const location = useLocation()
  const serviceData = history?.location?.state?.serviceData
  const refetchCurrentUser = data.refetch
  const { hide: hideIntercom } = useIntercom()

  useEffect(() => {
    hideIntercom()
  }, [hideIntercom])

  useEffect(() => {
    if (currentAccount) {
      setItem('activeAccountId', currentAccount.id)
    }
  }, [currentAccount])

  useEffect(() => {
    if (data.currentUser && isNonEmptyArray(data.currentUser?.accounts)) {
      const activeAccountId = getItem('activeAccountId') // string | null
      const activeAccountUser = data.currentUser?.accounts.find(
        (accountUser) => accountUser.account.id === activeAccountId
      )
      setCurrentAccount(activeAccountUser?.account ?? data.currentUser.accounts[0]?.account)
    }
  }, [data.currentUser])

  useEffect(() => {
    const refreshUserAndRedirect = async () => {
      await refetchCurrentUser()

      if (getItem('lastProductUsed') === 'ecosystem') {
        window.location.href = getEcosystemUrl()
      } else {
        const name = data.currentUser?.email?.split('@')[0]
        const serviceData = { name, applicationName: `${name}'s app` }
        window.location.href = getUnifyUrl({ serviceData, showOnboarding: true })
      }
    }

    if (ecosystemId) {
      refreshUserAndRedirect()
    }
  }, [history, ecosystemId, refetchCurrentUser, data.currentUser?.email])

  const handleCreateMarketplace = async ({ accountId, name, website, slug }) => {
    const marketplaceVariables = {
      accountId,
      name,
      slug,
      website,
      primaryColor: serviceData && serviceData.primaryColor ? serviceData.primaryColor : '#000000'
    }

    const {
      data: { createMarketplace }
    } = await createMarketplaceMutation({
      variables: marketplaceVariables
    })

    const ecosystemId = createMarketplace.id
    setItem('activeEcosystemId', ecosystemId)
    setEcosystemId(ecosystemId)
  }

  const handleSubmit = async ({ name, website, slug }, { setSubmitting, setErrors }) => {
    setSubmitting(true)
    const formattedWebsite = `https://${website}`
    let account = currentAccount

    try {
      if (!account) {
        // create account and add user as owner
        const accountVariables = {
          name,
          website: formattedWebsite,
          emailSubscription: false,
          referer: location?.state?.referer || null
        }
        // if planDetails are present in localStorage, add to variables
        const planDetails = JSON.parse(getItem('planDetails'))

        if (planDetails) {
          accountVariables.plan = planDetails.plan
          accountVariables.billingCycle = planDetails.cycle
          accountVariables.currency = planDetails.currency
        }

        const {
          data: { createAccountAndAddUserAsOwner }
        } = await createAccountMutation({ variables: accountVariables })

        account = createAccountAndAddUserAsOwner?.accounts?.[0]?.account
        if (!account) {
          throw new Error('Account not created')
        }

        setCurrentAccount(account)
      }

      // account exists, now add an ecosystem to it
      await handleCreateMarketplace({
        accountId: account?.id,
        name,
        website: formattedWebsite,
        slug
      })
    } catch (error) {
      toastr.error('Something went wrong. Please try again later.')
      if (error.graphQLErrors) {
        setErrors(transformError(error.graphQLErrors))
      }
      setSubmitting(false)
    }
  }

  const { loading, error } = data

  if (error) {
    toastr.error('Error', transformError(error))
  }

  if (loading) {
    return false
  }

  return (
    <SignupSetupLayout
      SidebarContent={<CreateSidebarContent hasEcosystem={!!ecosystemId} />}
      SidebarFooter={<ChatSidebarFooter />}
    >
      <Global
        styles={css`
          body {
            background-color: white;
          }
        `}
      />
      <CreateForm
        initialValues={getInitialValues(serviceData, currentAccount)}
        handleSubmit={handleSubmit}
        iconUrl={serviceData ? serviceData.iconUrl : null}
        activeAccount={currentAccount}
      />
    </SignupSetupLayout>
  )
}

const CreateMarketplaceContainer = compose(
  withRouter,
  withApollo,
  graphql(currentUserQuery),
  graphql(createAccountAndAddUserAsOwnerMutation, {
    name: 'createAccountMutation'
  }),
  graphql(createMarketplaceMutation, {
    name: 'createMarketplaceMutation'
  })
)(Container)

export default CreateMarketplaceContainer
