import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import { Fieldset, DeleteDialog } from 'components'

function Delete({ handleDelete }) {
  return (
    <Fieldset title="Danger Zone" style={{ marginTop: 30 }}>
      <p>Deleting this partner is a permanent change that can not be undone.</p>
      <DeleteDialog
        handleDelete={handleDelete}
        label="partner"
        opener={
          <Button className="delete" variant="contained" startIcon={<DeleteForeverIcon />}>
            Delete
          </Button>
        }
      />
    </Fieldset>
  )
}

Delete.propTypes = {
  handleDelete: PropTypes.func.isRequired
}

export default Delete
