import listings from './listings'
import partners from './partners'
import services from './services'
import contacts from './contacts'

export default {
  contacts,
  listings,
  partners,
  services
}
