import { partnerQuery } from 'api'
import { ErrorFallback, LoadingPlaceholder } from 'components'
import PropTypes from 'prop-types'
import React from 'react'
import { useQuery } from 'react-apollo'
import { useParams } from 'react-router-dom'
import PartnerLayout from './Layout'

function PartnerPage({ activeAccount, ecosystem }) {
  const { partnerId } = useParams()
  const { loading, error, data } = useQuery(partnerQuery, {
    variables: {
      accountId: activeAccount.id,
      ecosystemId: ecosystem.id,
      id: partnerId
    }
  })

  if (loading) {
    return <LoadingPlaceholder />
  }

  if (error) {
    return <ErrorFallback error={error} />
  }

  return (
    <PartnerLayout activeAccount={activeAccount} ecosystem={ecosystem} partner={data.partner} />
  )
}

PartnerPage.propTypes = {
  activeAccount: PropTypes.object.isRequired,
  ecosystem: PropTypes.object.isRequired
}

export default PartnerPage
