import { fileFields, translationFields } from 'api/queries'
import gql from 'graphql-tag'

const categoryOverviewQuery = gql`
  query categoryOverview($accountId: ID!, $ecosystemId: ID!, $categoryId: ID!) {
    category(id: $categoryId, accountId: $accountId) {
      id
      name
      slug
      description
      logo {
        ...fileFields
      }
      translations {
        ...translationFields
      }
      listingDescriptionTextTemplate
      listingPricingTextTemplate
      listingFeaturesTextTemplate
      listings {
        id
        name
        links {
          id
          url
          type
        }
        logo {
          ...fileFields
        }
        translations {
          ...translationFields
        }
        state
      }
    }
    marketplace(id: $ecosystemId, accountId: $accountId) {
      id
      listings {
        id
        name
        slug
        logo {
          ...fileFields
        }
        categories {
          id
        }
        translations {
          ...translationFields
        }
        state
      }
    }
  }

  ${fileFields}
  ${translationFields}
`

export default categoryOverviewQuery
