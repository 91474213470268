import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

const ModalView = ({ children }) => {
  return <Fragment>{children}</Fragment>
}

ModalView.propTypes = {
  children: PropTypes.node.isRequired
}

export default ModalView
