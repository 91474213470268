import React from 'react'
import PropTypes from 'prop-types'
import SeoForm from './forms/Seo'

const Overview = ({ handleSubmit, initialValues }) => (
  <SeoForm handleSubmit={handleSubmit} initialValues={initialValues} />
)

Overview.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired
}

export default Overview
