import Tooltip from '@material-ui/core/Tooltip'
import DeleteIcon from '@material-ui/icons/DeleteForever'
import { IconButton, Small, Thumbnail } from 'components'
import React, { useEffect, useState } from 'react'
import Dropzone from 'react-dropzone'
import styled from 'react-emotion'
import { borderColor, spacing } from 'styles/styles'
const dropzoneStyle = {
  padding: spacing.space2,
  width: '100%',
  height: '150px',
  borderWidth: '2px',
  borderColor: borderColor,
  borderStyle: 'dashed',
  borderRadius: '4px',
  textAlign: 'center'
}

const Div = styled('div')`
  font-size: 14px;
`

const FileUploadInput = ({
  accept,
  description,
  field,
  formikProps,
  label,
  maxSize,
  multiple,
  required,
  handleDelete
}) => {
  const { values, errors, setFieldValue } = formikProps
  const [persisted, setPersisted] = useState(values[field].length > 0 && values[field][0]?.id)

  useEffect(() => {
    if (handleDelete) {
      return () => {
        setPersisted(values[field].length > 0 && values[field][0]?.id)
      }
    }
  }, [values, field, handleDelete])

  return (
    <div className="fieldWrapper">
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'end',
          marginBottom: '10px'
        }}
      >
        <label htmlFor={field} style={{ marginBottom: 0 }}>
          {label}
          {required && <span style={{ color: '#FF5630' }}>*</span>}
        </label>

        {handleDelete && values[field].length > 0 && (
          <Tooltip title="Delete this image" placement="top" arrow>
            <div>
              <IconButton
                onClick={(e) => {
                  setFieldValue(field, [])
                  persisted && handleDelete()
                  e.preventDefault()
                }}
              >
                <DeleteIcon style={{ fontSize: 24 }} />
              </IconButton>
            </div>
          </Tooltip>
        )}
      </div>
      {errors[field] && <span className="error">{errors[field]}</span>}
      <Dropzone
        id={field}
        name={field}
        style={dropzoneStyle}
        multiple={multiple}
        accept={accept || 'image/*'}
        required={required}
        maxSize={maxSize}
        onDrop={(files) => {
          // do nothing if no files
          if (files.length === 0) {
            return
          }

          // on drop we add to the existing files
          if (multiple) {
            setFieldValue(field, values[field].concat(files))
          } else {
            setFieldValue(field, [files[0]])
          }
        }}
      >
        {({ isDragActive, isDragReject }) => {
          if (isDragActive) {
            return 'This file is authorized'
          }

          if (isDragReject) {
            return 'This file is not authorized'
          }

          if (values[field].length === 0) {
            return (
              <Div>
                <img
                  src="https://static.filestackapi.com/picker/v3/0.12.5/assets/images/icon-add-files-grey.svg"
                  alt=""
                  style={{
                    display: 'block',
                    margin: '0 auto',
                    marginBottom: spacing.space2
                  }}
                />
                Try dropping some files here, or click to select files to upload.
              </Div>
            )
          }

          return values[field].map((file, i) => <Thumbnail key={i} file={file} />)
        }}
      </Dropzone>
      <div className="flex flex-row justify-between">
        <Small style={{ margin: 0, textAlign: 'left' }}>{description}</Small>
      </div>
    </div>
  )
}

export default FileUploadInput
