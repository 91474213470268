import NetworkContainerWithoutServices from 'features/network/ContainerWithoutServices'
import PropTypes from 'prop-types'
import React from 'react'
import Grid from './Grid'

function MatchedServices(props) {
  const joinServicesWithListings = (services, listings) => {
    return services.map((service) => {
      const listing = listings && listings.find((l) => l.slug === service.id)

      return Object.assign(
        {
          imported: true,
          listingId: listing?.id
        },
        service
      )
    })
  }

  return (
    <NetworkContainerWithoutServices
      accountId={props.activeAccount.id}
      ecosystemId={props.ecosystem.id}
    >
      {({ deleteListing, importService }) => {
        const services = joinServicesWithListings(props.services, props.ecosystem.listings)

        const handleDelete = async (service) => {
          await deleteListing.mutation({
            variables: {
              accountId: props.activeAccount.id,
              id: service.listingId
            }
          })
        }

        const handleImport = async (service) => {
          await importService.mutation({
            variables: {
              accountId: props.activeAccount.id,
              ecosystemId: props.ecosystem.id,
              id: service.id
            }
          })
        }

        const handleServiceClick = async (service) => {
          if (service.listingId) {
            await handleDelete(service)
          } else {
            await handleImport(service)
          }
        }

        return <Grid handleServiceClick={handleServiceClick} services={services} />
      }}
    </NetworkContainerWithoutServices>
  )
}

MatchedServices.propTypes = {
  activeAccount: PropTypes.object.isRequired,
  ecosystem: PropTypes.object.isRequired,
  services: PropTypes.array.isRequired
}

export default MatchedServices
