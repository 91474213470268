import React from 'react'
import PropTypes from 'prop-types'
import { compose, setPropTypes } from 'recompose'
import { AccountLayout as Layout } from 'components'

const AccountContainer = (props) => <Layout {...props} />

export default compose(
  setPropTypes({
    activeAccount: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired
  })
)(AccountContainer)
