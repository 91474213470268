import { Card, CardEmptyState, MediaDialog, SecondaryButton } from 'components'

import Button from '@material-ui/core/Button'
import React from 'react'
import ReactPlayer from 'react-player'
import getLocalizedValue from 'utils/getLocalizedValue'
import styled from 'react-emotion'

const VideoWithCaption = styled('div')`
  margin-bottom: 24px;
`

const Media = ({ url, caption, children }) => (
  <VideoWithCaption>
    <ReactPlayer url={url} style={{ marginBottom: 12 }} />
    {caption}
    {children}
  </VideoWithCaption>
)

const MediaCard = ({ media, locales, handleMediaCreate, handleMediaUpdate, handleMediaDelete }) => (
  <Card
    title="Videos"
    button={
      <MediaDialog
        handleSubmit={handleMediaCreate}
        handleDelete={handleMediaDelete}
        locales={locales}
        opener={<SecondaryButton className="fr">+ Add</SecondaryButton>}
      />
    }
  >
    {media.length > 0 ? (
      media.map((media, index) => (
        <Media url={media.url} caption={getLocalizedValue(media, 'caption', locales)} key={index}>
          <MediaDialog
            handleSubmit={handleMediaUpdate}
            handleDelete={handleMediaDelete}
            media={media}
            key={media.id}
            locales={locales}
            opener={
              <Button type="text" variant="outlined" color="primary">
                Edit
              </Button>
            }
          />
        </Media>
      ))
    ) : (
      <MediaDialog
        handleSubmit={handleMediaCreate}
        handleDelete={handleMediaDelete}
        locales={locales}
        opener={
          <CardEmptyState>
            A video says more than a thousand screenshots.
            <br /> Get started by adding a video and describe your integration use case.
          </CardEmptyState>
        }
      />
    )}
  </Card>
)

export default MediaCard
