import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import MUICard from '@material-ui/core/Card'
import indigo from '@material-ui/core/colors/indigo'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import { CardNew as Card } from 'components'
import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'
import slugify from 'slugify'
import ecosystemUrl from 'utils/ecosystemUrl'

const REQUESTED_INTEGRATIONS_TOOLTIP_CONTENT = (listingNaming = 'LISTINGS') =>
  `A collection of all the requested ${listingNaming.toLowerCase()} by visitors of your ecosystem through the request form.`

const useStyles = makeStyles((theme) => ({
  gridCard: {
    border: `1px solid ${theme.palette.ui[200]}`,
    borderRadius: '8px'
  },
  avatarContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(4)
  },
  avatar: {
    width: '36px',
    height: '36px'
  },
  metadataContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderTop: `1px solid ${theme.palette.ui[200]}`,
    padding: theme.spacing(1, 1.5)
  },
  service: {
    fontSize: '15px',
    color: theme.palette.ui[600],
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  votesContainer: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: theme.spacing(2)
  },
  arrowUpIcon: {
    fontSize: '15px'
  },
  amount: {
    fontSize: '13px',
    color: theme.palette.ui[400],
    marginLeft: '2px'
  },
  placeholder: {
    padding: 0
  }
}))

const RequestIntegrationButton = ({ ecosystem }) => (
  <Button
    className="fr"
    style={{ marginLeft: 10 }}
    target="_blank"
    href={`${ecosystemUrl(ecosystem)}/request`}
    endIcon={
      <OpenInNewIcon
        style={{
          color: 'currentColor',
          fontSize: 16
        }}
      />
    }
  >
    Integration request form
  </Button>
)

const RequestsPage = ({ ecosystem, votes }) => {
  const classes = useStyles()
  const { listingNaming } = ecosystem

  return (
    <Card
      title="Requests"
      tooltipContent={REQUESTED_INTEGRATIONS_TOOLTIP_CONTENT(listingNaming)}
      action={
        <React.Fragment>
          <RequestIntegrationButton ecosystem={ecosystem} />
        </React.Fragment>
      }
    >
      <div data-testid="ecosystem-voting-portal-requests-view">
        {votes.length > 0 ? (
          <Grid container spacing={2}>
            {votes.map((vote, index) => {
              const { name, amount } = vote

              return (
                <Grid key={`${name}+${index}`} item xl={2} lg={3} sm={4} xs={12}>
                  <Link to={`/ecosystems/${ecosystem.id}/leads/request-form?${name}`}>
                    <MUICard variant="outlined" className={classes.gridCard}>
                      <div className={classes.avatarContainer}>
                        <Avatar
                          variant="rounded"
                          src={`https://res.cloudinary.com/apideck/icons/${slugify(
                            name
                          ).toLowerCase()}`}
                          className={classes.avatar}
                        >
                          {name.substring(0, 1)}
                        </Avatar>
                      </div>
                      <div className={classes.metadataContainer}>
                        {name.length > 15 ? (
                          <Tooltip title={name} arrow placement="top">
                            <Typography className={classes.service}>{name}</Typography>
                          </Tooltip>
                        ) : (
                          <Typography className={classes.service}>{name}</Typography>
                        )}
                        <div className={classes.votesContainer}>
                          <ArrowUpwardIcon
                            htmlColor={indigo[100]}
                            className={classes.arrowUpIcon}
                          />
                          <Typography className={classes.amount}>{amount}</Typography>
                        </div>
                      </div>
                    </MUICard>
                  </Link>
                </Grid>
              )
            })}
          </Grid>
        ) : (
          <Typography variant="caption" className={classes.placeholder}>
            No requests available yet.
          </Typography>
        )}
      </div>
    </Card>
  )
}

RequestsPage.propTypes = {
  votes: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      amount: PropTypes.number.isRequired
    }).isRequired
  ).isRequired
}

export default RequestsPage
