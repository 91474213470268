import environment from './environment'

const getQueryString = (params) => {
  let esc = encodeURIComponent
  return Object.keys(params)
    .map((k) => `${esc(k)}=${esc(params[k])}`)
    .join('&')
}

const uploadFile = (file, options) => {
  let url = `https://api.cloudinary.com/v1_1/${environment.cloudinary.name}/upload`
  url += `?${getQueryString(options.params)}`

  const formData = new FormData()
  formData.append('file', file)
  formData.append('upload_preset', environment.cloudinary.preset)

  return fetch(url, {
    method: 'POST',
    body: formData
  })
    .then((response) => {
      return response.json()
    })
    .then((result) => ({
      url: result.secure_url,
      format: result.format,
      resourceType: result.resource_type
    }))
    .catch(console.error)
}

export default uploadFile
