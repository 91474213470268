import { ImgPreview } from 'components/Helpers'
import React from 'react'
import { Field, reduxForm } from 'redux-form'

const SettingsForm = ({ handleSubmit, initialValues }) => (
  <form onSubmit={handleSubmit} data-testid="account-settings-form">
    {initialValues.imageUrl && <ImgPreview image={initialValues.imageUrl} />}
    <label htmlFor="name">Name</label>
    <Field name="name" component="input" type="text" required />
    <br />
    <label htmlFor="imageUrl">Image URL</label>
    <Field name="imageUrl" component="input" type="text" />
    <br />
    <label htmlFor="website">Website</label>
    <Field name="website" component="input" type="text" />
    <br />
    <button type="submit">Save</button>
  </form>
)

export default reduxForm({ form: 'accountForm' })(SettingsForm)
