import React from 'react'
import { Query } from 'react-apollo'
import PropTypes from 'prop-types'
import { CollectionLayout, LoadingPlaceholder, ErrorFallback } from 'components'
import { collectionOverviewQuery } from 'pages/private/ecosystem/listings/collections/graphql'

// TODO move to pages folder

const CollectionContainer = ({ activeAccount, match, ecosystem }) => (
  <Query
    query={collectionOverviewQuery}
    variables={{
      accountId: activeAccount.id,
      ecosystemId: ecosystem.id,
      collectionId: match.params.collectionId
    }}
  >
    {({ loading, error, data }) => {
      if (loading) return <LoadingPlaceholder />
      if (error) return <ErrorFallback error={error} />

      const { marketplace, collection } = data
      const { listings } = marketplace

      return (
        <CollectionLayout
          activeAccount={activeAccount}
          collection={collection}
          listings={listings}
          ecosystem={ecosystem}
        />
      )
    }}
  </Query>
)

CollectionContainer.propTypes = {
  activeAccount: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired
}

export default CollectionContainer
