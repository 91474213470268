export const ecosystemLocales = (ecosystem) => {
  const locales = []

  if (ecosystem.locales) {
    for (const locale of ecosystem.locales) {
      locales.push(locale.code)
    }
  } else {
    locales.push('en')
  }

  return locales
}

export const setRootValueFromTranslation = (locales, entity, key, rootValue, locale = 'en') => {
  if (
    locales?.length > 0 &&
    Array.isArray(entity?.translations) &&
    entity.translations.length > 0
  ) {
    const translation = entity.translations.find(
      (f) => f.key === key && f.locale === locale && f.value
    )

    if (translation) {
      return translation.value
    }
  }

  return rootValue || entity[key]
}
