import React from 'react'
import styled from 'react-emotion'

const Input = styled('input')`
  width: 40px;
  float: left;
  margin-bottom: 0;
`

const Wrapper = styled('div')`
  display: flex;
  align-items: center;
`

const Checkbox = (props) => {
  const { field, formikProps, label, disabled = false, ...rest } = props
  const { handleChange, handleBlur, values, touched, errors } = formikProps

  return (
    <Wrapper className="form-element">
      <Input
        id={field}
        name={field}
        checked={values[field]}
        onChange={handleChange}
        onBlur={handleBlur}
        disabled={disabled}
        type="checkbox"
        {...rest}
      />
      <label style={{ marginBottom: 0 }} htmlFor={field}>
        {label}
      </label>
      {touched[field] && errors[field] && <span className="errors">{errors[field]}</span>}
    </Wrapper>
  )
}

export default Checkbox
