import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import ArrowRightIcon from '@material-ui/icons/ArrowRight'
import { Status } from 'components'
import PropTypes from 'prop-types'
import React from 'react'

const useStyles = makeStyles((theme) => ({
  card: {
    margin: theme.spacing(2, 0),
    border: `1px solid ${theme.palette.ui[200]}`
  },
  actionArea: {
    borderBottom: `1px solid ${theme.palette.ui[200]}`,
    '&:last-of-type': {
      borderBottom: 'none'
    }
  },
  cardContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(1.5, 3)
  },
  label: {
    fontSize: '14px',
    color: theme.palette.ui[800]
  },
  valueContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  value: {
    fontSize: '15px',
    color: theme.palette.ui[500]
  },
  icon: {
    marginLeft: theme.spacing(2),
    color: theme.palette.ui[300],
    lineHeight: 1
  }
}))

const SettingsList = ({ setActiveModalViewId, formFields }) => {
  const classes = useStyles()

  return (
    <Card variant="outlined" className={classes.card}>
      {formFields.map((formField) => {
        const { formId: id, label, value, type, translatable, required, options } = formField
        const valueText = translatable ? value[0].value : value
        const labelClasses = required ? `${classes.label} required` : classes.label
        let valueLabel

        if (type === 'select' && value) {
          const selectedOption = options.filter((option) => option.value === value)[0]
          valueLabel = selectedOption && selectedOption.label
        }

        let valueString = ''
        valueString =
          valueText && valueText.length > 50 ? `${valueText.substring(0, 50)}…` : valueText

        return (
          <CardActionArea
            key={id}
            className={classes.actionArea}
            onClick={() => setActiveModalViewId(id)}
          >
            <div className={classes.cardContent}>
              <Typography className={labelClasses} component="label">
                {label}
              </Typography>
              <div className={classes.valueContainer}>
                {type === 'text' && (
                  <Typography className={classes.value}>{valueString}</Typography>
                )}
                {type === 'select' && (
                  <Typography className={classes.value}>{valueLabel}</Typography>
                )}
                {type === 'checkbox' && <Status type={value ? 'enabled' : 'disabled'} />}
                <Typography className={classes.icon}>
                  <ArrowRightIcon />
                </Typography>
              </div>
            </div>
          </CardActionArea>
        )
      })}
    </Card>
  )
}

SettingsList.propTypes = {
  setActiveModalViewId: PropTypes.func.isRequired,
  formFields: PropTypes.arrayOf(
    PropTypes.shape({
      formId: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      value: PropTypes.any,
      type: PropTypes.string.isRequired,
      translatable: PropTypes.bool
    }).isRequired
  ).isRequired,
  connection: PropTypes.shape({
    authType: PropTypes.string,
    authorizeUrl: PropTypes.string,
    revokeUrl: PropTypes.string
  }).isRequired
}

export default SettingsList
