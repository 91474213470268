import { SelectInput, Submit, TextInput } from 'components'
import { Formik } from 'formik'
import PropTypes from 'prop-types'
import React from 'react'
import * as yup from 'yup'

const roles = ['OWNER', 'ADMIN', 'MEMBER']

const schema = yup.object().shape({
  email: yup.string().trim().email().required('Email is a required field'),
  role: yup.mixed().required('Role is a required field')
})

const InviteForm = ({ handleSubmit, initialValues }) => (
  <Formik validationSchema={schema} onSubmit={handleSubmit} initialValues={initialValues}>
    {(formikProps) => {
      const { handleSubmit, isSubmitting } = formikProps

      return (
        <form onSubmit={handleSubmit}>
          <TextInput formikProps={formikProps} type="email" label="Email" field="email" />
          <SelectInput
            formikProps={formikProps}
            label="Role"
            field="role"
            options={roles.map((r) => ({
              label: r,
              value: r
            }))}
          />
          <Submit isSubmitting={isSubmitting} text="Send invite" />
        </form>
      )
    }}
  </Formik>
)

InviteForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired
}

export default InviteForm
