import React from 'react'
import ToggleButton from '@material-ui/lab/ToggleButton'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import ViewListIcon from '@material-ui/icons/ViewList'
import ViewModuleIcon from '@material-ui/icons/ViewModule'

const ListToggle = ({ view, ecosystemId }) => (
  <ToggleButtonGroup orientation="horizontal" value={view} exclusive>
    <ToggleButton
      value="cards"
      aria-label="cards"
      href={`/ecosystems/${ecosystemId}/listings/overview`}
    >
      <ViewModuleIcon />
    </ToggleButton>
    <ToggleButton
      value="list"
      aria-label="list"
      href={`/ecosystems/${ecosystemId}/listings/overview/list`}
    >
      <ViewListIcon />
    </ToggleButton>
  </ToggleButtonGroup>
)

export default ListToggle
