import IconButton from '@material-ui/core/IconButton'
import InputBase from '@material-ui/core/InputBase'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import VisibilityIcon from '@material-ui/icons/Visibility'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React, { useRef, useState } from 'react'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex'
  },
  label: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 500,
    color: theme.palette.ui[500]
  },
  labelRequired: {
    '&::after': {
      content: `'*'`,
      marginLeft: theme.spacing(0.5),
      color: theme.palette.error.main
    }
  },
  inputPrefixSuffix: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1.5),
    borderRadius: '4px',
    border: `1px solid ${theme.palette.ui[250]}`,
    backgroundColor: theme.palette.ui[100],
    color: theme.palette.ui[300]
  },
  inputPrefixSuffixWithError: {
    border: `1px solid ${theme.palette.error.light}`,
    backgroundColor: theme.palette.error.lighter,
    color: theme.palette.error.main
  },
  inputPrefix: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    borderRight: 0
  },
  inputSuffix: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderLeft: 0
  },
  inputContainer: {
    width: '100%',
    position: 'relative'
  },
  input: {
    height: '45px',
    borderRadius: '4px',
    border: `1px solid ${theme.palette.ui[250]}`,
    fontSize: '16px',
    color: theme.palette.ui[800],
    paddingLeft: theme.spacing(1),
    '&:focus': {
      border: `1px solid ${theme.palette.primary.main}`
    },
    '&:disabled': {
      backgroundColor: theme.palette.ui[100]
    }
  },
  hasError: {
    '& input': {
      border: `1px solid ${theme.palette.error.light}`,
      backgroundColor: theme.palette.error.lightest
    }
  },
  errorMessage: {
    marginTop: theme.spacing(1),
    color: theme.palette.error.main,
    fontSize: '13px'
  },
  description: {
    display: 'block',
    marginTop: theme.spacing(1),
    color: theme.palette.ui[400]
  },
  hasPrefix: {
    '& input': {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0
    }
  },
  hasSuffix: {
    '& input': {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0
    }
  },
  actionsContainer: {
    position: 'absolute',
    top: 0,
    right: theme.spacing(2),
    bottom: 0,
    display: 'flex',
    alignItems: 'center'
  },
  actionIcon: {
    color: theme.palette.ui[300]
  },
  actionIconLast: {
    marginLeft: theme.spacing(1)
  }
}))

const TextInput = ({
  field,
  label,
  type,
  required,
  placeholder,
  description,
  disabled,
  sensitive,
  valueCanBeCopied,
  prefix,
  suffix,
  formikProps,
  testid
}) => {
  const classes = useStyles()
  const theme = useTheme()
  const input = useRef()
  const [copied, setCopied] = useState(false)
  const [reveal, setReveal] = useState(false)
  const { handleChange, handleBlur, values, errors } = formikProps
  let paddingRight = theme.spacing(1)
  if (sensitive && valueCanBeCopied) {
    paddingRight = theme.spacing(10)
  } else if (sensitive || valueCanBeCopied) {
    paddingRight = theme.spacing(6)
  }
  const copyToClipboard = () => {
    navigator.clipboard.writeText(values[field])
    setCopied(true)
  }

  return (
    <div>
      {label && (
        <label
          htmlFor={field}
          className={classNames(classes.label, {
            [classes.labelRequired]: required
          })}
        >
          {label}
        </label>
      )}
      <div
        className={classNames(classes.container, {
          [classes.hasError]: errors[field]
        })}
      >
        {prefix && (
          <div
            className={classNames(classes.inputPrefixSuffix, classes.inputPrefix, {
              [classes.inputPrefixSuffixWithError]: errors[field]
            })}
          >
            {prefix}
          </div>
        )}
        <div className={classes.inputContainer}>
          <InputBase
            id={field}
            type={reveal ? 'text' : type}
            name={field}
            required={required}
            placeholder={placeholder}
            disabled={disabled}
            inputProps={{
              ref: input,
              'aria-label': label ? label : 'input',
              style: { paddingRight },
              ...(testid ? { 'data-testid': `${testid}` } : {})
            }}
            value={values[field] ?? ''}
            onChange={handleChange}
            onBlur={handleBlur}
            error={!!errors[field]}
            classes={{
              input: classes.input,
              error: classes.hasError
            }}
            fullWidth
            className={classNames({
              [classes.hasPrefix]: prefix,
              [classes.hasSuffix]: suffix
            })}
          />
          {(sensitive || valueCanBeCopied) && (
            <div className={classes.actionsContainer}>
              {sensitive && (
                <Tooltip title={reveal ? 'Hide' : 'Reveal'}>
                  <IconButton
                    size="small"
                    aria-label={reveal ? 'Hide' : 'Reveal'}
                    onClick={() => setReveal(!reveal)}
                    className={classes.actionIcon}
                  >
                    {reveal ? (
                      <VisibilityOffIcon style={{ fontSize: '18px' }} />
                    ) : (
                      <VisibilityIcon style={{ fontSize: '18px' }} />
                    )}
                  </IconButton>
                </Tooltip>
              )}
              {valueCanBeCopied && (
                <Tooltip
                  title={copied ? 'Copied!' : 'Copy to clipboard'}
                  onClose={() => {
                    //await transition
                    setTimeout(() => {
                      setCopied(false)
                    }, 300)
                  }}
                >
                  <IconButton
                    size="small"
                    aria-label="copy to clipboard"
                    onClick={() => copyToClipboard()}
                    className={classNames(classes.actionIcon, classes.actionIconLast)}
                  >
                    <FileCopyIcon style={{ fontSize: '18px' }} />
                  </IconButton>
                </Tooltip>
              )}
            </div>
          )}
        </div>
        {suffix && (
          <div
            className={classNames(classes.inputPrefixSuffix, classes.inputSuffix, {
              [classes.inputPrefixSuffixWithError]: errors[field]
            })}
          >
            {suffix}
          </div>
        )}
      </div>
      {errors[field] && (
        <Typography className={classes.errorMessage} component="div">
          {errors[field]}
        </Typography>
      )}
      {description && <small className={classes.description}>{description}</small>}
    </div>
  )
}

TextInput.defaultProps = {
  label: null,
  type: 'text',
  required: false,
  placeholder: null,
  description: null,
  isSensitive: false,
  valueCanBeCopied: false,
  prefix: null,
  suffix: null,
  testid: null
}

TextInput.propTypes = {
  field: PropTypes.string.isRequired,
  label: PropTypes.string,
  type: PropTypes.string,
  required: PropTypes.bool,
  placeholder: PropTypes.string,
  description: PropTypes.string,
  sensitive: PropTypes.bool,
  valueCanBeCopied: PropTypes.bool,
  prefix: PropTypes.node,
  suffix: PropTypes.node,
  testid: PropTypes.string
}

export default TextInput
