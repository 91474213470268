import auth0 from 'auth0-js'
import queryString from 'query-string'
import config from './environment'
import Storage from './storage'

/**
 * Class wrapping identity provider
 *
 * @class
 */
export default class Auth {
  static instance
  tokenRenewalTimeout
  client
  storage

  constructor({ client }) {
    if (!this.instance) {
      this.instance = new auth0.WebAuth({
        domain: config.identityProvider.domain,
        clientID: config.identityProvider.clientId,
        audience: `https://${config.identityProvider.audience}/userinfo`,
        cookieDomain: '.stagingapideck.com'
      })
    }

    this.client = client
    this.storage = new Storage()

    // schedule renewing the tokens when the page is refreshed
    this.scheduleRenewal()

    return this
  }

  connections() {
    return {
      USERNAME_PASSWORD: 'Username-Password-Authentication',
      GOOGLE: 'google-oauth2',
      GITHUB: 'github',
      MICROSOFT: 'windowslive'
    }
  }

  /**
   * Parse the query string hash
   */
  parseHash() {
    return new Promise((resolve, reject) => {
      this.instance.parseHash((error, authResult) => {
        if (error) {
          this.logout()
          reject(error)
        }

        if (authResult && authResult.accessToken && authResult.idToken) {
          resolve({
            expiresIn: authResult.expiresIn,
            accessToken: authResult.accessToken,
            idToken: authResult.idToken
          })
        }
      })
    })
  }

  // /* LEGACY: We are now using the universal login page */
  // _loginWithClassic(email, password, callbackPostfix) {
  //   return new Promise((resolve, reject) => {
  //     this.instance.login(
  //       {
  //         email,
  //         password,
  //         realm: 'Username-Password-Authentication',
  //         responseType: 'code id_token token',
  //         params: { scope: 'openid profile user_metadata' },
  //         redirectUri: `${window.location.origin}/${callbackPostfix}`
  //       },
  //       (err, authResult) => {
  //         if (err) {
  //           reject(err)
  //         }

  //         resolve(authResult)
  //       }
  //     )
  //   })
  // }

  // /* LEGACY: We are now using the universal login page */
  // _loginWithSocial(connection, callbackPostfix) {
  //   return new Promise((resolve, reject) => {
  //     this.instance.authorize(
  //       {
  //         connection,
  //         responseType: 'id_token token',
  //         redirectUri: `${window.location.origin}/${callbackPostfix}`
  //       },
  //       (err, authResult) => {
  //         if (err) {
  //           reject(err)
  //         }

  //         resolve(authResult)
  //       }
  //     )
  //   })
  // }

  // /* LEGACY: We are now using the universal login page */
  // login(connection, info, callbackPostfix = 'login') {
  //   if (connection === this.connections().USERNAME_PASSWORD) {
  //     return this._loginWithClassic(info.email, info.password, callbackPostfix)
  //   } else if (Object.values(this.connections()).includes(connection)) {
  //     return this._loginWithSocial(connection, callbackPostfix)
  //   } else {
  //     console.log(`can not find connection: ${connection}`)
  //   }
  // }

  _signupWithClassic(email, password, metadata, callbackPostfix) {
    return new Promise((resolve, reject) => {
      this.instance.signupAndAuthorize(
        {
          email,
          password,
          user_metadata: metadata,
          connection: this.connections().USERNAME_PASSWORD,
          params: { scope: 'openid profile user_metadata' },
          redirectUri: `${window.location.origin}/${callbackPostfix}`
        },
        (err, authResult) => {
          if (err) {
            reject(err)
          }
          resolve(authResult)
        }
      )
    })
  }

  _signupWithSocial(connection, callbackPostfix) {
    return new Promise((resolve, reject) => {
      this.instance.authorize(
        {
          connection,
          responseType: 'id_token token',
          redirectUri: `${window.location.origin}/${callbackPostfix}`
        },
        (err, authResult) => {
          if (err) {
            reject(err)
          }

          resolve(authResult)
        }
      )
    })
  }

  signup(connection, email, password, metadata, callbackPostfix = 'signup') {
    if (connection === this.connections().USERNAME_PASSWORD) {
      return this._signupWithClassic(email, password, metadata, callbackPostfix)
    } else if (Object.values(this.connections()).includes(connection)) {
      return this._signupWithSocial(connection, callbackPostfix)
    } else {
      console.log(`can not find connection: ${connection}`)
    }
  }

  changePassword(email) {
    return new Promise((resolve, reject) => {
      const data = {
        connection: this.connections().USERNAME_PASSWORD,
        email
      }

      this.instance.changePassword(data, (err) => {
        if (err) {
          reject(err)
        }

        resolve()
      })
    })
  }

  getProfile(accessToken) {
    return new Promise((resolve, reject) => {
      this.instance.client.userInfo(accessToken, (err, userInfo) => {
        if (err) {
          reject(err)
        }
        resolve(userInfo)
      })
    })
  }

  logout(message) {
    clearTimeout(this.tokenRenewalTimeout)
    this.storage.remove('id_token')
    this.storage.remove('access_token')
    this.storage.remove('expires_at')
    this.storage.remove('persist:root')
    this.storage.remove('activeEcosystemId')
    this.storage.remove('activeAccountId')

    console.groupCollapsed('Apideck')
    console.log(`${message}. Redirecting to login`)
    console.groupEnd()

    const query = queryString.stringify({
      post_logout_redirect_uri: `https://${process.env.REACT_APP_AUTH0_CUSTOM_DOMAIN}/login`,
      client_id: config.identityProvider.clientId
    })

    const logoutUrl = `https://${process.env.REACT_APP_AUTH0_CUSTOM_DOMAIN}/oidc/logout?${query}`
    window.location.assign(logoutUrl)
  }

  clearSession() {
    this.storage.remove('id_token')
    this.storage.remove('access_token')
    this.storage.remove('expires_at')
    this.storage.remove('persist:root')
    this.storage.remove('activeEcosystemId')
    this.storage.remove('activeAccountId')
  }

  isAuthenticated() {
    // Check whether the current time is past the
    // access token's expiry time
    let expiresAt = JSON.parse(localStorage.getItem('expires_at'))

    return new Date().getTime() < expiresAt
  }

  getToken() {
    return this.storage.get('access_token')
  }

  renewToken() {
    return new Promise((resolve, reject) => {
      this.instance.checkSession(
        {
          responseType: 'id_token token',
          redirectUri: `${window.location.origin}/login`
        },
        (err, result) => {
          if (err) {
            reject(err)
          } else {
            this.setSession(result)
            resolve()
          }
        }
      )
    })
  }

  setSession(authResult) {
    let expiresAt = JSON.stringify(authResult.expiresIn * 1000 + new Date().getTime())

    this.storage.set('access_token', authResult.accessToken)
    this.storage.set('id_token', authResult.idToken)
    this.storage.set('expires_at', expiresAt)

    // schedule a token renewal
    this.scheduleRenewal()
  }

  scheduleRenewal() {
    const expiresAt = JSON.parse(this.storage.get('expires_at'))
    const delay = expiresAt - Date.now()

    if (delay > 0) {
      this.tokenRenewalTimeout = setTimeout(() => {
        this.renewToken()
      }, delay)
    }
  }
}
