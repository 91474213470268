import {
  Card,
  ListingLinks as Links,
  ListingApprovalForm,
  MarkdownPreview,
  Media,
  Screenshots,
  SecondaryButton
} from 'components'

import AutomateIcon from 'assets/automate-io-icon.png'
import BlendrIcon from 'assets/blendr.png'
import Grid from '@material-ui/core/Grid'
import { Link } from 'react-router-dom'
import MicrosoftFlowIcon from 'assets/microsoft-flow-icon.png'
import PiesyncIcon from 'assets/piesync.png'
import PropTypes from 'prop-types'
import React from 'react'
import SegmentIcon from 'assets/segment.png'
import ZapierIcon from 'assets/zapier.png'
import { ecosystemLocales } from 'utils/locales'
import getLocalizedValue from 'utils/getLocalizedValue'
import integrationPlatforms from 'constants/integrationPlatforms'

const Developer = ({ image, name }) => (
  <li style={{ marginBottom: '10px' }}>
    <div style={{ display: 'flex' }}>
      <img
        src={image}
        width="24"
        height="24"
        style={{
          marginRight: '10px',
          borderRadius: '3px'
        }}
        alt=""
      />
      <p style={{ marginTop: '3px', marginBottom: 0 }}>{name}</p>
    </div>
  </li>
)

const EditButton = ({ basePath, section }) => (
  <SecondaryButton component={Link} to={`${basePath}/settings#${section}`} className="fr">
    Edit
  </SecondaryButton>
)

const Overview = ({
  listing,
  comments,
  marketplace,
  basePath,
  handleLinkCreate,
  handleLinkUpdate,
  handleLinkDelete,
  handleScreenshotCreate,
  handleScreenshotUpdate,
  handleScreenshotDelete,
  handleMediaCreate,
  handleMediaUpdate,
  handleMediaDelete,
  handleApproveListing,
  handleDeclineListing,
  handleCreateComment,
  handleDeleteComment,
  user,
  activeAccount
}) => (
  <React.Fragment>
    <Grid container spacing={2}>
      <Grid item sm={8} xs={12}>
        <Card title="Tag Line" button={<EditButton basePath={basePath} />}>
          <MarkdownPreview value={getLocalizedValue(listing, 'tagLine', marketplace.locales)} />
        </Card>
        <Card title="Description" button={<EditButton basePath={basePath} section="description" />}>
          <MarkdownPreview
            value={getLocalizedValue(listing, 'description', marketplace.locales) || ''}
          />
        </Card>
        <Card title="Features" button={<EditButton basePath={basePath} section="features" />}>
          <MarkdownPreview
            value={getLocalizedValue(listing, 'features', marketplace.locales) || ''}
          />
        </Card>
        <Card title="Pricing" button={<EditButton basePath={basePath} section="pricing" />}>
          <MarkdownPreview
            value={getLocalizedValue(listing, 'pricing', marketplace.locales) || ''}
          />
        </Card>
        <Media
          media={listing.media}
          locales={ecosystemLocales(marketplace)}
          handleMediaCreate={handleMediaCreate}
          handleMediaUpdate={handleMediaUpdate}
          handleMediaDelete={handleMediaDelete}
        />
        <Screenshots
          screenshots={listing.screenshots}
          locales={ecosystemLocales(marketplace)}
          handleScreenshotCreate={handleScreenshotCreate}
          handleScreenshotUpdate={handleScreenshotUpdate}
          handleScreenshotDelete={handleScreenshotDelete}
        />
      </Grid>
      <Grid item sm={4} xs={12}>
        {listing.partner && (
          <ListingApprovalForm
            user={user}
            ecosystem={marketplace}
            listing={listing}
            comments={comments}
            handleApproveListing={handleApproveListing}
            handleDeclineListing={handleDeclineListing}
            handleCreateComment={handleCreateComment}
            handleDeleteComment={handleDeleteComment}
          />
        )}
        <Links
          links={listing.links}
          locales={ecosystemLocales(marketplace)}
          handleLinkCreate={handleLinkCreate}
          handleLinkUpdate={handleLinkUpdate}
          handleLinkDelete={handleLinkDelete}
        />
        <Card
          title="Developer(s)"
          button={<EditButton basePath={basePath} section="integration-settings" />}
        >
          <ul className="developers">
            {listing.nativeIntegration ? (
              <Developer
                name={marketplace.name}
                image={marketplace.favicon ? marketplace.favicon.url : null}
              />
            ) : (
              ''
            )}
            {listing.thirdPartyIntegration ? (
              <Developer
                name={getLocalizedValue(listing, 'name', marketplace.locales)}
                image={listing.logo ? listing.logo.url : null}
              />
            ) : (
              ''
            )}
            {marketplace.zapierEnabled && listing.zapierId ? (
              <Developer name="Zapier" image={ZapierIcon} />
            ) : (
              ''
            )}
            {marketplace.piesyncEnabled && listing.piesyncId ? (
              <Developer name="Piesync" image={PiesyncIcon} />
            ) : (
              ''
            )}
            {marketplace.microsoftFlowEnabled && listing.microsoftFlowId ? (
              <Developer name="Microsoft Flow" image={MicrosoftFlowIcon} />
            ) : (
              ''
            )}
            {marketplace.segmentEnabled && listing.segmentId ? (
              <Developer name="Segment" image={SegmentIcon} />
            ) : (
              ''
            )}
            {marketplace.trayIoEnabled && listing.trayIoId ? (
              <Developer name="Tray.io" image={integrationPlatforms['tray-io'].icon} />
            ) : (
              ''
            )}
            {marketplace.combideskEnabled && listing.combideskId ? (
              <Developer name="Combidesk" image={integrationPlatforms['combidesk'].icon} />
            ) : (
              ''
            )}
            {marketplace.blendrEnabled && listing.blendrId ? (
              <Developer name="Blendr" image={BlendrIcon} />
            ) : (
              ''
            )}
            {marketplace.automateEnabled && listing.automateId ? (
              <Developer name="Automate" image={AutomateIcon} />
            ) : (
              ''
            )}
            {marketplace.integromatEnabled && listing.integromatId ? (
              <Developer name="Integromat" image={integrationPlatforms['integromat'].icon} />
            ) : (
              ''
            )}
          </ul>
        </Card>
        <Card title="Categories & collections">
          <div style={{ display: 'inline' }}>
            {listing?.categories?.map((i) => (
              <Pill
                key={i.id}
                id={i.id}
                path="listings/categories"
                ecosystemId={marketplace.id}
                label={i.name}
              />
            ))}
            {listing?.collections?.map((i) => (
              <Pill
                key={i.id}
                id={i.id}
                path="listings/collections"
                ecosystemId={marketplace.id}
                label={i.name}
              />
            ))}
          </div>
        </Card>
        {activeAccount?.plan === 'ENTERPRISE' && (
          <Card title="Partner">
            <div style={{ display: 'inline' }}>
              {listing?.partner && (
                <Pill
                  key={listing?.partner?.id}
                  id={listing?.partner?.id}
                  path="partners"
                  ecosystemId={marketplace.id}
                  label={listing?.partner?.company}
                />
              )}
            </div>
          </Card>
        )}
      </Grid>
    </Grid>
  </React.Fragment>
)

const Pill = ({ ecosystemId, id, label, path }) => (
  <Link to={`/ecosystems/${ecosystemId}/${path}/${id}/overview`}>
    <span key={id} className="pill">
      {label}
    </span>
  </Link>
)

Overview.propTypes = {
  listing: PropTypes.object.isRequired,
  handleScreenshotCreate: PropTypes.func.isRequired,
  handleScreenshotUpdate: PropTypes.func.isRequired,
  handleScreenshotDelete: PropTypes.func.isRequired,
  handleLinkCreate: PropTypes.func.isRequired,
  handleLinkUpdate: PropTypes.func.isRequired,
  handleLinkDelete: PropTypes.func.isRequired,
  handleDeleteComment: PropTypes.func,
  handleCreateComment: PropTypes.func,
  handleApproveListing: PropTypes.func,
  handleDeclineListing: PropTypes.func
}

export default Overview
