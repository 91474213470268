import { DELETE_PARTNER } from '../../graphql'
import { partnersQuery } from 'api'
import { toastr } from 'react-redux-toastr'
import { useHistory } from 'react-router-dom'
import { useMutation } from 'react-apollo'

function useDeletePartnerAction({ accountId, ecosystemId, partnerId }) {
  let history = useHistory()
  const [deletePartnerMutation] = useMutation(DELETE_PARTNER, {
    update: (cache) => {
      let data
      try {
        data = cache.readQuery({
          query: partnersQuery,
          variables: {
            ecosystemId,
            accountId,
            limit: 500
          }
        })
      } catch (e) {
        console.log(e)
      }

      if (data) {
        data.partners = [...data.partners.filter((partner) => partner.id !== partnerId)]

        cache.writeQuery({
          query: partnersQuery,
          data,
          variables: {
            ecosystemId,
            accountId,
            limit: 500
          }
        })
      }

      toastr.success('Partner Deleted')
      history.push(`/ecosystems/${ecosystemId}/partners/overview/all`)
    }
  })

  async function handleDeletePartner() {
    try {
      let variables = {
        accountId,
        id: partnerId
      }

      await deletePartnerMutation({
        variables
      })
    } catch (error) {
      toastr.error('could not delete partner')
    }
  }

  return [handleDeletePartner]
}

export { useDeletePartnerAction }
