import { getUnifyUrl } from 'utils/getUnifyUrl'
import { performNetworkLookup } from 'utils/networkLookup'

export const handleRedirectAfterSignup = async (user, client, options) => {
  const domain = user.email.split('@')[1]
  let serviceData = (await performNetworkLookup(domain, client)) || {}

  if (!serviceData.name) {
    const name = serviceData.websiteUrl
      ? serviceData.websiteUrl.split('.')[0]
      : user.email?.split('@')[0]
    serviceData = { ...serviceData, name: name.replace(/./, (c) => c.toUpperCase()) }
  }

  const product = options?.product || window?.sessionStorage?.getItem('product')
  const api = options?.api || window?.sessionStorage?.getItem('api')
  const connector = options?.connector || window?.sessionStorage?.getItem('connector')

  window.location.href = getUnifyUrl({ serviceData, showOnboarding: true, product, api, connector })
}
