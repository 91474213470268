import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'

const IconLabelButton = withStyles((theme) => ({
  root: {
    padding: theme.spacing(1, 2),
    backgroundColor: theme.palette.ui[100],
    minWidth: 0,
    '&:hover': {
      backgroundColor: theme.palette.ui[200]
    }
  },
  label: {
    padding: 0,
    color: theme.palette.ui[300],
    textTransform: 'none',
    fontSize: '15px'
  }
}))(Button)

const IconLabelButtonWrapper = ({ startIcon, endIcon, onClick, children }) => {
  return (
    <IconLabelButton startIcon={startIcon} endIcon={endIcon} onClick={onClick}>
      {children}
    </IconLabelButton>
  )
}

IconLabelButtonWrapper.defaultProps = {
  startIcon: null,
  endIcon: null
}

IconLabelButtonWrapper.propTypes = {
  startIcon: PropTypes.node,
  endIcon: PropTypes.node,
  onClick: PropTypes.func.isRequired
}

export default IconLabelButtonWrapper
