import gql from 'graphql-tag'
import translationFieldsFragment from './translationFields'

const linkFieldsFragment = gql`
  fragment linkFields on Link {
    id
    name
    url
    type
    sequence
    translations {
      ...translationFields
    }
  }

  ${translationFieldsFragment}
`

export default linkFieldsFragment
