import React from 'react'
import { spacing, colors } from 'styles/styles'

const Centered = ({ children }) => (
  <div
    style={{
      background: colors.blueBackground,
      height: '100vh',
      padding: '200px'
    }}
  >
    <div
      style={{
        margin: 'auto',
        width: '600px',
        textAlign: 'center'
      }}
    >
      <div style={{ marginBottom: '50px' }}>
        <img src="https://www.apideck.com/static/logo.5a11ed6f.svg" alt="" />
      </div>
      <div
        style={{
          borderRadius: '5px',
          background: '#FFF',
          padding: `${spacing.space6} ${spacing.space6}`
        }}
      >
        {children}
      </div>
    </div>
  </div>
)

export default Centered
