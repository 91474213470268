import { categoriesPreviewQuery } from 'api'
import { ErrorFallback, LoadingPlaceholder, MarketplaceCategories as Categories } from 'components'
import React from 'react'
import { useQuery } from 'react-apollo'

// TODO move to pages folder

const CategoriesContainer = ({ ecosystem }) => {
  const { loading, error, data } = useQuery(categoriesPreviewQuery, {
    variables: {
      ecosystemId: ecosystem.id,
      listingLimit: 3,
      limit: 1000 // Use absurd limit until pagination added
    }
  })

  if (loading) {
    return <LoadingPlaceholder />
  }

  if (error) {
    return <ErrorFallback error={error} />
  }

  const categories = data?.categoriesPreview

  return <Categories ecosystem={ecosystem} categories={categories} />
}

export default CategoriesContainer
