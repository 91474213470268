import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { CardNew as Card, IntegrationCard } from 'components'
import React from 'react'

export const CrmConnections = ({
  classes,
  ecosystem,
  connectionsQueryLoading,
  connectionsQueryError,
  connections,
  handleUpdateConnectionFormSubmit,
  handleDeleteConnection,
  updateConnectionMutationLoading,
  whichSettingsModalIsOpen,
  setSettingsModalOpen,
  setAddModalOpen,
  ErrorMessage
}) => {
  const LEADS_INTEGRATIONS_TOOLTIP_CONTENT = `Automatically push leads data to services of your choice`
  const leadsIntegrationsPlaceholderMessage =
    'Add a lead integration by clicking on the add button in the right upper corner.'

  return (
    <Card
      title="CRM integrations"
      tooltipContent={LEADS_INTEGRATIONS_TOOLTIP_CONTENT}
      action={
        !connectionsQueryLoading && (
          <Button
            variant="contained"
            color="primary"
            onClick={() => setAddModalOpen('leads-integration')}
          >
            + Add
          </Button>
        )
      }
      loading={connectionsQueryLoading}
      ErrorMessage={connectionsQueryError && ErrorMessage}
      PlaceholderMessage={connections.length === 0 && leadsIntegrationsPlaceholderMessage}
    >
      <Typography className={classes.title} component="h4">
        Connectors
      </Typography>

      <div className={classes.cardsContainer}>
        {connections.map((addedLeadsConnection) => {
          return (
            <IntegrationCard
              key={addedLeadsConnection.id}
              ecosystemId={ecosystem.id}
              connection={addedLeadsConnection}
              handleUpdateConnectionFormSubmit={handleUpdateConnectionFormSubmit}
              updateConnectionMutationLoading={updateConnectionMutationLoading}
              handleDeleteConnection={handleDeleteConnection}
              whichSettingsModalIsOpen={whichSettingsModalIsOpen}
              setSettingsModalOpen={setSettingsModalOpen}
            />
          )
        })}
      </div>
    </Card>
  )
}
