import { deleteLeadMutation, leadsQuery } from 'api/queries'
import { Loader } from 'components'
import displayForError from 'components/common/HOC/Error'
import displayWhileLoading from 'components/common/HOC/Loading'
import ErrorPlaceholder from 'components/placeholder/Error'
import PropTypes from 'prop-types'
import React from 'react'
import { graphql } from 'react-apollo'
import { toastr } from 'react-redux-toastr'
import { compose, withHandlers } from 'recompose'
import LeadsPage from './LeadsPage'

const pageSize = 2000

const LeadsContainer = ({ data, match, ecosystem, handleLeadDelete, setActivePath }) => (
  <LeadsPage
    ecosystem={ecosystem}
    handleLeadDelete={handleLeadDelete}
    leads={data.leads}
    match={match}
    setActivePath={setActivePath}
  />
)

const LoadingPlaceholder = () => (
  <div
    className="flex w-100 h-100 items-center justify-center"
    style={{ height: '200px', textAlign: 'center' }}
  >
    <Loader />
  </div>
)

const handlers = withHandlers({
  handleLeadDelete:
    ({ deleteLead, activeAccount, ecosystem }) =>
    async (id) => {
      try {
        const variables = {
          accountId: activeAccount.id,
          id
        }

        await deleteLead({
          variables,
          update: (store, { data: { deleteLead } }) => {
            const leadId = deleteLead.id
            const updateVariables = {
              accountId: activeAccount.id,
              ecosystemId: ecosystem.id,
              pageSize
            }
            const { leads } = store.readQuery({
              query: leadsQuery,
              variables: updateVariables
            })
            store.writeQuery({
              query: leadsQuery,
              data: { leads: leads.filter((lead) => lead.id !== leadId) },
              variables: updateVariables
            })
          }
        })
        toastr.success('Lead successfully deleted')
      } catch (error) {
        console.log('Error: ' + error)
        toastr.error('Could not delete Lead')
      }
    }
})

LeadsContainer.propTypes = {
  activeAccount: PropTypes.object.isRequired,
  ecosystem: PropTypes.object.isRequired
}

const data = graphql(leadsQuery, {
  options: ({ activeAccount, ecosystem }) => ({
    variables: {
      accountId: activeAccount.id,
      ecosystemId: ecosystem.id,
      pageSize
    }
  })
})

const LeadsContainerWithData = compose(
  data,
  displayWhileLoading(LoadingPlaceholder),
  displayForError(ErrorPlaceholder),
  graphql(deleteLeadMutation, { name: 'deleteLead' }),
  handlers
)(LeadsContainer)

export default LeadsContainerWithData
