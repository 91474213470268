import { HeaderWithTabs, SecondaryButton } from 'components'

import Button from '@material-ui/core/Button'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import React from 'react'
import SettingsIcon from '@material-ui/icons/Settings'
import VotingPortalNavigation from 'features/ecosystem/voting-portal/Navigation'
import ecosystemUrl from 'utils/ecosystemUrl'
import { withRouter } from 'react-router-dom'

const Header = ({ history, match, goBack, ecosystem }) => {
  return (
    <HeaderWithTabs tabs={<VotingPortalNavigation match={match} />}>
      <h1>Voting Portal</h1>
      {goBack && (
        <SecondaryButton className="fr" onClick={history.goBack}>
          Go Back
        </SecondaryButton>
      )}
      <Button
        className="fr"
        style={{ marginLeft: 10 }}
        href={`/ecosystems/${ecosystem.id}/settings/template#voting-portal`}
        endIcon={
          <SettingsIcon
            style={{
              color: 'currentColor',
              fontSize: 16
            }}
          />
        }
      >
        Settings
      </Button>
      <Button
        className="fr"
        target="_blank"
        href={`${ecosystemUrl(ecosystem)}/requested`}
        endIcon={
          <OpenInNewIcon
            style={{
              color: 'currentColor',
              fontSize: 16
            }}
          />
        }
      >
        View Voting Portal
      </Button>
    </HeaderWithTabs>
  )
}

const VotingPortalHeader = withRouter(Header)

export default VotingPortalHeader
