import React from 'react'
import { useHistory } from 'react-router-dom'
import { Header, SecondaryButton } from 'components'

function CreatePartnerHeader() {
  const history = useHistory()
  return (
    <Header>
      <h1>Create partner</h1>

      <SecondaryButton className="fr" onClick={history.goBack}>
        Go Back
      </SecondaryButton>
    </Header>
  )
}

export default CreatePartnerHeader
