import React, { useEffect, useState } from 'react'

import { CardIcon } from 'components'
import Grid from '@material-ui/core/Grid'
import PropTypes from 'prop-types'
import { ScaleLoader } from 'react-spinners'
import { colors } from 'styles/styles'
import styled from 'react-emotion'
import truncate from 'lodash/truncate'

function Service({ action, service, imported, handleClick }) {
  const [disabled, setDisabled] = useState(false)
  useEffect(() => {
    setDisabled(false)
  }, [action])

  const onClick = () => {
    setDisabled(true)
    handleClick(service)
  }

  let subtitle = ''
  if (service.categories && service.categories.length > 0) {
    subtitle = service.categories[0].name
  }

  return (
    <GridCard
      title={service.name}
      imageSrc={service.icon.url}
      id={service.id}
      handleClick={onClick}
      action={action}
      subtitle={subtitle}
      small
      integration
      disabled={disabled}
      style={{ border: imported ? '1px #12bf00 solid' : '' }}
      sm={2}
      xs={12}
    />
  )
}

Service.propTypes = {
  service: PropTypes.object.isRequired,
  action: PropTypes.string.isRequired,
  imported: PropTypes.bool,
  handleClick: PropTypes.func.isRequired
}

const ContentWrapper = styled('div')`
  height: 100%;
  width: 100%;
  display: block;
  padding: 30px 0 0 0;
  text-decoration: none;
  text-align: center;
  position: relative;
  background: #fff;
  box-sizing: border-box;
  transform: translateZ(0);
  box-shadow: 0px 1.5px 3px 0 rgba(225, 227, 229, 0.6);
  border-radius: 8px;
  border: thin solid #e9ebed;
  transition: all 0.2s ease-in-out;

  :hover {
    border-color: #087cff;
    cursor: pointer;
  }

  .title {
    color: #666;
    font-size: 14px;
    font-weight: 400;
    height: 24px;
  }

  .subtitle {
    font-size: 14px;
    line-height: 16px;
    color: rgba(0, 56, 108, 0.5);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding: 0 10px;
  }

  .icon {
    margin-bottom: 10px;
  }

  .image {
    max-width: 120px;
  }

  .dimmed {
    filter: grayscale(100%);
    opacity: 0.2;
  }

  p {
    line-height: 1.15em;
  }

  .buttons {
    border-top: 1px solid #ddd;
    height: 45px;
    width: 100%;
    margin-top: 15px;
  }

  .buttons p {
    line-height: 40px;
    color: #666;
    font-weight: normal;
    text-transform: capitalize;
    cursor: pointer;
  }

  .buttons a:hover {
    text-decoration: underline;
  }

  .buttons .left,
  .buttons .right {
    background: 0 0;
    color: inherit;
    display: inline-block;
    font: 600 14px proxima nova, Arial, sans-serif;
    height: 44px;
    line-height: 44px;
    position: relative;
    padding: 0;
    top: 0;
    text-align: center;
    width: 100%;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  .buttons .left {
    border-right: 1px solid #ddd;
  }
`

const GridCard = ({ sm, xs, ...rest }) => (
  <Grid item className="grid-card" sm={sm} xs={xs}>
    <Content {...rest} />
  </Grid>
)

const IconTitleSubtitle = ({ imageSrc, title, subtitle }) => (
  <React.Fragment>
    <CardIcon imageSrc={imageSrc} title={title} small />
    <div className="title">{truncate(title, { length: 14 })}</div>
    <div className="subtitle">{subtitle}</div>
  </React.Fragment>
)

class Content extends React.Component {
  render() {
    const {
      title,
      subtitle,
      imageSrc,
      action = 'View Details',
      id,
      style,
      disabled,
      handleClick
    } = this.props

    return (
      <ContentWrapper onClick={() => handleClick(id)} style={style}>
        <IconTitleSubtitle title={title} subtitle={subtitle || '-'} imageSrc={imageSrc} />
        <div className="buttons">
          <div className="right">
            {disabled ? (
              <ScaleLoader height={12} width={3} color={colors.primary} margin="2px" />
            ) : (
              <p>{action}</p>
            )}
          </div>
        </div>
      </ContentWrapper>
    )
  }
}

export default Service
