import { InMemoryCache } from 'apollo-cache-inmemory'
import { ApolloClient } from 'apollo-client'
import { ApolloLink, Observable } from 'apollo-link'
import { onError } from 'apollo-link-error'
import { HttpLink } from 'apollo-link-http'

import environment from 'utils/environment'
import { bugsnagClient } from '../index'

class apolloClient {
  constructor() {
    const cache = this.createCache()

    return new ApolloClient({
      link: this.createLink(cache),
      cache
    })
  }

  reportError({ error, message, operation, response }) {
    bugsnagClient.notify(message, {
      metaData: {
        originalError: error,
        operation,
        response
      }
    })
  }

  handleGraphQLError({ error, operation, response }) {
    if (error?.extensions?.exception?.code === 'UNAUTHENTICATED') {
      // auth.logout('Server returned UNAUTHENTICATED')
      // window.location.assign(`${process.env.REACT_APP_V2_URL}/api/auth/logout`)
    } else {
      this.reportError({
        error,
        message: error.message,
        operation,
        response
      })
    }
  }

  createLink() {
    const request = async (operation) => {
      const url = new URL(window.location.href)
      const params = new URLSearchParams(url.search)

      operation.setContext({
        headers: {
          Authorization: `Bearer ${
            params.get('accessToken') || window.localStorage.getItem('accessToken')
          }`
        }
      })
    }

    const requestLink = new ApolloLink(
      (operation, forward) =>
        new Observable((observer) => {
          let handle
          Promise.resolve(operation)
            .then((oper) => request(oper))
            .then(() => {
              handle = forward(operation).subscribe({
                next: observer.next.bind(observer),
                error: observer.error.bind(observer),
                complete: observer.complete.bind(observer)
              })
            })
            .catch(observer.error.bind(observer))

          return () => {
            if (handle) handle.unsubscribe()
          }
        })
    )

    return ApolloLink.from([
      onError(({ graphQLErrors, networkError, operation, response }) => {
        if (graphQLErrors) {
          for (const error of graphQLErrors) {
            this.handleGraphQLError({ error, operation, response })
          }
        }

        if (networkError) {
          bugsnagClient.notify(networkError)
          console.error(networkError)
        }
      }),
      requestLink,
      new HttpLink({
        uri: environment.graphql.uri
      })
    ])
  }

  createCache() {
    return new InMemoryCache({
      cacheRedirects: {
        Query: {
          partner: (_, args, { getCacheKey }) => {
            getCacheKey({ __typename: 'Partner', id: args.id })
          }
        }
      }
    })
  }
}

export default apolloClient
