import { DeleteDialog, OpenInNewButton } from 'components'

import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import { withStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'
import React from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import timeAgo from 'time-ago'
import ecosystemUrl from 'utils/ecosystemUrl'
import LeadDrawer from './LeadDrawer'

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy)
}

class EnhancedTableHead extends React.Component {
  createSortHandler = (property) => (event) => {
    this.props.onRequestSort(event, property)
  }

  render() {
    const { order, orderBy } = this.props
    const rows = [
      {
        id: 'name',
        numeric: false,
        label: 'Name',
        sort: false
      },
      {
        id: 'email',
        numeric: false,
        label: 'Email',
        sort: false
      },
      {
        id: 'companyName',
        numeric: false,
        label: 'Company',
        sort: false
      },
      {
        id: 'created',
        numeric: true,
        label: 'Time',
        sort: false,
        width: '180px'
      },
      {
        id: 'actions',
        numeric: true,
        label: 'Actions',
        sort: false
      }
    ]

    return (
      <TableHead>
        <TableRow>
          {rows.map((row) => {
            const sortLabel = (
              <TableSortLabel
                active={orderBy === row.id}
                direction={order}
                onClick={this.createSortHandler(row.id)}
              >
                {row.label}
              </TableSortLabel>
            )

            return (
              <TableCell
                key={row.id}
                sortDirection={orderBy === row.id ? order : false}
                style={{
                  textAlign: row.numeric ? 'right' : 'left',
                  width: row.width ? row.width : 'auto'
                }}
              >
                {row.sort ? sortLabel : row.label}
              </TableCell>
            )
          }, this)}
        </TableRow>
      </TableHead>
    )
  }
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired
}

const styles = (theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3)
  },
  title: {
    fontSize: '16px',
    fontWeight: 500,
    color: '#001b2f'
  },
  table: {
    minWidth: '1020px'
  },
  tableWrapper: {
    overflowX: 'auto'
  }
})

class EnhancedTable extends React.Component {
  state = {
    order: 'asc',
    orderBy: null,
    data: [],
    page: 0,
    rowsPerPage: 50,
    drawerOpen: false,
    activeLead: null
  }

  handleRequestSort = (event, property) => {
    const orderBy = property
    let order = 'desc'

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc'
    }

    this.setState({ order, orderBy })
  }

  handleChangePage = (event, page) => {
    this.setState({ page })
  }

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value })
  }

  handleActionsClick = (event, lead) => {
    this.setState({
      anchorEl: event.currentTarget,
      lead
    })
  }

  handleClose = () => {
    this.setState({ anchorEl: null })
  }

  toggleDrawer = (lead) => {
    this.setState({ drawerOpen: !this.state.drawerOpen })
    this.setState({ activeLead: lead })
  }

  render() {
    const { classes, data, ecosystem, handleLeadDelete } = this.props
    const { listingNaming } = ecosystem
    const { order, orderBy, rowsPerPage, page, activeLead, drawerOpen } = this.state

    return (
      <Paper>
        <Toolbar>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography className={classes.title}>Leads</Typography>
            </Grid>
            <Grid item>
              <OpenInNewButton href={ecosystemUrl(ecosystem) + '#cta'}>
                View CTA form
              </OpenInNewButton>
            </Grid>
          </Grid>
        </Toolbar>
        <Divider />
        <TableContainer>
          <Table
            className={classes.table}
            aria-label="CTA Form leads table"
            size="medium"
            data-testid="ecosystem-leads-cta-table"
            stickyHeader
          >
            {data.length === 0 && <caption>No records available.</caption>}
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={this.handleRequestSort}
              rowCount={data.length}
              listingNaming={listingNaming}
            />
            <TableBody>
              {stableSort(data, getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((lead) => {
                  return (
                    <TableRow
                      hover
                      tabIndex={-1}
                      key={lead.id}
                      onClick={() => this.toggleDrawer(lead)}
                    >
                      <TableCell>{lead.name}</TableCell>
                      <TableCell>{lead.email}</TableCell>
                      <TableCell>{lead.companyName}</TableCell>
                      <TableCell align="right">{timeAgo.ago(lead.createdAt)}</TableCell>
                      <TableCell align="right" padding="checkbox">
                        <DeleteDialog
                          handleDelete={handleLeadDelete}
                          id={lead.id}
                          label="Lead"
                          contentText="Are you sure you want to delete this lead?"
                        />
                      </TableCell>
                    </TableRow>
                  )
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            'aria-label': 'Previous Page'
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page'
          }}
          onPageChange={this.handleChangePage}
          onRowsPerPageChange={this.handleChangeRowsPerPage}
        />
        <LeadDrawer
          drawerOpen={drawerOpen}
          toggleDrawer={this.toggleDrawer}
          activeLead={activeLead}
        />
      </Paper>
    )
  }
}

EnhancedTable.propTypes = {
  classes: PropTypes.object.isRequired
}

export default compose(withRouter, withStyles(styles))(EnhancedTable)
