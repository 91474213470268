import { updateSeoSettingsMutation } from 'api/queries'
import { injectLocalizedValues, prepareVariables, SeoSettings } from 'components'
import LoadingPlaceholder from 'components/placeholder/Loading'
import { NOT_UPDATED, UPDATED } from 'constants/settings'
import PropTypes from 'prop-types'
import React from 'react'
import { graphql } from 'react-apollo'
import { toastr } from 'react-redux-toastr'
import { compose, withHandlers } from 'recompose'
import { ecosystemLocales, setRootValueFromTranslation } from 'utils/locales'
import transformError from 'utils/transformError'

class Container extends React.Component {
  render() {
    const { handleSubmit, ecosystem } = this.props

    if (!ecosystem) {
      return <LoadingPlaceholder />
    } else {
      let initialValues = {
        metaTagTitlePostfix: ecosystem.metaTagTitlePostfix || '',
        metaTagTitle: ecosystem.metaTagTitle || '',
        metaTagKeywords: ecosystem.metaTagKeywords || '',
        metaTagDescription: ecosystem.metaTagDescription || '',
        metaTagDescriptionCategoryPage: ecosystem.metaTagDescriptionCategoryPage || '',
        metaTagDescriptionCollectionPage: ecosystem.metaTagDescriptionCollectionPage || '',
        metaTagDescriptionListingPage: ecosystem.metaTagDescriptionListingPage || '',
        listingNamePostfix: ecosystem.listingNamePostfix || '',
        utmCampaign: ecosystem.utmCampaign || '',
        locales: ecosystemLocales(ecosystem)
      }

      initialValues = injectLocalizedValues('ecosystemSeoSettings', initialValues, ecosystem)

      return <SeoSettings handleSubmit={handleSubmit} initialValues={initialValues} />
    }
  }
}

Container.propTypes = {
  activeAccount: PropTypes.object.isRequired,
  ecosystem: PropTypes.object.isRequired
}

const handlers = withHandlers({
  handleSubmit:
    ({ updateSeoSettings, activeAccount, ecosystem }) =>
    async (props, { setSubmitting, setErrors }) => {
      setSubmitting(true)

      const {
        metaTagTitlePostfix,
        metaTagTitle,
        metaTagKeywords,
        metaTagDescription,
        metaTagDescriptionCategoryPage,
        metaTagDescriptionCollectionPage,
        metaTagDescriptionListingPage,
        listingNamePostfix,
        utmCampaign
      } = props

      const locales = ecosystemLocales(ecosystem)

      let variables = {
        accountId: activeAccount.id,
        id: ecosystem.id,
        name: ecosystem.name,
        slug: ecosystem.slug,
        metaTagTitlePostfix: setRootValueFromTranslation(
          locales,
          ecosystem,
          'metaTagTitlePostfix',
          metaTagTitlePostfix
        ),
        metaTagTitle: setRootValueFromTranslation(locales, ecosystem, 'metaTagTitle', metaTagTitle),
        metaTagKeywords: setRootValueFromTranslation(
          locales,
          ecosystem,
          'metaTagKeywords',
          metaTagKeywords
        ),
        metaTagDescription: setRootValueFromTranslation(
          locales,
          ecosystem,
          'metaTagDescription',
          metaTagDescription
        ),
        metaTagDescriptionCategoryPage: setRootValueFromTranslation(
          locales,
          ecosystem,
          'metaTagDescriptionCategoryPage',
          metaTagDescriptionCategoryPage
        ),
        metaTagDescriptionCollectionPage: setRootValueFromTranslation(
          locales,
          ecosystem,
          'metaTagDescriptionCollectionPage',
          metaTagDescriptionCollectionPage
        ),
        metaTagDescriptionListingPage: setRootValueFromTranslation(
          locales,
          ecosystem,
          'metaTagDescriptionListingPage',
          metaTagDescriptionListingPage
        ),
        listingNamePostfix: setRootValueFromTranslation(
          locales,
          ecosystem,
          'listingNamePostfix',
          listingNamePostfix
        ),
        utmCampaign,
        translations: prepareVariables(props, ecosystem, ecosystem.translations)
      }

      try {
        await updateSeoSettings({
          variables
        })

        toastr.success(UPDATED)
        setSubmitting(false)
      } catch (error) {
        toastr.error(NOT_UPDATED)
        if (error.graphQLErrors) {
          setErrors(transformError(error.graphQLErrors))
        }

        setSubmitting(false)
      }
    }
})

const updateSeoSettings = graphql(updateSeoSettingsMutation, {
  name: 'updateSeoSettings'
})

const SettingsContainer = compose(updateSeoSettings, handlers)(Container)

export default SettingsContainer
