import gql from 'graphql-tag'
import { listingFields } from './queries'

export const createListingCommentMutation = gql`
  mutation createAdminListingComment($listingId: ID!, $accountId: ID!, $comment: String!) {
    createAdminListingComment(
      input: { listingId: $listingId, accountId: $accountId, comment: $comment }
    ) {
      id
      content
      author {
        id
        email
      }
      createdAt
    }
  }
`
export const deleteListingCommentMutation = gql`
  mutation deleteAdminListingComment($id: ID!, $listingId: ID!, $accountId: ID!) {
    deleteAdminListingComment(input: { id: $id, listingId: $listingId, accountId: $accountId }) {
      id
    }
  }
`
export const approvePartnerListingMutation = gql`
  mutation approvePartnerListing($id: ID!, $accountId: ID!, $comment: String) {
    approvePartnerListing(input: { id: $id, accountId: $accountId, comment: $comment }) {
      ...listingFields
    }
  }
  ${listingFields}
`

export const declinePartnerListingMutation = gql`
  mutation declinePartnerListing($id: ID!, $accountId: ID!, $comment: String) {
    declinePartnerListing(input: { id: $id, accountId: $accountId, comment: $comment }) {
      ...listingFields
    }
  }
  ${listingFields}
`

export const listingCommentsQuery = gql`
  query listingComments($listingId: ID!, $accountId: ID) {
    listingComments(listingId: $listingId, accountId: $accountId) {
      id
      content
      author {
        id
        email
      }
      createdAt
    }
  }
`
