import React, { useEffect } from 'react'
import { useQuery, withApollo } from 'react-apollo'
import { getItem, setItem } from 'utils/localstorage'
import {
  normalizeUserAccount,
  normalizeUserDataForSegment
} from 'utils/normalizeUserDataForSegment'

import { currentUserQuery } from 'api'
import { ErrorFallback } from 'components'
import LoadingPlaceholder from 'components/placeholder/Loading'
import { useLocation } from 'react-router-dom'
import { useIntercom } from 'react-use-intercom'
import { registerUserWithBugsnag } from 'utils/bugsnag'
import { handleRedirectAfterSignup } from 'utils/handleRedirectAfterSignup'
import normalizeUserDataForIntercom from 'utils/normalizeUserDataForIntercom'
import PrivateRoutes from './PrivateRoutes'

const AuthenticatedRoute = ({ client }) => {
  const { boot: bootIntercom } = useIntercom()
  const { search } = useLocation()
  const params = new URLSearchParams(search)
  const accessToken = search?.split('access_token=')[1]
  const activeAccountId = params.get('account_id') || getItem('activeAccountId')

  if (!getItem('activeEcosystemId') && params.get('ecosystem_id')) {
    setItem('activeEcosystemId', params.get('ecosystem_id'))
  }
  if (accessToken) {
    setItem('accessToken', accessToken)
  }

  const { data, loading, error } = useQuery(currentUserQuery, {
    skip: !accessToken && !getItem('accessToken')
  })

  const { currentUser: user } = data || {}

  useEffect(() => {
    if (user?.accounts?.length === 0) {
      handleRedirectAfterSignup(user, client)
    }
  }, [client, user])

  if (loading) {
    return <LoadingPlaceholder />
  }
  if (!user) {
    window.location.assign(`${process.env.REACT_APP_V2_URL}/api/auth/logout`)
    return
  }

  if (error) {
    return <ErrorFallback error={error} />
  }

  // if user is not linked to account yet -> redirect to create ecosystem
  if (user?.accounts?.length === 0) {
    return null
  }

  let activeAccount
  if (activeAccountId) {
    const account = user.accounts.find((account) => account.account.id === activeAccountId)
    if (account) {
      activeAccount = account.account
    }
  } else {
    activeAccount = user?.accounts?.[0]?.account
  }

  activeAccount?.id && setItem('activeAccountId', activeAccount.id)

  // push user data to bugsnag
  activeAccount && registerUserWithBugsnag(user, activeAccount)

  // Segment user tracking
  if (user) {
    const segmentData = normalizeUserDataForSegment(
      user,
      user.accounts,
      activeAccount?.marketplaces
    )
    if (segmentData && window?.analytics) {
      window.analytics.identify(user.id, segmentData)

      if (activeAccount) {
        const normalizedData = normalizeUserAccount({ account: activeAccount })
        window.analytics.group(activeAccount.id, normalizedData)
      }
    }

    const intercomData = normalizeUserDataForIntercom(user, user.accounts)
    if (intercomData) {
      bootIntercom(intercomData)
    }
  }

  return <PrivateRoutes user={user} activeAccount={activeAccount} />
}

export default withApollo(AuthenticatedRoute)
