import Chip from '@material-ui/core/Chip'
import PropTypes from 'prop-types'
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  sizeSmall: {
    fontSize: '11px',
    height: '16px',
    pointerEvents: 'none'
  }
}))

const TabChip = ({ label }) => {
  const classes = useStyles()

  return <Chip className={classes.sizeSmall} size="small" label={label} color="secondary" />
}

TabChip.propTypes = {
  label: PropTypes.string.isRequired
}

export default TabChip
