import Button from '@material-ui/core/Button'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import { DeleteDialog, Fieldset } from 'components'
import PropTypes from 'prop-types'
import React from 'react'

const Delete = ({ handleProductDelete }) => (
  <Fieldset title="Danger Zone" style={{ marginTop: 30 }}>
    <p>Deleting your product is a permanent change that can not be undone.</p>
    <DeleteDialog
      handleDelete={handleProductDelete}
      label="product"
      opener={
        <Button className="delete" variant="contained" startIcon={<DeleteForeverIcon />}>
          Delete
        </Button>
      }
    />
  </Fieldset>
)

Delete.propTypes = {
  handleProductDelete: PropTypes.func.isRequired
}

export default Delete
