import React from 'react'
import Backdrop from '@material-ui/core/Backdrop'
import { makeStyles } from '@material-ui/core/styles'

const LINK = 'https://apideck.com/products/unify'

const useStyles = makeStyles((theme) => ({
  poweredByContainer: {
    position: 'absolute',
    left: '40px',
    bottom: '30px',
    display: 'flex',
    alignItems: 'center',
    color: 'white',
    border: '1px solid white',
    borderRadius: '4px',
    padding: theme.spacing(0.5, 1),
    fontSize: '12px',
    fontWeight: 400,
    opacity: 0.5,
    transition: '.3s',
    backgroundColor: theme.palette.ui[900],
    '&:hover': {
      opacity: 1
    },
    '& img': {
      marginLeft: '8px',
      height: '14px'
    },
    '& span': {
      textTransform: 'uppercase',
      fontWeight: 600,
      marginLeft: '3px',
      marginTop: '1px',
      fontSize: '12px',
      lineHeight: 1.08
    }
  }
}))

const BackdropPoweredByUnify = (props) => {
  const classes = useStyles()

  return (
    <Backdrop {...props}>
      <a
        className={classes.poweredByContainer}
        href={LINK}
        target="_blank"
        rel="noopener noreferrer"
      >
        Powered by <img src="/images/logo-white.svg" alt="Apideck" />
        <span>Unify</span>
      </a>
    </Backdrop>
  )
}

export default BackdropPoweredByUnify
