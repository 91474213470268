import styled from 'react-emotion'

const Empty = styled('div')`
  text-align: center;
  border-radius: 5px;
  border: 1px dashed #999;
  color: #555;
  font-weight: 500;
  padding: ${(props) => `${props.padding}px 0;` || '150px 0;'};
`

export default Empty
