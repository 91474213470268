import React, { Fragment, useState } from 'react'

import Backdrop from '@material-ui/core/Backdrop'
import CloseIcon from '@material-ui/icons/Close'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { IconButton } from 'components'
import Modal from '@material-ui/core/Modal'
import Paper from '@material-ui/core/Paper'
import Plan from 'components/ecosystem/pricing/Plan'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import { makeStyles } from '@material-ui/core/styles'
import styled from 'react-emotion'

const PLANS = [
  {
    id: 'LAUNCH',
    name: 'Launch',
    listings: 25,
    users: 5,
    pricePerMonth: {
      EUR: 99,
      USD: 99
    },
    pricePerYear: {
      EUR: 1010,
      USD: 1010
    },
    features: {
      '25 listings*': true,
      'Templates & layouts': true,
      Dashboard: true,
      Analytics: true,
      Integrations: true,
      'Standard SLA': true,
      'Support (chat + email)': true,
      'Lead capture': true,
      'Custom domain': true,
      'Voting portal': true,
      'Automated landing pages': true
    }
  },
  {
    id: 'GROWTH',
    name: 'Growth',
    listings: 50,
    users: 10,
    pricePerMonth: {
      EUR: 199,
      USD: 199
    },
    pricePerYear: {
      EUR: 2030,
      USD: 2030
    },
    features: {
      '50 listings*': true,
      'Custom CSS': true
    }
  },
  {
    id: 'SCALE',
    name: 'Scale',
    listings: 100,
    users: 15,
    pricePerMonth: {
      EUR: 399,
      USD: 399
    },
    pricePerYear: {
      EUR: 4070,
      USD: 4070
    },
    features: {
      '100 listings*': true,
      'Multi-language': true
    }
  },
  {
    id: 'ENTERPRISE',
    name: 'Enterprise',
    listings: null,
    users: null,
    pricePerMonth: null,
    pricePerYear: null,
    features: {
      'Per listing and/or partner': true,
      'Phone + Slack Support': true,
      'Partner portal add-on': true,
      'Multi-product add-on': true,
      'Professional services add-on': true,
      'Custom HTML add-on': true,
      'Yearly PO & Invoicing Available': true
    }
  }
]

const CloseWrapper = styled('div')`
  position: absolute;
  top: 0;
  right: 0;
`

const Content = styled('div')`
  padding: 40px 40px;
  min-width: 1024px;
  max-width: 1024px;
  font-family: var(--font-family-basier);
  h2 {
    font-size: 28px;
    color: var(--title-text-color);
    font-weight: 500;
    line-height: 1.15;
    text-align: center;
    margin-top: 10px;
  }
  p.description {
    color: var(--text-color);
    font-size: 15px;
    text-align: center;
    margin-bottom: 30px;
  }
  .badge {
    font-size: 10px;
    text-transform: uppercase;
    background-color: var(--color-secondary);
    color: var(--color-white);
    border-radius: 6px;
    padding: 0 7px;
    font-weight: 500;
    margin-left: 7px;
  }
  .plans-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
  }
  .note-container {
    font-size: 13px;
    color: var(--text-color);
  }
`

const useStyles = makeStyles(() => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '20px'
  },
  paper: {
    overflowY: 'auto',
    height: '100%',
    maxHeight: '700px',
    outline: 0,
    position: 'relative'
  },
  tooltip: {
    backgroundColor: 'var(--color-darkest)',
    color: 'var(--color-white)',
    fontSize: '13px',
    padding: '8px 16px',
    fontWeight: 400
  },
  arrow: {
    color: 'var(--color-darkest)'
  },
  labelContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    fontFamily: 'var(--font-family-basier)',
    color: 'var(--color-darker)',
    fontSize: '15px',
    letterSpacing: 0,
    marginBottom: 0
  }
}))

const EndOfTrialModal = ({ isOpen, trialExtensions, closeModal }) => {
  const [billingPeriod, setBillingPeriod] = useState('MONTHLY')
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const currency = timeZone.includes('Europe') ? 'EUR' : 'USD'
  const classes = useStyles()
  const hasTrialExtensions = trialExtensions !== 0

  return (
    <Modal
      open={isOpen}
      aria-labelledby="Your trial period has ended"
      className={classes.modal}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
      onClose={closeModal}
      disableEnforceFocus
      disableAutoFocus
    >
      <Paper className={classes.paper}>
        <CloseWrapper>
          <IconButton onClick={closeModal}>
            <CloseIcon style={{ fontSize: 24 }} />
          </IconButton>
        </CloseWrapper>
        <Content>
          <h2>
            {hasTrialExtensions
              ? 'Your extended trial period has ended'
              : 'Your 14 days trial period has ended'}
          </h2>
          <p className="description">
            {`For now, we have unpublished your ecosystem. Don't worry, none of the work you did has
            been lost.`}
            <br />
            {`You can re-publish your ecosystem and continue using Apideck Ecosystem by simply
            upgrading to one of our plans.`}
          </p>
          <RadioGroup
            aria-label="billing period"
            name="billingPeriod"
            value={billingPeriod}
            onChange={(e) => setBillingPeriod(e.target.value)}
            row
            classes={{
              row: classes.labelContainer
            }}
          >
            <FormControlLabel
              value="MONTHLY"
              control={<Radio />}
              label="Monthly"
              classes={{
                label: classes.label
              }}
            />
            <FormControlLabel
              value="YEARLY"
              control={<Radio />}
              label={
                <Fragment>
                  Yearly <span className="badge">15% off</span>
                </Fragment>
              }
              classes={{
                label: classes.label
              }}
            />
          </RadioGroup>
          <div className="plans-container">
            {PLANS.map((plan, index) => (
              <Plan
                key={plan.id}
                plan={plan}
                cycle={billingPeriod}
                currency={currency}
                highlighted={plan.id === 'SCALE'}
                enterprise={plan.id === 'ENTERPRISE'}
                prevPlanName={index > 0 ? PLANS[index - 1].name : undefined}
              />
            ))}
          </div>
          <div className="note-container">
            {`* Listings are dedicated integration or partner pages.`}
          </div>
        </Content>
      </Paper>
    </Modal>
  )
}

export default EndOfTrialModal
