import Select, { components } from 'react-select'
import { makeStyles, useTheme } from '@material-ui/core/styles'

import Avatar from '@material-ui/core/Avatar'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import PropTypes from 'prop-types'
import React from 'react'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles((theme) => ({
  label: {
    fontWeight: 500,
    fontSize: '14px',
    marginBottom: theme.spacing(1),
    color: theme.palette.ui[500]
  },
  option: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start'
  },
  avatar: {
    width: '24px',
    height: '24px',
    marginRight: theme.spacing(1)
  },
  optionLabel: {
    fontWeight: 500,
    fontSize: '15px'
  },
  dropdownIndicator: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1),
    color: theme.palette.ui[300]
  }
}))

const Option = (props) => {
  const { label, data } = props
  const classes = useStyles()

  return (
    <components.Option {...props}>
      <div className={classes.option}>
        <Avatar variant="rounded" src={data.icon} className={classes.avatar}>
          {label?.substring(0, 1)}
        </Avatar>
        <Typography className={classes.optionLabel}>{label}</Typography>
      </div>
    </components.Option>
  )
}

const DropdownIndicator = () => {
  const classes = useStyles()

  return (
    <div className={classes.dropdownIndicator}>
      <KeyboardArrowDownIcon />
    </div>
  )
}

const SelectIntegrationInput = ({
  field,
  label,
  placeholder,
  options,
  value,
  handleChange,
  height = 45,
  isClearable
}) => {
  const classes = useStyles()
  const theme = useTheme()
  const getOptionFromValue = (value) => {
    return options.filter((option) => option.value === value)
  }
  const customStyles = {
    menuPortal: (provided) => ({
      ...provided,
      zIndex: 1305
    }),
    control: (provided, state) => ({
      ...provided,
      height: `${height}px`,
      borderColor: state.isFocused ? theme.palette.primary.main : theme.palette.ui[250],
      borderRadius: '6px',
      fontSize: '16px',
      fontFamily: theme.typography.fontFamily,
      boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.05)',
      '&:hover': {
        borderColor: state.isFocused ? theme.palette.primary.main : theme.palette.ui[250]
      }
    }),
    placeholder: (provided) => ({
      ...provided,
      color: theme.palette.ui[300]
    })
  }

  return (
    <div className="form-element">
      {label && (
        <Typography className={classes.label} component="label" htmlFor={field}>
          {label}
        </Typography>
      )}
      <Select
        id={field}
        name={field}
        value={value && getOptionFromValue(value)}
        options={options}
        onChange={(props) => (props ? handleChange(props.value, props.id) : handleChange(null))}
        handleClick={() => {
          this.setValue(null) // here we reset value
        }}
        placeholder={placeholder}
        isClearable={isClearable}
        components={{
          Option,
          DropdownIndicator,
          IndicatorSeparator: null
        }}
        onKeyDown={(e) => {
          if (e.keyCode === 27) {
            e.stopPropagation()
          }
        }}
        styles={customStyles}
        menuPortalTarget={document.body}
      />
    </div>
  )
}

SelectIntegrationInput.propTypes = {
  field: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.string
}

export default SelectIntegrationInput
