import { propEq, reject } from 'ramda'

import { partnersQuery } from 'api'

function remove(cache, { accountId, ecosystemId }, { id }) {
  const variables = {
    accountId,
    ecosystemId,
    limit: 500
  }
  const query = partnersQuery
  const data = cache.readQuery({ query, variables })
  const byPartnerId = propEq('id', id)

  data.partners = reject(byPartnerId, data.partners)

  cache.writeQuery({ data, query, variables })
}

export { remove }
