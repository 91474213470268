import React from 'react'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import * as yup from 'yup'
import { TextInput, LegalNotice } from 'components'
import styled from 'react-emotion'

const schema = yup.object().shape({
  email: yup.string().required('Email is a required field')
})

const Button = styled('button')`
  margin-top: 15px;
`

function ForgotPasswordForm({ handleSubmit, initialValues }) {
  return (
    <Formik validationSchema={schema} initialValues={initialValues} onSubmit={handleSubmit}>
      {(formikProps) => {
        const { handleSubmit, isSubmitting, errors } = formikProps

        return (
          <form onSubmit={handleSubmit}>
            {errors.general && <span className="errors">{errors.general}</span>}
            <TextInput formikProps={formikProps} label="Email" field="email" type="email" />
            <Button type="submit" disabled={isSubmitting}>
              Send link
            </Button>
            <LegalNotice />
          </form>
        )
      }}
    </Formik>
  )
}

ForgotPasswordForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired
}

export default ForgotPasswordForm
