import React from 'react'
import styled from 'react-emotion'
import { media } from 'styles/styles'

const Container = styled('div')`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
  padding: 20px 60px 40px;

  @media screen and (max-width: ${media.xLarge}) {
    h1 {
      font-size: 36px;
    }
  }

  @media screen and (max-height: 850px) {
    background-size: 650px;
  }
`

const SignupContainer = ({ children }) => <Container>{children}</Container>

export default SignupContainer
