import { getItem } from './localstorage'

export const getUnifyUrl = (options) => {
  const onboarding = options?.showOnboarding ? `/onboarding` : ''

  const ecosystemId =
    !onboarding && getItem('activeEcosystemId')
      ? `?ecosystem_id=${getItem('activeEcosystemId')}`
      : '?'
  const expiresAt = getItem('expires_at') ? `&expires_at=${getItem('expires_at')}` : ''
  const accountId =
    !onboarding && getItem('activeAccountId') ? `&account_id=${getItem('activeAccountId')}` : ''
  const referrer = options?.referrer ? `&referrer=${options?.referrer}` : ''
  const name = options?.serviceData?.name ? `&name=${options?.serviceData?.name}` : ''
  const applicationName = options?.serviceData?.applicationName
    ? `&application_name=${options?.serviceData?.applicationName}`
    : ''
  const websiteUrl = options?.serviceData?.websiteUrl
    ? `&website_url=${options?.serviceData?.websiteUrl}`
    : ''
  const primaryColor = options?.serviceData?.primaryColor
    ? `&primary_color=${options?.serviceData?.primaryColor}`
    : ''
  const product = options?.product ? `&product=${options?.product}` : ''
  const api = options?.api ? `&api=${options?.api}` : ''
  const connector = options?.connector ? `&connector=${options?.connector}` : ''
  const url = `${process.env.REACT_APP_V2_URL}${onboarding}`
  const params = `${ecosystemId}${expiresAt}${accountId}${referrer}${name}${applicationName}${websiteUrl}${primaryColor}${product}${api}${connector}`

  return `${url}${params}`
}

export const getEcosystemUrl = (path = '/listings') => {
  const ecosystemId = getItem('activeEcosystemId')
    ? `?ecosystem_id=${getItem('activeEcosystemId')}`
    : '?'

  const accountId = getItem('activeAccountId') ? `&account_id=${getItem('activeAccountId')}` : ''
  const params = `${ecosystemId}${accountId}`
  const url = `${process.env.REACT_APP_V2_URL}/ecosystem${path}${params}`

  return url
}
