import { Tab, Tabs } from 'components'
import React from 'react'
import { Link, useRouteMatch } from 'react-router-dom'

function PartnersNavigation() {
  const match = useRouteMatch()

  return (
    <Tabs
      selectors={[
        {
          tab: 0,
          path: `${match.url}/all`,
          exact: true
        },
        {
          tab: 1,
          path: `${match.url}/requested`,
          exact: true
        },
        {
          tab: 2,
          path: `${match.url}/invitations`,
          exact: true
        }
      ]}
    >
      <Tab
        label="All"
        component={Link}
        to={`${match.url}/all`}
        data-testid={`ecosystem-partners-tab-all`}
      />
      <Tab
        label="Requests"
        component={Link}
        to={`${match.url}/requested`}
        data-testid={`ecosystem-partners-tab-requested`}
      />
      <Tab
        label="User Invitations"
        component={Link}
        to={`${match.url}/invitations`}
        data-testid={`ecosystem-partners-tab-invitations`}
      />
    </Tabs>
  )
}

export default PartnersNavigation
