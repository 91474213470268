import React, { useState } from 'react'
import { PanelLayout } from 'components'
import { auth } from 'routes/App'
import ForgotPassword from './ForgotPassword'

function ForgotPasswordPage() {
  const [message, setMessage] = useState('')
  const requestPasswordChange = async ({ email }, { setSubmitting }) => {
    try {
      setSubmitting(true)
      await auth.changePassword(email)
      setMessage('Password reset link has been sent.')
    } catch (error) {
      setMessage('Something went wrong. Please contact support.')
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <PanelLayout>
      <ForgotPassword handleRequestPasswordChange={requestPasswordChange} message={message} />
    </PanelLayout>
  )
}

export default ForgotPasswordPage
