import React from 'react'
import { Query, Mutation } from 'react-apollo'
import { catalogServicesQuery, importServiceMutation, deleteListingMutation } from 'api/queries'
import store from 'store'
import { adopt } from 'react-adopt'

function deleteListing({ accountId, ecosystemId, render }) {
  return (
    <Mutation
      mutation={deleteListingMutation}
      update={(cache, { data: { deleteListing } }) => {
        store.listings.remove(cache, { accountId, ecosystemId }, { id: deleteListing.id })
      }}
    >
      {(mutation, result) => render({ mutation, result })}
    </Mutation>
  )
}

function importService({ accountId, ecosystemId, render }) {
  return (
    <Mutation
      mutation={importServiceMutation}
      update={(cache, { data: { importService: service } }) => {
        store.services.import(cache, { accountId, ecosystemId }, { service })
      }}
    >
      {(mutation, result) => render({ mutation, result })}
    </Mutation>
  )
}

function services({ filters, page, perPage, render }) {
  return (
    <Query
      query={catalogServicesQuery}
      variables={{
        filterIntegrationPlatform: filters.integrationPlatform,
        filterText: filters.text,
        perPage: perPage,
        page
      }}
      fetchPolicy="cache-and-network"
      notifyOnNetworkStatusChange={true}
    >
      {render}
    </Query>
  )
}

const NetworkContainer = adopt({
  deleteListing,
  importService,
  services
})

export default NetworkContainer
