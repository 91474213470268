import { Tab, Tabs } from 'components'

import { Link } from 'react-router-dom'
import React from 'react'
import { withStyles } from '@material-ui/core/styles'

const styles = () => ({
  sizeSmall: {
    fontSize: '11px',
    height: '16px',
    marginLeft: '3px',
    pointerEvents: 'none'
  }
})

const ListingsNavigation = ({ match }) => {
  const { ecosystemId } = match.params

  return (
    <Tabs
      selectors={[
        {
          tab: 0,
          path: '/ecosystems/:ecosystemId/voting-portal/requests',
          exact: true
        }
      ]}
    >
      <Tab
        label={<span>Requests</span>}
        component={Link}
        to={`/ecosystems/${ecosystemId}/voting-portal/requests`}
      />
    </Tabs>
  )
}

export default withStyles(styles)(ListingsNavigation)
