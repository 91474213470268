import React from 'react'
import { ExternalA } from 'components'
import { Link as RouterLink } from 'react-router-dom'

const Link = ({ to = '', children, isExternal = false }) =>
  isExternal ? (
    <ExternalA href={to}>{children}</ExternalA>
  ) : (
    <RouterLink to={to}>{children}</RouterLink>
  )

export default Link
