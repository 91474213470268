import { NotFound } from 'components'
import PrivateRoute from 'components/PrivateRoute'
import PrivateRouteWithSidebarAndFooter from 'components/PrivateRouteWithSidebarAndFooter'
import {
  CategoryCreateContainer,
  CollectionCreateContainer,
  ProductCreateContainer
} from 'containers'
import {
  CreatePartnerPage,
  PartnerPage,
  PartnersPage,
  RequestedPartnersDetailPage
} from 'features/ecosystem/partners'
import { EcosystemSetupPage } from 'features/ecosystem/setup'
import PropTypes from 'prop-types'
import React from 'react'
import { ModalProvider } from 'react-modal-hook'
import { Redirect, Route, Switch, withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import {
  DashboardRoutes,
  LeadsRoutes,
  ListingsRoutes,
  SettingsRoutes,
  VotingPortalRoutes
} from 'routes'
import { getItem } from 'utils/localstorage'

function EcosystemRoute({ match, marketplace, activeAccount, user }) {
  return (
    <ModalProvider>
      <Switch>
        <PrivateRoute
          path={`${match.path}/setup`}
          component={EcosystemSetupPage}
          activeAccount={activeAccount}
          ecosystem={marketplace}
        />
        <PrivateRouteWithSidebarAndFooter
          path={match.path}
          component={WithNav}
          marketplace={marketplace}
          activeAccount={activeAccount}
          user={user}
          match={match}
        />
      </Switch>
    </ModalProvider>
  )
}

EcosystemRoute.propTypes = {
  activeAccount: PropTypes.object.isRequired,
  marketplace: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired
}

const WithNav = ({ marketplace, activeAccount, user, match }) => {
  const lastProductUsed = getItem('lastProductUsed')
  const shouldGoToUnify = lastProductUsed !== 'ecosystem' && marketplace.unifyApplicationId
  const initialPath = shouldGoToUnify ? `${match.path}/unify` : `${match.path}/listings/overview`

  return (
    <Switch>
      <Redirect exact from={`${match.path}/`} to={initialPath} />
      <Route path={`${match.path}/listings`}>
        <ListingsRoutes
          activeAccount={activeAccount}
          ecosystem={marketplace}
          user={user}
          match={match}
        />
      </Route>
      <PrivateRoute
        path={`${match.path}/category/create`}
        component={CategoryCreateContainer}
        activeAccount={activeAccount}
        ecosystem={marketplace}
      />
      <PrivateRoute
        path={`${match.path}/collection/create`}
        component={CollectionCreateContainer}
        ecosystem={marketplace}
        activeAccount={activeAccount}
      />
      <PrivateRoute
        path={`${match.path}/product/create`}
        component={ProductCreateContainer}
        ecosystem={marketplace}
        activeAccount={activeAccount}
      />
      <Route path={`${match.path}/voting-portal`}>
        <VotingPortalRoutes
          activeAccount={activeAccount}
          ecosystem={marketplace}
          user={user}
          match={match}
        />
      </Route>
      <PrivateRoute
        path={`${match.path}/partner-requests/:partnerId`}
        component={RequestedPartnersDetailPage}
        activeAccount={activeAccount}
        ecosystem={marketplace}
      />
      <PrivateRoute
        path={`${match.path}/partners/overview`}
        component={PartnersPage}
        activeAccount={activeAccount}
        user={user}
        ecosystem={marketplace}
      />
      <PrivateRoute
        path={`${match.path}/partners/:partnerId`}
        component={PartnerPage}
        activeAccount={activeAccount}
        listings={marketplace.listings}
        ecosystem={marketplace}
      />
      <PrivateRoute
        path={`${match.path}/partner/:create`}
        component={CreatePartnerPage}
        activeAccount={activeAccount}
        ecosystem={marketplace}
      />
      <PrivateRoute
        path={`${match.path}/dashboard`}
        component={DashboardRoutes}
        marketplace={marketplace}
        activeAccount={activeAccount}
        match={match}
      />
      <Route path={`${match.path}/settings`}>
        <SettingsRoutes marketplace={marketplace} activeAccount={activeAccount} />
      </Route>
      <Route path={`${match.path}/leads`}>
        <LeadsRoutes ecosystem={marketplace} activeAccount={activeAccount} />
      </Route>
      {/*
      Unify routes
      TODO: refactor to dedicated unify routes e.g. app.apideck.com/unify/unified-apis
    */}
      {/*
      fallback route
      TODO: redirect to dedicated 404 page (to be created)
    */}
      <Route component={NotFound} />
    </Switch>
  )
}

export default compose(withRouter)(EcosystemRoute)
