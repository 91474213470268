import LISTING_NAMING from 'constants/listingNaming'
import { startCase, toLower } from 'lodash'
import React from 'react'

export const ImgPreview = (props) => (
  <div>{props.image && <img src={props.image} className="w-10 mv3" alt="" />}</div>
)

export const Capitalize = ({ children }) => {
  return startCase(toLower(children.toLowerCase()))
}

export const capitalize = (string) => {
  return startCase(toLower(string.toLowerCase()))
}

export const listingLogo = (listing) => {
  if (listing.logo) {
    return listing.logo.url
  } else {
    return null
  }
}

export const imageUrlFormField = (imageObject) => {
  return imageObject && imageObject[0] && (imageObject[0].url || imageObject[0].preview)
}

export const getListingName = (ecosystem) => {
  if (ecosystem.listingNaming) {
    return LISTING_NAMING.find((item) => item.type === ecosystem.listingNaming)
  } else {
    return LISTING_NAMING.find((item) => item.type === 'LISTINGS')
  }
}

export const injectTags = (text, ecosystem) => {
  text = text.replace(/%ecosystem%/gi, ecosystem.name)

  const listingName = getListingName(ecosystem)

  if (listingName) {
    text = text.replace(/%listing_naming%/gi, listingName.label)
  }

  return text
}
