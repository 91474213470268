import PropTypes from 'prop-types'
import React from 'react'
import PartnerOverview from './Overview'
import { useCreateContactAction } from './useCreateContactAction'
import { useDeleteContactAction } from './useDeleteContactAction'
import { useInviteContactsAction } from './useInviteContactsAction'
import { useUpdateContactAction } from './useUpdateContactAction'

function PartnerOverviewPage({ activeAccount, ecosystem, partner }) {
  const input = {
    accountId: activeAccount.id,
    ecosystemId: ecosystem.id,
    partnerId: partner.id,
    ecosystemSlug: ecosystem.slug
  }

  const [createContactAction] = useCreateContactAction(input)
  const [updateContactAction] = useUpdateContactAction(input)
  const [deleteContactAction] = useDeleteContactAction(input)
  const [inviteContactsAction] = useInviteContactsAction({
    accountId: activeAccount.id,
    ecosystemId: ecosystem.id
  })

  return (
    <PartnerOverview
      ecosystem={ecosystem}
      partner={partner}
      handleContactCreate={createContactAction}
      handleContactUpdate={updateContactAction}
      handleContactDelete={deleteContactAction}
      handleContactsInvite={inviteContactsAction}
    />
  )
}

PartnerOverviewPage.propTypes = {
  activeAccount: PropTypes.object.isRequired,
  ecosystem: PropTypes.object.isRequired,
  partner: PropTypes.object.isRequired
}

export default PartnerOverviewPage
