import React, { useLayoutEffect, useRef, useState } from 'react'

import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column'
  }
}))

const ModalParentView = ({ activeModalViewId, children }) => {
  const classes = useStyles()
  const [modalHeight, setModalHeight] = useState()
  const modalViewEl = useRef(null)
  useLayoutEffect(() => {
    if (modalViewEl.current) {
      const HEIGHT = modalViewEl.current.offsetHeight
      setModalHeight(HEIGHT)
    }
  }, [modalViewEl])

  const childrenArray = React.Children.map(children, (child) => child)
  const activeModalViewChild = childrenArray.find((child) => child.props.id === activeModalViewId)

  return (
    <div ref={modalViewEl} style={{ minHeight: modalHeight }} className={classes.root}>
      {activeModalViewChild ? activeModalViewChild : childrenArray[0]}
    </div>
  )
}

ModalParentView.propTypes = {
  children: PropTypes.node.isRequired
}

export default ModalParentView
