import Avatar from '@material-ui/core/Avatar'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import { makeStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import ArrowRightIcon from '@material-ui/icons/ArrowRight'
import DeleteIcon from '@material-ui/icons/DeleteForever'
import classNames from 'classnames'
import { DeleteDialog, IconButton, IntegrationsSettingsModal, Status } from 'components'
import formatDistanceToNow from 'date-fns/formatDistanceToNow'
import PropTypes from 'prop-types'
import React from 'react'
import { useCardStyles } from '../../../styles/card'

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    marginBottom: theme.spacing(2),
    border: `1px solid ${theme.palette.ui[200]}`,
    borderRadius: '6px'
  }
}))

const IntegrationCard = ({
  connection,
  ecosystemId,
  handleUpdateConnectionFormSubmit,
  updateConnectionMutationLoading,
  handleDeleteConnection,
  whichSettingsModalIsOpen,
  setSettingsModalOpen
}) => {
  const classes = useStyles()
  const cardClasses = useCardStyles()
  const {
    id,
    serviceId,
    icon,
    name,
    enabled,
    createdAt,
    unifiedApi,
    state: connectionState
  } = connection
  const isModalOpen = whichSettingsModalIsOpen === id

  const handleSetModalOpen = async (open) => {
    if (open) {
      setSettingsModalOpen(id)
      // add query string
      window.history.pushState(
        {},
        document.title,
        `${window.location.pathname}?modal=${id.split('+').join('_')}`
      )
    } else {
      setSettingsModalOpen(null)
      // clear query strings
      window.history.pushState({}, document.title, window.location.pathname)
    }
  }

  const integrationType = unifiedApi === 'script' ? 'Third-party snippet' : 'CRM integration'
  const statusType = connectionState === 'callable' ? 'enabled' : 'incomplete'

  return (
    <Card className={classes.container} variant="outlined">
      <CardActionArea className={cardClasses.actionArea} onClick={() => handleSetModalOpen(true)}>
        <div className={cardClasses.leftContainer}>
          <Avatar
            className={classNames(cardClasses.icon, {
              [cardClasses.inactive]: !enabled
            })}
            src={icon}
            variant="rounded"
          >
            {name.substring(0, 1)}
          </Avatar>
          <div>
            <Typography className={cardClasses.label}>{name}</Typography>
            <Typography className={cardClasses.description}>
              Added {formatDistanceToNow(createdAt)} ago
            </Typography>
          </div>
        </div>
        <div className={cardClasses.rightContainer}>
          <Status type={statusType} />
          <Typography className={cardClasses.iconArrow}>
            <ArrowRightIcon />
          </Typography>
        </div>
      </CardActionArea>

      <IntegrationsSettingsModal
        isOpen={isModalOpen}
        setModalOpen={handleSetModalOpen}
        ecosystemId={ecosystemId}
        connection={connection}
        subheader={integrationType}
        handleFormSubmit={handleUpdateConnectionFormSubmit}
        formSubmitLoading={updateConnectionMutationLoading}
        headerActions={
          <DeleteDialog
            handleDelete={() =>
              handleDeleteConnection(serviceId, unifiedApi, () => handleSetModalOpen(false))
            }
            label={`${name} integration`}
            contentText="Are you sure you want to delete this integration?"
            opener={
              <Tooltip title="Delete this integration" placement="top" arrow>
                <div>
                  <IconButton onClick={(e) => e.preventDefault()}>
                    <DeleteIcon style={{ fontSize: 24 }} />
                  </IconButton>
                </div>
              </Tooltip>
            }
          />
        }
      />
    </Card>
  )
}

IntegrationCard.defaultProps = {
  whichSettingsModalIsOpen: null
}

IntegrationCard.propTypes = {
  connection: PropTypes.shape({
    serviceId: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    icon: PropTypes.string,
    enabled: PropTypes.bool.isRequired,
    tagLine: PropTypes.string
  }).isRequired,
  whichSettingsModalIsOpen: PropTypes.string,
  setSettingsModalOpen: PropTypes.func.isRequired
}

export default IntegrationCard
