import { ListingOverview as Overview, prepareVariables } from 'components'
import {
  approvePartnerListingMutation,
  createListingCommentMutation,
  declinePartnerListingMutation,
  deleteListingCommentMutation,
  listingCommentsQuery
} from 'api'
import { compose, withHandlers } from 'recompose'
import {
  createLinkMutation,
  createMediaMutation,
  createScreenshotMutation,
  deleteLinkMutation,
  deleteMediaMutation,
  deleteScreenshotMutation,
  marketplaceQuery,
  updateLinkMutation,
  updateMediaMutation,
  updateScreenshotMutation
} from 'api/queries'
import { ecosystemLocales, setRootValueFromTranslation } from 'utils/locales'

import LoadingPlaceholder from 'components/placeholder/Loading'
import PropTypes from 'prop-types'
import React from 'react'
import { graphql } from 'react-apollo'
import { listingOverviewQuery } from 'pages/private/ecosystem/listings/listing/graphql'
import { toastr } from 'react-redux-toastr'
import uploadFile from 'utils/fileUpload'

class Container extends React.Component {
  render() {
    const {
      listing,
      comments,
      marketplace,
      basePath,
      handleLinkCreate,
      handleLinkUpdate,
      handleLinkDelete,
      handleScreenshotCreate,
      handleScreenshotUpdate,
      handleScreenshotDelete,
      handleMediaCreate,
      handleMediaUpdate,
      handleMediaDelete,
      handleApproveListing,
      handleDeclineListing,
      handleCreateComment,
      handleDeleteComment,
      activeAccount,
      user
    } = this.props

    if (!listing) {
      return <LoadingPlaceholder />
    } else {
      return (
        <div data-testid="ecosystem-listing-overview-view">
          <Overview
            listing={listing}
            comments={comments}
            marketplace={marketplace}
            basePath={basePath}
            handleLinkCreate={handleLinkCreate}
            handleLinkUpdate={handleLinkUpdate}
            handleLinkDelete={handleLinkDelete}
            handleScreenshotCreate={handleScreenshotCreate}
            handleScreenshotUpdate={handleScreenshotUpdate}
            handleScreenshotDelete={handleScreenshotDelete}
            handleMediaCreate={handleMediaCreate}
            handleMediaUpdate={handleMediaUpdate}
            handleMediaDelete={handleMediaDelete}
            handleApproveListing={handleApproveListing}
            handleDeclineListing={handleDeclineListing}
            handleCreateComment={handleCreateComment}
            handleDeleteComment={handleDeleteComment}
            activeAccount={activeAccount}
            user={user}
          />
        </div>
      )
    }
  }
}

Container.propTypes = {
  activeAccount: PropTypes.object.isRequired,
  basePath: PropTypes.string.isRequired,
  listing: PropTypes.object.isRequired,
  marketplace: PropTypes.object.isRequired,
  handleLinkCreate: PropTypes.func.isRequired,
  handleLinkUpdate: PropTypes.func.isRequired,
  handleLinkDelete: PropTypes.func.isRequired,
  handleScreenshotCreate: PropTypes.func.isRequired,
  handleScreenshotUpdate: PropTypes.func.isRequired,
  handleScreenshotDelete: PropTypes.func.isRequired,
  handleMediaCreate: PropTypes.func.isRequired,
  handleMediaUpdate: PropTypes.func.isRequired,
  handleMediaDelete: PropTypes.func.isRequired,
  handleApproveListing: PropTypes.func.isRequired,
  handleDeclineListing: PropTypes.func.isRequired,
  handleCreateComment: PropTypes.func.isRequired,
  handleDeleteComment: PropTypes.func.isRequired
}

const addEntityToStore = (store, { listingId, ecosystemId, accountId }, entity, entityType) => {
  const data = store.readQuery({
    query: marketplaceQuery,
    variables: {
      id: ecosystemId,
      accountId
    }
  })

  const index = data.marketplace.listings.findIndex((l) => l.id === listingId)
  data.marketplace.listings[index][entityType].push(entity)

  store.writeQuery({
    query: marketplaceQuery,
    data,
    variables: {
      id: ecosystemId,
      accountId
    }
  })
}

const updateEntityInStore = (store, { listingId, ecosystemId, accountId }, entity, entityType) => {
  const data = store.readQuery({
    query: marketplaceQuery,
    variables: {
      id: ecosystemId,
      accountId
    }
  })

  const index = data.marketplace.listings.findIndex((l) => l.id === listingId)
  const entityIndex = data.marketplace.listings[index][entityType].findIndex(
    (l) => l.id === entity.id
  )

  data.marketplace.listings[index][entityType][entityIndex] = entity

  store.writeQuery({
    query: marketplaceQuery,
    data,
    variables: {
      id: ecosystemId,
      accountId
    }
  })
}

const removeEntityFromStore = (
  store,
  { ecosystemId, accountId, listingId },
  entityId,
  entityType
) => {
  const data = store.readQuery({
    query: marketplaceQuery,
    variables: {
      id: ecosystemId,
      accountId
    }
  })

  const index = data.marketplace.listings.findIndex((l) => l.id === listingId)
  data.marketplace.listings[index][entityType] = data.marketplace.listings[index][
    entityType
  ].filter((s) => s.id !== entityId)

  store.writeQuery({
    query: marketplaceQuery,
    data,
    variables: {
      id: ecosystemId,
      accountId
    }
  })
}

const handlers = withHandlers({
  handleLinkCreate:
    ({
      createLink,
      activeAccount,
      marketplace,
      match: {
        params: { listingId, ecosystemId }
      }
    }) =>
    async (props, _, handleClose) => {
      try {
        const { name, url, sequence, type } = props

        const variables = {
          accountId: activeAccount.id,
          listingId: listingId,
          id: listingId,
          link: {
            name,
            url,
            type,
            sequence,
            translations: prepareVariables(props, marketplace, [])
          }
        }

        const result = await createLink({
          variables,
          update: (store, { data: { createLink } }) =>
            addEntityToStore(
              store,
              {
                ecosystemId,
                listingId,
                accountId: activeAccount.id
              },
              createLink,
              'links'
            )
        })
        toastr.success('Link added')

        handleClose && handleClose()
        return result.data.createLink
      } catch (error) {
        toastr.error('Could not add link')
        throw new Error(error.message)
      }
    },
  handleLinkUpdate:
    ({
      updateLink,
      activeAccount,
      marketplace,
      listing,
      match: {
        params: { listingId, ecosystemId }
      }
    }) =>
    async (props, _, handleClose) => {
      const { id, name, url, type, sequence } = props
      const link = listing.links.find((i) => i.id === id)
      const locales = ecosystemLocales(marketplace)

      try {
        const updatedLink = {
          name: setRootValueFromTranslation(locales, link, 'name', name),
          url,
          type,
          sequence,
          translations: prepareVariables(props, marketplace, link.translations)
        }

        const variables = {
          accountId: activeAccount.id,
          id,
          link: updatedLink
        }

        const result = await updateLink({
          variables,
          update: (store, { data: { updateLink } }) =>
            updateEntityInStore(
              store,
              {
                ecosystemId,
                listingId,
                accountId: activeAccount.id
              },
              updateLink,
              'links'
            )
        })

        toastr.success('Link updated')

        handleClose && handleClose()
        return result.data.updateLink
      } catch (error) {
        toastr.error('Could not update link')
        throw new Error(error.message)
      }
    },
  handleLinkDelete:
    ({
      deleteLink,
      activeAccount,
      match: {
        params: { listingId, ecosystemId }
      }
    }) =>
    async (id, handleClose) => {
      try {
        const variables = {
          accountId: activeAccount.id,
          id
        }

        await deleteLink({
          variables,
          update: (store) =>
            removeEntityFromStore(
              store,
              {
                ecosystemId,
                listingId,
                accountId: activeAccount.id
              },
              id,
              'links'
            )
        })

        toastr.success('Link deleted')

        handleClose && handleClose()
      } catch (error) {
        toastr.error('Could not delete link')
        throw new Error(error.message)
      }
    },
  handleScreenshotCreate:
    ({
      createScreenshot,
      activeAccount,
      marketplace,
      listing,
      match: {
        params: { listingId, ecosystemId }
      }
    }) =>
    async (props, _, handleClose) => {
      try {
        const { caption, image } = props

        let result = await uploadFile(image[0], {
          params: {
            folder: `marketplaces/${ecosystemId}/listings/${listing.slug}/screenshots`
          }
        })

        const variables = {
          accountId: activeAccount.id,
          listingId,
          screenshot: {
            caption: caption,
            url: result.url,
            contentType: `${result.resourceType}/${result.format}`,
            translations: prepareVariables(props, marketplace, [])
          }
        }

        await createScreenshot({
          variables,
          update: (store, { data: { createScreenshot } }) =>
            addEntityToStore(
              store,
              {
                ecosystemId,
                listingId,
                accountId: activeAccount.id
              },
              createScreenshot,
              'screenshots'
            )
        })

        toastr.success('Screenshot has been created')
        handleClose()
      } catch (error) {
        toastr.error('Could not add screenshot')
        throw new Error(error.message)
      }
    },
  handleScreenshotUpdate:
    ({
      updateScreenshot,
      activeAccount,
      listing,
      marketplace,
      match: {
        params: { listingId, ecosystemId }
      }
    }) =>
    async (props, _, handleClose) => {
      try {
        const { id, caption, image, sequence } = props
        const screenshot = listing.screenshots.find((i) => i.id === id)
        const locales = ecosystemLocales(marketplace)

        let variables = {
          accountId: activeAccount.id,
          id,
          screenshot: {
            url: screenshot?.url,
            contentType: screenshot?.contentType,
            sequence: sequence ?? screenshot.sequence,
            caption: setRootValueFromTranslation(locales, screenshot, 'caption', caption),
            translations: prepareVariables(props, marketplace, screenshot.translations)
          }
        }

        if (image && image.length !== 0 && image[0].preview) {
          let result = await uploadFile(image[0], {
            params: {
              folder: `marketplaces/${ecosystemId}/listings/${listing.slug}/screenshots`
            }
          })

          variables.screenshot = Object.assign(variables.screenshot, {
            url: result.url,
            contentType: `${result.resourceType}/${result.format}`
          })
        }

        const result = await updateScreenshot({
          variables,
          update: (store) => {
            updateEntityInStore(
              store,
              {
                ecosystemId,
                listingId,
                accountId: activeAccount.id
              },
              updateScreenshot,
              'screenshots'
            )
          }
        })

        toastr.success('Screenshot has been updated')
        if (handleClose) {
          handleClose()
        }
        return result?.data?.updateScreenshot
      } catch (error) {
        toastr.error('Could not update screenshot')
        throw new Error(error.message)
      }
    },
  handleScreenshotDelete:
    ({
      deleteScreenshot,
      activeAccount,
      match: {
        params: { listingId, ecosystemId }
      }
    }) =>
    async (id, handleClose) => {
      try {
        const variables = {
          accountId: activeAccount.id,
          id
        }

        await deleteScreenshot({
          variables,
          update: (store) =>
            removeEntityFromStore(
              store,
              {
                ecosystemId,
                listingId,
                accountId: activeAccount.id
              },
              id,
              'screenshots'
            )
        })

        toastr.success('Screenshot deleted')

        handleClose()
      } catch (error) {
        toastr.error('Could not delete screenshot')
        throw new Error(error.message)
      }
    },
  handleMediaCreate:
    ({
      createMedia,
      activeAccount,
      marketplace,
      match: {
        params: { listingId, ecosystemId }
      }
    }) =>
    async (props, _, handleClose) => {
      try {
        const { caption, url } = props

        const variables = {
          accountId: activeAccount.id,
          listingId,
          media: {
            caption,
            url,
            translations: prepareVariables(props, marketplace, [])
          }
        }

        await createMedia({
          variables,
          update: (store, { data: { createMedia } }) =>
            addEntityToStore(
              store,
              {
                ecosystemId,
                listingId,
                accountId: activeAccount.id
              },
              createMedia,
              'media'
            )
        })

        toastr.success('The video has been added')
        handleClose()
      } catch (error) {
        toastr.error('Could not add video')
        throw new Error(error.message)
      }
    },
  handleMediaUpdate:
    ({
      updateMedia,
      activeAccount,
      listing,
      marketplace,
      match: {
        params: { listingId, ecosystemId }
      }
    }) =>
    async (props, _, handleClose) => {
      try {
        const { id, caption, url } = props
        const media = listing.media.find((i) => i.id === id)
        const locales = ecosystemLocales(marketplace)

        let variables = {
          accountId: activeAccount.id,
          id,
          media: {
            caption: setRootValueFromTranslation(locales, media, 'caption', caption),
            url,
            translations: prepareVariables(props, marketplace, media.translations)
          }
        }

        await updateMedia({
          variables,
          update: (store, { data: { updateMedia } }) =>
            updateEntityInStore(
              store,
              {
                ecosystemId,
                listingId,
                accountId: activeAccount.id
              },
              updateMedia,
              'media'
            )
        })

        toastr.success('Video has been updated')
        handleClose()
      } catch (error) {
        toastr.error('Could not update video')
        throw new Error(error.message)
      }
    },
  handleMediaDelete:
    ({
      deleteMedia,
      activeAccount,
      match: {
        params: { listingId, ecosystemId }
      }
    }) =>
    async (id, handleClose) => {
      try {
        const variables = {
          accountId: activeAccount.id,
          id
        }

        await deleteMedia({
          variables,
          update: (store) =>
            removeEntityFromStore(
              store,
              {
                ecosystemId,
                listingId,
                accountId: activeAccount.id
              },
              id,
              'media'
            )
        })

        toastr.success('Video deleted')

        handleClose()
      } catch (error) {
        toastr.error('Could not delete video')
        throw new Error(error.message)
      }
    },
  handleApproveListing:
    ({
      approveListing,
      activeAccount,
      match: {
        params: { listingId }
      }
    }) =>
    async (props) => {
      const { comment } = props

      const variables = {
        id: listingId,
        accountId: activeAccount.id,
        ...(comment && comment !== '' ? { comment } : {})
      }

      try {
        const result = await approveListing({
          variables,
          update: (store, { data: { approvePartnerListing } }) => {
            const { listing } = store.readQuery({
              query: listingOverviewQuery,
              variables: {
                listingId,
                accountId: activeAccount.id
              }
            })

            const data = { listing: { ...listing, ...approvePartnerListing } }

            store.writeQuery({
              query: listingOverviewQuery,
              data,
              variables: {
                listingId,
                accountId: activeAccount.id
              }
            })
          }
        })

        toastr.success('Listing changes have been approved')
        return result.data.approvePartnerListing
      } catch (error) {
        toastr.error('Could not approve listing changes')
        throw new Error(error.message)
      }
    },
  handleDeclineListing:
    ({
      declineListing,
      activeAccount,
      match: {
        params: { listingId }
      }
    }) =>
    async (props) => {
      const { comment } = props

      const variables = {
        id: listingId,
        accountId: activeAccount.id,
        ...(comment && comment !== '' ? { comment } : {})
      }

      try {
        const result = await declineListing({
          variables,
          update: (store, { data: { declinePartnerListing } }) => {
            const { listing } = store.readQuery({
              query: listingOverviewQuery,
              variables: {
                listingId,
                accountId: activeAccount.id
              }
            })

            const data = { listing: { ...listing, ...declinePartnerListing } }

            store.writeQuery({
              query: listingOverviewQuery,
              data,
              variables: {
                listingId,
                accountId: activeAccount.id
              }
            })
          }
        })

        toastr.success('Listing changes have been declined')
        return result.data.approvePartnerListing
      } catch (error) {
        toastr.error('Could not decline listing changes at this time')
        throw new Error(error.message)
      }
    },
  handleDeleteComment:
    ({
      deleteComment,
      activeAccount,
      match: {
        params: { listingId }
      }
    }) =>
    async ({ id }) => {
      try {
        await deleteComment({
          variables: {
            id,
            listingId,
            accountId: activeAccount.id
          },
          update: (store) => {
            const data = store.readQuery({
              query: listingCommentsQuery,
              variables: {
                listingId,
                accountId: activeAccount.id
              }
            })

            data.listingComments = data.listingComments.filter((i) => i.id !== id)

            store.writeQuery({
              query: listingCommentsQuery,
              data,
              variables: {
                listingId,
                accountId: activeAccount.id
              }
            })
          }
        })
      } catch (error) {
        toastr.error('Could not delete comment')
        throw new Error(error.message)
      }
    },
  handleCreateComment:
    ({
      createComment,
      activeAccount,
      match: {
        params: { listingId }
      }
    }) =>
    async (props) => {
      const { comment } = props
      try {
        await createComment({
          variables: {
            listingId,
            accountId: activeAccount.id,
            comment
          },
          update: (store, { data: { createAdminListingComment } }) => {
            const data = store.readQuery({
              query: listingCommentsQuery,
              variables: {
                listingId,
                accountId: activeAccount.id
              }
            })

            const updatedData = {
              ...data,
              listingComments: [...data.listingComments, createAdminListingComment]
            }

            store.writeQuery({
              query: listingCommentsQuery,
              data: updatedData,
              variables: {
                listingId,
                accountId: activeAccount.id
              }
            })
          }
        })
      } catch (error) {
        toastr.error('Could not add comment on listing')
        throw new Error(error.message)
      }
    }
})

const OverviewContainer = compose(
  graphql(createScreenshotMutation, { name: 'createScreenshot' }),
  graphql(updateScreenshotMutation, { name: 'updateScreenshot' }),
  graphql(deleteScreenshotMutation, { name: 'deleteScreenshot' }),
  graphql(createMediaMutation, { name: 'createMedia' }),
  graphql(updateMediaMutation, { name: 'updateMedia' }),
  graphql(deleteMediaMutation, { name: 'deleteMedia' }),
  graphql(createLinkMutation, { name: 'createLink' }),
  graphql(updateLinkMutation, { name: 'updateLink' }),
  graphql(deleteLinkMutation, { name: 'deleteLink' }),
  graphql(approvePartnerListingMutation, { name: 'approveListing' }),
  graphql(declinePartnerListingMutation, { name: 'declineListing' }),
  graphql(createListingCommentMutation, { name: 'createComment' }),
  graphql(deleteListingCommentMutation, { name: 'deleteComment' }),
  handlers
)(Container)

export default OverviewContainer
