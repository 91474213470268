import gql from 'graphql-tag'

const automatedQuery = gql`
  query automated($accountId: ID!, $ecosystemId: ID!) {
    marketplace(id: $ecosystemId, accountId: $accountId) {
      id
      listings {
        id
        slug
      }
    }
  }
`

export default automatedQuery
