export default (entity, key, locales, locale = 'en') => {
  if (
    locales?.length > 0 &&
    Array.isArray(entity?.translations) &&
    entity.translations.length > 0
  ) {
    const translation = entity.translations.find(
      (f) => f.key === key && f.locale === locale && f.value
    )

    if (translation) {
      return translation.value
    }
  }
  return entity[key] ?? ''
}
