import React from 'react'

const BorderIcon = ({ src, alt, ...rest }) => (
  <img
    src={src}
    alt={alt}
    width="24"
    height="24"
    style={{
      marginRight: '10px',
      borderRadius: '3px'
    }}
    {...rest}
  />
)

export default BorderIcon
