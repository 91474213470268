import gql from 'graphql-tag'

export const accountUsersQuery = gql`
  query accountUsersQuery($accountId: ID!) {
    accountUsers(accountId: $accountId) {
      id
      role
      inactive
      notificationPreferences {
        weeklyDigest
        listingChanges
      }
      user {
        id
        name
        email
        imageUrl
      }
      invitation {
        id
        createdAt
        updatedAt
        status
      }
    }
  }
`
