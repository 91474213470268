export default (ecosystem) => {
  if (ecosystem) {
    if (process.env.NODE_ENV === 'development') {
      return `http://${ecosystem.slug}.localhost:3002`
    } else if (process.env.REACT_APP_ENV === 'staging') {
      return `https://${ecosystem.slug}.apideck.dev`
    } else {
      if (ecosystem.customDomain) {
        return ecosystem.customDomain.replace(/\/$/, '') // strips trailing slash
      }

      return `https://${ecosystem.slug}.apideck.io`
    }
  }
}
