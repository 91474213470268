import React from 'react'
import { Query } from 'react-apollo'
import { catalogServiceQuery } from 'api/queries'
import { adopt } from 'react-adopt'

function service({ domain, render }) {
  return (
    <Query partialRefetch={true} query={catalogServiceQuery} variables={{ id: domain }}>
      {render}
    </Query>
  )
}

const EcosystemSetupContainer = adopt({
  service
})

export default EcosystemSetupContainer
