import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { IntegrationPlatformsPage } from './integration-platforms'
import { ServicesPage } from './services'
import { TemplatePage } from './template'
import { useEcosystemContext } from 'context'
import { EcosystemSetupLayout } from 'components'
import Stepper from './Stepper'

function getStepContent({ stepIndex, ...props }) {
  switch (stepIndex) {
    case 0:
      return <IntegrationPlatformsPage {...props} />
    case 1:
      return <ServicesPage {...props} />
    case 2:
      return <TemplatePage {...props} />
    default:
      return 'Unknown stepIndex'
  }
}

function EcosystemSetupArea({ steps, catalogService, activeAccount }) {
  const { ecosystem } = useEcosystemContext()
  const [activeStep, setActiveStep] = useState(0)
  const [completed, setCompleted] = useState({})
  const totalSteps = steps.length
  const completedSteps = Object.keys(completed).length

  const handleNext = () => {
    const allStepsAreCompleted = completedSteps === totalSteps
    const isLastStep = activeStep === totalSteps - 1
    let aStep = activeStep

    if (isLastStep && !allStepsAreCompleted) {
      // It's the last step, but not all steps have been completed,
      // find the first step that has been completed
      aStep = steps.findIndex((step, i) => !(i in completed))
    } else {
      aStep += 1
    }

    setActiveStep(aStep)
  }

  const handleComplete = () => {
    let cpCompleted = completed

    cpCompleted[activeStep] = true
    setCompleted(cpCompleted)

    handleNext()
  }
  const handleStep = (step) => () => {
    setActiveStep(step)
  }

  return (
    <EcosystemSetupLayout
      activeAccount={activeAccount}
      stepper={
        <Stepper
          activeStep={activeStep}
          completed={completed}
          handleStep={handleStep}
          steps={steps}
        />
      }
    >
      {getStepContent({
        activeAccount,
        ecosystem,
        handleComplete,
        stepIndex: activeStep,
        service: catalogService
      })}
    </EcosystemSetupLayout>
  )
}

EcosystemSetupArea.propTypes = {
  catalogService: PropTypes.object
}

export default EcosystemSetupArea
