import { createCategoryMutation, categoriesQuery } from 'api/queries'
import { CategoryCreate as Create, injectLocalizedValues, prepareVariables } from 'components'
import React from 'react'
import { graphql } from 'react-apollo'
import { toastr } from 'react-redux-toastr'
import { compose, withHandlers } from 'recompose'
import uploadFile from 'utils/fileUpload'
import { ecosystemLocales } from 'utils/locales'
import transformError from 'utils/transformError'

export class Container extends React.Component {
  render() {
    const { handleSubmit, ecosystem } = this.props

    let initialValues = {
      description: '',
      logo: [],
      name: '',
      slug: '',
      locales: ecosystemLocales(ecosystem)
    }

    initialValues = injectLocalizedValues('category', initialValues)

    return (
      <Create ecosystem={ecosystem} handleSubmit={handleSubmit} initialValues={initialValues} />
    )
  }
}

const handleSubmit = withHandlers({
  handleSubmit:
    ({
      createCategory,
      activeAccount,
      match: {
        params: { ecosystemId }
      },
      ecosystem,
      history
    }) =>
    async (props, { setErrors, setSubmitting }) => {
      setSubmitting(true)

      const { description, logo, name, slug } = props

      let variables = {
        accountId: activeAccount.id,
        ecosystemId,
        name,
        slug,
        description,
        translations: prepareVariables(props, ecosystem, [])
      }

      if (logo.length !== 0) {
        let result = await uploadFile(logo[0], {
          params: {
            folder: `marketplaces/${ecosystemId}/categories`
          }
        })

        variables = {
          ...variables,
          logo: {
            url: result.url,
            contentType: `${result.resourceType}/${result.format}`
          }
        }
      }

      try {
        await createCategory({
          variables,
          update: (store, { data: { createCategory } }) => {
            const data = store.readQuery({
              query: categoriesQuery,
              variables: {
                id: ecosystemId,
                accountId: activeAccount.id
              }
            })

            data.marketplace.categories.push({ ...createCategory })

            store.writeQuery({
              query: categoriesQuery,
              data,
              variables: {
                id: ecosystemId,
                accountId: activeAccount.id
              }
            })
          }
        })
        toastr.success('Category created')
        history.push(`/ecosystems/${ecosystemId}/listings/categories`)
      } catch (error) {
        toastr.error('Could not create category')
        if (error.graphQLErrors) {
          setErrors(transformError(error.graphQLErrors))
        }

        setSubmitting(false)
      }
    }
})

const createCategory = graphql(createCategoryMutation, {
  name: 'createCategory'
})

const CreateContainer = compose(createCategory, handleSubmit)(Container)

export default CreateContainer
