import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import { Formik } from 'formik'
import PropTypes from 'prop-types'
import React from 'react'
import DialogActions from './Actions'
import DialogTitle from './Title'

const styles = () => ({
  root: { overflow: 'visible' }
})

class FormDialog extends React.Component {
  static propTypes = {
    opener: PropTypes.func.isRequired
  }

  state = {
    open: false
  }

  /**
   * The dialog can serve many purposes by opening with a completely new state
   * this should be validated eventually, but for now newState should have:
   *
   *  title: string
   *  contentText: string
   *  validationSchema: yup schema object
   *  initialValues: formik initialValues
   *  render: formik render prop
   *  onSubmit: formik/dialog submit handler
   */
  handleOpen = (newState) => {
    this.setState(() => ({
      ...newState,
      open: true
    }))
  }

  handleClose = () => {
    this.setState({
      open: false
    })
  }

  /**
   * Calls the onSubmit passed via handleOpen with the
   * formik submit args and the dialog close function
   * (values, options: { setSubmitting, setErrors }, handleClose)
   */
  handleSubmit = (...formikSubmitArgs) => {
    this.state.onSubmit(...formikSubmitArgs, this.handleClose)
  }

  handleDelete = () => {
    this.state.onDelete(this.state.initialValues.id, this.handleClose)
  }

  render() {
    const { open, title, contentText, validationSchema, initialValues, render } = this.state

    const { classes } = this.props

    return (
      <React.Fragment>
        {this.props.opener(this.handleOpen)}
        <Dialog
          open={open}
          onClose={this.handleClose}
          fullWidth={true}
          classes={{ paper: classes.paper }}
        >
          <DialogTitle>{title}</DialogTitle>
          <Formik
            validationSchema={validationSchema}
            initialValues={initialValues}
            onSubmit={this.handleSubmit}
          >
            {(formikProps) => {
              const { handleSubmit, isSubmitting } = formikProps
              return (
                <form onSubmit={handleSubmit}>
                  <DialogContent style={{ overflow: 'visible' }} classes={this.props.classes}>
                    <DialogContentText>{contentText}</DialogContentText>
                    {render(formikProps)}
                  </DialogContent>
                  <DialogActions>
                    {typeof this.state.onDelete === 'function' ? (
                      <Button onClick={this.handleDelete} className={classNames('delete')}>
                        Delete
                      </Button>
                    ) : (
                      ''
                    )}
                    <Button onClick={this.handleClose}>Cancel</Button>
                    <Button type="submit" color="secondary" disabled={isSubmitting}>
                      Submit
                    </Button>
                  </DialogActions>
                </form>
              )
            }}
          </Formik>
        </Dialog>
      </React.Fragment>
    )
  }
}

export default withStyles(styles)(FormDialog)
