import { fileFieldsFragment, translationFieldsFragment } from 'api/fragments'
import gql from 'graphql-tag'

const productsQuery = gql`
  query products($id: ID!, $accountId: ID!) {
    products(ecosystemId: $id, accountId: $accountId) {
      id
      name
      slug
      visible
      sequence
      logo {
        ...fileFields
      }
      translations {
        ...translationFields
      }
    }
  }

  ${fileFieldsFragment}
  ${translationFieldsFragment}
`

export default productsQuery
