import { ecosystemSettingsQuery } from 'api'

export const updateTemplateSettingsInStore = (store, { ecosystem, accountId }) => {
  const data = store.readQuery({
    query: ecosystemSettingsQuery,
    variables: {
      id: ecosystem.id,
      accountId
    }
  })

  data.marketplace = { ...data.marketplace, ecosystem }

  store.writeQuery({
    query: ecosystemSettingsQuery,
    data,
    variables: {
      id: ecosystem.id,
      accountId
    }
  })
}

export const addNavigationItemToStore = (store, { ecosystemId, accountId }, item) => {
  const data = store.readQuery({
    query: ecosystemSettingsQuery,
    variables: {
      id: ecosystemId,
      accountId
    }
  })

  data.marketplace.navigationItems = [...(data.marketplace.navigationItems ?? []), item]

  store.writeQuery({
    query: ecosystemSettingsQuery,
    data,
    variables: {
      id: ecosystemId,
      accountId
    }
  })
}

export const updateNavigationItemInStore = (store, { ecosystemId, accountId }, item) => {
  const data = store.readQuery({
    query: ecosystemSettingsQuery,
    variables: {
      id: ecosystemId,
      accountId
    }
  })

  const itemIndex = (data.marketplace.navigationItems ?? []).findIndex((i) => i.id === item.id)
  if (itemIndex && itemIndex > 0) {
    data.marketplace.navigationItems[itemIndex] = { ...item }
  }

  store.writeQuery({
    query: ecosystemSettingsQuery,
    data,
    variables: {
      id: ecosystemId,
      accountId
    }
  })
}

export const deleteNavigationItemFromStore = (store, { ecosystemId, accountId }, id) => {
  const data = store.readQuery({
    query: ecosystemSettingsQuery,
    variables: {
      id: ecosystemId,
      accountId
    }
  })

  data.marketplace.navigationItems = (data.marketplace.navigationItems ?? []).filter(
    (i) => i.id !== id
  )

  store.writeQuery({
    query: ecosystemSettingsQuery,
    data,
    variables: {
      id: ecosystemId,
      accountId
    }
  })
}

export const addFooterItemToStore = (store, { ecosystemId, accountId }, item) => {
  const data = store.readQuery({
    query: ecosystemSettingsQuery,
    variables: {
      id: ecosystemId,
      accountId
    }
  })

  data.marketplace.footerItems = [...(data.marketplace.footerItems ?? []), item]

  store.writeQuery({
    query: ecosystemSettingsQuery,
    data,
    variables: {
      id: ecosystemId,
      accountId
    }
  })
}

export const updateFooterItemInStore = (store, { ecosystemId, accountId }, item) => {
  const data = store.readQuery({
    query: ecosystemSettingsQuery,
    variables: {
      id: ecosystemId,
      accountId
    }
  })

  const itemIndex = (data.marketplace.footerItems ?? []).findIndex((i) => i.id === item.id)
  if (itemIndex && itemIndex > 0) {
    data.marketplace.footerItems[itemIndex] = { ...item }
  }

  store.writeQuery({
    query: ecosystemSettingsQuery,
    data,
    variables: {
      id: ecosystemId,
      accountId
    }
  })
}

export const deleteFooterItemFromStore = (store, { ecosystemId, accountId }, id) => {
  const data = store.readQuery({
    query: ecosystemSettingsQuery,
    variables: {
      id: ecosystemId,
      accountId
    }
  })

  data.marketplace.footerItems = (data.marketplace.footerItems ?? []).filter((i) => i.id !== id)

  store.writeQuery({
    query: ecosystemSettingsQuery,
    data,
    variables: {
      id: ecosystemId,
      accountId
    }
  })
}

export const deleteImageFromStore = (store, { ecosystemId, accountId, propName }) => {
  const data = store.readQuery({
    query: ecosystemSettingsQuery,
    variables: {
      id: ecosystemId,
      accountId
    }
  })

  data.marketplace[propName] = null

  store.writeQuery({
    query: ecosystemSettingsQuery,
    data,
    variables: {
      id: ecosystemId,
      accountId
    }
  })
}

export const addLocaleToStore = (store, { ecosystemId, accountId }, item) => {
  const data = store.readQuery({
    query: ecosystemSettingsQuery,
    variables: {
      id: ecosystemId,
      accountId
    }
  })

  data.marketplace.locales = [...(data.marketplace.locales ?? []), item]

  store.writeQuery({
    query: ecosystemSettingsQuery,
    data,
    variables: {
      id: ecosystemId,
      accountId
    }
  })
}

export const deleteLocaleFromStore = (store, { ecosystemId, accountId }, id) => {
  const data = store.readQuery({
    query: ecosystemSettingsQuery,
    variables: {
      id: ecosystemId,
      accountId
    }
  })

  data.marketplace.locales = (data.marketplace.locales ?? []).filter((i) => i.id !== id)

  store.writeQuery({
    query: ecosystemSettingsQuery,
    data,
    variables: {
      id: ecosystemId,
      accountId
    }
  })
}
