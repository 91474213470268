import React from 'react'
import Button from '@material-ui/core/Button'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'

const OpenInNewButton = ({ href, children }) => (
  <Button
    href={href}
    target="blank"
    size="small"
    endIcon={
      <OpenInNewIcon
        style={{
          color: 'currentColor',
          fontSize: 16
        }}
      />
    }
  >
    {children}
  </Button>
)

export default OpenInNewButton
