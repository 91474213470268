import { makeStyles } from '@material-ui/core/styles'
import { IntegrationsSettingsAddModal } from 'components'
import PropTypes from 'prop-types'
import queryString from 'query-string'
import React, { Fragment, useEffect, useState } from 'react'
import { useIntercom } from 'react-use-intercom'
import { CrmConnections } from './CrmConnections'
import { Platforms } from './Platforms'
import { ThirdPartySnippets } from './ThirdPartySnippets'

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: 500,
    fontSize: '14px',
    color: theme.palette.ui[700],
    marginTop: theme.spacing(2)
  },
  cardsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: theme.spacing(2)
  }
}))

const IntegrationsSettings = ({
  accountId,
  ecosystem,
  connections,
  connectionsQueryLoading,
  connectionsQueryError,
  updateMarketplace,
  updateIntegrationSettings,
  updateIntegrationSettingsMutationLoading,
  handleUpdateConnectionFormSubmit,
  updateConnectionMutationLoading,
  handleDeleteConnection,
  updateIntegrationPlatformSettingsMutationLoading
}) => {
  const { show: showIntercom } = useIntercom()
  const [whichAddModalIsOpen, setAddModalOpen] = useState(null)
  const [whichSettingsModalIsOpen, setSettingsModalOpen] = useState(null)
  const classes = useStyles()

  let scriptConnections = []
  let leadsConnections = []
  let addedScriptConnections = []
  let remainingScriptConnections = []
  let addedLeadsConnections = []
  let remainingLeadsConnections = []

  if (!connectionsQueryLoading) {
    scriptConnections = connections.filter((connection) => connection.unifiedApi === 'script')
    leadsConnections = connections.filter((connection) => connection.unifiedApi === 'crm')
    addedScriptConnections = scriptConnections.filter((connection) => connection.added)
    remainingScriptConnections = scriptConnections.filter((connection) => !connection.added)
    addedLeadsConnections = leadsConnections.filter((connection) => connection.added)
    remainingLeadsConnections = leadsConnections.filter((connection) => !connection.added)
  }
  const ErrorMessage = (
    <Fragment>
      An error occurred while loading the integration providers. Please try again by reloading the
      page. If the error re-occurs, please&nbsp;
      <a
        href="#support"
        onClick={(e) => {
          e.preventDefault()
          showIntercom()
        }}
      >
        contact support
      </a>
      .
    </Fragment>
  )

  useEffect(() => {
    const values = queryString.parse(window.location.search)

    if (values.modal) {
      const modalId = values.modal.split('_').join('+')
      setSettingsModalOpen(modalId)
    } else {
      setSettingsModalOpen(null)
    }
  }, [])

  return (
    <Fragment>
      <div data-testid="ecosystem-settings-integrations-form">
        <ThirdPartySnippets
          classes={classes}
          ecosystem={ecosystem}
          connectionsQueryLoading={connectionsQueryLoading}
          connectionsQueryError={connectionsQueryError}
          addedScriptConnections={addedScriptConnections}
          handleUpdateConnectionFormSubmit={handleUpdateConnectionFormSubmit}
          updateConnectionMutationLoading={updateConnectionMutationLoading}
          handleDeleteConnection={handleDeleteConnection}
          whichSettingsModalIsOpen={whichSettingsModalIsOpen}
          setSettingsModalOpen={setSettingsModalOpen}
          setAddModalOpen={setAddModalOpen}
          ErrorMessage={ErrorMessage}
        />

        <Platforms
          classes={classes}
          accountId={accountId}
          ecosystem={ecosystem}
          updateMarketplace={updateMarketplace}
          updateIntegrationSettings={updateIntegrationSettings}
          updateIntegrationSettingsMutationLoading={updateIntegrationSettingsMutationLoading}
          updateIntegrationPlatformSettingsMutationLoading={
            updateIntegrationPlatformSettingsMutationLoading
          }
        />

        <CrmConnections
          classes={classes}
          ecosystem={ecosystem}
          connectionsQueryLoading={connectionsQueryLoading}
          connectionsQueryError={connectionsQueryError}
          connections={addedLeadsConnections}
          handleUpdateConnectionFormSubmit={handleUpdateConnectionFormSubmit}
          updateConnectionMutationLoading={updateConnectionMutationLoading}
          handleDeleteConnection={handleDeleteConnection}
          whichSettingsModalIsOpen={whichSettingsModalIsOpen}
          setSettingsModalOpen={setSettingsModalOpen}
          setAddModalOpen={setAddModalOpen}
        />

        {!connectionsQueryLoading && (
          <IntegrationsSettingsAddModal
            unifiedApi={whichAddModalIsOpen === 'third-party-snippets' ? 'script' : 'crm'}
            title="Add an integration"
            isOpen={!!whichAddModalIsOpen}
            setModalOpen={setAddModalOpen}
            connections={
              whichAddModalIsOpen === 'third-party-snippets'
                ? remainingScriptConnections
                : remainingLeadsConnections
            }
            handleFormSubmit={handleUpdateConnectionFormSubmit}
            formSubmitLoading={updateConnectionMutationLoading}
            successCallback={(id) => {
              setSettingsModalOpen(id)
              // add query string
              window.history.pushState(
                {},
                document.title,
                `${window.location.pathname}?modal=${id.split('+').join('_')}`
              )
              setAddModalOpen(false)
            }}
          />
        )}
      </div>
    </Fragment>
  )
}

IntegrationsSettings.propTypes = {
  connections: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      unifiedApi: PropTypes.string.isRequired,
      added: PropTypes.bool.isRequired
    }).isRequired
  ).isRequired,
  connectionsQueryLoading: PropTypes.bool.isRequired,
  connectionsQueryError: PropTypes.bool.isRequired
}

export default IntegrationsSettings
