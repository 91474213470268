import { Card, EmptyState } from 'components'
import React, { Fragment } from 'react'

import Button from '@material-ui/core/Button'
import ContactsList from '../contacts/List'
import CreateContactDialog from '../contacts/CreateDialog'
import { Link } from 'react-router-dom'
import ListingsList from '../listings/List'
import PropTypes from 'prop-types'

function Overview({ partner, ecosystem, handleContactCreate, ...props }) {
  const contacts = partner?.contacts ?? []
  const listings = partner?.listings ?? []
  return (
    <Fragment>
      <Card
        title="Contacts"
        button={
          <CreateContactDialog
            handleSubmit={handleContactCreate}
            label="Contact"
            opener={
              <Button variant="contained" className="fr" color="primary">
                + Create
              </Button>
            }
          />
        }
      >
        {contacts.length === 0 ? (
          <EmptyState padding="50">No contacts found. </EmptyState>
        ) : (
          <ContactsList contacts={contacts} {...props} />
        )}
      </Card>
      <Card
        title="Listings"
        button={
          <Link to={`/ecosystems/${ecosystem.id}/partners/${partner.id}/settings`}>
            <Button variant="contained" className="fr" color="primary">
              + Add
            </Button>
          </Link>
        }
      >
        {listings.length === 0 ? (
          <EmptyState padding="50">No listings found. </EmptyState>
        ) : (
          <ListingsList listings={listings} ecosystem={ecosystem} {...props} />
        )}
      </Card>
    </Fragment>
  )
}

Overview.propTypes = {
  partner: PropTypes.object.isRequired,
  ecosystem: PropTypes.object.isRequired,
  handleContactCreate: PropTypes.func.isRequired,
  handleContactUpdate: PropTypes.func.isRequired,
  handleContactDelete: PropTypes.func.isRequired,
  handleContactsInvite: PropTypes.func.isRequired
}

export default Overview
