import React from 'react'
import Button from '@material-ui/core/Button'

const PreviewButton = ({ href }) => (
  <a href={`${href}?_vercel_no_cache=1`} target="_blank" rel="noopener noreferrer">
    <Button variant="contained" className="fr" color="primary" style={{ marginRight: '15px' }}>
      Preview
    </Button>
  </a>
)

export default PreviewButton
