import React from 'react'
import PropTypes from 'prop-types'
import MUICard from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import Tooltip from '@material-ui/core/Tooltip'
import { makeStyles } from '@material-ui/core/styles'
import { InfoIcon, Loader } from 'components'

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.06), 0 1px 3px 0 rgba(4, 7, 45, 0.1)',
    marginBottom: theme.spacing(4)
  },
  header: {
    minHeight: '70px',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    borderBottom: `1px solid ${theme.palette.ui[200]}`
  },
  headerTitle: {
    display: 'flex',
    alignItems: 'center',
    lineHeight: 1
  },
  headerTitleText: {
    fontWeight: 500,
    color: theme.palette.ui[800]
  },
  titlePillContainer: {
    marginLeft: theme.spacing(0.5)
  },
  tooltipContainer: {
    lineHeight: 1,
    marginLeft: theme.spacing(1)
  },
  content: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3)
  },
  contentWithVerticalPadding: {
    padding: `${theme.spacing(6, 3)} !important`
  },
  action: {
    margin: 0,
    alignSelf: 'center'
  },
  contentTitle: {
    fontWeight: 500,
    fontSize: 16,
    color: theme.palette.ui[600],
    textAlign: 'center',
    marginBottom: theme.spacing(1)
  },
  contentMessage: {
    color: theme.palette.ui[400],
    textAlign: 'center',
    fontSize: 14
  }
}))

const Card = ({
  title,
  titlePill,
  tooltipContent,
  action,
  loading,
  ErrorMessage,
  PlaceholderMessage,
  children
}) => {
  const classes = useStyles()
  let contentToRender

  if (loading) {
    contentToRender = (
      <CardContent className={classes.contentWithVerticalPadding}>
        <Loader size={32} />
      </CardContent>
    )
  } else if (ErrorMessage) {
    contentToRender = (
      <CardContent className={classes.contentWithVerticalPadding}>
        <Typography className={classes.contentTitle}>Something went wrong..</Typography>
        <Typography className={classes.contentMessage}>{ErrorMessage}</Typography>
      </CardContent>
    )
  } else if (PlaceholderMessage) {
    contentToRender = (
      <CardContent className={classes.contentWithVerticalPadding}>
        <Typography className={classes.contentTitle}>
          No {title.toLowerCase()} added yet..
        </Typography>
        <Typography className={classes.contentMessage}>{PlaceholderMessage}</Typography>
      </CardContent>
    )
  } else {
    contentToRender = <CardContent className={classes.content}>{children}</CardContent>
  }

  return (
    <MUICard className={classes.root}>
      <CardHeader
        className={classes.header}
        disableTypography
        title={
          <div className={classes.headerTitle}>
            <Typography className={classes.headerTitleText} component="h3">
              {title}
            </Typography>
            {titlePill && <div className={classes.titlePillContainer}>{titlePill}</div>}
            {tooltipContent && (
              <Tooltip title={tooltipContent} placement="right" arrow>
                <div className={classes.tooltipContainer}>
                  <InfoIcon />
                </div>
              </Tooltip>
            )}
          </div>
        }
        action={action}
        classes={{
          action: classes.action
        }}
      />
      {contentToRender}
    </MUICard>
  )
}

Card.defaultProps = {
  title: '',
  action: false,
  loading: false,
  ErrorMessage: null,
  PlaceholderMessage: null,
  titlePill: null
}

Card.propTypes = {
  title: PropTypes.node,
  tooltipContent: PropTypes.string,
  action: PropTypes.node,
  loading: PropTypes.bool,
  titlePill: PropTypes.node,
  ErrorMessage: PropTypes.node,
  PlaceholderMessage: PropTypes.node
}

export default Card
