import { propEq, reject } from 'ramda'
import { marketplaceQuery } from 'api/queries'

function remove(cache, { accountId, ecosystemId }, { id }) {
  const query = marketplaceQuery
  const { marketplace } = cache.readQuery({
    query,
    variables: {
      id: ecosystemId,
      accountId
    }
  })

  const byListingId = propEq('id', id)

  marketplace.listings = reject(byListingId, marketplace.listings)

  cache.writeQuery({
    data: { marketplace },
    query,
    variables: {
      id: ecosystemId,
      accountId
    }
  })
}

export { remove }
