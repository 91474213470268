import ecosystemUrl from 'utils/ecosystemUrl'

export const normalizeUserAccount = (userAccount) => {
  return {
    id: userAccount?.account.id,
    name: userAccount?.account.name,
    website: userAccount?.account.website,
    plan:
      userAccount?.account.plan &&
      userAccount?.account.subscriptionStatus === 'ACTIVE' &&
      userAccount?.account.plan,
    subscriptionStatus: userAccount?.account.subscriptionStatus,
    endOfTrial: userAccount?.account.endOfTrial,
    createdAt: userAccount?.account.createdAt,
    ecosystems: userAccount?.account.marketplaces?.map((ecosystem) => ({
      id: ecosystem.id,
      name: ecosystem.name,
      slug: ecosystem.slug,
      customDomain: ecosystem.customDomain,
      url: ecosystemUrl(ecosystem),
      amountOfListings: Array.isArray(ecosystem?.listings) ? ecosystem.listings.length : 0
    }))
  }
}

export const normalizeUserDataForSegment = (user, userAccounts = [], ecosystems = []) => {
  const ecosystem = ecosystems?.[0]

  const companies = user.accounts.map((userAccount) => normalizeUserAccount(userAccount))

  const segmentData = {
    id: user.id,
    email: user.email,
    name: user.name,
    plan:
      userAccounts[0].account.plan &&
      userAccounts[0].account.subscriptionStatus === 'ACTIVE' &&
      userAccounts[0].account.plan,
    endOfTrial: userAccounts[0].endOfTrial,
    ecosystemSlug: ecosystem?.slug,
    ecosystemUrl: ecosystemUrl(ecosystem),
    customDomain: ecosystem?.customDomain,
    listings: Array.isArray(ecosystem?.listings) ? ecosystem.listings.length : 0,
    companies: companies,
    ecosystems: ecosystems.map((_ecosystem) => ({
      id: _ecosystem.id,
      name: _ecosystem.name,
      slug: _ecosystem.slug,
      customDomain: _ecosystem.customDomain,
      url: ecosystemUrl(_ecosystem),
      listings: Array.isArray(_ecosystem?.listings) ? _ecosystem.listings.length : 0
    }))
  }

  return segmentData
}
