import AccessTimeIcon from '@material-ui/icons/AccessTime'
import { NO_DATA } from 'constants/cards'
import React from 'react'
import styled from 'react-emotion'

const IconDiv = styled('div')`
  margin: 0 auto;
  display: block;
`

export const CardEmptyState = ({ message = NO_DATA }) => (
  <div style={{ padding: '50px 20px', textAlign: 'center' }}>
    <IconDiv>
      <AccessTimeIcon />
    </IconDiv>
    {message}
  </div>
)

export default CardEmptyState
