import { CREATE_PARTNER } from '../../graphql'
import { partnersQuery } from 'api'
import { toastr } from 'react-redux-toastr'
import transformError from 'utils/transformError'
import uploadFile from 'utils/fileUpload'
import { useHistory } from 'react-router-dom'
import { useMutation } from 'react-apollo'

function useCreatePartnerAction({ accountId, ecosystemId }) {
  const history = useHistory()
  const [createPartnerMutation] = useMutation(CREATE_PARTNER, {
    update: (cache, { data: { createPartner: partner } }) => {
      let data
      try {
        data = cache.readQuery({
          query: partnersQuery,
          variables: {
            ecosystemId,
            accountId,
            limit: 500
          }
        })
      } catch (e) {
        console.log(e)
      }

      if (data) {
        cache.writeQuery({
          query: partnersQuery,
          variables: { ecosystemId, accountId, limit: 500 },
          data: { partners: [partner, ...data.partners] }
        })
      }

      toastr.success('Partner created')
      history.push(`/ecosystems/${ecosystemId}/partners/overview/all`)
    }
  })

  async function handleCreatePartner(
    { company, icon, listed, listings, website, twitter },
    { setErrors, setSubmitting }
  ) {
    try {
      setSubmitting(true)

      let variables = {
        accountId,
        company,
        ecosystemId,
        listed,
        listings,
        website,
        twitter
      }

      if (icon.length !== 0 && icon[0].preview) {
        let result = await uploadFile(icon[0], {
          params: {
            folder: `marketplaces/${ecosystemId}/partners`
          }
        })

        variables = {
          ...variables,
          icon: {
            url: result.url,
            contentType: `${result.resourceType}/${result.format}`
          }
        }
      }
      await createPartnerMutation({
        variables
      })
    } catch (error) {
      if (error.graphQLErrors) {
        setErrors(transformError(error.graphQLErrors))
      }
      toastr.error('Could not create partner')
    }
  }

  return [handleCreatePartner]
}

export { useCreatePartnerAction }
