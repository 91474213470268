import {
  createFooterItemMutation,
  createNavigationItemMutation,
  deleteEcosystemLogoMutation,
  deleteEcosystemMastheadBackgroundImageMutation,
  deleteEcosystemMastheadImageMutation,
  deleteFooterItemMutation,
  deleteNavigationItemMutation,
  updateFooterItemMutation,
  updateNavigationItemMutation,
  updateTemplateSettingsMutation
} from 'api/queries'
import { injectLocalizedValues, prepareVariables, TemplateSettings } from 'components'
import LoadingPlaceholder from 'components/placeholder/Loading'
import { NOT_UPDATED, UPDATED } from 'constants/settings'
import PropTypes from 'prop-types'
import React from 'react'
import { graphql } from 'react-apollo'
import { toastr } from 'react-redux-toastr'
import { compose, withHandlers } from 'recompose'
import {
  addFooterItemToStore,
  addNavigationItemToStore,
  deleteFooterItemFromStore,
  deleteImageFromStore,
  deleteNavigationItemFromStore,
  updateFooterItemInStore,
  updateNavigationItemInStore,
  updateTemplateSettingsInStore
} from 'store/ecosystem'
import uploadFile from 'utils/fileUpload'
import { ecosystemLocales, setRootValueFromTranslation } from 'utils/locales'
import transformError from 'utils/transformError'

class Container extends React.Component {
  render() {
    const {
      handleSubmit,
      ecosystem,
      activeAccount,
      handleNavigationItemCreate,
      handleNavigationItemUpdate,
      handleNavigationItemDelete,
      handleFooterItemCreate,
      handleFooterItemUpdate,
      handleFooterItemDelete,
      handleEcosystemMastheadBackgroundImageDelete,
      handleEcosystemMastheadImageDelete,
      handleEcosystemLogoDelete
    } = this.props

    if (!ecosystem) {
      return <LoadingPlaceholder />
    } else {
      let initialValues = {
        logo: ecosystem.logo ? [ecosystem.logo] : [],
        requestLink: ecosystem.requestLink || '',
        createLink: ecosystem.createLink || '',
        primaryColor: ecosystem.primaryColor || '',
        bodyColor: ecosystem.bodyColor || '#444',
        bodyLinkColor: ecosystem.bodyLinkColor || ecosystem.primaryColor,
        bodyBackgroundColor: ecosystem.bodyBackgroundColor || '#FFF',
        navigationLogoPostFix: ecosystem.navigationLogoPostFix || '',
        navigationMobileMenuType: ecosystem.navigationMobileMenuType || '',
        mastheadTitle: ecosystem.mastheadTitle || '',
        mastheadDescription: ecosystem.mastheadDescription || '',
        mastheadColor: ecosystem.mastheadColor || '',
        mastheadImage: ecosystem.mastheadImage ? [ecosystem.mastheadImage] : [],
        mastheadBackgroundImage: ecosystem.mastheadBackgroundImage
          ? [ecosystem.mastheadBackgroundImage]
          : [],
        mastheadBackgroundColor: ecosystem.mastheadBackgroundColor || '',
        // TODO: refactor selectInput to make sure that defaultValue works with
        // empty string value
        mastheadColumns: ecosystem.mastheadColumns || 3,
        homePageShowAllListings: ecosystem?.homePageShowAllListings ?? false,
        homePageCollectionCategoryCards: ecosystem?.homePageCollectionCategoryCards ?? false,
        ctaTitle: ecosystem.ctaTitle || '',
        ctaDescription: ecosystem.ctaDescription || '',
        ctaButtonLink: ecosystem.ctaButtonLink || '',
        ctaButtonLabel: ecosystem.ctaButtonLabel || '',
        ctaColor: ecosystem.ctaColor || '',
        ctaBackgroundColor: ecosystem.ctaBackgroundColor || '',
        ctaButtonColor: ecosystem.ctaButtonColor || '',
        ctaButtonBackgroundColor: ecosystem.ctaButtonBackgroundColor || '',
        ctaEnabled: ecosystem?.ctaEnabled ?? true,
        alternativesColor: ecosystem.alternativesColor || '',
        alternativesBackgroundColor: ecosystem.alternativesBackgroundColor || '',
        footerColor: ecosystem.footerColor || '',
        footerBackgroundColor: ecosystem.footerBackgroundColor || '',
        listingSidebarPosition: ecosystem.listingSidebarPosition || 'LEFT',
        menuPosition: ecosystem.menuPosition || 'LEFT',
        menuStyle: ecosystem.menuStyle || 'LIST',
        navigationBackgroundColor:
          ecosystem.navigationBackgroundColor || ecosystem.mastheadBackgroundColor,
        navigationColor: ecosystem.navigationColor || ecosystem.mastheadColor,
        navigationSticky: ecosystem?.navigationSticky ?? false,
        cardShadowEnabled: ecosystem?.cardShadowEnabled ?? false,
        cardBorderColor: ecosystem.cardBorderColor || '',
        cardBorderSize: ecosystem.cardBorderSize || '',
        cardBackgroundColor: ecosystem.cardBackgroundColor || '',
        cardColor: ecosystem.cardColor || '',
        cardBorderRadius: ecosystem.cardBorderRadius || '',
        cardShowCategory: ecosystem?.cardShowCategory ?? true,
        cardShowDescription: ecosystem?.cardShowDescription ?? false,
        cardDescriptionLines: ecosystem?.cardDescriptionLines || 1,
        cardShowAction: ecosystem?.cardShowAction ?? true,
        cardStyle: ecosystem.cardStyle || 'STYLE1',
        cardColumns: ecosystem?.cardColumns || 3,
        hideInstallButtons: ecosystem?.hideInstallButtons ?? false,
        zapsMenuTitle: ecosystem.zapsMenuTitle || '',
        categoriesCountBadge: ecosystem?.categoriesCountBadge ?? false,
        categoriesShowMaxItems: ecosystem.categoriesShowMaxItems || 6,
        collectionsTitle: ecosystem.collectionsTitle || '',
        collectionsCountBadge: ecosystem?.collectionsCountBadge ?? false,
        termsLink: ecosystem.termsLink,
        privacyLink: ecosystem.privacyLink,
        customHtmlNav: ecosystem.customHtmlNav || '',
        customHtmlFooter: ecosystem.customHtmlFooter || '',
        showRequestedListings: ecosystem?.showRequestedListings ?? true,
        customCssLink: ecosystem.customCssLink,
        customCss: ecosystem.customCss,
        customJavaScriptLink: ecosystem.customJavaScriptLink,
        locales: ecosystemLocales(ecosystem)
      }

      initialValues = injectLocalizedValues('ecosystemTemplateSettings', initialValues, ecosystem)

      return (
        <TemplateSettings
          ecosystem={ecosystem}
          handleNavigationItemCreate={handleNavigationItemCreate}
          handleNavigationItemUpdate={handleNavigationItemUpdate}
          handleNavigationItemDelete={handleNavigationItemDelete}
          handleFooterItemCreate={handleFooterItemCreate}
          handleFooterItemUpdate={handleFooterItemUpdate}
          handleFooterItemDelete={handleFooterItemDelete}
          handleEcosystemMastheadBackgroundImageDelete={
            handleEcosystemMastheadBackgroundImageDelete
          }
          handleEcosystemMastheadImageDelete={handleEcosystemMastheadImageDelete}
          handleEcosystemLogoDelete={handleEcosystemLogoDelete}
          handleSubmit={handleSubmit}
          initialValues={initialValues}
          plan={activeAccount.plan}
        />
      )
    }
  }
}

Container.propTypes = {
  activeAccount: PropTypes.object.isRequired,
  ecosystem: PropTypes.object.isRequired
}

const handlers = withHandlers({
  handleSubmit:
    ({ updateTemplateSettings, activeAccount, ecosystem }) =>
    async (props, { setSubmitting, setErrors }) => {
      const {
        alternativesBackgroundColor,
        alternativesColor,
        cardColumns,
        cardBorderColor,
        cardBorderSize,
        cardBackgroundColor,
        cardColor,
        cardShowAction,
        cardShowCategory,
        cardShowDescription,
        cardDescriptionLines,
        cardStyle,
        cardShadowEnabled,
        cardBorderRadius,
        createLink,
        ctaBackgroundColor,
        ctaButtonBackgroundColor,
        ctaButtonColor,
        ctaButtonLabel,
        ctaButtonLink,
        ctaColor,
        ctaDescription,
        ctaTitle,
        ctaEnabled,
        footerBackgroundColor,
        footerColor,
        logo,
        menuPosition,
        menuStyle,
        mastheadBackgroundImage,
        mastheadBackgroundColor,
        mastheadColor,
        mastheadColumns,
        mastheadDescription,
        mastheadImage,
        mastheadTitle,
        homePageShowAllListings,
        homePageCollectionCategoryCards,
        navigationLogoPostFix,
        navigationMobileMenuType,
        navigationBackgroundColor,
        navigationColor,
        navigationSticky,
        primaryColor,
        bodyColor,
        bodyLinkColor,
        bodyBackgroundColor,
        requestLink,
        zapsMenuTitle,
        categoriesCountBadge,
        categoriesShowMaxItems,
        collectionsTitle,
        collectionsCountBadge,
        privacyLink,
        termsLink,
        customHtmlNav,
        customHtmlFooter,
        showRequestedListings,
        customCssLink,
        customCss,
        customJavaScriptLink
      } = props

      const locales = ecosystemLocales(ecosystem)
      let variables = {
        accountId: activeAccount.id,
        id: ecosystem.id,
        name: ecosystem.name,
        slug: ecosystem.slug,
        alternativesBackgroundColor,
        alternativesColor,
        cardColumns,
        cardBorderColor,
        cardBorderSize,
        cardBackgroundColor,
        cardColor,
        cardShowAction,
        cardShowCategory,
        cardShowDescription,
        cardShadowEnabled,
        cardBorderRadius,
        cardStyle,
        createLink,
        ctaBackgroundColor,
        ctaButtonBackgroundColor,
        ctaButtonColor,
        ctaButtonLabel: setRootValueFromTranslation(
          locales,
          ecosystem,
          'ctaButtonLabel',
          ctaButtonLabel
        ),
        ctaButtonLink,
        ctaColor,
        ctaDescription: setRootValueFromTranslation(
          locales,
          ecosystem,
          'ctaDescription',
          ctaDescription
        ),
        ctaTitle: setRootValueFromTranslation(locales, ecosystem, 'ctaTitle', ctaTitle),
        ctaEnabled,
        footerBackgroundColor,
        footerColor,
        mastheadBackgroundColor,
        mastheadColor,
        mastheadDescription: setRootValueFromTranslation(
          locales,
          ecosystem,
          'mastheadDescription',
          mastheadDescription
        ),
        mastheadTitle: setRootValueFromTranslation(
          locales,
          ecosystem,
          'mastheadTitle',
          mastheadTitle
        ),
        mastheadColumns,
        menuPosition,
        menuStyle,
        homePageShowAllListings,
        homePageCollectionCategoryCards,
        navigationLogoPostFix,
        navigationMobileMenuType,
        navigationBackgroundColor,
        navigationColor,
        navigationSticky,
        primaryColor,
        bodyColor,
        bodyLinkColor,
        bodyBackgroundColor,
        requestLink,
        zapsMenuTitle: setRootValueFromTranslation(
          locales,
          ecosystem,
          'zapsMenuTitle',
          zapsMenuTitle
        ),
        categoriesCountBadge,
        collectionsTitle: setRootValueFromTranslation(
          locales,
          ecosystem,
          'collectionsTitle',
          collectionsTitle
        ),
        collectionsCountBadge,
        privacyLink,
        termsLink,
        customHtmlNav,
        customHtmlFooter,
        showRequestedListings,
        customCssLink,
        customCss,
        customJavaScriptLink,
        translations: prepareVariables(props, ecosystem, ecosystem.translations)
      }

      variables.cardDescriptionLines = cardDescriptionLines ? cardDescriptionLines : null
      variables.categoriesShowMaxItems = categoriesShowMaxItems ? categoriesShowMaxItems : null

      if (mastheadBackgroundImage.length !== 0 && mastheadBackgroundImage[0].preview) {
        let result = await uploadFile(mastheadBackgroundImage[0], {
          params: {
            folder: `ecosystems`
          }
        })

        variables = {
          ...variables,
          mastheadBackgroundImage: {
            url: result.url,
            contentType: `${result.resourceType}/${result.format}`
          }
        }
      }

      if (mastheadImage.length !== 0 && mastheadImage[0].preview) {
        let result = await uploadFile(mastheadImage[0], {
          params: {
            folder: `ecosystems`
          }
        })

        variables = {
          ...variables,
          mastheadImage: {
            url: result.url,
            contentType: `${result.resourceType}/${result.format}`
          }
        }
      }

      if (logo.length !== 0 && logo[0].preview) {
        let result = await uploadFile(logo[0], {
          params: {
            folder: `ecosystems`
          }
        })

        variables = {
          ...variables,
          logo: {
            url: result.url,
            contentType: `${result.resourceType}/${result.format}`
          }
        }
      }

      try {
        setSubmitting(true)

        await updateTemplateSettings({
          variables,
          update: (store, { data: { updateMarketplace } }) => {
            return updateTemplateSettingsInStore(store, {
              ecosystem: updateMarketplace,
              accountId: activeAccount.id
            })
          }
        })

        window.scrollTo(0, 0)
        toastr.success(UPDATED)
        setSubmitting(false)
      } catch (error) {
        toastr.error(NOT_UPDATED)
        if (error.graphQLErrors) {
          setErrors(transformError(error.graphQLErrors))
        }

        setSubmitting(false)
      }
    },
  handleNavigationItemCreate:
    ({
      createNavigationItem,
      activeAccount,
      ecosystem,
      match: {
        params: { ecosystemId }
      }
    }) =>
    async (props, formikProps, handleClose) => {
      try {
        const { url, label, type } = props

        const variables = {
          accountId: activeAccount.id,
          ecosystemId: ecosystemId,
          item: {
            label,
            url,
            type,
            translations: prepareVariables(props, ecosystem, [])
          }
        }

        await createNavigationItem({
          variables,
          update: (store, { data: { createNavigationItem } }) =>
            addNavigationItemToStore(
              store,
              {
                ecosystemId,
                accountId: activeAccount.id
              },
              createNavigationItem
            )
        })

        toastr.success('Item added')

        handleClose && handleClose()
      } catch (error) {
        toastr.error('Could not add item')
        throw new Error(error && error.message)
      }
    },
  handleNavigationItemUpdate:
    ({
      updateNavigationItem,
      activeAccount,
      ecosystem,
      match: {
        params: { ecosystemId }
      }
    }) =>
    async (props, formikProps, handleClose) => {
      try {
        const { id, label, url, type, sequence } = props

        const navigationItem = ecosystem.navigationItems.find((i) => i.id === id)

        let variables = {
          accountId: activeAccount.id,
          id,
          item: {
            label,
            url,
            type,
            sequence,
            translations: prepareVariables(props, ecosystem, navigationItem.translations)
          }
        }

        await updateNavigationItem({
          variables,
          update: (store, { data: { updateNavigationItem } }) => {
            updateNavigationItemInStore(
              store,
              {
                ecosystemId,
                accountId: activeAccount.id
              },
              updateNavigationItem
            )
          }
        })

        toastr.success('Item updated')

        handleClose && handleClose()
      } catch (error) {
        toastr.error('Could not update item')
        throw new Error(error && error.message)
      }
    },
  handleNavigationItemDelete:
    ({
      deleteNavigationItem,
      activeAccount,
      match: {
        params: { ecosystemId }
      }
    }) =>
    async (id, handleClose) => {
      try {
        const variables = {
          accountId: activeAccount.id,
          id
        }

        await deleteNavigationItem({
          variables,
          update: (store) =>
            deleteNavigationItemFromStore(
              store,
              {
                ecosystemId,
                accountId: activeAccount.id
              },
              id
            )
        })

        toastr.success('Link deleted')

        handleClose && handleClose()
      } catch (error) {
        toastr.error('Could not delete link')
        throw new Error(error && error.message)
      }
    },
  handleFooterItemCreate:
    ({
      createFooterItem,
      activeAccount,
      ecosystem,
      match: {
        params: { ecosystemId }
      }
    }) =>
    async (props, _, handleClose) => {
      const { url, label, type, alignment } = props

      try {
        const variables = {
          accountId: activeAccount.id,
          ecosystemId: ecosystemId,
          item: {
            label,
            url,
            type,
            alignment,
            translations: prepareVariables(props, ecosystem, [])
          }
        }

        await createFooterItem({
          variables,
          update: (store, { data: { createFooterItem } }) =>
            addFooterItemToStore(
              store,
              {
                ecosystemId,
                accountId: activeAccount.id
              },
              createFooterItem
            )
        })

        toastr.success('Item added')

        handleClose && handleClose()
      } catch (error) {
        toastr.error('Could not add item')
        throw new Error(error && error.message)
      }
    },
  handleFooterItemUpdate:
    ({
      updateFooterItem,
      activeAccount,
      ecosystem,
      match: {
        params: { ecosystemId }
      }
    }) =>
    async (props, formikProps, handleClose) => {
      try {
        const { id, label, url, type, alignment, sequence } = props
        const footerItem = ecosystem.footerItems.find((i) => i.id === id)

        let variables = {
          accountId: activeAccount.id,
          id,
          item: {
            label,
            url,
            type,
            alignment,
            sequence,
            translations: prepareVariables(props, ecosystem, footerItem.translations)
          }
        }

        await updateFooterItem({
          variables,
          update: (store, { data: { updateFooterItem } }) => {
            updateFooterItemInStore(
              store,
              {
                ecosystemId,
                accountId: activeAccount.id
              },
              updateFooterItem
            )
          }
        })

        toastr.success('Item updated')

        handleClose && handleClose()
      } catch (error) {
        toastr.error('Could not update item')
        throw new Error(error && error.message)
      }
    },
  handleFooterItemDelete:
    ({
      deleteFooterItem,
      activeAccount,
      match: {
        params: { ecosystemId }
      }
    }) =>
    async (id, handleClose) => {
      try {
        const variables = {
          accountId: activeAccount.id,
          id
        }

        await deleteFooterItem({
          variables,
          update: (store) =>
            deleteFooterItemFromStore(
              store,
              {
                ecosystemId,
                accountId: activeAccount.id
              },
              id
            )
        })

        toastr.success('Link deleted')

        handleClose && handleClose()
      } catch (error) {
        toastr.error('Could not delete link')
        throw new Error(error && error.message)
      }
    },
  handleEcosystemMastheadBackgroundImageDelete:
    ({ deleteEcosystemMastheadBackgroundImage, activeAccount }) =>
    async (id) => {
      try {
        const variables = {
          accountId: activeAccount.id,
          ecosystemId: id
        }
        await deleteEcosystemMastheadBackgroundImage({
          variables,
          update: (store) =>
            deleteImageFromStore(store, {
              ecosystemId: id,
              accountId: activeAccount.id,
              propName: 'mastheadBackgroundImage'
            })
        })

        toastr.success('Masthead Background Image deleted')
      } catch (error) {
        toastr.error('Could not delete Masthead Background Image')
        throw new Error(error && error.message)
      }
    },
  handleEcosystemMastheadImageDelete:
    ({ deleteEcosystemMastheadImage, activeAccount }) =>
    async (id) => {
      try {
        const variables = {
          accountId: activeAccount.id,
          ecosystemId: id
        }
        await deleteEcosystemMastheadImage({
          variables,
          update: (store) =>
            deleteImageFromStore(store, {
              ecosystemId: id,
              accountId: activeAccount.id,
              propName: 'mastheadImage'
            })
        })

        toastr.success('Masthead Image deleted')
      } catch (error) {
        toastr.error('Could not delete Masthead Image')
        throw new Error(error && error.message)
      }
    },
  handleEcosystemLogoDelete:
    ({ deleteEcosystemLogo, activeAccount }) =>
    async (id) => {
      try {
        const variables = {
          accountId: activeAccount.id,
          ecosystemId: id
        }
        await deleteEcosystemLogo({
          variables,
          update: (store) =>
            deleteImageFromStore(store, {
              ecosystemId: id,
              accountId: activeAccount.id,
              propName: 'logo'
            })
        })

        toastr.success('Logo deleted')
      } catch (error) {
        toastr.error('Could not delete logo')
        throw new Error(error && error.message)
      }
    }
})

const SettingsContainer = compose(
  graphql(updateTemplateSettingsMutation, {
    name: 'updateTemplateSettings'
  }),
  graphql(createNavigationItemMutation, { name: 'createNavigationItem' }),
  graphql(updateNavigationItemMutation, { name: 'updateNavigationItem' }),
  graphql(deleteNavigationItemMutation, { name: 'deleteNavigationItem' }),
  graphql(createFooterItemMutation, { name: 'createFooterItem' }),
  graphql(updateFooterItemMutation, { name: 'updateFooterItem' }),
  graphql(deleteFooterItemMutation, { name: 'deleteFooterItem' }),
  graphql(deleteEcosystemMastheadBackgroundImageMutation, {
    name: 'deleteEcosystemMastheadBackgroundImage'
  }),
  graphql(deleteEcosystemMastheadImageMutation, {
    name: 'deleteEcosystemMastheadImage'
  }),
  graphql(deleteEcosystemLogoMutation, {
    name: 'deleteEcosystemLogo'
  }),
  handlers
)(Container)

export default SettingsContainer
