export const variables = {
  identityProvider: {
    clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
    domain: process.env.REACT_APP_AUTH0_CUSTOM_DOMAIN,
    audience: process.env.REACT_APP_AUTH0_AUDIENCE
  },
  graphql: {
    uri: process.env.REACT_APP_GRAPHQL_URL
  },
  cloudinary: {
    name: process.env.REACT_APP_CLOUDINARY_CLOUD_NAME,
    preset: process.env.REACT_APP_CLOUDINARY_PRESET
  },
  node: {
    environment: process.env.NODE_ENV
  },
  publicUrl: process.env.PUBLIC_URL
}

export default variables
