import React, { Fragment } from 'react'

import classNames from 'classnames'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    padding: theme.spacing(4),
    position: 'relative'
  },
  noPadding: {
    padding: 0
  }
}))

const Content = ({ children, header, noPadding }) => {
  const classes = useStyles()

  return (
    <Fragment>
      {header}
      <div
        className={classNames(classes.container, {
          [classes.noPadding]: noPadding
        })}
      >
        {children}
      </div>
    </Fragment>
  )
}

Content.defaultProps = {
  noPadding: false
}

export default Content
