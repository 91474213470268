import { fileFields, translationFields } from 'api/queries'
import { useLocation, useRouteMatch } from 'react-router-dom'

import { ErrorFallback } from 'components'
import LoadingPlaceholder from 'components/placeholder/Loading'
import { EcosystemContext } from 'context'
import gql from 'graphql-tag'
import PropTypes from 'prop-types'
import React from 'react'
import { Query } from 'react-apollo'
import { getItem, setItem } from 'utils/localstorage'
import EcosystemRoutes from './EcosystemRoutes'

export const marketplaceQuery = gql`
  query marketplace($id: ID!, $accountId: ID!) {
    marketplace(id: $id, accountId: $accountId) {
      id
      unifyApplicationId
      alternativesBackgroundColor
      alternativesColor
      attribution
      blendrEnabled
      automateEnabled
      cardBorderColor
      cardBorderSize
      cardBackgroundColor
      cardColor
      cardColumns
      cardShowAction
      cardShowCategory
      cardShowDescription
      cardDescriptionLines
      cardStyle
      cardBorderRadius
      cardShadowEnabled
      categoriesCountBadge
      categoriesShowMaxItems
      collectionsCountBadge
      collectionsTitle
      privacyLink
      termsLink
      customCssLink
      customCss
      customJavaScriptLink
      customHtmlNav
      customHtmlFooter
      showRequestedListings
      zapsPageEnabled
      zapsMenuTitle
      combideskEnabled
      trayIoEnabled
      createLink
      ctaBackgroundColor
      ctaButtonBackgroundColor
      ctaButtonColor
      ctaButtonLabel
      ctaButtonLink
      ctaColor
      ctaDescription
      ctaTitle
      ctaEnabled
      customDomain
      icon {
        ...fileFields
      }
      favicon {
        ...fileFields
      }
      logo {
        ...fileFields
      }
      footerBackgroundColor
      footerColor
      footerItems {
        id
        label
        url
        type
        alignment
        sequence
        translations {
          ...translationFields
        }
      }
      googleSiteVerificationId
      integrationAlbacrossId
      integrationDriftId
      integrationGoogleAnalyticsId
      integrationGoogleTagManagerId
      integrationHeapId
      integrationHubspotPortalId
      integrationIntercomAppId
      integrationCrispId
      integrationIubendaCookiePolicyId
      integrationIubendaSiteId
      integrationJournyIoDomain
      integrationJournyIoId
      integrationMetomicId
      integrationOnetrustId
      integrationLivechatId
      integrationSegmentId
      integrationZapierId
      integrationZapierBetaLink
      listingApprovalEnabled
      leadCaptureFormEnabled
      listings {
        id
        externalId
        slug
      }
      listingNaming
      listingDescriptionTextTemplate
      listingFeaturesTextTemplate
      listingPricingTextTemplate
      listingDescriptionTitle
      listingFeaturesTitle
      listingPricingTitle
      listingNativeIntegrationLink
      listingInstallButtonLabel
      listingNamePostfix
      listingSidebarPosition
      menuPosition
      menuStyle
      mastheadBackground
      mastheadBackgroundColor
      mastheadBackgroundImage {
        ...fileFields
      }
      mastheadColumns
      mastheadImage {
        ...fileFields
      }
      locales {
        id
        code
        default
      }
      mastheadColor
      mastheadDescription
      mastheadTitle
      homePageShowAllListings
      homePageCollectionCategoryCards
      bodyBackgroundColor
      bodyColor
      bodyButtonBackgroundColor
      bodyButtonColor
      bodyLinkColor
      metaTagDescription
      metaTagDescriptionCategoryPage
      metaTagDescriptionCollectionPage
      metaTagDescriptionListingPage
      metaTagKeywords
      metaTagTitle
      metaTagTitlePostfix
      microsoftFlowEnabled
      name
      navigationItems {
        id
        label
        url
        type
        sequence
        translations {
          ...translationFields
        }
      }
      navigationLogoPostFix
      navigationMobileMenuType
      navigationBackgroundColor
      navigationColor
      navigationSticky
      piesyncEnabled
      primaryColor
      requestLink
      segmentEnabled
      slug
      socialImage {
        ...fileFields
      }
      utmCampaign
      website
      integromatEnabled
      zapierEnabled
      zapsPageEnabled
      zapsMenuTitle
      customCssLink
      customCss
      customJavaScriptLink
      customHtmlNav
      customHtmlFooter
      showRequestedListings
      about
      shadowPagesEnabled
      shadowPageDescription
      installationRequestFlowEnabled
      leadTelephoneFieldEnabled
      leadTelephoneFieldRequired
      leadFirstNameFieldEnabled
      leadFirstNameFieldRequired
      leadLastNameFieldEnabled
      leadLastNameFieldRequired
      leadWorkEmailValidation
      leadIntegrationEnabled
      translations {
        ...translationFields
      }
      multiCategories: categories {
        id
        name
        slug
      }
      maxListings
    }
  }
  ${fileFields}
  ${translationFields}
`

function EcosystemRoutesContainer({ user, activeAccount }) {
  const match = useRouteMatch()
  const { search } = useLocation()
  const activeEcosystemId = match.params.ecosystemId

  setItem('activeEcosystemId', activeEcosystemId)

  const params = new URLSearchParams(search)
  const accountId = params.get('account_id') ? params.get('account_id') : activeAccount?.id
  if (params.get('account_id')) {
    setItem('activeAccountId', params.get('account_id'))
  }

  return (
    <Query
      query={marketplaceQuery}
      variables={{ accountId, id: activeEcosystemId }}
      skip={!getItem('accessToken')}
    >
      {({ loading, error, data }) => {
        if (loading) return <LoadingPlaceholder />
        if (error) return <ErrorFallback error={error} />

        const { marketplace } = data

        return (
          <EcosystemContext.Provider value={{ ecosystem: marketplace }}>
            <EcosystemRoutes
              marketplace={marketplace}
              activeAccount={activeAccount}
              user={user}
              match={match}
            />
          </EcosystemContext.Provider>
        )
      }}
    </Query>
  )
}

EcosystemRoutesContainer.propTypes = {
  activeAccount: PropTypes.object.isRequired
}

export default EcosystemRoutesContainer
