import gql from 'graphql-tag'

export const partnerQuery = gql`
  query partnerQuery($accountId: ID!, $ecosystemId: ID!, $id: ID!) {
    partner(input: { accountId: $accountId, ecosystemId: $ecosystemId, id: $id }) {
      company
      contacts {
        id
        name
        firstName
        lastName
        email
        role
        invitation {
          status
        }
      }
      icon {
        id
        url
        contentType
      }
      id
      listed
      listings {
        id
        name
        state
        logo {
          id
          url
          contentType
        }
      }
      website
      twitter
    }
  }
`

export const partnersQuery = gql`
  query partnersQuery($accountId: ID!, $ecosystemId: ID!, $offset: Int = 0, $limit: Int) {
    partners(
      input: { accountId: $accountId, ecosystemId: $ecosystemId, offset: $offset, limit: $limit }
    ) {
      company
      contacts {
        id
        name
        firstName
        lastName
        email
        role
        invitation {
          status
        }
      }
      icon {
        id
        url
        contentType
      }
      id
      listed
      listings {
        id
        name
        state
        logo {
          id
          url
          contentType
        }
      }
      website
      twitter
    }
  }
`
