import { Fieldset, ToggleField } from 'components'

import { Grid } from '@material-ui/core'
import PropTypes from 'prop-types'
import React from 'react'

export const PartnerSettings = ({ marketplace, handleSubmit }) => {
  return (
    <form>
      <Fieldset title="Partner settings">
        <Grid container>
          <Grid item sm={6} lg={6}>
            <p style={{ fontSize: '16px', fontWeight: 500, color: '#0A0E42' }}>Listing approval</p>
            <p style={{ fontSize: '14px', color: '#686b89' }}>
              By enabling the partner approval flow, partners can submit listing changes that will
              first need to be reviewed and approved.
            </p>
            <ToggleField
              isActive={marketplace.listingApprovalEnabled}
              label={'Require listing approval'}
              onToggle={(e) => {
                handleSubmit({
                  listingApprovalEnabled: e.target.checked
                })
              }}
            />
          </Grid>
        </Grid>
      </Fieldset>
    </form>
  )
}
PartnerSettings.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object
}

export default PartnerSettings
