import { publishListingMutation, unpublishListingMutation } from 'api/queries'
import { ErrorFallback, ListingLayout, LoadingPlaceholder } from 'components'
import React from 'react'
import { graphql, useQuery } from 'react-apollo'

import { listingCommentsQuery } from 'api'
import { listingOverviewQuery } from 'pages/private/ecosystem/listings/listing/graphql'
import { toastr } from 'react-redux-toastr'
import { compose } from 'recompose'
import getLocalizedValue from 'utils/getLocalizedValue'

// TODO move to pages folder

const ListingContainer = ({
  activeAccount,
  ecosystem,
  user,
  match,
  publishListing,
  unpublishListing
}) => {
  const { params } = match
  const { listingId } = params
  const { loading, error, data } = useQuery(listingOverviewQuery, {
    variables: {
      accountId: activeAccount.id,
      listingId
    },
    fetchPolicy: 'network-only'
  })

  const { loading: loadingComments, data: listingCommentsData } = useQuery(listingCommentsQuery, {
    variables: {
      accountId: activeAccount.id,
      listingId
    }
  })

  if (loading || loadingComments) {
    return <LoadingPlaceholder />
  }

  if (error) {
    return <ErrorFallback error={error} />
  }

  const { listing } = data
  const { listingComments } = listingCommentsData

  const handleTogglePublishListing = async () => {
    try {
      const variables = {
        accountId: activeAccount.id,
        id: listingId
      }
      let notificationMessage = `${getLocalizedValue(listing, 'name', ecosystem.locales)} has been`

      if (listing.published) {
        notificationMessage += ' unpublished'
        await unpublishListing({ variables })
      } else {
        notificationMessage += ' published'
        await publishListing({ variables })
      }

      toastr.success(notificationMessage)
    } catch (error) {
      toastr.error(
        `${getLocalizedValue(
          listing,
          'name',
          ecosystem.locales
        )} could not be published/unpublished `
      )
      throw new Error(error.message)
    }
  }

  return (
    <ListingLayout
      activeAccount={activeAccount}
      listing={listing}
      comments={listingComments}
      marketplace={ecosystem}
      user={user}
      handleTogglePublishListing={handleTogglePublishListing}
    />
  )
}

const ListingContainerWithHandlers = compose(
  graphql(publishListingMutation, { name: 'publishListing' }),
  graphql(unpublishListingMutation, { name: 'unpublishListing' })
)(ListingContainer)

export default ListingContainerWithHandlers
