import gql from 'graphql-tag'

export const categoriesPreviewQuery = gql`
  query categoriesPreviewQuery(
    $ecosystemId: ID!
    $offset: Int = 0
    $limit: Int = 20
    $listingLimit: Int = 6
  ) {
    categoriesPreview(ecosystemId: $ecosystemId, offset: $offset, limit: $limit) {
      id
      name
      slug
      count
      description
      translations {
        locale
        key
        value
      }
      listings(limit: $listingLimit) {
        id
        name
        slug
        description
        tagLine
        state
        logo {
          id
          url
        }
        links {
          id
          name
          type
          url
          sequence
          translations {
            locale
            key
            value
          }
        }
        zapierId
        piesyncId
        blendrId
        microsoftFlowId
        combideskId
        segmentId
        integromatId
        automateId
        trayIoId
        nativeIntegration
        thirdPartyIntegration
        nativeIntegrationLink
        thirdPartyIntegrationLink
        detailPageDisabled
        cardBackgroundColor
        cardBackgroundImage {
          id
          url
        }
        translations {
          locale
          key
          value
        }
        sticky
        upcoming
      }
    }
  }
`
