import React, { useMemo } from 'react'

import PropTypes from 'prop-types'
import { Table } from 'components'
import styled from 'react-emotion'
import { useHistory } from 'react-router-dom'

function normalizeData(listings, ecosystemId) {
  return listings.map((l) => {
    return {
      id: l.id,
      name: l.name,
      icon: l.logo && l.logo.url,
      state: l.state,
      link: `/ecosystems/${ecosystemId}/listings/${l.id}/overview`
    }
  })
}

const State = styled('div')`
  font-size: 12px;
  font-weight: 700;
  text-align: center;
  color: #854d0e;
  color: ${(props) => (props.isApproved ? '#15803d' : '#854d0e')};
  padding: 3px 0px;
  border-radius: 20px;
  margin: 0;
  background-color: ${(props) => (props.isApproved ? '#dcfce7' : '#fef9c3')};
  width: 120px;
`

function ListingsList({ listings, ecosystem }) {
  const history = useHistory()
  const normalizedData = useMemo(
    () => normalizeData(listings, ecosystem.id),
    [listings, ecosystem.id]
  )
  let columns = [
    {
      Header: '',
      accessor: 'icon',
      Cell: ({ cell, row }) => {
        return (
          <div
            className="border"
            onClick={() => {
              history.push(row.original.link)
            }}
          >
            <img src={cell.value} alt="" />
          </div>
        )
      },
      cursor: 'pointer',
      width: 10,
      padding: 0
    },
    {
      id: 'name',
      accessor: 'name',
      Header: 'Name',
      width: 1000
    }
  ]

  if (ecosystem.listingApprovalEnabled) {
    columns = [
      ...columns,
      {
        id: 'state',
        accessor: 'state',
        Header: 'State',
        width: 1200,
        Cell: ({ cell }) => {
          return (
            <State isApproved={cell.value === 'APPROVED'}>
              {cell.value === 'APPROVED' ? 'Approved' : 'Pending Review'}
            </State>
          )
        }
      }
    ]
  }
  return <Table columns={columns} data={normalizedData} clickableRow />
}

ListingsList.propTypes = {
  listings: PropTypes.array.isRequired
}

export default ListingsList
