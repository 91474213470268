import { addListingToCollectionMutation, removeListingFromCollectionMutation } from 'api/queries'
import { CollectionOverview as Overview } from 'components'
import some from 'lodash/some'
import PropTypes from 'prop-types'
import React from 'react'
import { graphql } from 'react-apollo'
import { toastr } from 'react-redux-toastr'
import { compose, withHandlers } from 'recompose'

class Container extends React.Component {
  render() {
    return (
      <Overview
        collection={this.props.collection}
        ecosystem={this.props.ecosystem}
        listings={this.props.listings}
        handleListingClick={this.props.handleListingClick}
      />
    )
  }
}

Container.propTypes = {
  activeAccount: PropTypes.object.isRequired,
  collection: PropTypes.object.isRequired,
  listings: PropTypes.array,
  handleListingClick: PropTypes.func.isRequired
}

const handleListingClick = withHandlers({
  handleListingClick:
    ({ addListingToCollection, removeListingFromCollection, activeAccount, collection }) =>
    async (id) => {
      try {
        let variables = {
          accountId: activeAccount.id,
          id: collection.id,
          listingId: id
        }

        if (some(collection.listings, ['id', id])) {
          await removeListingFromCollection({ variables })
        } else {
          await addListingToCollection({ variables })
        }
      } catch (error) {
        toastr.error('could not add/remove listing to/from collection')
        throw new Error(error.message)
      }
    }
})

const OverviewContainer = compose(
  graphql(addListingToCollectionMutation, {
    name: 'addListingToCollection'
  }),
  graphql(removeListingFromCollectionMutation, {
    name: 'removeListingFromCollection'
  }),
  handleListingClick
)(Container)

export default OverviewContainer
