import PropTypes from 'prop-types'
import React from 'react'
import TemplateForm from './forms/Template'

const Overview = ({
  handleSubmit,
  initialValues,
  plan,
  handleNavigationItemCreate,
  handleNavigationItemUpdate,
  handleNavigationItemDelete,
  handleFooterItemCreate,
  handleFooterItemUpdate,
  handleFooterItemDelete,
  handleEcosystemMastheadBackgroundImageDelete,
  handleEcosystemMastheadImageDelete,
  handleEcosystemLogoDelete,
  ecosystem
}) => (
  <TemplateForm
    handleSubmit={handleSubmit}
    initialValues={initialValues}
    ecosystem={ecosystem}
    handleNavigationItemCreate={handleNavigationItemCreate}
    handleNavigationItemUpdate={handleNavigationItemUpdate}
    handleNavigationItemDelete={handleNavigationItemDelete}
    handleFooterItemCreate={handleFooterItemCreate}
    handleFooterItemUpdate={handleFooterItemUpdate}
    handleFooterItemDelete={handleFooterItemDelete}
    handleEcosystemMastheadBackgroundImageDelete={() =>
      handleEcosystemMastheadBackgroundImageDelete(ecosystem.id)
    }
    handleEcosystemMastheadImageDelete={() => handleEcosystemMastheadImageDelete(ecosystem.id)}
    handleEcosystemLogoDelete={() => handleEcosystemLogoDelete(ecosystem.id)}
    plan={plan}
  />
)

Overview.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired
}

export default Overview
