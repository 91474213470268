import { ContentContainer } from 'components'
import Header from 'components/ecosystem/leads/LeadsHeader'
import PrivateRoute from 'components/PrivateRoute'
import { LeadsContainer } from 'pages'
import React, { useState } from 'react'
import { Redirect, Switch, useRouteMatch } from 'react-router-dom'

const LeadsRoutes = ({ ecosystem, activeAccount }) => {
  const [activePath, setActivePath] = useState('')
  const match = useRouteMatch()

  return (
    <ContentContainer
      header={
        <Header
          leadCaptureFormEnabled={ecosystem.leadCaptureFormEnabled}
          match={match}
          ecosystem={ecosystem}
          activePath={activePath}
        />
      }
    >
      <Switch>
        <Redirect exact from={`${match.path}/`} to={`${match.path}/request-form`} />
        <PrivateRoute
          exact
          path={`${match.path}/request-form`}
          component={({ match }) => (
            <LeadsContainer
              ecosystem={ecosystem}
              activeAccount={activeAccount}
              match={match}
              setActivePath={setActivePath}
            />
          )}
          ecosystem={ecosystem}
        />
        {ecosystem.leadCaptureFormEnabled && (
          <PrivateRoute
            exact
            path={`${match.path}/cta-form`}
            component={({ match }) => (
              <LeadsContainer ecosystem={ecosystem} activeAccount={activeAccount} match={match} />
            )}
            ecosystem={ecosystem}
            activeAccount={activeAccount}
          />
        )}
        <Redirect to={`${match.path}/integration-requests`} />
      </Switch>
    </ContentContainer>
  )
}

export default LeadsRoutes
