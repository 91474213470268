import React from 'react'
import { Link, useRouteMatch } from 'react-router-dom'
import { Tabs, Tab } from 'components'

function PartnerNavigation() {
  const { url } = useRouteMatch()

  return (
    <Tabs
      selectors={[
        {
          tab: 0,
          path: `${url}/overview`,
          exact: true
        },
        {
          tab: 1,
          path: `${url}/settings`,
          exact: true
        }
      ]}
    >
      <Tab label="Overview" component={Link} to={`${url}/overview`} />
      <Tab label="Settings" component={Link} to={`${url}/settings`} />
    </Tabs>
  )
}

export default PartnerNavigation
