import ListingsForm from './forms/Listings'
import PropTypes from 'prop-types'
import React from 'react'

const Overview = ({ handleSubmit, initialValues, ecosystem }) => (
  <ListingsForm handleSubmit={handleSubmit} initialValues={initialValues} ecosystem={ecosystem} />
)

Overview.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired
}

export default Overview
