import React from 'react'
import { Query, Mutation } from 'react-apollo'
import { adopt } from 'react-adopt'
import { activateInvitationMutation, checkInvitationQuery } from 'api/queries'

const activateInvitation = ({ render }) => (
  <Mutation mutation={activateInvitationMutation}>
    {(mutation, result) => render({ mutation, result })}
  </Mutation>
)

const checkInvitation = ({ render, challenge }) => (
  <Query query={checkInvitationQuery} variables={{ challenge }}>
    {render}
  </Query>
)

export const ActivateContainer = adopt({
  checkInvitation,
  activateInvitation
})

export default ActivateContainer
