import React from 'react'
import Select from 'react-select'
import get from 'lodash/get'
import memoize from 'memoize-one'

export default class MultiSelect extends React.Component {
  filterOptions = (options, values) => options.filter((o) => values.includes(o.value))

  getSelectedOptions = memoize((options, values) => {
    return this.filterOptions(options, values)
  })

  handleChange = (items) => {
    const fieldName = this.props.field
    const vals = items.map((obj) => obj.value)
    this.props.formikProps.setFieldValue(fieldName, items ? vals : '')
  }

  render() {
    const {
      description,
      disabled,
      field,
      formikProps,
      label,
      placeholder: pc,
      placeholderNoPossibleOptions
    } = this.props

    const { values, touched, errors } = formikProps

    const options = this.props.options || []

    let placeholder = pc || label
    if (!options || options.length === 0) {
      placeholder = placeholderNoPossibleOptions
    }

    const val = get(values, field)
    const selectedOptions = this.getSelectedOptions(options, val)

    return (
      <div className="form-element">
        <label htmlFor={field}>{label}</label>
        {description && <p>{description}</p>}
        <Select
          id={field}
          className={this.props.className}
          isMulti={true}
          isClearable={true}
          value={selectedOptions}
          placeholder={placeholder}
          options={options}
          disabled={disabled}
          onChange={this.handleChange}
        />
        {!!errors[field] && touched[field] && (
          <div style={{ color: 'red', marginTop: '.5rem' }}>{errors[field]}</div>
        )}
      </div>
    )
  }
}
