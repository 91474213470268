import {
  ColorPicker,
  Fieldset,
  FileUploadInput,
  generateSlugValue,
  MarketplaceBaseForm,
  TextInput
} from 'components'
import React from 'react'

const NameField = ({ formikProps }) => (
  <TextInput formikProps={formikProps} label="Name" field="name" required localized="true" />
)

const ConnectedSlugField = ({ formikProps, prefix }) => (
  <TextInput
    formikProps={formikProps}
    label="Slug (autogenerated)"
    field="slug"
    value={(formikProps.values['slug'] = generateSlugValue(formikProps, 'name'))}
    prefix={prefix}
  />
)

const LogoField = ({ formikProps, handleDelete }) => (
  <FileUploadInput
    formikProps={formikProps}
    label="Logo"
    field="logo"
    description="Square icon. Ideal dimensions 400x400px."
    multiple={false}
    handleDelete={handleDelete}
  />
)

const DescriptionField = ({ formikProps }) => (
  <TextInput formikProps={formikProps} label="Description" field="description" localized="true" />
)

const SequenceField = ({ formikProps }) => (
  <TextInput
    formikProps={formikProps}
    label="Sequence"
    field="sequence"
    type="number"
    description="Defines the sequence of the collections on the homepage. (1 === first)"
  />
)

const StyleFieldset = ({ formikProps }) => {
  const { values } = formikProps

  return (
    <Fieldset title="Card style">
      <MarketplaceBaseForm.CardStyleField formikProps={formikProps} />
      <MarketplaceBaseForm.CardColumnsField formikProps={formikProps} />
      {['STYLE7', 'STYLE8'].includes(values?.cardStyle?.value) && (
        <React.Fragment>
          <ColorPicker
            formikProps={formikProps}
            label="Card background color"
            field="cardBackgroundColor"
          />
          <FileUploadInput
            formikProps={formikProps}
            label="Card background image"
            field="cardBackgroundImage"
            description="Square icon. Ideal dimensions 400x400px."
            multiple={false}
          />
        </React.Fragment>
      )}
    </Fieldset>
  )
}

export { ConnectedSlugField, NameField, StyleFieldset, DescriptionField, SequenceField, LogoField }
