import React from 'react'
import { Card } from 'components'

const Fieldset = ({ title, children, style, id, button }) => (
  <Card title={title} style={style} id={id} button={button}>
    <fieldset>{children}</fieldset>
  </Card>
)

export default Fieldset
