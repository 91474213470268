import CloseIcon from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'
import InputBase from '@material-ui/core/InputBase'
import PropTypes from 'prop-types'
import React from 'react'
import SearchIcon from '@material-ui/icons/Search'
import Tooltip from '@material-ui/core/Tooltip'
import classNames from 'classnames'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  search: {
    position: 'relative',
    backgroundColor: 'white',
    boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.05)',
    border: '1px solid',
    borderColor: theme.palette.ui[250],
    borderRadius: '6px'
  },
  searchIcon: {
    position: 'absolute',
    top: 0,
    left: theme.spacing(1.25),
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.ui[400]
  },
  inputRoot: {
    width: '100%',
    color: theme.palette.ui[400],
    fontSize: '16px',
    fontFamily: theme.typography.fontFamily
  },
  inputInline: {
    height: 'auto',
    marginLeft: theme.spacing(6),
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(4),
    paddingBottom: theme.spacing(0.75),

    borderBottom: '1px solid var(--color-lightest)',
    transition: theme.transitions.create('width'),
    '&:focus': {
      width: '200px'
    }
  },
  inputBlock: {
    width: '100%',
    padding: theme.spacing(2.4, 2.4, 2.4, 4.8)
  },
  close: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0
  },
  closeBlock: {
    right: theme.spacing(2)
  }
}))

const SearchInput = ({ inline, value, handleChange, placeholder }) => {
  const classes = useStyles()
  const clearValue = () => {
    handleChange('')
  }

  return (
    <div className={classes.search}>
      <SearchIcon className={classes.searchIcon} />
      <InputBase
        placeholder={placeholder}
        classes={{
          root: classes.inputRoot,
          input: inline ? classes.inputInline : classes.inputBlock
        }}
        inputProps={{ 'aria-label': 'search' }}
        value={value || ''}
        onChange={(e) => {
          handleChange(e.target.value)
        }}
      />
      {value?.length ? (
        <div
          className={classNames(classes.close, {
            [classes.closeBlock]: !inline
          })}
        >
          <Tooltip title="Clear search value">
            <IconButton size="small" aria-label="clear search value" onClick={() => clearValue()}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </div>
      ) : (
        ''
      )}
    </div>
  )
}

SearchInput.defaultProps = {
  inline: false,
  placeholder: 'Search..'
}

SearchInput.propTypes = {
  inline: PropTypes.bool,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  handleChange: PropTypes.func.isRequired
}

export default SearchInput
