import { FileUploadInput, generateSlugValue, TextInput } from 'components'
import React from 'react'
import * as yup from 'yup'

const baseValidationSchema = yup.object().shape({
  slug: yup
    .string()
    .matches(
      /^[a-z0-9]+(?:-[a-z0-9]+)*$/,
      'Must be alphanumeric (A-Z, 0-9) with dashes between words.'
    )
    .required('Slug is a required field'),
  description: yup.string()
})

const NameField = ({ formikProps }) => (
  <TextInput formikProps={formikProps} label="Name" field="name" required localized="true" />
)

const ConnectedSlugField = ({ formikProps, prefix }) => (
  <TextInput
    formikProps={formikProps}
    label="Slug (autogenerated)"
    value={(formikProps.values['slug'] = generateSlugValue(formikProps, 'name'))}
    field="slug"
    prefix={prefix}
  />
)

const SlugField = ({ formikProps, prefix }) => (
  <TextInput formikProps={formikProps} label="Slug" field="slug" prefix={prefix} required />
)

const LogoField = ({ formikProps, handleDelete }) => (
  <FileUploadInput
    formikProps={formikProps}
    label="Logo"
    field="logo"
    description="Square icon. Ideal dimensions 400x400px."
    multiple={false}
    handleDelete={handleDelete}
  />
)

const DescriptionField = ({ formikProps }) => (
  <TextInput formikProps={formikProps} label="Description" field="description" localized="true" />
)

export {
  NameField,
  ConnectedSlugField,
  SlugField,
  DescriptionField,
  baseValidationSchema,
  LogoField
}
