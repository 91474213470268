import { Card, ContentContainer, PrimaryButton } from 'components'

import PropTypes from 'prop-types'
import React from 'react'

function Services({ handleComplete, Content, Filter }) {
  return (
    <ContentContainer>
      <Card
        title={'Import listings'}
        left={Filter}
        button={<PrimaryButton onClick={handleComplete}>Next</PrimaryButton>}
      >
        {Content}
      </Card>
    </ContentContainer>
  )
}

Services.propTypes = {
  cloudService: PropTypes.object,
  Content: PropTypes.element.isRequired,
  ecosystem: PropTypes.object.isRequired,
  Filter: PropTypes.element,
  handleComplete: PropTypes.func.isRequired
}

export default Services
