import Avatar from '@material-ui/core/Avatar'
import Box from '@material-ui/core/Box'
import Chip from '@material-ui/core/Chip'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import Paper from '@material-ui/core/Paper'
import { withStyles } from '@material-ui/core/styles'
import TableCell from '@material-ui/core/TableCell'
import Toolbar from '@material-ui/core/Toolbar'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import InfoIcon from '@material-ui/icons/InfoOutlined'
import LaunchIcon from '@material-ui/icons/Launch'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import Alert from '@material-ui/lab/Alert'
import { Loader } from 'components'
import SearchInput from 'components/common/form/SearchInput'
import React, { Fragment } from 'react'
import { AutoSizer, Column, InfiniteLoader, Table, WindowScroller } from 'react-virtualized'
import ecosystemUrl from 'utils/ecosystemUrl'

const columns = [
  {
    id: 'icon',
    numeric: false,
    label: '',
    width: 50
  },
  {
    id: 'name',
    numeric: false,
    label: 'Name',
    width: null
  },
  {
    id: 'categories',
    numeric: false,
    label: 'Categories',
    width: null
  },
  {
    id: 'actions',
    numeric: true,
    label: 'Actions',
    width: 200
  }
]

const styles = (theme) => ({
  outlinedInfo: {
    border: `1px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main
  },
  icon: {
    color: theme.palette.primary.main
  },
  title: {
    flex: 1,
    fontSize: '16px',
    fontWeight: 500,
    color: '#001b2f'
  },
  poweredBy: {
    display: 'flex',
    minWidth: '200px',
    alignItems: 'center',
    marginRight: theme.spacing(1),
    color: 'var(--color-text-lighter)',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: 1,
    opacity: 0.7,
    '& img': {
      marginLeft: '8px',
      height: '14px',
      opacity: 0.5
    },
    '& span': {
      color: 'var(--color-text-lighter)',
      textTransform: 'uppercase',
      fontWeight: 600,
      marginLeft: '3px',
      marginTop: '1px',
      fontSize: '12px',
      lineHeight: 1.08
    }
  },
  cell: {
    display: 'flex',
    alignItems: 'center',
    flex: 1
  },
  headerCell: {
    backgroundColor: 'var(--color-lightest)'
  },
  avatar: {
    width: theme.spacing(4),
    height: theme.spacing(4)
  },
  name: {
    fontWeight: 500
  },
  url: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '13px',
    marginTop: '2px',
    marginLeft: '-6px',
    padding: '3px 6px',
    color: 'var(--color-text-light)',
    borderRadius: '4px',
    transition: theme.transitions.create('background-color'),
    '&:hover': {
      backgroundColor: 'var(--color-lightest)'
    }
  },
  chip: {
    marginRight: '10px',
    backgroundColor: theme.palette.ui[100],
    fontSize: '12px'
  }
})

const AutomatedPage = ({
  classes,
  data,
  ecosystem,
  total,
  loadNextPage,
  hasMore,
  setSearchText,
  searchText,
  nextPageIsLoading
}) => {
  const headerHeight = 57
  const rowHeight = 73

  return (
    <Fragment>
      <div data-testid="ecosystem-listings-automated-view">
        <Box marginY={3}>
          <Alert
            variant="outlined"
            severity="info"
            className={classes.outlinedInfo}
            icon={<InfoIcon className={classes.icon} />}
            action={
              <div className={classes.poweredBy}>
                Powered by <img src="/images/logo.svg" alt="Apideck" />
                <span>Network</span>
              </div>
            }
          >
            Automated listings are automatically generated landing pages for every possible
            integration for your product
          </Alert>
        </Box>
        <Paper style={{ position: 'relative' }}>
          <Toolbar>
            <Typography className={classes.title}>
              Automated Listings ({total > 1000 ? '10.000+' : total})
            </Typography>
            <SearchInput value={searchText} handleChange={setSearchText} inline />
          </Toolbar>
          <Divider />
          <InfiniteLoader
            isRowLoaded={(index) => !!data[index]}
            loadMoreRows={() => {
              if (!nextPageIsLoading && hasMore) {
                loadNextPage()
              }
            }}
            rowCount={total}
          >
            {({ onRowsRendered, registerChild }) => (
              <WindowScroller scrollElement={document.querySelector('#scroll-element')}>
                {({ height, isScrolling, scrollTop }) => (
                  <AutoSizer disableHeight>
                    {({ width }) => (
                      <Table
                        ref={registerChild}
                        autoHeight
                        height={height || 0}
                        isScrolling={isScrolling}
                        width={width}
                        headerHeight={headerHeight}
                        rowHeight={rowHeight}
                        rowGetter={({ index }) => data[index]}
                        rowCount={data.length}
                        scrollTop={scrollTop}
                        onRowsRendered={onRowsRendered}
                      >
                        {columns.map(({ id, width, label }, index) => (
                          <Column
                            key={id}
                            dataKey={id}
                            width={width ? width : 200} // nasty hack for fixed width's to work
                            flexGrow={1}
                            maxWidth={width ? width : 10000} // nasty hack for dynamic width of table to work
                            label={label}
                            style={{ margin: 0 }}
                            headerStyle={{
                              margin: 0,
                              textTransform: 'none'
                            }}
                            headerRenderer={({ label }) => (
                              <TableCell
                                component="div"
                                variant="head"
                                style={{ height: headerHeight }}
                                className={`${classes.cell} ${classes.headerCell}`}
                                align={columns[index].numeric || false ? 'right' : 'left'}
                              >
                                {label}
                              </TableCell>
                            )}
                            cellRenderer={({ cellData, columnIndex, dataKey, rowData }) => (
                              <TableCell
                                component="div"
                                variant="body"
                                style={{ height: rowHeight }}
                                className={classes.cell}
                                align={columns[columnIndex].numeric || false ? 'right' : 'left'}
                              >
                                <TableCellContent
                                  dataKey={dataKey}
                                  cellData={cellData}
                                  rowData={rowData}
                                  classes={classes}
                                  ecosystemUrl={ecosystemUrl(ecosystem)}
                                />
                              </TableCell>
                            )}
                          />
                        ))}
                      </Table>
                    )}
                  </AutoSizer>
                )}
              </WindowScroller>
            )}
          </InfiniteLoader>
          {nextPageIsLoading && (
            <Box p={6}>
              <Loader />
            </Box>
          )}
          {data.length === 0 && <Typography variant="caption">No records available.</Typography>}
        </Paper>
      </div>
    </Fragment>
  )
}

const TableCellContent = ({ dataKey, cellData, rowData, classes, ecosystemUrl }) => {
  if (dataKey === 'categories') {
    if (cellData) {
      return cellData.map((data) => (
        <Chip key={data.id} size="small" label={data.name} className={classes.chip} />
      ))
    } else {
      return false
    }
  } else if (dataKey === 'icon') {
    return (
      <Avatar variant="rounded" src={cellData && cellData.url} className={classes.avatar}>
        {rowData.name.substring(0, 1)}
      </Avatar>
    )
  } else if (dataKey === 'actions') {
    return (
      <Tooltip title="View automated listing on your ecosystem">
        <IconButton
          aria-label="View"
          edge="end"
          onClick={() => window.open(`${ecosystemUrl}/s/${rowData.id}`, '_blank')}
        >
          <LaunchIcon style={{ fontSize: '20px' }} />
        </IconButton>
      </Tooltip>
    )
  } else if (dataKey === 'name') {
    return (
      <div>
        <div className={classes.name}>{cellData}</div>
        <div>
          <a
            href={`${ecosystemUrl}/s/${rowData.id}`}
            target="_blank"
            className={classes.url}
            rel="noreferrer"
          >
            {`${ecosystemUrl}/s/${rowData.id}`}
            <OpenInNewIcon
              style={{
                marginLeft: '5px',
                color: 'currentColor',
                fontSize: 12
              }}
            />
          </a>
        </div>
      </div>
    )
  }
  return cellData
}

export default withStyles(styles)(AutomatedPage)
