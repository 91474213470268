import { Card, CardEmptyState, ErrorFallback, Loader } from 'components'

import Button from '@material-ui/core/Button'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import React from 'react'
import Table from './Table'
import { partnersQuery } from 'api'
import { useQuery } from 'react-apollo'

function CreatePartner({ ecosystemId, children }) {
  return <Link to={`/ecosystems/${ecosystemId}/partner/create`}>{children}</Link>
}

function AllPartnersPage({ activeAccount, ecosystem }) {
  const { loading, error, data } = useQuery(partnersQuery, {
    variables: {
      accountId: activeAccount.id,
      ecosystemId: ecosystem.id,
      limit: 500
    }
  })

  if (error) return <ErrorFallback error={error} />

  return (
    <Card
      title="Partners"
      button={
        <CreatePartner ecosystemId={ecosystem.id}>
          <Button variant="contained" className="fr" color="primary">
            + Create
          </Button>
        </CreatePartner>
      }
    >
      <div data-testid="ecosystem-all-partners-view">
        {loading ? (
          <Loader />
        ) : data.partners && data.partners.length === 0 ? (
          <CardEmptyState>
            No partners found.{' '}
            <CreatePartner ecosystemId={ecosystem.id}>Create a Partner</CreatePartner>
          </CardEmptyState>
        ) : (
          <Table
            data={data.partners}
            ecosystemId={ecosystem.id}
            data-testid="ecosystem-all-partners-table"
          />
        )}
      </div>
    </Card>
  )
}

AllPartnersPage.propTypes = {
  activeAccount: PropTypes.object.isRequired,
  ecosystem: PropTypes.object.isRequired
}

export default AllPartnersPage
