import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Table } from 'components'
import Chip from '@material-ui/core/Chip'
import Menu from './Menu'

function normalizeData(contacts) {
  return contacts.map((c) => {
    let status = 'Not Invited'

    if (c.invitation && c.invitation.status) {
      switch (c.invitation.status) {
        case 'EXPIRED':
          status = 'Expired'
          break
        case 'TO_BE_ACTIVATED':
          status = 'Pending'
          break
        case 'ACTIVATED':
          status = 'Activated'
          break
        default:
          break
      }
    }

    return {
      id: c.id,
      firstName: c.firstName,
      lastName: c.lastName,
      role: c.role,
      email: c.email,
      status
    }
  })
}

function ContactActions({
  row: { original: contact },
  handleContactDelete,
  handleContactUpdate,
  handleContactsInvite
}) {
  return (
    <Menu
      contact={contact}
      handleContactDelete={handleContactDelete}
      handleContactUpdate={handleContactUpdate}
      handleContactsInvite={handleContactsInvite}
    />
  )
}

function ContactsList({
  contacts,
  handleContactUpdate,
  handleContactDelete,
  handleContactsInvite
}) {
  const normalizedData = useMemo(() => normalizeData(contacts), [contacts])
  const columns = [
    {
      id: 'firstName',
      accessor: 'firstName',
      Header: 'First Name'
    },
    {
      id: 'lastName',
      accessor: 'lastName',
      Header: 'Last Name'
    },
    {
      id: 'role',
      accessor: 'role',
      Header: 'Role'
    },
    {
      id: 'email',
      accessor: 'email',
      Cell: ({ cell: { value: email } }) => <a href={`mailto:${email}`}>{email}</a>,
      Header: 'Email'
    },
    {
      id: 'status',
      accessor: 'status',
      Header: 'Invitation Status',
      Cell: ({ cell: { value: status } }) => {
        return <Chip variant="outlined" color="primary" size="small" label={status} />
      }
    },
    {
      id: 'actions',
      Header: 'Actions',
      Cell: ContactActions,
      numeric: true,
      width: 15
    }
  ]

  return (
    <Table
      actions={{
        handleContactUpdate,
        handleContactDelete,
        handleContactsInvite
      }}
      columns={columns}
      data={normalizedData}
    />
  )
}

ContactsList.propTypes = {
  contacts: PropTypes.array.isRequired,
  handleContactUpdate: PropTypes.func.isRequired,
  handleContactDelete: PropTypes.func.isRequired,
  handleContactsInvite: PropTypes.func.isRequired
}

export default ContactsList
