import { translationFieldsFragment } from 'api/fragments'
import { ErrorFallback, LoadingPlaceholder } from 'components'
import gql from 'graphql-tag'
import PropTypes from 'prop-types'
import React from 'react'
import { useQuery } from 'react-apollo'
import getLocalizedValue from 'utils/getLocalizedValue'
import PartnerDelete from './Delete'
import PartnerSettings from './Settings'
import { useDeletePartnerAction } from './useDeletePartnerAction'
import { useUpdatePartnerAction } from './useUpdatePartnerAction'

const listingsQuery = gql`
  query marketplace($id: ID!, $accountId: ID!) {
    marketplace(id: $id, accountId: $accountId) {
      id
      listings {
        id
        slug
        name
        translations {
          ...translationFields
        }
      }
    }
  }

  ${translationFieldsFragment}
`

// TODO moves to pages folder

const PartnerSettingsPage = ({ activeAccount, ecosystem, partner }) => {
  const input = {
    accountId: activeAccount.id,
    ecosystemId: ecosystem.id,
    partnerId: partner.id
  }
  const [updatePartnerAction] = useUpdatePartnerAction(input)
  const [deletePartnerAction] = useDeletePartnerAction(input)
  const { loading, error, data } = useQuery(listingsQuery, {
    variables: {
      id: ecosystem.id,
      accountId: activeAccount.id
    }
  })

  if (loading) return <LoadingPlaceholder />
  if (error) return <ErrorFallback error={error} />

  const { marketplace } = data
  const { listings } = marketplace

  return (
    <React.Fragment>
      <PartnerSettings
        handleUpdatePartner={updatePartnerAction}
        initialValues={{
          feature: partner.feature,
          icon: partner.icon ? [partner.icon] : [],
          company: partner.company,
          listed: partner.listed || '',
          listings: partner.listings?.map(({ id }) => id),
          website: partner.website || '',
          twitter: partner.twitter || ''
        }}
        listings={listings.map((listing) => ({
          value: listing.id,
          label: getLocalizedValue(listing, 'name', ecosystem.locales)
        }))}
      />
      <PartnerDelete handleDelete={deletePartnerAction} />
    </React.Fragment>
  )
}

PartnerSettingsPage.propTypes = {
  activeAccount: PropTypes.object.isRequired,
  ecosystem: PropTypes.object.isRequired,
  partner: PropTypes.object.isRequired
}

export default PartnerSettingsPage
