import React from 'react'
import styled from 'react-emotion'
import CircularProgress from '@material-ui/core/CircularProgress'
import purple from '@material-ui/core/colors/purple'

const Wrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 400px;
  text-align: center;
  margin: 40px;
`

const Item = styled('div')`
  margin-top: 25px;
`

const LoaderWithText = ({ children }) => (
  <Wrapper>
    <Item>
      <CircularProgress style={{ color: purple[500] }} thickness={5} />
    </Item>
    <Item>{children}</Item>
  </Wrapper>
)

export default LoaderWithText
