import { ExternalA, MarkdownEditor, Pill } from 'components'
import { compose, withHandlers } from 'recompose'

import React from 'react'
import isFieldLocalized from 'utils/isFieldLocalized'
import styled from 'react-emotion'

const MODE = 'htmlmixed'

const InputWrapper = styled('div')`
  padding: var(--input-padding);
  border: 1px solid rgb(212, 212, 212);
  border-radius: 3px;
  width: 100%;
`

const Input = ({ field, label, pill, description, formikProps: { values }, handleChange }) => (
  <div id={field}>
    <div
      style={{
        marginBottom: '16px'
      }}
    >
      <label htmlFor={field}>
        {label} {pill && <Pill>{pill}</Pill>}
      </label>
      {description && <p>{description}</p>}
      <InputWrapper>
        <MarkdownEditor
          value={values[field]}
          mode={MODE}
          lineWrapping
          onBeforeChange={handleChange}
        />
      </InputWrapper>
      <div style={{ fontSize: '13px', marginTop: '10px' }}>
        Only should be used with valid{' '}
        <ExternalA href="https://en.wikipedia.org/wiki/HTML">HTML</ExternalA>. Can only consist of
        HTML elements within the body tag.
      </div>
    </div>
  </div>
)

const withHandleChange = withHandlers({
  handleChange:
    ({ field, formikProps }) =>
    (editor, data, value) =>
      formikProps.setFieldValue(field, value)
})

const HtmlTextInputWithHandler = compose(withHandleChange)(Input)

const HtmlTextInput = (props) =>
  isFieldLocalized(props) ? (
    <LocalizedHtmlTextInput {...props} />
  ) : (
    <HtmlTextInputWithHandler {...props} />
  )

const LocalizedHtmlTextInput = (props) => (
  <React.Fragment>
    {props.formikProps.initialValues.locales.map((locale) => (
      <HtmlTextInputWithHandler
        {...props}
        field={`${props.field}_${locale}`}
        label={props.label}
        pill={locale}
        key={locale}
      />
    ))}
  </React.Fragment>
)

export default HtmlTextInput
