import React from 'react'
import { graphql } from 'react-apollo'
import { compose, withHandlers } from 'recompose'
import PropTypes from 'prop-types'
import { updateAccountMutation } from 'api/queries'
import { AccountOverview } from 'components'
import LoadingPlaceholder from 'components/placeholder/Loading'
import { toastr } from 'react-redux-toastr'

class Container extends React.Component {
  render() {
    const { activeAccount, handleSubmit } = this.props

    if (!activeAccount) {
      return <LoadingPlaceholder />
    } else {
      return <AccountOverview handleSubmit={handleSubmit} account={activeAccount} />
    }
  }
}

const handleSubmit = withHandlers({
  handleSubmit:
    ({ activeAccount, updateAccount }) =>
    async ({ name, website, imageUrl }) => {
      const variables = {
        accountId: activeAccount.id,
        name,
        website,
        imageUrl
      }

      try {
        await updateAccount({ variables })
        toastr.success('Account updated')
      } catch (error) {
        toastr.error("Couldn't update account")
        throw new Error(error.message)
      }
    }
})

Container.propTypes = {
  activeAccount: PropTypes.object.isRequired
}

const updateAccount = graphql(updateAccountMutation, {
  name: 'updateAccount'
})

const OverviewContainer = compose(updateAccount, handleSubmit)(Container)

export default OverviewContainer
