import Grid from '@material-ui/core/Grid'
import { EcosystemPreview, GridContainer, Submit } from 'components'
import { imageUrlFormField } from 'components/Helpers'
import { Formik } from 'formik'
import PropTypes from 'prop-types'
import React from 'react'
import * as yup from 'yup'
import {
  CardFieldset,
  DetailPageFieldset,
  HomePageFieldset,
  MastheadFieldset,
  privacyAndTermsLinkValidation,
  TemplateColorsFieldset,
  TemplateCtaFieldset,
  TemplateCustomCssFieldset,
  TemplateCustomHtmlFieldset,
  TemplateCustomJavaScriptFieldset,
  TemplateFooterFieldset,
  TemplateNavigationFieldset,
  TemplateOverviewSidebarFieldset,
  TemplatePrivacyTermsLinks,
  templateValidationSchema,
  VotingPortalFieldset
} from '../../forms/Base'
import NavigationItems from '../navigation-items'

const schema = yup.object().shape({
  ...templateValidationSchema,
  ...privacyAndTermsLinkValidation
})

export const TemplateForm = ({
  initialValues,
  handleSubmit,
  ecosystem,
  handleNavigationItemCreate,
  handleNavigationItemUpdate,
  handleNavigationItemDelete,
  handleFooterItemCreate,
  handleFooterItemUpdate,
  handleFooterItemDelete,
  handleEcosystemMastheadBackgroundImageDelete,
  handleEcosystemMastheadImageDelete,
  handleEcosystemLogoDelete,
  plan
}) => (
  <Formik validationSchema={schema} initialValues={initialValues} onSubmit={handleSubmit}>
    {(formikProps) => {
      const { handleSubmit, isSubmitting, errors, values } = formikProps
      return (
        <form onSubmit={handleSubmit} data-testid="ecosystem-settings-template-form">
          {errors.general && <span className="errors">{errors.general}</span>}
          <GridFormPreview values={values} ecosystem={ecosystem}>
            <MastheadFieldset
              formikProps={formikProps}
              handleBackgroundImageDelete={handleEcosystemMastheadBackgroundImageDelete}
              handleImageDelete={handleEcosystemMastheadImageDelete}
            />
            <HomePageFieldset formikProps={formikProps} />
            <TemplateColorsFieldset formikProps={formikProps} />
            <CardFieldset formikProps={formikProps} />
            <VotingPortalFieldset formikProps={formikProps} />
            <TemplateOverviewSidebarFieldset formikProps={formikProps} ecosystem={ecosystem} />
            <DetailPageFieldset formikProps={formikProps} />
            <TemplateCtaFieldset formikProps={formikProps} />
            <TemplateNavigationFieldset
              formikProps={formikProps}
              handleEcosystemLogoDelete={handleEcosystemLogoDelete}
            >
              <NavigationItems
                itemType="Navigation"
                items={ecosystem.navigationItems}
                handleCreate={handleNavigationItemCreate}
                handleUpdate={handleNavigationItemUpdate}
                handleDelete={handleNavigationItemDelete}
                locales={formikProps.initialValues.locales}
              />
            </TemplateNavigationFieldset>
            <TemplateFooterFieldset formikProps={formikProps}>
              <NavigationItems
                itemType="Footer"
                items={ecosystem.footerItems}
                handleCreate={handleFooterItemCreate}
                handleUpdate={handleFooterItemUpdate}
                handleDelete={handleFooterItemDelete}
                locales={formikProps.initialValues.locales}
              />
            </TemplateFooterFieldset>
            <TemplatePrivacyTermsLinks formikProps={formikProps} />
            {plan === 'ENTERPRISE' && <TemplateCustomHtmlFieldset formikProps={formikProps} />}
            <TemplateCustomCssFieldset
              formikProps={formikProps}
              disabled={plan !== 'GROWTH' && plan !== 'ENTERPRISE' && plan !== 'SCALE'}
            />
            <TemplateCustomJavaScriptFieldset
              formikProps={formikProps}
              disabled={plan === 'FREE'}
            />
            <div className="sticky-submit-container align-left">
              <Submit isSubmitting={isSubmitting} text="Save settings" />
            </div>
          </GridFormPreview>
        </form>
      )
    }}
  </Formik>
)

const GridFormPreview = ({ children, values, ecosystem }) => (
  <GridContainer>
    <Grid item sm={7}>
      {children}
    </Grid>
    <Grid item sm={5}>
      <div style={{ position: 'sticky', maxWidth: '500px', top: '20px' }}>
        <EcosystemPreview
          ecosystem={ecosystem}
          primaryColor={values.primaryColor}
          bodyColor={values.bodyColor}
          bodyLinkColor={values.bodyLinkColor}
          bodyBackgroundColor={values.bodyBackgroundColor}
          cardColumns={12 / values.cardColumns}
          cardStyle={values.cardStyle}
          cardShadowEnabled={values.cardShadowEnabled}
          cardBorderRadius={values.cardBorderRadius || '5px'}
          cardShowDescription={values.cardShowDescription}
          cardDescriptionLines={values.cardDescriptionLines}
          cardShowCategory={values.cardShowCategory}
          website={ecosystem.website}
          logo={imageUrlFormField(values.logo)}
          mastheadBackgroundImage={imageUrlFormField(values.mastheadBackgroundImage)}
          favicon={
            (ecosystem.favicon && ecosystem.favicon.url) || (ecosystem.icon && ecosystem.icon.url)
          }
          mastheadTitle={values.mastheadTitle}
          mastheadDescription={values.mastheadDescription}
          mastheadColor={values.mastheadColor}
          mastheadBackgroundColor={values.mastheadBackgroundColor}
          mastheadColumns={values.mastheadColumns}
          mastheadImage={values.mastheadImage}
          homePageShowAllListings={values.homePageShowAllListings}
          homePageCollectionCategoryCards={values.homePageCollectionCategoryCards}
          ctaTitle={values.ctaTitle}
          ctaDescription={values.ctaDescription}
          ctaColor={values.ctaColor}
          ctaBackgroundColor={values.ctaBackgroundColor}
          ctaButtonLabel={values.ctaButtonLabel}
          ctaButtonBackgroundColor={values.ctaButtonBackgroundColor}
          ctaButtonColor={values.ctaButtonColor}
          ctaEnabled={values.ctaEnabled}
          footerColor={values.footerColor}
          footerBackgroundColor={values.footerBackgroundColor}
          menuPosition={values.menuPosition}
          menuStyle={values.menuStyle}
        />
      </div>
    </Grid>
  </GridContainer>
)

TemplateForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object
}

export default TemplateForm
