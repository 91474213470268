import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import { makeStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import { getConnectionQuery } from 'api/queries'
import {
  IconButton,
  Loader,
  ModalParentView,
  ModalView,
  OAuthCard,
  SelectInput,
  SettingsList,
  SettingsModalSubView,
  TextInputNew as TextInput,
  ToggleField
} from 'components'
import { Formik } from 'formik'
import PropTypes from 'prop-types'
import queryString from 'query-string'
import React, { Fragment, useEffect, useState } from 'react'
import { withApollo } from 'react-apollo'
import { compose } from 'recompose'
import { getItem } from 'utils/localstorage'

export const useStyles = makeStyles((theme) => ({
  card: {
    width: '840px'
  },
  cardHeader: {
    padding: theme.spacing(4, 6),
    borderBottom: `1px solid ${theme.palette.ui[200]}`
  },
  avatar: {
    width: '40px',
    height: '40px'
  },
  title: {
    fontSize: '20px',
    fontWeight: 500,
    color: theme.palette.ui[800]
  },
  subheader: {
    color: theme.palette.ui[300],
    fontSize: '14px'
  },
  cardHeaderActions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  action: {
    margin: 0
  },
  buttonClose: {
    marginLeft: theme.spacing(2)
  },
  loaderContainer: {
    marginRight: theme.spacing(2)
  },
  content: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(2, 6),
    color: theme.palette.ui[600],
    fontSize: '15px',
    '&:last-of-type': {
      paddingBottom: theme.spacing(6)
    }
  },
  contentTitle: {
    fontSize: '16px',
    fontWeight: 500,
    color: theme.palette.ui[800]
  },
  description: {
    marginTop: theme.spacing(1),
    fontSize: '15px',
    fontWeight: 400,
    color: theme.palette.ui[300]
  },
  form: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  formContent: {
    padding: theme.spacing(4, 6, 2),
    color: theme.palette.ui[600],
    fontSize: '15px'
  },
  formFooter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    backgroundColor: theme.palette.ui[100],
    padding: theme.spacing(2, 6)
  },
  fieldDescription: {
    marginTop: theme.spacing(1),
    fontSize: '14px',
    color: theme.palette.ui[400]
  }
}))

const IntegrationsSettingsModal = ({
  connection,
  ecosystemId,
  setModalOpen,
  handleFormSubmit,
  formSubmitLoading,
  headerActions,
  subheader,
  client
}) => {
  const classes = useStyles()
  const [activeModalViewId, setActiveModalViewId] = useState()
  const [connectionDetails, setConnectionDetails] = useState(connection)
  const [connectionDetailsLoading, setConnectionDetailsLoading] = useState(false)
  const { name, serviceId, icon, tagLine, formFields, authType, unifiedApi } = connectionDetails

  useEffect(() => {
    let isSubscribed = true

    const fetchConnectionDetail = async () => {
      setConnectionDetailsLoading(true)
      // get connection detail so apollo cache is updated for this connection
      const { error, data } = await client.query({
        query: getConnectionQuery,
        variables: {
          ecosystemId,
          accountId: getItem('activeAccountId'),
          unifiedApiId: unifiedApi,
          serviceId
        }
      })

      if (error) {
        console.log('error fetching connection', error)
      }
      // only set connectionDetails state with the result if `isSubscribed` is true
      // so we don't keep calling if 'someone' else alters the state
      if (isSubscribed) {
        setConnectionDetails(data.connection)
        setConnectionDetailsLoading(false)
      }
    }

    if (unifiedApi) {
      fetchConnectionDetail().catch(console.error)
    }

    return () => (isSubscribed = false)
  }, [client, ecosystemId, serviceId, unifiedApi])

  useEffect(() => {
    const values = queryString.parse(window.location.search)

    if (values.view) {
      setActiveModalViewId(values.view)
    }
  }, [])

  return (
    <Card className={classes.card}>
      <ModalParentView activeModalViewId={activeModalViewId}>
        <ModalView id="default">
          <Fragment>
            <CardHeader
              className={classes.cardHeader}
              classes={{
                action: classes.action
              }}
              avatar={
                <Avatar className={classes.avatar} src={icon} aria-label={name} variant="rounded">
                  {name.substring(0, 1)}
                </Avatar>
              }
              action={
                <div className={classes.cardHeaderActions}>
                  {headerActions}
                  <Tooltip title="Close" placement="top" arrow>
                    <div className={classes.buttonClose}>
                      <IconButton onClick={() => setModalOpen(false)}>
                        <CloseIcon style={{ fontSize: 24 }} />
                      </IconButton>
                    </div>
                  </Tooltip>
                </div>
              }
              title={name}
              titleTypographyProps={{
                className: classes.title
              }}
              subheader={subheader}
              subheaderTypographyProps={{
                className: classes.subheader
              }}
            />
            {tagLine && (
              <CardContent className={classes.content}>
                <Typography className={classes.contentTitle} component="h3">
                  About
                </Typography>
                <Typography className={classes.description}>{tagLine}</Typography>
              </CardContent>
            )}
            {authType === 'oauth2' && (
              <CardContent className={classes.content}>
                <Typography className={classes.contentTitle} component="h3">
                  OAuth connection settings
                </Typography>
                <OAuthCard connection={connection} />
              </CardContent>
            )}
            {connectionDetailsLoading ? (
              <CardContent className={classes.content}>
                <Typography className={classes.contentTitle} component="h3">
                  <Loader />
                </Typography>
              </CardContent>
            ) : (
              formFields.length !== 0 && (
                <CardContent className={classes.content}>
                  <Typography className={classes.contentTitle} component="h3">
                    Settings
                  </Typography>
                  <SettingsList
                    setActiveModalViewId={setActiveModalViewId}
                    formFields={formFields}
                    connection={connection}
                  />
                </CardContent>
              )
            )}
          </Fragment>
        </ModalView>

        {formFields.length !== 0 &&
          formFields.map((formField) => {
            const { formId, type, label, description, value, required, options, placeholder } =
              formField
            const initialValues = {
              unifiedApi,
              [formId]: value ? value : ''
            }
            if (serviceId) {
              initialValues.serviceId = serviceId
            }

            return (
              <ModalView key={formId} id={formId}>
                <SettingsModalSubView
                  icon={icon}
                  name={name}
                  title={name}
                  backAction={() => setActiveModalViewId(null)}
                  closeAction={() => setModalOpen(false)}
                  subheader={subheader}
                >
                  {type === 'text' && (
                    <Formik
                      onSubmit={(values) => handleFormSubmit(values)}
                      initialValues={initialValues}
                      validate={(values) => {
                        const errors = {}
                        if (required && values[formId] === '') {
                          errors[formId] = 'This field is required'
                        }
                        return errors
                      }}
                      validateOnChange={false}
                      validateOnBlur={false}
                    >
                      {(formikProps) => {
                        const { handleSubmit } = formikProps

                        return (
                          <form className={classes.form} onSubmit={handleSubmit}>
                            <div className={classes.formContent}>
                              <TextInput
                                formikProps={formikProps}
                                label={label}
                                field={formId}
                                required={required}
                                placeholder={placeholder}
                              />
                              {description && (
                                <Typography className={classes.fieldDescription}>
                                  {description}
                                </Typography>
                              )}
                            </div>
                            <div className={classes.formFooter}>
                              <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                                disabled={formSubmitLoading}
                              >
                                {formSubmitLoading ? 'Loading..' : 'Save'}
                              </Button>
                            </div>
                          </form>
                        )
                      }}
                    </Formik>
                  )}
                  {type === 'checkbox' && (
                    <form className={classes.form}>
                      <div className={classes.formContent}>
                        <ToggleField
                          isActive={value}
                          label={label}
                          onToggle={() => {
                            if (!formSubmitLoading) {
                              handleFormSubmit({
                                [formId]: !value
                              })
                            }
                          }}
                        />
                        {description && (
                          <Typography className={classes.fieldDescription}>
                            {description}
                          </Typography>
                        )}
                      </div>
                    </form>
                  )}
                  {type === 'select' && (
                    <Formik
                      onSubmit={(values) => handleFormSubmit(values)}
                      initialValues={initialValues}
                      validate={(values) => {
                        const errors = {}
                        if (values[formId] === '') {
                          errors[formId] = 'This field is required'
                        }
                        return errors
                      }}
                      validateOnChange={false}
                      validateOnBlur={false}
                    >
                      {(formikProps) => {
                        const { handleSubmit } = formikProps

                        return (
                          <form className={classes.form} onSubmit={handleSubmit}>
                            <div className={classes.formContent}>
                              <SelectInput
                                formikProps={formikProps}
                                label={label}
                                field={formId}
                                options={options}
                                required={required}
                                placeholder={placeholder}
                              />
                              {description && (
                                <Typography className={classes.fieldDescription}>
                                  {description}
                                </Typography>
                              )}
                            </div>
                            <div className={classes.formFooter}>
                              <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                                disabled={formSubmitLoading}
                              >
                                {formSubmitLoading ? 'Loading..' : 'Save'}
                              </Button>
                            </div>
                          </form>
                        )
                      }}
                    </Formik>
                  )}
                </SettingsModalSubView>
              </ModalView>
            )
          })}
      </ModalParentView>
    </Card>
  )
}

IntegrationsSettingsModal.defaultProps = {
  headerActions: null,
  subheader: null
}

IntegrationsSettingsModal.propTypes = {
  connection: PropTypes.shape({
    name: PropTypes.string.isRequired,
    serviceId: PropTypes.string,
    icon: PropTypes.string,
    tagLine: PropTypes.string.isRequired,
    authType: PropTypes.string,
    formFields: PropTypes.arrayOf(
      PropTypes.shape({
        formId: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        value: PropTypes.any,
        type: PropTypes.string.isRequired,
        translatable: PropTypes.bool,
        options: PropTypes.arrayOf(
          PropTypes.shape({
            label: PropTypes.string.isRequired,
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
          }).isRequired
        )
      }).isRequired
    )
  }).isRequired,
  setModalOpen: PropTypes.func.isRequired,
  handleFormSubmit: PropTypes.func.isRequired,
  formSubmitLoading: PropTypes.bool.isRequired,
  headerActions: PropTypes.element,
  subheader: PropTypes.node,
  serviceId: PropTypes.string
}

export default compose(withApollo)(IntegrationsSettingsModal)
