import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  loader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    color: theme.palette.ui[300],
    fontSize: '12px',
    fontWeight: 400
  },
  loaderIcon: {
    marginRight: theme.spacing(1),
    color: theme.palette.ui[300]
  }
}))

const LoaderInline = () => {
  const classes = useStyles()

  return (
    <span className={classes.loader}>
      <CircularProgress className={classes.loaderIcon} thickness={4} size={16} />
      Saving..
    </span>
  )
}

export default LoaderInline
