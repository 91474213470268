import { matchPath, withRouter } from 'react-router-dom'

import { default as MUTabs } from '@material-ui/core/Tabs'
import PropTypes from 'prop-types'
import React from 'react'

function Tabs(props) {
  const { children, selectors, location } = props

  const match = selectors.find((selector) => !!matchPath(location.pathname, { ...selector }))
  const selected = match === undefined ? false : match.tab

  return (
    <MUTabs
      value={selected}
      textColor="primary"
      variant="scrollable"
      scrollButtons="auto"
      aria-label="scrollable auto tabs example"
    >
      {children}
    </MUTabs>
  )
}

Tabs.propTypes = {
  selectors: PropTypes.arrayOf(
    PropTypes.shape({
      tab: PropTypes.any.isRequired,
      path: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.array.isRequired])
    })
  ).isRequired,
  location: PropTypes.object,
  children: PropTypes.node.isRequired
}

Tabs.contextTypes = {
  router: PropTypes.shape({
    route: PropTypes.object.isRequired
  })
}

export default withRouter(Tabs)
