import differenceInDays from 'date-fns/differenceInDays'
import addDays from 'date-fns/addDays'

export const getDaysLeft = (endOfInitialTrial, trialExtensions) => {
  let endOfTrial = new Date(endOfInitialTrial)

  if (trialExtensions !== 0) {
    endOfTrial = addDays(endOfTrial, 14 * trialExtensions)
  }

  const difference = differenceInDays(new Date(endOfTrial), new Date(Date.now()))
  return difference <= 0 ? 0 : difference
}
