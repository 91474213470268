import listingStoreActions from '../listings'
import partnerStoreActions from '../partners'

function importService(cache, { accountId, ecosystemId }, { service }) {
  const { partners } = service

  listingStoreActions.add(
    cache,
    {
      accountId,
      ecosystemId
    },
    {
      listing: service
    }
  )
  partnerStoreActions.add(cache, { accountId, ecosystemId }, { partners })
}

export default importService
