import { ContentContainer, NotFound } from 'components'
import Header from 'components/ecosystem/voting-portal/Header'
import PrivateRoute from 'components/PrivateRoute'
import { RequestsContainer } from 'pages'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { Route, Switch, withRouter } from 'react-router-dom'

const ListingsRoutes = ({ activeAccount, ecosystem, match }) => (
  <Switch>
    <Route path={[`${match.path}/requests`]}>
      <WithHeader ecosystem={ecosystem} activeAccount={activeAccount} />
    </Route>
  </Switch>
)

const WithHeader = ({ ecosystem, activeAccount }) => {
  const [activePath, setActivePath] = useState('')

  return (
    <ContentContainer header={<Header activePath={activePath} ecosystem={ecosystem} />}>
      <Switch>
        <PrivateRoute
          path="/ecosystems/:ecosystemId/voting-portal/requests"
          component={RequestsContainer}
          setActivePath={setActivePath}
          activeAccount={activeAccount}
        />
        {/*
          fallback route
          TODO: redirect to dedicated 404 page (to be created)
        */}
        <Route component={NotFound} />
      </Switch>
    </ContentContainer>
  )
}

ListingsRoutes.propTypes = {
  activeAccount: PropTypes.object.isRequired,
  ecosystem: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired
}

export default withRouter(ListingsRoutes)
