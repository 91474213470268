import React from 'react'
import { AccountSettingsForm, Card } from 'components'
import PropTypes from 'prop-types'

const Overview = ({ handleSubmit, account }) => (
  <Card>
    <AccountSettingsForm onSubmit={handleSubmit} initialValues={account} />
  </Card>
)

Overview.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  account: PropTypes.object.isRequired
}

export default Overview
