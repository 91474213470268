import React, { useRef, useState } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import InputBase from '@material-ui/core/InputBase'
import IconButton from '@material-ui/core/IconButton'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import VisibilityIcon from '@material-ui/icons/Visibility'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'
import Tooltip from '@material-ui/core/Tooltip'
import { makeStyles, useTheme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  wrapper: {
    marginTop: theme.spacing(1)
  },
  container: {
    display: 'flex'
  },
  label: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 500,
    color: theme.palette.ui[500]
  },
  inputPrefixSuffix: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1.5),
    borderRadius: '4px',
    border: `1px solid ${theme.palette.ui[250]}`,
    backgroundColor: theme.palette.ui[100],
    color: theme.palette.ui[300]
  },
  inputPrefix: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    borderRight: 0
  },
  inputSuffix: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderLeft: 0
  },
  inputContainer: {
    width: '100%',
    position: 'relative'
  },
  input: {
    height: '45px',
    borderRadius: '4px',
    border: `1px solid ${theme.palette.ui[250]}`,
    fontSize: '16px',
    color: theme.palette.ui[800],
    paddingLeft: theme.spacing(1),
    '&:focus': {
      border: `1px solid ${theme.palette.primary.main}`
    },
    '&:disabled': {
      backgroundColor: theme.palette.ui[100]
    }
  },
  description: {
    display: 'block',
    marginTop: theme.spacing(1),
    color: theme.palette.ui[400]
  },
  hasPrefix: {
    '& input': {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0
    }
  },
  hasSuffix: {
    '& input': {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0
    }
  },
  actionsContainer: {
    position: 'absolute',
    top: 0,
    right: theme.spacing(2),
    bottom: 0,
    display: 'flex',
    alignItems: 'center'
  },
  actionIcon: {
    color: theme.palette.ui[300]
  },
  actionIconLast: {
    marginLeft: theme.spacing(1)
  }
}))

const Label = ({
  field,
  label,
  type,
  placeholder,
  description,
  disabled,
  sensitive,
  valueCanBeCopied,
  value,
  prefix,
  suffix
}) => {
  const classes = useStyles()
  const theme = useTheme()
  const input = useRef()
  const [copied, setCopied] = useState(false)
  const [reveal, setReveal] = useState(false)
  let paddingRight = theme.spacing(1)
  if (sensitive && valueCanBeCopied) {
    paddingRight = theme.spacing(10)
  } else if (sensitive || valueCanBeCopied) {
    paddingRight = theme.spacing(6)
  }
  const copyToClipboard = () => {
    navigator.clipboard.writeText(input.current.value)
    setCopied(true)
  }

  return (
    <div className={classNames(classes.wrapper)}>
      {label && (
        <label htmlFor={field} className={classNames(classes.label)}>
          {label}
        </label>
      )}
      <div className={classNames(classes.container)}>
        {prefix && (
          <div className={classNames(classes.inputPrefixSuffix, classes.inputPrefix)}>{prefix}</div>
        )}
        <div className={classes.inputContainer}>
          <InputBase
            id={field}
            type={reveal ? 'text' : type}
            name={field}
            placeholder={placeholder}
            disabled={disabled}
            inputProps={{
              ref: input,
              'aria-label': label ? label : 'input',
              style: { paddingRight }
            }}
            value={value}
            classes={{
              input: classes.input
            }}
            fullWidth
            className={classNames({
              [classes.hasPrefix]: prefix,
              [classes.hasSuffix]: suffix
            })}
          />
          {(sensitive || valueCanBeCopied) && (
            <div className={classes.actionsContainer}>
              {sensitive && (
                <Tooltip title={reveal ? 'Hide' : 'Reveal'}>
                  <IconButton
                    size="small"
                    aria-label={reveal ? 'Hide' : 'Reveal'}
                    onClick={() => setReveal(!reveal)}
                    className={classes.actionIcon}
                  >
                    {reveal ? (
                      <VisibilityOffIcon style={{ fontSize: '18px' }} />
                    ) : (
                      <VisibilityIcon style={{ fontSize: '18px' }} />
                    )}
                  </IconButton>
                </Tooltip>
              )}
              {valueCanBeCopied && (
                <Tooltip
                  title={copied ? 'Copied!' : 'Copy to clipboard'}
                  onClose={() => {
                    //await transition
                    setTimeout(() => {
                      setCopied(false)
                    }, 300)
                  }}
                >
                  <IconButton
                    size="small"
                    aria-label="copy to clipboard"
                    onClick={() => copyToClipboard()}
                    className={classNames(classes.actionIcon, classes.actionIconLast)}
                  >
                    <FileCopyIcon style={{ fontSize: '18px' }} />
                  </IconButton>
                </Tooltip>
              )}
            </div>
          )}
        </div>
        {suffix && (
          <div className={classNames(classes.inputPrefixSuffix, classes.inputSuffix)}>{suffix}</div>
        )}
      </div>
      {description && <small className={classes.description}>{description}</small>}
    </div>
  )
}

Label.defaultProps = {
  label: null,
  type: 'text',
  placeholder: null,
  description: null,
  isSensitive: false,
  valueCanBeCopied: true,
  prefix: null,
  suffix: null,
  value: null
}

Label.propTypes = {
  field: PropTypes.string.isRequired,
  label: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  description: PropTypes.string,
  value: PropTypes.string,
  sensitive: PropTypes.bool,
  valueCanBeCopied: PropTypes.bool,
  prefix: PropTypes.node,
  suffix: PropTypes.node
}

export default Label
