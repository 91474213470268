import React from 'react'

const InfoIcon = () => (
  <svg width={16} height={16} viewBox="0 0 16 16">
    <g fill="#A037EC">
      <circle opacity={0.15} cx={8} cy={8} r={8} />
      <path
        d="M8.612,9.856 L8.612,9.664 C8.612,9.436 8.66,9.244 8.768,9.1 C8.876,8.956 9.08,8.776 9.38,8.548 L9.884,8.152 C10.496,7.684 10.796,7.072 10.796,6.328 C10.796,5.656 10.532,5.104 10.016,4.66 C9.5,4.216 8.804,4 7.928,4 C6.02,4 5,5.068 5,6.664 L6.872,6.664 C6.872,5.968 7.22,5.62 7.928,5.62 C8.6,5.62 8.9,6.004 8.9,6.436 C8.9,6.832 8.744,7 8.276,7.372 L7.892,7.672 C7.124,8.284 6.812,8.716 6.812,9.664 L6.812,9.856 L8.612,9.856 Z M7.772,12.616 C8.456,12.616 8.852,12.208 8.852,11.536 C8.852,10.876 8.456,10.468 7.772,10.468 C7.064,10.468 6.692,10.864 6.692,11.536 C6.692,12.22 7.064,12.616 7.772,12.616 Z"
        fillRule="nonzero"
        opacity={0.5}
      />
    </g>
  </svg>
)

export default InfoIcon
