import {
  fileFieldsFragment,
  linkFieldsFragment,
  mediaFieldsFragment,
  screenshotFieldsFragment,
  translationFieldsFragment
} from 'api/fragments'

import gql from 'graphql-tag'

const listingsOverviewQuery = gql`
  query listing($accountId: ID!, $listingId: ID!) {
    listing(accountId: $accountId, listingId: $listingId) {
      id
      externalId
      parentId
      name
      slug
      description
      features
      pricing
      tagLine
      metaTagTitle
      metaTagDescription
      metaTagKeywords
      unifyConnectorId
      nativeIntegration
      thirdPartyIntegration
      nativeIntegrationLink
      thirdPartyIntegrationLink
      cardBackgroundColor
      cardBackgroundImage {
        ...fileFields
      }
      sticky
      upcoming
      zapierId
      piesyncId
      blendrId
      integromatId
      segmentId
      microsoftFlowId
      combideskId
      trayIoId
      automateId
      cloudServiceId
      detailPageDisabled
      published
      publishedAt
      state
      translations {
        ...translationFields
      }
      logo {
        ...fileFields
      }
      screenshots {
        ...screenshotFields
      }
      media {
        ...mediaFields
      }
      links {
        ...linkFields
      }
      category {
        id
        name
        slug
      }
      categories {
        id
        name
        slug
      }
      collections {
        id
        name
        slug
      }
      partner {
        id
        company
      }
      comments {
        id
        content
        author {
          id
          email
        }
        createdAt
      }
    }
  }

  ${fileFieldsFragment}
  ${linkFieldsFragment}
  ${translationFieldsFragment}
  ${screenshotFieldsFragment}
  ${mediaFieldsFragment}
`

export default listingsOverviewQuery
