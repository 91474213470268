import { partnerQuery } from 'api'
import { propEq, reject } from 'ramda'

function remove(cache, { accountId, ecosystemId, partnerId }, { id }) {
  const query = partnerQuery
  const variables = {
    accountId,
    ecosystemId,
    id: partnerId
  }
  const data = cache.readQuery({ query, variables })
  const byContactId = propEq('id', id)

  data.partner.contacts = reject(byContactId, data.partner.contacts)

  cache.writeQuery({ query, data, variables })
}

export { remove }
