import * as yup from 'yup'

import {
  CheckboxInput,
  DialogActions,
  DialogTitle,
  PanelInputWithInfo,
  SelectInput,
  Small
} from 'components'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import { Formik } from 'formik'
import PropTypes from 'prop-types'
import React from 'react'
import { withStyles } from '@material-ui/core/styles'

const styles = () => ({
  paper: {
    overflow: 'visible'
  }
})

const validationSchema = yup.object().shape({
  category: yup.string().required('Category is required')
})

function ImportForm({ open, onSubmit, onClose, classes, categories, initialValues }) {
  const sortedCategories = categories.sort(function (a, b) {
    const textA = a.label.toUpperCase()
    const textB = b.label.toUpperCase()
    return textA < textB ? -1 : textA > textB ? 1 : 0
  })

  return (
    <Dialog classes={{ paper: classes.paper }} fullWidth={true} onClose={onClose} open={open}>
      <DialogTitle>Import listing</DialogTitle>
      <Formik validationSchema={validationSchema} initialValues={initialValues} onSubmit={onSubmit}>
        {(formikProps) => {
          const { handleSubmit, isSubmitting } = formikProps

          return (
            <form onSubmit={handleSubmit}>
              <DialogContent style={{ overflow: 'visible' }}>
                <SelectInput
                  formikProps={formikProps}
                  label="Select the category that you want to assign to the listing"
                  field="category"
                  options={sortedCategories}
                  defaultValue={initialValues.category}
                />
                <PanelInputWithInfo
                  leftBloc={
                    <CheckboxInput formikProps={formikProps} label="Published" field="published" />
                  }
                  rightBloc={<Small>Publish the listing to your ecosystem.</Small>}
                />
                <PanelInputWithInfo
                  leftBloc={
                    <CheckboxInput
                      formikProps={formikProps}
                      label="Upcoming"
                      field="upcoming"
                      disabled={!formikProps.values.published}
                    />
                  }
                  rightBloc={<Small>Mark the listing as coming soon (disables detail page).</Small>}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button type="submit" color="secondary" disabled={isSubmitting}>
                  Submit
                </Button>
              </DialogActions>
            </form>
          )
        }}
      </Formik>
    </Dialog>
  )
}

ImportForm.propTypes = {
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired
    })
  ),
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  service: PropTypes.object.isRequired
}

export default withStyles(styles)(ImportForm)
