import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { authenticateMutation, createUserMutation } from 'api/queries'
import { useLocation, useParams } from 'react-router-dom'
import { useMutation, withApollo } from 'react-apollo'

import { Helmet } from 'react-helmet'
import SignupContainer from 'components/signup/layout/SignupContainer'
import SignupContent from 'components/signup/SignupContent'
import SignupLoading from 'components/signup/SignupLoading'
import SignupSetupLayout from 'components/layout/SignupSetup'
import SignupSidebarContent from 'components/signup/sidebar/SignupSidebarContent'
import SignupSidebarFooter from 'components/signup/sidebar/SignupSidebarFooter'
import SignupUserExists from 'components/signup/SignupUserExists'
import { auth } from 'routes/App'
import { handleRedirectAfterSignup } from 'utils/handleRedirectAfterSignup'
import { handleUserFormError } from 'utils/ErrorHandling'
import { setItem } from 'utils/localstorage'
import { useIntercom } from 'react-use-intercom'

const Page = ({ client, history }) => {
  const { shutdown: shutdownIntercom } = useIntercom()
  const { USERNAME_PASSWORD, GOOGLE, GITHUB } = auth.connections()

  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [product, setProduct] = useState('unify')
  const [userExists, setUserExists] = useState(false)
  const [userProfile, setUserProfile] = useState(null)
  const [referer, setReferer] = useState(null)
  const [authenticatedUser, setAuthenticatedUser] = useState(null)

  const [authenticate] = useMutation(authenticateMutation)
  const [createUser] = useMutation(createUserMutation)

  const { search } = useLocation()
  const values = useParams()

  const searchParams = useMemo(() => new URLSearchParams(search), [search])

  // authenticateToken calls our API to see if the auth0User exists
  const authenticateToken = useCallback(
    async (authResult) => {
      try {
        const {
          data: { authenticate: user }
        } = await authenticate({
          variables: {
            idToken: authResult?.idToken
          }
        })

        if (user) {
          auth.clearSession()
          setUserExists(user)
        }
      } catch (err) {
        const userProfileResult = await auth.getProfile(authResult.accessToken)
        setUserProfile(userProfileResult)
      }
    },
    [authenticate]
  )

  // This useEffect handles initial state when url was deep linked
  // When url is redirect from OAuth flow, validate token and
  // try to create new user

  useEffect(() => {
    const hasRedirectToken = window.location.hash.includes('id_token')

    const handleAuth0 = async (hash) => {
      // parsing hash verifies token is valid
      const authResult = await auth.parseHash(hash)
      auth.setSession(authResult)
      await authenticateToken(authResult)
    }

    if (hasRedirectToken) {
      shutdownIntercom()
      setIsAuthenticated(true)
      const hash = window.location.hash.slice(1)
      handleAuth0(hash)
    }
  }, [authenticateToken, shutdownIntercom])

  useEffect(() => {
    const productInterest = values?.product || searchParams?.get('product') || 'unify'

    if (search !== '') {
      setItem('planDetails', JSON.stringify(values))
    }
    if (searchParams?.get('product'))
      window?.sessionStorage?.setItem('product', searchParams.get('product'))
    if (searchParams?.get('api')) window?.sessionStorage?.setItem('api', searchParams.get('api'))
    if (searchParams?.get('connector'))
      window?.sessionStorage?.setItem('connector', searchParams.get('connector'))

    setProduct(productInterest)
  }, [values, searchParams, search])

  const handleCreateUser = useCallback(async () => {
    const { sub, email, name } = userProfile

    const {
      data: { createUser: user }
    } = await createUser({
      variables: {
        auth0UserId: sub,
        emailSubscription: false,
        email,
        name,
        referer
      }
    })

    if (user) {
      setAuthenticatedUser(user)
    }
  }, [userProfile, createUser, referer])

  // we don't call createUser unless we have a userProfile object
  // as a result of the authenticateToken call
  //  this can happen from user/pass submit or from a redirect from OAuth flow
  useEffect(() => {
    if (userProfile && !userExists) {
      handleCreateUser()
    }
  }, [userProfile, userExists, handleCreateUser])

  useEffect(() => {
    if (authenticatedUser) {
      if (window?.analytics) {
        window.analytics.identify(authenticatedUser.id, {
          id: authenticatedUser.id,
          email: authenticatedUser.email
        })
      }

      const options = {}
      if (searchParams?.get('product')) options.product = searchParams.get('product')
      if (searchParams?.get('api')) options.api = searchParams.get('api')
      if (searchParams?.get('connector')) options.connector = searchParams.get('connector')

      handleRedirectAfterSignup(authenticatedUser, client, options)
    }
  }, [history, authenticatedUser, client, referer, searchParams])

  // Start Google OAuth flow
  const onGoogleSubmit = async () => {
    await auth.signup(GOOGLE)
  }

  // Start GitHub OAuth flow
  const onGithubSubmit = async () => {
    await auth.signup(GITHUB)
  }

  const onUsernamePasswordSubmit = async ({ email, password }, { setSubmitting, setErrors }) => {
    if (!email || !password) {
      handleUserFormError(setErrors, 'Please enter a valid email and password')
      return
    }

    try {
      setSubmitting(true)
      const authResult = await auth.signup(USERNAME_PASSWORD, email, password, {
        newsletter: '0' // TODO newsletter opt-in removed from new signup
      })

      // we set the session early as the token needs to be sent with the call to createUser()
      auth.setSession(authResult)
      setReferer(document?.referrer)
      // TODO not clear why we try to authenticate here
      // feels like we should skip and let the createUser mutation happen
      // and return invalid when auth0UserId in not unique
      await authenticateToken(authResult)
    } catch (error) {
      setSubmitting(false)
      // handle user form fails
      handleUserFormError(setErrors, error)
    }
  }

  // We have a valid auth0UserId, but we need to check if the user exists
  const whenAuthenticated = () => {
    return userExists ? whenUserExists() : <SignupLoading />
  }

  // Apideck already has a User with the auth0UserId
  const whenUserExists = () => {
    const resetState = () => {
      setIsAuthenticated(false)
      setUserExists(false)
    }

    return <SignupUserExists resetState={resetState} />
  }

  // Present Signup Options to choose from
  const whenNotAuthenticated = () => {
    return (
      <SignupContent
        handleUsernamePasswordSubmit={onUsernamePasswordSubmit}
        handleGoogleSubmit={onGoogleSubmit}
        handleGithubSubmit={onGithubSubmit}
        product={product}
        initialValues={{
          name: '',
          email: '',
          password: ''
        }}
      />
    )
  }

  return (
    <SignupSetupLayout
      SidebarContent={<SignupSidebarContent />}
      SidebarFooter={<SignupSidebarFooter />}
    >
      <Helmet>
        <title>Signup - Apideck</title>
        <link rel="canonical" href="https://app.apideck.com/signup" />
      </Helmet>
      <SignupContainer>
        {isAuthenticated ? whenAuthenticated() : whenNotAuthenticated()}
      </SignupContainer>
    </SignupSetupLayout>
  )
}

export default withApollo(Page)
