import { fileFieldsFragment, linkFieldsFragment, translationFieldsFragment } from 'api/fragments'

import gql from 'graphql-tag'

const listingsOverviewQuery = gql`
  query listings($input: ListingsInput!) {
    listings(input: $input) {
      data {
        id
        parentId
        name
        slug
        description
        zapierId
        piesyncId
        segmentId
        blendrId
        automateId
        microsoftFlowId
        combideskId
        trayIoId
        integromatId
        logo {
          ...fileFields
        }
        links {
          ...linkFields
        }
        state
        nativeIntegration
        thirdPartyIntegration
        translations {
          ...translationFields
        }
      }
      page
      perPage
      hasMore
      total
      totalPages
    }
  }

  ${fileFieldsFragment}
  ${linkFieldsFragment}
  ${translationFieldsFragment}
`

export default listingsOverviewQuery
