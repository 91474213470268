import { Avatar, Box, Grid } from '@material-ui/core'
import { AvatarGroup, Skeleton } from '@material-ui/lab'
import { useHistory, useLocation } from 'react-router-dom'

import { Badge } from 'components'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import React from 'react'
import { useCardStyles } from 'components/cards/styles'

const ConsumerCard = ({ consumer, isLoading }) => {
  const classes = useCardStyles()
  const location = useLocation()
  const history = useHistory()

  const handleRowClick = () => {
    history.push(`${location.pathname}/${consumer.id}`)
  }

  if (isLoading) return <LoadingCard />

  return (
    <div className={classes.row} onClick={handleRowClick}>
      <Grid container>
        <Grid item sm={3} lg={3}>
          <div className={classes.flexContainer}>
            <Avatar
              src={consumer?.metadata?.image || 'https://www.gravatar.com/avatar?d=mp&f=y'}
              alt={consumer?.metadata?.userName}
              className={classes.avatar}
            />
            <div style={{ maxWidth: '100%' }}>
              <span className={classes.header}>{consumer?.metadata?.userName || 'Username'}</span>
              <span className={classes.userName}>
                {consumer?.metadata?.accountName || 'Account name'}
              </span>
            </div>
          </div>
        </Grid>
        <Grid item sm={3} className={classes.grid}>
          <Badge
            label={
              consumer.id?.length > 30
                ? `${consumer.id.slice(0, -(consumer.id?.length - 27))}...`
                : consumer.id
            }
            type="neutral"
            uppercase={false}
          />
        </Grid>
        <Grid item sm={3} className={classes.grid}>
          <strong style={{ marginRight: 3 }}>{consumer.totalRequests}</strong>
          requests
        </Grid>
        <Grid item sm={3} lg={2} className={classes.grid}>
          <div>
            {consumer.services?.length ? (
              <AvatarGroup
                max={5}
                spacing={12}
                classes={{
                  avatar: classes.connectionCount
                }}
              >
                {consumer.services.map((serviceId, i) => {
                  return (
                    <Avatar
                      src={`https://res.cloudinary.com/apideck/icons/${serviceId}`}
                      alt={serviceId}
                      className={classes.connection}
                      key={i}
                    />
                  )
                })}
              </AvatarGroup>
            ) : (
              <span>
                <strong style={{ marginRight: 3 }}>0</strong>
                connections
              </span>
            )}
          </div>
        </Grid>
        <Grid item sm={1} className={classes.grid}>
          <Box display="flex" justifyContent="flex-end" width="100%">
            <ChevronRightIcon fontSize="small" />
          </Box>
        </Grid>
      </Grid>
    </div>
  )
}

const LoadingCard = () => {
  const classes = useCardStyles()

  return (
    <div className={classes.row}>
      <Grid container>
        <Grid item sm={4} lg={5}>
          <div className={classes.flexContainer}>
            <Skeleton variant="circle" width={40} height={40} style={{ marginRight: 15 }} />
            <div>
              <span className={classes.header}>
                <Skeleton display="block" width={90} />
              </span>
              <span className={classes.userName}>
                <Skeleton display="block" width={150} />
              </span>
            </div>
          </div>
        </Grid>
        <Grid item sm={2} className={classes.grid}>
          <Skeleton variant="text" width={100} />
        </Grid>
        <Grid item sm={3} lg={2} className={classes.grid}>
          <Skeleton variant="text" width={100} />
        </Grid>
        <Grid item sm={2} className={classes.grid}>
          <Skeleton variant="text" width={120} />
        </Grid>
        <Grid item sm={1} className={classes.grid}>
          <Skeleton display="block" width={20} />
        </Grid>
      </Grid>
    </div>
  )
}

ConsumerCard.defaultProps = {
  consumer: null
}

export default ConsumerCard
