import { Icon, LetterAvatar } from 'components'

import PropTypes from 'prop-types'
import React from 'react'
import styled from 'react-emotion'

const H3 = styled('h3')`
  display: inline-flex;
  align-items: center;
  margin-bottom: 0;

  span {
    margin-left: 10px;
  }
`

const H4 = styled('h4')`
  color: #999;
  margin-top: 10px;
  font-weight: normal;
  font-size: 15px;

  a {
    text-decoration: none;
    color: #999;
  }
`

const IconWrapper = styled('div')`
  margin: 10px 20px 0 0;
`

const HeaderTitle = ({ imageTitle, imageSrc, title, subtitle, badge }) => (
  <div style={{ display: 'flex' }}>
    <IconWrapper>
      {imageSrc ? (
        <Icon src={imageSrc} alt={imageTitle ?? ''} size={72} />
      ) : (
        <LetterAvatar title={typeof title === 'string' ? title : ''} size={72} />
      )}
    </IconWrapper>
    <div>
      <H3>
        {title}
        {badge && <span>{badge}</span>}
      </H3>
      <H4>{subtitle}</H4>
    </div>
  </div>
)

HeaderTitle.defaultProps = {
  badge: null
}

HeaderTitle.propTypes = {
  imageSrc: PropTypes.string,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.node,
  imageTitle: PropTypes.string,
  badge: PropTypes.element
}

export default HeaderTitle
