import React from 'react'
import { InvitationForm, Card } from 'components'
import PropTypes from 'prop-types'

const Create = ({ handleSubmit, initialValues }) => (
  <Card>
    <h1>Create invitation</h1>
    <InvitationForm initialValues={initialValues} handleSubmit={handleSubmit} />
  </Card>
)

Create.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired
}

export default Create
