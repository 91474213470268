import React, { useState } from 'react'
import { hasPath, path } from 'ramda'

import Network from './network/Network'
import NetworkContainer from 'features/network/Container'
import NetworkFilters from 'features/network/Filters'
import PropTypes from 'prop-types'
import Services from './Services'
import { useDebounce } from 'use-debounce'

const PER_PAGE = 200

function ServicesFromNetwork(props) {
  const [searchText, _setSearchText] = useState()
  const [integrationPlatform, _setIntegrationPlatform] = useState()
  const [debouncedSearchText] = useDebounce(searchText, 1000)

  const setSearchText = (value) => {
    _setSearchText(value)
  }

  const setIntegrationPlatform = (value) => {
    _setIntegrationPlatform(value)
  }

  return (
    <NetworkContainer
      accountId={props.activeAccount.id}
      ecosystemId={props.ecosystem.id}
      filters={{
        integrationPlatform,
        text: debouncedSearchText
      }}
      page={1}
      perPage={PER_PAGE}
    >
      {({ deleteListing, importService, services: { loading, data, fetchMore } }) => {
        const loadNextPage = async () => {
          try {
            await fetchMore({
              variables: {
                filterIntegrationPlatform: integrationPlatform,
                filterText: searchText,
                page: data.services.page + 1,
                perPage: PER_PAGE
              },
              updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prev
                let services = []
                const hasNewServices =
                  fetchMoreResult && hasPath(['services', 'services'], fetchMoreResult)

                if (hasNewServices) {
                  services = path(['services', 'services'], fetchMoreResult)
                }

                return Object.assign({}, prev, {
                  services: {
                    hasMore: fetchMoreResult.services.hasMore,
                    page: fetchMoreResult.services.page,
                    perPage: fetchMoreResult.services.perPage,
                    services: [...prev.services.services, ...services],
                    total: fetchMoreResult.services.total,
                    totalPages: fetchMoreResult.services.totalPages,
                    __typename: 'ServicePage'
                  }
                })
              }
            })
          } catch (error) {
            console.log(error)
          }
        }

        const handleDelete = async (service) => {
          await deleteListing.mutation({
            variables: {
              accountId: props.activeAccount.id,
              id: service.listingId
            }
          })
        }

        const handleImport = async (service) => {
          await importService.mutation({
            variables: {
              accountId: props.activeAccount.id,
              ecosystemId: props.ecosystem.id,
              id: service.id
            }
          })
        }

        const handleServiceClick = async (service) => {
          if (service.listingId) {
            await handleDelete(service)
          } else {
            await handleImport(service)
          }
        }

        // by default return 0 services
        let services = []

        // check if we have already have services and use them
        const hasServices = data && hasPath(['services', 'services'], data)
        if (hasServices) {
          services = path(['services', 'services'], data)
        }

        const total = loading ? 50000 : data.services.total
        let hasMore = true
        if (data && data.services) {
          hasMore = data.services.hasMore
        }

        return (
          <Services
            cloudService={props.cloudService}
            Content={
              <Network
                handleServiceClick={handleServiceClick}
                hasMore={hasMore}
                listings={props.ecosystem.listings}
                loading={loading}
                loadNextPage={loadNextPage}
                searching={loading && !!debouncedSearchText}
                services={services}
                total={total}
              />
            }
            Filter={
              <NetworkFilters
                handleIntegrationFilterChange={setIntegrationPlatform}
                handleTextFilterChange={setSearchText}
                searchText={searchText}
              />
            }
            ecosystem={props.ecosystem}
            handleComplete={props.handleComplete}
          />
        )
      }}
    </NetworkContainer>
  )
}

ServicesFromNetwork.propTypes = {
  activeAccount: PropTypes.object.isRequired,
  cloudService: PropTypes.object,
  ecosystem: PropTypes.object.isRequired,
  handleComplete: PropTypes.func.isRequired
}

export default ServicesFromNetwork
