import React from 'react'
import PropTypes from 'prop-types'
import NetworkGrid from './Grid'
import { EmptyState, Loader } from 'components'

function NetworkContent(props) {
  const { handleServiceClick, hasMore, loading, loadNextPage, searching, services, total } = props

  if (props.searching || (services.length === 0 && loading)) {
    return <Loader />
  }

  if (services.length === 0 && !loading && !searching) {
    return <EmptyState>No Services found.</EmptyState>
  }

  return (
    <NetworkGrid
      isNextPageLoading={loading}
      handleServiceClick={handleServiceClick}
      hasNextPage={hasMore}
      loadNextPage={loadNextPage}
      services={services}
      total={total}
    />
  )
}

NetworkContent.propTypes = {
  handleServiceClick: PropTypes.func.isRequired,
  hasMore: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  loadNextPage: PropTypes.func.isRequired,
  searching: PropTypes.bool.isRequired,
  services: PropTypes.array,
  total: PropTypes.number.isRequired
}

export default NetworkContent
