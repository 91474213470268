import { ContentContainer, ListingHeader } from 'components'
import {
  ListingOverviewContainer as OverviewContainer,
  ListingSettingsContainer as SettingsContainer
} from 'containers'
import { Switch, withRouter } from 'react-router-dom'

import PrivateRoute from 'components/PrivateRoute'
import PropTypes from 'prop-types'
import React from 'react'

const Layout = ({
  match,
  listing,
  comments,
  activeAccount,
  marketplace,
  user,
  handleTogglePublishListing
}) => (
  <ContentContainer
    header={
      <ListingHeader
        listing={listing}
        marketplace={marketplace}
        handleTogglePublishListing={handleTogglePublishListing}
        activeAccount={activeAccount}
      />
    }
  >
    <Switch>
      <PrivateRoute
        path={`${match.path}/overview`}
        basePath={match.url}
        component={OverviewContainer}
        listing={listing}
        comments={comments}
        user={user}
        marketplace={marketplace}
        activeAccount={activeAccount}
      />
      <PrivateRoute
        path={`${match.path}/settings`}
        component={SettingsContainer}
        listing={listing}
        ecosystem={marketplace}
        activeAccount={activeAccount}
      />
    </Switch>
  </ContentContainer>
)

Layout.propTypes = {
  listing: PropTypes.object.isRequired,
  handleTogglePublishListing: PropTypes.func.isRequired,
  comments: PropTypes.array
}

const ListingLayout = withRouter(Layout)

export default ListingLayout
