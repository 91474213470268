import React from 'react'
import IubendaLogo from 'assets/iubenda-logo.png'
import DriftLogo from 'assets/drift-logo.png'
import IntercomLogo from 'assets/intercom-logo.svg'
import GoogleAnalyticsLogo from 'assets/google-analytics-logo.png'
import GoogleTagManagerLogo from 'assets/google-tag-manager.png'
import HeapLogo from 'assets/heap.png'
import integrationPlatforms from 'constants/integrationPlatforms'
import AlgoliaLogo from 'assets/algolia.svg'
import AlbacrossLogo from 'assets/albacross-logo.png'
import CrispLogo from 'assets/crisp-logo.png'
import HubspotLogo from 'assets/hubspot.svg.png'
import LivechatLogo from 'assets/livechat-logo.svg'
import JournyIoLogo from 'assets/journy-io.png'
import MetomicLogo from 'assets/metomic.svg'
import OnetrustLogo from 'assets/onetrust.svg'
import TrayIoLogo from 'assets/tray-io.png'

const paths = {
  algolia: {
    label: 'Algolia',
    src: AlgoliaLogo,
    width: '100px'
  },
  albacross: {
    label: 'Albacross',
    src: AlbacrossLogo,
    width: '100px'
  },
  zapier: {
    label: 'Zapier',
    src: integrationPlatforms['zapier'].logo,
    width: '100px'
  },
  piesync: {
    label: 'Piesync',
    src: integrationPlatforms['piesync'].logo,
    width: '140px'
  },
  blendr: {
    label: 'Blendr',
    src: integrationPlatforms['blendr'].logo,
    width: '120px'
  },
  segment: {
    label: 'Segment',
    src: integrationPlatforms['segment'].logo,
    width: '140px'
  },
  apiant: {
    label: 'Apiant',
    src: integrationPlatforms['apiant'].logo,
    width: '140px'
  },
  automate: {
    label: 'Automate.io',
    src: integrationPlatforms['automate'].logo,
    width: '140px'
  },
  integromat: {
    label: 'Integromat',
    src: integrationPlatforms['integromat'].logo,
    width: '140px'
  },
  workato: {
    label: 'Workato',
    src: integrationPlatforms['workato'].logo,
    width: '140px'
  },
  ifttt: {
    label: 'IFFFT',
    src: integrationPlatforms['ifttt'].logo,
    width: '140px'
  },
  'microsoft-flow': {
    label: 'Microsoft Flow',
    src: integrationPlatforms['microsoft-flow'].logo,
    width: '140px'
  },
  combidesk: {
    label: 'Combidesk',
    src: integrationPlatforms['combidesk'].logo,
    width: '140px'
  },
  iubenda: {
    label: 'Iubenda',
    src: IubendaLogo,
    width: '120px'
  },
  metomic: {
    label: 'Metomic',
    src: MetomicLogo,
    width: '120px'
  },
  onetrust: {
    label: 'OneTrust',
    src: OnetrustLogo,
    width: '120px'
  },
  drift: {
    label: 'Drift',
    src: DriftLogo,
    width: '100px'
  },
  intercom: {
    label: 'Intercom',
    src: IntercomLogo,
    width: '140px'
  },
  crisp: {
    label: 'Crisp',
    src: CrispLogo,
    width: '120px'
  },
  journyIo: {
    label: 'Journy.io',
    src: JournyIoLogo,
    width: '120px'
  },
  googleAnalytics: {
    label: 'Google Analytics',
    src: GoogleAnalyticsLogo,
    width: '150px'
  },
  googleTagManager: {
    label: 'Google Tag Manager',
    src: GoogleTagManagerLogo,
    width: '190px'
  },
  heap: {
    label: 'Heap Analytics',
    src: HeapLogo,
    width: '140px'
  },
  hubspot: {
    label: 'HubSpot',
    src: HubspotLogo,
    width: '140px'
  },
  livechat: {
    label: 'Livechat',
    src: LivechatLogo,
    width: '120px'
  },
  'tray-io': {
    label: 'Tray.io',
    src: TrayIoLogo,
    width: '120px'
  }
}

const Logo = ({ type, ...rest }) => (
  <img
    src={paths[type] && paths[type].src}
    alt={paths[type] && paths[type].label}
    width={paths[type] && paths[type].width}
    {...rest}
  />
)

export default Logo
