import {
  DetailPageDisabled,
  IntegrationLinksFieldset,
  LogoField,
  NameField,
  PageContentFieldset,
  SeoFieldset,
  SlugField,
  StickyField,
  StyleFieldset,
  TagLineField,
  UpcomingField,
  baseValidationSchema
} from './Base'
import { Fieldset, ListingDelete, Submit } from 'components'

import { Formik } from 'formik'
import PropTypes from 'prop-types'
import React from 'react'
import ecosystemUrl from 'utils/ecosystemUrl'

export const ListingForm = ({ initialValues, handleSubmit, ecosystem, handleDelete }) => (
  <Formik
    validationSchema={baseValidationSchema}
    initialValues={initialValues}
    onSubmit={handleSubmit}
  >
    {(formikProps) => {
      const { handleSubmit, isSubmitting } = formikProps
      return (
        <form onSubmit={handleSubmit}>
          <Fieldset title="Basic info">
            <NameField formikProps={formikProps} />
            <SlugField
              formikProps={formikProps}
              ecosystemUrl={`${ecosystemUrl(ecosystem)}/listing/`}
            />
            <TagLineField formikProps={formikProps} ecosystemName={ecosystem.name} />
            <LogoField formikProps={formikProps} />
            <StickyField formikProps={formikProps} />
            <UpcomingField formikProps={formikProps} />
            <DetailPageDisabled formikProps={formikProps} />
          </Fieldset>
          <PageContentFieldset formikProps={formikProps} ecosystem={ecosystem} />
          <StyleFieldset formikProps={formikProps} />
          <IntegrationLinksFieldset formikProps={formikProps} ecosystem={ecosystem} />
          <SeoFieldset formikProps={formikProps} />
          <ListingDelete handleDelete={handleDelete} />
          <div className="sticky-submit-container">
            <Submit isSubmitting={isSubmitting} text="Update" />
          </div>
        </form>
      )
    }}
  </Formik>
)

ListingForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  listing: PropTypes.object,
  initialValues: PropTypes.object
}

export default ListingForm
