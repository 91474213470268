import { Card, ContentContainer } from 'components'
import PropTypes from 'prop-types'
import React from 'react'
import NetworkHeader from './Header'

function NetworkOverview({ ecosystem, Filter, Grid }) {
  return (
    <ContentContainer header={<NetworkHeader ecosystemId={ecosystem.id} />}>
      <Card
        left={Filter}
        title="Use the Apideck Network to look up and import listings you want to add to your ecosystem."
      >
        {Grid}
      </Card>
    </ContentContainer>
  )
}

NetworkOverview.propTypes = {
  Filter: PropTypes.element.isRequired,
  Grid: PropTypes.element.isRequired
}

export default NetworkOverview
