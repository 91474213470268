import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import { withStyles } from '@material-ui/core/styles'
import { Centered } from 'components'
import PropTypes from 'prop-types'
import React from 'react'

import { removeItem } from 'utils/localstorage'

const ACCOUNT_TITLE = 'Choose an Account'

const styles = (theme) => ({
  root: {
    width: '100%',
    maxWidth: '360px',
    backgroundColor: theme.palette.background.paper
  }
})

const AccountPortal = ({ user, setActiveAccount }) => {
  return (
    <Centered>
      {user && user.accounts && (
        <AccountsSection accounts={user.accounts} setActiveAccount={setActiveAccount} />
      )}
    </Centered>
  )
}

const AccountsSection = ({ accounts, setActiveAccount }) => (
  <div>
    <h3>{ACCOUNT_TITLE}</h3>
    <List component="nav">
      {accounts &&
        accounts.map((accountUser) => (
          <AccountItem
            key={accountUser.id}
            account={accountUser.account}
            onClick={() => {
              removeItem('activeEcosystemId')
              setActiveAccount(accountUser)
            }}
          />
        ))}
    </List>
  </div>
)

const AccountItem = ({ account, onClick }) => (
  <ListItem data-testid={`account_${account.id}`} button onClick={onClick}>
    <ListItemText inset primary={account.name} />
  </ListItem>
)

AccountPortal.propTypes = {
  user: PropTypes.object.isRequired
}

export default withStyles(styles)(AccountPortal)
