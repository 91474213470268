import { CategoryHeader as Header, ContentContainer } from 'components'
import PrivateRoute from 'components/PrivateRoute'
import {
  CategoryOverviewContainer as OverviewContainer,
  CategorySettingsContainer as SettingsContainer
} from 'containers'
import React from 'react'
import { Switch, withRouter } from 'react-router-dom'

const CategoryLayout = ({ match, activeAccount, category, ecosystem, listings }) => (
  <ContentContainer header={<Header category={category} ecosystem={ecosystem} />}>
    <Switch>
      <PrivateRoute
        path={`${match.path}/overview`}
        component={OverviewContainer}
        category={category}
        activeAccount={activeAccount}
        ecosystem={ecosystem}
        listings={listings}
      />
      <PrivateRoute
        path={`${match.path}/settings`}
        component={SettingsContainer}
        category={category}
        activeAccount={activeAccount}
        ecosystem={ecosystem}
      />
    </Switch>
  </ContentContainer>
)

export default withRouter(CategoryLayout)
