import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'

const Thumbnail = ({ file }) => {
  const [loading, setLoading] = useState(false)
  const [preview, setPreview] = useState()
  const fileReader = useRef(new FileReader())

  useEffect(() => {
    if (!file.url) {
      const reader = fileReader.current

      reader.onloadend = () => {
        setLoading(false)
        setPreview(reader.result)
      }

      setLoading(true)
      reader.readAsDataURL(file)

      return
    }

    setLoading(false)
    setPreview(file.url)
  }, [file])

  if (loading) {
    return <p>loading</p>
  }

  return (
    <img
      src={preview}
      alt={file.name}
      className="img-thumbnail mt-4"
      style={{ maxWidth: '250px', maxHeight: '100px' }}
    />
  )
}

Thumbnail.propTypes = {
  file: PropTypes.object.isRequired
}

export default Thumbnail
