import React from 'react'
import PropTypes from 'prop-types'
import styled from 'react-emotion'
import LetterAvatar from './LetterAvatar'

const IconWrapper = styled('div')`
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #eee;
  border-radius: 5px;

  img {
    width: 80px;
    border-radius: 5px;
  }
`

const Icon = ({ src, alt, size = 80, padding = size / 4, style }) =>
  src ? (
    <IconWrapper style={{ height: size, width: size, padding: padding + 'px', ...style }}>
      <img src={src} alt={alt} style={{ width: size, maxHeight: size }} />
    </IconWrapper>
  ) : (
    <LetterAvatar style={{ ...style, height: size, width: size }} size={size} title={alt} />
  )

Icon.propTypes = {
  alt: PropTypes.string.isRequired
}

export default Icon
