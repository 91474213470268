import React, { Fragment, useEffect, useState } from 'react'
import { Link, NavLink, useHistory, useLocation, useRouteMatch } from 'react-router-dom'
import { getItem, setItem } from 'utils/localstorage'

import Tooltip from '@material-ui/core/Tooltip'
import AddIcon from '@material-ui/icons/Add'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore'
import { ExternalA } from 'components'
import { default as Dropdown } from 'components/common/Dropdown'
import { default as styled } from 'react-emotion'
import { useIntercom } from 'react-use-intercom'
import ecosystemUrl from 'utils/ecosystemUrl'
import { getEcosystemUrl, getUnifyUrl } from 'utils/getUnifyUrl'

const Sidebar = ({ user, activeAccount }) => {
  const { show, showNewMessages } = useIntercom()
  const [loading, setLoading] = useState(true)
  const [activeEcosystem, setActiveEcosystem] = useState(null)
  const { pathname } = window.location
  const [whichMenuIsOpen, setMenuOpen] = useState('ecosystem')
  const match = useRouteMatch()
  const { search } = useLocation()
  const params = new URLSearchParams(search)

  const { subscriptionStatus, plan } = activeAccount

  let dashboardAccess = true

  if (subscriptionStatus === 'TRIAL' || plan === 'FREE' || !plan) {
    dashboardAccess = false
  }

  if (pathname.includes('unify') && whichMenuIsOpen !== 'unify') {
    setMenuOpen('unify')
  }

  const ecosystems = activeAccount.marketplaces
  const handleGetHelp = (e) => {
    e.preventDefault()
    show()
  }

  const handleUpgradeRequest = (e, msg) => {
    e.preventDefault()
    show()
    showNewMessages(msg)
  }

  useEffect(() => {
    const getActiveEcosystemId = async () => {
      const activeEcosystemId =
        match.params.ecosystemId || params.get('ecosystem_id') || getItem('activeEcosystemId')
      if (activeEcosystemId) {
        setItem('activeEcosystemId', activeEcosystemId)
        setActiveEcosystem(ecosystems.find((ecosystem) => ecosystem.id === activeEcosystemId))
      } else {
        const firstEcosystem = ecosystems[0]
        setActiveEcosystem(typeof firstEcosystem !== 'undefined' ? firstEcosystem : null)
      }
      setLoading(false)
    }

    getActiveEcosystemId()
  }, [ecosystems, match.params.ecosystemId])

  return (
    <SidebarWrapper>
      {!loading && (
        <>
          <Link
            className="logo-container"
            to={activeEcosystem ? `/ecosystems/${activeEcosystem.id}` : '/'}
          >
            <img src="/images/logo-white.svg" alt="Apideck" />
          </Link>
          <div className="content">
            {activeEcosystem ? (
              <Fragment>
                <Dropdown
                  testid="ecosystem-dropdown"
                  placement="bottom-end"
                  renderContent={({ handleClose }) => (
                    <EcosystemMenuWrapper
                      currentEcosystem={activeEcosystem} // current ecosystem
                      setActiveEcosystem={setActiveEcosystem}
                      ecosystems={ecosystems}
                      handleClose={handleClose}
                      setMenuOpen={setMenuOpen}
                    />
                  )}
                >
                  <div className="ecosystem-container" onClick={(e) => e.preventDefault()}>
                    {activeEcosystem.icon ? (
                      <img src={activeEcosystem.icon.url} alt="icon" />
                    ) : (
                      activeEcosystem.favicon && (
                        <img src={activeEcosystem.favicon.url} alt="favicon" />
                      )
                    )}
                    <div className="name truncate">{activeEcosystem.name}</div>
                    <div className="icon-container">
                      <ArrowDropDownIcon style={{ color: 'currentColor' }} />
                    </div>
                  </div>
                </Dropdown>
                <nav>
                  <ul className="main">
                    <li>
                      <a
                        href={getEcosystemUrl('/listings')}
                        activeClassName="active"
                        data-testid="ecosystem-listings-nav-link"
                      >
                        Listings
                      </a>
                    </li>
                    <li>
                      <a
                        href={getEcosystemUrl('/leads/votes')}
                        activeClassName="active"
                        data-testid="ecosystem-voting-portal-nav-link"
                      >
                        Voting Portal
                      </a>
                    </li>
                    <li>
                      <a
                        href={getEcosystemUrl('/leads')}
                        activeClassName="active"
                        data-testid="ecosystem-leads-nav-link"
                      >
                        Leads
                      </a>
                    </li>
                    <li>
                      {plan === 'ENTERPRISE' ? (
                        <a
                          href={getEcosystemUrl('/partners')}
                          activeClassName="active"
                          data-testid="ecosystem-partners-nav-link"
                        >
                          Partners
                        </a>
                      ) : (
                        <Tooltip
                          title="Upgrade to the enterprise plan to unlock this feature"
                          placement="right"
                          arrow
                        >
                          <a
                            href="#partners"
                            className="fakeLink"
                            onClick={(e) =>
                              handleUpgradeRequest(
                                e,
                                'I would like to know more about the enterprise plan.'
                              )
                            }
                          >
                            Partners
                          </a>
                        </Tooltip>
                      )}
                    </li>
                    <li>
                      {dashboardAccess ? (
                        <a
                          href={getEcosystemUrl('/dashboard')}
                          activeClassName="active"
                          data-testid="ecosystem-dashboard-nav-link"
                        >
                          Dashboard
                        </a>
                      ) : (
                        <Tooltip
                          title="Upgrade to a paid plan to unlock this feature"
                          placement="right"
                          arrow
                        >
                          <a
                            href="#dashboard"
                            className="fakeLink"
                            onClick={(e) =>
                              handleUpgradeRequest(
                                e,
                                'I would like to know more about the upgrading my plan.'
                              )
                            }
                          >
                            Dashboard
                          </a>
                        </Tooltip>
                      )}
                    </li>
                    <li>
                      <NavLink
                        to={`/ecosystems/${activeEcosystem.id}/settings`}
                        activeClassName="active"
                        data-testid="ecosystem-settings-nav-link"
                      >
                        Settings
                      </NavLink>
                    </li>
                    <li>
                      <ExternalA href={`${ecosystemUrl(activeEcosystem)}?_vercel_no_cache=1`}>
                        <span>View Ecosystem</span>
                        <div className="icon-container" style={{ float: 'right' }}>
                          <OpenInNewIcon style={{ color: 'currentColor', fontSize: 16 }} />
                        </div>
                      </ExternalA>
                    </li>
                    <li>
                      <a href={getUnifyUrl()} onClick={() => setItem('lastProductUsed', 'unify')}>
                        Unify
                      </a>
                    </li>
                  </ul>

                  <div className="divider" />
                  <ul className="extra">
                    <li>
                      <div className="extra-link" onClick={(e) => handleGetHelp(e)}>
                        <span>Need Help?</span>
                        <div className="icon-container">
                          <HelpOutlineIcon style={{ color: 'currentColor', fontSize: 16 }} />
                        </div>
                      </div>
                    </li>
                  </ul>
                </nav>
              </Fragment>
            ) : (
              <nav>
                <span className="menu-title">Ecosystem</span>
                <ul className="main">
                  <li>
                    <a href={`${process.env.REACT_APP_V2_URL}/onboarding/new`}>
                      + Create ecosystem
                    </a>
                  </li>
                </ul>
              </nav>
            )}
          </div>
          <div className="user-wrapper">
            <Dropdown
              testid="user-dropdown"
              placement="top-end"
              renderContent={({ handleClose }) => (
                <UserMenuWrapper
                  user={user}
                  activeAccount={activeAccount}
                  handleClose={handleClose}
                />
              )}
            >
              <div className="user-container" onClick={(e) => e.preventDefault()}>
                <div className="meta-container">
                  <div>
                    <div className="account">{activeAccount.name}</div>
                    <div className="name truncate">{user.name ? user.name : user.email}</div>
                  </div>
                </div>
                <div className="icon-container">
                  <UnfoldMoreIcon style={{ color: 'currentColor' }} />
                </div>
              </div>
            </Dropdown>
          </div>
        </>
      )}
    </SidebarWrapper>
  )
}

const SidebarWrapper = styled('aside')`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 280px;
  min-width: 280px;
  background-color: var(--sidebar-background-color);
  padding: 30px 10px;
  font-family: var(--font-family-basier);
  line-height: 1;

  .logo-container {
    display: inline-block;
    padding: 0 15px;
    margin-bottom: 20px;

    img {
      width: 100px;
    }
  }

  .content {
    overflow-y: auto;
    padding-bottom: 100px;
  }

  div.ecosystem-container {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 5px 15px;
    margin-top: 20px;
    font-size: 15px;
    font-weight: 500;
    color: var(--color-white);

    &:hover {
      .icon-container {
        color: var(--color-white);
      }
    }

    img {
      width: 36px;
      height: 36px;
      border-radius: 4px;
      margin-right: 10px;
    }

    .name {
      height: 17px;
    }

    .icon-container {
      transition: 150ms;
      margin-top: 2px;
      color: var(--sidebar-icon-color);
    }
  }

  .user-wrapper {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(180deg, rgba(7, 11, 75, 0) 0%, rgba(7, 11, 75, 1) 20%);

    .plan-message-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 20px;
      color: var(--sidebar-secondary-color);
      font-size: 12px;
      text-transform: uppercase;
      font-weight: 500;
      margin-top: 40px;

      a {
        display: inline-block;
        background-color: var(--color-secondary);
        font-size: 10px;
        font-weight: 500;
        text-transform: uppercase;
        color: var(--color-white);
        border-radius: 10px;
        padding: 3px 10px;
        padding-top: 4px;
        transition: 300ms;

        &:hover {
          background-color: var(--color-primary);
        }
      }
    }

    .progress-bar-container {
      margin-bottom: -10px;
      padding: 0 20px;
    }
  }

  div.user-container {
    margin: 25px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--sidebar-user-background-color);
    border-radius: 3px;
    padding: 10px 15px;
    padding-right: 5px;
    transition: 150ms;

    &:hover {
      background-color: var(--sidebar-user-hover-background-color);

      .icon-container {
        color: var(--color-white);
      }
    }

    .meta-container {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }

    img {
      width: 36px;
      height: 36px;
      border-radius: 36px;
      margin-right: 10px;
    }

    .account {
      color: var(--color-white);
      opacity: 0.5;
      text-transform: uppercase;
      font-size: 10px;
      margin: 2px 0 4px;
    }

    .name {
      color: var(--color-white);
      font-size: 14px;
      font-weight: 500;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 150px;
      height: 17px;
    }

    .icon-container {
      transition: 150ms;
      color: var(--sidebar-icon-color);
    }
  }

  nav {
    margin-top: 20px;

    ul.main {
      list-style: none;
      padding: 0;

      li {
        a {
          border-radius: 3px;
          display: block;
          padding: 12px 15px;
          transition: none;
          color: var(--color-white);
          font-size: 15px;

          &:hover {
            background-color: var(--sidebar-menu-item-hover-background-color);
          }

          &.active {
            border-left: 4px solid var(--color-primary);
            background-color: var(--sidebar-menu-item-active-background-color);
            padding-left: 11px;
            padding-right: 11px;
          }

          &.fakeLink {
            opacity: 0.35;
            cursor: default;

            &:hover {
              background-color: transparent;
            }
          }
        }
      }
    }

    ul.extra {
      list-style: none;
      padding: 0;

      li {
        div.extra-link {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          padding: 10px 15px;
          font-size: 14px;
          transition: 150ms;
          color: var(--sidebar-menu-item-extra-color);

          &:hover {
            color: var(--color-white);

            .icon-container {
              color: var(--color-white);
            }
          }

          span {
            margin-right: 5px;
          }

          .icon-container {
            color: var(--sidebar-icon-color);
            line-height: 0;
            transition: 150ms;
          }
        }
      }
    }
  }

  .menu-title {
    margin-left: 15px;
    text-transform: uppercase;
    color: var(--sidebar-secondary-color);
    font-size: 12px;
  }

  .divider {
    margin: 15px;
    border-top: 1px solid var(--sidebar-divider-color);
  }
`

const EcosystemMenuWrapper = ({
  currentEcosystem,
  ecosystems,
  setActiveEcosystem,
  setMenuOpen,
  handleClose
}) => {
  const history = useHistory()
  const handleChangeEcosystem = (e, ecosystem) => {
    e.preventDefault()
    setActiveEcosystem(ecosystem)
    setMenuOpen('unify')
    setItem('activeEcosystemId', ecosystem.id)
    handleClose()
    history.push(`/ecosystems/${ecosystem.id}/`)
  }

  return (
    <DropdownContainer>
      <ul className="ecosystem-menu">
        {ecosystems.map((ecosystem) => (
          <li key={ecosystem.id}>
            <div
              className="ecosystem-menu-link"
              onClick={(e) => handleChangeEcosystem(e, ecosystem)}
            >
              <div className="meta-container">
                {ecosystem.icon ? (
                  <img src={ecosystem.icon.url} alt="icon" />
                ) : ecosystem.favicon ? (
                  <img src={ecosystem.favicon.url} alt="favicon" />
                ) : (
                  <div className="icon" />
                )}
                <div>
                  <div data-testid={`ecosystem_${ecosystem.id}`} className="name truncate">
                    {ecosystem.name}
                  </div>
                  {/* <div className='listings'>
                    {ecosystem && ecosystem.listingCount || 0} listings
                  </div> */}
                </div>
              </div>
              <div className="icon-container">
                {currentEcosystem.id === ecosystem.id && (
                  <CheckCircleIcon style={{ color: 'currentColor', fontSize: 16 }} />
                )}
              </div>
            </div>
          </li>
        ))}
        <li>
          <a href={`${process.env.REACT_APP_V2_URL}/onboarding/new`}>
            <div className="meta-container">
              <div className="icon">
                <AddIcon style={{ color: 'currentColor', fontSize: 16 }} />
              </div>
              <div>
                <div className="name" data-testid="create-ecosystem">
                  Create new ecosystem
                </div>
              </div>
            </div>
          </a>
        </li>
      </ul>
    </DropdownContainer>
  )
}

const UserMenuWrapper = ({ user, activeAccount, handleClose }) => {
  return (
    <DropdownContainer>
      <div className="title">{activeAccount.name}</div>
      <div className="title subtitle">ID: {activeAccount.id}</div>
      <ul>
        <li>
          <Link to="/account/users" onClick={() => handleClose()} data-testid="account-users-link">
            Users
          </Link>
        </li>
        <li>
          <Link
            to="/account/invitations"
            onClick={() => handleClose()}
            data-testid="account-invitations-link"
          >
            Invitations
          </Link>
        </li>
        <li>
          <Link
            to="/account/settings"
            onClick={() => handleClose()}
            data-testid="account-settings-link"
          >
            Settings
          </Link>
        </li>
        <li>
          <a
            href="https://billing.stripe.com/p/login/5kA6pUbtjc9o7Di288"
            onClick={() => handleClose()}
            target="_blank"
            rel="noopener noreferrer"
            data-testid="account-invoices-link"
          >
            Invoices
          </a>
        </li>
      </ul>
      <div className="divider" />
      <ul>
        {user && user.accounts && user.accounts.length > 1 && (
          <li>
            <Link
              to="/accounts/portal"
              onClick={() => handleClose()}
              data-testid="switch-accounts-link"
            >
              Switch accounts
            </Link>
          </li>
        )}
        <li>
          <Link
            to="/account/profile/user"
            onClick={() => handleClose()}
            data-testid="account-profile-link"
          >
            Your Profile
          </Link>
        </li>
        <li>
          <a
            href={`${process.env.REACT_APP_V2_URL}/api/auth/logout`}
            className="logout-link"
            data-testid="account-logout-link"
          >
            Logout
          </a>
        </li>
      </ul>
    </DropdownContainer>
  )
}

const DropdownContainer = styled('div')`
  max-height: 380px;
  max-width: 275px;
  overflow-y: auto;

  ul.ecosystem-menu {
    li:last-of-type {
      .ecosystem-menu-link {
        border-bottom: none;
      }
    }

    .ecosystem-menu-link {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px;
      border-bottom: 1px solid var(--color-light);

      &:hover {
        cursor: pointer;
        .icon {
          background-color: var(--color-white);
        }
      }
    }

    .meta-container {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    img,
    .icon {
      width: 30px;
      height: 30px;
      border-radius: 4px;
      margin-right: 10px;
    }

    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: var(--color-light);
      color: var(--sidebar-text-color);
    }

    .name {
      font-weight: 500;
      line-height: 1;
      margin-bottom: 3px;
      height: 15px;
    }

    .listings {
      font-size: 11px;
      font-weight: 600;
      line-height: 1;
      color: var(--sidebar-text-color);
    }

    .icon-container {
      line-height: 1;
      margin-left: 10px;
      color: var(--color-primary);
      min-width: 16px;
    }
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .title {
    margin-top: 5px;
    text-transform: uppercase;
    font-size: 11px;
    padding: 10px 10px 0px 10px;
    font-weight: 600;
    line-height: 1;
    color: var(--sidebar-text-color);
  }

  .subtitle {
    font-size: 9px;
    margin-top: 0;
    margin-bottom: 5px;
    text-transform: none;
  }

  .divider {
    width: calc(100% + 5px);
    border-top: 1px solid var(--color-light);
    margin: 7px auto;
    transform: translateX(-5px);
  }

  a,
  .ecosystem-menu-link,
  .logout-link {
    cursor: pointer;
    display: block;
    padding: 5px 10px;
    font-size: 14px;
    font-weight: 500;
    color: var(--color-dark);

    &:hover {
      border-radius: 3px;
      background-color: var(--dropdown-link-hover-background-color);
    }
  }
`

export default Sidebar
