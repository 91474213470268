import { DeleteDialog, Table } from 'components'
import React, { useMemo } from 'react'

import Checkbox from '@material-ui/core/Checkbox'
import MuiChip from '@material-ui/core/Chip'
import PropTypes from 'prop-types'

function normalizeUserData(users) {
  return users.map(({ id, user, role, notificationPreferences }) => {
    const preferences = notificationPreferences ?? {}
    delete preferences['__typename']

    return {
      id,
      name: user.name,
      email: user.email,
      role,
      notificationPreferences: preferences ?? []
    }
  })
}

const insert = (arr, index, newItem) => [
  // part of the array before the specified index
  ...arr.slice(0, index),
  // inserted item
  newItem,
  // part of the array after the specified index
  ...arr.slice(index)
]

export const Users = ({ user, accountUsers, onDelete, onUpdate, activeAccount }) => {
  const normalizedData = useMemo(() => normalizeUserData(accountUsers), [accountUsers])

  const handleCheckboxClicked = (accountUser, preferenceKey, preferenceValue) => {
    const notificationPreferences = {
      ...accountUser.notificationPreferences,
      [preferenceKey]: preferenceValue
    }
    onUpdate({ accountUserId: accountUser.id, notificationPreferences })
  }

  const activeUserAccount = user.accounts.find(
    (userAccounts) => userAccounts.account.id === activeAccount.id
  )

  let columns = [
    {
      Header: 'Name',
      accessor: 'name',
      padding: 'none'
    },
    {
      Header: 'Email',
      accessor: 'email'
    },
    {
      Header: 'Role',
      accessor: 'role',
      Cell: ({ cell: { value: role } }) => {
        return (
          <MuiChip
            style={{ height: 18, fontSize: 10, lineHeight: 1 }}
            label={role}
            color="primary"
          />
        )
      }
    },
    {
      Header: 'Actions',
      id: 'actions',
      padding: 'none',
      align: 'right',
      Cell: ({ row }) => {
        const accountUserId = row.original.id

        // if active user !== user to delete, show delete action
        if (user.accounts.find((account) => account.id !== accountUserId)) {
          return (
            <DeleteDialog
              handleDelete={() => onDelete({ accountUserId })}
              label="User"
              id={accountUserId}
              contentText="Are you sure you want to delete this user?"
            />
          )
        }
        return false
      }
    }
  ]

  if (activeUserAccount?.account?.plan === 'ENTERPRISE') {
    columns = insert(columns, 3, {
      Header: 'Notifications',
      accessor: 'notificationPreferences',
      Cell: ({ row, cell: { value: preferences } }) => {
        const accountUser = row.original
        const userAccount = user.accounts.find((account) => account.id === accountUser.id)
        const isAdmin = activeUserAccount.role === 'ADMIN' || activeUserAccount.role === 'OWNER'

        return Object.keys(preferences)?.map((key) => {
          return (
            <div key={`preference-${key}`} className="flex items-center">
              <Checkbox
                name={key}
                checked={preferences[key]}
                onChange={(e) => {
                  if (userAccount || isAdmin) {
                    handleCheckboxClicked(accountUser, key, e.target.checked)
                  }
                }}
                size="small"
                disabled={!userAccount && !isAdmin}
              />
              <div>{key === 'listingChanges' ? 'Listing Changes' : 'Weekly Digest'}</div>
            </div>
          )
        })
      }
    })
  }

  return (
    <div data-testid="account-users-view">
      <Table columns={columns} data={normalizedData} />
    </div>
  )
}

Users.propTypes = {
  users: PropTypes.array,
  user: PropTypes.object.isRequired
}
