import { Card, CardEmptyState, ErrorFallback, Loader } from 'components'
import gql from 'graphql-tag'
import PropTypes from 'prop-types'
import React from 'react'
import { useQuery } from 'react-apollo'
import Table from './Table'

const cardTitle = 'Partner Requests'

const GET_REQUESTED_PARTNERS = gql`
  query partnerRequests($accountId: ID!, $ecosystemId: ID!) {
    partnerRequests(accountId: $accountId, ecosystemId: $ecosystemId) {
      id
      listingName
      listingIcon {
        url
      }
      status
    }
  }
`

function normalizePartnerData(partners) {
  return partners.map((p) => ({
    id: p.id,
    icon: p.listingIcon ? p.listingIcon.url : null,
    name: p.listingName,
    status: p.status
  }))
}

function RequestedPartnersOverviewPage({ activeAccount, ecosystem }) {
  const { loading, error, data } = useQuery(GET_REQUESTED_PARTNERS, {
    variables: {
      accountId: activeAccount.id,
      ecosystemId: ecosystem.id
    }
  })

  let partners

  if (error) return <ErrorFallback error={error} />
  if (!loading) {
    partners = normalizePartnerData(data.partnerRequests)
  }

  let content = <Loader />

  if (!loading) {
    if (partners.length === 0) {
      content = <CardEmptyState>There are no requests.</CardEmptyState>
    } else {
      content = (
        <Table
          data={partners}
          ecosystemId={ecosystem.id}
          data-testid="ecosystem-partner-requests-table"
        />
      )
    }
  }

  return (
    <Card title={cardTitle}>
      <div data-testid="ecosystem-partner-requests-view">{content}</div>
    </Card>
  )
}

RequestedPartnersOverviewPage.propTypes = {
  activeAccount: PropTypes.object.isRequired,
  ecosystem: PropTypes.object.isRequired
}

export default RequestedPartnersOverviewPage
