import { Query, graphql } from 'react-apollo'
import { compose, setPropTypes, withHandlers } from 'recompose'
import { deleteInvitationMutation, invitationsQuery } from 'api/queries'

import { Invitations } from 'components'
import LoadingPlaceholder from 'components/placeholder/Loading'
import PropTypes from 'prop-types'
import React from 'react'
import { ErrorFallback } from 'components'
const InvitationsContainer = ({ activeAccount, handleDelete }) => (
  <Query query={invitationsQuery} variables={{ accountId: activeAccount.id }}>
    {({ data, loading, error }) => {
      if (loading) return <LoadingPlaceholder />
      if (error) return <ErrorFallback error={error} />

      return (
        <React.Fragment>
          <Invitations invitations={data.invitations} handleDelete={handleDelete} />
        </React.Fragment>
      )
    }}
  </Query>
)

const removeInvitationFromStore = (store, { accountId }, id) => {
  const data = store.readQuery({
    query: invitationsQuery,
    variables: {
      accountId
    }
  })

  data.invitations = data.invitations.filter((i) => i.id !== id)

  store.writeQuery({
    query: invitationsQuery,
    data,
    variables: {
      accountId
    }
  })
}

export default compose(
  graphql(deleteInvitationMutation, {
    name: 'deleteInvitation'
  }),
  withHandlers({
    handleDelete:
      ({ deleteInvitation, activeAccount }) =>
      async ({ id }) => {
        const variables = {
          id,
          accountId: activeAccount.id
        }

        try {
          await deleteInvitation({
            variables,
            update: (store) =>
              removeInvitationFromStore(
                store,
                {
                  accountId: activeAccount.id
                },
                id
              )
          })
        } catch (error) {
          throw new Error(error.message)
        }
      }
  }),
  setPropTypes({
    activeAccount: PropTypes.object.isRequired,
    handleDelete: PropTypes.func.isRequired
  })
)(InvitationsContainer)
