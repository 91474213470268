import Button from '@material-ui/core/Button'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import { HeaderWithTabs, SecondaryButton } from 'components'
import ListingsNavigation from 'features/ecosystem/listings/Navigation'
import PropTypes from 'prop-types'
import React from 'react'
import { withRouter } from 'react-router-dom'
import ecosystemUrl from 'utils/ecosystemUrl'

const Header = ({ history, match, goBack, activePath, ecosystem }) => {
  const isPathAutomated = activePath.split('/').pop() === 'automated'

  return (
    <HeaderWithTabs tabs={<ListingsNavigation match={match} />}>
      <h1>Listings</h1>
      {goBack && (
        <SecondaryButton className="fr" onClick={history.goBack}>
          Go Back
        </SecondaryButton>
      )}
      {isPathAutomated && (
        <Button
          className="fr"
          target="_blank"
          href={`${ecosystemUrl(ecosystem)}/sitemap.xml`}
          endIcon={
            <OpenInNewIcon
              style={{
                color: 'currentColor',
                fontSize: 16
              }}
            />
          }
        >
          View Sitemap
        </Button>
      )}
    </HeaderWithTabs>
  )
}

Header.propTypes = {
  activePath: PropTypes.string.isRequired
}

const ListingsHeader = withRouter(Header)

export default ListingsHeader
