import { CategoryLayout, ErrorFallback, LoadingPlaceholder } from 'components'
import { categoryOverviewQuery } from 'pages/private/ecosystem/listings/categories/graphql'
import React from 'react'
import { Query } from 'react-apollo'

// TODO move to pages folder

const CategoryContainer = ({ activeAccount, match, ecosystem }) => (
  <Query
    query={categoryOverviewQuery}
    variables={{
      accountId: activeAccount.id,
      ecosystemId: ecosystem.id,
      categoryId: match.params.categoryId
    }}
  >
    {({ loading, error, data }) => {
      if (loading) return <LoadingPlaceholder />
      if (error) return <ErrorFallback error={error} />

      const { marketplace, category } = data
      const { listings } = marketplace

      return (
        <CategoryLayout
          activeAccount={activeAccount}
          category={category}
          ecosystem={ecosystem}
          listings={listings}
        />
      )
    }}
  </Query>
)

export default CategoryContainer
