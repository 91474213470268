import React from 'react'
import Grid from '@material-ui/core/Grid'
import integrationPlatforms from 'constants/integrationPlatforms'

function NetworkFilters({ handleIntegrationFilterChange, handleTextFilterChange, searchText }) {
  return (
    <Grid container>
      <Grid item xs={6}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <input
              type="text"
              className="form-control form-control-lg search"
              placeholder="Search"
              val={searchText}
              onChange={({ currentTarget }) => {
                handleTextFilterChange(currentTarget.value)
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <select
              style={{ height: '48px', backgroundColor: 'white' }}
              onChange={(e) => handleIntegrationFilterChange(e.target.value)}
            >
              <option value="">Integration platform</option>
              {Object.keys(integrationPlatforms).map((i) => (
                <option value={integrationPlatforms[i].id} key={i}>
                  {integrationPlatforms[i].label}
                </option>
              ))}
            </select>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default NetworkFilters
