import React, { useState } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import DeleteIcon from '@material-ui/icons/DeleteForever'
import { IconButton } from 'components'
import Loader from 'components/common/Loader'
import formatDistanceToNow from 'date-fns/formatDistanceToNow'

export const useStyles = makeStyles((theme) => ({
  comment: {
    marginBottom: `1em`,
    paddingBottom: `1em`,
    borderBottom: `1px solid ${theme.palette.ui[200]}`
  },
  emailFromAdmin: {
    color: theme.palette.ui[400]
  },
  emailFromPartner: {
    color: theme.palette.ui[600]
  },
  commentHeader: {
    display: `flex`,
    alignItems: `top`,
    justifyContent: 'space-between',
    color: theme.palette.ui[300],
    marginBottom: '0.25em',
    position: 'relative'
  },
  timestamp: {
    color: theme.palette.ui[250]
  },
  trash: {
    position: 'absolute',
    top: 0,
    right: 0,
    opacity: 0.95
  },
  content: {
    marginTop: `0.5em`,
    whiteSpace: `pre-line`
  }
}))

export const ListingComment = ({ comment, user, handleDelete }) => {
  const classes = useStyles()
  const [hoverEffects, setHoverEffects] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)

  const onDelete = async (commentId) => {
    setIsDeleting(true)
    await handleDelete(commentId)
    setIsDeleting(false)
  }

  const commentFromAdmin = user.id === comment.author.id

  return (
    <div
      className={classes.comment}
      onMouseEnter={() => setHoverEffects(true)}
      onMouseLeave={() => setHoverEffects(false)}
    >
      <div className={classes.commentHeader}>
        <div className={commentFromAdmin ? classes.emailFromAdmin : classes.emailFromPartner}>
          {comment.author.email}
        </div>

        {(!hoverEffects || !commentFromAdmin) && (
          <div className={classes.timestamp}>
            {formatDistanceToNow(new Date(comment.createdAt))} ago
          </div>
        )}

        {(hoverEffects || isDeleting) && commentFromAdmin && (
          <div className={classes.trash}>
            <IconButton
              onClick={(e) => {
                onDelete(comment.id)
                e.preventDefault()
              }}
            >
              {isDeleting ? (
                <Loader size={18} color="#6b7280" />
              ) : (
                <DeleteIcon style={{ fontSize: 24 }} />
              )}
            </IconButton>
          </div>
        )}
      </div>
      <div className={classes.content}>{comment.content}</div>
    </div>
  )
}
