import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import reducers from '../redux'

const middleware = [thunk]

export const initStore = () => {
  const enhancers = []
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__
  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension())
  }

  let store = compose(applyMiddleware(...middleware), ...enhancers)(createStore)(reducers)

  return store
}
