import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer'
import React from 'react'
import styled from 'react-emotion'
import { useIntercom } from 'react-use-intercom'

const Container = styled('div')`
  margin-bottom: -30px;

  h3 {
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 5px;
  }

  span {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: var(--color-primary-lighter);
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;

    svg {
      font-size: 18px;
      margin-right: 7px;
    }
  }
`

const ChatSidebarFooter = () => {
  const { show: showIntercom } = useIntercom()
  const handleStartChat = (e) => {
    e.preventDefault()
    showIntercom()
  }

  return (
    <Container>
      <h3>Questions?</h3>
      <span onClick={(e) => handleStartChat(e)}>
        <QuestionAnswerIcon />
        Chat with us
      </span>
    </Container>
  )
}

export default ChatSidebarFooter
