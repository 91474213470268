import React from 'react'
import { Link } from 'react-router-dom'
import { Tabs, Tab } from 'components'

const Navigation = ({ match }) => (
  <Tabs
    selectors={[
      {
        tab: 0,
        path: `${match.url}/users`,
        exact: true
      },
      {
        tab: 1,
        path: `${match.url}/invitations`
      },
      {
        tab: 2,
        path: `${match.url}/settings`,
        exact: true
      },
      {
        tab: 3,
        path: `${match.url}/profile/user`,
        exact: true
      }
    ]}
  >
    <Tab label="Users" component={Link} to={`${match.url}/users`} />
    <Tab label="Invitations" component={Link} to={`${match.url}/invitations`} />
    <Tab label="Settings" component={Link} to={`${match.url}/settings`} />
    <Tab label="Profile" component={Link} to={`${match.url}/profile/user`} />
  </Tabs>
)

export default Navigation
