import { Fieldset, Submit } from 'components'
import { Formik } from 'formik'
import PropTypes from 'prop-types'
import React from 'react'
import ecosystemUrl from 'utils/ecosystemUrl'
import { CategoryPageContentTemplateFieldset } from '../../forms/Base'
import { baseValidationSchema, DescriptionField, LogoField, NameField, SlugField } from './Base'

export const CategoryForm = ({ initialValues, handleSubmit, ecosystem, handleLogoDelete }) => (
  <Formik
    validationSchema={baseValidationSchema}
    initialValues={initialValues}
    onSubmit={handleSubmit}
  >
    {(formikProps) => {
      const { handleSubmit, isSubmitting } = formikProps
      return (
        <form onSubmit={handleSubmit}>
          <Fieldset>
            <NameField formikProps={formikProps} />
            <SlugField formikProps={formikProps} prefix={`${ecosystemUrl(ecosystem)}/category/`} />
            <LogoField formikProps={formikProps} handleDelete={handleLogoDelete} />
            <DescriptionField formikProps={formikProps} />
          </Fieldset>
          <CategoryPageContentTemplateFieldset formikProps={formikProps} ecosystem={ecosystem} />
          <div className="sticky-submit-container">
            <Submit isSubmitting={isSubmitting} text="Save Category" />
          </div>
        </form>
      )
    }}
  </Formik>
)

CategoryForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object
}

export default CategoryForm
