import Card from '@material-ui/core/Card'
import PropTypes from 'prop-types'
import React from 'react'
import Switch from '@material-ui/core/Switch'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  toggleField: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
    border: `1px solid ${theme.palette.ui[200]}`,
    padding: theme.spacing(1, 2)
  },
  label: {
    fontSize: '14px',
    fontWeight: 500,
    color: theme.palette.ui[600]
  }
}))

const ToggleField = ({ isActive, label, onToggle }) => {
  const classes = useStyles()

  return (
    <Card className={classes.toggleField} variant="outlined">
      <Typography className={classes.label}>{label}</Typography>
      <Switch checked={isActive} onChange={(e) => onToggle(e)} />
    </Card>
  )
}

ToggleField.defaultProps = {
  isActive: false
}

ToggleField.propTypes = {
  isActive: PropTypes.bool,
  label: PropTypes.string.isRequired,
  onToggle: PropTypes.func.isRequired
}

export default ToggleField
