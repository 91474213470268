import React, { useRef, useState } from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'

import Button from '@material-ui/core/Button'
import { Card } from 'components'
import { Formik } from 'formik'
import { ListingComments } from './ListingComments'
import Loader from 'components/common/Loader'
import SendIcon from '@material-ui/icons/Send'

export const useStyles = makeStyles((theme) => ({
  commentInputWrapper: {
    position: 'relative',
    paddingBottom: '2em',
    border: `solid 1px ${theme.palette.ui[200]}`,
    borderRadius: `5px`,
    background: `#f6f6f6`
  },
  commentInput: {
    width: '100%',
    padding: `0.5em 0.5em 1em 0.5em`,
    fontSize: `15px`,
    height: `110px`,
    border: `none`,
    borderBottom: `solid 1px ${theme.palette.ui[200]}`,
    background: `#f6f6f6`,
    resize: `none`
  },
  commentSubmit: {
    marginBottom: `0px`,
    display: `flex`,
    justifyContent: `end`,
    position: `absolute`,
    bottom: `2px`,
    right: `4px`
  },
  commentSubmitButton: {
    width: `80px`,
    border: `none`,
    padding: `0.5em`,
    background: `transparent`,
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.ui[300],
    fontSize: `13px`,
    '&:disabled': {
      opacity: `0.3`
    }
  },
  approvalActions: {
    display: `flex`,
    flexDirection: `row`,
    borderTop: `1px solid ${theme.palette.ui[200]}`,
    paddingTop: `1em`
  }
}))

const ApprovalForm = (props) => {
  const [isCreatingComment, setIsCreatingComment] = useState(false)
  const [isApproving, setIsApproving] = useState(false)
  const [isDeclining, setIsDeclining] = useState(false)
  const [comment, setComment] = useState('')
  const classes = useStyles()
  const theme = useTheme()
  const commentBoxRef = useRef(null)

  const {
    comments,
    listing,
    handleDeclineListing,
    handleApproveListing,
    handleCreateComment,
    handleDeleteComment,
    user,
    ecosystem
  } = props

  const state = listing?.state
  const partner = listing?.partner

  const handleSubmit = async (approve = null) => {
    if (approve === true) {
      setIsApproving(true)
      await handleApproveListing({ comment })
      setIsApproving(false)
    } else if (approve === false) {
      setIsDeclining(true)
      await handleDeclineListing({ comment })
      setIsDeclining(false)
    } else {
      setIsCreatingComment(true)
      await handleCreateComment({ comment })
      setIsCreatingComment(false)
      setTimeout(
        () =>
          commentBoxRef.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'nearest',
            inline: 'start'
          }),
        100
      )
    }
    setComment('')
  }

  const stateText = (state) => {
    if (state === 'PENDING_REVIEW') return 'Pending review'
    if (state === 'APPROVED') return 'Approved'
    if (state === 'DECLINED') return 'Declined'
    if (state === 'DRAFT') return 'Draft'
    return state
  }

  const stateColor = (state) => {
    if (state === 'PENDING_REVIEW') return '#f97316'
    if (state === 'APPROVED') return '#22c55e'
    if (state === 'DECLINED') return '#ef4444'
    return '#5c51C1'
  }

  return (
    <Formik onSubmit={handleSubmit} initialValues={{ comment: '' }}>
      {(formikProps) => {
        const { isSubmitting } = formikProps
        return (
          <form>
            <Card
              title={ecosystem?.listingApprovalEnabled ? `Listing Approval` : 'Comments'}
              button={
                ecosystem?.listingApprovalEnabled && (
                  <span
                    className="pill"
                    style={{ marginRight: 0, backgroundColor: stateColor(state) }}
                  >
                    {stateText(state)}
                  </span>
                )
              }
            >
              <div>
                <ListingComments
                  comments={comments}
                  handleDeleteComment={handleDeleteComment}
                  user={user}
                  commentBoxRef={commentBoxRef}
                />
              </div>

              <div className={classes.commentInputWrapper}>
                <textarea
                  className={classes.commentInput}
                  id="comment"
                  name="comment"
                  onChange={(e) => setComment(e.target.value)}
                  value={comment}
                  placeholder={`Message: ${partner.company}`}
                  type={'text'}
                />

                <div className={classes.commentSubmit}>
                  <button
                    type="button"
                    className={classes.commentSubmitButton}
                    disabled={!comment}
                    onClick={() => !!comment && handleSubmit()}
                  >
                    {isCreatingComment ? (
                      'Sending...'
                    ) : (
                      <div className="flex items-center">
                        <SendIcon style={{ fontSize: 18, color: theme.palette.ui[250] }} />
                        <span style={{ marginLeft: 8 }}>Send</span>
                      </div>
                    )}
                  </button>
                </div>
              </div>

              {ecosystem?.listingApprovalEnabled && (
                <div>
                  {state === 'PENDING_REVIEW' ? (
                    <div className={classes.approvalActions}>
                      <Button
                        variant="contained"
                        color="primary"
                        className="delete flex-grow-1"
                        disabled={isSubmitting}
                        style={{ marginRight: '1em' }}
                        onClick={() => handleSubmit(false)}
                      >
                        {isDeclining ? (
                          <div className="flex items-center">
                            <Loader size={20} color="#FFFFFF" />
                            <span style={{ marginLeft: 8 }}>Decline</span>
                          </div>
                        ) : (
                          'Decline'
                        )}
                      </Button>

                      <Button
                        variant="contained"
                        color="primary"
                        className="flex-grow-1"
                        disabled={isSubmitting}
                        onClick={() => handleSubmit(true)}
                      >
                        {isApproving ? (
                          <div className="flex items-center">
                            <Loader size={20} color="#FFFFFF" />
                            <span style={{ marginLeft: 8 }}>Approve</span>
                          </div>
                        ) : (
                          'Approve'
                        )}
                      </Button>
                    </div>
                  ) : (
                    <div />
                  )}
                </div>
              )}
            </Card>
          </form>
        )
      }}
    </Formik>
  )
}

export default ApprovalForm
