import React from 'react'
import { withRouter } from 'react-router-dom'
import { setItem } from 'utils/localstorage'
import AccountPortalPage from './AccountPortalPage'

const AccountPortalContainer = (props) => {
  const setActiveAccount = async (accountUser) => {
    setItem('activeAccountId', accountUser.account.id)

    // redirect
    props.history.push('/')
  }

  return <AccountPortalPage user={props.user} setActiveAccount={setActiveAccount} />
}

export default withRouter(AccountPortalContainer)
