import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import styled from 'react-emotion'
import ForgotPasswordForm from './Form'

const Feedback = styled('div')`
  margin: 10px 5px;
  background-color: #6f56fc;
  color: #fff;
  display: flex;
  align-items: center;
  min-height: 0;
  border-radius: 4px;
  box-shadow: 0px 4px 4px rgba(79, 83, 108, 0.1);
  height: 25px;
  font-size: 13px;
  font-weight: 600;
  padding: 5px 10px;
  font-family: 'Open Sans', Arial, Helvetica, sans-serif;
`

function ForgotPassword({ handleRequestPasswordChange, message }) {
  return (
    <Fragment>
      <h2>Forgot Password</h2>
      {message ? <Feedback>{message}</Feedback> : <p>Send a password reset link</p>}
      <ForgotPasswordForm
        handleSubmit={handleRequestPasswordChange}
        initialValues={{
          email: ''
        }}
      />
      <Link to="/login">Go back</Link>
    </Fragment>
  )
}

ForgotPassword.propTypes = {
  handleRequestPasswordChange: PropTypes.func.isRequired,
  message: PropTypes.string
}

export default ForgotPassword
