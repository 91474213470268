import React from 'react'
import { PulseLoader } from 'react-spinners'
import styled from 'react-emotion'
import { Global, css } from '@emotion/core'

const Container = styled('div')`
  color: var(--color-text);
  text-align: center;

  .loader-container > div {
    margin: auto;
  }

  h1 {
    margin-top: 40px;
    font-weight: 500;
  }

  a {
    font-weight: 500;
  }
`

const SignupLoading = () => (
  <Container>
    <Global
      styles={css`
        body {
          background-color: white;
        }
      `}
    />
    <div className="loader-container">
      <PulseLoader size={10} color="var(--color-primary)" />
    </div>
    <h1>Just a few seconds..</h1>
    <p>We are creating your account. You will get redirected automatically when we are done.</p>
  </Container>
)

export default SignupLoading
