import React from 'react'
import { CategoryCreateForm as Form, ContentWrapper } from 'components'
import PropTypes from 'prop-types'

const Create = ({ handleSubmit, initialValues, ecosystem }) => (
  <ContentWrapper title="Create category">
    <Form handleSubmit={handleSubmit} initialValues={initialValues} ecosystem={ecosystem} />
  </ContentWrapper>
)

Create.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired
}

export default Create
