import React from 'react'
import styled from 'react-emotion'
import { TermsLink, PrivacyLink, SecurityLink, StatusLink, ComplianceLink } from 'components'

const FooterWrapper = styled('footer')`
  width: 100%;
  font-size: 13px;
  background: var(--color-white);
  border-top: 1px solid #e7edf7;
  padding: 10px var(--main-padding-horizontal);

  p {
    color: #666;
  }

  nav {
    margin-right: 60px;
  }

  nav a {
    color: var(--link-color);
  }

  nav a:hover {
    color: var(--link-color);
    text-decoration: underline;
  }

  ul {
    display: flex;
    flex-direction: row;
    li {
      margin-left: 15px;
    }
  }
`

const Footer = () => (
  <FooterWrapper>
    <div
      style={{
        margin: '0 auto',
        display: 'flex',
        justifyContent: 'space-between'
      }}
    >
      <p>Copyright © {new Date().getFullYear()} Apideck. All rights reserved.</p>
      <nav>
        <ul>
          <li>
            <TermsLink />
          </li>
          <li>
            <PrivacyLink />
          </li>
          <li>
            <StatusLink />
          </li>
          <li>
            <SecurityLink />
          </li>
          <li>
            <ComplianceLink />
          </li>
        </ul>
      </nav>
    </div>
  </FooterWrapper>
)

export default Footer
