import { Fieldset, Submit } from 'components'
import { Formik } from 'formik'
import PropTypes from 'prop-types'
import React from 'react'
import ecosystemUrl from 'utils/ecosystemUrl'
import {
  baseValidationSchema,
  ConnectedSlugField,
  DescriptionField,
  LogoField,
  NameField
} from './Base'

export const CategoryForm = ({ initialValues, handleSubmit, ecosystem }) => (
  <Formik
    validationSchema={baseValidationSchema}
    initialValues={initialValues}
    onSubmit={handleSubmit}
  >
    {(formikProps) => {
      const { handleSubmit, isSubmitting } = formikProps
      return (
        <form onSubmit={handleSubmit}>
          <Fieldset>
            <NameField formikProps={formikProps} />
            <ConnectedSlugField
              formikProps={formikProps}
              prefix={`${ecosystemUrl(ecosystem)}/category/`}
            />
            <LogoField formikProps={formikProps} />
            <DescriptionField formikProps={formikProps} />
          </Fieldset>
          <div className="sticky-submit-container">
            <Submit isSubmitting={isSubmitting} text="Create Category" />
          </div>
        </form>
      )
    }}
  </Formik>
)

CategoryForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object
}

export default CategoryForm
