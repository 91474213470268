import gql from 'graphql-tag'
import translationFieldsFragment from './translationFields'

const mediaFieldsFragment = gql`
  fragment mediaFields on Media {
    id
    caption
    url
    sequence
    translations {
      ...translationFields
    }
  }
  ${translationFieldsFragment}
`

export default mediaFieldsFragment
