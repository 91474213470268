import { useMaxListingsContext } from 'context/useMaxListingsContext'
import React, { useEffect, useState } from 'react'
import GridCard from './GridCard'

function Service({ onClick, service }) {
  const [busy, setBusy] = useState(false)
  const { maxListingsReached, infoMessage } = useMaxListingsContext()

  useEffect(() => {
    setBusy(false)
  }, [service.imported, busy])

  const handleClick = () => {
    setBusy(true)
    onClick(service)
  }

  return (
    <GridCard
      title={service.name}
      imageSrc={service.icon.url || null}
      id={service.id}
      link={`add/${service.id}`}
      handleClick={handleClick}
      action={service.imported ? 'Remove' : 'Import'}
      small
      integration
      loading={busy}
      disabled={!service.listingId && maxListingsReached}
      tooltip={infoMessage}
      style={{ border: service.imported ? '1px #12bf00 solid' : '' }}
      sm={2}
      xs={12}
    />
  )
}

export default Service
