import { createProductMutation, productsQuery } from 'api/queries'
import { injectLocalizedValues, prepareVariables, ProductCreate } from 'components'
import PropTypes from 'prop-types'
import React from 'react'
import { graphql } from 'react-apollo'
import { toastr } from 'react-redux-toastr'
import { compose, withHandlers } from 'recompose'
import uploadFile from 'utils/fileUpload'
import { ecosystemLocales } from 'utils/locales'
import transformError from 'utils/transformError'

class Container extends React.Component {
  render() {
    const { handleSubmit, ecosystem } = this.props
    let initialValues = {
      name: '',
      slug: '',
      description: '',
      visible: true,
      logo: [],
      sequence: 1,
      locales: ecosystemLocales(ecosystem)
    }

    initialValues = injectLocalizedValues('product', initialValues)

    return (
      <ProductCreate
        initialValues={initialValues}
        handleSubmit={handleSubmit}
        ecosystem={ecosystem}
      />
    )
  }
}

Container.propTypes = {
  activeAccount: PropTypes.object.isRequired
}

const addProductToStore = (store, { ecosystemId, accountId }, product) => {
  const data = store.readQuery({
    query: productsQuery,
    variables: {
      ecosystemId,
      accountId
    }
  })

  data.products.push(product)

  store.writeQuery({
    query: productsQuery,
    data,
    variables: {
      ecosystemId,
      accountId
    }
  })
}

const handleSubmit = withHandlers({
  handleSubmit:
    ({
      createProduct,
      activeAccount,
      ecosystem,
      history,
      match: {
        params: { ecosystemId }
      }
    }) =>
    async (props, { setSubmitting, setErrors }) => {
      setSubmitting(true)
      try {
        const { name, slug, description, visible, logo, sequence } = props

        let variables = {
          accountId: activeAccount.id,
          ecosystemId,
          name,
          slug,
          description,
          visible,
          sequence,
          translations: prepareVariables(props, ecosystem, [])
        }

        if (logo.length !== 0) {
          let result = await uploadFile(logo[0], {
            params: {
              folder: `marketplaces/${ecosystemId}/products`
            }
          })

          variables = {
            ...variables,
            logo: {
              url: result.url,
              contentType: `${result.resourceType}/${result.format}`
            }
          }
        }

        // create product
        await createProduct({
          variables,
          update: (store, { data: { createProduct } }) =>
            addProductToStore(
              store,
              {
                ecosystemId,
                accountId: activeAccount.id
              },
              createProduct
            )
        })

        toastr.success('Product created')
        history.push(`/ecosystems/${ecosystemId}/listings/products`)
      } catch (error) {
        toastr.error('Could not create product')
        if (error.graphQLErrors) {
          setErrors(transformError(error.graphQLErrors))
        }

        setSubmitting(false)
      }
    }
})
const createProduct = graphql(createProductMutation, {
  name: 'createProduct'
})

const CreateProductContainer = compose(createProduct, handleSubmit)(Container)

export default CreateProductContainer
