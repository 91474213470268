import { deleteProductMutation, productsQuery, updateProductMutation } from 'api/queries'
import {
  injectLocalizedValues,
  prepareVariables,
  ProductDelete as Delete,
  ProductEditForm
} from 'components'
import PropTypes from 'prop-types'
import React from 'react'
import { graphql } from 'react-apollo'
import { toastr } from 'react-redux-toastr'
import { compose, withHandlers } from 'recompose'
import uploadFile from 'utils/fileUpload'
import { ecosystemLocales, setRootValueFromTranslation } from 'utils/locales'
import transformError from 'utils/transformError'

class Container extends React.Component {
  render() {
    const { handleSubmit, handleDelete, product, ecosystem } = this.props

    let initialValues = {
      name: product.name || '',
      slug: product.slug || '',
      description: product.description || '',
      logo: product.logo ? [product.logo] : [],
      visible: product?.visible ?? true,
      sequence: product.sequence || 1,
      locales: ecosystemLocales(ecosystem)
    }

    initialValues = injectLocalizedValues('product', initialValues, product)

    return (
      <React.Fragment>
        <ProductEditForm
          initialValues={initialValues}
          handleSubmit={handleSubmit}
          ecosystem={ecosystem}
        />
        <Delete handleProductDelete={handleDelete} />
      </React.Fragment>
    )
  }
}

Container.propTypes = {
  activeAccount: PropTypes.object.isRequired,
  product: PropTypes.object.isRequired
}

const removeProductFromStore = (store, { ecosystemId, accountId }, product) => {
  const data = store.readQuery({
    query: productsQuery,
    variables: {
      ecosystemId,
      accountId
    }
  })

  data.products = data.products.filter((c) => c.id !== product.id)

  store.writeQuery({
    query: productsQuery,
    data,
    variables: {
      ecosystemId,
      accountId
    }
  })
}

const productHandlers = withHandlers({
  handleSubmit:
    ({
      updateProduct,
      activeAccount,
      history,
      product,
      ecosystem,
      match: {
        params: { ecosystemId }
      }
    }) =>
    async (props, { setErrors, setSubmitting }) => {
      setSubmitting(true)
      try {
        const { description, sequence, logo, name, slug, visible } = props

        product.translations = prepareVariables(props, ecosystem, product.translations)

        const locales = ecosystemLocales(ecosystem)
        let variables = {
          accountId: activeAccount.id,
          id: product.id,
          slug,
          name: setRootValueFromTranslation(locales, product, 'name', name),
          description: setRootValueFromTranslation(locales, product, 'description', description),
          visible,
          sequence,
          translations: product.translations
        }

        if (logo.length !== 0 && logo[0].preview) {
          let result = await uploadFile(logo[0], {
            params: {
              folder: `marketplaces/${ecosystemId}/products`
            }
          })

          variables = {
            ...variables,
            logo: {
              url: result.url,
              contentType: `${result.resourceType}/${result.format}`
            }
          }
        }

        await updateProduct({
          variables
        })

        toastr.success('Product updated')
        history.push(`/ecosystems/${ecosystemId}/listings/products/${product.id}/overview`)
      } catch (error) {
        toastr.error('could not update product')
        if (error.graphQLErrors) {
          setErrors(transformError(error.graphQLErrors))
        }

        setSubmitting(false)
      }
    },
  handleDelete:
    ({
      deleteProduct,
      activeAccount,
      history,
      product,
      match: {
        params: { ecosystemId }
      }
    }) =>
    async () => {
      try {
        let variables = {
          accountId: activeAccount.id,
          id: product.id
        }

        await deleteProduct({
          variables,
          update: (store, { data: { deleteProduct } }) => {
            removeProductFromStore(
              store,
              {
                ecosystemId,
                accountId: activeAccount.id
              },
              deleteProduct
            )
          }
        })

        toastr.success('Product deleted')
        history.push(`/ecosystems/${ecosystemId}/listings/products`)
      } catch (error) {
        toastr.error('could not delete product')
      }
    }
})

const SettingsContainer = compose(
  graphql(updateProductMutation, { name: 'updateProduct' }),
  graphql(deleteProductMutation, { name: 'deleteProduct' }),
  productHandlers
)(Container)

export default SettingsContainer
