import {
  collectionQuery,
  collectionsQuery,
  deleteCollectionLogoMutation,
  deleteCollectionMutation,
  updateCollectionMutation
} from 'api/queries'
import {
  CollectionDelete as Delete,
  CollectionEditForm,
  injectLocalizedValues,
  prepareVariables
} from 'components'
import PropTypes from 'prop-types'
import React from 'react'
import { graphql } from 'react-apollo'
import { toastr } from 'react-redux-toastr'
import { compose, withHandlers } from 'recompose'
import uploadFile from 'utils/fileUpload'
import { ecosystemLocales, setRootValueFromTranslation } from 'utils/locales'
import transformError from 'utils/transformError'

class Container extends React.Component {
  render() {
    const { handleSubmit, handleDelete, collection, ecosystem, handleLogoDelete } = this.props

    let initialValues = {
      name: collection.name || '',
      slug: collection.slug || '',
      description: collection.description || '',
      logo: collection.logo ? [collection.logo] : [],
      cardStyle: collection.cardStyle || ecosystem.cardStyle,
      cardColumns: collection.cardColumns || ecosystem.cardColumns,
      cardBackgroundColor: collection.cardBackgroundColor || '',
      sequence: collection.sequence || 1,
      cardBackgroundImage: collection.cardBackgroundImage ? [collection.cardBackgroundImage] : [],
      visible: collection?.visible ?? true,
      hiddenFromHomepage: collection?.hiddenFromHomepage ?? false,
      showMaxItemsHomepage: collection.showMaxItemsHomepage,
      locales: ecosystemLocales(ecosystem)
    }

    initialValues = injectLocalizedValues('collection', initialValues, collection)

    return (
      <React.Fragment>
        <CollectionEditForm
          initialValues={initialValues}
          handleSubmit={handleSubmit}
          ecosystem={ecosystem}
          handleLogoDelete={() => handleLogoDelete(collection.id)}
        />
        <Delete handleCollectionDelete={handleDelete} />
      </React.Fragment>
    )
  }
}

Container.propTypes = {
  activeAccount: PropTypes.object.isRequired,
  collection: PropTypes.object.isRequired
}

const removeCollectionFromStore = (store, { ecosystemId, accountId }, collection) => {
  const data = store.readQuery({
    query: collectionsQuery,
    variables: {
      id: ecosystemId,
      accountId
    }
  })

  data.marketplace.collections = data.marketplace.collections.filter((c) => c.id !== collection.id)

  store.writeQuery({
    query: collectionsQuery,
    data,
    variables: {
      id: ecosystemId,
      accountId
    }
  })
}

const removeCollectionLogoFromStore = (store, { id, accountId }) => {
  const data = store.readQuery({
    query: collectionQuery,
    variables: {
      id,
      accountId
    }
  })

  data.collection.logo = null

  store.writeQuery({
    query: collectionQuery,
    data,
    variables: {
      id,
      accountId
    }
  })
}

const collectionHandlers = withHandlers({
  handleSubmit:
    ({
      updateCollection,
      activeAccount,
      collection,
      ecosystem,
      match: {
        params: { ecosystemId }
      }
    }) =>
    async (props, { setErrors, setSubmitting }) => {
      setSubmitting(true)
      try {
        const {
          cardBackgroundColor,
          cardBackgroundImage,
          cardStyle,
          cardColumns,
          description,
          sequence,
          logo,
          name,
          slug,
          visible,
          hiddenFromHomepage,
          showMaxItemsHomepage
        } = props

        const locales = ecosystemLocales(ecosystem)
        let variables = {
          accountId: activeAccount.id,
          id: collection.id,
          name: setRootValueFromTranslation(locales, collection, 'name', name),
          slug,
          description: setRootValueFromTranslation(locales, collection, 'description', description),
          sequence,
          visible,
          cardStyle,
          cardColumns,
          cardBackgroundColor,
          translations: prepareVariables(props, ecosystem, collection.translations),
          hiddenFromHomepage,
          showMaxItemsHomepage: showMaxItemsHomepage || null
        }

        if (logo.length !== 0 && logo[0].preview) {
          let result = await uploadFile(logo[0], {
            params: {
              folder: `marketplaces/${ecosystemId}/collections`
            }
          })

          variables = {
            ...variables,
            logo: {
              url: result.url,
              contentType: `${result.resourceType}/${result.format}`
            }
          }
        }

        if (cardBackgroundImage.length !== 0 && cardBackgroundImage[0].preview) {
          let result = await uploadFile(cardBackgroundImage[0], {
            params: {
              folder: `marketplaces/${ecosystemId}/collections`
            }
          })

          variables = {
            ...variables,
            cardBackgroundImage: {
              url: result.url,
              contentType: `${result.resourceType}/${result.format}`
            }
          }
        }

        await updateCollection({
          variables
        })

        toastr.success('Collection updated')
      } catch (error) {
        toastr.error('could not update collection')
        if (error.graphQLErrors) {
          setErrors(transformError(error.graphQLErrors))
        }

        setSubmitting(false)
      }
    },
  handleDelete:
    ({
      deleteCollection,
      activeAccount,
      history,
      collection,
      match: {
        params: { ecosystemId }
      }
    }) =>
    async () => {
      try {
        let variables = {
          accountId: activeAccount.id,
          id: collection.id
        }

        await deleteCollection({
          variables,
          update: (store, { data: { deleteCollection } }) => {
            removeCollectionFromStore(
              store,
              {
                ecosystemId,
                accountId: activeAccount.id
              },
              deleteCollection
            )
          }
        })

        toastr.success('Collection deleted')
        history.push(`/ecosystems/${ecosystemId}/listings/collections`)
      } catch (error) {
        toastr.error('could not delete collection')
      }
    },
  handleLogoDelete:
    ({ deleteCollectionLogo, activeAccount }) =>
    async (id) => {
      try {
        const variables = {
          accountId: activeAccount.id,
          id
        }

        await deleteCollectionLogo({
          variables,
          update: (store) =>
            removeCollectionLogoFromStore(store, {
              id,
              accountId: activeAccount.id
            })
        })

        toastr.success('Collection logo deleted')
      } catch (error) {
        toastr.error('Could not delete collection logo')
        throw new Error(error && error.message)
      }
    }
})

const SettingsContainer = compose(
  graphql(updateCollectionMutation, { name: 'updateCollection' }),
  graphql(deleteCollectionMutation, { name: 'deleteCollection' }),
  graphql(deleteCollectionLogoMutation, { name: 'deleteCollectionLogo' }),
  collectionHandlers
)(Container)

export default SettingsContainer
