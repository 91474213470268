import { DeleteDialog, OpenInNewButton } from 'components'

import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import { CSVLink } from 'react-csv'
import Chip from '@material-ui/core/Chip'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import LeadDrawer from './LeadDrawer'
import Paper from '@material-ui/core/Paper'
import PropTypes from 'prop-types'
import React from 'react'
import RequestFilter from './RequestsFilter'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Toolbar from '@material-ui/core/Toolbar'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import { compose } from 'recompose'
import ecosystemUrl from 'utils/ecosystemUrl'
import slugify from 'slugify'
import timeAgo from 'time-ago'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy)
}
class EnhancedTableHead extends React.Component {
  createSortHandler = (property) => (event) => {
    this.props.onRequestSort(event, property)
  }

  render() {
    const { order, orderBy, ecosystem } = this.props
    const listingNaming = (ecosystem?.listingNaming ?? 'LISTINGS').toLowerCase()
    const {
      leadTelephoneFieldEnabled,
      leadFirstNameFieldEnabled,
      leadLastNameFieldEnabled,
      installationRequestFlowEnabled
    } = ecosystem
    const rows = []
    if (leadFirstNameFieldEnabled || leadLastNameFieldEnabled) {
      rows.push({
        id: 'name',
        numeric: false,
        label: 'Name',
        sort: false
      })
    }
    rows.push({
      id: 'email',
      numeric: false,
      label: 'Email',
      sort: false
    })
    if (leadTelephoneFieldEnabled) {
      rows.push({
        id: 'telephone',
        numeric: false,
        label: 'Telephone',
        sort: false
      })
    }
    rows.push(
      {
        id: 'user',
        numeric: false,
        label: 'Customer',
        sort: false
      },
      {
        id: 'requested',
        numeric: false,
        label: `Requested ${listingNaming}`,
        sort: false
      }
    )
    if (installationRequestFlowEnabled) {
      rows.push({
        id: 'installation-request',
        numeric: false,
        label: 'Installation request',
        sort: false
      })
    }
    rows.push(
      {
        id: 'created',
        numeric: true,
        label: 'Created',
        sort: false,
        width: '180px'
      },
      {
        id: 'actions',
        numeric: true,
        label: 'Actions',
        sort: false
      }
    )

    return (
      <TableHead>
        <TableRow>
          {rows.map((row) => {
            const sortLabel = (
              <TableSortLabel
                active={orderBy === row.id}
                direction={order}
                onClick={this.createSortHandler(row.id)}
              >
                {row.label}
              </TableSortLabel>
            )

            return (
              <TableCell
                key={row.id}
                sortDirection={orderBy === row.id ? order : false}
                style={{
                  textAlign: row.numeric ? 'right' : 'left',
                  width: row.width ? row.width : 'auto'
                }}
              >
                {row.sort ? sortLabel : row.label}
              </TableCell>
            )
          }, this)}
        </TableRow>
      </TableHead>
    )
  }
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired
}

const styles = (theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3)
  },
  gridItemsWrap: {
    display: 'flex',
    alignItems: 'center'
  },
  title: {
    fontSize: '16px',
    fontWeight: 500,
    color: '#001b2f'
  },
  table: {
    minWidth: '1020px'
  },
  tableWrapper: {
    overflowX: 'auto'
  },
  tableCell: {
    fontSize: '13px'
  },
  chip: {
    marginRight: '10px',
    backgroundColor: 'transparent',
    border: `1px solid ${theme.palette.ui[200]}`,
    fontSize: '12px'
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start'
  },
  avatar: {
    width: '16px',
    height: '16px',
    marginRight: theme.spacing(1)
  }
})

class EnhancedTable extends React.Component {
  state = {
    order: 'asc',
    orderBy: null,
    data: [],
    filteredData: [],
    page: 0,
    rowsPerPage: 50,
    drawerOpen: false,
    activeLead: null
  }

  componentDidMount() {
    const { location, data } = this.props

    if (location.search.length) {
      const service = location.search.substring(1)

      this.setState({
        filteredData: service
          ? data.filter((lead) => lead.requestedServices.includes(service))
          : data
      })
    }
  }

  handleRequestSort = (event, property) => {
    const orderBy = property
    let order = 'desc'

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc'
    }

    this.setState({ order, orderBy })
  }

  handleChangePage = (event, page) => {
    this.setState({ page })
  }

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value })
  }

  handleActionsClick = (event, lead) => {
    this.setState({
      anchorEl: event.currentTarget,
      lead
    })
  }

  handleClose = () => {
    this.setState({ anchorEl: null })
  }

  toggleDrawer = (lead) => {
    this.setState({ drawerOpen: !this.state.drawerOpen })
    this.setState({ activeLead: lead })
  }

  getCsvData(data) {
    const csvData = [
      [
        'Name',
        'Email',
        'Customer',
        'Requested integrations',
        'Installation request',
        'Notes',
        'Created'
      ]
    ]

    if (this.state.filteredData && this.state.filteredData.length > 0) {
      data = this.state.filteredData
    }

    data &&
      data.length &&
      data.map((lead) => {
        return csvData.push([
          `${lead.firstName} ${lead.lastName}`,
          lead.email,
          lead.isCustomer ? 'Yes' : 'No',
          lead.requestedServices.join(', '),
          lead.installationRequest ? 'Yes' : 'No',
          lead.notes,
          new Date(lead.createdAt).toLocaleString()
        ])
      })

    return csvData
  }

  getServices() {
    return [...new Set(this.props.data.map((lead) => lead.requestedServices).flat())]
  }

  filterOnService(service) {
    this.props.history.push({
      pathname: this.props.location.pathname,
      search: service
    })
  }

  render() {
    const { classes, data, handleLeadDelete, ecosystem, location } = this.props
    const { leadTelephoneFieldEnabled, leadFirstNameFieldEnabled, leadLastNameFieldEnabled } =
      ecosystem
    const { order, orderBy, rowsPerPage, page, activeLead, drawerOpen, filteredData } = this.state

    return (
      <div>
        <Grid container>
          <Grid item lg={3} md={12} style={{ marginBottom: 17 }}>
            <RequestFilter
              services={this.getServices()}
              handleChange={(service) => this.filterOnService(service)}
              service={location.search.substring(1) || ''}
              ecosystem={ecosystem}
            />
          </Grid>
        </Grid>
        <Paper style={{ position: 'relative' }}>
          <Toolbar>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item>
                <Typography className={classes.title}>Leads</Typography>
              </Grid>
              <div className={classes.gridItemsWrap}>
                <Grid item>
                  <Button size="small" style={{ marginRight: 15 }}>
                    <CSVLink
                      data={this.getCsvData((filteredData && filteredData.length > 0) || data)}
                      filename="apideck-leads.csv"
                    >
                      Download CSV
                    </CSVLink>
                  </Button>
                </Grid>
                <Grid item>
                  <OpenInNewButton href={ecosystemUrl(ecosystem) + '/request'}>
                    View request form
                  </OpenInNewButton>
                </Grid>
              </div>
            </Grid>
          </Toolbar>
          <Divider />
          <TableContainer>
            <Table
              className={classes.table}
              aria-label="Requests leads table"
              size="medium"
              data-testid="ecosystem-leads-requests-table"
              stickyHeader
            >
              {data.length === 0 && <caption>No records available.</caption>}
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={this.handleRequestSort}
                rowCount={filteredData.length}
                ecosystem={ecosystem}
              />
              <TableBody>
                {stableSort(filteredData.length ? filteredData : data, getSorting(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((lead) => {
                    const createdAt = new Date(lead.createdAt)

                    return (
                      <TableRow
                        hover
                        tabIndex={-1}
                        key={lead.id}
                        onClick={() => this.toggleDrawer(lead)}
                      >
                        {(leadFirstNameFieldEnabled || leadLastNameFieldEnabled) && (
                          <TableCell>
                            {lead.firstName} {lead.lastName}
                          </TableCell>
                        )}
                        <TableCell>{lead.email}</TableCell>
                        {leadTelephoneFieldEnabled && <TableCell>{lead.telephone}</TableCell>}
                        <TableCell>{lead.isCustomer ? 'Yes' : 'No'}</TableCell>
                        <TableCell>
                          {lead.requestedServices
                            .map((requestedService, index) => {
                              const label = (
                                <span className={classes.label}>
                                  <Avatar
                                    variant="rounded"
                                    src={`https://res.cloudinary.com/apideck/icons/${slugify(
                                      requestedService
                                    ).toLowerCase()}`}
                                    className={classes.avatar}
                                  >
                                    {requestedService.substring(0, 1)}
                                  </Avatar>
                                  {requestedService}
                                </span>
                              )

                              return (
                                <Chip
                                  key={index}
                                  size="small"
                                  label={label}
                                  className={classes.chip}
                                />
                              )
                            })
                            .splice(0, 2)}

                          {lead.requestedServices.length > 2 &&
                            `+${lead.requestedServices.length - 2}`}
                        </TableCell>
                        {ecosystem.installationRequestFlowEnabled && (
                          <TableCell>{lead.installationRequest ? 'Yes' : 'No'}</TableCell>
                        )}
                        <TableCell align="right">
                          <Tooltip
                            title={`${createdAt.toLocaleDateString()} - ${createdAt.toLocaleTimeString()}`}
                            placement="bottom"
                            arrow
                          >
                            <span>{timeAgo.ago(lead.createdAt)}</span>
                          </Tooltip>
                        </TableCell>
                        <TableCell align="right" padding="checkbox">
                          <DeleteDialog
                            handleDelete={handleLeadDelete}
                            id={lead.id}
                            label="Lead"
                            contentText="Are you sure you want to delete this lead?"
                          />
                        </TableCell>
                      </TableRow>
                    )
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
            component="div"
            count={filteredData.length || data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              'aria-label': 'Previous Page'
            }}
            nextIconButtonProps={{
              'aria-label': 'Next Page'
            }}
            onPageChange={this.handleChangePage}
            onRowsPerPageChange={this.handleChangeRowsPerPage}
          />
          <CSVLink
            data={this.getCsvData((filteredData && filteredData.length > 0) || data)}
            filename="apideck-leads.csv"
            style={{ position: 'absolute', left: 15, bottom: 15 }}
          >
            Download CSV
          </CSVLink>
          <LeadDrawer
            drawerOpen={drawerOpen}
            toggleDrawer={this.toggleDrawer}
            activeLead={activeLead}
            ecosystem={ecosystem}
          />
        </Paper>
      </div>
    )
  }
}

EnhancedTable.propTypes = {
  classes: PropTypes.object.isRequired
}

export default compose(withRouter, withStyles(styles))(EnhancedTable)
