import {
  createLocaleMutation,
  deleteEcosystemFaviconMutation,
  deleteEcosystemIconMutation,
  deleteEcosystemLogoMutation,
  deleteEcosystemMutation,
  deleteEcosystemSocialImageMutation,
  deleteLocaleMutation,
  updateMarketplaceGeneralSettingsMutation
} from 'api/queries'
import { GeneralSettingsForm } from 'components'
import LoadingPlaceholder from 'components/placeholder/Loading'
import { NOT_UPDATED, UPDATED } from 'constants/settings'
import PropTypes from 'prop-types'
import React from 'react'
import { graphql } from 'react-apollo'
import { toastr } from 'react-redux-toastr'
import { compose, withHandlers } from 'recompose'
import { addLocaleToStore, deleteImageFromStore, deleteLocaleFromStore } from 'store/ecosystem'
import uploadFile from 'utils/fileUpload'
import { setItem } from 'utils/localstorage'
import transformError from 'utils/transformError'

class Container extends React.Component {
  render() {
    const {
      handleSubmit,
      marketplace,
      handleLocaleCreate,
      handleLocaleDelete,
      handleEcosystemSocialImageDelete,
      handleEcosystemIconDelete,
      handleEcosystemFaviconDelete,
      handleEcosystemDelete,
      activeAccount
    } = this.props

    if (!marketplace) {
      return <LoadingPlaceholder />
    } else {
      return (
        <GeneralSettingsForm
          handleSubmit={handleSubmit}
          handleLocaleCreate={handleLocaleCreate}
          handleLocaleDelete={handleLocaleDelete}
          handleEcosystemSocialImageDelete={handleEcosystemSocialImageDelete}
          handleEcosystemIconDelete={handleEcosystemIconDelete}
          handleEcosystemFaviconDelete={handleEcosystemFaviconDelete}
          handleEcosystemDelete={handleEcosystemDelete}
          activeAccount={activeAccount}
          ecosystem={marketplace}
          initialValues={{
            name: marketplace.name || '',
            slug: marketplace.slug || '',
            website: marketplace.website || '',
            icon: marketplace.icon ? [marketplace.icon] : [],
            favicon: marketplace.favicon ? [marketplace.favicon] : [],
            socialImage: marketplace.socialImage ? [marketplace.socialImage] : []
          }}
        />
      )
    }
  }
}

Container.propTypes = {
  activeAccount: PropTypes.object.isRequired,
  marketplace: PropTypes.object.isRequired
}

const handlers = withHandlers({
  handleSubmit:
    ({ updateMarketplace, activeAccount, marketplace }) =>
    async ({ name, slug, website, icon, favicon, socialImage }, { setSubmitting, setErrors }) => {
      setSubmitting(true)

      let variables = {
        accountId: activeAccount.id,
        id: marketplace.id,
        name,
        slug,
        website
      }

      if (icon.length !== 0 && icon[0].preview) {
        let result = await uploadFile(icon[0], {
          params: {
            folder: `marketplaces`
          }
        })

        variables = Object.assign(
          {
            icon: {
              url: result.url,
              contentType: `${result.resourceType}/${result.format}`
            }
          },
          variables
        )
      }

      if (favicon.length !== 0 && favicon[0].preview) {
        let result = await uploadFile(favicon[0], {
          params: {
            folder: `marketplaces`
          }
        })

        variables = Object.assign(
          {
            favicon: {
              url: result.url,
              contentType: `${result.resourceType}/${result.format}`
            }
          },
          variables
        )
      }

      if (socialImage.length !== 0 && socialImage[0].preview) {
        let result = await uploadFile(socialImage[0], {
          params: {
            folder: `marketplaces`
          }
        })

        variables = Object.assign(
          {
            socialImage: {
              url: result.url,
              contentType: `${result.resourceType}/${result.format}`
            }
          },
          variables
        )
      }

      try {
        await updateMarketplace({ variables })

        toastr.success(UPDATED)
        setSubmitting(false)
      } catch (error) {
        toastr.error(NOT_UPDATED)
        if (error.graphQLErrors) {
          setErrors(transformError(error.graphQLErrors))
        }

        setSubmitting(false)
      }
    },
  handleLocaleCreate:
    ({
      createLocale,
      activeAccount,
      match: {
        params: { ecosystemId }
      }
    }) =>
    async ({ locale }, _, handleClose) => {
      try {
        const variables = {
          accountId: activeAccount.id,
          ecosystemId: ecosystemId,
          item: {
            code: locale
          }
        }

        await createLocale({
          variables,
          update: (store, { data: { createLocale } }) =>
            addLocaleToStore(
              store,
              {
                ecosystemId,
                accountId: activeAccount.id
              },
              createLocale
            )
        })

        toastr.success('Locale added')

        handleClose && handleClose()
      } catch (error) {
        toastr.error('Could not add locale')
        throw new Error(error.message)
      }
    },
  handleLocaleDelete:
    ({
      deleteLocale,
      activeAccount,
      match: {
        params: { ecosystemId }
      }
    }) =>
    async (id, handleClose) => {
      try {
        const variables = {
          accountId: activeAccount.id,
          id
        }

        await deleteLocale({
          variables,
          update: (store) =>
            deleteLocaleFromStore(
              store,
              {
                ecosystemId,
                accountId: activeAccount.id
              },
              id
            )
        })

        toastr.success('Locale deleted')

        handleClose && handleClose()
      } catch (error) {
        toastr.error('Could not delete locale')
        throw new Error(error.message)
      }
    },
  handleEcosystemSocialImageDelete:
    ({ deleteEcosystemSocialImage, activeAccount }) =>
    async (id) => {
      try {
        const variables = {
          accountId: activeAccount.id,
          ecosystemId: id
        }
        await deleteEcosystemSocialImage({
          variables,
          update: (store) =>
            deleteImageFromStore(store, {
              ecosystemId: id,
              accountId: activeAccount.id,
              propName: 'socialImage'
            })
        })

        toastr.success('Social Image deleted')
      } catch (error) {
        toastr.error('Could not delete Social Image')
        throw new Error(error && error.message)
      }
    },
  handleEcosystemIconDelete:
    ({ deleteEcosystemIcon, activeAccount }) =>
    async (id) => {
      try {
        const variables = {
          accountId: activeAccount.id,
          ecosystemId: id
        }
        await deleteEcosystemIcon({
          variables,
          update: (store) =>
            deleteImageFromStore(store, {
              ecosystemId: id,
              accountId: activeAccount.id,
              propName: 'icon'
            })
        })

        toastr.success('Icon deleted')
      } catch (error) {
        toastr.error('Could not delete Icon Image')
        throw new Error(error && error.message)
      }
    },
  handleEcosystemFaviconDelete:
    ({ deleteEcosystemFavicon, activeAccount }) =>
    async (id) => {
      try {
        const variables = {
          accountId: activeAccount.id,
          ecosystemId: id
        }
        await deleteEcosystemFavicon({
          variables,
          update: (store) =>
            deleteImageFromStore(store, {
              ecosystemId: id,
              accountId: activeAccount.id,
              propName: 'favicon'
            })
        })

        toastr.success('Favicon deleted')
      } catch (error) {
        toastr.error('Could not delete Favicon')
        throw new Error(error && error.message)
      }
    },
  handleEcosystemDelete:
    ({ deleteEcosystem, activeAccount }) =>
    async (ecosystemId, challenge) => {
      try {
        const variables = {
          accountId: activeAccount.id,
          ecosystemId: ecosystemId,
          challenge
        }

        await deleteEcosystem({
          variables
        })

        const activeEcosystemId = activeAccount.marketplaces[0].id
        setItem('activeEcosystemId', activeEcosystemId)

        window.location.href = window.location.origin

        toastr.success('Ecosystem deleted')
      } catch (error) {
        toastr.error('Could not delete ecosystem')
        throw new Error(error.message)
      }
    }
})

const updateMarketplace = graphql(updateMarketplaceGeneralSettingsMutation, {
  name: 'updateMarketplace'
})

const SettingsContainer = compose(
  graphql(createLocaleMutation, { name: 'createLocale' }),
  graphql(deleteLocaleMutation, { name: 'deleteLocale' }),
  graphql(deleteEcosystemSocialImageMutation, {
    name: 'deleteEcosystemSocialImage'
  }),
  graphql(deleteEcosystemLogoMutation, { name: 'deleteEcosystemLogo' }),
  graphql(deleteEcosystemIconMutation, { name: 'deleteEcosystemIcon' }),
  graphql(deleteEcosystemFaviconMutation, { name: 'deleteEcosystemFavicon' }),
  graphql(deleteEcosystemMutation, { name: 'deleteEcosystem' }),
  updateMarketplace,
  handlers
)(Container)

export default SettingsContainer
