import React from 'react'
import PropTypes from 'prop-types'
import MuiStepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepButton from '@material-ui/core/StepButton'

function Stepper({ steps, activeStep, completed, handleStep }) {
  return (
    <MuiStepper nonLinear activeStep={activeStep}>
      {steps.map(({ label }, index) => (
        <Step key={label}>
          <StepButton onClick={handleStep(index)} completed={completed[index]}>
            {label}
          </StepButton>
        </Step>
      ))}
    </MuiStepper>
  )
}

Stepper.propTypes = {
  activeStep: PropTypes.number.isRequired,
  handleStep: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      path: PropTypes.string
    })
  ).isRequired
}

export default Stepper
