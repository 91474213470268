import { makeStyles } from '@material-ui/core/styles'

export const useCardStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    borderRadius: '6px'
  },
  cardTitle: {
    fontSize: '.875rem',
    lineHeight: '1.25rem',
    color: theme.palette.ui[500],
    display: 'block',
    marginBottom: 8
  },
  cardNumber: {
    fontSize: 24,
    fontWeight: 600,
    lineHeight: '1.25rem',
    color: theme.palette.ui[800]
  },
  container: {
    width: '100%',
    cursor: 'normal',
    borderRadius: '6px'
  },
  actionArea: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(2)
  },
  leftContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start'
  },
  rightContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  icon: {
    width: '40px',
    height: '40px',
    marginRight: theme.spacing(2)
  },
  inactive: {
    filter: 'grayscale(100%)',
    opacity: 0.5
  },
  label: {
    fontWeight: 500,
    fontSize: '16px',
    color: theme.palette.ui[700]
  },
  description: {
    fontSize: '13px',
    color: theme.palette.ui[300]
  },
  iconArrow: {
    marginLeft: theme.spacing(4),
    color: theme.palette.ui[300],
    lineHeight: 1
  }
}))
