import { CardNoData, ExternalA, Icon } from 'components'

import DashboardCard from './Card'
import React from 'react'
import styled from 'react-emotion'

const Ol = styled('ol')`
  display: inline-block;
  padding-left: 25px;
  margin-top: 0;

  li {
    width: 100%;
    float: left;
    margin-bottom: 0px;
    line-height: 2.6em;

    a {
      color: #333;
    }

    p,
    span {
      line-height: 35px;
    }

    p {
      margin: 0;
      max-width: 350px;
    }

    span {
      margin-left: auto;
    }
  }
`

const OlLi = ({ icon, name, href, amount }) => (
  <li>
    <ExternalA href={href}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {icon && (
          <Icon src={icon} size={32} padding={0} style={{ margin: '0 12px 0 12px' }} alt="" />
        )}
        <p className="truncate">{name}</p>
        <span>{amount}</span>
      </div>
    </ExternalA>
  </li>
)

const ListCard = ({ title, data, loading }) => (
  <DashboardCard title={title} size={6} height="380px" loading={loading}>
    {data && data.length > 2 ? (
      <Ol>
        {data.map((p, i) => (
          <OlLi name={p.label} icon={p.icon} href={p.href} amount={p.count} key={i} />
        ))}
      </Ol>
    ) : (
      <CardNoData />
    )}
  </DashboardCard>
)

export default ListCard
