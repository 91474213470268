import React from 'react'
import { InvitationCreate } from 'components'
import { graphql } from 'react-apollo'
import { compose, withHandlers } from 'recompose'
import { createInvitationMutation, invitationsQuery } from 'api/queries'
import { toastr } from 'react-redux-toastr'
import transformError from 'utils/transformError'

class Container extends React.Component {
  render() {
    const { handleSubmit } = this.props

    return (
      <InvitationCreate
        initialValues={{
          email: '',
          role: ''
        }}
        handleSubmit={handleSubmit}
      />
    )
  }
}

const addInvitationToStore = (store, { invitation, accountId }) => {
  const data = store.readQuery({
    query: invitationsQuery,
    variables: {
      accountId
    }
  })

  data.invitations.push(invitation)

  store.writeQuery({
    query: invitationsQuery,
    data,
    variables: {
      accountId
    }
  })
}

const handleSubmit = withHandlers({
  handleSubmit:
    ({ createInvitation, activeAccount, history }) =>
    async ({ email, role }, { setSubmitting, setErrors }) => {
      try {
        setSubmitting(true)

        let variables = {
          email,
          role: role,
          accountId: activeAccount.id
        }

        await createInvitation({
          variables,
          update: (store, { data: { createInvitation } }) => {
            if (createInvitation.status === 'TO_BE_ACTIVATED') {
              addInvitationToStore(store, {
                invitation: createInvitation,
                accountId: activeAccount.id
              })
            }
          }
        })
        toastr.success('Invitation sent')
        history.push('/account/invitations')
      } catch (error) {
        if (error.graphQLErrors) {
          setErrors(transformError(error.graphQLErrors))
        }

        setSubmitting(false)
      }
    }
})

const createInvitation = graphql(createInvitationMutation, {
  name: 'createInvitation'
})

const InvitationCreateContainer = compose(createInvitation, handleSubmit)(Container)

export default InvitationCreateContainer
