import { Header, HeaderTitle } from 'components'

import React from 'react'
import ecosystemUrl from 'utils/ecosystemUrl'
import { withRouter } from 'react-router-dom'

const MarketplaceHeader = ({ ecosystem }) => (
  <Header>
    <HeaderTitle
      imageSrc={ecosystem.favicon ? ecosystem.favicon.url : null}
      imageTitle={ecosystem.name}
      title={ecosystem.name}
      subtitle={
        <a href={ecosystemUrl(ecosystem)} target="_blank" rel="noopener noreferrer">
          {ecosystemUrl(ecosystem)}
        </a>
      }
    />
  </Header>
)

export default withRouter(MarketplaceHeader)
