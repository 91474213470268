import App from 'routes/App'
import React from 'react'
import ReactDOM from 'react-dom'
import bugsnag from 'bugsnag-js'
import createPlugin from 'bugsnag-react'

export const bugsnagClient = bugsnag({
  apiKey: process.env.REACT_APP_BUGSNAG_API_KEY,
  appVersion: process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA,
  notifyReleaseStages: ['production', 'staging']
})
const ErrorBoundary = bugsnagClient.use(createPlugin(React))

ReactDOM.render(
  <ErrorBoundary>
    <App />
  </ErrorBoundary>,
  document.getElementById('root')
)

if (module.hot) {
  module.hot.accept('./routes/App', () => {
    const HotApp = require('./routes/App').default
    ReactDOM.render(
      <ErrorBoundary>
        <HotApp />
      </ErrorBoundary>,
      document.getElementById('root')
    )
  })
}
