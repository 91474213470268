import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import styled from 'react-emotion'

const styles = (theme) => ({
  root: {
    width: '100%'
  },
  button: {
    marginRight: theme.spacing(1)
  },
  completed: {
    display: 'inline-block'
  }
})

const ContentContainer = styled('div')`
  max-width: 1280px;
  margin: 30px auto;
`

class SetupLayout extends React.Component {
  render() {
    const { classes, stepper, children } = this.props

    return (
      <div style={{ borderTop: '5px solid rgb(26, 39, 68)' }}>
        <div className={classes.root}>
          <div style={{ backgroundColor: '#FFF' }}>
            <div style={{ maxWidth: 1200, margin: '0 auto', padding: '20px 0' }}>{stepper}</div>
          </div>
          <ContentContainer>{children}</ContentContainer>
        </div>
      </div>
    )
  }
}

SetupLayout.propTypes = {
  classes: PropTypes.object
}

export default withStyles(styles)(SetupLayout)
