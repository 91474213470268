import CTAFormTable from 'components/ecosystem/leads/cta-form/Table'
import IntegrationRequestsTable from 'components/ecosystem/leads/integration-requests/Table'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'

const LeadsPage = ({ ecosystem, leads, match, handleLeadDelete, setActivePath }) => {
  const integrationRequests = leads.filter((lead) => lead.requestedServices.length > 0)
  const CTAFormSubmissions = leads.filter((lead) => lead.requestedServices.length === 0)
  // const installationRequests = leads.filter(
  //   (lead) => lead.requestedServices.length > 0 && lead.installationRequest
  // )

  useEffect(() => {
    if (setActivePath) {
      setActivePath(match.path)

      return () => {
        setActivePath('')
      }
    }
  })

  if (match.path.includes('request-form')) {
    return (
      <IntegrationRequestsTable
        data={integrationRequests}
        ecosystem={ecosystem}
        leadCaptureFormEnabled={ecosystem.leadCaptureFormEnabled}
        handleLeadDelete={handleLeadDelete}
      />
    )
  } else {
    return (
      <CTAFormTable
        data={CTAFormSubmissions}
        ecosystem={ecosystem}
        handleLeadDelete={handleLeadDelete}
      />
    )
  }
}

LeadsPage.propTypes = {
  leads: PropTypes.array.isRequired
}

export default LeadsPage
