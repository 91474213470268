import { AnyErrorsMessage, PrimaryButtonNew as PrimaryButton, TextInput } from 'components'
import { Formik } from 'formik'
import PropTypes from 'prop-types'
import React from 'react'
import { ScaleLoader } from 'react-spinners'
import * as yup from 'yup'

const schema = yup.object().shape({
  email: yup.string().trim().email().required('Email is a required field'),
  password: yup
    .string()
    .required('Password is a required field')
    .min(8, 'Password must be at least 8 characters')
})

const ActivateForm = ({ onSubmit, initialValues }) => {
  return (
    <Formik validationSchema={schema} onSubmit={onSubmit} initialValues={initialValues}>
      {(formikProps) => {
        const { handleSubmit, isSubmitting } = formikProps
        return (
          <form onSubmit={handleSubmit}>
            <TextInput
              formikProps={formikProps}
              type="email"
              label="Email"
              field="email"
              autofill="email"
            />
            <TextInput
              formikProps={formikProps}
              label="Password"
              field="password"
              type="password"
              autofill="current-password"
            />
            <PrimaryButton type="submit" disabled={isSubmitting}>
              {isSubmitting ? (
                <ScaleLoader height={12} width={3} color="var(--color-white)" />
              ) : (
                'Join'
              )}
            </PrimaryButton>

            <AnyErrorsMessage />
          </form>
        )
      }}
    </Formik>
  )
}

ActivateForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired
}

export default ActivateForm
