import { ContentContainer, ProductHeader } from 'components'
import PrivateRoute from 'components/PrivateRoute'
import {
  ProductOverviewContainer as OverviewContainer,
  ProductSettingsContainer as SettingsContainer
} from 'containers'
import PropTypes from 'prop-types'
import React from 'react'
import { Switch, withRouter } from 'react-router-dom'

const ProductLayout = ({ match, product, activeAccount, listings, ecosystem }) => (
  <ContentContainer header={<ProductHeader product={product} ecosystem={ecosystem} />}>
    <Switch>
      <PrivateRoute
        path={`${match.path}/overview`}
        component={OverviewContainer}
        product={product}
        activeAccount={activeAccount}
        ecosystem={ecosystem}
        listings={listings}
      />
      <PrivateRoute
        path={`${match.path}/settings`}
        component={SettingsContainer}
        product={product}
        activeAccount={activeAccount}
        ecosystem={ecosystem}
      />
    </Switch>
  </ContentContainer>
)

ProductLayout.propTypes = {
  product: PropTypes.object.isRequired,
  activeAccount: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  listings: PropTypes.array.isRequired
}

const ProductProductLayout = withRouter(ProductLayout)

export default ProductProductLayout
