import { ExternalA, GridContainer, MarkdownEditor, MarkdownPreview, Pill } from 'components'
import { compose, withHandlers } from 'recompose'

import Grid from '@material-ui/core/Grid'
import MarkdownIcon from 'assets/markdown.svg'
import React from 'react'
import isFieldLocalized from 'utils/isFieldLocalized'
import styled from 'react-emotion'

const MODE = 'gfm'

const InputWrapper = styled('div')`
  padding: var(--input-padding);
  border: 1px solid rgb(212, 212, 212);
  border-radius: 3px;
  width: 100%;
`

const Input = ({
  field,
  label,
  pill,
  description,
  formikProps: { values },
  handleChange,
  ecosystem
}) => (
  <div id={field}>
    <div
      style={{
        marginBottom: '16px'
      }}
    >
      <GridContainer>
        <Grid item lg={6}>
          <label htmlFor={field}>
            {label} {pill && <Pill>{pill}</Pill>}
          </label>
          {description && <p>{description}</p>}
          <InputWrapper>
            <MarkdownEditor
              value={values[field] ?? ''}
              mode={MODE}
              lineWrapping
              onBeforeChange={handleChange}
            />
          </InputWrapper>
          <div style={{ fontSize: '13px' }}>
            <img
              src={MarkdownIcon}
              alt="Markdown"
              style={{ width: '24px', verticalAlign: 'middle' }}
            />{' '}
            Format your text like a pro with the{' '}
            <ExternalA href="https://help.github.com/articles/basic-writing-and-formatting-syntax/">
              markdown cheatsheet
            </ExternalA>
            .
          </div>
        </Grid>
        <Grid item lg={5}>
          <p style={{ marginTop: 0 }}>
            <b>Preview</b>
          </p>
          <MarkdownPreview value={values[field] ?? ''} ecosystem={ecosystem} />
        </Grid>
      </GridContainer>
    </div>
  </div>
)

const withHandleChange = withHandlers({
  handleChange:
    ({ field, formikProps }) =>
    (editor, data, value = '') =>
      formikProps.setFieldValue(field, value)
})

const MarkdownTextInputWithHandler = compose(withHandleChange)(Input)

const MarkdownTextInput = (props) =>
  isFieldLocalized(props) ? (
    <LocalizedMarkdownTextInput {...props} />
  ) : (
    <MarkdownTextInputWithHandler {...props} />
  )

const LocalizedMarkdownTextInput = (props) => (
  <React.Fragment>
    {props.formikProps.initialValues.locales.map((locale, i) => (
      <MarkdownTextInputWithHandler
        {...props}
        key={`${props.field}_${locale}_${i}`}
        field={`${props.field}_${locale}`}
        label={props.label}
        pill={locale}
      />
    ))}
  </React.Fragment>
)

export default MarkdownTextInput
