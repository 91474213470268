import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

const useStyles = makeStyles((theme) => ({
  container: {
    fontSize: '13px',
    padding: theme.spacing(0.25, 0.4),
    borderRadius: '4px',
    fontWeight: 500
  },
  success: {
    backgroundColor: '#EAF7EF',
    color: '#60B764'
  },
  error: {
    backgroundColor: '#FAEBEB',
    color: '#E58181'
  },
  neutral: {
    backgroundColor: theme.palette.ui[200],
    color: theme.palette.ui[300]
  },
  uppercase: {
    textTransform: 'uppercase'
  }
}))

const Badge = ({ label, type, uppercase = true }) => {
  const classes = useStyles()

  return (
    <span
      className={classNames([
        classes.container,
        {
          [classes.uppercase]: uppercase
        },
        {
          [classes.success]: type === 'success',
          [classes.error]: type === 'error',
          [classes.neutral]: type === 'neutral'
        }
      ])}
    >
      {label}
    </span>
  )
}

Badge.propTypes = {
  type: PropTypes.oneOf(['success', 'error', 'neutral']).isRequired,
  label: PropTypes.string.isRequired
}

export default Badge
