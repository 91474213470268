import React from 'react'
import styled from 'react-emotion'
import { media } from 'styles/styles'

const Container = styled('div')`
  font-size: 16px;
  font-weight: 500;

  ul.clients {
    padding: 0;
    list-style: none;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;

    &:hover {
      li {
        img {
          opacity: 0.6;
        }
      }
    }

    li {
      width: 45%;
      margin-top: 30px;

      a {
        display: block;

        &:hover {
          img {
            opacity: 1;
          }
        }

        img {
          transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
        }
      }
    }
  }

  @media screen and (max-width: ${media.medium}) {
    ul.clients {
      display: block;

      li {
        width: 100%;

        &:nth-last-child(-n + 3) {
          display: none;
        }
      }
    }
  }
`

const SignupSidebarContent = () => {
  return (
    <Container>
      Join SaaS companies of all sizes:
      <ul className="clients">
        <li>
          <a href="https://integrations.clicksend.com" target="_blank" rel="noreferrer">
            <img src="/images/clients/clicksend.png" alt="Clicksend" style={{ height: '22px' }} />
          </a>
        </li>
        <li>
          <a href="https://apps.10to8.com" target="_blank" rel="noreferrer">
            <img
              src="/images/clients/10to8.png"
              alt="10to8"
              style={{ height: '25px', marginLeft: '20px' }}
            />
          </a>
        </li>
        <li>
          <a href="https://appstore.nmbrs.com" target="_blank" rel="noreferrer">
            <img
              src="/images/clients/nmbrs.png"
              alt="Nmbrs"
              style={{ height: '17px', marginLeft: '10px' }}
            />
          </a>
        </li>
        <li>
          <a href="https://marketplace.officient.io" target="_blank" rel="noreferrer">
            <img
              src="/images/clients/officient.png"
              alt="Officient"
              style={{ height: '17px', marginLeft: '15px' }}
            />
          </a>
        </li>
        <li>
          <a href="https://integrations.messagemedia.com" target="_blank" rel="noreferrer">
            <img
              src="/images/clients/messagemedia.png"
              alt="Message Media"
              style={{ height: '33px' }}
            />
          </a>
        </li>
        <li>
          <a href="https://integrations.salesflare.com" target="_blank" rel="noreferrer">
            <img src="/images/clients/salesflare.png" alt="Salesflare" style={{ height: '27px' }} />
          </a>
        </li>
      </ul>
    </Container>
  )
}

export default SignupSidebarContent
