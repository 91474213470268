import React, { Fragment } from 'react'

import { Link } from 'react-router-dom'

export const handleUserFormError = (setErrors, error) => {
  switch (error?.code) {
    case 'user_exists':
      return setErrors({
        user_error: (
          <Fragment>
            A user with this email address already exists.
            <br />
            If this is your email, please try to <Link to="/login">log in</Link>.
          </Fragment>
        )
      })

    case 'invalid_password':
      return setErrors({
        user_error: error?.policy
      })

    case 'access_denied':
      return setErrors({
        user_error: `Login failed. ${error?.error_description}`
      })

    default:
      setErrors({
        user_error: (
          <Fragment>
            {error?.message ||
              error?.error_description ||
              error?.error ||
              error?.description ||
              error?.toString()}
          </Fragment>
        )
      })
  }
}
