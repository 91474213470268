import PropTypes from 'prop-types'
import React from 'react'
import ReactMarkdown from 'react-markdown'
import { assign } from 'lodash'
import remarkGfm from 'remark-gfm'
import styled from 'react-emotion'

const PreviewWrapper = styled('div')`
  table {
    border-spacing: 0;
    border-collapse: collapse;
    width: 100%;
  }

  th,
  td {
    border: 1px solid rgb(212, 212, 212);
    padding: 5px;
  }

  th {
    text-align: left;
    background-color: #f9f9f9;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: normal;
    margin-bottom: 20px;
    color: ${(props) => props.bodyColor};
    border: none;
  }

  p,
  ul li,
  ol li {
    line-height: 25px;
    font-size: 15px;
  }

  p,
  ul,
  ol {
    color: #808080;
    margin-bottom: 12px;
  }

  ol {
    margin-left: 18px;
  }

  ul {
    list-style: square;
    margin-left: 18px;
  }

  ol,
  ul {
    margin-bottom: 40px;
  }

  ul li,
  ol li {
    margin-bottom: 6px;
  }

  ul li,
  ol li,
  p {
    font-size: 14px;
    line-height: 1.8;
  }

  .a,
  a:visited {
    color: ${(props) => props.primaryColor};
  }

  background-color: ${(props) => props.bodyBackgroundColor};
  color: ${(props) => props.bodyColor};
  padding: 10px;
  border-radius: 4px;
  min-height: ${(props) => props.minHeight};
`

const CodeBlock = () => (
  <pre>
    <code>{this.props.literal}</code>
  </pre>
)

const Preview = ({ value = '', ecosystem }) => {
  const primaryColor = ecosystem?.primaryColor?.trim()
  const bodyBackgroundColor = ecosystem?.bodyBackgroundColor?.trim()
  const bodyColor = ecosystem?.bodyColor?.trim()

  return (
    <PreviewWrapper
      primaryColor={primaryColor}
      bodyColor={bodyColor}
      bodyBackgroundColor={value?.length > 0 && bodyBackgroundColor}
      minHeight={value?.length > 0 ? '258px' : '100%'}
    >
      <ReactMarkdown
        renderers={assign({}, ReactMarkdown.renderers, { CodeBlock })}
        remarkPlugins={[remarkGfm]}
      >
        {value}
      </ReactMarkdown>
    </PreviewWrapper>
  )
}

Preview.propTypes = {
  value: PropTypes.string.isRequired
}

export default Preview
