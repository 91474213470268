import { GridCard } from 'components'
import React from 'react'
import getLocalizedValue from 'utils/getLocalizedValue'
import { listingLogo } from '../../Helpers'
import IntegrationPlatformBadges from '../../layout/IntegrationPlatformBadges'

const listingLink = (ecosystem, listing) => {
  let linkToListing = `/ecosystems/${ecosystem.id}/listings/${listing.id}/overview`
  if (listing.pendingReviewListing) {
    linkToListing = `/ecosystems/${ecosystem.id}/listings/${listing.pendingReviewListing.id}/overview`
  }

  return linkToListing
}

const ListingCard = ({ listing, ecosystem }) => {
  return (
    <GridCard
      title={getLocalizedValue(listing, 'name', ecosystem.locales)}
      subtitle={
        listing?.category ? getLocalizedValue(listing?.category, 'name', ecosystem.locales) : ''
      }
      state={listing.state}
      imageSrc={listingLogo(listing)}
      link={listingLink(ecosystem, listing)}
      id={listing.id}
      key={listing.id}
      pendingReviewListing={listing?.pendingReviewListing}
      integration
      upcoming={listing.upcoming}
      small
      sm={2}
      xs={12}
    >
      <IntegrationPlatformBadges
        zapierId={listing.zapierId}
        piesyncId={listing.piesyncId}
        segmentId={listing.segmentId}
        blendrId={listing.blendrId}
        automateId={listing.automateId}
        microsoftFlowId={listing.microsoftFlowId}
        combideskId={listing.combideskId}
        trayIoId={listing.trayIoId}
        integromatId={listing.integromatId}
        zapierEnabled={ecosystem.zapierEnabled}
        piesyncEnabled={ecosystem.piesyncEnabled}
        segmentEnabled={ecosystem.segmentEnabled}
        blendrEnabled={ecosystem.blendrEnabled}
        automateEnabled={ecosystem.automateEnabled}
        microsoftFlowEnabled={ecosystem.microsoftFlowEnabled}
        combideskEnabled={ecosystem.combideskEnabled}
        trayIoEnabled={ecosystem.trayIoEnabled}
        integromatEnabled={ecosystem.integromatEnabled}
        nativeIcon={
          (ecosystem.icon && ecosystem.icon.url) || (ecosystem.favicon && ecosystem.favicon.url)
        }
        nativeIntegration={listing.nativeIntegration}
        thirdPartyIntegration={listing.thirdPartyIntegration}
        imageSrc={listingLogo(listing)}
      />
    </GridCard>
  )
}

export default ListingCard
