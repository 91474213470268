import { Card, CardEmptyState, GridContainer, ListingCard, ListToggle, Loader } from 'components'
import React, { Fragment } from 'react'
import { AutoSizer, InfiniteLoader, List, WindowScroller } from 'react-virtualized'

import styled from 'react-emotion'
import Button from '@material-ui/core/Button'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { useMaxListingsContext } from 'context/useMaxListingsContext'
import Tooltip from '@material-ui/core/Tooltip'

const NUM_ITEMS_PER_ROW = 6

const DisabledButton = styled('div')`
  padding: 6px 16px;
  font-size: 0.875rem;
  min-width: 64px;
  box-sizing: border-box;
  font-weight: 500;
  line-height: 1.75;
  border-radius: 4px;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.26);
  background: rgba(0, 0, 0, 0.12);
`

const Listings = ({ listings, ecosystem, hasMore, total, loadNextPage, nextPageIsLoading }) => {
  const rowCountReal = Math.ceil(listings.length / NUM_ITEMS_PER_ROW)
  // adding 1 row for loading indicator
  const rowCount = hasMore ? rowCountReal + 1 : rowCountReal
  const loadMoreRows = nextPageIsLoading ? () => {} : loadNextPage
  const { maxListingsReached, infoMessage } = useMaxListingsContext()
  const isRowLoaded = ({ index }) => !hasMore || index < rowCountReal
  const rowRenderer = ({ index, key, style }) => {
    const fromIndex = index * NUM_ITEMS_PER_ROW
    const toIndex = Math.min(fromIndex + NUM_ITEMS_PER_ROW, listings.length)

    if (!isRowLoaded({ index })) {
      return (
        <div
          key={index}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            ...style
          }}
        >
          <Loader />
        </div>
      )
    }

    return (
      <div key={key} style={style}>
        <GridContainer>
          {listings.slice(fromIndex, toIndex).map((listing) => (
            <ListingCard key={listing.id} listing={listing} ecosystem={ecosystem} />
          ))}
        </GridContainer>
      </div>
    )
  }

  return (
    <Fragment>
      <div data-testid="ecosystem-listings-overview-view">
        <div style={{ marginBottom: '12px' }}>
          <ListToggle view="cards" ecosystemId={ecosystem.id} />
        </div>
        <Card
          title="Listings"
          button={
            <>
              {maxListingsReached ? (
                <Tooltip title={infoMessage} interactive placement="left" arrow>
                  <DisabledButton className="fr" data-testid="ecosystem-listing-add-button">
                    + Add listing
                  </DisabledButton>
                </Tooltip>
              ) : (
                <Link to={`/ecosystems/${ecosystem.id}/listings/add`}>
                  <Button
                    variant="contained"
                    className="fr"
                    color="primary"
                    data-testid="ecosystem-listing-add-button"
                  >
                    + Add listing
                  </Button>
                </Link>
              )}
            </>
          }
        >
          {listings.length ? (
            <InfiniteLoader
              isRowLoaded={isRowLoaded}
              loadMoreRows={loadMoreRows}
              rowCount={Math.round(total / NUM_ITEMS_PER_ROW)}
            >
              {({ onRowsRendered, registerChild }) => (
                <WindowScroller scrollElement={document.querySelector('#scroll-element')}>
                  {({ height, isScrolling, scrollTop }) => {
                    return (
                      <AutoSizer disableHeight>
                        {({ width }) => (
                          <List
                            ref={registerChild}
                            autoHeight
                            height={height || 0}
                            isScrolling={isScrolling}
                            scrollTop={scrollTop}
                            onRowsRendered={onRowsRendered}
                            rowRenderer={rowRenderer}
                            rowCount={rowCount}
                            rowHeight={160}
                            width={width}
                            style={{ outline: 'none' }}
                          />
                        )}
                      </AutoSizer>
                    )
                  }}
                </WindowScroller>
              )}
            </InfiniteLoader>
          ) : (
            <CardEmptyState>
              No results found.{' '}
              <Link to={`/ecosystems/${ecosystem.id}/listings/add`}>Add a Listing</Link>
            </CardEmptyState>
          )}
        </Card>
      </div>
    </Fragment>
  )
}

Listings.propTypes = {
  listings: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired
    }).isRequired
  ).isRequired,
  setSearchText: PropTypes.func.isRequired,
  setIntegrationPlatform: PropTypes.func.isRequired,
  hasMore: PropTypes.bool.isRequired,
  page: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  loadNextPage: PropTypes.func.isRequired,
  nextPageIsLoading: PropTypes.bool.isRequired
}

export default Listings
