import { productsPreviewQuery } from 'api'
import { EcosystemProducts as Products, ErrorFallback, LoadingPlaceholder } from 'components'
import React from 'react'
import { useQuery } from 'react-apollo'
// TODO move to pages folder

const ProductsContainer = ({ ecosystem }) => {
  const { loading, error, data } = useQuery(productsPreviewQuery, {
    variables: {
      ecosystemId: ecosystem.id,
      listingLimit: 3,
      limit: 1000 // Use absurd limit until pagination added
    }
  })

  if (loading) {
    return <LoadingPlaceholder />
  }

  if (error) {
    return <ErrorFallback error={error} />
  }

  const products = data?.productsPreview
  return <Products products={products} ecosystem={ecosystem} />
}

export default ProductsContainer
