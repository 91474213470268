import { loader } from 'graphql.macro'
import { toastr } from 'react-redux-toastr'
import transformError from 'utils/transformError'
import { useMutation } from 'react-apollo'

const UPDATE_CONTACT = loader('../contacts/update.graphql')

function useUpdateContactAction({ accountId }) {
  const [updateContactMutation] = useMutation(UPDATE_CONTACT)

  async function handleUpdateContact(
    { id, firstName, lastName, email, role },
    { setSubmitting, setErrors },
    handleClose
  ) {
    try {
      setSubmitting(true)

      let variables = {
        accountId,
        email,
        firstName,
        id,
        lastName,
        role
      }

      await updateContactMutation({ variables })

      toastr.success('Contact updated')

      handleClose()
    } catch (error) {
      if (error.graphQLErrors) {
        setErrors(transformError(error.graphQLErrors))
      }
      toastr.error('Could not update contact')
    } finally {
      setSubmitting(false)
    }
  }

  return [handleUpdateContact]
}

export { useUpdateContactAction }
