import { ListingsSettings, injectLocalizedValues, prepareVariables } from 'components'
import { NOT_UPDATED, UPDATED } from 'constants/settings'
import { compose, withHandlers } from 'recompose'
import { ecosystemLocales, setRootValueFromTranslation } from 'utils/locales'

import LoadingPlaceholder from 'components/placeholder/Loading'
import PropTypes from 'prop-types'
import React from 'react'
import { graphql } from 'react-apollo'
import { toastr } from 'react-redux-toastr'
import transformError from 'utils/transformError'
import { updateListingsSettingsMutation } from 'api/queries'

class Container extends React.Component {
  render() {
    const { handleSubmit, ecosystem } = this.props

    if (!ecosystem) {
      return <LoadingPlaceholder />
    } else {
      let initialValues = {
        listingDescriptionTextTemplate: ecosystem.listingDescriptionTextTemplate || '',
        listingPricingTextTemplate: ecosystem.listingPricingTextTemplate || '',
        listingFeaturesTextTemplate: ecosystem.listingFeaturesTextTemplate || '',
        listingDescriptionTitle: ecosystem.listingDescriptionTitle || '',
        listingPricingTitle: ecosystem.listingPricingTitle || '',
        listingFeaturesTitle: ecosystem.listingFeaturesTitle || '',
        listingNativeIntegrationLink: ecosystem.listingNativeIntegrationLink,
        listingInstallButtonLabel: ecosystem.listingInstallButtonLabel || '',
        shadowPagesEnabled: ecosystem?.shadowPagesEnabled ?? false,
        shadowPageDescription: ecosystem.shadowPageDescription || '',
        installationRequestFlowEnabled: ecosystem?.installationRequestFlowEnabled ?? false,
        about: ecosystem.about || '',
        locales: ecosystemLocales(ecosystem)
      }

      initialValues = injectLocalizedValues('ecosystemListingSettings', initialValues, ecosystem)

      return (
        <ListingsSettings
          handleSubmit={handleSubmit}
          initialValues={initialValues}
          ecosystem={ecosystem}
        />
      )
    }
  }
}

Container.propTypes = {
  activeAccount: PropTypes.object.isRequired,
  ecosystem: PropTypes.object.isRequired
}

const handlers = withHandlers({
  handleSubmit:
    ({ updateListingsSettings, activeAccount, ecosystem }) =>
    async (props, { setSubmitting, setErrors }) => {
      setSubmitting(true)

      const {
        listingDescriptionTextTemplate,
        listingPricingTextTemplate,
        listingFeaturesTextTemplate,
        listingNativeIntegrationLink,
        listingInstallButtonLabel,
        listingDescriptionTitle,
        listingPricingTitle,
        listingFeaturesTitle,
        shadowPagesEnabled,
        shadowPageDescription,
        installationRequestFlowEnabled,
        about
      } = props

      const locales = ecosystemLocales(ecosystem)

      let variables = {
        accountId: activeAccount.id,
        id: ecosystem.id,
        name: ecosystem.name,
        slug: ecosystem.slug,
        listingNativeIntegrationLink,
        listingDescriptionTextTemplate: setRootValueFromTranslation(
          locales,
          ecosystem,
          'listingDescriptionTextTemplate',
          listingDescriptionTextTemplate
        ),
        listingPricingTextTemplate: setRootValueFromTranslation(
          locales,
          ecosystem,
          'listingPricingTextTemplate',
          listingPricingTextTemplate
        ),
        listingFeaturesTextTemplate: setRootValueFromTranslation(
          locales,
          ecosystem,
          'listingFeaturesTextTemplate',
          listingFeaturesTextTemplate
        ),
        listingDescriptionTitle: setRootValueFromTranslation(
          locales,
          ecosystem,
          'listingDescriptionTitle',
          listingDescriptionTitle
        ),
        listingInstallButtonLabel: setRootValueFromTranslation(
          locales,
          ecosystem,
          'listingInstallButtonLabel',
          listingInstallButtonLabel
        ),
        listingPricingTitle: setRootValueFromTranslation(
          locales,
          ecosystem,
          'listingPricingTitle',
          listingPricingTitle
        ),
        listingFeaturesTitle: setRootValueFromTranslation(
          locales,
          ecosystem,
          'listingFeaturesTitle',
          listingFeaturesTitle
        ),
        shadowPagesEnabled,
        shadowPageDescription: setRootValueFromTranslation(
          locales,
          ecosystem,
          'shadowPageDescription',
          shadowPageDescription
        ),
        installationRequestFlowEnabled,
        about: setRootValueFromTranslation(locales, ecosystem, 'about', about),
        translations: prepareVariables(props, ecosystem, ecosystem.translations)
      }

      try {
        await updateListingsSettings({
          variables
        })

        toastr.success(UPDATED)
        setSubmitting(false)
      } catch (error) {
        toastr.error(NOT_UPDATED)
        if (error.graphQLErrors) {
          setErrors(transformError(error.graphQLErrors))
        }

        setSubmitting(false)
      }
    }
})

const updateListingsSettings = graphql(updateListingsSettingsMutation, {
  name: 'updateListingsSettings'
})

const SettingsContainer = compose(updateListingsSettings, handlers)(Container)

export default SettingsContainer
