import React, { Fragment, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Card from '@material-ui/core/Card'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import Switch from '@material-ui/core/Switch'
import Typography from '@material-ui/core/Typography'
import Tooltip from '@material-ui/core/Tooltip'
import Avatar from '@material-ui/core/Avatar'
import { IntegrationsSettingsModal, LoaderInline } from 'components'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: 'calc(50% - 50px)',
    marginRight: theme.spacing(4),
    marginBottom: theme.spacing(2),
    border: `1px solid ${theme.palette.ui[200]}`,
    padding: theme.spacing(2, 2),
    borderRadius: '6px'
  },
  dataContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start'
  },
  icon: {
    width: '28px',
    height: '28px',
    marginRight: theme.spacing(2)
  },
  inactive: {
    filter: 'grayscale(100%)',
    opacity: 0.5
  },
  label: {
    fontWeight: 500,
    fontSize: '14px',
    color: theme.palette.ui[700]
  },
  description: {
    fontSize: '12px',
    color: theme.palette.ui[300]
  },
  actionContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  settingsButton: {
    fontSize: '13px',
    fontWeight: 500,
    marginRight: theme.spacing(2),
    backgroundColor: theme.palette.ui[100],
    '&:hover': {
      backgroundColor: theme.palette.ui[200]
    }
  },
  loaderContainer: {
    display: 'inline-block',
    marginRight: theme.spacing(2)
  }
}))

const IntegrationPlatformCard = ({
  formId,
  label,
  icon,
  description,
  docUrl,
  isActive,
  handleToggleActivateIntegrationPlatform,
  updateIntegrationPlatformSettingsMutationLoading,
  integrationPlatformZapierFormFields,
  handleIntegrationPlatformZapierFormSubmit,
  updateIntegrationSettingsMutationLoading
}) => {
  const [isModalOpen, setModalOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const classes = useStyles()
  const isZapierPlatformCard = formId === 'zapierEnabled'
  const platform = {
    name: label,
    icon,
    tagLine: description,
    formFields: integrationPlatformZapierFormFields
  }
  const handleToggle = (formId) => {
    setLoading(true)
    handleToggleActivateIntegrationPlatform(formId)
  }

  useEffect(() => {
    if (!updateIntegrationPlatformSettingsMutationLoading) {
      setLoading(false)
    }
  }, [updateIntegrationPlatformSettingsMutationLoading])

  return (
    <Card className={classes.container} variant="outlined">
      <a href={docUrl} target="_blank" rel="noreferrer" className={classes.dataContainer}>
        <Avatar
          className={classNames(classes.icon, {
            [classes.inactive]: !isActive
          })}
          src={icon}
          variant="rounded"
        >
          {label.substring(0, 1)}
        </Avatar>
        <div>
          <Typography className={classes.label}>{label}</Typography>
          <Typography className={classes.description}>{description}</Typography>
        </div>
      </a>
      <div className={classes.actionContainer}>
        {isZapierPlatformCard && (
          <Fragment>
            {!loading && (
              <a
                href="#modal"
                onClick={(e) => {
                  e.preventDefault()
                  setModalOpen(true)
                }}
              >
                <Button
                  className={classes.settingsButton}
                  size="small"
                  color="primary"
                  disableRipple
                >
                  Settings
                </Button>
              </a>
            )}
            <IntegrationsSettingsModal
              isOpen={isModalOpen}
              setModalOpen={setModalOpen}
              connection={platform}
              headerActions={
                <Fragment>
                  {loading && (
                    <div className={classes.loaderContainer}>
                      <LoaderInline />
                    </div>
                  )}
                  <Tooltip
                    title={
                      isActive
                        ? 'Disable this integration platform'
                        : 'Enable this integration platform'
                    }
                    placement="top"
                    arrow
                  >
                    <Switch checked={isActive} onChange={() => handleToggle(formId)} />
                  </Tooltip>
                </Fragment>
              }
              subheader="Integration platform"
              handleFormSubmit={handleIntegrationPlatformZapierFormSubmit}
              formSubmitLoading={updateIntegrationSettingsMutationLoading}
            />
          </Fragment>
        )}
        {loading && (
          <div className={classes.loaderContainer}>
            <LoaderInline />
          </div>
        )}
        <Tooltip
          title={
            isActive ? 'Disable this integration platform' : 'Enable this integration platform'
          }
          placement="top"
          arrow
        >
          <Switch checked={isActive} onChange={() => handleToggle(formId)} />
        </Tooltip>
      </div>
    </Card>
  )
}

IntegrationPlatformCard.defaultProps = {
  icon: null
}

IntegrationPlatformCard.propTypes = {
  formId: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  icon: PropTypes.string,
  description: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
  handleToggleActivateIntegrationPlatform: PropTypes.func.isRequired,
  updateIntegrationPlatformSettingsMutationLoading: PropTypes.bool.isRequired
}

export default IntegrationPlatformCard
